import { HiOutlineArrowNarrowDown } from "react-icons/hi";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import ReusableModal from "../../../layout/GenericLayout/ReusableModal";
import { nanoid } from "@reduxjs/toolkit";

/* MODALS OR CHILDREN COMPONENTS */
const StatusHistoryModal = (props) => {
  /* OBJECT DESTRUCTURE */
  const { activeRemarks } = props;

  /* TABLE OPTIONS */
  const dateOptions = {
    day: "numeric",
    year: "numeric",
    month: "short",
  };

  const timeOptions = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  return (
    <div className="">
      <span className="fs-4 fw-semibold">Status History</span>
      <div>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Sl No.</th>
              <th scope="col">Remarks</th>
              <th scope="col">Date</th>
            </tr>
          </thead>
          <tbody>
            {activeRemarks.map((eachRows, index) => (
              <tr key={nanoid()} scope="col">
                <th scope="row">{index + 1}</th>
                <td>{eachRows.remark}</td>
                <td className="d-flex flex-column">
                  <span className="">
                    {new Date(eachRows.timestamp).toLocaleDateString(
                      "en-IN",
                      dateOptions
                    )}
                  </span>
                  <span className="text-uppercase">
                    {new Date(eachRows.timestamp).toLocaleTimeString(
                      "en-IN",
                      timeOptions
                    )}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

/* PARENT COMPONENT */
const AdminChannelPartnerTable = (props) => {
  /* FUNCTION DECLARATIONS */
  const navigate = useNavigate();
  /* USESTATES */
  const [showModal, setShowModal] = useState(false);
  const [activeRemarks, setActiveRemarks] = useState([]);

  /* EVENT LISTENERS */
  const toggleModal = () => {
    setShowModal((prev) => !prev);
  };

  const onClickStatus = (remarks) => {
    setActiveRemarks(remarks);
    setShowModal(!showModal);
  };

  const onClickAccountHolderName = (emailId) => {
    navigate(`/admin/channel-partner/kyc/${emailId}`, {
      state: { isUser: false },
    });
  };

  /* TABLE OPTIONS */
  const totalPage = 5;

  return (
    <>
      {/* Modal Content */}
      <ReusableModal
        showModal={showModal}
        toggleModal={toggleModal}
        modalContent={<StatusHistoryModal activeRemarks={activeRemarks} />}
        modalSizeClass="modal-lg"
      />
      <div className="table-container">
        <table className={props.className}>
          <thead>
            <tr>
              {props.tableHeader.map((eachHeader) => (
                <th key={nanoid()} scope="col">
                  {eachHeader}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {props.tableRows.map((eachRows) => (
              <tr key={eachRows.customerId} scope="col">
                <td>{eachRows.customerId}</td>
                <td
                  role="button"
                  onClick={() => onClickAccountHolderName(eachRows.emailId)}
                >
                  {eachRows.name}
                </td>
                <td>{eachRows.emailId}</td>
                <td onClick={() => onClickStatus(eachRows.remarks)}>
                  {eachRows?.status ? (
                    eachRows?.status === "approved" ? (
                      <span
                        className="w-75 text-capitalize mx-1 success-btn"
                        type="button"
                      >
                        {eachRows.status}
                      </span>
                    ) : eachRows?.status === "saved" ? (
                      <span
                        className="w-75 text-capitalize mx-1 highlight-btn"
                        type="button"
                      >
                        {eachRows.status}
                      </span>
                    ) : (
                      <span
                        className="w-75 text-capitalize mx-1 warning-btn"
                        type="button"
                      >
                        {eachRows.status}
                      </span>
                    )
                  ) : (
                    <span
                      className="w-75 text-capitalize mx-1 danger-btn"
                      type="button"
                    >
                      NAN
                    </span>
                  )}
                  {/* // <span className="text-capitalize mx-1 success-btn" type="button">
                  //   {eachRows.status}
                  // </span> */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* <div className="d-flex justify-content-end px-2">
          <span
            // onClick={props.onClickViewMoreTableData}
            className="d-flex align-items-center gap-1 my-3 view-more-text"
          >
            View more <HiOutlineArrowNarrowDown className="fs-5" />
          </span>
        </div> */}
      </div>
      {/* <div className="d-flex align-items-center gap-2 page-data-text-container mt-3 px-3">
        <AiOutlineDoubleLeft
        // onClick={props.onClickPreviousPage}
        />
        <span className="page-data-text">
          Page {} of {totalPage}
        </span>
        <AiOutlineDoubleRight
        // onClick={props.onClickNextPage}
        />
      </div> */}
    </>
  );
};

export default AdminChannelPartnerTable;
