import { nanoid } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postResetPassword } from "../../store/slices/underwriter";
import { toastMessage } from "../../layout/GenericLayout/ToastMessage";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const UnderwriterMyProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const resetPassword = useSelector((state) => state.underwriter.resetPassword);


  const [changePassword, setChangePassword] = useState({
    currentPassword: "",
    newPassword: "",
  });



  const onChangeChangePassword = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setChangePassword((prev) => ({ ...prev, [name]: value }));
  };

  const onClickUpdatePassword = () => {
    const body = {
      emailId: sessionStorage.getItem("email"),
      newPassword: changePassword.newPassword,
      oldPassword: changePassword.currentPassword,
    };
    dispatch(postResetPassword(body))
      .unwrap()
      .then((response) => {
        setChangePassword({
          currentPassword: "",
          newPassword: "",
        });
        toastMessage(response.success, "success");
      })
      .catch((error) => {
        toastMessage(error.message, "error");
      });
  };


  const onClickBack = () => {
    navigate(-1);
  };

  return (
    <div className="bg-white admin-dashboard-card-container p-3 mb-3">
      <Helmet>
        <title>My-Profile</title>
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6">
            <span className="dashboard-content-title">User Profile</span>
          </div>
        </div>
      </div>


      <div className="d-flex flex-column align-items-center my-4 details-page-container">
        <div className="d-flex flex-column details-header">Change Password</div>

        <div className="container p-3">
          <div className="row gy-3 d-flex align-items-end">
            <div className="kyc-fields-container col-12  col-md-4">
              <label
                htmlFor="currentPassword"
                className="kyc-label-text form-label "
              >
                Current Password
              </label>
              <input
                onChange={onChangeChangePassword}
                id="currentPassword"
                name="currentPassword"
                type="text"
                className="kyc-input-field form-control"
                value={changePassword.currentPassword}
              />
            </div>

            <div className="kyc-fields-container col-12  col-md-4">
              <label
                htmlFor="newPassword"
                className="kyc-label-text form-label "
              >
                New Password
              </label>
              <input
                onChange={onChangeChangePassword}
                id="newPassword"
                name="newPassword"
                type="text"
                className="kyc-input-field form-control"
                value={changePassword.newPassword}
              />
            </div>

            <div className="kyc-fields-container col-12 col-md-4 text-center">
              <button
                onClick={onClickUpdatePassword}
                className="btn btn-primary px-5"
              >
                {resetPassword.isLoading ? (
                  <span className="loader mx-auto"></span>
                ) : (
                  "Update"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-end">
        <button onClick={onClickBack} className="btn btn-warning px-5">
          Back
        </button>
      </div>
    </div>
  );
};

export default UnderwriterMyProfile;
