import { useState } from "react";
import RiskAnalysis from "./RiskAnalysis";
import ScoreInfo from "./ScoreInfo";
import ITRInfo from "./ITRInfo";
import CompanyKYC from "./CompanyKYC";
import GSTInfo from "./GSTInfo";
import BankInfo from "./BankInfo";
import PersonalKYC from "./PersonalKYC";
import BorrowerInfo from "./BorrowerInfo";
import BusinessInfo from "./BusinessInfo";
import AdditionalDocument from "./AdditionalDocument";

const ScoreCardDetails = ({ scoreCardByEmailIdData, scoreCardDetails }) => {
  console.log("scoreCardByEmailIdData", scoreCardByEmailIdData);

  return (
    <div className="d-flex flex-column gap-3">
      <div className="container">
        <div className="row">
          <div className="col-4">
            <button className="modal-export-pdf-button d-flex align-items-center gap-2">
              Export to PDF
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 25"
              >
                <path
                  id="Path_541"
                  data-name="Path 541"
                  d="M207.639,120.656l3.949-4.032a1.18,1.18,0,0,0-1.667-1.671l-1.352,1.361c-.308.31-.557.208-.557-.227V102.8a1.178,1.178,0,1,0-2.356,0v13.291c0,.434-.249.537-.557.227l-1.352-1.361a1.18,1.18,0,0,0-1.667,1.671l3.908,4.026a1.145,1.145,0,0,0,1.651.005Zm-8.464-6.96a1.379,1.379,0,0,0,1.95-1.95,8.074,8.074,0,1,1,11.419,0,1.379,1.379,0,0,0,1.95,1.95,10.832,10.832,0,1,0-15.318,0Z"
                  transform="translate(-196.003 -95.205)"
                  fill="#fff"
                  fillRule="evenodd"
                />
              </svg>
            </button>
          </div>
          <div className="col-7 col-md-4 text-center">
            <span className="fs-4 fw-bold modal-title-text">
              Score Card Details
            </span>
          </div>
          <div className="col-md-4 col-1"></div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          {/* LEFT PANEL */}
          <div className="col-12 col-lg-4">
            <div className="row g-3 h-100">
              {/* RISK ANALYSIS */}
              <div className="d-flex flex-column admin-cam-modal-container flex-grow-1">
                <div className="d-flex gap-2 align-items-center  w-100">
                  <div className="d-flex align-items-center flex-grow-1 mx-4">
                    <hr className="admin-user-modal-title-line" />
                  </div>
                  <span className="fs-4 fw-semibold modal-title-text">
                    Risk Analysis
                  </span>
                  <div className="d-flex align-items-center flex-grow-1 mx-4">
                    <hr className="admin-user-modal-title-line" />
                  </div>
                </div>
                <div className="px-3 h-100">
                  <RiskAnalysis
                    scoreCardDetails={scoreCardDetails.data.risk_analysis}
                  />
                </div>
              </div>

              {/* ITR INFO */}
              <div className="d-flex flex-column admin-cam-modal-container flex-grow-1">
                <div className="d-flex gap-2 align-items-center  w-100">
                  <div className="d-flex align-items-center flex-grow-1 mx-4">
                    <hr className="admin-user-modal-title-line" />
                  </div>
                  <span className="fs-4 fw-semibold modal-title-text">
                    ITR Info
                  </span>
                  <div className="d-flex align-items-center flex-grow-1 mx-4">
                    <hr className="admin-user-modal-title-line" />
                  </div>
                </div>
                <div className="px-3 h-100">
                  <ITRInfo scoreCardDetails={scoreCardDetails.data.itr} />
                </div>
              </div>

              {/* PERSONAL KYC */}
              <div className="d-flex flex-column admin-cam-modal-container flex-grow-1">
                <div className="d-flex gap-2 align-items-center  w-100">
                  <div className="d-flex align-items-center flex-grow-1 mx-4">
                    <hr className="admin-user-modal-title-line" />
                  </div>
                  <span className="fs-4 fw-semibold modal-title-text">
                    Personal KYC
                  </span>
                  <div className="d-flex align-items-center flex-grow-1 mx-4">
                    <hr className="admin-user-modal-title-line" />
                  </div>
                </div>
                <div className="px-3 h-100">
                  <PersonalKYC
                    scoreCardDetails={scoreCardByEmailIdData?.data}
                  />
                </div>
              </div>

              {/* COMPANY KYC */}
              <div className="d-flex flex-column admin-cam-modal-container flex-grow-1">
                <div className="d-flex gap-2 align-items-center  w-100">
                  <div className="d-flex align-items-center flex-grow-1 mx-4">
                    <hr className="admin-user-modal-title-line" />
                  </div>
                  <span className="fs-4 fw-semibold modal-title-text">
                    Company KYC
                  </span>
                  <div className="d-flex align-items-center flex-grow-1 mx-4">
                    <hr className="admin-user-modal-title-line" />
                  </div>
                </div>
                <div className="px-3 h-100">
                  <CompanyKYC scoreCardDetails={scoreCardDetails.data.mca} />
                </div>
              </div>
            </div>
          </div>
          {/* RIGHT PANEL */}
          <div className="col-12 col-lg-8">
            <div className="row g-3">
              {/* SCORE INFO - GRAPH */}
              <div className="col-12">
                <div className="d-flex flex-column align-items-center admin-cam-modal-container">
                  <div className="d-flex gap-2 align-items-center w-100">
                    <div className="d-flex align-items-center flex-grow-1 mx-4">
                      <hr className="admin-user-modal-title-line" />
                    </div>
                    <span className="fs-4 fw-semibold modal-title-text">
                      Score Info
                    </span>
                    <div className="d-flex align-items-center flex-grow-1 mx-4">
                      <hr className="admin-user-modal-title-line" />
                    </div>
                  </div>
                  <div>
                    <ScoreInfo
                      scoreCardDetails={scoreCardDetails.data.score_card}
                    />
                    <h4 className="text-center text-info fw-semibold">
                      Equifax Score
                    </h4>
                  </div>
                </div>
              </div>

              {/* BORROWER INFO */}
              <div className="col-12 col-lg-6">
                <div className="d-flex flex-column align-items-center admin-cam-modal-container">
                  <div className="d-flex gap-2 align-items-center  w-100">
                    <div className="d-flex align-items-center flex-grow-1 mx-4">
                      <hr className="admin-user-modal-title-line" />
                    </div>
                    <span className="fs-4 fw-semibold modal-title-text">
                      Borrower Info
                    </span>
                    <div className="d-flex align-items-center flex-grow-1 mx-4">
                      <hr className="admin-user-modal-title-line" />
                    </div>
                  </div>
                  <div>
                    <BorrowerInfo
                      scoreCardDetails={scoreCardByEmailIdData?.data}
                    />
                  </div>
                </div>
              </div>

              {/* BUSINESS INFO */}
              <div className="col-12 col-lg-6">
                <div className="d-flex flex-column admin-cam-modal-container">
                  <div className="d-flex gap-2 align-items-center  w-100">
                    <div className="d-flex align-items-center flex-grow-1 mx-4">
                      <hr className="admin-user-modal-title-line" />
                    </div>
                    <span className="fs-4 fw-semibold modal-title-text">
                      Business Info
                    </span>
                    <div className="d-flex align-items-center flex-grow-1 mx-4">
                      <hr className="admin-user-modal-title-line" />
                    </div>
                  </div>
                  <div className="px-3 h-100">
                    <BusinessInfo
                      scoreCardDetails={scoreCardByEmailIdData?.data}
                    />
                  </div>
                </div>
              </div>

              {/* GST INFO */}
              <div className="col-12 col-lg-6">
                <div className="d-flex flex-column admin-cam-modal-container">
                  <div className="d-flex gap-2 align-items-center  w-100">
                    <div className="d-flex align-items-center flex-grow-1 mx-4">
                      <hr className="admin-user-modal-title-line" />
                    </div>
                    <span className="fs-4 fw-semibold modal-title-text">
                      GST Info
                    </span>
                    <div className="d-flex align-items-center flex-grow-1 mx-4">
                      <hr className="admin-user-modal-title-line" />
                    </div>
                  </div>
                  <div className="px-3 h-100">
                    <GSTInfo
                      scoreCardDetails={scoreCardDetails.data.gst_data}
                    />
                  </div>
                </div>
              </div>

              {/* BANK INFO */}
              <div className="col-12 col-lg-6 h-100">
                <div className="d-flex flex-column admin-cam-modal-container h-100">
                  <div className="d-flex gap-2 align-items-center  w-100">
                    <div className="d-flex align-items-center flex-grow-1 mx-4">
                      <hr className="admin-user-modal-title-line" />
                    </div>
                    <span className="fs-4 fw-semibold modal-title-text">
                      Bank Info
                    </span>
                    <div className="d-flex align-items-center flex-grow-1 mx-4">
                      <hr className="admin-user-modal-title-line" />
                    </div>
                  </div>
                  <div className="px-3 h-100">
                    <BankInfo
                      scoreCardDetails={scoreCardDetails.data.bank_data}
                    />
                  </div>
                </div>
              </div>

              {/* ADDITIONAL DOCUMENT */}
              <div className="col-12">
                <div className="d-flex flex-column admin-cam-modal-container">
                  <div className="d-flex gap-2 align-items-center  w-100">
                    <div className="d-flex align-items-center flex-grow-1 mx-4">
                      <hr className="admin-user-modal-title-line" />
                    </div>
                    <span className="fs-4 fw-semibold modal-title-text">
                      Additional Document
                    </span>
                    <div className="d-flex align-items-center flex-grow-1 mx-4">
                      <hr className="admin-user-modal-title-line" />
                    </div>
                  </div>
                  <div className="px-3 h-100">
                    <AdditionalDocument
                      scoreCardDetails={scoreCardByEmailIdData?.data}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScoreCardDetails;
