import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../utilities/api";
import { DOC_URL,BASE_URL } from "../../utilities/config";
import { GiConsoleController } from "react-icons/gi";

const initialState = {
  /* SELLER KYC VERIFICATION */
  sellerKYCVerification: {
    data: null,
    error: null,
    isLoading: false,
  },

  /* INVENTORY */
  inventoryTableData: {
    data: null,
    error: null,
    isLoading: false,
  },

  inventoryEditTableData: {
    data: null,
    error: null,
    isLoading: false,
  },

  inventoryAddTableData: {
    data: null,
    error: null,
    isLoading: false,
  },

  inventoryDeleteTableData: {
    data: null,
    error: null,
    isLoading: false,
  },

  inventoryFileData: {
    data: null,
    error: null,
    isLoading: false,
  },
  /* MATERIAL REQUEST */
  materialRequestTableData: {
    data: null,
    error: null,
    isLoading: false,
  },

  materialRequestByIdData: {
    data: null,
    error: null,
    isLoading: false,
  },

  materialRequestRejectByIdData: {
    data: null,
    error: null,
    isLoading: false,
  },
  materialRequestAddByIdData: {
    data: null,
    error: null,
    isLoading: false,
  },
  /* PROFORMA INVOICE */
  proformaInvoiceTableData: {
    data: null,
    error: null,
    isLoading: false,
  },

  proformaInvoiceByIdData: {
    data: null,
    error: null,
    isLoading: false,
  },

  /* PURCHASE ORDER INVOICE */
  digitalPurchaseOrderTableData: {
    data: null,
    error: null,
    isLoading: false,
  },
  manualPurchaseOrderTableData: {
    data: null,
    error: null,
    isLoading: false,
  },
  userRetailerById: {
    data: null,
    error: null,
    isLoading: false,
  },

  addManualPO: {
    data: null,
    error: null,
    isLoading: false,
  },

  digitalPurchaseOrderDataById: {
    data: null,
    error: null,
    isLoading: false,
  },

  generateBoeData: {
    data: null,
    error: null,
    isLoading: false,
  },

  /* INVOICE */
  digitalInvoiceTableData: {
    data: null,
    error: null,
    isLoading: false,
  },
  manualInvoiceTableData: {
    data: null,
    error: null,
    isLoading: false,
  },
  bulkInvoiceTableData: {
    data: null,
    error: null,
    isLoading: false,
  },
  manualSendForDiscountingData: {
    data: null,
    error: null,
    isLoading: false,
  },

  digitalInvoiceDataById: {
    data: null,
    error: null,
    isLoading: false,
  },

  addManualFilesUploadData: {
    data: null,
    error: null,
    isLoading: false,
  },
  uploadBatchInvoiceData: {
    data: null,
    error: null,
    isLoading: false,
  },

  /* PAYMENT RECONCILIATION */
  paymentReconciliationTableData: {
    data: null,
    error: null,
    isLoading: false,
  },

  /* COUNTER PARTY */
  counterPartyApproveData: {
    data: null,
    error: null,
    isLoading: false,
  },

  counterPartyDeclineData: {
    data: null,
    error: null,
    isLoading: false,
  },

  counterPartyUserLimitData: {
    data: null,
    error: null,
    isLoading: false,
  },

  counterPartyUserTableData: {
    data: null,
    error: null,
    isLoading: false,
  },

  manualApprovedPOData: {
    data: null,
    error: null,
    isLoading: false,
  },
  manualPOData: {
    data: null,
    error: null,
    isLoading: false,
  },
  gstVerification: {
    data: null,
    error: null,
    isLoading: false,
  },
  LenderInvoice: {
    data: null,
    error: null,
    isLoading: false,
  },
  addManualInvoice: {
    data: null,
    error: null,
    isLoading: false,
  },
  approvedRetailer: {
    data: null,
    error: null,
    isLoading: false,
  },
  acceptPerforma: {
    data: null,
    error: null,
    isLoading: false,
  },
  fetchManualInvoiceData: { data: null, isLoading: false, error: null },
  submitEditManualInvoice: { data: null, isLoading: false, error: null },
  deleteInvoiceFile: { data: null, isLoading: false, error: null },
  downloadBatchExcel: { data: null, isLoading: false, error: null },


  dashboardData:{data:null,isLoading:false,error:null},
  LiquidationDueData:{data:null,isLoading:false,error:null}

};

export const getLiquidationDue = createAsyncThunk(
  "get/liquidation_due",
  async () => {
    try {
      const url = "analytics/seller/liquidation_due";
      const response = await api.get(url, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const getDashboardData = createAsyncThunk(
  "get/getDashboardData",
  async () => {
    try {
      const url =BASE_URL +
         "analytics/seller/anchor_dashboard" ;
       
      /**
       * @type { import("axios").AxiosRequestHeaders }
       */
      const headers = {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      };

      const { data } = await api.get(url, { headers });

      return data;
    } catch (err) {
      throw new Error(err?.response?.data?.error);
    }
  }
);



export const downloadBatchExcel = createAsyncThunk(
  "get/batchExcelFile",
  async ({ invoiceNumber, sellerId }) => {
    try {
      const url =
        DOC_URL +
        "swarm/docs/" +
        sellerId +
        "/" +
        invoiceNumber +
        "/true/invoiceExcelFileUrl";

      /**
       * @type { import("axios").AxiosRequestHeaders }
       */
      const headers = {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      };

      const { data } = await api.get(url, { headers });

      return data;
    } catch (err) {
      throw new Error(err?.response?.data?.error);
    }
  }
);

export const deleteInvoiceFile = createAsyncThunk(
  "delete/deleteInvoiceFile",
  async (body) => {
    try {
      const url = "user/delete_invoice_file";

      console.log("delete_invoice_file", body);

      /**
       * @type { import("axios").AxiosRequestHeaders }
       */
      const headers = {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      };

      const { data } = await api.delete(url, { headers, data: body });

      return data;
    } catch (err) {
      throw new Error(err?.response?.data?.error);
    }
  }
);

export const submitEditManualInvoice = createAsyncThunk(
  "put/submitEditManualInvoice",
  async (body) => {
    try {
      const url = "user/edit_manual_invoice";

      /**
       * @type { import("axios").AxiosRequestHeaders }
       */
      const headers = {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      };

      const { data } = await api.put(url, body, { headers });

      return data;
    } catch (err) {
      throw new Error(err?.response?.data?.error);
    }
  }
);

export const fetchManualInvoiceData = createAsyncThunk(
  "get/manualInvoiceData",
  async (invoiceNumber) => {
    try {
      const url = "seller/invoice/manual/" + invoiceNumber;

      /**
       * @type { import("axios").AxiosRequestHeaders }
       */
      const headers = {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      };

      const { data } = await api.get(url, { headers });

      return data;
    } catch (err) {
      throw new Error(err?.response?.data?.error);
    }
  }
);

/* SELLER KYC VERIFICATION */
export const updateSellerKYCVerification = createAsyncThunk(
  "get/sellerKYCVerification",
  async (body) => {
    try {
      const url = `seller/kyc`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.post(url, body, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

/* INVENTORY */
export const getInventoryTable = createAsyncThunk(
  "get/inventoryTable",
  async () => {
    try {
      const url = `seller/inventory`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.get(url, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

export const editInventoryTable = createAsyncThunk(
  "put/inventoryTable",
  async (body) => {
    try {
      const url = `seller/inventory`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.put(url, body, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

export const addInventoryDetails = createAsyncThunk(
  "post/addinventory",
  async (body) => {
    try {
      const url = `seller/inventory/item`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.post(url, body, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

export const addInventoryFileDetails = createAsyncThunk(
  "post/addFileInventory",
  async (body) => {
    try {
      const url = `seller/inventory`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.post(url, body, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

export const deleteInventoryDetails = createAsyncThunk(
  "delete/deleteinventory",
  async (body) => {
    console.log("body : ", body);
    try {
      const url = `seller/inventory`;
      console.log("token : ", sessionStorage.getItem("token"));
      // const head = {
      //  headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` }
      // };

      const headers = {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      };
      console.log("head : ", headers);
      // const response = await api.delete(url, body, head);
      const response = await api.delete(url, { data: body, headers });
      console.log("response : ", response);
      return response.data;
    } catch (error) {
      console.log("error : ", error);
      throw new Error(error.response.data.error);
    }
  }
);

/* MATERIAL REQUEST */
export const getMaterialRequestTable = createAsyncThunk(
  "get/materialRequestTable",
  async (tableData) => {
    try {
      const url = `seller/mr?${tableData}`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      console.log(url);
      const response = await api.get(url, head);
      console.log(response.data);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

export const getMaterialRequestById = createAsyncThunk(
  "get/materialRequestById",
  async ({ buyerId, mrId }) => {
    try {
      const url = `seller/mr/${buyerId}/${mrId}`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.get(url, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

export const updateMaterialRequestRejectById = createAsyncThunk(
  "update/materialRequestrejectById",
  async (body) => {
    try {
      const url = `seller/reject_mr`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.post(url, body, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

export const updateMaterialRequestAddById = createAsyncThunk(
  "udpate/materialRequestAddById",
  async (body) => {
    try {
      const url = `seller/add`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.post(url, body, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

/* PROFORMA INVOICE */
export const getProformaInvoiceTable = createAsyncThunk(
  "get/proformaInvoiceTable",
  async (tableData) => {
    try {
      const url = `seller/proforma?offset=${tableData.tableQueryParams.tableOffset}&limit=${tableData.tableQueryParams.tableLimit}&sort=${tableData.tableQueryParams.tableSort}&order=${tableData.tableQueryParams.tableOrder}`;
      const searchUrl = `seller/proforma?offset=${tableData.tableQueryParams.tableOffset}&limit=${tableData.tableQueryParams.tableLimit}&sort=${tableData.tableQueryParams.tableSort}&order=${tableData.tableQueryParams.tableOrder}&search=${tableData.tableQueryParams.search}`;
      const activeUrl =
        tableData.tableQueryParams.search === "" ? url : searchUrl;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.get(activeUrl, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

export const getProformaInvoiceById = createAsyncThunk(
  "get/proformaInvoiceById",
  async ({ sellerId, proformaId }) => {
    try {
      const url = `seller/proforma/${sellerId}/${proformaId}`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.get(url, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

/* PURCHASE ORDER INVOICE */
export const getDigitalPurchaseOrderTable = createAsyncThunk(
  "get/digitalPurchaseOrderTable",
  async (tableData) => {
    try {
      var url = `seller/po?offset=${tableData.tableQueryParams.tableOffset}&limit=${tableData.tableQueryParams.tableLimit}`;
      // &sort=${tableData.tableQueryParams.tableSort}&order=${tableData.tableQueryParams.tableOrder}`;

      //if there is a search Input for Purchase id
      if (tableData.search.length > 0) {
        url = `seller/po?offset=${tableData.tableQueryParams.tableOffset}&limit=${tableData.tableQueryParams.tableLimit}&search=${tableData.search}`;
      }
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.get(url, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);
export const getManualPurchaseOrderTable = createAsyncThunk(
  "get/manualPurchaseOrderTable",
  async (tableData) => {
    try {
      var url = `seller/manualpo?offset=${tableData.tableQueryParams.tableOffset}&limit=${tableData.tableQueryParams.tableLimit}`;
      // &sort=${tableData.tableQueryParams.tableSort}&order=${tableData.tableQueryParams.tableOrder}`;

      if (tableData.search.length > 0) {
        url = `seller/manualpo?offset=${tableData.tableQueryParams.tableOffset}&limit=${tableData.tableQueryParams.tableLimit}&search=${tableData.search}`;
      }

      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.get(url, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);
export const getUserRetailerById = createAsyncThunk(
  "get/userRetailerById",
  async () => {
    try {
      const retailerId = sessionStorage.getItem("customerId");
      const url = `user/retailer/${retailerId}`;
      const head = {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      };
      const response = await api.get(url, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);
export const getApprovedRetailerList = createAsyncThunk(
  "get/seller/approved_retailer/",
  async () => {
    try {
      const sellerId = sessionStorage.getItem("customerId");
      const url = `seller/approved_retailer/${sellerId}`;
      const head = {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      };
      const response = await api.get(url, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

export const updateAddManualPO = createAsyncThunk(
  "update/addManualPO",
  async (body) => {
    try {
      const url = `seller/manualpo`;
      const head = {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          "content-type": "multipart/form-data",
        },
      };
      const response = await api.post(url, body, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);
export const getDigitalPurchaseOrderById = createAsyncThunk(
  "get/digitalPurchaseOrderById",
  async ({ buyerId, proformaId }) => {
    try {
      const url = `seller/po/${buyerId}/${proformaId}`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.get(url, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

export const updateGenerateBoe = createAsyncThunk(
  "update/generateBoe",
  async (body) => {
    try {
      const url = `seller/boe`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };

      const response = await api.post(url, body, head);
      console.log("response", response);
      return response.data;
    } catch (error) {
      console.log("error", error);
      throw new Error(error.response.data.error);
    }
  }
);

/* INVOICE */
export const getDigitalInvoiceTable = createAsyncThunk(
  "get/digitalInvoiceTable",
  async (tableData) => {
    try {
      console.log("----------------------------------------", tableData);

      let url = "";

        if (tableData?.tableQueryParams?.search.length > 0) {
          url = `seller/invoice?offset=${tableData.tableQueryParams.tableOffset}&limit=${tableData.tableQueryParams.tableLimit}&search=${tableData.tableQueryParams.search}`;
      } else {
        // url = `seller/invoice?offset=${tableData.tableQueryParams.tableOffset}&limit=${tableData.tableQueryParams.tableLimit}&sort=${tableData.tableQueryParams.tableSort}&order=${tableData.tableQueryParams.tableOrder}`;
        url = `seller/invoice?offset=${tableData.tableQueryParams.tableOffset}&limit=${tableData.tableQueryParams.tableLimit}`;
      }

      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.get(url, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

export const getManuaInvoiceTable = createAsyncThunk(
  "get/manuaInvoiceTable",
  async (tableData) => {
    try {
      let url = "";

        if (tableData?.tableQueryParams?.search.length > 0) {
          url = `seller/invoice/manual?offset=${tableData.tableQueryParams.tableOffset}&limit=${tableData.tableQueryParams.tableLimit}&search=${tableData.tableQueryParams.search}`;
      } else  {
        url = `seller/invoice/manual?offset=${tableData.tableQueryParams.tableOffset}&limit=${tableData.tableQueryParams.tableLimit}`;
      }
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.get(url, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

export const getBulkInvoiceTable = createAsyncThunk(
  "get/bulkInvoiceTable",
  async (tableData) => {
    try {
      let url = "";

      // if (tableData?.tabQuerySearch) {
        if (tableData?.tableQueryParams?.search.length > 0) {
          url = `seller/bulk-invoice?offset=${tableData.tableQueryParams.tableOffset}&limit=${tableData.tableQueryParams.tableLimit}&search=${tableData.tableQueryParams.search}`;
        // } else {
        //   url = `seller/bulk-invoice?offset=${tableData.tabQuerySearch.tableOffset}&limit=${tableData.tabQuerySearch.tableLimit}`;
        // }
      } else {
        url = `seller/bulk-invoice?offset=${tableData.tableQueryParams.tableOffset}&limit=${tableData.tableQueryParams.tableLimit}`;
      }
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.get(url, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

export const updateManualSendForDiscounting = createAsyncThunk(
  "update/manuaSendForDiscounting",
  async (body) => {
    try {
      const url = "seller/invoiceDiscount";
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.post(url, body, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);
export const getDigitalInvoiceDetailsById = createAsyncThunk(
  "get/digitalInvoiceDetailsById",
  async ({ sellerId, invoiceId }) => {
    try {
      const url = `seller/invoice/${sellerId}/${invoiceId}`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.get(url, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

export const updateAddManualFilesUpload = createAsyncThunk(
  "update/addManualFilesUpload",
  async (body) => {
    try {
      const url = `user/file_upload`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.post(url, body, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

export const updateAddBatchManualFilesUpload = createAsyncThunk(
  "update/addBatchManualFilesUpload",
  async (body) => {
    try {
      const url = `user/bulk-manual-invoice-upload`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.post(url, body, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

export const getManualApprovePO = createAsyncThunk(
  "get/manualApprovedPO",
  async () => {
    try {
      const url = `seller/manualapprovedpo`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.get(url, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

export const getManualPO = createAsyncThunk("get/manualPO", async () => {
  try {
    const url = `seller/get-all-manual-po`;
    const head = {
      headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
    };
    const response = await api.get(url, head);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
});

/* PAYMENT RECONCILIATION */
export const getPaymentReconciliationTable = createAsyncThunk(
  "get/paymentReconciliation",
  async () => {
    try {
      const url = `seller/reconciliation/`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.get(url, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

/* COUNTER PARTY */
export const updateCountryPartyApprove = createAsyncThunk(
  "update/countryPatyApprove",
  async (body) => {
    try {
      const url = `user/approveretailer`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.put(url, body, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

export const updateCountryPartyDecline = createAsyncThunk(
  "update/countryPatryDecline",
  async (body) => {
    try {
      const url = `user/rejectretailer`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.put(url, body, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

export const getCountryPartyUserLimit = createAsyncThunk(
  "get/countryPatyUserLimit",
  async () => {
    try {
      const userId = sessionStorage.getItem("customerId");
      const url = `user/viewmaxlimit/${userId}`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.get(url, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

export const getCountryPartyUserDetails = createAsyncThunk(
  "get/countryPatyUserDetails",
  async () => {
    try {
      const userId = sessionStorage.getItem("customerId");
      const url = `user/retailer/${userId}`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.get(url, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

export const gstVerification = createAsyncThunk(
  "get/verifyGst",
  async (gstValue) => {
    const url = `user/verify_gst/${gstValue}`;
    const head = {
      headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
    };

    try {
      const { data } = await api.get(url, head);
      return data;
    } catch (err) {
      throw new Error(err?.response?.data?.error);
    }
  }
);

export const postAddManualInvoice = createAsyncThunk(
  "post/add/manualInvoice",
  async (body) => {
    const url = `user/file_upload`;
    const head = {
      headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
    };

    try {
      const { data } = await api.post(url, body, head);
      return data;
    } catch (err) {
      throw new Error(err?.response?.data?.error);
    }
  }
);

export const ApproveLender = createAsyncThunk("get/approve", async (body) => {
  const url = `banker/approvemanualpo`;
  const head = {
    headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
  };

  try {
    const { data } = await api.put(url, body, head);
    return data;
  } catch (err) {
    throw new Error(err?.response?.data?.error);
  }
});

export const acceptPerformaInvoice = createAsyncThunk(
  "accept/performa",
  async (body) => {
    const url = `seller/proforma`;
    const head = {
      headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
    };

    try {
      const { data } = await api.put(url, body, head);
      return data;
    } catch (err) {
      throw new Error(err?.response?.data?.error);
    }
  }
);

/* ANCHOR SLICE */
const anchorSlice = createSlice({
  name: "anchor",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      /* ***SELLER KYC VERIFICATION*** */

      .addCase(updateSellerKYCVerification.pending, (state, action) => {
        state.sellerKYCVerification.isLoading = true;
      })
      .addCase(updateSellerKYCVerification.fulfilled, (state, action) => {
        state.sellerKYCVerification.data = action.payload;
        state.sellerKYCVerification.isLoading = false;
      })
      .addCase(updateSellerKYCVerification.rejected, (state, action) => {
        state.sellerKYCVerification.error = action.error.message;
        state.sellerKYCVerification.isLoading = false;
      })
      /* MATERIAL REQUEST */
      /* GET MATERIAL REQUEST TABLE */
      .addCase(getMaterialRequestTable.pending, (state, action) => {
        state.materialRequestTableData.isLoading = true;
      })
      .addCase(getMaterialRequestTable.fulfilled, (state, action) => {
        state.materialRequestTableData.data = action.payload;
        state.materialRequestTableData.isLoading = false;
      })
      .addCase(getMaterialRequestTable.rejected, (state, action) => {
        state.materialRequestTableData.error = action.error.message;
        state.materialRequestTableData.isLoading = false;
      })

      /* GET MATERIAL REQUEST BY id */
      .addCase(getMaterialRequestById.pending, (state, action) => {
        state.materialRequestByIdData.isLoading = true;
      })
      .addCase(getMaterialRequestById.fulfilled, (state, action) => {
        state.materialRequestByIdData.data = action.payload;
        state.materialRequestByIdData.isLoading = false;
      })
      .addCase(getMaterialRequestById.rejected, (state, action) => {
        state.materialRequestByIdData.error = action.error.message;
        state.materialRequestByIdData.isLoading = false;
      })

      /* REJECT MATERIAL REQUEST BY id */
      .addCase(updateMaterialRequestRejectById.pending, (state, action) => {
        state.materialRequestRejectByIdData.isLoading = true;
      })
      .addCase(updateMaterialRequestRejectById.fulfilled, (state, action) => {
        state.materialRequestRejectByIdData.data = action.payload;
        state.materialRequestRejectByIdData.isLoading = false;
      })
      .addCase(updateMaterialRequestRejectById.rejected, (state, action) => {
        state.materialRequestRejectByIdData.error = action.error.message;
        state.materialRequestRejectByIdData.isLoading = false;
      })

      /* ADD MATERIAL REQUEST BY id */
      .addCase(updateMaterialRequestAddById.pending, (state, action) => {
        state.materialRequestAddByIdData.isLoading = true;
      })
      .addCase(updateMaterialRequestAddById.fulfilled, (state, action) => {
        state.materialRequestAddByIdData.data = action.payload;
        state.materialRequestAddByIdData.isLoading = false;
      })
      .addCase(updateMaterialRequestAddById.rejected, (state, action) => {
        state.materialRequestAddByIdData.error = action.error.message;
        state.materialRequestAddByIdData.isLoading = false;
      })

      /* INVENTORY */
      /* GET INVENTORY TABLE */
      .addCase(getInventoryTable.pending, (state, action) => {
        state.inventoryTableData.isLoading = true;
      })
      .addCase(getInventoryTable.fulfilled, (state, action) => {
        state.inventoryTableData.data = action.payload;
        state.inventoryTableData.isLoading = false;
      })
      .addCase(getInventoryTable.rejected, (state, action) => {
        state.inventoryTableData.error = action.error.message;
        state.inventoryTableData.isLoading = false;
      })

      /* EDIT INVENTORY TABLE */
      .addCase(editInventoryTable.pending, (state, action) => {
        state.inventoryEditTableData.isLoading = true;
      })
      .addCase(editInventoryTable.fulfilled, (state, action) => {
        state.inventoryEditTableData.data = action.payload;
        state.inventoryEditTableData.isLoading = false;
      })
      .addCase(editInventoryTable.rejected, (state, action) => {
        state.inventoryEditTableData.error = action.error.message;
        state.inventoryEditTableData.isLoading = false;
      })

      /* ADD INVENTORY TABLE */
      .addCase(addInventoryDetails.pending, (state, action) => {
        state.inventoryAddTableData.isLoading = true;
      })
      .addCase(addInventoryDetails.fulfilled, (state, action) => {
        state.inventoryAddTableData.data = action.payload;
        state.inventoryAddTableData.isLoading = false;
      })
      .addCase(addInventoryDetails.rejected, (state, action) => {
        state.inventoryAddTableData.error = action.error.message;
        state.inventoryAddTableData.isLoading = false;
      })

      /* ADD INVENTORY FILE */
      .addCase(addInventoryFileDetails.pending, (state, action) => {
        state.inventoryFileData.isLoading = true;
      })
      .addCase(addInventoryFileDetails.fulfilled, (state, action) => {
        state.inventoryFileData.data = action.payload;
        state.inventoryFileData.isLoading = false;
      })
      .addCase(addInventoryFileDetails.rejected, (state, action) => {
        state.inventoryFileData.error = action.error.message;
        state.inventoryFileData.isLoading = false;
      })

      /* DELETE INVENTORY TABLE */
      .addCase(deleteInventoryDetails.pending, (state, action) => {
        state.inventoryDeleteTableData.isLoading = true;
      })
      .addCase(deleteInventoryDetails.fulfilled, (state, action) => {
        state.inventoryDeleteTableData.data = action.payload;
        state.inventoryDeleteTableData.isLoading = false;
      })
      .addCase(deleteInventoryDetails.rejected, (state, action) => {
        state.inventoryDeleteTableData.error = action.error.message;
        state.inventoryDeleteTableData.isLoading = false;
      })

      /* PROFORMA INVOICE */
      /* GET PROFORMA INVOICE TABLE */
      .addCase(getProformaInvoiceTable.pending, (state, action) => {
        state.proformaInvoiceTableData.isLoading = true;
      })
      .addCase(getProformaInvoiceTable.fulfilled, (state, action) => {
        state.proformaInvoiceTableData.data = action.payload;
        state.proformaInvoiceTableData.isLoading = false;
      })
      .addCase(getProformaInvoiceTable.rejected, (state, action) => {
        state.proformaInvoiceTableData.error = action.error.message;
        state.proformaInvoiceTableData.isLoading = false;
      })

      /* GET PROFORMA INVOICE DETAILS BY ID */
      .addCase(getProformaInvoiceById.pending, (state, action) => {
        state.proformaInvoiceByIdData.isLoading = true;
      })
      .addCase(getProformaInvoiceById.fulfilled, (state, action) => {
        state.proformaInvoiceByIdData.data = action.payload;
        state.proformaInvoiceByIdData.isLoading = false;
      })
      .addCase(getProformaInvoiceById.rejected, (state, action) => {
        state.proformaInvoiceByIdData.error = action.error.message;
        state.proformaInvoiceByIdData.isLoading = false;
      })

      /* GET MANUAL APPROVED PO */
      .addCase(getManualApprovePO.pending, (state, action) => {
        state.manualApprovedPOData.isLoading = true;
      })
      .addCase(getManualApprovePO.fulfilled, (state, action) => {
        state.manualApprovedPOData.data = action.payload;
        state.manualApprovedPOData.isLoading = false;
      })
      .addCase(getManualApprovePO.rejected, (state, action) => {
        state.manualApprovedPOData.error = action.error.message;
        state.manualApprovedPOData.isLoading = false;
      })

      .addCase(getManualPO.pending, (state, action) => {
        state.manualPOData.isLoading = true;
      })
      .addCase(getManualPO.fulfilled, (state, action) => {
        state.manualPOData.data = action.payload;
        state.manualPOData.isLoading = false;
      })
      .addCase(getManualPO.rejected, (state, action) => {
        state.manualPOData.error = action.error.message;
        state.manualPOData.isLoading = false;
      })

      /* PURCHASE ORDER INVOICE */
      /* GET DIGITAL PURCHASE ORDER TABLE */
      .addCase(getDigitalPurchaseOrderTable.pending, (state, action) => {
        state.digitalPurchaseOrderTableData.isLoading = true;
      })
      .addCase(getDigitalPurchaseOrderTable.fulfilled, (state, action) => {
        state.digitalPurchaseOrderTableData.data = action.payload;
        state.digitalPurchaseOrderTableData.isLoading = false;
      })
      .addCase(getDigitalPurchaseOrderTable.rejected, (state, action) => {
        state.digitalPurchaseOrderTableData.error = action.error.message;
        state.digitalPurchaseOrderTableData.isLoading = false;
      })
      /* GET MANUAL PURCHASE ORDER TABLE */
      .addCase(getManualPurchaseOrderTable.pending, (state, action) => {
        state.manualPurchaseOrderTableData.isLoading = true;
      })
      .addCase(getManualPurchaseOrderTable.fulfilled, (state, action) => {
        state.manualPurchaseOrderTableData.data = action.payload;
        state.manualPurchaseOrderTableData.isLoading = false;
      })
      .addCase(getManualPurchaseOrderTable.rejected, (state, action) => {
        state.manualPurchaseOrderTableData.error = action.error.message;
        state.manualPurchaseOrderTableData.isLoading = false;
      })
      /* GET USER RETAILER BY ID */
      .addCase(getUserRetailerById.pending, (state, action) => {
        state.userRetailerById.isLoading = true;
      })
      .addCase(getUserRetailerById.fulfilled, (state, action) => {
        state.userRetailerById.data = action.payload;
        state.userRetailerById.isLoading = false;
      })
      .addCase(getUserRetailerById.rejected, (state, action) => {
        state.userRetailerById.error = action.error.message;
        state.userRetailerById.isLoading = false;
      })
      /* ADD MANUAL PO */
      .addCase(updateAddManualPO.pending, (state, action) => {
        state.addManualPO.isLoading = true;
      })
      .addCase(updateAddManualPO.fulfilled, (state, action) => {
        state.addManualPO.data = action.payload;
        state.addManualPO.isLoading = false;
      })
      .addCase(updateAddManualPO.rejected, (state, action) => {
        state.addManualPO.error = action.error.message;
        state.addManualPO.isLoading = false;
      })
      /* GET DIGITAL PURCHASE ORDER BY ID */
      .addCase(getDigitalPurchaseOrderById.pending, (state, action) => {
        state.digitalPurchaseOrderDataById.isLoading = true;
      })
      .addCase(getDigitalPurchaseOrderById.fulfilled, (state, action) => {
        state.digitalPurchaseOrderDataById.data = action.payload;
        state.digitalPurchaseOrderDataById.isLoading = false;
      })
      .addCase(getDigitalPurchaseOrderById.rejected, (state, action) => {
        state.digitalPurchaseOrderDataById.error = action.error.message;
        state.digitalPurchaseOrderDataById.isLoading = false;
      })
      /* UPDTE GENERATE BOE */
      .addCase(updateGenerateBoe.pending, (state, action) => {
        state.generateBoeData.isLoading = true;
      })
      .addCase(updateGenerateBoe.fulfilled, (state, action) => {
        state.generateBoeData.data = action.payload;
        state.generateBoeData.isLoading = false;
      })
      .addCase(updateGenerateBoe.rejected, (state, action) => {
        state.generateBoeData.error = action.error.message;
        state.generateBoeData.isLoading = false;
      })
      /*  ***INVOICE*** */
      /* GET DIGITAL INVOICE TABLE */
      .addCase(getDigitalInvoiceTable.pending, (state, action) => {
        state.digitalInvoiceTableData.isLoading = true;
      })
      .addCase(getDigitalInvoiceTable.fulfilled, (state, action) => {
        state.digitalInvoiceTableData.data = action.payload;
        state.digitalInvoiceTableData.isLoading = false;
      })
      .addCase(getDigitalInvoiceTable.rejected, (state, action) => {
        state.digitalInvoiceTableData.error = action.error.message;
        state.digitalInvoiceTableData.isLoading = false;
      })
      /* GET MANUAL INVOICE TABLE */
      .addCase(getManuaInvoiceTable.pending, (state, action) => {
        state.manualInvoiceTableData.isLoading = true;
      })
      .addCase(getManuaInvoiceTable.fulfilled, (state, action) => {
        state.manualInvoiceTableData.data = action.payload;
        state.manualInvoiceTableData.isLoading = false;
      })
      .addCase(getManuaInvoiceTable.rejected, (state, action) => {
        state.manualInvoiceTableData.error = action.error.message;
        state.manualInvoiceTableData.isLoading = false;
      })

      .addCase(getBulkInvoiceTable.pending, (state, action) => {
        state.bulkInvoiceTableData.isLoading = true;
      })
      .addCase(getBulkInvoiceTable.fulfilled, (state, action) => {
        state.bulkInvoiceTableData.data = action.payload;
        state.bulkInvoiceTableData.isLoading = false;
      })
      .addCase(getBulkInvoiceTable.rejected, (state, action) => {
        state.bulkInvoiceTableData.error = action.error.message;
        state.bulkInvoiceTableData.isLoading = false;
      })
      /* UPDATE MANUAL SEND FOR DISCOUNTING */
      .addCase(updateManualSendForDiscounting.pending, (state, action) => {
        state.manualSendForDiscountingData.isLoading = true;
      })
      .addCase(updateManualSendForDiscounting.fulfilled, (state, action) => {
        state.manualSendForDiscountingData.data = action.payload;
        state.manualSendForDiscountingData.isLoading = false;
      })
      .addCase(updateManualSendForDiscounting.rejected, (state, action) => {
        state.manualSendForDiscountingData.error = action.error.message;
        state.manualSendForDiscountingData.isLoading = false;
      })
      /* GET INVOICE DETAILS BY ID */
      .addCase(getDigitalInvoiceDetailsById.pending, (state, action) => {
        state.digitalInvoiceDataById.isLoading = true;
      })
      .addCase(getDigitalInvoiceDetailsById.fulfilled, (state, action) => {
        state.digitalInvoiceDataById.data = action.payload;
        state.digitalInvoiceDataById.isLoading = false;
      })
      .addCase(getDigitalInvoiceDetailsById.rejected, (state, action) => {
        state.digitalInvoiceDataById.error = action.error.message;
        state.digitalInvoiceDataById.isLoading = false;
      })
      /* UPDATE ADD MANUAL INVOICE */
      .addCase(updateAddManualFilesUpload.pending, (state, action) => {
        state.addManualFilesUploadData.isLoading = true;
      })
      .addCase(updateAddManualFilesUpload.fulfilled, (state, action) => {
        state.addManualFilesUploadData.data = action.payload;
        state.addManualFilesUploadData.isLoading = false;
      })
      .addCase(updateAddManualFilesUpload.rejected, (state, action) => {
        state.addManualFilesUploadData.error = action.error.message;
        state.addManualFilesUploadData.isLoading = false;
      })

      .addCase(updateAddBatchManualFilesUpload.pending, (state, action) => {
        state.uploadBatchInvoiceData.isLoading = true;
      })
      .addCase(updateAddBatchManualFilesUpload.fulfilled, (state, action) => {
        state.uploadBatchInvoiceData.data = action.payload;
        state.uploadBatchInvoiceData.isLoading = false;
      })
      .addCase(updateAddBatchManualFilesUpload.rejected, (state, action) => {
        state.uploadBatchInvoiceData.error = action.error.message;
        state.uploadBatchInvoiceData.isLoading = false;
      })
      /* ***PAYMENT RECONCILIATION*** */
      .addCase(getPaymentReconciliationTable.pending, (state, action) => {
        state.paymentReconciliationTableData.isLoading = true;
      })
      .addCase(getPaymentReconciliationTable.fulfilled, (state, action) => {
        state.paymentReconciliationTableData.data = action.payload;
        state.paymentReconciliationTableData.isLoading = false;
      })
      .addCase(getPaymentReconciliationTable.rejected, (state, action) => {
        state.paymentReconciliationTableData.error = action.error.message;
        state.paymentReconciliationTableData.isLoading = false;
      })

      /* COUNTER PARTY */
      /* UPDATE COUNTER PARTY APPROVE */
      .addCase(updateCountryPartyApprove.pending, (state, action) => {
        state.counterPartyApproveData.isLoading = true;
      })
      .addCase(updateCountryPartyApprove.fulfilled, (state, action) => {
        state.counterPartyApproveData.data = action.payload;
        state.counterPartyApproveData.isLoading = false;
      })
      .addCase(updateCountryPartyApprove.rejected, (state, action) => {
        state.counterPartyApproveData.error = action.error.message;
        state.counterPartyApproveData.isLoading = false;
      })

      /* UPDATE COUNTER PARTY DECLINE */
      .addCase(updateCountryPartyDecline.pending, (state, action) => {
        state.counterPartyDeclineData.isLoading = true;
      })
      .addCase(updateCountryPartyDecline.fulfilled, (state, action) => {
        state.counterPartyDeclineData.data = action.payload;
        state.counterPartyDeclineData.isLoading = false;
      })
      .addCase(updateCountryPartyDecline.rejected, (state, action) => {
        state.counterPartyDeclineData.error = action.error.message;
        state.counterPartyDeclineData.isLoading = false;
      })
      /* GET COUNTER PARTY USER LIMITS */
      .addCase(getCountryPartyUserLimit.pending, (state, action) => {
        state.counterPartyUserLimitData.isLoading = true;
      })
      .addCase(getCountryPartyUserLimit.fulfilled, (state, action) => {
        state.counterPartyUserLimitData.data = action.payload;
        state.counterPartyUserLimitData.isLoading = false;
      })
      .addCase(getCountryPartyUserLimit.rejected, (state, action) => {
        state.counterPartyUserLimitData.error = action.error.message;
        state.counterPartyUserLimitData.isLoading = false;
      })

      /* GET COUNTER PARTY USER DETAILS */
      .addCase(getCountryPartyUserDetails.pending, (state, action) => {
        state.counterPartyUserTableData.isLoading = true;
      })
      .addCase(getCountryPartyUserDetails.fulfilled, (state, action) => {
        state.counterPartyUserTableData.data = action.payload;
        state.counterPartyUserTableData.isLoading = false;
      })
      .addCase(getCountryPartyUserDetails.rejected, (state, action) => {
        state.counterPartyUserTableData.error = action.error.message;
        state.counterPartyUserTableData.isLoading = false;
      })

      /* GST VERIFICATION */
      .addCase(gstVerification.pending, (state, action) => {
        state.gstVerification.isLoading = true;
      })
      .addCase(gstVerification.fulfilled, (state, action) => {
        state.gstVerification.data = action.payload;
        state.gstVerification.isLoading = false;
      })
      .addCase(gstVerification.rejected, (state, action) => {
        state.gstVerification.error = action.error.message;
        state.gstVerification.isLoading = false;
      })

      /* GST VERIFICATION */
      .addCase(ApproveLender.pending, (state, action) => {
        state.LenderInvoice.isLoading = true;
      })
      .addCase(ApproveLender.fulfilled, (state, action) => {
        state.LenderInvoice.data = action.payload;
        state.LenderInvoice.isLoading = false;
      })
      .addCase(ApproveLender.rejected, (state, action) => {
        state.LenderInvoice.error = action.error.message;
        state.LenderInvoice.isLoading = false;
      })

      /* Add Maual Invoice */
      .addCase(postAddManualInvoice.pending, (state, action) => {
        state.addManualInvoice.isLoading = true;
        state.addManualInvoice.data = null;
        state.addManualInvoice.error = null;
      })
      .addCase(postAddManualInvoice.fulfilled, (state, action) => {
        state.addManualInvoice.data = action.payload;
        state.addManualInvoice.isLoading = false;
        state.addManualInvoice.error = null;
      })
      .addCase(postAddManualInvoice.rejected, (state, action) => {
        state.addManualInvoice.error = action.error.message;
        state.addManualInvoice.isLoading = false;
        state.addManualInvoice.data = null;
      })

      /* Add Approved Retailer */
      .addCase(getApprovedRetailerList.pending, (state, action) => {
        state.approvedRetailer.isLoading = true;
        state.approvedRetailer.data = null;
        state.approvedRetailer.error = null;
      })
      .addCase(getApprovedRetailerList.fulfilled, (state, action) => {
        state.approvedRetailer.data = action.payload;
        state.approvedRetailer.isLoading = false;
        state.approvedRetailer.error = null;
      })
      .addCase(getApprovedRetailerList.rejected, (state, action) => {
        state.approvedRetailer.error = action.error.message;
        state.approvedRetailer.isLoading = false;
        state.approvedRetailer.data = null;
      })

      /* Accept Performa Invoice */
      .addCase(acceptPerformaInvoice.pending, (state, action) => {
        state.acceptPerforma.isLoading = true;
        state.acceptPerforma.data = null;
        state.acceptPerforma.error = null;
      })
      .addCase(acceptPerformaInvoice.fulfilled, (state, action) => {
        state.acceptPerforma.data = action.payload;
        state.acceptPerforma.isLoading = false;
        state.acceptPerforma.error = null;
      })
      .addCase(acceptPerformaInvoice.rejected, (state, action) => {
        state.acceptPerforma.error = action.error.message;
        state.acceptPerforma.isLoading = false;
        state.acceptPerforma.data = null;
      })

      .addCase(fetchManualInvoiceData.pending, (state, action) => {
        state.fetchManualInvoiceData.isLoading = true;
        state.fetchManualInvoiceData.data = null;
        state.fetchManualInvoiceData.error = null;
      })
      .addCase(fetchManualInvoiceData.fulfilled, (state, action) => {
        state.fetchManualInvoiceData.data = action.payload;
        state.fetchManualInvoiceData.isLoading = false;
        state.fetchManualInvoiceData.error = null;
      })
      .addCase(fetchManualInvoiceData.rejected, (state, action) => {
        state.fetchManualInvoiceData.error = action.error.message;
        state.fetchManualInvoiceData.isLoading = false;
        state.fetchManualInvoiceData.data = null;
      })

      .addCase(submitEditManualInvoice.pending, (state, action) => {
        state.submitEditManualInvoice.isLoading = true;
        state.submitEditManualInvoice.data = null;
        state.submitEditManualInvoice.error = null;
      })
      .addCase(submitEditManualInvoice.fulfilled, (state, action) => {
        state.submitEditManualInvoice.data = action.payload;
        state.submitEditManualInvoice.isLoading = false;
        state.submitEditManualInvoice.error = null;
      })
      .addCase(submitEditManualInvoice.rejected, (state, action) => {
        state.submitEditManualInvoice.error = action.error.message;
        state.submitEditManualInvoice.isLoading = false;
        state.submitEditManualInvoice.data = null;
      })

      .addCase(deleteInvoiceFile.pending, (state, action) => {
        state.deleteInvoiceFile.isLoading = true;
        state.deleteInvoiceFile.data = null;
        state.deleteInvoiceFile.error = null;
      })
      .addCase(deleteInvoiceFile.fulfilled, (state, action) => {
        state.deleteInvoiceFile.data = action.payload;
        state.deleteInvoiceFile.isLoading = false;
        state.deleteInvoiceFile.error = null;
      })
      .addCase(deleteInvoiceFile.rejected, (state, action) => {
        state.deleteInvoiceFile.error = action.error.message;
        state.deleteInvoiceFile.isLoading = false;
        state.deleteInvoiceFile.data = null;
      })

      .addCase(downloadBatchExcel.pending, (state, action) => {
        state.downloadBatchExcel.isLoading = true;
        state.downloadBatchExcel.data = null;
        state.downloadBatchExcel.error = null;
      })
      .addCase(downloadBatchExcel.fulfilled, (state, action) => {
        state.downloadBatchExcel.data = action.payload;
        state.downloadBatchExcel.isLoading = false;
        state.downloadBatchExcel.error = null;
      })
      .addCase(downloadBatchExcel.rejected, (state, action) => {
        state.downloadBatchExcel.error = action.error.message;
        state.downloadBatchExcel.isLoading = false;
        state.downloadBatchExcel.data = null;
      })

      //get Dashboard Data
      .addCase(getDashboardData.pending, (state, action) => {
        state.dashboardData.isLoading = true;
        state.dashboardData.data = null;
        state.dashboardData.error = null;
      })
      .addCase(getDashboardData.fulfilled, (state, action) => {
        state.dashboardData.data = action.payload;
        state.dashboardData.isLoading = false;
        state.dashboardData.error = null;
      })
      .addCase(getDashboardData.rejected, (state, action) => {
        state.dashboardData.error = action.error.message;
        state.dashboardData.isLoading = false;
        state.dashboardData.data = null;
      })
   
      .addCase(getLiquidationDue.pending, (state, action) => {
        state.LiquidationDueData.isLoading = true;
        state.LiquidationDueData.data = null;
        state.LiquidationDueData.error = null;
      })
      .addCase(getLiquidationDue.fulfilled, (state, action) => {
        state.LiquidationDueData.data = action.payload;
        state.LiquidationDueData.isLoading = false;
        state.LiquidationDueData.error = null;
      })
      .addCase(getLiquidationDue.rejected, (state, action) => {
        state.LiquidationDueData.error = action.error.message;
        state.LiquidationDueData.isLoading = false;
        state.LiquidationDueData.data = null;
      });
  },
});

export default anchorSlice.reducer;
