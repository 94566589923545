import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx'; // Import all exports from 'xlsx' as XLSX

function ExcelSheet(props) {
    const [data, setData] = useState([]);

    useEffect(() => {
        if (props?.tableData?.data) {
            const headerRow = ['Anchor', 'Anchor Name', 'Credit Limit Anchor (Rs)', 'Counter Party Name', 'Invoice Id',
                'Discounting Date', 'Due Date', 'Manual Invoice', 'Invoice Amount (Rs)', 'Interest Rate (%)', 'Interest Amount (Rs)',
                'Outstanding Amount (Rs)', 'Payment Amount (Rs)', 'Invoice Status',
                'Buyer Invoice Status', 'Compliance Chk', 'Liquidated Date'];

            const newData = props.tableData.data.items.map(eachItem => {
                return [
                    eachItem.sellerId, eachItem.sellerName, eachItem.achorMaxlimit,
                    eachItem.buyerName, eachItem.invoiceId, eachItem.discountedDate,
                    eachItem.dueDate, eachItem.manualInvoice, eachItem.amount,
                    eachItem.interestRate, eachItem.interestAmount, eachItem.outstandingAmount,
                    eachItem.overDueAmount, eachItem.invoiceStatus,
                    eachItem.buyerInvoiceStatus, eachItem.complianceCheck
                ];
            });

            setData([headerRow, ...newData]);
        }
    }, [props.tableData]);

    const exportToExcel = () => {
        const ws = XLSX.utils.aoa_to_sheet(data);
    
        // Set column widths (characters)
        const wscols = [
             // Column B width
            { wch: 15 }, // Column A width
            { wch: 15 }, // Column A width
            { wch: 15 }, // Column A width
            { wch: 15 }, // Column A width
            { wch: 15 }, // Column A width
            { wch: 15 }, // Column A width
            { wch: 15 }, // Column A width
            { wch: 15 }, // Column A width
            { wch: 15 }, // Column A width
            { wch: 15 }, // Column A width
            { wch: 15 }, // Column A width
            { wch: 15 }, // Column A width
            { wch: 15 }, // Column A width
            { wch: 15 }, // Column A width
            { wch: 15 }, // Column A width
            { wch: 15 }, // Column A width
            { wch: 15 }, // Column A width
            { wch: 15 }, // Column A width
            // Add more column widths as needed
        ];
        ws['!cols'] = wscols;
    
        // Set header row height (pixels)
        const wsrows = [{ hpx: 40 }]; // Header row height
        ws['!rows'] = wsrows;
    
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, 'excel_sheet.xlsx');
    };
    

    const printExcelData = () => {
        const printContents = document.getElementById('print-area').innerHTML;
        const originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
    };

    return (
        <div>
        <div className="button-container" style={{display:'inline'}}>
            <button onClick={exportToExcel} className="btn btn-success px-2 my-3">Export to Excel</button>
            <button onClick={printExcelData} className="btn btn-primary px-2 my-3 ml-2" style={{marginLeft:'10px'}}>Print Excel Data</button>
        </div>
        <div id="print-area" style={{ display: 'none' }}>
            <table>
                <thead>
                    <tr>
                        {data.length > 0 && data[0].map((item, index) => (
                            <th key={index}>{item}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.slice(1).map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {row.map((cell, cellIndex) => (
                                <td key={cellIndex}>{cell}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    </div>
    
    );
}

export default ExcelSheet;
