const BorrowerInfo = ({ scoreCardDetails }) => {
  return (
    <div className="my-3">
      <div>
        <span className="fw-semibold text-secondary">Borrower Name: </span>
        <span className="fw-semibold text-success">
          {scoreCardDetails?.success?.aadharSDK?.name || (
            <span className="text-danger mx-3">N/A</span>
          )}
        </span>
      </div>

      <div>
        <span className="fw-semibold text-secondary">Contact number : </span>
        <span className="fw-semibold text-success">
          {scoreCardDetails?.success?.mobileNumber || (
            <span className="text-danger mx-3">N/A</span>
          )}
        </span>
      </div>

      <div>
        <span className="fw-semibold text-secondary">Email ID: </span>
        <span className="fw-semibold text-success">
          {scoreCardDetails?.success?.emailId || (
            <span className="text-danger mx-3">N/A</span>
          )}
        </span>
      </div>
    </div>
  );
};

export default BorrowerInfo;
