import { HiOutlineArrowNarrowDown } from "react-icons/hi";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import { BiSort } from "react-icons/bi";
import TablePagination from "../../../layout/GenericLayout/TablePagination";
import { useState } from "react";
import Loader from "../../../layout/GenericLayout/Loader";
import ReusableModal from "../../../layout/GenericLayout/ReusableModal";
import { nanoid } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
import { toastMessage } from "../../../layout/GenericLayout/ToastMessage";

/* MODALS OR CHILDREN COMPONENTS */
const ItemsDetailsModal = (props) => {
  /* OBJECT DESTRUCTURE */
  const { activeItemsDetails } = props;

  return (
    <div className="p-2">
      <span className="fs-4 fw-semibold">Items Information</span>
      <div>
        <table className="table">
          <thead>
            <tr>
              {/* <th scope="col">Sl No.</th> */}
              <th scope="col">Items</th>
              <th scope="col">Quantity Ordered</th>
              <th scope="col">Model No</th>
            </tr>
          </thead>
          <tbody>
            {activeItemsDetails.map((eachRows, index) => (
              <tr key={nanoid()} scope="col">
                {/* <td scope="col">{index + 1}</td> */}
                <td>{eachRows.item}</td>
                <td>{eachRows.quantity}</td>
                <td>{eachRows.modelNumber}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

/* PARENT COMPONENT */
const AnchorMaterialRequestTable = (props) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [activeItemsDetails, setActiveItemsDetails] = useState([]);

  const updatePage = (currentPage) => {
    const newOffSet = props.tableQueryParams.tableLimit * (currentPage - 1);
    props.setTableQueryParams((prev) => ({
      ...prev,
      tableOffset: newOffSet,
      currentPage,
    }));
  };

  const onClickActiveSort = (activeSortValue) => {
    if (activeSortValue !== "") {
      let order = -1;
      if (activeSortValue === props.tableQueryParams.tableSort) {
        if (props.tableQueryParams.tableOrder === -1) {
          order = 1;
        }
      }
      props.setTableQueryParams((prev) => ({
        ...prev,
        tableSort: activeSortValue,
        tableOrder: order,
      }));
    }
  };

  const onClickViewMoreTableData = () => {
    let newLimit = props.tableQueryParams.tableLimit;
    if (newLimit === 5) {
      newLimit += 5;
    } else {
      newLimit -= 5;
    }
    props.setTableQueryParams((prev) => ({
      ...prev,
      tableLimit: newLimit,
    }));
  };

  const toggleModal = () => {
    setShowModal((prev) => !prev);
  };

  const onclickItemsCount = (data) => {
    setActiveItemsDetails(data);
    toggleModal();
  };

  const onClickMetarialId = ({ buyerId, mrId, status }) => {
    // console.log(buyerId, mrId);
    if (status?.toLowerCase() === "new") {
      navigate(`${buyerId}/${mrId}`);
      // toastMessage("Navigating", "success");
    } else {
      toastMessage("Already Raised", "error");
    }
    // console.log("------------------------------>>>>>",status.toLowerCase())
    // navigate(`${buyerId}/${mrId}`);
  };

  return (
    <>
      {/* Modal Content */}
      {showModal && (
        <ReusableModal
          showModal={showModal}
          toggleModal={toggleModal}
          modalContent={
            <ItemsDetailsModal activeItemsDetails={activeItemsDetails} />
          }
          modalSizeClass="modal-lg"
        />
      )}
      <div className="table-container">
        <table className={props.className}>
          <thead>
            <tr>
              {props.tableHeader.map((eachHeader) => (
                <th
                  key={eachHeader.name}
                  scope="col"
                  role={
                    eachHeader.sortValue === ""
                      ? eachHeader.sortValue
                      : "button"
                  }
                  className={`${
                    props.tableQueryParams.tableSort === eachHeader.sortValue &&
                    eachHeader.sortValue !== "" &&
                    "active-sort-text"
                  }`}
                  onClick={() => onClickActiveSort(eachHeader.sortValue)}
                >
                  {eachHeader.name}
                  {eachHeader.sortValue !== "" && <BiSort />}
                </th>
              ))}
            </tr>
          </thead>
          {props.materialRequestMetaData?.paging?.total<0?(<div className="no-data text-danger">Oops! there is no data to show. Please check back later</div>):null} 
          {props.isLoading ? (
            <tbody>
              <tr>
                <td colSpan={6}>
                  <Loader className="my-5" />
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              {props.tableRows.map((eachRows, index) => (
                <tr key={eachRows.mrId} scope="col">
                  {/* {console.log(
                    "eachRows : ",
                    eachRows?.buyerId,
                    eachRows?.mrId
                  )} */}
                  <td
                    style={{ color: "#aeb7e5", cursor: "pointer" }}
                    onClick={() =>
                      onClickMetarialId({
                        buyerId: eachRows?.buyerId,
                        mrId: eachRows?.mrId,
                        status: eachRows?.mrstatus,
                      })
                    }
                  >
                    {eachRows.mrId}
                  </td>
                  <td>{eachRows.buyerName}</td>
                  <td>
                    {eachRows.itemsDetails?.length > 0
                      ? eachRows.itemsDetails?.length
                      : "N/A"}
                  </td>
                  <td
                    style={{ color: "#aeb7e5", cursor: "pointer" }}
                    role="button"
                    onClick={() => onclickItemsCount(eachRows?.itemsDetails)}
                    className={` ${
                      eachRows?.itemsDetails.reduce(
                        (acc, cuurentVal) => acc + cuurentVal.quantity,
                        0
                      ) === 0 && "text-dark"
                    } ${
                      eachRows?.itemsDetails.reduce(
                        (acc, cuurentVal) => acc + cuurentVal.quantity,
                        0
                      ) === "0" && "text-dark"
                    }`}
                  >
                    {/* {eachRows?.itemsDetails[0]?.quantity
                      ? eachRows?.itemsDetails[0]?.quantity
                      : "NAN"} */}
                    {eachRows?.itemsDetails.reduce(
                      (acc, cuurentVal) => acc + cuurentVal.quantity,
                      0
                    )}
                  </td>
                  <td>{eachRows?.requiredDate}</td>
                  <td>{eachRows?.modifiedDate}</td>
                  <td
                    className={`${
                      eachRows?.mrstatus === "New" && "text-primary"
                    } ${eachRows?.mrstatus === "Raised" && "text-warning"} ${
                      eachRows?.mrstatus === "Rejected" && "text-danger"
                    }`}
                  >
                    {eachRows?.mrstatus}
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
      
      {!props.isLoading && props.materialRequestMetaData?.paging?.total>5 && (
          <div className="d-flex justify-content-end px-2">
            <span
              onClick={onClickViewMoreTableData}
              className="d-flex align-items-center gap-1 my-3 view-more-text"
            >
              {props.tableQueryParams.tableLimit === 5
               ? props.tableQueryParams.tableOffset+props.tableQueryParams.tableLimit<props.materialRequestMetaData?.paging?.total?"View More ":""
               : props.tableQueryParams.tableOffset+props.tableQueryParams.tableLimit<props.materialRequestMetaData?.paging?.total?"View Less ":""}
               <HiOutlineArrowNarrowDown
                className={`fs-5 ${
                  props.tableQueryParams.tableLimit === 5
                  ? props.tableQueryParams.tableOffset+props.tableQueryParams.tableLimit<props.materialRequestMetaData?.paging?.total? "down-arrow":"d-none"
                  : props.tableQueryParams.tableOffset+props.tableQueryParams.tableLimit<props.materialRequestMetaData?.paging?.total? "up-arrow":"d-none"}
                `}
              />
            </span>
          </div>
        )}

      <div className="d-flex align-items-center gap-2 page-data-text-container mt-3 px-3 w-100">
        <TablePagination
          pageSize={props.tableQueryParams.tableLimit}
          onUpdatePage={updatePage}
          current={props.tableQueryParams.currentPage}
          total={props.materialRequestMetaData?.paging?.total}
        ></TablePagination>
      </div>
    </>
  );
};

export default AnchorMaterialRequestTable;
