import { useEffect, useState } from "react";
import { FaDownload } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../../layout/GenericLayout/Loader";
import ReusableModal from "../../../layout/GenericLayout/ReusableModal";
import { toastMessage } from "../../../layout/GenericLayout/ToastMessage";
import { getKycData, updateKycApproveData } from "../../../store/slices/admin";
import {Helmet} from "react-helmet";

/* MODALS OR CHILDREN COMPONENTS */
const RejectModalContent = ({ kycData, toggleRejectModal,fetchUsedata }) => {
  const [rejectReason, setRejectReason] = useState("");
  const dispatch = useDispatch();

  const updateKyc = useSelector(state => state.admin.kycApproveData);

  const onChangeRejectReason = (event) => {
    setRejectReason(event.target.value);
  };
  const onClickSubmitRejectReason = () => {
    const body = {
      emailId: kycData.data?.success?.emailId,
      remarks: rejectReason,
      status: "rejected",
    };
    dispatch(updateKycApproveData(body))
      .unwrap()
      .then((response) => {
        toastMessage(response.success, "success");
        toggleRejectModal();
        fetchUsedata();
      })
      .catch((error) => {
        console.log("error : ", error);
        toastMessage(error.message || "Unknown error occurred", "error");
      });
  };

  return (
    <div className="container">
      <span className="fs-4 fw-semibold">Rejection Reason</span>
      <div className="row">
        <div className="kyc-fields-container col-12">
          <label htmlFor="rejectReason" className="kyc-label-text form-label">
            Remarks <span className="text-danger">*</span>
          </label>
          <input
            onChange={onChangeRejectReason}
            id="rejectReason"
            name="rejectReason"
            type="text"
            className="kyc-input-field form-control"
            value={rejectReason}
          /> 
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-end gap-3 mx-5 my-3">
        <button className="kyc-back-button" disabled={updateKyc.isLoading} onClick={toggleRejectModal}>
          Cancel
        </button>
        <button disabled={updateKyc.isLoading} className="kyc-back-button" onClick={onClickSubmitRejectReason}>
            {updateKyc.isLoading ? (
              <span className="loader mx-auto"></span>
            ) : (
              "Reject"
            )}
        </button>
      </div>
    </div>
  );
};

const SendBackModalContent = ({ kycData, toggleSendBackModal, fetchUsedata }) => {
  const [sendBackReason, setSendBackReason] = useState("");
  const dispatch = useDispatch();

  const onChangeSendBackReason = (event) => {
    setSendBackReason(event.target.value);
  };
  const onClickSubmitSendBackReason = () => {
    const body = {
      emailId: kycData.data?.success?.emailId,
      remarks: sendBackReason,
      status: "sent back",
    };
    dispatch(updateKycApproveData(body))
      .unwrap()
      .then((response) => {
        console.log("response : ", response);
        toastMessage(response.success, "success");
        toggleSendBackModal()
        fetchUsedata()
      })
      .catch((error) => {
        console.log("error : ", error);
        toastMessage(error.message, "error");
      });
    // console.log(body);
  };

  return (
    <div className="container">
      <span className="fs-4 fw-semibold">sent back Reason</span>
      <div className="row">
        <div className="kyc-fields-container col-12">
          <label htmlFor="rejectReason" className="kyc-label-text form-label">
            Remarks <span className="text-danger">*</span>
          </label>
          <input
            onChange={onChangeSendBackReason}
            id="rejectReason"
            name="rejectReason"
            type="text"
            className="kyc-input-field form-control"
            value={sendBackReason}
          />
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-end gap-3 mx-5 my-3">
        <button className="kyc-back-button" onClick={toggleSendBackModal}>
          Cancel
        </button>
        <button
          className="kyc-back-button"
          onClick={onClickSubmitSendBackReason}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

const AadharView = ({ kycData, toggleModal }) => {
  let aadharImage = null;
  kycData.data?.success?.documents.forEach((element) => {
    if (element.adharImage) {
      console.log(element);
      aadharImage = element.adharImage;
    }
  });

  console.log(aadharImage);
  return (
    <div>
      <span className="fs-4 fw-semibold">Aadhaar Card Front View</span>
      {aadharImage === null ? (
        <div className="m-4 d-flex justify-content-center align-items-center my-5">
          <h4 className="text-danger ">Aadhaar is not available!</h4>
        </div>
      ) : (
        <>
          <div className="m-4">
            <img
              alt="Aadhaar Card Front View"
              src={aadharImage}
              className="w-100 h-100"
            />
          </div>
          <div>
            <a
              target="_blank"
              href={aadharImage}
              download
              className="d-flex justify-content-center align-items-center gap-3 list-group-item list-group-item-action list-group-item-primary w-50 mx-auto py-2 rounded fw-semibold"
            >
              Download <FaDownload className="fs-4" />
            </a>
          </div>
        </>
      )}
    </div>
  );
};

const BankStatementView = ({ kycData, toggleModal }) => {
  let bankStatement = null;
  kycData.data?.success?.documents.forEach((element) => {
    console.log(element);
    if (element.bankstatement) {
      bankStatement = element.bankstatement;
    }
  });

  return (
    <div>
      <span className="fs-4 fw-semibold">Bank Statement</span>
      {bankStatement === null ? (
        <div className="m-4 d-flex justify-content-center align-items-center my-5">
          <h4 className="text-danger ">Bank Statement is not available!</h4>
        </div>
      ) : (
        <>
          <div className="m-4">
            <object
              data={bankStatement}
              type="application/pdf"
              width="100%"
              height="500px"
            ></object>
          </div>
          <div>
            <a
              target="_blank"
              href={bankStatement}
              download
              className="d-flex justify-content-center align-items-center gap-3 list-group-item list-group-item-action list-group-item-primary w-50 mx-auto py-2 rounded fw-semibold"
            >
              Download <FaDownload className="fs-4" />
            </a>
          </div>
        </>
      )}
    </div>
  );
};

/* PARENT COMPONENT */
const AdminLenderKYC = () => {
  /* FUNCTION DECLARATIONS */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  /* OBJECT DESTRUCTURE */
  const { emailId } = useParams();
  /* USESELECTOR */
  const kycData = useSelector((state) => state.admin.kycData);
  const kycApproveData = useSelector((state) => state.admin.kycApproveData);
  /* USESTATES */
  const [kycUserData, setKycUserData] = useState(null);
  const [kycDataError, setKycDataError] = useState(null);
  const [showRejectModalStatus, setShowRejectModalStatus] = useState(false);
  const [showSendBackModalStatus, setShowSendBackModalStatus] = useState(false);
  const [showAadhaarModal, setShowAdharModal] = useState(false);
  const [showBankStatementModal, setShowBankStatementModal] = useState(false);

  /* USEEFFECTS */
  const fetchUsedata = () => {
    dispatch(getKycData(emailId))
      .unwrap()
      .then((response) => {
        console.log("response : ", response);
      })
      .catch((error) => {
        console.log("error : ", error);
        setKycDataError(error.message);
      });
  }
  useEffect(() => {
    fetchUsedata()
  }, [dispatch]);
  /* EVENT LISTENERS */
  const toggleRejectModal = () => {
    setShowRejectModalStatus((prev) => !prev);
  };
  const toggleSendBackModal = () => {
    setShowSendBackModalStatus((prev) => !prev);
  };
  const onClickBack = () => {
    navigate(-1, { replace: true });
  };
  const onClickApprove = () => {
    const body = {
      emailId: kycData.data?.success?.emailId,
      remarks: "KYC is proper",
      status: "approved",
    };

    dispatch(updateKycApproveData(body))
      .unwrap()
      .then((response) => {
        console.log("response : ", response);
        toastMessage(response.success, "success");
        fetchUsedata()
      })
      .catch((error) => {
        console.log("error : ", error);
        toastMessage(error.message, "error");
      });
  };

  const toggleAadhaarView = () => {
    setShowAdharModal((prev) => !prev);
  };

  const toggleBankStatementView = () => {
    setShowBankStatementModal((prev) => !prev);
  };

  /* RENDER */
  if (kycDataError) {
    return (
      <div className="bg-white admin-kyc-card-container px-3 py-4 h-100">
        <div className="d-flex flex-column justify-content-center align-items-center w-100 h-100">
          <h2 className="text-danger">{kycData.error}</h2>
          <div className="d-flex align-items-center justify-content-center mx-5 my-3">
            <button className="kyc-back-button" onClick={onClickBack}>
              Back
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {/* MODALS */}
      {showRejectModalStatus && (
        <ReusableModal
          showModal={showRejectModalStatus}
          toggleModal={toggleRejectModal}
          modalContent={
            <RejectModalContent fetchUsedata={fetchUsedata}
              kycData={kycData}
              toggleRejectModal={toggleRejectModal}
            />
          }
          modalSizeClass="modal-lg"
        />
      )}
      {showSendBackModalStatus && (
        <ReusableModal
          showModal={showSendBackModalStatus}
          toggleModal={toggleSendBackModal}
          modalContent={
            <SendBackModalContent fetchUsedata={fetchUsedata}
              kycData={kycData}
              toggleSendBackModal={toggleSendBackModal}
            />
          }
          modalSizeClass="modal-lg"
        />
      )}
      {showAadhaarModal && (
        <ReusableModal
          showModal={showAadhaarModal}
          toggleModal={toggleAadhaarView}
          modalContent={
            <AadharView kycData={kycData} toggleModal={toggleAadhaarView} />
          }
          modalSizeClass="modal-lg"
        />
      )}
      {showBankStatementModal && (
        <ReusableModal
          showModal={showBankStatementModal}
          toggleModal={toggleBankStatementView}
          modalContent={
            <BankStatementView
              kycData={kycData}
              toggleModal={toggleBankStatementView}
            />
          }
          modalSizeClass="modal-lg"
        />
      )}
      <div className="bg-white admin-kyc-card-container px-3 py-4">
      <Helmet>
                <title>Lender-KYC</title>
            </Helmet>
        {kycData.isLoading && (
          <div className="d-flex justify-content-center align-items-center w-100 min-vh-100">
            <Loader />
          </div>
        )}

        {kycData.data !== null && (
          <div className="d-flex flex-column m-0 p-0">
            <div className="d-flex flex-column">
              <span className="kyc-content-title my-3">Personal Details</span>
              <div className="details-container container">
                <div className="row gy-md-4 gy-2">
                  <div className="kyc-fields-container col-12  col-md-4">
                    <label htmlFor="name" className="kyc-label-text form-label">
                      Contact Person
                    </label>
                    <input
                      disabled
                      id="name"
                      name="name"
                      type="text"
                      className="kyc-input-field form-control"
                      value={kycData.data?.success?.poc}
                    />
                  </div>

                  <div className="kyc-fields-container col-12  col-md-4">
                    <label
                      htmlFor="emailId"
                      className="kyc-label-text form-label"
                    >
                      Email
                    </label>
                    <input
                      disabled
                      id="emailId"
                      type="text"
                      name="emailId"
                      className="kyc-input-field form-control"
                      value={kycData.data?.success?.emailId}
                    />
                  </div>

                  <div className="kyc-fields-container col-12  col-md-4">
                    <label
                      htmlFor="telephone"
                      className="kyc-label-text form-label"
                    >
                      Telephone
                    </label>
                    <input
                      disabled
                      id="telephone"
                      type="text"
                      name="telephone"
                      className="kyc-input-field form-control"
                      value={kycData.data?.success?.phoneNumber}
                    />
                  </div>

                  <div className="kyc-fields-container col-12  col-md-4">
                    <label
                      htmlFor="mobileNumber"
                      className="kyc-label-text form-label"
                    >
                      Mobile Number
                    </label>
                    <input
                      disabled
                      id="mobileNumber"
                      type="text"
                      name="mobileNumber"
                      className="kyc-input-field form-control"
                      value={kycData.data?.success?.mobileNumber}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex flex-column">
              <span className="kyc-content-title my-3">Bank Details</span>
              <div className="details-container container">
                <div className="row gy-md-4 gy-2">
                  <div className="kyc-fields-container col-12  col-md-4">
                    <label htmlFor="name" className="kyc-label-text form-label">
                      Name of the Bank
                    </label>
                    <input
                      disabled
                      id="name"
                      name="name"
                      type="text"
                      className="kyc-input-field form-control"
                      value={kycData.data?.success?.name}
                    />
                  </div>

                  <div className="kyc-fields-container col-12  col-md-4">
                    <label
                      htmlFor="branchName"
                      className="kyc-label-text form-label"
                    >
                      Branch Name
                    </label>
                    <input
                      disabled
                      id="branchName"
                      type="text"
                      name="branchName"
                      className="kyc-input-field form-control"
                      value={kycData.data?.success?.branch}
                    />
                  </div>

                  <div className="kyc-fields-container col-12  col-md-4">
                    <label
                      htmlFor="category"
                      className="kyc-label-text form-label "
                    >
                      Category
                    </label>
                    <input
                      disabled
                      id="category"
                      type="text"
                      name="category"
                      className="kyc-input-field form-control text-capitalize"
                      value={kycData.data?.success?.category}
                    />
                  </div>

                  <div className="kyc-fields-container col-12  col-md-4">
                    <label htmlFor="IFSC" className="kyc-label-text form-label">
                      IFSC Code
                    </label>
                    <input
                      disabled
                      id="IFSC"
                      type="text"
                      name="IFSC"
                      className="kyc-input-field form-control"
                      value={kycData.data?.success?.IFSC}
                    />
                  </div>

                  <div className="kyc-fields-container col-12  col-md-4">
                    <label htmlFor="city" className="kyc-label-text form-label">
                      City
                    </label>
                    <input
                      disabled
                      id="city"
                      type="text"
                      name="city"
                      className="kyc-input-field form-control text-capitalize"
                      value={kycData.data?.success?.city}
                    />
                  </div>

                  <div className="kyc-fields-container col-12  col-md-4">
                    <label
                      htmlFor="state"
                      className="kyc-label-text form-label"
                    >
                      State
                    </label>
                    <input
                      disabled
                      id="state"
                      type="text"
                      name="state"
                      className="kyc-input-field form-control"
                      value={kycData.data?.success?.state}
                    />
                  </div>

                  <div className="kyc-fields-container col-12  col-md-4">
                    <label
                      htmlFor="license"
                      className="kyc-label-text form-label"
                    >
                      License No.
                    </label>
                    <input
                      disabled
                      id="license"
                      type="text"
                      name="license"
                      className="kyc-input-field form-control"
                      value={kycData.data?.success?.license}
                    />
                  </div>

                  <div className="kyc-fields-container col-12  col-md-4">
                    <label
                      htmlFor="address"
                      className="kyc-label-text form-label"
                    >
                      Address
                    </label>
                    <input
                      disabled
                      id="address"
                      type="textbox"
                      name="address"
                      className="kyc-input-field form-control"
                      value={kycData.data?.success?.address}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="d-flex flex-column">
              <span className="kyc-content-title my-3">Document Details</span>
              <div className="details-container container">
                <div className="row gy-md-4 gy-2">
                  <div className="kyc-fields-container col-12  col-md-4">
                    <label htmlFor="name" className="kyc-label-text form-label">
                      Aadhaar Card Front
                    </label>

                    <button
                      onClick={toggleAadhaarView}
                      className="d-flex justify-content-center align-items-center gap-3 list-group-item list-group-item-action list-group-item-primary w-100 py-2 rounded fw-semibold"
                    >
                      View Aadhaar <BsEyeFill className="fs-4" />{" "}
                    </button>
                  </div>

                  <div className="kyc-fields-container col-12  col-md-4">
                    <label htmlFor="pan" className="kyc-label-text form-label">
                      Bank Statement
                    </label>
                    <button
                      onClick={toggleBankStatementView}
                      className="d-flex justify-content-center align-items-center gap-3 list-group-item list-group-item-action list-group-item-primary w-100 py-2 rounded fw-semibold"
                    >
                      View Bank Statement <BsEyeFill className="fs-4" />{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="d-flex flex-column flex-md-row align-items-center justify-content-end gap-3 mx-5 my-3">
              <button className="btn btn-outline-dark" onClick={onClickBack}>
                Back
              </button>

              <div className="d-flex align-items-center gap-3">
                {kycData.data?.success?.status === "approved" && (
                  <button disabled className="btn btn-success">
                    {kycApproveData.isLoading ? <Loader /> : "Approved"}
                  </button>
                )}
                {kycData.data?.success?.status === "sent back" && (
                  <button disabled className="btn btn-warning">
                    Sent Back
                  </button>
                )}

                {kycData.data?.success?.status === "rejected" && (
                  <button disabled className="btn btn-outline-danger">
                    Rejected
                  </button>
                )}
              </div>
              {kycData.data?.success?.status !== "approved" &&
                kycData.data?.success?.status !== "rejected" &&
                kycData.data?.success?.status !== "sent back" && (
                  <div className="d-flex align-items-center gap-3">
                    {
                      kycData.data?.success?.status !== "saved" &&
                      <button
                        className="btn btn-success"
                        onClick={onClickApprove}
                      >
                        {kycApproveData.isLoading ? <Loader /> : "Approve"}
                      </button>
                    }
                    <button
                      className="btn btn-warning"
                      onClick={toggleSendBackModal}
                    >
                      Send Back
                    </button>
                    {
                      kycData.data?.success?.status !== "saved" &&
                        <button
                          className="btn btn-outline-danger"
                          onClick={toggleRejectModal}
                        >
                          Reject
                        </button>
                    }
                  </div>
                )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AdminLenderKYC;
