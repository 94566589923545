import { HiOutlineArrowNarrowDown } from "react-icons/hi";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import { BiSort } from "react-icons/bi";
import { useState } from "react";
import Loader from "../../../../layout/GenericLayout/Loader";
import TablePagination from "../../../../layout/GenericLayout/TablePagination";
import ReusableModal from "../../../../layout/GenericLayout/ReusableModal";
import { wordsToNum } from "../../../../layout/GenericLayout/WordsToNum";
import { useDispatch, useSelector } from "react-redux";
import { updateGenerateBoe } from "../../../../store/slices/anchor";
import { toastMessage } from "../../../../layout/GenericLayout/ToastMessage";
import {
  BsCheck,
  BsCheck2,
  BsFillArrowUpCircleFill,
  BsFillCheckCircleFill,
} from "react-icons/bs";
import { updateUploadDocuments } from "../../../../store/slices/generic";
import { Link, useNavigate } from "react-router-dom";

/* MODALS OR CHILDREN COMPONENTS */
const GenerateBOEModal = ({
  toggleBOEModal,
  activeBOEData,
  onRefreshTable,
}) => {
  const dispatch = useDispatch();
  const [nameOfThePayee, setNameOfThePayee] = useState(activeBOEData.buyerName);

  const onChangeNameOfThePayee = (event) => {
    setNameOfThePayee(event.target.value);
  };

  const onClickGenerateBOE = () => {
    const body = {
      invoiceId: activeBOEData.invoiceId,
      paymentTerms: 30,
      toAddress: activeBOEData.buyerName,
    };

    console.log(body);
    dispatch(updateGenerateBoe(body))
      .unwrap()
      .then((response) => {
        onRefreshTable();
        toastMessage(response.success, "success");
        toggleBOEModal();
      })
      .catch((error) => {
        toastMessage(error.message, "error");
      });
  };

  return (
    <div className="">
      <span className="fs-4 fw-semibold">Bill Of Exchange</span>
      <div className="d-flex flex-column gap-3">
        {/* TEMPORARY IMAGE */}
        <div className="d-flex flex-column align-items-center">
          <img
            className="admin-profile-pic rounded-circle"
            src="https://picsum.photos/200"
            alt="admin pro"
          />
          <h4 className="text-primary fw-bold my-2">
            {activeBOEData?.sellerName}
          </h4>
          <span>Seller Address</span>
        </div>
        <div className="d-flex flex-column gap-2 px-4">
          <h5 className="fw-semibold text-secondary">
            Invoice Amount :{" "}
            <span className="text-primary fs-5">
              ₹ {activeBOEData.totalAmount}
            </span>
          </h5>

          <div className="d-flex gap-4 align-items-center">
            <span className="fw-semibold text-secondary w-50">
              Thirty days after date, pay to
            </span>
            <input
              onChange={onChangeNameOfThePayee}
              id="nameOfThePayee"
              type="text"
              className="kyc-input-field form-control border border-secondary"
              value={nameOfThePayee}
            />
          </div>

          <span className="text-secondary w-100">
            or to their order, the sum of{" "}
            <span className="text-capitalize fw-semibold ">
              {wordsToNum(activeBOEData.totalAmount)}
            </span>{" "}
            only for the value received.
          </span>
        </div>

        <div className="d-flex justify-content-between my-3 px-4">
          <div className="d-flex flex-column align-items-center">
            <img
              className="admin-profile-pic rounded-circle"
              src="https://picsum.photos/200"
              alt="admin pro"
            />
            <h5 className="text-primary my-2">{activeBOEData?.buyerName}</h5>
            <span>Buyer Address</span>
          </div>

          <div className="d-flex align-items-end">
            Authorized Signatory For {activeBOEData.sellerName}
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-center my-3">
        <button
          className="kyc-back-button p-2 w-25 fw-normal"
          onClick={onClickGenerateBOE}
        >
          Generate BOE
        </button>
      </div>
    </div>
  );
};

/* PARENT COMPONENT */
const DigitalInvoiceTable = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  /* USESELECTOR */
  const uploadDocuments = useSelector((state) => state.generic.uploadDocuments);
  /* USESTATES */
  const [showBOEModal, setShowBOEModal] = useState(false);

  const [activeBOEData, setActiveBOEData] = useState({});
  const [activeLRUploadData, setActiveLRUploadData] = useState(null);
  const [activeOthersUploadData, setActiveOthersUploadData] = useState(null);

  /* EVENT LISTENERS */
  const toggleBOEModal = () => {
    setShowBOEModal((prev) => !prev);
  };

  const updatePage = (currentPage) => {
    const newOffSet = props.tableQueryParams.tableLimit * (currentPage - 1);
    props.setTableQueryParams((prev) => ({
      ...prev,
      tableOffset: newOffSet,
      currentPage,
    }));
  };

  const onClickActiveSort = (activeSortValue) => {
    if (activeSortValue !== "") {
      let order = -1;
      if (activeSortValue === props.tableQueryParams.tableSort) {
        if (props.tableQueryParams.tableOrder === -1) {
          order = 1;
        }
      }
      props.setTableQueryParams((prev) => ({
        ...prev,
        tableSort: activeSortValue,
        tableOrder: order,
      }));
    }
  };

  const onClickViewMoreTableData = () => {
    let newLimit = props.tableQueryParams.tableLimit;
    if (newLimit === 5) {
      newLimit += 5;
    } else {
      newLimit -= 5;
    }
    props.setTableQueryParams((prev) => ({
      ...prev,
      tableLimit: newLimit,
    }));
  };

  const onClickGenerateBOE = (data) => {
    console.log(data);
    setActiveBOEData(data);
    toggleBOEModal();
  };

  const onChangeLRDocument = (event, eachRows) => {
    setActiveLRUploadData(eachRows.invoiceId);
    const body = new FormData();
    body.append("lorryReceipt", event.target.files[0]);
    body.append("invoiceId", eachRows.invoiceId);
    body.append("manualInvoice", eachRows.manualInvoice);
    body.append("sellerId", eachRows.sellerId);

    dispatch(updateUploadDocuments(body))
      .unwrap()
      .then((response) => {
        props.onRefreshTable();
        toastMessage(response.success, "success");
      })
      .catch((error) => {
        console.log("error : ", error);
        toastMessage(error.message, "error");
      });
  };

  const onClickInvoiceId = ({ sellerId, invoiceId }) => {
    console.log(sellerId, invoiceId);
    navigate(`digital/${sellerId}/${invoiceId}`);
  };

  const onChangeOthersDocument = (event, eachRows) => {
    setActiveOthersUploadData(eachRows.invoiceId);
    const body = new FormData();
    body.append("others", event.target.files[0]);
    body.append("invoiceId", eachRows.invoiceId);
    body.append("manualInvoice", eachRows.manualInvoice);
    body.append("sellerId", eachRows.sellerId);

    dispatch(updateUploadDocuments(body))
      .unwrap()
      .then((response) => {
        props.onRefreshTable();
        toastMessage(response.success, "success");
      })
      .catch((error) => {
        console.log("error : ", error);
        toastMessage(error.message, "error");
      });
  };

  console.log("activeLRUploadData", activeLRUploadData);

  return (
    <>
      {/* Modal Content */}
      {showBOEModal && (
        <ReusableModal
          showModal={showBOEModal}
          toggleModal={toggleBOEModal}
          modalContent={
            <GenerateBOEModal
              activeBOEData={activeBOEData}
              toggleBOEModal={toggleBOEModal}
              onRefreshTable={props.onRefreshTable}
            />
          }
          modalSizeClass="modal-lg"
        />
      )}

      <div className="table-container">
        <table className={props.className}>
          <thead>
            <tr>
              {props.tableHeader.map((eachHeader) => (
                <th
                  key={eachHeader.name}
                  scope="col"
                  role={
                    eachHeader.sortValue === ""
                      ? eachHeader.sortValue
                      : "button"
                  }
                  className={`${
                    props.tableQueryParams.tableSort === eachHeader.sortValue &&
                    eachHeader.sortValue !== "" &&
                    "active-sort-text"
                  }`}
                  onClick={() => onClickActiveSort(eachHeader.sortValue)}
                >
                  {eachHeader.name}
                  {eachHeader.sortValue !== "" && <BiSort />}
                </th>
              ))}
            </tr>
          </thead>
          {props.invoiceMetaData?.paging?.total<0?(<div className="no-data text-danger">Oops! there is no data to show. Please check back later</div>):null} 
          {props.isLoading ? (
            <tbody>
              <tr>
                <td colSpan={10}>
                  <Loader className="my-5" />
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              {props.tableRows.map((eachRows, index) => (
                <tr key={eachRows.poId} scope="col">
                  <td>{eachRows.addedDate}</td>
                  <td
                    onClick={() =>
                      onClickInvoiceId({
                        sellerId: eachRows.sellerId,
                        invoiceId: eachRows.invoiceId,
                      })
                    }
                  >
                    <Link style={{textDecoration:"none"}}>
                    {eachRows.invoiceId}
                    </Link>
                  </td>
                  <td className="px-3">{eachRows.poId}</td>
                  <td>{eachRows.totalAmount}</td>
                  <td>{eachRows.overDueAmount}</td>
                  <td
                    className={`text-priamry ${
                      eachRows.status === "Accepted" && "text-success"
                    } ${eachRows.status === "Rejected" && "text-danger"}`}
                  >
                    {eachRows.status}
                  </td>
                  <td className="px-3">{eachRows.invoiceStatus}</td>
                  <td>
                    {eachRows.boeId === undefined ? (
                      <span>
                        <button
                          onClick={() => onClickGenerateBOE(eachRows)}
                          className="btn list-group-item list-group-item-action list-group-item-primary px-3 py-1 rounded fw-semibold"
                        >
                          Generate BOE
                        </button>
                      </span>
                    ) : (
                      eachRows.boeId
                    )}
                  </td>

                  {eachRows?.lrInserted ? (
                    <td className="file-exist-container px-3">
                      <label
                        className="d-flex justify-content-between mx-auto align-items-center gap-2"
                        style={{ width: "130px" }}
                      >
                        File Exists
                        <span className="d-flex align-items-center">
                          <BsCheck2 className="fs-3" />
                        </span>
                      </label>
                    </td>
                  ) : (
                    <td className="upload-button-container">
                      {uploadDocuments.isLoading &&
                      activeLRUploadData === eachRows?.invoiceId ? (
                        <Loader />
                      ) : (
                        <>
                          <label
                            htmlFor={`uploadLRFile_${index}`}
                            role="button"
                            className="d-flex justify-content-between mx-auto align-items-center gap-2"
                          >
                            Upload
                            <span className="d-flex align-items-center">
                              <BsFillArrowUpCircleFill />
                            </span>
                          </label>

                          <input
                            onChange={(event) =>
                              onChangeLRDocument(event, eachRows)
                            }
                            id={`uploadLRFile_${index}`}
                            type="file"
                            className="d-none"
                          />
                        </>
                      )}
                    </td>
                  )}

                  <td className="upload-button-container">
                    {uploadDocuments.isLoading &&
                    eachRows?.invoiceId === activeOthersUploadData ? (
                      <Loader />
                    ) : (
                      <>
                        <label
                          htmlFor={`uploadOthersFile_${index}`}
                          role="button"
                          className="d-flex justify-content-between mx-auto align-items-center gap-2"
                        >
                          Upload
                          <span className="d-flex align-items-center">
                            <BsFillArrowUpCircleFill />
                          </span>
                        </label>
                        <input
                          onChange={(event) =>
                            onChangeOthersDocument(event, eachRows)
                          }
                          id={`uploadOthersFile_${index}`}
                          type="file"
                          className="d-none"
                          accept=".zip"
                        />
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>


      {!props.isLoading && props.invoiceMetaData?.paging?.total>5 && (
          <div className="d-flex justify-content-end px-2">
            <span
              onClick={onClickViewMoreTableData}
              className="d-flex align-items-center gap-1 my-3 view-more-text"
            >
              {props.tableQueryParams.tableLimit === 5
                ? props.tableQueryParams.tableOffset+props.tableQueryParams.tableLimit<props.invoiceMetaData?.paging?.total?"View More ":""
                : props.tableQueryParams.tableOffset+props.tableQueryParams.tableLimit<props.invoiceMetaData?.paging?.total?"View Less ":""}
              <HiOutlineArrowNarrowDown
                className={`fs-5 ${
                  props.tableQueryParams.tableLimit === 5
                  ? props.tableQueryParams.tableOffset+props.tableQueryParams.tableLimit<props.invoiceMetaData?.paging?.total? "down-arrow":"d-none"
                  : props.tableQueryParams.tableOffset+props.tableQueryParams.tableLimit<props.invoiceMetaData?.paging?.total? "up-arrow":"d-none"}
                }`}
              />
            </span>
          </div>
        )}

        
      <div className="d-flex align-items-center gap-2 page-data-text-container mt-3 px-3 w-100">
        <TablePagination
          pageSize={props.tableQueryParams.tableLimit}
          onUpdatePage={updatePage}
          current={props.tableQueryParams.currentPage}
          total={props.invoiceMetaData?.paging?.total}
        />
      </div>
    </>
  );
};

export default DigitalInvoiceTable;
