import { Navigate, Outlet, useNavigate } from "react-router-dom";

const AnchorPrivateRoute = () => {
  const navigate = useNavigate();
  const token = sessionStorage.getItem("token");
  const role = sessionStorage.getItem("role");
  const kycStatus = sessionStorage.getItem("kycStatus");

  const isKycUpdated = kycStatus === "NotUpdated";
  const isAuthenticated =
    token === null || token === undefined || role !== "seller";

  return isAuthenticated ? (
    <Navigate to="/login" replace />
  ) : isKycUpdated ? (
    <Navigate to="/anchor/kyc-verification-form" replace />
  ) : (
    <Outlet />
  );
};

export default AnchorPrivateRoute;
