import { HiOutlineArrowNarrowDown } from "react-icons/hi";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import { BiSort } from "react-icons/bi";
import TablePagination, {
  jumpToPage,
  nextPage,
  previousPage,
} from "../../../layout/GenericLayout/TablePagination";
import { useState } from "react";
import Loader from "../../../layout/GenericLayout/Loader";
import { Link, useLocation } from "react-router-dom";

const BuyerMaterialRequestTable = (props) => {
  const currentLocation = useLocation();
  // console.log(currentLocation.pathname);

  const updatePage = (currentPage) => {
    const newOffSet = props.tableQueryParams.tableLimit * (currentPage - 1);
    props.setTableQueryParams((prev) => ({
      ...prev,
      tableOffset: newOffSet,
      currentPage,
    }));
  };

  const onClickActiveSort = (activeSortValue) => {
    if (activeSortValue !== "") {
      let order = -1;
      if (activeSortValue === props.tableQueryParams.tableSort) {
        if (props.tableQueryParams.tableOrder === -1) {
          order = 1;
        }
      }
      props.setTableQueryParams((prev) => ({
        ...prev,
        tableSort: activeSortValue,
        tableOrder: order,
      }));
    }
  };

  const onClickViewMoreTableData = () => {
    let newLimit = props.tableQueryParams.tableLimit;
    if (newLimit === 5) {
      newLimit += 5;
    } else {
      newLimit -= 5;
    }
    props.setTableQueryParams((prev) => ({
      ...prev,
      tableLimit: newLimit,
    }));
  };

  return (
    <>
      <div className="buyer-table-container">
        <div className="table-inner-cont">
          <table className={props.className}>
            <thead>
              <tr>
                {props.tableHeader.map((eachHeader) => (
                  <th
                    key={eachHeader.name}
                    scope="col"
                    // role={eachHeader.sortValue !== "" ? "button" : false}
                    className={`${
                      props.tableQueryParams.tableSort ===
                        eachHeader.sortValue &&
                      eachHeader.sortValue !== "" &&
                      "active-sort-text"
                    }`}
                    onClick={() => onClickActiveSort(eachHeader.sortValue)}
                  >
                    {eachHeader.name}
                    {eachHeader.sortValue !== "" && <BiSort />}
                  </th>
                ))}
              </tr>
            </thead>
            {props.materialRequestMetaData?.paging?.total<0?(<div className="no-data text-danger">Oops! there is no data to show. Please check back later</div>):null} 
            {props.isLoading ? (
              <tbody>
                <tr>
                  <td colSpan={5}>
                    <Loader className="my-5" />
                  </td>
                </tr>
              </tbody>
            ) : props.tableRows.length === 0 ? (
              <tbody >
                <tr>
                  <td colSpan={5}>NO DATA</td>
                </tr>
                {/* <p></p> */}
              </tbody>
            ) : (
              <tbody>
                {props.tableRows.map((eachRows, index) => (
                  <tr key={eachRows.mrId} scope="col">
                    <td className="row-hight-light-txt">
                      <Link
                        to={`${currentLocation.pathname}/${eachRows.mrId}`}
                        className="remove-underline-link"
                      >
                        {eachRows.mrId}
                      </Link>
                    </td>
                    <td>{eachRows?.sellerCompanyFullName ? eachRows?.sellerCompanyFullName: 'NAN'}</td>
                    <td>{eachRows.itemsDetails?.length > 0 ? eachRows.itemsDetails?.length : 'N/A'}</td>
                    <td>{eachRows.itemsDetails?.length > 0 ? eachRows.itemsDetails?.reduce((total, elem) => (total + (+elem?.quantity)), 0) : 'NAN'}</td>
                    <td>{eachRows?.modifiedDate ? (eachRows?.modifiedDate).substring(0,10)?.split("-")?.reverse()?.join("-"): 'NAN'}</td>
                    <td className="row-hight-light-txt">
                    {eachRows?.mrstatus ?<>
                    {
                      eachRows.mrstatus === 'New'?
                      <button className="w-75 hightLight-success-td ">
                      {eachRows.mrstatus}</button>:
                      eachRows.mrstatus === 'Raised'?
                      <button className="w-75 btn hightLight-warning-td ">
                      {eachRows.mrstatus}</button>:
                      eachRows.mrstatus === 'Rejected'?
                      <button className="w-75 btn hightLight-danger-td ">
                      {eachRows.mrstatus}</button>:<>{'NAN'}</>
                      
                      }
                    </>: 'NAN'}
                      </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
        {!props.isLoading && props.materialRequestMetaData?.paging?.total>5 && (
          <div className="d-flex justify-content-end px-2">
            <span
              onClick={onClickViewMoreTableData}
              className="d-flex align-items-center gap-1 my-3 view-more-text"
            >
             {props.tableQueryParams.tableLimit === 5
                ? props.tableQueryParams.tableOffset+props.tableQueryParams.tableLimit<props.materialRequestMetaData?.paging?.total?"View More ":""
                : props.tableQueryParams.tableOffset+props.tableQueryParams.tableLimit<props.materialRequestMetaData?.paging?.total?"View Less ":""}
              <HiOutlineArrowNarrowDown
                className={`fs-5 ${
                  props.tableQueryParams.tableLimit === 5
                  ? props.tableQueryParams.tableOffset+props.tableQueryParams.tableLimit<props.materialRequestMetaData?.paging?.total? "down-arrow":"d-none"
                  : props.tableQueryParams.tableOffset+props.tableQueryParams.tableLimit<props.materialRequestMetaData?.paging?.total? "up-arrow":"d-none"}
               `}
              />
            </span>
          </div>
        )}
      </div>
      <div className="d-flex align-items-center gap-2 page-data-text-container mt-3 px-3 w-100">
        <TablePagination
          pageSize={props.tableQueryParams.tableLimit}
          onUpdatePage={updatePage}
          current={props.tableQueryParams.currentPage}
          total={props.materialRequestMetaData?.paging?.total}
        ></TablePagination>
      </div>
    </>
  );
};

export default BuyerMaterialRequestTable;
