import {
  Fragment,
  createContext,
  useReducer,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { PersonalDetailsReducer } from "./personalDetails/personalDetailsReducer";
import { CoApplicantDetailsReducer } from "./coApplicantDetails/coApplicantDetailsReducer";
import { useDispatch } from "react-redux";
import { toastMessage } from "../../../layout/GenericLayout/ToastMessage";
import {
  getUserKYCDetails,
  getUserKYCDocument,
  updateAadharOCRDetails,
  updateDeleteFile,
  updateUploadKYCDocuments,
  updateUploadProfilePicture,
} from "../../../store/slices/generic";
import { useLocation } from "react-router-dom";
import { CompanyDetailsReducer } from "./companyDetails/companyDetailsReducer";
import { BankDetailsReducer } from "./bankDetails/bankDetailsReducer";
import { DocumentDetailsReducer } from "./documentDetails/documentDetailsReducer";
import { GstDetailsReducer } from "./gstDetails/gstDetailsReducer";
import { useNavigate } from "react-router-dom";
import { updateSellerKYCVerification } from "../../../store/slices/anchor";
import { AnchorKycActions } from "./actionTypes";
import { fetchFilesOfAnchorAndCounterParty, fetchOnBoardedUsersDetails, rmOnboardAnchorDispatch, rmUploadFiles } from "../../../store/slices/rm";
import {postEquifaxscore} from "../../../store/slices/generic";


function generateTemplate() {
  return {
    value: "",
    isTouched: false,
    isValid: false,
  };
}

function generateTemplateForFile() {
  return {
    fileList: [],
    isTouched: false,
    isVerified: false,
  };
}

const personalDetailsTemplate = {
  name: generateTemplate(),
  DOB: generateTemplate(),
  mobileNumber: generateTemplate(),
  panNo: { ...generateTemplate(), isVerified: false },
  aadhaarImage: {
    ...generateTemplateForFile(),
    isVerified: false,
    verificationStatus: null,
  },
  profilePic: { ...generateTemplateForFile(), isVerified: false },
};

const coApplicantDetailsTemplate = {
  name: generateTemplate(),
  mobileNumber: generateTemplate(),
  panNo: { ...generateTemplate(), isVerified: false },
  aadhaarImage: {
    ...generateTemplateForFile(),
    isVerified: false,
    verificationStatus: null,
  },
};

const companyDetailsTemplate = {
  logo: { ...generateTemplateForFile(), isVerified: false },
  productCategory: generateTemplate(),
  companyName: generateTemplate(),
  companyType: generateTemplate(),
  websiteAddress: generateTemplate(),
  email: generateTemplate(),
  cinNumber: generateTemplate(),
  panNumber: { ...generateTemplate(), isVerified: false },
  security: generateTemplate(),
  companyAddress: generateTemplate(),
  city: generateTemplate(),
  state: generateTemplate(),
  pinCode: generateTemplate(),
  region: generateTemplate(),
  country: generateTemplate(),
  typeOfOffices: generateTemplate(),
  noOfOffices: generateTemplate(),
  typeOfStoresAndPlant: generateTemplate(),
  noOfEmployeesInStore: generateTemplate(),
  noOfWarehouses: generateTemplate(),
  telephoneNumber: generateTemplate(),
  mobileNumber: generateTemplate(),
  noOfEmployeesInWarehouse: generateTemplate(),
  storeDetails: generateTemplate(),
  manufacturer:["","",""]
};

const bankDetailsTemplate = {
  accountType: generateTemplate(),
  bankName: generateTemplate(),
  accountNumber: generateTemplate(),
  accountHolderName: generateTemplate(),
  IFSCCode: generateTemplate(),
  bankStatement: generateTemplateForFile(),
};

const gstDetailsTemplate = {
  gst: { ...generateTemplate(), isVerified: false },
};

const documentDetailsTemplate = {
  documents: [
    {
      documentName: generateTemplate(),
      bankStatement: generateTemplateForFile(),
    },
  ],
  loanAmount: generateTemplate(),
};

export const AnchorKYCFormTemplate = {
  personalDetails: personalDetailsTemplate,
  coApplicantAvailable: false,
  coApplicantDetails: coApplicantDetailsTemplate,
  companyDetails: companyDetailsTemplate,
  bankDetails: bankDetailsTemplate,
  gstDetails: gstDetailsTemplate,
  documentDetails: documentDetailsTemplate,
  personalDetailsDispatch: ({ type, payload }) => {},
  coApplicantDetailsDispatch: ({ type, payload }) => {},
  companyDetailsDispatch: ({ type, payload }) => {},
  bankDetailsDispatch: ({ type, payload }) => {},
  documentDetailsDispatch: ({ type, payload }) => {},
  gstDetailsDispatch: ({ type, payload }) => {},
  setCoApplicantAvailable: (isAvailable) => {},
  handleSubmitForm: () => {},
  files: [
    {
      email: "",
      documents: [
        {
          imageFileFormat: "image/jpeg",
          name: "",
          modifiedDate: "2023-07-12 20:23:06.476000",
          coAadharImage:
            "https://atoncap.s3.ap-south-1.amazonaws.com/monherry9+wxobz@gmail.com/coAadharImage/2023-07-12 20:23:06.374819-2023-05-15 13_01_27.395279-adhar.jpg",
        },
      ],
    },
  ],
  deleteFiles: (key, url) => {},
  emailId: "",
  uploadFiles: (formData, type) => {},
  disableEdit: false,
};

export const AnchorKycContext = createContext(AnchorKYCFormTemplate);

/**
 * @param {{ children: React.ReactNode, roles?: "seller" }} props
 */
export const AnchorKycContextWrapper = (props) => {
  /**
   * @type { [ typeof AnchorKYCFormTemplate['personalDetails'], React.DispatchWithoutAction ] }
   */
  const [personalDetails, personalDetailsDispatch] = useReducer(
    PersonalDetailsReducer,
    AnchorKYCFormTemplate.personalDetails
  );

  const [coApplicantDetails, coApplicantDetailsDispatch] = useReducer(
    CoApplicantDetailsReducer,
    AnchorKYCFormTemplate.coApplicantDetails
  );

  const [companyDetails, companyDetailsDispatch] = useReducer(
    CompanyDetailsReducer,
    AnchorKYCFormTemplate.companyDetails
  );

  const [bankDetails, bankDetailsDispatch] = useReducer(
    BankDetailsReducer,
    AnchorKYCFormTemplate.bankDetails
  );

  const [documentDetails, documentDetailsDispatch] = useReducer(
    DocumentDetailsReducer,
    AnchorKYCFormTemplate.documentDetails
  );

  const [gstDetails, gstDetailsDispatch] = useReducer(
    GstDetailsReducer,
    AnchorKYCFormTemplate.gstDetails
  );

  const [coApplicantAvailable, setCoApplicantAvailable] = useState(true);
  const [ disableEdit, setDisableEdit ] = useState(false);

  const [files, setFiles] = useState([]);
  const [ isFetched, setIsFetched ] = useState(false);

  const dispatch = useDispatch();

  const { state } = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    if (state?.disableEdit) {
      setDisableEdit(state?.disableEdit);
    }
  }, [state]);

  const emailId = useMemo(() => {
    if (state?.emailId && typeof state?.emailId === "string") {
      return state.emailId?.toLowerCase();
    }
    else if (state?.loginData?.items[0].user.emailId) {
      return state.loginData.items[0].user.emailId;
    }
    toastMessage("session Expired", "error");

    setTimeout(() => navigate("/login", { replace: true }), 2000);
  }, [state, navigate]);

  const roles = useMemo(() => {
    if (props?.roles && typeof props?.roles === "string" && props?.roles === "seller") {
      return [props?.roles];
    }
    else if (state?.loginData?.items[0].user.roles) {
      return state.loginData.items[0].user.roles;
    }
    toastMessage("session Expired", "error");

    setTimeout(() => navigate("/login", { replace: true }), 2000);
  }, [state, navigate, props?.roles]);

  const fetchDocuments = useCallback(() => {    
    if (window.location.pathname.includes("/rm")) {
      dispatch(fetchFilesOfAnchorAndCounterParty(emailId))
        .unwrap()
        .then(response => {
          setFiles(response?.items);
          console.log("fetchDocuments an",response?.items);
        })
        .catch(error => {
          console.error("error while fetching files", error?.message);
        });
      
      dispatch(fetchOnBoardedUsersDetails(emailId))
        .unwrap()
        .then(data => {
          if (data?.success && !isFetched) {
            updateDocumentDetails(data?.success);
            updateGstDetails(data?.success);
            updateStoreDetails(data?.success);
            updateBankDetails(data?.success);
            updatePersonalDetails(data?.success);
            updateCoApplicantDetails(data?.success);
            setFiles([data?.success]);
          }
        })
        .catch(err => {
          if (disableEdit) {
            toastMessage("Unable to fetch KYC details", "error");
          }
          console.log("error", err?.message);
        });
        
      setIsFetched(true);

    } else {
      dispatch(getUserKYCDocument())
        .unwrap()
        .then((response) => {
          if (response?.items?.length > 0 && !isFetched) {
            updateGstDetails(response?.items?.[response?.items?.length - 1]);
            updateStoreDetails(response?.items?.[response?.items?.length - 1]);
            updateDocumentDetails(response?.items?.[response?.items?.length - 1]);
            updateBankDetails(response?.items?.[response?.items?.length - 1]);
            updatePersonalDetails(response?.items?.[response?.items?.length - 1]);
            updateCoApplicantDetails(response?.items?.[response?.items?.length - 1]);
          }
          setFiles(response?.items);
          setIsFetched(true);
        })
        .catch((error) => {
          if (error.message !== "kyc is not updated for this login") {
            toastMessage(error.message ?? "Unknown Error Occurred", "error");
          }
          setIsFetched(true);
        });
    }
  }, [dispatch, emailId]);

  /**
   * @param {{ name: string, dateOfBirth: string, panValidationStatus: boolean, PANNumber: string, mobileNumber: string, aadhaarValidationStatus: boolean, aadharSDK: { dateOfBirth: string, name: string, gender: "MALE" | "FEMALE" } }} payload
   */
  const updatePersonalDetails = ({
    name,
    PANNumber,
    dateOfBirth,
    mobileNumber,
    aadhaarValidationStatus,
    aadharSDK,
    panValidationStatus,
  }) => {
    // console.log("dob", dateOfBirth);
    const parsedDOB = dateOfBirth?.split("/")?.reverse()?.join("-");

    personalDetailsDispatch({
      type: AnchorKycActions.PERSONAL_DETAILS.CHANGE_NAME,
      payload: name,
    });
    PANNumber && personalDetailsDispatch({
      type: AnchorKycActions.PERSONAL_DETAILS.CHANGE_PAN,
      payload: PANNumber,
    });
    personalDetailsDispatch({
      type: AnchorKycActions.PERSONAL_DETAILS.BLUR_DOB,
      payload: parsedDOB,
    });
    personalDetailsDispatch({
      type: AnchorKycActions.PERSONAL_DETAILS.CHANGE_MOBILE,
      payload: mobileNumber,
    });
    
    personalDetailsDispatch({
      type: AnchorKycActions.PERSONAL_DETAILS.BLUR_MOBILE,
    });

    if (aadharSDK) {
     // console.log("aadhaarSDK", aadharSDK);

      personalDetailsDispatch({
        type: AnchorKycActions.PERSONAL_DETAILS.CHANGE_AADHAAR,
        payload: { status: aadhaarValidationStatus, response: aadharSDK },
      });
    }

    panValidationStatus && personalDetailsDispatch({
      type: AnchorKycActions.PERSONAL_DETAILS.BLUR_PAN,
      payload: panValidationStatus,
    });
  };

  /**
   * @param {{ coApplicant: { coPanValidationStatus: boolean, PANNumber: string, name: string, mobileNumber: string, coAadhaarValidationStatus: boolean, aadharSDK: { name: string, dateOfBirth: string, gender: "MALE" | "FEMALE" } }[] }} param0
   */
  const updateCoApplicantDetails = ({ coApplicant }) => {
    coApplicantDetailsDispatch({
      type: AnchorKycActions.CO_APPLICANT_DETAILS.CHANGE_NAME,
      payload: coApplicant?.[0]?.name,
    });
    coApplicant?.[0]?.PANNumber && coApplicantDetailsDispatch({
      type: AnchorKycActions.CO_APPLICANT_DETAILS.CHANGE_PAN,
      payload: coApplicant?.[0]?.PANNumber,
    });
    coApplicantDetailsDispatch({
      type: AnchorKycActions.CO_APPLICANT_DETAILS.CHANGE_MOBILE,
      payload: coApplicant?.[0]?.mobileNumber,
    });
    coApplicantDetailsDispatch({
      type: AnchorKycActions.CO_APPLICANT_DETAILS.BLUR_MOBILE,
    });

    if (coApplicant?.[0]?.aadharSDK) {
      coApplicantDetailsDispatch({
        type: AnchorKycActions.CO_APPLICANT_DETAILS.CHANGE_AADHAAR,
        payload: {
          status: coApplicant[0].coAadhaarValidationStatus,
          response: coApplicant[0].aadharSDK,
        },
      });
    }

    coApplicantDetailsDispatch({
      type: AnchorKycActions.CO_APPLICANT_DETAILS.BLUR_NAME,
    });
    coApplicant?.[0]?.coPanValidationStatus && coApplicantDetailsDispatch({
      type: AnchorKycActions.CO_APPLICANT_DETAILS.BLUR_PAN,
      payload: coApplicant?.[0]?.coPanValidationStatus,
    });
    coApplicantDetailsDispatch({
      type: AnchorKycActions.CO_APPLICANT_DETAILS.BLUR_MOBILE,
    });
  };

  /**
   * @param {{
   *  companyFullName: string,
   *  companyPAN: string,
   *  companyPanValidationStatus: boolean,
   *  companyType: string,
   *  companyFullAddress: string,
   *  city: string,
   *  state: string,
   *  region: string,
   *  pinCode: number,
   *  productCategory: string,
   *  websiteAddress: string,
   *  CIN: string,
   *  security: string,
   *  typeOfOffices: string,
   *  noOfOffices: number,
   *  typeOfShowroom: string,
   *  noOfEMPShowroom: number,
   *  noOfEMPWarehouse: number,
   *  companyTelephone: string,
   *  companyMobileNumber: string,
   *  noOfWarehouse: number
   * }} param0
   */
  const updateStoreDetails = ({
    city,
    companyFullAddress,
    companyFullName,
    companyPAN,
    companyPanValidationStatus,
    companyType,
    detailsAboutCompany,
    pinCode,
    region,
    state,
    country,
    CIN,
    companyMobileNumber,
    companyTelephone,
    noOfEMPShowroom,
    noOfEMPWarehouse,
    noOfOffices,
    productCategory,
    security,
    typeOfOffices,
    typeOfShowroom,
    websiteAddress,
    noOfWarehouse,
  }) => {

   console.log("store details", { city, companyFullAddress, companyFullName, companyPAN, companyPanValidationStatus, companyType, detailsAboutCompany, pinCode, region, state, CIN, companyMobileNumber, companyTelephone, noOfEMPShowroom, noOfEMPWarehouse, noOfOffices, productCategory, security, typeOfOffices, typeOfShowroom, websiteAddress, noOfWarehouse, });
    
    companyDetailsDispatch({
      type: AnchorKycActions.COMPANY_DETAILS.CHANGE_COMPANY_NAME,
      payload: companyFullName,
    });
    companyDetailsDispatch({
      type: AnchorKycActions.COMPANY_DETAILS.CHANGE_PAN,
      payload: companyPAN,
    });
    companyDetailsDispatch({
      type: AnchorKycActions.COMPANY_DETAILS.CHANGE_COMPANY_TYPE,
      payload: companyType,
    });
    companyDetailsDispatch({
      type: AnchorKycActions.COMPANY_DETAILS.CHANGE_COMPANY_ADDRESS,
      payload: companyFullAddress,
    });
    companyDetailsDispatch({
      type: AnchorKycActions.COMPANY_DETAILS.CHANGE_CITY,
      payload: city,
    });
    companyDetailsDispatch({
      type: AnchorKycActions.COMPANY_DETAILS.BLUR_CITY,
    });
    companyDetailsDispatch({
      type: AnchorKycActions.COMPANY_DETAILS.CHANGE_COUNTRY,
      payload: country,
    });
    companyDetailsDispatch({
      type: AnchorKycActions.COMPANY_DETAILS.BLUR_COUNTRY,
    });
    companyDetailsDispatch({
      type: AnchorKycActions.COMPANY_DETAILS.CHANGE_STATE,
      payload: state,
    });
    companyDetailsDispatch({
      type: AnchorKycActions.COMPANY_DETAILS.CHANGE_PIN,
      payload: pinCode?.toString(),
    });
    companyDetailsDispatch({
      type: AnchorKycActions.COMPANY_DETAILS.CHANGE_REGION,
      payload: region,
    });
    companyDetailsDispatch({
      type: AnchorKycActions.COMPANY_DETAILS.CHANGE_STORE_DETAILS,
      payload: detailsAboutCompany,
    });

    companyDetailsDispatch({
      type: AnchorKycActions.COMPANY_DETAILS.CHANGE_PRODUCT_CATEGORY,
      payload: productCategory,
    });
    companyDetailsDispatch({
      type: AnchorKycActions.COMPANY_DETAILS.CHANGE_WEBSITE,
      payload: websiteAddress,
    });
    companyDetailsDispatch({
      type: AnchorKycActions.COMPANY_DETAILS.CHANGE_CIN,
      payload: CIN,
    });
    companyDetailsDispatch({
      type: AnchorKycActions.COMPANY_DETAILS.CHANGE_SECURITY,
      payload: security,
    });
    companyDetailsDispatch({
      type: AnchorKycActions.COMPANY_DETAILS.CHANGE_OFFICE_TYPE,
      payload: typeOfOffices,
    });
    companyDetailsDispatch({
      type: AnchorKycActions.COMPANY_DETAILS.CHANGE_NO_OFFICE,
      payload: noOfOffices?.toString(),
    });
    companyDetailsDispatch({
      type: AnchorKycActions.COMPANY_DETAILS.CHANGE_STORE_TYPE,
      payload: typeOfShowroom,
    });
    companyDetailsDispatch({
      type: AnchorKycActions.COMPANY_DETAILS.CHANGE_EMP_STORE,
      payload: noOfEMPShowroom?.toString(),
    });
    companyDetailsDispatch({
      type: AnchorKycActions.COMPANY_DETAILS.CHANGE_EMP_WAREHOUSE,
      payload: noOfEMPWarehouse?.toString(),
    });
    companyDetailsDispatch({
      type: AnchorKycActions.COMPANY_DETAILS.CHANGE_NO_WAREHOUSE,
      payload: noOfWarehouse?.toString(),
    });
    companyDetailsDispatch({
      type: AnchorKycActions.COMPANY_DETAILS.CHANGE_MOBILE,
      payload: companyMobileNumber,
    });
    companyDetailsDispatch({
      type: AnchorKycActions.COMPANY_DETAILS.CHANGE_TELEPHONE,
      payload: companyTelephone,
    });

    companyDetailsDispatch({
      type: AnchorKycActions.COMPANY_DETAILS.BLUR_PAN,
      payload: companyPanValidationStatus,
    });
  };

  /**
   * @param {{ gstValidationStatus: boolean, GSTNumber: string }} param0
   */
  const updateGstDetails = ({ GSTNumber, gstValidationStatus }) => {
    // console.log("----------- GST -----------", GSTNumber, gstValidationStatus);
    
    gstDetailsDispatch({
      type: AnchorKycActions.GST_DETAILS.CHANGE_GST,
      payload: GSTNumber,
    });

    gstDetailsDispatch({
      type: AnchorKycActions.GST_DETAILS.BLUR_GST,
      payload: gstValidationStatus,
    });
  };

  /**
   * @param {{
   *  accountNumber: string,
   *  accountHolderName: string,
   *  bankName: string,
   *  accountType: string,
   *  IFSCCode: string,
   * }} param0
   */
  const updateBankDetails = ({
    IFSCCode,
    accountHolderName,
    accountNumber,
    accountType,
    bankName,
  }) => {
    bankDetailsDispatch({
      type: AnchorKycActions.BANK_DETAILS.CHANGE_ACC_NO,
      payload: accountNumber,
    });
    bankDetailsDispatch({
      type: AnchorKycActions.BANK_DETAILS.CHANGE_ACC_HOLDER_NAME,
      payload: accountHolderName,
    });
    bankDetailsDispatch({
      type: AnchorKycActions.BANK_DETAILS.BLUR_ACC_NO,
    });
    bankDetailsDispatch({
      type: AnchorKycActions.BANK_DETAILS.BLUR_ACC_HOLDER_NAME,
    });
    bankDetailsDispatch({
      type: AnchorKycActions.BANK_DETAILS.CHANGE_BANK_NAME,
      payload: bankName,
    });
    bankDetailsDispatch({
      type: AnchorKycActions.BANK_DETAILS.CHANGE_ACC_TYPE,
      payload: accountType,
    });
    bankDetailsDispatch({
      type: AnchorKycActions.BANK_DETAILS.CHANGE_IFSC_CODE,
      payload: IFSCCode,
    });
    bankDetailsDispatch({
      type: AnchorKycActions.BANK_DETAILS.BLUR_IFSC_CODE,
    });
  };

  /**
   * @param {{ loanRequestAmount: number }} param0
   */
  const updateDocumentDetails = ({ loanRequestAmount }) => {
    documentDetailsDispatch({
      type: AnchorKycActions.DOCUMENT_DETAIL.CHANGE_LOAN,
      payload: loanRequestAmount?.toString(),
    });
  };

  useEffect(() => {
    fetchDocuments();
  }, [fetchDocuments]);

  const handleSubmitForm = () => {
    const states = [
      personalDetails,
      coApplicantDetails,
      companyDetails,
      bankDetails,
      documentDetails,
      gstDetails,
    ];

    //! TRIGGER FOCUS EVENT ON THE FIELDS WHICH ARE NOT FILLED
    personalDetailsDispatch({ type: "TOUCH_ALL" });
    coApplicantDetailsDispatch({ type: "TOUCH_ALL" });
    companyDetailsDispatch({ type: "TOUCH_ALL" });
    bankDetailsDispatch({ type: "TOUCH_ALL" });
    documentDetailsDispatch({ type: "TOUCH_ALL" });
    gstDetailsDispatch({ type: "TOUCH_ALL" });

    if (window.location.pathname !== "/rm/rm-onboarding/anchor") {
      for (let state of states) {
        for (let obj in state) {
          for (let item in state[obj]) {
            if (
              (item === "isVerified" || item === "isValid") &&
              state[obj][item] === false
            ) {
              console.log(state, obj);
              console.log("item", state[obj][item]);
              return;
            }
          }
        }
      }
    }

    // console.log("all are verified");

    if (personalDetails.aadhaarImage.verificationStatus === null) {
      toastMessage("Upload Aadhaar In Personal Details", "error");
      return;
    } else if (
      coApplicantAvailable &&
      coApplicantDetails.aadhaarImage.verificationStatus === null
    ) {
      toastMessage("Upload Aadhaar In Co-Applicant Details", "error");
      return;
    }

    const data = {
      name: personalDetails.name.value,
      dateOfBirth: personalDetails.DOB.value.split("-").reverse().join("/"),
      emailId,
      mobileNumber: personalDetails.mobileNumber.value,
      panValidationStatus: personalDetails.panNo.isVerified, //
      PANNumber: personalDetails.panNo.value.toUpperCase(),
      profilePicture:
        personalDetails.profilePic.fileList?.[0]?.userProfilePic ?? null, // url
      aadhaarValidationStatus: personalDetails.aadhaarImage.isVerified,
      aadharSDK: {
        dateOfBirth:
          personalDetails.aadhaarImage.verificationStatus?.response?.dob ??
          null,
        name:
          personalDetails.aadhaarImage.verificationStatus?.response?.name ??
          null,
        gender:
          personalDetails.aadhaarImage.verificationStatus?.response?.gender ??
          null,
      },
      coApplicant: [
        {
          coPanValidationStatus: coApplicantDetails.panNo.isVerified,
          PANNumber: coApplicantDetails.panNo.value ?? null,
          name: coApplicantDetails.name.value ?? null,
          mobileNumber: coApplicantDetails.mobileNumber.value ?? null,
          coAadhaarValidationStatus: coApplicantDetails.aadhaarImage.isVerified,
          aadharSDK: {
            dateOfBirth:
              coApplicantDetails.aadhaarImage.verificationStatus?.response
                ?.dob ?? null,
            name:
              coApplicantDetails.aadhaarImage.verificationStatus?.response
                ?.name ?? null,
            gender:
              coApplicantDetails.aadhaarImage.verificationStatus?.response
                ?.gender ?? null,
          },
        },
      ],

      companyLogo: companyDetails.logo.fileList?.[0]?.profilePicture ?? "",
      productCategory: companyDetails.productCategory.value,
      companyFullName: companyDetails.companyName.value,
      companyType: companyDetails.companyType.value,
      websiteAddress: companyDetails.websiteAddress.value,
      companyEmail: emailId,
      CIN: companyDetails.cinNumber.value,
      companyPanValidationStatus: companyDetails.panNumber.isVerified,
      companyPAN: companyDetails.panNumber.value,
      security: companyDetails.security.value,
      companyFullAddress: companyDetails.companyAddress.value,
      city: companyDetails.city.value,
      state: companyDetails.state.value,
      pinCode: +companyDetails.pinCode.value,
      region: companyDetails.region.value,
      country: companyDetails.country.value,
      typeOfOffices: companyDetails.typeOfOffices.value,
      noOfOffices: +companyDetails.noOfOffices.value,
      typeOfShowroom: companyDetails.typeOfStoresAndPlant.value,
      noOfEMPShowroom: +companyDetails.noOfEmployeesInStore.value,
      noOfWarehouse: +companyDetails.noOfWarehouses.value,
      companyTelephone: companyDetails.telephoneNumber.value,
      companyMobileNumber: companyDetails.mobileNumber.value,
      noOfEMPWarehouse: +companyDetails.noOfWarehouses.value,
      detailsAboutCompany: companyDetails.storeDetails.value,
      manufacturer: companyDetails.manufacturer,

      accountNumber: bankDetails.accountNumber.value,
      accountHolderName: bankDetails.accountHolderName.value,
      bankName: bankDetails.bankName.value,
      accountType: bankDetails.accountType.value,
      IFSCCode: bankDetails.IFSCCode.value.toUpperCase(),
      bankstatement:
        bankDetails.bankStatement.fileList?.[0]?.bankstatement ?? null,
      roles,
      isPrimary: true,
      gstValidationStatus: gstDetails.gst.isVerified,
      GSTNumber: gstDetails.gst.value.toUpperCase(),
      loanRequestAmount: +documentDetails.loanAmount.value,
      otherDetails: [
        {
          documentName: null,
          document: null,
        },
      ],
    };

    if (window.location.pathname === "/rm/rm-onboarding/anchor") {
      data.createdBy = sessionStorage.getItem("email");
      data.createdRole = sessionStorage.getItem("role");

      dispatch(rmOnboardAnchorDispatch(data))
        .unwrap()
        .then(dat => {
          console.log("dT",data)
          toastMessage(
            dat?.success ?? "KYC Uploaded Successfully",
            "success"
          );
          if(dat?.code==200){
            dispatch(postEquifaxscore({emailId:data?.emailId, role:"seller"}));
          }

          setTimeout(() => {
            navigate("/rm/customer-onboarding");
          }, 3000);

        })
        .catch(err => {
          toastMessage(err.message ?? "Unable to Upload KYC", "error");
        });
      
        return;
    }

    dispatch(updateSellerKYCVerification(data))
      .unwrap() 
      .then((response) => {
        toastMessage(
          response?.success ?? "KYC Uploaded Successfully",
          "success"
        );
        if(response?.code==200){
          dispatch(postEquifaxscore({emailId:data?.emailId, role:"seller"}));
        }
        setTimeout(() => (window.location.pathname = "/login"), 3200);
      })
      .catch((error) => {
        toastMessage(error?.message ?? "Unable to Upload KYC", "error");
      });
  };

  const deleteFiles = (key, url) => {
    const body = { email_id: emailId, image_url: url, key };

    dispatch(updateDeleteFile(body))
      .unwrap()
      .then((response) => {
        toastMessage("Document deleted Successfully", "success");
        fetchDocuments();
      })
      .catch((error) => {
        toastMessage(error?.message ?? "Unable to delete file", "error");
      });
  };

  const uploadFiles = (formData, type) => {
    formData.append("emailId", emailId);
    formData.append("roles", roles);

    if (window.location.pathname.includes("rm")) {
      if (type === "adharImage" || type === "coAadharImage") {
        dispatch(updateAadharOCRDetails(formData))
          .unwrap()
          .then((validationResponse) => {
            if (validationResponse.status) {
              toastMessage(validationResponse?.message ?? "Verification Successfully for Aadhar", "success");
              if (type === "adharImage") {
                personalDetailsDispatch({
                  type: AnchorKycActions.PERSONAL_DETAILS.CHANGE_AADHAAR,
                  payload: {
                    status: validationResponse.status,
                    response: validationResponse?.data?.result?.[0]?.details,
                  },
                });
              } else {
                coApplicantDetailsDispatch({
                  type: AnchorKycActions.PERSONAL_DETAILS.CHANGE_AADHAAR,
                  payload: {
                    status: validationResponse.status,
                    response: validationResponse?.data?.result?.[0]?.details,
                  },
                });
              }
  
              dispatch(rmUploadFiles(formData))
                .unwrap()
                .then((response) => {
                  fetchDocuments();
                })
                .catch((error) => {
                  console.log("error while uploading Aadhaar", error);
                });
            }
          })
          .catch((error) => {
            toastMessage(
              error?.message ?? error?.error ?? "Unable to verify Aadhaar",
              "error"
            );
          });
        } else {
          dispatch(rmUploadFiles(formData))
            .unwrap()
            .then(data => {
              console.log("uploaded file in rm");
              fetchDocuments();
            })
            .catch(err => {
              console.error("error while uploading", err);
            });
      }
      return;
    }

    if (type === "userProfilePic") {
      dispatch(updateUploadProfilePicture(formData))
        .unwrap()
        .then((response) => {
          fetchDocuments();
        })
        .catch((error) => {
          toastMessage(
            error?.message ?? error?.error ?? "Unable to Upload Profile Pic",
            "error"
          );
        });

      return;
    } else if (type === "adharImage" || type === "coAadharImage") {
      dispatch(updateAadharOCRDetails(formData))
        .unwrap()
        .then((validationResponse) => {
          if (validationResponse.status) {
            if (type === "adharImage") {
              personalDetailsDispatch({
                type: AnchorKycActions.PERSONAL_DETAILS.CHANGE_AADHAAR,
                payload: {
                  status: validationResponse.status,
                  response: validationResponse?.data?.result?.[0]?.details,
                },
              });
            } else {
              coApplicantDetailsDispatch({
                type: AnchorKycActions.PERSONAL_DETAILS.CHANGE_AADHAAR,
                payload: {
                  status: validationResponse.status,
                  response: validationResponse?.data?.result?.[0]?.details,
                },
              });
            }

            dispatch(updateUploadKYCDocuments(formData))
              .unwrap()
              .then((response) => {
                fetchDocuments();
              })
              .catch((error) => {
                console.log("error while uploading Aadhaar", error);
              });
          }
        })
        .catch((error) => {
          toastMessage(
            error?.message ?? error?.error ?? "Unable to verify Aadhaar",
            "error"
          );
        });
      return;
    }

    dispatch(updateUploadKYCDocuments(formData))
      .unwrap()
      .then((response) => {
        console.log("bank details updated");
        
        fetchDocuments();
      })
      .catch((error) => {
        toastMessage("Unable to delete File", "error");
      });
  };

  const value = {
    personalDetails,
    personalDetailsDispatch,
    coApplicantDetails,
    coApplicantDetailsDispatch,
    companyDetails,
    companyDetailsDispatch,
    bankDetails,
    bankDetailsDispatch,
    documentDetails,
    documentDetailsDispatch,
    gstDetails,
    gstDetailsDispatch,
    coApplicantAvailable,
    setCoApplicantAvailable,
    files,
    deleteFiles,
    emailId,
    uploadFiles,
    handleSubmitForm: handleSubmitForm, disableEdit
  };

  return (
    <Fragment>
      <AnchorKycContext.Provider value={value}>
        {props?.children ?? null}
      </AnchorKycContext.Provider>
    </Fragment>
  );
};
