import React, { Fragment, useContext, useEffect, useState } from "react";
import { GenericInputField } from "../../../../GenericComponent/Input/Input";
import { GenericSelect } from "../../../../GenericComponent/Select/Select";
import { indianStateList } from "../../../../utilities/indianStateList";
import { GenericFileInput } from "../../../../GenericComponent/FileInput/FileInput";
import { CorporateKycContext } from "../corporateKycContext";
import { actions } from "./companyDetailsReducer";
import { useDispatch } from "react-redux";
import { updatePANVerification } from "../../../../store/slices/generic";
import { toastMessage } from "../../../../layout/GenericLayout/ToastMessage";

/**
 * @type { { label: string, value: string, id?: string, disabled?: boolean }[] }
 */
const productCategoryList = [
	{ label: "Choose Product Category", value: "", disabled: true, id: "0" },
	{ label: "Supply Chain Finance", value: "Supply Chain Finance", id: "1" },
	{ label: "Choose Product Category", value: "Vendor Financing", id: "2" },
];

/**
 * @type { { label: string, value: string, id?: string, disabled?: boolean }[] }
 */
const companyTypeList = [
	{ label: "Choose Type", value: "", disabled: true, id: "0" },
	{ label: "Private Limited", value: "Private Limited", id: "1" },
	{ label: "Public Limited", value: "Public Limited", id: "2" },
	{ label: "Limited Liability Partnership", value: "Limited Liability Partnership", id: "3" },
	{ label: "Partnership", value: "Partnership", id: "4" },
	{ label: "Proprietorship", value: "Proprietorship", id: "5" },
];

/**
 * @type { { label: string, value: string, id?: string, disabled?: boolean }[] }
 */
const securityList = [
	{ label: "Choose Type", value: "", disabled: true, id: "0" },
	{ label: "Enforceable Security", value: "Enforceable Security", id: "1" },
	{ label: "Corporate guarantee & PG of Directors", value: "Corporate guarantee & PG of Directors", id: "2" },
];

/**
 * @type { { label: string, value: string, id?: string, disabled?: boolean }[] }
 */
const officeTypesList = [
	{ label: "Choose Office Type", value: "", disabled: true, id: "0" },
	{ label: "Owned", value: "Owned", id: "1" },
	{ label: "Rented", value: "Rented", id: "2" },
];

/**
 * @type { { label: string, value: string, id?: string, disabled?: boolean }[] }
 */
const storeTypesList = [
	{ label: "Choose Office Type", value: "", disabled: true, id: "0" },
	{ label: "Owned", value: "Owned", id: "1" },
	{ label: "Rented", value: "Rented", id: "2" },
	{ label: "No Store/Plant", value: "No Store/Plant", id: "3" },
];

const statesList = (() => {
	/**
	 * @type { { label: string, value: string, id?: string, disabled?: boolean }[] }
	 */
	const states = [{ label: "Select State", value: "", disabled: true, id: "0" }];

	for (let i = 0; i < indianStateList.length; i++) {
		states.push({
			label: indianStateList[i].stateName,
			value: indianStateList[i].stateName,
			id: (i + 1).toString(),
		});
	}
	return states;
})();

/**
 * @type { { label: string, value: string, id?: string, disabled?: boolean }[] }
 */
const regionList = [
	{ label: "Choose Region", value: "", disabled: true, id: "0" },
	{ label: "East", value: "East", id: "1" },
	{ label: "West", value: "West", id: "2" },
	{ label: "North", value: "North", id: "3" },
	{ label: "South", value: "South", id: "4" },
];

export const CorporateKYCCompanyDetails = (props) => {

	// ----------------------------------> States
	const [ profilePics, setProfilePics ] = useState([]);

	// -----------------------------------> Hooks and Effects

	const { files, companyDetails, companyDetailsDispatch, uploadFiles, emailId } = useContext(CorporateKycContext);

	const dispatch = useDispatch();

	// SET COUNTRY VALUE TO INDIA
	useEffect(() => {
		companyDetailsDispatch({ type: actions.CHANGE_COUNTRY, payload: "India" });
		companyDetailsDispatch({ type: actions.CHANGE_EMAIL, payload: emailId });
	}, [ companyDetailsDispatch, emailId ]);
	
	useEffect(() => {
		if (files?.length > 0 && files?.[0]?.documents && Array.isArray(files[0].documents)) {
			const profilePicsTemp = [];
			for (let item of files[0].documents) {
				if (item?.profilePicture !== undefined) {
					profilePicsTemp.push(item);
				}
			}

			if (profilePicsTemp.length > 0) {
				companyDetailsDispatch({ type: actions.LOGO_UPDATE_API, payload: profilePicsTemp });
			}

			setProfilePics(profilePicsTemp);
		}
	}, [ files, companyDetailsDispatch ]);


	// ----------------------------------> Handlers and functions

	/**
	 * @param { React.ChangeEvent<HTMLInputEvent> } event 
	 */
	const handleLogoChange = event => {
		const formData = new FormData();

		formData.append("profilePicture", event.target.files[0]);

		uploadFiles(formData, "profilePicture");
	}


	const handlePanBlur = () => {
		if (companyDetails.panNumber.value.length === 10) {
			const body = {
				panNumber: companyDetails.panNumber.value,
				userid: emailId,
			};
			
			dispatch(updatePANVerification(body))
				.unwrap()
				.then(response => {
					if (response?.status) {
						companyDetailsDispatch({ type: actions.BLUR_PAN, payload: true });
					}
				})
				.catch(error => {
					companyDetailsDispatch({ type: actions.BLUR_PAN, payload: false });
					toastMessage(error?.message ?? error?.error ?? "Unable to Verify Pan", "error");
				});
		}
	};

	return (
		<Fragment>
			<div className="container-with-heading d-flex flex-column container">
				<span className="kyc-content-title mb-3 row">
					Company Information
				</span>
				<div className="details-container ">
					<div className="row gy-md-4 gy-2">

						<GenericFileInput
							required="true" fileType="profilePicture" label="Logo" isVerified={ companyDetails.logo.isVerified }
							fileList={ profilePics } onChangeHandler={ handleLogoChange } 
						/>

						<GenericSelect
							label="Product Category" value={ companyDetails.productCategory.value } isInvalid={ companyDetails.productCategory.isTouched && !companyDetails.productCategory.isValid }
							optionList={productCategoryList} required={true} errorMessage={ companyDetails.productCategory.value === "" ? "Product Category is Required" : "Choose Product Category" }
							onChangeHandler={ event => companyDetailsDispatch({ type: actions.CHANGE_PRODUCT_CATEGORY, payload: event.target.value }) }
							onBlurHandler={ () => companyDetailsDispatch({ type: actions.BLUR_PRODUCT_CATEGORY }) }
						/>

						<GenericInputField
							label="Company Name" required={true} value={ companyDetails.companyName.value } errorMessage={ companyDetails.companyName.value === "" ? "Company Name is Required" : "Name should contain at least 4 chars" }
							isInvalid={ companyDetails.companyName.isTouched && !companyDetails.companyName.isValid } onChangeHandler={ event => companyDetailsDispatch({ type: actions.CHANGE_COMPANY_NAME, payload: event.target.value }) }
							onBlurHandler={ () => companyDetailsDispatch({ type: actions.BLUR_COMPANY_NAME, }) } 
						/>

						<GenericSelect
							optionList={companyTypeList} required={true} value={ companyDetails.companyType.value } errorMessage={ companyDetails.companyType.value === "" ? "Company Type is Required" : "Invalid Company Type" }
							label="Company Type" onChangeHandler={ event => companyDetailsDispatch({ type: actions.CHANGE_COMPANY_TYPE, payload: event.target.value }) } 
							onBlurHandler={ () => companyDetailsDispatch({ type: actions.BLUR_COMPANY_TYPE, }) } isInvalid={ companyDetails.companyType.isTouched && !companyDetails.companyType.isValid }
						/>

						<GenericInputField
							label="Website Address" required={true} value={ companyDetails.websiteAddress.value } errorMessage={ companyDetails.websiteAddress.value === "" ? "Company Website is Required" : "Invalid Website Address" }
							isInvalid={ companyDetails.websiteAddress.isTouched && !companyDetails.websiteAddress.isValid } onChangeHandler={ event => companyDetailsDispatch({ type: actions.CHANGE_WEBSITE, payload: event.target.value }) }
							onBlurHandler={ () => companyDetailsDispatch({ type: actions.BLUR_WEBSITE, }) }
						/>

						<GenericInputField
							label="Email" disabled={true} required={true} value={ emailId }
						/>

						<GenericInputField
							label="CIN Number" required={true} value={ companyDetails.cinNumber.value } errorMessage={ companyDetails.cinNumber.value === "" ? "CIN Number is Required" : "Invalid CIN Number" }
							isInvalid={ companyDetails.cinNumber.isTouched && !companyDetails.cinNumber.isValid } onChangeHandler={ event => companyDetailsDispatch({ type: actions.CHANGE_CIN, payload: event.target.value }) }
							onBlurHandler={ () => companyDetailsDispatch({ type: actions.BLUR_CIN }) }
						/>

						<GenericInputField
							label="PAN Number" required={true} value={ companyDetails.panNumber.value } errorMessage={ companyDetails.panNumber.value === "" ? "PAN Number is required" : companyDetails.panNumber.isVerified ? "Invalid PAN" : "PAN Number Not Verified" }
							isInvalid={ companyDetails.panNumber.isTouched && (!companyDetails.panNumber.isValid || !companyDetails.panNumber.isVerified) } onChangeHandler={ event => companyDetailsDispatch({ type: actions.CHANGE_PAN, payload: event.target.value }) }
							onBlurHandler={ () => handlePanBlur() } successMessage="PAN Number Verified" verified={ companyDetails.panNumber.isVerified }
						/>

						<GenericSelect
							optionList={ securityList } required={ true } value={ companyDetails.security.value } errorMessage={ companyDetails.security.value === "" && "Security Details is Required" } 
							label="Security" isInvalid={ companyDetails.security.isTouched && !companyDetails.security.isValid } onChangeHandler={ event => companyDetailsDispatch({ type: actions.CHANGE_SECURITY, payload: event.target.value }) }
							onBlurHandler={ () => companyDetailsDispatch({ type: actions.BLUR_SECURITY }) }
						/>

						<GenericInputField
							label="Company Address" required={true} isInvalid={ companyDetails.companyAddress.isTouched && !companyDetails.companyAddress.isValid }
							errorMessage={ companyDetails.companyAddress.value === "" ? "Company Address is Required" : "Address should contain at least 5 chars" } value={ companyDetails.companyAddress.value }
							onChangeHandler={ event => companyDetailsDispatch({ type: actions.CHANGE_COMPANY_ADDRESS, payload: event.target.value }) } onBlurHandler={ () => companyDetailsDispatch({ type: actions.BLUR_COMPANY_ADDRESS }) }
						/>

						<GenericInputField
							label="City" required={true} isInvalid={ companyDetails.city.isTouched && !companyDetails.city.isValid } errorMessage={ companyDetails.city.value === "" ? "City is Required" : "Invalid City Name" }
							value={ companyDetails.city.value } onChangeHandler={ event => companyDetailsDispatch({ type: actions.CHANGE_CITY, payload: event.target.value }) }
							onBlurHandler={ () => companyDetailsDispatch({ type: actions.BLUR_CITY, }) }
						/>

						<GenericSelect
							optionList={statesList} required={true} isInvalid={ companyDetails.state.isTouched && !companyDetails.state.isValid } value={ companyDetails.state.value }
							errorMessage="State is Required" onChangeHandler={ event => companyDetailsDispatch({ type: actions.CHANGE_STATE, payload: event.target.value }) }
							label="State" onBlurHandler={ () => companyDetailsDispatch({ type: actions.BLUR_STATE, }) }
						/>

						<GenericInputField
							label="Pin Code" required={true} type="number" isInvalid={ companyDetails.pinCode.isTouched && !companyDetails.pinCode.isValid } errorMessage={ companyDetails.pinCode.value === "" ? "PinCode is Required" : "Invalid PinCode" }
							onChangeHandler={ event => companyDetailsDispatch({ type: actions.CHANGE_PIN, payload: event.target.value }) } value={ companyDetails.pinCode.value }
							onBlurHandler={ () => companyDetailsDispatch({ type: actions.BLUR_PIN, }) }
						/>

						<GenericSelect
							optionList={regionList} required={true} value={ companyDetails.region.value } onChangeHandler={ event => companyDetailsDispatch({ type: actions.CHANGE_REGION, payload: event.target.value }) }
							label="Region" errorMessage="Region is Required" isInvalid={ companyDetails.region.isTouched && !companyDetails.region.isValid }
							onBlurHandler={ () => companyDetailsDispatch({ type: actions.BLUR_REGION }) }
						/>

						<GenericInputField
							label="Country" required={true} disabled={ true } value={ companyDetails.country.value } 
							isInvalid={ companyDetails.country.isTouched && !companyDetails.country.isValid }
						/>

						<GenericInputField
							label="Telephone Number" required={true} type="number" value={ companyDetails.telephoneNumber.value } errorMessage={ (companyDetails.telephoneNumber.isTouched && companyDetails.telephoneNumber.value === "") ? "Telephone Number is Required" : "Invalid Telephone Number" }
							onChangeHandler={ event => companyDetailsDispatch({ type: actions.CHANGE_TELEPHONE, payload: event.target.value }) }
							onBlurHandler={ () => companyDetailsDispatch({ type: actions.BLUR_TELEPHONE }) } isInvalid={ companyDetails.telephoneNumber.isTouched && !companyDetails.telephoneNumber.isValid }
						/>

						<GenericInputField
							label="Mobile Number" required={true} type="number" errorMessage={ (companyDetails.mobileNumber.isTouched && companyDetails.mobileNumber.value === "") ? "Mobile Number is Required" : "Invalid Mobile Number" }
							onChangeHandler={ event => companyDetailsDispatch({ type: actions.CHANGE_MOBILE, payload: event.target.value }) } value={ companyDetails.mobileNumber.value }
							onBlurHandler={ () => companyDetailsDispatch({ type: actions.BLUR_MOBILE }) } isInvalid={ companyDetails.mobileNumber.isTouched && !companyDetails.mobileNumber.isValid }
						/>

						<div className="kyc-fields-container col-12  col-md-8">
							<label
								htmlFor="aboutCompany"
								className="kyc-label-text form-label"
							>
								Details about the Store / Promoters
								<span className="text-danger fs-5">*</span>
							</label>
							<textarea
								onChange={ event => companyDetailsDispatch({ type: actions.CHANGE_STORE_DETAILS, payload: event.target.value }) }
								id="aboutCompany"
								type="text"
								name="DetailAboutCompany"
								onBlur={ () => companyDetailsDispatch({ type: actions.BLUR_STORE_DETAILS }) }
								className={ `kyc-input-field form-control ${ (companyDetails.storeDetails.isTouched && !companyDetails.storeDetails.isValid) ? "invalid-input": "" }` }
								value={ companyDetails.storeDetails.value }
							></textarea>
							<span className="text-danger px-1">
								{ companyDetails.storeDetails.isTouched && companyDetails.storeDetails.value === "" ? "Store Details is Required" :
									(companyDetails.storeDetails.isTouched && !companyDetails.storeDetails.isValid) ? "Store Details should contain more than 5 chars" : "" }
							</span>
						</div>

					</div>
				</div>
			</div>
		</Fragment>
	);
};