import { BsDownload } from "react-icons/bs";
import { FaDownload } from "react-icons/fa";

const CompanyKYC = ({ scoreCardDetails }) => {
  return (
    <div className="py-2 d-flex flex-column justify-content-around h-100">
      <div>
        <span className="fw-semibold text-secondary">CIN : </span>
        <span className="fw-semibold text-success">
          {scoreCardDetails[0]?.cin || (
            <span className="text-danger mx-3">N/A</span>
          )}
        </span>
      </div>

      <div>
        <span className="fw-semibold text-secondary">Company Name : </span>
        <span className="fw-semibold text-success">
          {scoreCardDetails[0]?.company_name || (
            <span className="text-danger mx-3">N/A</span>
          )}
        </span>
      </div>

      <div>
        <span className="fw-semibold text-secondary">Company PAN : </span>
        <span className="fw-semibold text-success">
          {scoreCardDetails[0]?.company_pan || (
            <span className="text-danger mx-3">N/A</span>
          )}
        </span>
      </div>

      <div>
        <span className="fw-semibold text-secondary">
          Date of Incorporation :{" "}
        </span>
        <span className="fw-semibold text-success">
          {scoreCardDetails[0]?.date_of_Incorporation || (
            <span className="text-danger mx-3">N/A</span>
          )}
        </span>
      </div>

      <div>
        <span className="fw-semibold text-secondary">MCA Report : </span>

        {scoreCardDetails[0]?.mca_report ? (
          <a
            className="text-decoration-none btn btn btn-outline-success py-0"
            target="_blank"
            href={scoreCardDetails[0]?.mca_report}
            download
          >
            <span className="d-flex align-items-center gap-3">
              Download <BsDownload />
            </span>
          </a>
        ) : (
          <span className="fw-semibold text-danger">N/A</span>
        )}
      </div>
    </div>
  );
};

export default CompanyKYC;
