export const AnchorKycActions = Object.freeze({
	// Common for all the objects
	UPDATE_FROM_API: "UPDATE_FROM_API",

	// Types for Personal Details
	PERSONAL_DETAILS: Object.freeze({
		CHANGE_NAME: "CHANGE_NAME",
		BLUR_NAME: "BLUR_NAME",
		CHANGE_DOB: "CHANGE_DOB",
		BLUR_DOB: "BLUR_DOB",
		CHANGE_PAN: "CHANGE_PAN",
		BLUR_PAN: "BLUR_PAN",
		CHANGE_MOBILE: "CHANGE_MOBILE",
		BLUR_MOBILE: "BLUR_MOBILE",
		CHANGE_AADHAAR: "CHANGE_AADHAAR",
		BLUR_AADHAAR: "BLUR_AADHAAR",
		AADHAAR_UPDATE_API: "AADHAAR_UPDATE_API",
		CHANGE_PROFILE_PIC: "CHANGE_PROFILE_PIC",
		BLUR_PROFILE_PIC: "BLUR_PROFILE_PIC",
		PROFILE_PIC_UPDATE_API: "PROFILE_PIC_UPDATE_API",
		TOUCH_ALL: "TOUCH_ALL",
	}),

	CO_APPLICANT_DETAILS: Object.freeze({
		CHANGE_NAME: "CHANGE_NAME",
		BLUR_NAME: "BLUR_NAME",
		CHANGE_PAN: "CHANGE_PAN",
		BLUR_PAN: "BLUR_PAN",
		CHANGE_MOBILE: "CHANGE_MOBILE",
		BLUR_MOBILE: "BLUR_MOBILE",
		CHANGE_AADHAAR: "CHANGE_AADHAAR",
		BLUR_AADHAAR: "BLUR_AADHAAR",
		AADHAAR_UPDATE_API: "AADHAAR_UPDATE_API",
		EXCLUDE_CO_APPLICANT: "EXCLUDE_CO_APPLICANT",
		INCLUDE_CO_APPLICANT: "INCLUDE_CO_APPLICANT",
		TOUCH_ALL: "TOUCH_ALL",
	}),

	COMPANY_DETAILS: Object.freeze({
		CHANGE_LOGO: "CHANGE_LOGO",
		BLUR_LOGO: "BLUR_LOGO",
		LOGO_UPDATE_API: "LOGO_UPDATE_API",
		CHANGE_PRODUCT_CATEGORY: "CHANGE_PRODUCT_CATEGORY",
		BLUR_PRODUCT_CATEGORY: "BLUR_PRODUCT_CATEGORY",
		CHANGE_MANUFACTURER: "CHANGE_MANUFACTURER",
		CHANGE_COMPANY_TYPE: "CHANGE_COMPANY_TYPE",
		BLUR_COMPANY_TYPE: "BLUR_COMPANY_TYPE",
		CHANGE_COMPANY_NAME: "CHANGE_COMPANY_NAME",
		BLUR_COMPANY_NAME: "BLUR_COMPANY_NAME",
		CHANGE_WEBSITE: "CHANGE_WEBSITE",
		CHANGE_EMAIL: "CHANGE_EMAIL",
		BLUR_WEBSITE: "BLUR_WEBSITE",
		CHANGE_CIN: "CHANGE_CIN",
		BLUR_CIN: "BLUR_CIN",
		CONFIG_CIN:"CONFIG_CIN",
		CHANGE_PAN: "CHANGE_PAN",
		BLUR_PAN: "BLUR_PAN",
		CHANGE_SECURITY: "CHANGE_SECURITY",
		BLUR_SECURITY: "BLUR_SECURITY",
		CHANGE_COMPANY_ADDRESS: "CHANGE_COMPANY_ADDRESS",
		BLUR_COMPANY_ADDRESS: "BLUR_COMPANY_ADDRESS",
		CHANGE_CITY: "CHANGE_CITY",
		BLUR_CITY: "BLUR_CITY",
		CHANGE_STATE: "CHANGE_STATE",
		BLUR_STATE: "BLUR_STATE",
		CHANGE_PIN: "CHANGE_PIN",
		BLUR_PIN: "BLUR_PIN",
		CHANGE_REGION: "CHANGE_REGION",
		BLUR_REGION: "BLUR_REGION",
		CHANGE_COUNTRY: "CHANGE_COUNTRY",
		BLUR_COUNTRY: "BLUR_COUNTRY",
		CHANGE_OFFICE_TYPE: "CHANGE_OFFICE_TYPE",
		BLUR_OFFICE_TYPE: "BLUR_OFFICE_TYPE",
		CHANGE_NO_OFFICE: "CHANGE_NO_OFFICE",
		BLUR_NO_OFFICES: "BLUR_NO_OFFICES",
		CHANGE_STORE_TYPE: "CHANGE_STORE_TYPE",
		BLUR_STORE_TYPE: "BLUR_STORE_TYPE",
		CHANGE_EMP_STORE: "CHANGE_EMP_STORE",
		BLUR_EMP_STORE: "BLUR_EMP_STORE",
		CHANGE_NO_WAREHOUSE: "CHANGE_NO_WAREHOUSE",
		BLUR_NO_WAREHOUSE: "BLUR_NO_WAREHOUSE",
		CHANGE_TELEPHONE: "CHANGE_TELEPHONE",
		BLUR_TELEPHONE: "CHANGE_TELEPHONE",
		CHANGE_MOBILE: "CHANGE_MOBILE",
		BLUR_MOBILE: "BLUR_MOBILE",
		CHANGE_EMP_WAREHOUSE: "CHANGE_EMP_WAREHOUSE",
		BLUR_EMP_WAREHOUSE: "BLUR_EMP_WAREHOUSE",
		CHANGE_STORE_DETAILS: "CHANGE_STORE_DETAILS",
		BLUR_STORE_DETAILS: "BLUR_STORE_DETAILS",
		TOUCH_ALL: "TOUCH_ALL",
	}),

	BANK_DETAILS: Object.freeze({
		CHANGE_ACC_NO: "CHANGE_ACC_NO",
		BLUR_ACC_NO: "BLUR_ACC_NO",
		CHANGE_ACC_HOLDER_NAME: "CHANGE_ACC_HOLDER_NAME",
		BLUR_ACC_HOLDER_NAME: "BLUR_ACC_HOLDER_NAME",
		CHANGE_BANK_NAME: "CHANGE_BANK_NAME",
		BLUR_BANK_NAME: "BLUR_BANK_NAME",
		CHANGE_ACC_TYPE: "CHANGE_ACC_TYPE",
		BLUR_ACC_TYPE: "BLUR_ACC_TYPE",
		CHANGE_IFSC_CODE: "CHANGE_IFSC_CODE",
		BLUR_IFSC_CODE: "BLUR_IFSC_CODE",
		CHANGE_BANK_STATEMENT: "CHANGE_BANK_STATEMENT",
		BLUR_BANK_STATEMENT: "BLUR_BANK_STATEMENT",
		BANK_STATEMENT_UPDATE_API: "BANK_STATEMENT_UPDATE_API",
		TOUCH_ALL: "TOUCH_ALL",
	}),

	GST_DETAILS: Object.freeze({
		CHANGE_GST: "CHANGE_GST",
		BLUR_GST: "BLUR_GST",
		TOUCH_ALL: "TOUCH_ALL",
	}),

	DOCUMENT_DETAIL: Object.freeze({
		CHANGE_LOAN: "CHANGE_LOAN",
		BLUR_LOAN: "BLUR_LOAN",
		TOUCH_ALL: "TOUCH_ALL",
	}),
});
