import { HiOutlineArrowNarrowDown } from "react-icons/hi";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import { BiSort } from "react-icons/bi";
import TablePagination, {
  jumpToPage,
  nextPage,
  previousPage,
} from "../../../../layout/GenericLayout/TablePagination";
import { useEffect, useState } from "react";
import Loader from "../../../../layout/GenericLayout/Loader";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CiUndo } from "react-icons/ci";
import {
  getInvoiceBillOfExchange,
  getInvoiceLorryReceipt,
  getUploadBOE,
} from "../../../../store/slices/buyer";
import ReusableModal from "../../../../layout/GenericLayout/ReusableModal";
import ReusableMessageModal from "../../../../layout/GenericLayout/ReusableMessageModel";
import PaymentPage from "../../../../Pages/PaymentPage";

const ShowPdf = (props) => {
  // console.log("I have PDF LINK cross Verifing: ", props.pdfLink)
  return (
    <div>
      <iframe
        src={props.pdfLink}
        width="100%"
        height="600px"
        title="PDF Viewer"
      />
    </div>
  );
};

const AlertResponse = (props) => {
  // console.log("I have PDF LINK cross Verifing: ", props.alertMsg)
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ maxWidth: "100%" }}
    >
      <p>{props?.alertMsg}</p>
    </div>
  );
};

const GetPaymentUser = (props) => {
  console.log(typeof props?.amount);
  const [payment, setPayment] = useState(props?.amount);
  const [notes, setNotes] = useState("");
  
  return (
    <div className="m-2 p-3 d-flex justify-content-center align-items-start flex-column gap-3">
      <h4>Enter the Payment Amount</h4>
      <div className="d-flex flex-column gap-2" style={{width:"100%"}}>
        <div> Amount:</div>
        <div className="d-flex">
          <input
            type="number"
            value={payment}
            placeholder="Enter the amount"
            className="w-100"
            onChange={(event) => {
              setPayment(parseFloat(event.target.value));
            }}
          />
          <div className="py-2"><CiUndo onClick={() => setPayment(props?.amount)} /></div>
        </div>
      </div>

      <div className="d-flex flex-column gap-2" style={{width:"95%"}}>
        <div>Additional Notes:</div>
        <input 
          type="text" 
          value={notes}
          className="w-100 p-1"
          onChange={(event) => {
            setNotes(event.target.value);
          }}  
        />
      </div>

      <div className="d-flex w-100 justify-content-end gap-5 my-3">
        <button
          type="button"
          className="btn btn-danger"
          onClick={props?.cancel}
        >
          Cancel
        </button>
        <PaymentPage 
         closePayment={props?.cancel}
         amount={payment}
         invoiceId={props?.invoiceId}
         sellerId={props?.sellerId}
         orderNote={notes}
         role="buyerDigital"
         >
          <button type="button" className="btn btn-primary">
            Make Payment
          </button>
        </PaymentPage>
      </div>

    </div>
  );
};

const InvoiceDigitalTable = (props) => {
  const currentLocation = useLocation();
  const dispatch = useDispatch();

  // *************************************
  // Selectors
  // *************************************
  const billOfExchangeResponse = useSelector(
    (state) => state.buyer.invoiceBillOfExchange
  );
  const lorryReceiptResponse = useSelector(
    (state) => state.buyer.invoiceLorryReceipt
  );
  const uploadBOEFilesResponse = useSelector(
    (state) => state.buyer.uploadBOEFiles
  );

  // ****************************************************************
  //   Use State
  // ****************************************************************
  const [billOfExchange, setBillOfExchange] = useState(null);
  const [billOfExchangeViewClick, setBillOfExchangeViewClick] = useState(false);

  const [lorryReceipt, setLorryReceipt] = useState(null);
  const [lorryReceiptViewClick, setLorryReceiptViewClick] = useState(false);

  const [showAcceptedInvoiceAlert, setShowAcceptedInvoiceAlert] = useState();
  const [showRejectedInvoiceAlert, setShowRejectedInvoiceAlert] = useState();
  const [showPdfBOEModalStatus, setShowPdfBOEModalStatus] = useState(false);
  const [showPaymentWindow, setShowPaymentWindow] = useState(false);

  const [showPdfLorryReceiptModalStatus, setShowLorryReceiptModalStatus] =
    useState(false);
  const [showErrorBOEModalMessageStatus, setShowErrorBOEModalMessageStatus] =
    useState(false);
  const [
    showRejectLorryReceiptModalMessageStatus,
    setShowRejectLorryReceiptModalMessageStatus,
  ] = useState(false);
  const [showBOEUploadFileStatus, setShowBOEUploadFileStatus] = useState();
  const [paymentAmount, setPaymentAmount] = useState();
  const [paymentSellerId,setPaymentSellerId]=useState();
  const[dispatchedID, setDispatchedID]=useState("");

  // *******************************************************
  // Toggle models
  // *******************************************************
  const toggleRejectBOEModal = () => {
    setShowPdfBOEModalStatus(!showPdfBOEModalStatus);
  };
  const toggleRejectLorryReceiptModal = () => {
    setShowLorryReceiptModalStatus(!showPdfLorryReceiptModalStatus);
  };
  const toggleErrorBOEMessageModal = () => {
    setShowErrorBOEModalMessageStatus(!showErrorBOEModalMessageStatus);
  };
  const toggleRejectLorryReceiptMessageModal = () => {
    setShowRejectLorryReceiptModalMessageStatus(
      !showRejectLorryReceiptModalMessageStatus
    );
  };
  const toggleShowBOEUploadFileStatus = () => {
    setShowBOEUploadFileStatus(!showBOEUploadFileStatus);
  };
  const toggleShowAcceptInvoiceAlert = () => {
    setShowAcceptedInvoiceAlert(!showAcceptedInvoiceAlert);
  };
  const toggleShowRejectedInvoiceAlert = () => {
    setShowRejectedInvoiceAlert(!showRejectedInvoiceAlert);
  };
  const toggleShowPaymentWindow = () => {
    setShowPaymentWindow(!showPaymentWindow);
  };

  const updatePage = (currentPage) => {
    const newOffSet = props.tableQueryParams.tableLimit * (currentPage - 1);
    props.setTableQueryParams((prev) => ({
      ...prev,
      tableOffset: newOffSet,
      currentPage,
    }));
  };

  const onClickActiveSort = (activeSortValue) => {
    if (activeSortValue !== "") {
      let order = -1;
      if (activeSortValue === props.tableQueryParams.tableSort) {
        if (props.tableQueryParams.tableOrder === -1) {
          order = 1;
        }
      }
      props.setTableQueryParams((prev) => ({
        ...prev,
        tableSort: activeSortValue,
        tableOrder: order,
      }));
    }
  };

  const onClickViewMoreTableData = () => {
    let newLimit = props.tableQueryParams.tableLimit;
    if (newLimit === 5) {
      newLimit += 5;
    } else {
      newLimit -= 5;
    }
    props.setTableQueryParams((prev) => ({
      ...prev,
      tableLimit: newLimit,
    }));
  };

  const handleViewBOE = (sellerId, InvoiceId) => {
    setDispatchedID(InvoiceId)
    dispatch(
      getInvoiceBillOfExchange({
        sellerID: sellerId,
        invoiceID: InvoiceId,
      })
    );
    setBillOfExchangeViewClick(true);
  };

  const handleLorryReceipt = (sellerId, InvoiceId) => {
    setDispatchedID(InvoiceId)
    dispatch(
      getInvoiceLorryReceipt({
        sellerID: sellerId,
        invoiceID: InvoiceId,
      })
    );
    setLorryReceiptViewClick(true);
  };

  useEffect(() => {
    if (billOfExchangeResponse.data && billOfExchangeViewClick) {
      setBillOfExchange(billOfExchangeResponse.data);
      setShowPdfBOEModalStatus(true);
    }
  }, [billOfExchangeResponse.data, billOfExchangeViewClick]);

  useEffect(() => {
    if (billOfExchangeResponse.error && billOfExchangeViewClick) {
      setShowErrorBOEModalMessageStatus(true);
    }
  }, [billOfExchangeResponse.error, billOfExchangeViewClick]);

  useEffect(() => {
    if (lorryReceiptResponse.data && lorryReceiptViewClick) {
      setLorryReceipt(lorryReceiptResponse.data);
      setShowLorryReceiptModalStatus(true);
    }
  }, [lorryReceiptResponse.data, lorryReceiptViewClick]);

  useEffect(() => {
    if (lorryReceiptResponse.error && lorryReceiptViewClick) {
      setShowRejectLorryReceiptModalMessageStatus(true);
    }
  }, [lorryReceiptResponse.error, lorryReceiptViewClick]);

  const handleBOEFileUpload = (
    billOfExchange,
    invoiceId,
    manualInvoice,
    sellerId
  ) => {
    const formData = new FormData();
    formData.append("billOfExchange", billOfExchange);
    formData.append("invoiceId", invoiceId);
    formData.append("manualInvoice", manualInvoice);
    formData.append("sellerId", sellerId);
    dispatch(getUploadBOE(formData));
    toggleShowBOEUploadFileStatus();
  };

  const handleDigitalInvoiceNumberAccepted = () => {
    setShowAcceptedInvoiceAlert(true);
  };
  const handleDigitalInvoiceNumberRejected = () => {
    setShowRejectedInvoiceAlert(true);
  };

  const handleMakePayment = (amount,invoiceId, sellerId) => {
    setDispatchedID(invoiceId)
    setPaymentSellerId(sellerId);
    setShowPaymentWindow(true);
    setPaymentAmount(amount);
  };

  const returnProperDateFormat = (dateString) => {
    const date = new Date(dateString);
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthInText = monthNames[date.getMonth()];

    if (
      dateString === undefined ||
      date === undefined ||
      date === null ||
      isNaN(date)
    ) {
      return dateString;
    } else {
      if (date.getHours() === 0) {
        return date.getDate() + " " + monthInText + " " + date.getFullYear();
      } else {
        return (
          date.getDate() +
          " " +
          monthInText +
          " " +
          date.getFullYear() +
          " " +
          date.getHours() +
          ":" +
          date.getMinutes() +
          ":" +
          date.getSeconds()
        );
      }
      // return   dateString
    }
  };

  // console.log('--------------------------------------------');
  // console.log('showPdfBOEModalStatus', showPdfBOEModalStatus);
  // console.log('showPdfLorryReceiptModalStatus', showPdfLorryReceiptModalStatus);
  // console.log('showErrorBOEModalMessageStatus', showErrorBOEModalMessageStatus);
  // console.log('showRejectLorryReceiptModalMessageStatus', showRejectLorryReceiptModalMessageStatus);
  // console.log('showBOEUploadFileStatus', showBOEUploadFileStatus);
  // console.log('--------------------------------------------');

  // console.log(lorryReceipt?.items[0]?.URL)
  // console.log(lorryReceiptError);
  return (
    <>
      {showPdfBOEModalStatus && (
        <ReusableModal
          showModal={showPdfBOEModalStatus}
          toggleModal={toggleRejectBOEModal}
          modalContent={<ShowPdf pdfLink={billOfExchange?.items[0]?.URL} />}
          modalSizeClass="modal-lg"
        />
      )}
      {showErrorBOEModalMessageStatus && (
        <ReusableMessageModal
          showModal={showErrorBOEModalMessageStatus}
          toggleModal={toggleErrorBOEMessageModal}
          msgType={"alert"}
          noBtn={false}
          modalContent={
            <AlertResponse alertMsg={billOfExchangeResponse?.error} />
          }
          modalSizeClass="modal-lg"
        />
      )}
      {showPdfLorryReceiptModalStatus && (
        <ReusableModal
          showModal={showPdfLorryReceiptModalStatus}
          toggleModal={toggleRejectLorryReceiptModal}
          modalContent={<ShowPdf pdfLink={lorryReceipt?.items[0]?.URL} />}
          modalSizeClass="modal-lg"
        />
      )}

      {showRejectLorryReceiptModalMessageStatus && (
        <ReusableMessageModal
          showModal={showRejectLorryReceiptModalMessageStatus}
          toggleModal={toggleRejectLorryReceiptMessageModal}
          msgType={"alert"}
          noBtn={false}
          modalContent={
            <AlertResponse alertMsg={lorryReceiptResponse?.error} />
          }
          modalSizeClass="modal-lg"
        />
      )}
      {showBOEUploadFileStatus && (
        <ReusableMessageModal
          showModal={showBOEUploadFileStatus}
          toggleModal={toggleShowBOEUploadFileStatus}
          msgType={"alert"}
          noBtn={false}
          modalContent={
            <AlertResponse alertMsg={lorryReceiptResponse.data?.status} />
          }
          modalSizeClass="modal-lg"
        />
      )}
      {showAcceptedInvoiceAlert && (
        <ReusableMessageModal
          showModal={showAcceptedInvoiceAlert}
          toggleModal={toggleShowAcceptInvoiceAlert}
          msgType={"alert"}
          noBtn={false}
          modalContent={<AlertResponse alertMsg={"Invoice Already Accepted"} />}
          modalSizeClass="modal-lg"
        />
      )}
      {showRejectedInvoiceAlert && (
        <ReusableMessageModal
          showModal={showRejectedInvoiceAlert}
          toggleModal={toggleShowRejectedInvoiceAlert}
          msgType={"alert"}
          noBtn={false}
          modalContent={<AlertResponse alertMsg={"Rejected Invoice"} />}
          modalSizeClass="modal-lg"
        />
      )}
      {showPaymentWindow && (
        <ReusableMessageModal
          showModal={showPaymentWindow}
          toggleModal={toggleShowPaymentWindow}
          //  msgType={"alert"}
          noBtn={true}
          modalContent={
            <GetPaymentUser
              amount={paymentAmount}
              cancel={toggleShowPaymentWindow}
              invoiceId={dispatchedID}
              sellerId={paymentSellerId}
            />
          }
          modalSizeClass="modal-lg"
        />
      )}
      <div className="buyer-table-container">
        <div className="table-inner-cont">
          <table className={props.className}>
            <thead>
              <tr>
                {props.tableHeader.map((eachHeader) => (
                  <th
                    key={eachHeader.name}
                    scope="col"
                    // role={eachHeader.sortValue !== "" ? "button" : false}
                    className={`${
                      props.tableQueryParams.tableSort ===
                        eachHeader.sortValue &&
                      eachHeader.sortValue !== "" &&
                      "active-sort-text"
                    }`}
                    onClick={() => onClickActiveSort(eachHeader.sortValue)}
                  >
                    {eachHeader.name === `Total Amount` ? (
                      <>
                        {eachHeader.name} &#8377;
                        {eachHeader.sortValue !== "" && <BiSort />}
                      </>
                    ) : (
                      <>
                        {eachHeader.name}
                        {eachHeader.sortValue !== "" && <BiSort />}
                      </>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            {props.materialRequestMetaData?.paging?.total<0?(<div className="no-data text-danger">Oops! there is no data to show. Please check back later</div>):null} 
            {props.isLoading ? (
              <tbody>
                <tr>
                  <td colSpan={14}>
                    <Loader className="my-5" />
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {props.tableRows.map((eachRows, index) => (
                  <tr key={eachRows.invoiceId} scope="col">
                    {/* <td>{eachRows.addedDate.slice(0, 10)}</td> */}

                    <td>{returnProperDateFormat(eachRows.addedDate)}</td>
                    <td>
                      {eachRows.status === "New" && (
                        <>
                          <Link
                            to={`${currentLocation.pathname}/${eachRows.invoiceId}`}
                            state={eachRows.sellerId}
                            className="remove-underline-link"
                          >
                            {eachRows.invoiceId}
                          </Link>
                        </>
                      )}
                      {eachRows.status === "Accepted" && (
                        <>
                          <Link
                            className="remove-underline-link"
                            onClick={handleDigitalInvoiceNumberAccepted}
                          >
                            {eachRows.invoiceId}
                          </Link>
                        </>
                      )}
                      {eachRows.status === "Rejected" && (
                        <>
                          <Link
                            className="remove-underline-link"
                            onClick={handleDigitalInvoiceNumberRejected}
                          >
                            {eachRows.invoiceId}
                          </Link>
                        </>
                      )}
                    </td>
                    <td>{eachRows?.poId}</td>
                    <td>{eachRows?.totalAmount}</td>
                    <td>{eachRows?.invoiceStatus}</td>
                    <td>{eachRows?.dueDate ? eachRows?.dueDate : "N/A"}</td>
                    <td>
                      {eachRows?.outstandingAmount ? (
                        <>₹ {eachRows?.outstandingAmount}</>
                      ) : (
                        <>{"N/A"}</>
                      )}
                    </td>
                    <td>
                      {eachRows?.overDueAmount ? (
                        eachRows?.overDueAmount
                      ) : (
                        <>{"N/A"}</>
                      )}
                    </td>
                    <td>
                      {eachRows?.remainingAmount ? (
                        <>₹ {eachRows?.remainingAmount}</>
                      ) : (
                        <>{"N/A"}</>
                      )}
                    </td>
                    <td>
                      {eachRows.boeId ? (
                        <Link
                          to={`${currentLocation.pathname}/BOE/${eachRows.boeId}`}
                          state={eachRows.sellerId}
                          className="remove-underline-link"
                        >
                          {eachRows.boeId}
                        </Link>
                      ) : (
                        <>{"N/A"}</>
                      )}
                    </td>
                    <td>
                      {eachRows?.boeInserted ? (
                        <span style={{ cursor: "not-allowed" }}>
                          {`File Exist`}
                        </span>
                      ) : (
                        <>
                          <input
                            className="d-none"
                            id="invoiceDigitalBOEFileUploadInput"
                            type="file"
                            onChange={(event) => {
                              handleBOEFileUpload(
                                event.target.files[0],
                                eachRows.invoiceId,
                                eachRows.manualInvoice,
                                eachRows.sellerId
                              );
                            }}
                          />
                          <label
                            htmlFor={"invoiceDigitalBOEFileUploadInput"}
                            className="hightLight-td"
                          >
                            Choose File
                          </label>
                        </>
                      )}
                    </td>
                    <td className="row-hight-light-txt ">
                      <button
                        className="w-75 hightLight-td"
                        onClick={() => {
                          handleViewBOE(eachRows.sellerId, eachRows.invoiceId);
                        }}
                      >
                         {( dispatchedID===eachRows.invoiceId && billOfExchangeResponse.isLoading) ? (
                    <span className="loader mx-auto"></span>
                  ) : (
                    "VIEW"
                  )}
       
                      </button>
                    </td>
                    <td className="row-hight-light-txt">
                      <button
                        className="w-75 hightLight-td"
                        onClick={() => {
                          handleLorryReceipt(
                            eachRows?.sellerId,
                            eachRows?.invoiceId
                          );
                        }}
                      >
                        {( dispatchedID===eachRows.invoiceId && lorryReceiptResponse.isLoading) ? (
                    <span className="loader mx-auto"></span>
                  ) : (
                    "VIEW"
                  )}
                      </button>
                    </td>
                    <td>
                      {/* <PaymentPage amount={eachRows?.invoiceAmount}> */}
                      <button
                        className="w-75 hightLight-success-td"
                        onClick={() =>
                          handleMakePayment(eachRows?.outstandingAmount, eachRows?.invoiceId, eachRows?.sellerId)
                        }
                      >
                         {( dispatchedID===eachRows.invoiceId && showPaymentWindow) ? (
                          <span className="loader mx-auto"></span>
                            ) : (
                            "Make Payment"
                          )}
                      </button>
                      {/* </PaymentPage> */}
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
        {!props.isLoading && props.materialRequestMetaData?.paging?.total>5 && (
          <div className="d-flex justify-content-end px-2">
            <span
              onClick={onClickViewMoreTableData}
              className="d-flex align-items-center justify-content-start gap-1 my-3 view-more-text"
            >
             {props.tableQueryParams.tableLimit === 5
               ? props.tableQueryParams.tableOffset+props.tableQueryParams.tableLimit<props.materialRequestMetaData?.paging?.total?"View More ":""
               : props.tableQueryParams.tableOffset+props.tableQueryParams.tableLimit<props.materialRequestMetaData?.paging?.total?"View Less ":""}
              <HiOutlineArrowNarrowDown
                className={`fs-5 ${
                  props.tableQueryParams.tableLimit === 5
                  ? props.tableQueryParams.tableOffset+props.tableQueryParams.tableLimit<props.materialRequestMetaData?.paging?.total? "down-arrow":"d-none"
                  : props.tableQueryParams.tableOffset+props.tableQueryParams.tableLimit<props.materialRequestMetaData?.paging?.total? "up-arrow":"d-none"}
                `}
              />
            </span>
          </div>
        )}
      </div>
      <div className="d-flex align-items-center gap-2 page-data-text-container mt-3 px-3 w-100">
        <TablePagination
          pageSize={props.tableQueryParams.tableLimit}
          onUpdatePage={updatePage}
          current={props.tableQueryParams.currentPage}
          total={props.materialRequestMetaData?.paging?.total}
        ></TablePagination>
      </div>
    </>
  );
};

export default InvoiceDigitalTable;
