import React, { useState } from "react";
import BannerImg from "../assests/images/rodion-kutsaiev-JKGjV3E-HnY-unsplash@2x.png";
import atonLogo from "../assests/images/Aton-logo.png";
import { useNavigate } from "react-router-dom";

const SelectRole = () => {
  const navigate = useNavigate();
  const [activeRole, setActiveRole] = useState("");

  const onClickSelectRole = (event) => {
    setActiveRole(event.target.value);
  };

  const onClickNextButton = () => {
    // Redirection needs to implemented with the respective page.
    // console.log(activeRole);
    if (activeRole === "") {
      let roleButtonSelectors = document.querySelectorAll(
        ".select-role-container"
      );
      roleButtonSelectors.forEach((eachRoleButton) =>
        eachRoleButton.classList.add("shakeAlert")
      );
    } else {
      navigate("/sign-up", {
        replace: true,
        state: { activeRole },
      });
    }
  };

  return (
    <>
      <div style={{ position: "relative", width: "100%", height: "100%" }}>
        <div className="row m-0 p-0">
          <div className="col-md-2"></div>
          <div className="col-md-8 mt-5">
            <div className="row d-flex align-items-center">
              <div className="col-md-8">
                <span className="txt-welc">Welcome!</span> <br />
                <span className="txt-yr-acc">Select role</span>
              </div>
              <div className="col-md-4 d-flex justify-content-end">
                <img src={atonLogo} style={{ width: "80px" }} />
              </div>
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>

        <div className="row mt-3">
          <div className="col-md-2"></div>
          <div className="col-md-8 mt-5">
            <div className="px-3 mb-4 d-flex justify-content-center gap-5 flex-row">
              {/* <button
                className={`${
                  activeRole === "admin"
                    ? "active-role"
                    : "select-role-container"
                }`}
                value="admin"
                onClick={onClickSelectRole}
                disable={ true }
              >
                Admin
              </button>
              <button
                className={`${
                  activeRole === "lender"
                    ? "active-role"
                    : "select-role-container"
                }`}
                value="lender"
                onClick={onClickSelectRole}
                disable={ true }
              >
                Lender
              </button> */}
              <button
                className={`${
                  activeRole === "seller"
                    ? "active-role"
                    : "select-role-container"
                }`}
                value="seller"
                onClick={onClickSelectRole}
                style={{ padding: "3rem" }}
              >
                Anchor
              </button>
              <button
                className={`${
                  activeRole === "buyer"
                    ? "active-role"
                    : "select-role-container"
                }`}
                value="buyer"
                onClick={onClickSelectRole}
                style={{ padding: "3rem" }}
              >
                Counter Party
              </button>
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>
        <div className="col-12 col-md-5 sign-up-text px-4 mt-5">
          Already have an account?{" "}
          <span
            className="sign-up-text-highlighted"
            onClick={() => navigate("/login", { replace: true })}
          >
            Sign in
          </span>
        </div>
        <button onClick={onClickNextButton} className="sign-in-btn">
          Next
        </button>
      </div>
      {/* </div>
        </div>
      </div> */}
    </>
  );
};

export default SelectRole;
