import React, { useEffect, useRef, useState } from "react";
import { BsPlusCircleFill, BsSearch } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { InputValidator } from "../../layout/GenericLayout/InputValidator";
import ReusableModal from "../../layout/GenericLayout/ReusableModal";
import { toastMessage } from "../../layout/GenericLayout/ToastMessage";
import {
  deleteInvoiceFile,
  fetchManualInvoiceData,
  getApprovedRetailerList,
  getDigitalInvoiceTable,
  getManuaInvoiceTable,
  getManualApprovePO,
  submitEditManualInvoice,
  updateAddManualFilesUpload
} from "../../store/slices/anchor";
import DigitalInvoiceTable from "./AnchorTables/AnchorInvoiceTables.js/DigitalInvoiceTable";
import ManualInvoiceTable from "./AnchorTables/AnchorInvoiceTables.js/ManualInvoiceTable";
import {Helmet} from "react-helmet";

/* MODALS OR CHILDREN COMPONENTS */
const CreateManualInvoiceModal = (props) => {
  const dispatch = useDispatch();
  const [manualInvoiceFields, setManualInvoiceFields] = useState({});
  const [fileUploads, setFileUploads] = useState({});
  const [isError, setIsError] = useState({});

  const $dueDateRef = useRef();

  const userRetailerById = useSelector(
    (state) => state.anchor.approvedRetailer
  );
  
  const editInvoiceState = useSelector(state => state?.anchor?.submitEditManualInvoice);
  const submitInvoiceState = useSelector(state => state?.anchor?.addManualFilesUploadData);

  useEffect(() => {
    if (props.id) {
      setManualInvoiceFields(prev => ({ ...prev, invoice_number: props?.externalId }));
      
      dispatch(fetchManualInvoiceData(props?.id))
        .unwrap()
        .then(data => {
          console.log("invoice for id", props?.id, data);

          if (data !== undefined && typeof data === "object" && 'items' in data) {
            const { items } = data;
            
            setManualInvoiceFields(previous => ({
              ...previous,
              invoice_date: items?.invoiceDate,
              invoice_amount: items?.invoiceAmount,
              due_date: items?.dueDate,
              buyer_id: items?.buyerId,
              uploadedInvoice: items?.invoiceFileUrl,
            }));
          }
        })
        .catch(err => {
          console.error("error occurred", err?.message);
        })
    }
  }, [props?.id, dispatch]);

  useEffect(() => {
    dispatch(getApprovedRetailerList());
    dispatch(getManualApprovePO());
  }, [dispatch]);

  const handleChangeInvoiceAmount = (event) => {
    if (event?.target?.value === "") {
      setManualInvoiceFields((prev) => ({ ...prev, invoice_amount: "" }));
      return;
    }
    const value = parseInt(event?.target?.value);

    if (isNaN(value)) {
      return;
    }

    setManualInvoiceFields((prev) => ({
      ...prev,
      invoice_amount: value?.toString(),
    }));
  };

  const handleBlurInvoiceAmount = (event) => {
    const value = parseInt(event?.target.value);

    if (isNaN(value) || value <= 0) {
      setManualInvoiceFields((prev) => ({ ...prev, invoice_amount: "" }));
      setIsError((prev) => ({
        ...prev,
        invoiceAmount: "Amount should be greater than 0",
      }));
    } else {
      setIsError((prev) => ({ ...prev, invoiceAmount: "" }));
    }
  };

  const onChangeAddManualInvoice = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    console.log("on change : ", name, value);
    setManualInvoiceFields((prev) => ({ ...prev, [name]: value }));
  };
  const onChangeFileUpload = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const file = event.target.files[0];

    console.log(file?.size / (1024 * 1024));

    if (file?.size / (1024 * 1024) > 5) {
      toastMessage("File is too large", "error");
    } else {
      setFileUploads((prev) => ({ ...prev, [name]: { file, value }, uploadedInvoice: undefined }));
    }
  };

  const onSubmitCreateManualInvoice = (event) => {
    event.preventDefault();
    
    const data = {
      ...manualInvoiceFields,
      billOfExchange: "",
      lorryReceipt:
        fileUploads?.lorryReceipt?.file === undefined
          ? ""
          : fileUploads?.lorryReceipt?.file,
      invoice: fileUploads?.invoice?.file,
    };

    const body = new FormData();
    Object.keys(data).forEach((key) => body.append(key, data[key]));

    const isNoneError =
      Object.keys(isError).length === 0
        ? false
        : Object.values(isError).every(
            (eachError) => eachError === null || eachError === ""
          );
    
    if (isNoneError) {
      if (props?.id) {

        if (!manualInvoiceFields.uploadedInvoice && !fileUploads?.invoice) {
          toastMessage("Please upload Invoice File", "error");
          return;
        }
        
        dispatch(submitEditManualInvoice(body))
        .unwrap()
        .then((response) => {
          toastMessage(response?.message, "success");
          props?.onRefreshTable();
          props?.toggleBOEModal();
        })
        .catch((error) => {
          toastMessage(error.message, "error");
        });
  
        return;
      }
      
      dispatch(updateAddManualFilesUpload(body))
        .unwrap()
        .then((response) => {
          toastMessage(response?.message, "success");
          props?.onRefreshTable();
          props?.toggleBOEModal();
        })
        .catch((error) => {
          toastMessage(error.message, "error");
        });
    } else {
      toastMessage("Please edit fields to update", "error");
    }
  };

  const onBlurInputFeilds = (event) => {
    const name = event.target.id;
    const value = event.target.value;

    // console.log(name, value);

    if (name === "eInvoice" || name === "eWayBill") {
      if (event.target.files[0]?.size / (1024 * 1024) > 5) {
        setIsError((prev) => ({
          ...prev,
          [name]: "File is Too large, should be less than 5 Mb",
        }));
      } else {
        const validator = () => {
          return {
            regex: /^.+$/,
            message: "Field is required",
          };
        };

        if (name === "invoice_date" && !manualInvoiceFields.invoice_date) {
          setManualInvoiceFields((prev) => ({ ...prev, due_date: "" }));
          $dueDateRef.current.value = null;
        }

        const errorMessgae = InputValidator({
          regex: validator().regex,
          value,
          message: validator().message,
        });

        setIsError((prev) => ({
          ...prev,
          [name]: errorMessgae,
        }));
      }
    } else {
      const validator = () => {
        return {
          regex: /^.+$/,
          message: "Field is required",
        };
      };

      if (name === "invoice_date" && !manualInvoiceFields.invoice_date) {
        setManualInvoiceFields((prev) => ({ ...prev, due_date: "" }));
        $dueDateRef.current.value = null;
      }

      const errorMessgae = InputValidator({
        regex: validator().regex,
        value,
        message: validator().message,
      });

      setIsError((prev) => ({
        ...prev,
        [name]: errorMessgae,
      }));
    }
  };

  const handleDeleteInvoiceFile = () => {

    console.log("manualInvoiceFields", manualInvoiceFields);

    const body = {
      sellerId: sessionStorage.getItem("customerId"),
      invoiceFileUrl: manualInvoiceFields.uploadedInvoice,
      invoiceId: props?.id,
    };
    
    dispatch(deleteInvoiceFile(body))
      .unwrap()
      .then(data => {
        toastMessage(data?.message, "success");
        setManualInvoiceFields(prev => ({ ...prev, uploadedInvoice: undefined }));
      })
      .catch(err => {
        console.error("error delete invoice file", err.message);
        toastMessage(err?.message, "error");
      });
    
  };

  return (
    <form onSubmit={onSubmitCreateManualInvoice} className="">
      <span className="fs-4 fw-semibold">{ props?.id ? "Edit " : "" }Manual Invoice</span>
      <div className="details-container container px-5 py-3">
        <div className="row gy-md-4 gy-2">
          <div className="kyc-fields-container col-12  col-md-6">
            <label
              htmlFor="eInvoice"
              className="kyc-label-text form-label add-star"
            >
              E-Invoice (File should be below 5 MB)
            </label>
            <input
              onChange={onChangeFileUpload}
              name="invoice"
              id="eInvoice"
              type="file"
              className={`kyc-input-field form-control   ${
                isError?.eInvoice && "invalid-input"
              }`}
              onBlur={onBlurInputFeilds}
              accept=".pdf"
              value={manualInvoiceFields?.invoice}
            />
            <span>
              {
                manualInvoiceFields?.uploadedInvoice &&
                  <p className="p-2" title={ manualInvoiceFields?.uploadedInvoice}>
                    <a href={manualInvoiceFields?.uploadedInvoice} target="_blank" rel="noreferrer">
                      {manualInvoiceFields?.uploadedInvoice?.slice(0, 30)}...
                    </a>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span>
                      <MdDelete onClick={handleDeleteInvoiceFile} className="fs-4 text-danger" role="button" />
                    </span>
                  </p>
              }
            </span>
            <span className="text-danger px-1">{isError?.eInvoice}</span>
          </div>

          <div className="kyc-fields-container col-12  col-md-6">
            <label htmlFor="eWayBill" className="kyc-label-text form-label">
              E-Way Bill (Below 5 MB) (optional)
            </label>
            <input
              onChange={onChangeFileUpload}
              name="lorryReceipt"
              id="eWayBill"
              type="file"
              accept=".pdf"
              className={`kyc-input-field form-control  ${
                isError?.eWayBill && "invalid-input"
              }`}
              // onBlur={onBlurInputFeilds}
              value={manualInvoiceFields?.lorryReceipt}
            />
            <span className="text-danger px-1">{isError?.eWayBill}</span>
          </div>

          <div className="kyc-fields-container col-12  col-md-6">
            <label htmlFor="invoiceDate" className="kyc-label-text form-label">
              Invoice Date <span className="text-danger">*</span>
            </label>
            <input
              onChange={onChangeAddManualInvoice}
              id="invoiceDate"
              name="invoice_date"
              type="date"
              className={`kyc-input-field form-control  ${
                isError?.invoiceDate && "invalid-input"
              }`}
              onBlur={onBlurInputFeilds}
              value={manualInvoiceFields?.invoice_date}
            />
            <span className="text-danger px-1">{isError?.invoiceDate}</span>
          </div>

          <div className="kyc-fields-container col-12  col-md-6">
            <label
              htmlFor="invoiceNumber"
              className="kyc-label-text form-label"
            >
              Invoice Number <span className="text-danger">*</span>
            </label>
            <input
              name="invoice_number"
              onChange={onChangeAddManualInvoice}
              id="invoiceNumber"
              type="text"
              className={`kyc-input-field form-control  ${
                isError?.invoiceNumber && "invalid-input"
              }`}
              disabled={props?.id}
              onBlur={onBlurInputFeilds}
              value={manualInvoiceFields?.invoice_number}
            />
            <span className="text-danger px-1">{isError?.invoiceNumber}</span>
          </div>

          <div className="kyc-fields-container col-12  col-md-6">
            <label
              htmlFor="invoiceAmount"
              className="kyc-label-text form-label"
            >
              Invoice Amount <span className="text-danger">*</span>
            </label>
            <input
              name="invoice_amount"
              onChange={handleChangeInvoiceAmount}
              id="invoiceAmount"
              type="number"
              min={0}
              className={`kyc-input-field form-control  ${
                isError?.invoiceAmount && "invalid-input"
              }`}
              onBlur={handleBlurInvoiceAmount}
              value={manualInvoiceFields?.invoice_amount}
            />
            <span className="text-danger px-1">{isError?.invoiceAmount}</span>
          </div>

          <div className="kyc-fields-container col-12  col-md-6">
            <label htmlFor="dueDate" className="kyc-label-text form-label">
              Due Date <span className="text-danger">*</span>
            </label>
            <input
              onChange={onChangeAddManualInvoice}
              name="due_date"
              id="dueDate"
              type="date"
              className={`kyc-input-field form-control  ${
                isError?.dueDate && "invalid-input"
              }`}
              onBlur={onBlurInputFeilds}
              value={manualInvoiceFields?.due_date}
              disabled={!manualInvoiceFields?.invoice_date}
              min={manualInvoiceFields.invoice_date}
              ref={$dueDateRef}
            />
            <span className="text-danger px-1">{isError?.dueDate}</span>
          </div>

          {sessionStorage.getItem("productCategory") !==
            "Purchase Invoice Discounting" && (
            <div className="kyc-fields-container col-12  col-md-6">
              <label
                htmlFor="selectBuyerName"
                className="kyc-label-text form-label"
              >
                Counter Party Name <span className="text-danger">*</span>
              </label>
              <select
                onChange={onChangeAddManualInvoice}
                name="buyer_id"
                className={`kyc-input-field form-select  ${
                  isError?.selectBuyerName && "invalid-input"
                }`}
                onBlur={onBlurInputFeilds}
                // value={fileUploads?.buyer_id?.value || ""}
                value={manualInvoiceFields?.buyer_id || ""}
                id="selectBuyerName"
              >
                <option value="" disabled defaultValue>
                  Open this select menu
                </option>
                {userRetailerById.data &&
                  userRetailerById.data.success.map((eachBuyer) => (
                    <option
                      key={eachBuyer.retailerId}
                      value={eachBuyer.retailerId}
                    >
                      {eachBuyer.name}
                    </option>
                  ))}
              </select>
              <span className="text-danger px-1">
                {isError?.selectBuyerName}
              </span>
            </div>
          )}

          <div className="kyc-fields-container col-12  col-md-6">
            <span className="text-danger px-1">
              {isError?.selectPurchaseIdOrder}
            </span>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end m-2">
        <button type="button" onClick={ props?.toggleBOEModal } className="btn btn-outline-secondary mx-2">
          Cancel
        </button>
        <button type="submit" className="btn btn-success">
          { props?.id ? 
            <>
              {
                editInvoiceState?.isLoading ? 
                <span className="loader mx-auto"></span> :
                "Edit" 
              }
            </> 
            :
            <>
              {
                submitInvoiceState?.isLoading ? 
                  <span className="loader mx-auto"></span> :
                "Submit" 
              }
            </>
          }
        </button>
      </div>
    </form>
  );
};

/* PARENT COMPONENT */
const AnchorInvoice = () => {
  const dispatch = useDispatch();
  /* USESELECT HOOKS */
  const digitalInvoiceTableData = useSelector(
    (state) => state.anchor.digitalInvoiceTableData
  );
  const manualInvoiceTableData = useSelector(
    (state) => state.anchor.manualInvoiceTableData
  );
  /* USESTATES */
  const [activeTableData, setActiveTableData] = useState(1);
  const [invoiceTableRows, setInvoiceTableRows] = useState([]);
  const [invoiceMetaData, setInvoiceMetaData] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  const [ editId, setEditId ] = useState("");
  const [ externalInvoiceId, setExternalInvoiceId ] = useState("");
  
  useEffect(() => {
    const productCategory = sessionStorage.getItem("productCategory");
    if (
      productCategory !== null &&
      productCategory === "Purchase Invoice Discounting"
    ) {
      setActiveTableData(2);
    }
  }, []);
  //
  const [showCreateManualInvoiceModal, setShowCreateManualInvoiceModal] =
    useState(false);

  /* TABLE QUERY PARAMS */
  const [tableQueryParams, setTableQueryParams] = useState({
    tableOffset: 0,
    tableLimit: 5,
    tableSort: "invoiceDate",
    tableOrder: -1,
    currentPage: 1,
    search:""
  });

  /* USEEFFECTS */
  useEffect(() => {
    if (activeTableData === 1) {
      dispatch(
        getDigitalInvoiceTable({
          tableQueryParams,
        })
      );
    } else if (activeTableData === 2) {
      dispatch(
        getManuaInvoiceTable({
          tableQueryParams,
        })
      );
    }
  }, [dispatch, tableQueryParams, activeTableData]);

  useEffect(() => {
    if (activeTableData === 1) {
      if (digitalInvoiceTableData.data) {
        setInvoiceTableRows(digitalInvoiceTableData.data.items);
        setInvoiceMetaData(digitalInvoiceTableData.data.metadata);
      }
    }
  }, [digitalInvoiceTableData]);

  useEffect(() => {
    if (activeTableData === 2) {
      if (manualInvoiceTableData.data) {
        setInvoiceTableRows(manualInvoiceTableData.data.items);
        setInvoiceMetaData(manualInvoiceTableData.data.metadata);
      }
    }
  }, [manualInvoiceTableData]);

  /* EVENT LISTENERS */
  const toggleCreateManualInvoiceModal = () => {
    setShowCreateManualInvoiceModal((prev) => {
      if (prev === true) {
        setEditId("");
        setExternalInvoiceId("");
      }
      
      return !prev;
    });
  };

  const onClickActiveTable = (value) => {
    setActiveTableData(value);
    setTableQueryParams((prev) => ({ ...prev, currentPage: 1 }));
  };

  const onRefreshTable = () => {
    if (activeTableData === 1) {
      dispatch(
        getDigitalInvoiceTable({
          tableQueryParams,
        })
      );
    } else if (activeTableData === 2) {
      dispatch(
        getManuaInvoiceTable({
          tableQueryParams,
        })
      );
    }
  };

  const onChangeSearch = (event) => {
    // setSearchInvoiceValue((prev, ))
    // console.log(event.target.value)
    setTableQueryParams((prev)=>({
      ...prev,
      tableOffset:0,
      search: event.target.value
    }));
  };

  const handleEditManualInvoice = (id, externalInvoiceId) => {
    toggleCreateManualInvoiceModal();

    setEditId(id);
    setExternalInvoiceId(externalInvoiceId)
  }

  return (
    <>
      {showCreateManualInvoiceModal && (
        <ReusableModal
          showModal={showCreateManualInvoiceModal}
          toggleModal={toggleCreateManualInvoiceModal}
          modalContent={
            <CreateManualInvoiceModal
              // activeBOEData={activeBOEData}
              toggleBOEModal={toggleCreateManualInvoiceModal}
              onRefreshTable={onRefreshTable}
              id={editId}
              externalId={externalInvoiceId}
            />
          }
          modalSizeClass="modal-lg"
        />
      )}

      <div className="d-flex flex-column gap-4" style={{ overflowY: "auto" }}>
      <Helmet>
                <title>Invoice</title>
            </Helmet>
        <div className="bg-white admin-dashboard-card-container p-3">
          <div className="d-flex justify-content-between align-items-center flex-wrap px-2 gap-2 py-2">
            <span className="dashboard-content-title">List of Invoices</span>
            <div
              onClick={toggleCreateManualInvoiceModal}
              className="d-flex align-items-center"
              type="button"
            >
              <div
                className={`rm-add-button ${isHovered ? "show" : ""}`}
                // onMouseEnter={handleMouseEnter}
                // onMouseLeave={handleMouseLeave}
              >
                ADD
              </div>

              <button
                id="add_manual_invoice"
                className="btn mx-3"
                style={{
                  border: "1px solid #6378d4",
                  borderRadius: "10px",
                }}
              >
                {" "}
                Add Manual Invoice &nbsp;
                <BsPlusCircleFill className="rm-light-plus-button" />{" "}
              </button>
            </div>
          </div>

          <div className="d-flex flex-column flex-sm-row align-items-center mt-2 mb-1">
            <div className="d-flex flex-column justify-content-center gap-3 col-12 col-sm-6">
              {/* <label
                htmlFor="selectAnchor"
                className="anchor-and-lender-select-label"
              >
                Invoice Number
              </label> */}
              <div className="search-container d-flex align-items-center py-1 px-3 col-12 col-sm-8">
                <input
                  className="search-input-element d-flex flex-grow-1"
                  type="text"
                  placeholder="Search with Invoice No."
                  onChange={onChangeSearch}
                  // value={search}
                />{" "}
                <BsSearch className="fs-5" />
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex flex-column">
          {sessionStorage.getItem("productCategory") !==
            "Purchase Invoice Discounting" && (
            <div className="d-flex">
              <span
                className={`dashboard-table-title ${
                  activeTableData !== 1 && "dashboard-table-title-hover"
                } py-2 px-3 ${
                  activeTableData === 1 && "active-table-data placeholder-wave"
                }`}
                onClick={() => onClickActiveTable(1)}
              >
                Digital Invoices
              </span>
              <span
                className={`dashboard-table-title ${
                  activeTableData !== 2 && "dashboard-table-title-hover"
                } py-2 px-3 ${
                  activeTableData === 2 && "active-table-data placeholder-wave"
                }`}
                onClick={() => onClickActiveTable(2)}
              >
                Manual Invoices
              </span>
            </div>
          )}
          <div>
            {activeTableData === 1 && (
              <DigitalInvoiceTable
                className="admin-anchor-digital-invoice-table"
                tableHeader={[
                  { name: "Invoice Date", sortValue: "" },
                  { name: "Invoice Number", sortValue: "" },
                  { name: "PO Number", sortValue: "" },
                  {
                    name: "Total Amount (₹)	",
                    sortValue: "",
                  },
                  { name: "Overdue Amount (₹)	", sortValue: "" },
                  { name: "Status", sortValue: "" },
                  { name: "Invoice Status", sortValue: "" },
                  { name: "BOE Number", sortValue: "" },
                  { name: "Upload LR", sortValue: "" },
                  { name: "Other Document(.zip)", sortValue: "" },
                ]}
                tableRows={invoiceTableRows}
                invoiceMetaData={invoiceMetaData}
                tableQueryParams={tableQueryParams}
                setTableQueryParams={setTableQueryParams}
                isLoading={digitalInvoiceTableData.isLoading}
                onRefreshTable={onRefreshTable}
              />
            )}
            {activeTableData === 2 && (
              <ManualInvoiceTable
                className="admin-anchor-manual-invoice-table"
                tableHeader={
                  sessionStorage.getItem("productCategory") !==
                  "Purchase Invoice Discounting"
                    ? [
                        { name: "Counter Party", sortValue: "" },
                        { name: "Invoice Date", sortValue: "" },
                        { name: "Invoice Number", sortValue: "" },
                        // { name: "PO Number", sortValue: "" },
                        { name: "Total Amount (₹)	", sortValue: "" },
                        { name: "Due Date", sortValue: "" },
                        { name: "Other Document(.zip)", sortValue: "" },
                        { name: "Action", sortValue: "" },
                        { name: "Status", sortValue: "" },
                      ]
                    : [
                        { name: "Invoice Date", sortValue: "" },
                        { name: "Invoice Number", sortValue: "" },
                        { name: "PO Number", sortValue: "" },
                        { name: "Total Amount (₹)	", sortValue: "" },
                        { name: "Due Date", sortValue: "" },
                        { name: "Other Document(.zip)", sortValue: "" },
                        { name: "Action", sortValue: "" },
                      ]
                }
                tableRows={invoiceTableRows}
                invoiceMetaData={invoiceMetaData}
                tableQueryParams={tableQueryParams}
                setTableQueryParams={setTableQueryParams}
                isLoading={manualInvoiceTableData.isLoading}
                onRefreshTable={onRefreshTable}
                handleEditManualInvoice={handleEditManualInvoice}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AnchorInvoice;
