import { Fragment, useContext, useEffect, useState } from "react";
import { BuyerKycContext } from "../buyerKycContext";
import { GenericInputField } from "../../../../GenericComponent/Input/Input";
import { actions } from "./documentDetailsReducer";
import { DocumentListItem } from "../../../../GenericComponent/OtherDocumentsListItem/documentListItem";

export const BuyerKYCDocumentDetails = (props) => {

	const [docs, setDocs] = useState([]);


	const [ documentName, setDocumentName ] = useState("");
	const [ document, setDocument ] = useState(null);

	const [ showAddNewDocsFields, setShowAddNewDocsFields ] = useState(true);

	const { files, documentDetailsDispatch: setState, documentDetails: { loanAmount }, uploadFiles, disableEdit } = useContext(BuyerKycContext);

	useEffect(() => {
		if (files?.length > 0 && files?.[0]?.documents && Array.isArray(files[0].documents)) {
			const docsTemp = [];
			for (let item of files[0].documents) {
				if (item?.otherDocs !== undefined) {
					docsTemp.push(item);
				}
			}

			if (docsTemp.length >= 5) {
				setShowAddNewDocsFields(false);
			}
			else {
				setShowAddNewDocsFields(true);
			}
			
			setDocs(docsTemp);
		}
	}, [ files ]);

	const handleAddDocument = () => {
		console.log(documentName, document);

		if (documentName.length <= 5 && document === null) {
			return;
		}

		const formData = new FormData();

		formData.append("name", documentName);
		formData.append("otherDocs", document?.[0]);

		if (docs.length === 4) {
			setShowAddNewDocsFields(false);
		}

		uploadFiles(formData, "otherDocs");

		setDocument(null);
		setDocumentName("");
	};

	return (
		<Fragment>
			<div className="container-with-heading d-flex flex-column container">
				<span className="kyc-content-title mb-3 row">
					Document Details
				</span>
				<div className="details-container">
					{ docs.map((item, index) => (
						<div key={ item?.otherDocs ?? "" } className="row gy-md-4 gy-2 my-2">
							<DocumentListItem 
								documentName={ item?.name ?? "" }
								document={ item?.otherDocs ?? "" }
								disabled={disableEdit}
							/>
						</div>
					))}

					{
						showAddNewDocsFields && !disableEdit &&
							<div className="row gy-md-4 gy-2 my-1">
								<DocumentListItem 
									showInputField={ true } showSubmitButton={ documentName.length > 5 && document !== null } documentNamePlaceholder="Enter Name of the Document"
									onChangeDocNameHandler={ event => setDocumentName(event?.target?.value) } documentName={ documentName }
									onChangeDocHandler={ event => setDocument(event?.target?.files) }
									handleAddDocument={ handleAddDocument } disabled={disableEdit}
								/>
							</div>
					}

					<div className="row gy-md-4 gy-2 mt-1">
						<GenericInputField
							label="Loan Amount" type="number" onKeyDown={e => (e.which === 38 || e.which === 40) && e.preventDefault()}  required={ true } value={ loanAmount.value } isInvalid={ loanAmount.isTouched && !loanAmount.isValid }
							errorMessage={ loanAmount.value === "" ? "Loan Amount is Required" : "Loan Amount should be greater than 0" }
							onChangeHandler={ event => setState({ type: actions.CHANGE_LOAN, payload: event.target.value }) }
							onBlurHandler={ () => setState({ type: actions.BLUR_LOAN }) } className="input-number"
							disabled={ disableEdit }
						/>
					</div>
				</div>
				
			</div>
		</Fragment>
	);
};