import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { BsPlusCircleFill,BsSearch } from "react-icons/bs";
import Loader from "../../layout/GenericLayout/Loader";
import AdminRMDetailsTable from "./AdminTables/AdminRMDetailsTable";
import { useDispatch, useSelector } from "react-redux";
import {
  addLender,
  getAllLender,
  getAllRelationshipManager,
} from "../../store/slices/admin";
import ReusableModal from "../../layout/GenericLayout/ReusableModal";
import {
  getIsEmailRegistered,
  resetGenericState,
} from "../../store/slices/generic";
import { toastMessage } from "../../layout/GenericLayout/ToastMessage";
import AdminLenderDetailsTable from "./AdminTables/AdminLenderDetailsTable";
import { Helmet } from "react-helmet";
import TablePagination from "../../layout/GenericLayout/TablePagination";
import { HiOutlineArrowNarrowDown } from "react-icons/hi";


/* MODALS OR CHILDREN COMPONENTS */
const AddLenderDetailsModal = ({ toggleLenderAddModal, onRefreshTable }) => {
  /* FUNCTION DECLARATIONS */
  const dispatch = useDispatch();
  /* USESELECTOR */
  const isEmailRegisteredData = useSelector(
    (state) => state.generic.isEmailRegisteredData
  );
  const lenderAddDataResponse = useSelector((state) => state.admin.lenderAddData)

  /* USESTATES */
  const [addLenderDetailsFields, setAddLenderDetailsFields] = useState({
    name: "",
    emailId: "",
    address: "",
    mobileNumber: "",
  });
  /* EVENT LISTENERS */
  const onChangeAddRMDetailsFields = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const pattern = new RegExp(event.target.pattern)
    if (pattern.test(value)) {
      setAddLenderDetailsFields((prev) => ({ ...prev, [name]: value }));
    }
  };

  const onBlurAddLenderDetailsFields = (event) => {
    const name = event.target.name;
    const validity = event.target.checkValidity();

    if (name === "emailId" && validity) {
      dispatch(getIsEmailRegistered(addLenderDetailsFields.emailId))
        .unwrap()
        .then((response) => {
          toastMessage(response.success, "success");
        })
        .catch((error) => {
          toastMessage(error.message, "error");
        });
    }
  };

  const onSubmitLenderAddDetails = (event) => {
    event.preventDefault();
    //check if the mobile number is valid
    const regex= /^(?!(9999999999|8888888888|7777777777|6666666666|9876543210)$)[6-9][0-9]{9}$/;
    if(!regex.test(addLenderDetailsFields?.mobileNumber)){
      toastMessage("Invalid Mobile Number","error");
      return;
    }

    dispatch(addLender(addLenderDetailsFields))
      .unwrap()
      .then((response) => {
        toastMessage(response.success, "success");
        onRefreshTable();
        toggleLenderAddModal();
      })
      .catch((error) => {
        toastMessage(error.message, "error");
      });
  };

  return (
    <form className="" onSubmit={onSubmitLenderAddDetails}>
      <span className="fs-4 fw-semibold">Lender Details</span>
      <div className="details-container container mt-4">
        <div className="row gy-md-4 gy-2">
          <div className="kyc-fields-container col-12  col-md-6">
            <label htmlFor="name" className="kyc-label-text form-label">
              Name
            </label>
            <input
              required
              onChange={onChangeAddRMDetailsFields}
              id="name"
              name="name"
              pattern="^[a-zA-Z. ]*$"
              type="text"
              className="kyc-input-field form-control"
              value={addLenderDetailsFields.name}
            />
          </div>

          <div className="kyc-fields-container col-12  col-md-6">
            <label htmlFor="pan" className="kyc-label-text form-label">
              Email ID
            </label>
            <input
              onBlur={onBlurAddLenderDetailsFields}
              required
              onChange={onChangeAddRMDetailsFields}
              id="emailId"
              type="email"
              name="emailId"
              className="kyc-input-field form-control"
              value={addLenderDetailsFields.emailId}
            />
          </div>

          <div className="kyc-fields-container col-12  col-md-6">
            <label htmlFor="email" className="kyc-label-text form-label">
              Address
            </label>
            <input
              required
              onChange={onChangeAddRMDetailsFields}
              id="address"
              type="text"
              name="address"
              className="kyc-input-field form-control"
              value={addLenderDetailsFields.address}
            />
          </div>

          <div className="kyc-fields-container col-12  col-md-6">
            <label htmlFor="mobileNumber" className="kyc-label-text form-label">
              Mobile Number
            </label>
            <input
              required
              onChange={onChangeAddRMDetailsFields}
              id="mobileNumber"
              type="number"
              name="mobileNumber"
              className="kyc-input-field form-control"
              value={addLenderDetailsFields.mobileNumber}
            />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end gap-4 mt-5">
        <button
          type="button"
          className="btn btn-secondary"
          onClick={toggleLenderAddModal}
        >
          Back
        </button>
        {
          lenderAddDataResponse?.isLoading ? <button
            type="submit"
            className="btn btn-primary"
            disabled
          >
            <Loader className="mx-2"></Loader>
          </button> :
            <button type="submit" className="btn btn-success">
              Add
            </button>
        }

      </div>
    </form>
  );
};

/* PARENT COMPONENT */
const AdminLender = () => {
  const dispatch = useDispatch();
  const lenderData = useSelector((state) => state.admin.lenderData);
  const [lenderDetailsTableRows, setLenderDetailsTableRows] = useState([]);
  const [searchByLenderName,setSearchByLenderName]=useState('');


  const [isHovered, setIsHovered] = useState(false);

  /* MODALS USESTATE */
  const [showLenderAddModal, setShowLenderAddModal] = useState(false);
  const [lendersListMetadata, setLendersListMetadata] = useState("")

  const [tableQueryParams, setTableQueryParams] = useState({
    tableOffset: 0,
    tableLimit: 5,
    tableSort: "",
    tableOrder: -1,
    currentPage: 1,
  });

  /* MODALS TOGGLE */
  const toggleLenderAddModal = () => {
    setShowLenderAddModal((prev) => !prev);
  };

  const updatePage = (currentPage) => {
    const newOffSet = tableQueryParams.tableLimit * (currentPage - 1);
    setTableQueryParams((prev) => ({
      ...prev,
      tableOffset: newOffSet,
      currentPage,
    }));
  };

  useEffect(() => {
    let tableQuery = `?offset=${tableQueryParams.tableOffset}&limit=${tableQueryParams?.tableLimit}`;
    // **For sort order needs to be there

    if (tableQueryParams?.tableSort.length > 1) {
      tableQuery = `?offset=${tableQueryParams.tableOffset}&limit=${tableQueryParams?.tableLimit}&sort=${tableQueryParams.tableSort}&order=${tableQueryParams?.tableOrder}`;
    }

    if (searchByLenderName.length > 1) {
      //&search=mrID
      tableQuery = `?offset=0&limit=${tableQueryParams?.tableLimit}&search=${searchByLenderName}`;
    }

    dispatch(getAllLender(tableQuery))
      .unwrap()
      .then((response) => {
        setLenderDetailsTableRows(response.items);
        setLendersListMetadata(response.metadata);
      })
      .catch((error) => {
        console.log("error : ", error);
      });
  }, [dispatch, tableQueryParams,searchByLenderName]);

  useEffect(() => {
    if (lenderData.data) {
      setLenderDetailsTableRows(lenderData.data.items);
    }
  }, [lenderData.data]);

  const onRefreshTable = () => {
    dispatch(getAllLender())
      .unwrap()
      .then((response) => {
        setLenderDetailsTableRows(response.data.items);
      })
      .catch((error) => {
        console.log("error : ", error);
      });
  };

  const onChangeSearchByLenderName= (e) => {
    setSearchByLenderName(e.target.value);
  };

  const onClickViewMoreTableData = () => {
    let newLimit = tableQueryParams.tableLimit;
    if (newLimit === 5) {
      newLimit += 5;
    } else {
      newLimit -= 5;
    }
    setTableQueryParams((prev) => ({
      ...prev,
      tableLimit: newLimit,
    }));
  };

  return (
    <>
      {/* MODAL CONTENT */}
      {showLenderAddModal && (
        <ReusableModal
          showModal={showLenderAddModal}
          toggleModal={toggleLenderAddModal}
          modalContent={
            <AddLenderDetailsModal
              toggleLenderAddModal={toggleLenderAddModal}
              onRefreshTable={onRefreshTable}
            />
          }
          modalSizeClass="modal-lg"
        />
      )}

      <div className="d-flex flex-column gap-4">
        <Helmet>
          <title>Lender</title>
        </Helmet>
        <div className="bg-white admin-dashboard-card-container px-3 py-3 d-flex flex-column gap-2 ">
          <div className="d-flex justify-content-between align-items-center flex-wrap px-2 gap-2 py-2">
            <span className="dashboard-content-title">Lender Details</span>
            <div
              onClick={toggleLenderAddModal}
              className="d-flex align-items-center"
            >
              <div
                className={`rm-add-button ${isHovered ? "show" : ""}`}>
                Add
              </div>
              <button
                className="btn mx-3"
                style={{
                  // background:'#fff',
                  border: "1px solid #6378d4",
                  borderRadius: "10px",
                }}
              >
                {" "}
                Onboard Lender &nbsp;
                <BsPlusCircleFill
                  className="rm-plus-button"

                />{" "}
              </button>
              {/* <BsPlusCircleFill
                className="rm-plus-button"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              /> */}
            </div>
          </div>

        {/* search bar */}
          <div className="d-flex flex-column flex-sm-row align-items-center my-4">
            <div className="d-flex flex-column justify-content-center gap-3 col-12 col-sm-6">
              <div className="search-container d-flex align-items-center py-1 px-3 col-12 col-sm-8">
                <input
                  className="search-input-element d-flex flex-grow-1"
                  type="text"
                  placeholder="Search with Lender Name"
                  onChange={onChangeSearchByLenderName}
                  value={searchByLenderName}
                />{" "}
                <BsSearch className="fs-5" />
              </div>
            </div>
          </div>
        </div>

        {lenderData.isLoading ? (
            <Loader className="my-5" />
          ) : (
            <AdminLenderDetailsTable
              onRefreshTable={onRefreshTable}
              className="admin-channel-partner-data-table"
              tableHeader={[
                "Lender Name",
                "Email ID",
                "Phone No.",
                "Account Status",
                "Action",
              ]}
              tableRows={lenderDetailsTableRows}
              lendersListMetadata={lendersListMetadata}
            />
          )}

          {!lenderData.isLoading && lendersListMetadata?.paging?.total>5 && (
            <div className="d-flex justify-content-end px-2">
              <span
                onClick={onClickViewMoreTableData}
                className="d-flex align-items-center gap-1 my-3 view-more-text"
              >
                {tableQueryParams.tableLimit === 5
                 ? tableQueryParams.tableOffset+tableQueryParams.tableLimit<lendersListMetadata?.paging?.total?"View More ":""
                 : tableQueryParams.tableOffset+tableQueryParams.tableLimit<lendersListMetadata?.paging?.total?"View Less ":""}
                <HiOutlineArrowNarrowDown
                  className={`fs-5 ${
                    tableQueryParams.tableLimit === 5
                    ? tableQueryParams.tableOffset+tableQueryParams.tableLimit<lendersListMetadata?.paging?.total?"down-arrow ":"d-none"
                    : tableQueryParams.tableOffset+tableQueryParams.tableLimit<lendersListMetadata?.paging?.total?"up-arrow":"d-none"}
                   `}
                />
              </span>
            </div>
          )}

        <div className="d-flex align-items-center gap-2 page-data-text-container mt-3 px-3 w-100">
          <TablePagination
            pageSize={tableQueryParams.tableLimit}
            onUpdatePage={updatePage}
            current={tableQueryParams.currentPage}
            total={lendersListMetadata?.paging?.total}
          ></TablePagination>
        </div>
      </div>
    </>
  );
};

export default AdminLender;
