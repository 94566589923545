import { BsDownload, BsEyeFill } from "react-icons/bs";

const BankInfo = ({ scoreCardDetails }) => {
  return (
    <div className="my-3">
      <div>
        <span className="fw-semibold text-secondary">AQB : </span>
        <span className="fw-semibold text-success">
          {scoreCardDetails[0]?.AQB || (
            <span className="text-danger mx-3">N/A</span>
          )}
        </span>
      </div>

      <div>
        <span className="fw-semibold text-secondary ">Account No. : </span>
        <span className="fw-semibold text-success">
          {scoreCardDetails[0]?.account_no || (
            <span className="text-danger mx-3">N/A</span>
          )}
        </span>
      </div>

      <div>
        <span className="fw-semibold text-secondary">Account Type : </span>
        <span className="fw-semibold text-success">
          {scoreCardDetails[0]?.account_type || (
            <span className="text-danger mx-3">N/A</span>
          )}
        </span>
      </div>

      <div>
        <span className="fw-semibold text-secondary">Bank Name : </span>
        <span className="fw-semibold text-success">
          {scoreCardDetails[0]?.bank_name || (
            <span className="text-danger mx-3">N/A</span>
          )}
        </span>
      </div>

      <div>
        <span className="fw-semibold text-secondary">Company Name : </span>
        <span className="fw-semibold text-success">
          {scoreCardDetails[0]?.company_name || (
            <span className="text-danger mx-3">N/A</span>
          )}
        </span>
      </div>

      <div>
        <span className="fw-semibold text-secondary">Net Take Home : </span>
        <span className="fw-semibold text-success">
          {scoreCardDetails[0]?.net_take_home || (
            <span className="text-danger mx-3">N/A</span>
          )}
        </span>
      </div>

      <div>
        <span className="fw-semibold text-secondary">Obligation : </span>
        <span className="fw-semibold text-success">
          {scoreCardDetails[0]?.obligation || (
            <span className="text-danger mx-3">N/A</span>
          )}
        </span>
      </div>

      <div>
        <span className="fw-semibold text-secondary">Bank Report : </span>
        {scoreCardDetails[0]?.bank_report ? (
          <a
            className="text-decoration-none btn btn btn-outline-success py-0"
            target="_blank"
            href={scoreCardDetails[0]?.bank_report}
            download
          >
            <span className="d-flex align-items-center gap-3">
              Download <BsDownload />
            </span>
          </a>
        ) : (
          <span className="fw-semibold text-danger">N/A</span>
        )}
      </div>
    </div>
  );
};

export default BankInfo;
