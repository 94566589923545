import React, { Fragment, useId } from "react";

/**
 * 
 * @param {{
 * 	label: string,
 * 	onChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void,
 * 	name?: string,
 * 	isInvalid: boolean,
 * 	onBlurHandler?: (event: React.FocusEvent<HTMLInputElement>) => void,
 * 	value: string,
 * 	errorMessage?: string,
 * 	verified?: boolean,
 * 	className?: string,
 * 	style?: React.CSSProperties,
 * 	placeholder?: string,
 * 	type?: 'text' | 'date' | 'number' | 'email' | 'number' | 'submit' | 'button' | 'password' | 'checkbox' | 'radio',
 * 	value: string,
 * 	required?: boolean,
 * 	disabled?: boolean,
 * 	successMessage?: string,
 * 	min?: string
 * 	max?: string
 * 	onKeyDown?: React.KeyboardEvent<HTMLInputElement>
 * }} props
 */
export const GenericInputField = (props) => {

	const uniqueId = useId();

	return (
		<Fragment>
			<div className="kyc-fields-container col-12 col-md-4">
				<label htmlFor={uniqueId} className="kyc-label-text form-label">
					{props?.label ?? ""} 
					{
						props?.required &&
							<span className="text-danger fs-5">*</span>
					}
				</label>

				<input
					onChange={ props?.onChangeHandler ?? null }
					id={uniqueId}
					name={props?.name ?? ""}
					type={props?.type ?? "text"}
					onBlur={props?.onBlurHandler ?? null}
					value={props?.value ?? ""}
					style={props?.style ?? {}}
					placeholder={props?.placeholder ?? ""}
					className={ `kyc-input-field form-control ${ props?.isInvalid && "invalid-input" } ${ props?.className ?? "" }` }
					disabled={ props?.disabled ?? false }
					min={props.min ?? null}
					onKeyDown={props?.onKeyDown}
					max={props.max ?? null}
				/>
				
				<span className="text-danger px-1">
					{ (props?.isInvalid && props?.errorMessage) ?? null }
				</span>

				<span className="text-success px-1">
					{ (props?.verified && props?.successMessage) ?? null }
				</span>
			</div>
		</Fragment>
	);
};