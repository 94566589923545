import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getDigitalInvoiceDetailsById,
  getDigitalPurchaseOrderById,
  getProformaInvoiceById,
  updateManualSendForDiscounting,
} from "../../../../store/slices/anchor";
import { getLenderList } from "../../../../store/slices/generic";
import { toastMessage } from "../../../../layout/GenericLayout/ToastMessage";
import Loader from "../../../../layout/GenericLayout/Loader";

const DigitalInvoiceDetailsById = () => {
  /* FUNCTION DECLARATIONS */
  // const location = useLocation();
  const { sellerId, invoiceId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  console.log("location", sellerId, invoiceId);
  /* USESELECTOR */
  const digitalInvoiceDataById = useSelector(
    (state) => state.anchor.digitalInvoiceDataById
  );

  const selectLenderData = useSelector(
    (state) => state.generic.selectLenderData
  );

  const isLoading =
    digitalInvoiceDataById.isLoading || selectLenderData.isLoading;

  /* USESTATES */
  const [tableRows, setTableRows] = useState([]);
  const [inputValues, setInputValues] = useState({});
  const [selectLender, setSelectLender] = useState("");
  const [termAndCondition, setTermsAndCondition] = useState(false);

  /* USEEFFECTS */
  useEffect(() => {
    dispatch(getDigitalInvoiceDetailsById({ sellerId, invoiceId }))
      .unwrap()
      .then((response) => {
        const sellerId = response.items[0].sellerId;
        const proformaId = response.items[0].proformaId;
        dispatch(getProformaInvoiceById({ sellerId, proformaId }))
          .unwrap()
          .then((response) => {
            console.log("res : ", response);
          });
        setTableRows(response.items[0].itemDetails);
        setInputValues((prev) => ({ ...prev, ...response.items[0] }));
      });

    dispatch(getLenderList());
  }, [dispatch]);

  const onChangeBankList = (event) => {
    console.log(event.target.value);
    setSelectLender(event.target.value);
  };

  const onChangeTermAndCondition = (event) => {
    setTermsAndCondition(event.target.checked);
  };

  const onClickBack = () => {
    navigate(-1, { replace: true });
  };

  const onSubmitSendForDiscount = (event) => {
    event.preventDefault();
    const body = {
      emailId: selectLender,
      gst: 1,
      invoiceAmount: invoiceData?.invoiceAmount,
      invoiceId: invoiceData?.invoiceId,
      invoiceStatus: invoiceData?.invoiceStatus,
      manualInvoice: invoiceData?.manualInvoice,
    };

    console.log(body);

    dispatch(updateManualSendForDiscounting(body))
      .unwrap()
      .then((response) => {
        console.log("response : ", response);
        toastMessage(response.success, "success");
        setSelectLender("");
      })
      .catch((error) => {
        console.log("error : ", error);
        toastMessage(error.message, "error");
      });
  };

  /* OPTIONS */
  const totalAmount =
    tableRows.length === 0
      ? 0
      : tableRows.reduce(
          (accumulator, currentValue) => accumulator + currentValue.totalAmount,
          0
        );

  const invoiceData = digitalInvoiceDataById?.data?.items[0];

  return (
    <form
      onSubmit={onSubmitSendForDiscount}
      className="bg-white admin-dashboard-card-container p-3"
    >
      <div className="d-flex justify-content-between align-items-center flex-wrap px-2 gap-2 py-2">
        <span className="dashboard-content-title">Invoice Details</span>
        <button className="modal-export-pdf-button d-flex align-items-center gap-2">
          Export to PDF
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 25"
          >
            <path
              id="Path_541"
              data-name="Path 541"
              d="M207.639,120.656l3.949-4.032a1.18,1.18,0,0,0-1.667-1.671l-1.352,1.361c-.308.31-.557.208-.557-.227V102.8a1.178,1.178,0,1,0-2.356,0v13.291c0,.434-.249.537-.557.227l-1.352-1.361a1.18,1.18,0,0,0-1.667,1.671l3.908,4.026a1.145,1.145,0,0,0,1.651.005Zm-8.464-6.96a1.379,1.379,0,0,0,1.95-1.95,8.074,8.074,0,1,1,11.419,0,1.379,1.379,0,0,0,1.95,1.95,10.832,10.832,0,1,0-15.318,0Z"
              transform="translate(-196.003 -95.205)"
              fill="#fff"
              fillRule="evenodd"
            />
          </svg>
        </button>
      </div>

      {isLoading ? (
        <div className="py-5">
          <Loader />
        </div>
      ) : (
        <>
          {/* Reject Reason */}
          {invoiceData?.status === "Rejected" && (
            <div className="d-flex flex-column align-items-center my-4 details-page-container bg-danger bg-opacity-25 py-3">
              <h5 className="text-danger">
                Your Invoice with Invoice_id{" "}
                <span className="fw-semibold">{invoiceData?.invoiceId}</span>{" "}
                being rejected!
              </h5>
              <h5 className="text-danger">
                Rejection Reason : {invoiceData?.rejectionRemarks}
              </h5>
            </div>
          )}
          <div className="d-flex flex-column align-items-center my-4 details-page-container">
            <div className="d-flex flex-column details-header">Tax Invoice</div>
            <div className="p-3 w-100">
              <div className="container">
                <div className="row">
                  <div className="col-7">
                    <h5 className="fw-semibold">
                      Seller : {invoiceData?.sellerName}
                    </h5>
                    <h6 className="fw-semibold">
                      Ship-from Address :{" "}
                      {`${invoiceData?.sellerAddress}, ${invoiceData?.sellerState}, ${invoiceData?.sellerCountry} - ${invoiceData?.sellerPinCode}`}
                    </h6>
                    <h6 className="fw-semibold">
                      GSTIN :{" "}
                      <span className="fw-normal">
                        {invoiceData?.sellerGSTNumber}
                      </span>
                    </h6>
                  </div>
                  <div className="col-5">
                    <h5 className="fw-semibold">
                      Invoice Number :{" "}
                      <span className="fw-normal">
                        # {invoiceData?.invoiceId}
                      </span>
                    </h5>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-4">
                    <h6 className="fw-semibold">PO ID : {invoiceData?.poId}</h6>
                    <h6 className="fw-semibold">
                      Order Date :
                      <span className="fw-normal">
                        {" "}
                        {invoiceData?.modifiedDate}
                      </span>
                    </h6>
                    <h6 className="fw-semibold">
                      PAN :{" "}
                      <span className="fw-normal">
                        {invoiceData?.buyerPanNumber}
                      </span>
                    </h6>
                    <h6 className="fw-semibold">
                      CIN :{" "}
                      <span className="fw-normal">
                        {invoiceData?.sellerCIN}
                      </span>
                    </h6>
                  </div>
                  <div className="col-4">
                    <h6 className="fw-semibold">
                      Bill To : {invoiceData?.buyerName}
                    </h6>
                    <h6 className="fw-semibold">
                      Phone :{" "}
                      <span className="fw-normal">
                        {invoiceData?.buyerPhoneNumber}
                      </span>
                    </h6>
                  </div>
                  <div className="col-4">
                    <h6 className="fw-semibold">
                      Ship To : {invoiceData?.buyerName}
                    </h6>
                    <h6 className="fw-semibold">
                      Phone :{" "}
                      <span className="fw-normal">
                        {invoiceData?.buyerPhoneNumber}
                      </span>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex flex-column align-items-center my-4 details-page-container">
            <div className="d-flex flex-column details-header">
              Items Information
            </div>
            <div className="p-3 w-100">
              <div className="details-page-table-container">
                <table className="details-page-table">
                  <thead>
                    <tr>
                      <th scope="col">Item</th>
                      <th scope="col">Model Number</th>
                      <th scope="col">Quantity Ordered</th>
                      <th scope="col">Available Quantity</th>
                      <th scope="col">Price/Unit (₹)</th>
                      <th scope="col">GST (%)</th>
                      <th scope="col">Total Amount</th>
                    </tr>
                  </thead>

                  <tbody>
                    {tableRows.map((eachRows, index) => (
                      <tr key={eachRows.id} scope="col">
                        <td>{eachRows.item}</td>
                        <td>{eachRows.modelNumber}</td>
                        <td>{eachRows.quantityOrdered}</td>
                        <td>{eachRows.quantityAvailable}</td>
                        <td>{eachRows.unitPrice}</td>
                        <td>{eachRows.gst}</td>
                        <td>{eachRows.totalAmount}</td>
                      </tr>
                    ))}
                    <tr className="total-table-text">
                      <td className="fw-bold" colSpan={6}>
                        TOTAL
                      </td>
                      <td className="text-center fw-bold">{totalAmount}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="d-flex flex-column align-items-center my-4 details-page-container">
            <div className="d-flex flex-column details-header">
              Invoice Address
            </div>
            <div className="details-container container p-3">
              <div className="row gy-md-4 gy-2">
                <div className="kyc-fields-container col-12">
                  <label
                    htmlFor="billingAddress"
                    className="kyc-label-text form-label"
                  >
                    Billing Address
                  </label>
                  <input
                    id="billingAddress"
                    disabled
                    type="text"
                    className="kyc-input-field form-control"
                    value={inputValues.shippingToAddress}
                  />
                </div>

                <div className="kyc-fields-container col-12">
                  <label
                    htmlFor="shippingAddress"
                    className="kyc-label-text form-label"
                  >
                    Shipping Address
                  </label>
                  <input
                    id="shippingAddress"
                    type="text"
                    className="kyc-input-field form-control shadow"
                    value={inputValues.shippingToAddress}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex flex-column align-items-center my-4 details-page-container">
            <div className="d-flex flex-column details-header">
              Transport Details
            </div>
            <div className="details-container container p-3">
              <div className="row gy-md-4 gy-2">
                <div className="kyc-fields-container col-12  col-md-4">
                  <label htmlFor="name" className="kyc-label-text form-label">
                    Shipment Terms
                  </label>
                  <input
                    disabled
                    type="text"
                    className="kyc-input-field form-control"
                    value={inputValues.shippingTerms}
                  />
                </div>

                <div className="kyc-fields-container col-12  col-md-4">
                  <label htmlFor="name" className="kyc-label-text form-label">
                    Mode of Transport
                  </label>
                  <input
                    disabled
                    type="text"
                    className="kyc-input-field form-control"
                    value={inputValues.modeOfTransport}
                  />
                </div>

                <div className="kyc-fields-container col-12  col-md-4">
                  <label htmlFor="name" className="kyc-label-text form-label">
                    Expected Date of delivery
                  </label>
                  <input
                    disabled
                    type="text"
                    className="kyc-input-field form-control"
                    value={inputValues.expectedDateOfDelivery}
                  />
                </div>

                <div className="kyc-fields-container col-12  col-md-4">
                  <label htmlFor="name" className="kyc-label-text form-label">
                    Delivery Location
                  </label>
                  <input
                    disabled
                    type="text"
                    className="kyc-input-field form-control"
                    value={inputValues.locationOfDelivery}
                  />
                </div>

                <div className="kyc-fields-container col-12  col-md-4">
                  <label htmlFor="name" className="kyc-label-text form-label">
                    Payment Terms
                  </label>
                  <input
                    disabled
                    type="text"
                    className="kyc-input-field form-control"
                    value={inputValues.paymentTerms}
                  />
                </div>

                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="selectAnchor"
                    className="kyc-label-text form-label"
                  >
                    Select Bank
                  </label>
                  <select
                    value={selectLender}
                    onChange={onChangeBankList}
                    name="selectAnchor"
                    className="kyc-input-field form-select"
                    id="selectAnchor"
                    required
                  >
                    <option value="" disabled defaultValue>
                      Open this select menu
                    </option>

                    {selectLenderData.data &&
                      selectLenderData.data.map((eachLenderData) => (
                        <option
                          key={eachLenderData.customerId}
                          value={eachLenderData.emailId}
                        >
                          {eachLenderData.fullName}
                        </option>
                      ))}
                  </select>
                </div>

                {/* <div className="kyc-fields-container col-12  col-md-4">
              <label htmlFor="name" className="kyc-label-text form-label">
              Additional Text
              </label>
              <input
                disabled
                type="text"
                className="kyc-input-field form-control"
                value={inputValues.shippingTerms}
              />
            </div> */}
              </div>
            </div>
          </div>

          <div
            className={`d-flex gap-2 align-items-center my-4 details-page-container ${
              termAndCondition ? "bg-success" : "bg-danger"
            } bg-opacity-25 p-2`}
          >
            <input
              id="termAndCondition"
              type="checkbox"
              className="form-check-input"
              value={termAndCondition}
              onChange={onChangeTermAndCondition}
              required
            />
            <label
              htmlFor="termAndCondition"
              className="kyc-label-text form-check-label"
            >
              I hereby declare that this invoice is not funded/discounted with
              any financier outside ATON
            </label>
          </div>

          <div className="d-flex flex-column flex-md-row align-items-center justify-content-end gap-3 mx-5 my-3">
            <button className="btn btn-dark px-4" onClick={onClickBack}>
              Back
            </button>

            <button
              className="kyc-back-button btn btn-primary w-25"
              type="submit"
            >
              Send for Discounting
            </button>
          </div>
        </>
      )}
    </form>
  );
};

export default DigitalInvoiceDetailsById;
