import { Fragment, useContext, useEffect } from "react";
import { GenericInputField } from "../../../../GenericComponent/Input/Input";
import { actions } from "./gstDetailsReducer"
import { CorporateKycContext } from "../corporateKycContext";
import { useDispatch } from "react-redux";
import { gstVerification } from "../../../../store/slices/anchor";

export const CorporateKYCGstDetails = (props) => {

	const { gstDetailsDispatch: setState, gstDetails: { gst } } = useContext(CorporateKycContext);

	const dispatch = useDispatch();

	const handleGSTBlur = () => {
		if (gst.value.length === 15) {
			dispatch(gstVerification(gst.value))
				.unwrap()
				.then(response => {
					console.log('status', response?.success);
					if (response?.success) {
						setState({ type: actions.BLUR_GST, payload: true });
					}
					else {
						setState({ type: actions.BLUR_GST, payload: false });
					}
				})
				.catch(error => {
					setState({ type: actions.BLUR_GST, payload: false });
				});
		}
		
	};

	useEffect(() => {
		console.log("gst", gst)
	}, [ gst ]);

	return (
		<Fragment>
			<div className="container-with-heading d-flex flex-column container">
				<span className="kyc-content-title mb-3 row">GST Details</span>
				<div className="details-container ">
					<div className="row gy-md-4 gy-2">

						<GenericInputField
							label="GST Number" required={true} value={gst.value} isInvalid={gst.isTouched && (!gst.isValid || !gst.isVerified)}
							errorMessage={gst.value === "" ? "GST Number is Required" : !gst.isValid ? "Invalid GST Number" : "GST Number is Not Verified"}
							verified={gst.isVerified} successMessage="GST Number is Verified" onChangeHandler={event => setState({ type: actions.CHANGE_GST, payload: event.target.value })}
							onBlurHandler={() => handleGSTBlur() }
						/>

					</div>
				</div>
			</div>
		</Fragment>
	);
};
