import { Fragment } from "react";
import { toastMessage } from "../layout/GenericLayout/ToastMessage";
import { useNavigate } from "react-router-dom";

export const ResetPasswordError = () => {

	toastMessage("Token Not Available in the url", "error");
	
	const navigate = useNavigate();
	
	setTimeout(() => navigate("/login"), 3500);

	return (
		<Fragment></Fragment>
	);
};