import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../layout/GenericLayout/Loader";
import { getPaymentReconciliationTable } from "../../store/slices/anchor";
import AnchorPaymentReconciliationTable from "./AnchorTables/AnchorPaymentReconciliation/AnchorPaymentReconciliationTable";
import {Helmet} from "react-helmet";

const AnchorPaymentReconciliation = () => {
  const dispatch = useDispatch();
  const paymentReconciliationTableData = useSelector(
    (state) => state.anchor.paymentReconciliationTableData
  );
  const [paymentReconciliation, setPaymentReconciliation] = useState([]);

  useEffect(() => {
    dispatch(getPaymentReconciliationTable());
  }, [dispatch]);

  useEffect(() => {
    if (paymentReconciliationTableData.data) {
      setPaymentReconciliation(paymentReconciliationTableData.data.items);
    }
  }, [paymentReconciliationTableData.data]);
  console.log("paymentReconciliationTableData", paymentReconciliationTableData);
  return (
    <div className="d-flex flex-column gap-4">
      <Helmet>
                <title>Payment-Reconciliation</title>
            </Helmet>
      <div className="bg-white admin-dashboard-card-container px-3 pt-3 d-flex flex-column gap-2 pb-5">
        <span className="dashboard-content-title">Payment Reconciliation</span>
        {paymentReconciliationTableData.isLoading ? (
          <Loader className="my-5" />
        ) : (
          <AnchorPaymentReconciliationTable
            className="admin-users-data-table"
            tableHeader={[
              "Buyer Name",
              "Check Invoice Details",
              "Amount received from buyer (₹)",
              "Amount financed from bank (₹)",
              "Repayment Date",
              "Repayment Amount (₹)",
            ]}
            tableRows={paymentReconciliation}
          />
        )}
      </div>
    </div>
  );
};

export default AnchorPaymentReconciliation;
