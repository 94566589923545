import React, { useEffect, useState } from "react";
import ProformaInvoiceTable from "../BuyerTables/ProformaInvoiceTable";
import { BsPlusCircleFill, BsSearch } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { getProformaInvoiceTable } from "../../../store/slices/buyer";
import {Helmet} from "react-helmet";

const BuyerProformaInvoice = () => {
  const dispatch = useDispatch();
  const proFormaInvoiceTableResponse = useSelector(
    (state) => state.buyer.proformaInvoiceTableData
  );

  const [proformaInvoiceTableRowData, setProformaInvoiceTableRowData] =
    useState([]);
  const [proformInvoiceTableMetaData, setProformInvoiceTableMetaData] =
    useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [searchByProformaID, setSearchByProformaID] = useState("");

  /* TABLE QUERY PARAMS */
  const [tableQueryParams, setTableQueryParams] = useState({
    tableOffset: 0,
    tableLimit: 5,
    tableSort: "proformaId",
    tableOrder: 1,
    currentPage: 0,
    search: "",
  });

  useEffect(() => {
    let tableData = ``
    if (searchByProformaID.length > 0) {
      tableData = `offset=${tableQueryParams.tableOffset}&limit=${tableQueryParams.tableLimit}&search=${searchByProformaID}`;
      // &sort=${tableQueryParams.tableSort}&search=${searchByProformaID}&order=${tableQueryParams.tableOrder}`;
      dispatch(getProformaInvoiceTable( tableData ));
    }
    else{
      tableData = `offset=${tableQueryParams.tableOffset}&limit=${tableQueryParams.tableLimit}`;
      // &sort=${tableQueryParams.tableSort}&order=${tableQueryParams.tableOrder}`;
      dispatch(getProformaInvoiceTable( tableData ));
    }
   
  }, [tableQueryParams, dispatch, searchByProformaID]);


  useEffect(() => {
    if (proFormaInvoiceTableResponse.data) {
      setProformaInvoiceTableRowData(proFormaInvoiceTableResponse.data.items);
      setProformInvoiceTableMetaData(
        proFormaInvoiceTableResponse.data.metadata
      );
    }
  }, [proFormaInvoiceTableResponse]);


  const handleSearchByProformaIDOnchange = (e) => {
    setSearchByProformaID(e.target.value);
  };


  // const handleSearchByProformaIDSubmit = (e) => {
  //   if (e.key === "Enter") {
  //     console.log("Thiis is search by ProfoID", searchByProformaID);
  //     setTableQueryParams((prev) => ({ ...prev, search: searchByProformaID }));
  //   }
  // };
  // console.log("The Meta Data ", proformInvoiceTableMetaData);

  return (
    <>
      <div className=""
      style={{overflowX:'hidden'}}
      >
        <Helmet>
                <title>Proforma-Invoice</title>
            </Helmet>
        <div className="bg-white admin-dashboard-card-container p-3">
          <div className="d-flex justify-content-between align-items-center flex-wrap px-2 gap-2 py-2">
            <span className="dashboard-content-title">Proforma Invoice</span>
          </div>

          <div className="d-flex flex-column flex-sm-row align-items-center my-4">
            <div className="d-flex flex-column justify-content-center gap-3 col-12 col-sm-6">
              {/* <label
                htmlFor="selectAnchor"
                className="anchor-and-lender-select-label"
              >
                Proforma Invoice
              </label> */}
              <div className="search-container d-flex align-items-center py-1 px-3 col-12 col-sm-8">
                <input
                  className="search-input-element d-flex flex-grow-1"
                  type="text"
                  placeholder="Search with Proforma Invoice ID"
                  value={searchByProformaID}
                  onChange={handleSearchByProformaIDOnchange}
                />{" "}
                <BsSearch className="fs-5" />
              </div>
            </div>
          </div>
        </div>


      {/* The Table container starts here */}
        <div 
            className="mb-5 mt-3"
            style={{
                  overflowX:'hidden',
                  boxSizing:'border-box'
                  }}
        >
          <ProformaInvoiceTable
            className="buyer-table placeholder-wave"
            tableHeader={[
              { name: "Proforma Date", sortValue: "addedDate" },
              { name: "Proforma Invoice ID", sortValue: "proformaId" },
              { name: "Seller Name", sortValue: "sellerName" },
              { name: "Date of Delivery", sortValue: "expectedDateOfDelivery" },
              { name: "Payment Terms (Days)", sortValue: "paymentTerms" },
              { name: "Status", sortValue: "proformaStatus" },
            ]}
            tableRows={proformaInvoiceTableRowData}
            materialRequestMetaData={proformInvoiceTableMetaData}
            tableQueryParams={tableQueryParams}
            setTableQueryParams={setTableQueryParams}
            isLoading={proFormaInvoiceTableResponse.isLoading}
          />
        </div>
      </div>
    </>
  );
};

export default BuyerProformaInvoice;
