import { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useOutlet } from "react-router-dom";
import { BsSearch } from "react-icons/bs";
import { TbBellRingingFilled } from "react-icons/tb";
import { FcAdvertising } from "react-icons/fc";
import { FaBars } from "react-icons/fa";
import { RxCross1 } from "react-icons/rx";
import { AiFillLeftCircle, AiFillSetting } from "react-icons/ai";
import { FiLogOut } from "react-icons/fi";
import atonLogo from "../../assests/images/Aton-logo.png";
import notificationDeclined from "../../assests/icons/notification-declined.svg";
import notificationActive from "../../assests/icons/notification-active.svg";
import profilePic from '../../assests/images/profilePicture.jpg'
import {profilePicture} from '../../store/slices/generic'
// importing Icons of Side Bar in Buyer
import {
  DashBoardIcon,
  MaterialRequestIcon,
  ProformaInvoiceIcon,
  PurchaseOrderIcon,
  InvoiceIcon,
} from "../../assests/icons/sidebar/BuyerSideBarIcons/BuyerSideBarIcons";

// import { DashBoardBuyerIco } from "../../assests/icons/sidebar/Buyer/dash.svg";

import { MdOutlineManageAccounts } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

const BuyerLayout = () => {
  const navigate = useNavigate();
  const outlet = useOutlet();
  const userDetails = JSON.parse(sessionStorage.getItem("user"));

  const [activeMenu, setActiveMenu] = useState("/buyer/dashboard");
  const [notification, setNotification] = useState([]);
  const [mobileNavbarStatus, setMobileNavbarBar] = useState(false);

  const sidebarRef=useRef(null);
  const sidebarMenuTextRef=useRef(null);
  const sidebarCircleRef=useRef(null);


   //profile picture
   const [profilePictureImage, setProfilePictureImage]=useState(profilePic);
   const profilePictureData=useSelector(
     (state)=> state.generic.profilePictureData
   )

   const dispatch=useDispatch();
 
 
   useEffect(()=>{
     dispatch(profilePicture());
   },[])
 
 
   useEffect(()=>{
     console.log(profilePictureData);
     if(profilePictureData?.data){
      const fileExtension = profilePictureData?.data?.items[0]?.URL?.split('.')?.pop()?.toLowerCase();
      console.log(profilePictureData?.data?.items[0]?.URL, fileExtension)
      if(fileExtension && fileExtension !== 'pdf'){
        setProfilePictureImage(profilePictureData?.data?.items[0]?.URL)
      }
    }
   },[profilePictureData.data])



  const onClickActiveMenu = (path) => {
    setActiveMenu(path);
    setMobileNavbarBar((prev) => !prev);
    navigate(path);
  };

  const onClickSidebarCollaspe = () => {
    
    // let sidebar = document.querySelector(".admin-sidebar");
    const sidebar=sidebarRef.current;
    // console.log('buyer layouyt side bar widht check -->',sidebar.style);
    if (sidebar.style.width === "120px") {
      sidebar.style.minWidth = "350px";
      sidebar.style.width = "350px";
    } else {
      // console.log('validation for size reduce in Buyer Layout', sidebar.style.width);
      // sidebar.style.border = "3px solid black";
      sidebar.style.minWidth = "120px";
      sidebar.style.width = "120px";
    }

    // let sidebarMenuText = document.querySelectorAll(".sidebar-text");
    let sidebarMenuText= sidebarMenuTextRef.current.childNodes;
    sidebarMenuText.forEach((eachMenuText) => {
      if (eachMenuText.lastChild.style.display === "none") {
        eachMenuText.lastChild.style.display = "";
      } else {
        eachMenuText.lastChild.style.display = "none";
      }
    });

    // let sidebarCircle = document.querySelector(".sidebar-circle-icon");
    const sidebarCircle= sidebarCircleRef.current;
    if (sidebarCircle.style.transform === "rotate(180deg)") {
      sidebarCircle.style.transform = "rotate(0deg)";
    } else {
      sidebarCircle.style.transform = "rotate(180deg)";
    }
  };

  const onChangeAdminSearch = (event) => {
    // Search resutls to be handled
  };

  const onClickLogout = () => {
    sessionStorage.clear();
    window.location.href = "/login";
  };
  const onClickMyProfile = () => {
    navigate("/buyer/my-profile");
  };

  // ********small and medium devices events********
  const onClickMobileNavbar = () => {
    setMobileNavbarBar((prev) => !prev);
  };

  return (
    <>
      {/* desktop and tablet layout */}
      <div className="d-md-flex gap-2 min-vh-100 admin-layout-bg-container p-3 pe-0 d-none">
        <div className="admin-sidebar mh-100 d-flex flex-column py-1" ref={sidebarRef}>
          <div className="sidebar-circle" ref={sidebarCircleRef}>
            <AiFillLeftCircle
              className="sidebar-circle-icon"
              onClick={onClickSidebarCollaspe}
            />
          </div>

          <Link to="/buyer/dashboard">
            <div className="d-flex justify-content-center align-items-center">
              <img src={atonLogo} alt="aton logo" className="admin-sidebar-image-logo" />
            </div>
          </Link>

          <div className="sidebar-menu-container d-flex flex-column gap-2" ref={sidebarMenuTextRef}>
            <div
              className={`d-flex align-items-center px-5 gap-3 py-2 ${
                window.location.pathname.includes("/buyer/dashboard")
                  ? "sidebar-active-menu"
                  : "menu-container"
              }`}
              title="Dashboard"
              onClick={() => onClickActiveMenu("/buyer/dashboard")}
            >
              <DashBoardIcon styleClassName="sidebar-icons" />
              <span className="sidebar-text">Dashboard</span>
            </div>

            <div
              className={`d-flex align-items-center px-5 gap-3 py-2 ${
                window.location.pathname.includes("/buyer/material-request")
                  ? "sidebar-active-menu"
                  : "menu-container"
              }`}
              onClick={() => onClickActiveMenu("/buyer/material-request")}
              title="Material Request"
            >
              <MaterialRequestIcon styleClassName="sidebar-icons" />
              <span className="sidebar-text">Material Request</span>
            </div>

            <div
              className={`d-flex align-items-center px-5 gap-3 py-2 ${
                window.location.pathname.includes("/buyer/proforma-invoice")
                  ? "sidebar-active-menu"
                  : "menu-container"
              }`}
              onClick={() => onClickActiveMenu("/buyer/proforma-invoice")}
              title="Proforma Invoice"
            >
              <ProformaInvoiceIcon styleClassName="sidebar-icons" />
              <span className="sidebar-text">Proforma Invoice</span>
            </div>

            <div
              className={`d-flex align-items-center px-5 gap-3 py-2 ${
                window.location.pathname.includes("/buyer/purchase-order")
                  ? "sidebar-active-menu"
                  : "menu-container"
              }`}
              onClick={() => onClickActiveMenu("/buyer/purchase-order")}
              title="Purchase Order"
            >
              <PurchaseOrderIcon styleClassName="sidebar-icons" />
              <span className="sidebar-text">Purchase Order</span>
            </div>
            <div
              className={`d-flex align-items-center px-5 gap-3 py-2 ${
                window.location.pathname.includes("/buyer/inv-reference-number")
                  ? "sidebar-active-menu"
                  : "menu-container"
              }`}
              onClick={() => onClickActiveMenu("/buyer/inv-reference-number")}
              title="INV Reference Number"
            >
              <PurchaseOrderIcon styleClassName="sidebar-icons" />
              <span className="sidebar-text" id="INV_reference_number">INV Reference Number</span>
            </div>

            <div
              className={`d-flex align-items-center px-5 gap-3 py-2 ${
                window.location.pathname.includes("/buyer/invoice")
                  ? "sidebar-active-menu"
                  : "menu-container"
              }`}
              onClick={() => onClickActiveMenu("/buyer/invoice")}
              title="Invoice"
            >
              <InvoiceIcon styleClassName="sidebar-icons" />
              <span className="sidebar-text">Invoice</span>
            </div>
            
            <div
              className={`d-flex align-items-center px-5 gap-3 py-2 ${
                window.location.pathname.includes("/buyer/bulk_invoice")
                  ? "sidebar-active-menu"
                  : "menu-container"
              }`}
              onClick={() => onClickActiveMenu("/buyer/bulk_invoice")}
              title="Batch Invoice"
            >
              <InvoiceIcon styleClassName="sidebar-icons" />
              <span className="sidebar-text">Batch Invoice</span>
            </div>
          </div>
        </div>
        <div
          className="flex-grow-1 px-0 mb-5"
          style={{
            overflowY: "hidden",
            overflowX: "hidden",
            boxSizing: "border-box",
          }}
        >
          
          <div className={`d-flex align-items-center pe-3 search justify-content-end py-3`}>
            {/* { window.location.pathname.includes("/buyer/dashboard")?
            <div className="admin-search-container d-flex align-items-center py-1 px-4 mx-3 mb-4">
              <input
                className="admin-search-input-element d-flex flex-grow-1"
                type="search"
                placeholder="Search"
                onChange={onChangeAdminSearch}
              />{" "}
              <BsSearch />
            </div>
            :""} */}
            <div className="d-flex gap-3 align-items-center">
              <div className="dropdown-center">
                <button
                  className="notification-bell border-0"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {notification.length === 0 ? (
                    <img src={notificationDeclined} alt="notification declined" />
                  ) : (
                    <img src={notificationActive} alt="notification active" />
                  )}
                </button>
                <ul
                  className="dropdown-menu dropdown-menu-end border-0 shadow"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <h6 className="dropdown-header d-flex align-items-center gap-2">
                      <TbBellRingingFilled /> Notification
                    </h6>
                  </li>
                  <li className="dropdown-item d-flex flex-column justify-content-center align-items-center">
                    <FcAdvertising className="fs-3" />
                    No new notification!
                  </li>
                </ul>
              </div>
              <div className="d-flex align-items-center gap-2">
                <div className="dropdown-center">
                  <button
                    className="border-0"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      className="admin-profile-pic rounded-circle"
                      src={profilePictureImage}
                      alt="admin profile pic"
                    />
                  </button>
                  <ul
                    className="dropdown-menu dropdown-menu-start border-0 shadow"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <h6 className="dropdown-header d-flex align-items-center gap-2">
                        <AiFillSetting /> Menu
                      </h6>
                    </li>
                    <li
                      className="dropdown-item fw-semibold d-flex align-items-center gap-2"
                      style={{ cursor: "pointer" }}
                      onClick={onClickMyProfile}
                    >
                      {" "}
                      <MdOutlineManageAccounts /> My Profile
                    </li>
                    <li
                      className="dropdown-item fw-semibold d-flex align-items-center gap-2"
                      style={{ cursor: "pointer" }}
                      onClick={onClickLogout}
                    >
                      {" "}
                      <FiLogOut /> Logout
                    </li>
                  </ul>
                </div>

                <div className="d-flex flex-column justify-content=evenly">
                  <span className="admin-name">
                    {userDetails.name === null ? "N/A" : "Hi, " + userDetails.name}
                  </span>
                  <span className="role">Counterparty!</span>
                </div>
              </div>
            </div>
          </div>

          <div className="buyer-content">
            {window.innerWidth > 767 && outlet}
            <footer className="footer">
              <div>Copyright &copy; 2023 Aton</div>
            </footer>

          </div>
        </div>
      </div>
      {/* mobile layout */}
      <div
        className={`d-md-none admin-layout-small-devices-bg-container d-flex flex-column ${
          mobileNavbarStatus && "active-navbar"
        }`}
      >
        <div className="topnav d-flex flex-column">
          <div className="d-flex justify-content-between align-items-center px-2 py-2 only-navbar">
            <div className="d-flex align-items-center gap-2">
              <button
                onClick={onClickMobileNavbar}
                type="button"
                className="btn outline-0 border-0"
              >
                {mobileNavbarStatus ? (
                  <RxCross1 className="fs-1" />
                ) : (
                  <FaBars className="fs-2" />
                )}
              </button>

              <Link to="/admin/dashboard">
                <div className="d-flex justify-content-center align-items-center">
                  <img src={atonLogo} alt="aton logo" className="mobile-navbar-image-logo" />
                </div>
              </Link>
            </div>

            <div className="d-flex align-items-center gap-2">
              <div className="dropdown-center">
                <button
                  className="border-0 bg-transparent"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {notification.length === 0 ? (
                    <img src={notificationDeclined} alt="notification declined" />
                  ) : (
                    <img src={notificationActive} alt="notification active" />
                  )}
                </button>
                <ul
                  className="dropdown-menu dropdown-menu-end border-0 shadow"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <h6 className="dropdown-header d-flex align-items-center gap-2">
                      <TbBellRingingFilled /> Notification
                    </h6>
                  </li>
                  <li className="dropdown-item d-flex flex-column justify-content-center align-items-center">
                    <FcAdvertising className="fs-3" />
                    No new notification!
                  </li>
                </ul>
              </div>

              <div className="d-flex align-items-center gap-2">
                <div className="dropdown-center">
                  <button
                    className="border-0 bg-transparent"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      className="admin-profile-pic rounded-circle"
                      src={profilePictureImage}
                      alt="admin profile pic"
                    />
                  </button>
                  <ul
                    className="dropdown-menu dropdown-menu-start border-0 shadow"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <h6 className="dropdown-header d-flex align-items-center gap-2">
                        <AiFillSetting /> Admin!
                      </h6>
                    </li>

                    <li className="dropdown-item d-flex flex-column justify-content-center align-items-center">
                      {userDetails.name === null ? "N/A" : userDetails.name}
                    </li>
                  </ul>
                </div>
              </div>

              <div
                className="fw-semibold d-flex align-items-center justify-content-center"
                style={{ cursor: "pointer" }}
                onClick={onClickLogout}
              >
                <FiLogOut className="fs-2" />
              </div>
            </div>
          </div>

          {mobileNavbarStatus && (
            <div className="min-vh-100">
              <div
                className="d-flex flex-column gap-1 justify-content-center"
                style={{ padding: "0 100px" }}
              >
                <div
                  className={`d-flex align-items-center gap-3 py-2 ${
                    window.location.pathname.includes("/buyer/dashboard")
                      ? "mobile-sidebar-active-menu"
                      : "mobile-menu-container"
                  }`}
                  onClick={() => onClickActiveMenu("/buyer/dashboard")}
                >
                  <DashBoardIcon styleClassName="sidebar-icon" />
                  <span className="sidebar-text">Dashboard</span>
                </div>

                <hr className="px-5 mobile-nav-line my-2" />

                <div
                  className={`d-flex align-items-center gap-3 py-2 ${
                    window.location.pathname.includes("/buyer/material-request")
                      ? "mobile-sidebar-active-menu"
                      : "mobile-menu-container"
                  }`}
                  onClick={() => onClickActiveMenu("/buyer/material-request")}
                >
                  <MaterialRequestIcon styleClassName="sidebar-icon" />
                  <span className="sidebar-text">Material Request</span>
                </div>

                <hr className="px-5 mobile-nav-line my-2" />

                <div
                  className={`d-flex align-items-center gap-3 py-2 ${
                    window.location.pathname.includes("/buyer/material-request")
                      ? "mobile-sidebar-active-menu"
                      : "mobile-menu-container"
                  }`}
                  onClick={() => onClickActiveMenu("/buyer/material-request")}
                >
                  <ProformaInvoiceIcon styleClassName="sidebar-icon" />
                  <span className="sidebar-text">Proforma Invoice</span>
                </div>

                <hr className="px-5 mobile-nav-line my-2" />

                <div
                  className={`d-flex align-items-center gap-3 py-2 ${
                    window.location.pathname.includes("/admin/channel-partner")
                      ? "mobile-sidebar-active-menu"
                      : "mobile-menu-container"
                  }`}
                  onClick={() => onClickActiveMenu("/admin/channel-partner")}
                >
                  <PurchaseOrderIcon styleClassName="sidebar-icon" />
                  <span className="sidebar-text">Purchase Order</span>
                </div>

                <hr className="px-5 mobile-nav-line my-2" />

                <div
                  className={`d-flex align-items-center gap-3 py-2 ${
                    window.location.pathname.includes(
                      "/buyer/relationship-manager"
                    )
                      ? "mobile-sidebar-active-menu"
                      : "mobile-menu-container"
                  }`}
                  onClick={() =>
                    onClickActiveMenu("/buyer/relationship-manager")
                  }
                >
                  <InvoiceIcon styleClassName="sidebar-icon" />
                  <span className="sidebar-text">Invoice</span>
                </div>

                <hr className="px-5 mobile-nav-line my-2" />
                
                <div
                  className={`d-flex align-items-center gap-3 py-2 ${ window.location.pathname.includes("/buyer/bulk_invoice") ? "mobile-sidebar-active-menu" : "mobile-menu-container" }`}
                  onClick={() =>
                    onClickActiveMenu("/buyer/bulk_invoice")
                  }
                >
                  <InvoiceIcon styleClassName="sidebar-icon" />
                  <span className="sidebar-text">Batch Invoice</span>
                </div>

                <hr className="px-5 mobile-nav-line my-2" />
              </div>
            </div>
          )}
        </div>
        {!mobileNavbarStatus && (
          // <div className="min-vh-100">{props.children}</div>
          <div className="min-vh-100 p-2" style={{border:"3px solid red", overflowY:'scroll'}}>
            {window.innerWidth < 768 && outlet}
            <footer className="text-center " style={{fontSize:"0.8rem"}}>
              <div>Copyright &copy; 2023 Aton</div>
            </footer>
          </div>
        )}
      </div>
    </>
  );
};

export default BuyerLayout;
