import { Fragment, useContext, useEffect, useMemo, useState } from "react";

import { GenericInputField } from "../../../../GenericComponent/Input/Input";
import { AnchorKYCCoApplicantDetails } from "../coApplicantDetails";
import { AnchorKycContext } from "../anchorKycContext";
import { AnchorKycActions } from "../actionTypes";
import { GenericFileInput } from "../../../../GenericComponent/FileInput/FileInput";
import { useDispatch } from "react-redux";
import { updatePANVerification } from "../../../../store/slices/generic";
import moment from "moment";
import { toastMessage } from "../../../../layout/GenericLayout/ToastMessage";

export const AnchorKYCPersonalDetails = (props) => {
  // ---------------------------> States and Reducers
  const [aadhaarFiles, setAadhaarFiles] = useState([]);
  const [profilePics, setProfilePics] = useState([]);

  const minDate = useMemo(() => {
    const today = new Date();

    today.setFullYear(today.getFullYear() - 1);
    today.setDate(today.getDate() - 1);

    return moment(today).format("YYYY-MM-DD");
  }, []);

  // ---------------------------> HOOKS
  const {
    personalDetails,
    personalDetailsDispatch,
    files, disableEdit,
    emailId,
    uploadFiles,
  } = useContext(AnchorKycContext);
  const dispatch = useDispatch();

  console.log("personalDetails : ", personalDetails);

  // --------> useEffects
  useEffect(() => {
    if (
      files?.length > 0 &&
      files?.[0]?.documents &&
      Array.isArray(files[0].documents)
    ) {
      const aadhaarTemp = [],
        profilePicsTemp = [];
      for (let item of files[0].documents) {
        if (item?.adharImage !== undefined) {
          aadhaarTemp.push(item);
        } else if (item?.userProfilePic !== undefined) {
          profilePicsTemp.push(item);
        }
      }
      
      if (aadhaarTemp.length > 0) {
        personalDetailsDispatch({
          type: AnchorKycActions.PERSONAL_DETAILS.AADHAAR_UPDATE_API,
          payload: aadhaarTemp,
        });
      }

      if (profilePicsTemp.length > 0) {
        personalDetailsDispatch({
          type: AnchorKycActions.PERSONAL_DETAILS.PROFILE_PIC_UPDATE_API,
          payload: profilePicsTemp,
        });
      }

      setAadhaarFiles(aadhaarTemp);
      setProfilePics(profilePicsTemp);
    }
  }, [files, personalDetailsDispatch]);

  // -----------------------------------> Handlers and Functions

  /**
   * @param { string } value
   */
  const handleNameChange = (value) => {

    const pattern = /^[A-Za-z ]*$/g;

		if (!pattern.test(value)) {
			return;
		}
    
    personalDetailsDispatch({
      type: AnchorKycActions.PERSONAL_DETAILS.CHANGE_NAME,
      payload: value,
    });
  };

  /**
   * @param { string } value
   */
  const handleNameBlur = () => {
    personalDetailsDispatch({
      type: AnchorKycActions.PERSONAL_DETAILS.BLUR_NAME,
    });
  };

  /**
   * @param {string} value
   */
  const handleDOBBlur = (value) => {
    personalDetailsDispatch({
      type: AnchorKycActions.PERSONAL_DETAILS.BLUR_DOB,
      payload: value,
    });
  };

  /**
   * @param {string} value
   */
  const handleDOBChange = (value) => {
    personalDetailsDispatch({
      type: AnchorKycActions.PERSONAL_DETAILS.CHANGE_DOB,
      payload: value,
    });
  };

  const handlePanBlur = () => {
    let regex = new RegExp(/[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}/);
    if (personalDetails.panNo.value.length === 10 && regex.test(personalDetails.panNo.value)) {
      const body = {
        panNumber: personalDetails.panNo.value,
        userid: emailId,
      };

      dispatch(updatePANVerification(body))
        .unwrap()
        .then(response => {
          if (response?.status) {
            personalDetailsDispatch({ type: AnchorKycActions.PERSONAL_DETAILS.BLUR_PAN, payload: true });
          }
        })
        .catch(error => {
          personalDetailsDispatch({ type: AnchorKycActions.PERSONAL_DETAILS.BLUR_PAN, payload: false });
          toastMessage(error.message ?? "Unable to verify PAN", "error");
        });
    } else {
      personalDetailsDispatch({
        type: AnchorKycActions.PERSONAL_DETAILS.BLUR_PAN,
        payload: false,
      });
    }
  };

  /**
   * @param { React.ChangeEvent<HTMLInputElement> } event
   */
  const handleAadhaarChange = (event) => {
    const formData = new FormData();

    formData.append("adharImage", event?.target?.files?.[0]);

    uploadFiles(formData, "adharImage");
  };

  /**
   * @param { React.ChangeEvent<HTMLInputElement> } event
   */
  const handleProfilePicChange = (event) => {
    const formData = new FormData();

    formData.append("userProfilePic", event.target.files?.[0]);

    uploadFiles(formData, "userProfilePic");
  };

  return (
    <Fragment>
      <div className="container-with-heading d-flex flex-column container">
        <span className="kyc-content-title mb-3 row">Personal Details</span>
        <div className="details-container pb-0">
          <div className="row gy-md-4 gy-2">
            <GenericInputField
              label="Name"
              value={personalDetails.name.value}
              isInvalid={
                personalDetails.name.isTouched && !personalDetails.name.isValid
              }
              errorMessage={
                personalDetails.name.value === ""
                  ? "Name is required"
                  : "Name should have at least 4 characters"
              }
              disabled={disableEdit}
              required={true}
              onChangeHandler={(event) =>
                handleNameChange(event?.target?.value)
              }
              onBlurHandler={(_) => handleNameBlur()}
            />

            <GenericInputField
              label="Date of Birth (Should be at least 18 years)"
              value={personalDetails.DOB.value}
              isInvalid={
                personalDetails.DOB.isTouched && !personalDetails.DOB.isValid
              }
              errorMessage={
                personalDetails.DOB.value === ""
                  ? "DOB is required"
                  : "Invalid DOB"
              }
              required={true}
              type="date"
              onChangeHandler={(event) => handleDOBChange(event.target.value)}
              onBlurHandler={(event) => handleDOBBlur(event.target.value)}
              max={ minDate }
              disabled={disableEdit}
            />

            <GenericInputField
              label="PAN Number"
              value={personalDetails.panNo.value}
              isInvalid={
                (personalDetails.panNo.isTouched &&
                  !personalDetails.panNo.isValid) ||
                (personalDetails.panNo.isTouched &&
                  !personalDetails.panNo.isVerified)
              }
              errorMessage={
                personalDetails.panNo.value === ""
                  ? "PAN Number is required"
                  : "Invalid PAN Number"
              }
              disabled={disableEdit}
              required={true}
              onChangeHandler={(event) =>
                personalDetailsDispatch({
                  type: AnchorKycActions.PERSONAL_DETAILS.CHANGE_PAN,
                  payload: event.target.value.toUpperCase(),
                })
              }
              onBlurHandler={() => handlePanBlur()}
              verified={personalDetails.panNo.isVerified}
              successMessage="PAN Verified"
            />

            <GenericInputField
              label="Mobile Number"
              value={personalDetails.mobileNumber.value}
              isInvalid={
                personalDetails.mobileNumber.isTouched &&
                !personalDetails.mobileNumber.isValid
              }
              errorMessage={
                personalDetails.mobileNumber.value === ""
                  ? "Mobile Number is required"
                  : !personalDetails.mobileNumber.isValid ? "Invalid Mobile Number" : ""
              }
              required={true}
              onChangeHandler={(event) =>
                personalDetailsDispatch({
                  type: AnchorKycActions.PERSONAL_DETAILS.CHANGE_MOBILE,
                  payload: event.target.value,
                })
              }
              onBlurHandler={(event) =>
                personalDetailsDispatch({
                  type: AnchorKycActions.PERSONAL_DETAILS.BLUR_MOBILE,
                })
              }
              disabled={disableEdit}
            />

            <GenericFileInput
              label="User Profile Pic"
              required={true}
              fileList={profilePics}
              fileType="userProfilePic"
              isVerified={personalDetails.aadhaarImage.isVerified}
              onChangeHandler={handleProfilePicChange}
              isInvalid={ personalDetails.profilePic.isTouched && profilePics?.length === 0 }
              disabled={disableEdit}
            />

            <GenericFileInput
              label="Upload Aadhaar"
              required={true}
              fileList={aadhaarFiles}
              fileType="adharImage"
              onChangeHandler={handleAadhaarChange}
              isVerified={personalDetails.aadhaarImage.isVerified}
              isInvalid={
                personalDetails.aadhaarImage.isTouched &&
                (!personalDetails.aadhaarImage.isVerified || aadhaarFiles?.length === 0)
              }
              disabled={disableEdit}
            />
          </div>
        </div>

        {/*===============================================
         *  CO-APPLICANT DETAILS
         * ==============================================*/}
        <AnchorKYCCoApplicantDetails />
      </div>
    </Fragment>
  );
};
