import React, { useEffect, useState } from "react";
import ProformaInvoiceTable from "../Lender/LenderTables/TransactionMaintenanceTable";
import { BsPlusCircleFill, BsSearch } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { getTransactionData, postDeletApi } from "../../store/slices/lender";
import ReusableModal from "../../layout/GenericLayout/ReusableModal";
import EditComp from "./EditComp";
import { toastMessage } from "../../layout/GenericLayout/ToastMessage";
import { useNavigate } from "react-router-dom";
import {Helmet} from "react-helmet";

const CamModal = ({ editData, setEditModal }) => {
  return <EditComp editData={editData} title={"Update Transaction Maintainance"} setEditModal={setEditModal} />;
};

const LenderTransactionMaintenance = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const proFormaInvoiceTableResponse = useSelector(
    (state) => state.lender.Transacdata
  );
  const deleteData = useSelector((state) => state.lender.deleteApi);

  const [proformaInvoiceTableRowData, setProformaInvoiceTableRowData] =
    useState([]);
  const [proformInvoiceTableMetaData, setProformInvoiceTableMetaData] =
    useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [searchByProformaID, setSearchByProformaID] = useState("");
  const [editModal, setEditModal] = useState(false);
  const [editData, setEditData] = useState("");

  /* TABLE QUERY PARAMS */
  const [tableQueryParams, setTableQueryParams] = useState({
    tableOffset: 0,
    tableLimit: 10,
    tableSort: "proformaId",
    tableOrder: 1,
    currentPage: 0,
    search: "",
  });

  useEffect(() => {
    dispatch(getTransactionData(tableQueryParams.tableOffset));

    // setSearchByProformaID("");
  }, [tableQueryParams, dispatch, editModal]);

  useEffect(() => {
    if (proFormaInvoiceTableResponse.data) {
      console.log(proFormaInvoiceTableResponse);
      setProformaInvoiceTableRowData(proFormaInvoiceTableResponse.data.items);
      setProformInvoiceTableMetaData(
        proFormaInvoiceTableResponse.data.metadata
      );
    }
  }, [proFormaInvoiceTableResponse]);

  const handleSearchByProformaIDOnchange = (e) => {
    setSearchByProformaID(e.target.value);
    //
  };

  useEffect(() => {
    if (searchByProformaID !== "") {
      setTableQueryParams((prev) => ({ ...prev, search: searchByProformaID }));
    }
  }, [searchByProformaID]);

  const handleSearchByProformaIDSubmit = (e) => {
    if (e.key === "Enter") {
      console.log("Thiis is search by ProfoID", searchByProformaID);
      setTableQueryParams((prev) => ({ ...prev, search: searchByProformaID }));
      // setSearchByProformaID('')
    }
  };

  // console.log("The Response or the Data Itself",proFormaInvoiceTableResponse);
  console.log("The Data Itself ", proformaInvoiceTableRowData);
  console.log("The Meta Data ", proformInvoiceTableMetaData);

  // console.log(searchByProformaID.length)

  const handleEdit = (data) => {
    if (proformaInvoiceTableRowData.length !== 0) {
      for (var i = 0; i < proformaInvoiceTableRowData.length; i++) {
        console.log(data);
        console.log(proformaInvoiceTableRowData[i].customerId);
        if (proformaInvoiceTableRowData[i].customerId === data) {
          setEditData(proformaInvoiceTableRowData[i]);
        }
      }
    }
    setEditModal(!editModal);
  };

  const handleDelete = (data) => {
    const body = {
      customerId: data,
    };
    dispatch(postDeletApi(body))
      .unwrap()
      .then((response) => {
        dispatch(getTransactionData(tableQueryParams.tableOffset));
        toastMessage(response.success, "success");
      })
      .catch((error) => {
        console.log(error);
        toastMessage(error.message, "error");
      });
  };

  const handleRedirect = () => {
    navigate({ pathname: "/lender/trans-maintain/new-trans-maintain" });
  };

  console.log(editData);
  return (
    <>
      <ReusableModal
        showModal={editModal}
        toggleModal={handleEdit}
        modalContent={
          editData !== "" && (
            <CamModal editData={editData} setEditModal={setEditModal} />
          )
        }
        modalSizeClass="modal-xl"
      />
      <div className="d-flex flex-column gap-4">
      <Helmet>
                <title>Transaction-Maintenance</title>
            </Helmet>
        <div className="bg-white admin-dashboard-card-container p-3">
          <div className="d-flex justify-content-between align-items-center flex-wrap px-2 gap-2 py-2">
            <span className="dashboard-content-title">
              Transaction Maintenance
            </span>
            <div
              className="d-flex align-items-center gap-2 border border-primary px-3 py-1 rounded"
              type="button"
              onClick={() => handleRedirect()}
            >
              <div>
                ADD Transaction Maintenance
              </div>
              <BsPlusCircleFill
                className="rm-plus-button"
                // onMouseEnter={handleMouseEnter}
                // onMouseLeave={handleMouseLeave}
              />
            </div>
          </div>

          {/* <div className="d-flex flex-column flex-sm-row align-items-center my-4">
            <div className="d-flex flex-column justify-content-center gap-3 col-12 col-sm-6">
              
              <div className="search-container d-flex align-items-center py-1 px-3 col-12 col-sm-8">
                <input
                  className="search-input-element d-flex flex-grow-1"
                  type="text"
                  placeholder="Search with Purchase ID"
                  value={searchByProformaID}
                  onChange={handleSearchByProformaIDOnchange}
                  // onKeyDown={handleSearchByProformaIDSubmit}

                  // value={search}
                />{" "}
                <BsSearch className="fs-5" />
              </div>
            </div>
          </div> */}
        </div>

        <div className="d-flex flex-column justify-content-between flex-wrap px-2 gap-2">
          <ProformaInvoiceTable
            className="lender-anchor-and-lender-mapped-table placeholder-wave0"
            tableHeader={[
              { name: "CIF", sortValue: "addedDate" },
              { name: "Customer Name", sortValue: "" },
              { name: "Product", sortValue: "proformaId" },
              { name: "Interest Rate(%)", sortValue: "sellerName" },
              { name: "Processing Fees", sortValue: "expectedDateOfDelivery" },
              { name: "Tenure (in days)", sortValue: "" },
              { name: "Action", sortValue: "" },
            ]}
            tableRows={proformaInvoiceTableRowData}
            materialRequestMetaData={proformInvoiceTableMetaData}
            tableQueryParams={tableQueryParams}
            setTableQueryParams={setTableQueryParams}
            isLoading={proFormaInvoiceTableResponse.isLoading}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        </div>
      </div>
    </>
  );
};

export default LenderTransactionMaintenance;
