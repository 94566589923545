import { HiOutlineArrowNarrowDown } from "react-icons/hi";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import { BiSort } from "react-icons/bi";
import { useEffect, useState } from "react";
import Loader from "../../../../layout/GenericLayout/Loader";
import TablePagination from "../../../../layout/GenericLayout/TablePagination";
import { toastMessage } from "../../../../layout/GenericLayout/ToastMessage";
import {
  getLenderList,
  updateUploadDocuments,
} from "../../../../store/slices/generic";
import { useDispatch, useSelector } from "react-redux";
import { BsFillArrowUpCircleFill } from "react-icons/bs";
import ReusableModal from "../../../../layout/GenericLayout/ReusableModal";
import { downloadBatchExcel, updateManualSendForDiscounting } from "../../../../store/slices/anchor";
import { Link } from "react-router-dom";

const SendForDiscountingModal = ({
  toggleModal,
  onRefreshTable,
  activeDiscountingData,
}) => {
  const dispatch = useDispatch();
  const selectLenderData = useSelector(
    (state) => state.generic.selectLenderData
  );

  const [selectLender, setSelectLender] = useState("");

  useEffect(() => {
    dispatch(getLenderList());
  }, []);

  const onClickSendForDiscounting = () => {
    const body = {
      emailId: selectLender,
      gst: 1,
      invoiceAmount: activeDiscountingData.invoiceAmount,
      invoiceId: activeDiscountingData.invoiceId,
      invoiceStatus: "Unregistered",
      manualInvoice: activeDiscountingData.manualInvoice,
    };
    console.log("body : ", body);
    dispatch(updateManualSendForDiscounting(body))
      .unwrap()
      .then((response) => {
        onRefreshTable();
        console.log("response : ", response);
        toastMessage(response.success, "success");
        toggleModal();
      })
      .catch((error) => {
        console.log("error : ", error);
        toastMessage(error.message, "error");
      });
  };

  const onChangeBankList = (event) => {
    console.log(event.target.value);
    setSelectLender(event.target.value);
  };

  console.log("sele :", activeDiscountingData);
  return (
    <div className="">
      <span className="fs-4 fw-semibold">Invoice Details</span>
      <div className="details-container p-4 mt-4 ">
        <div className="d-flex flex-column gap-4 gy-md-4 gy-2">
          <div className="kyc-fields-container d-flex align-items-center">
            <label
              htmlFor="invoiceNumber"
              className="kyc-label-text form-label w-25"
            >
              Invoice Number
            </label>
            <input
              disabled
              id="invoiceNumber"
              name="invoiceNumber"
              type="text"
              className="kyc-input-field form-control w-75"
              value={activeDiscountingData?.externalInvoiceId}
            />
          </div>

          <div className="kyc-fields-container d-flex align-items-center">
            <label
              htmlFor="invoiceNumber"
              className="kyc-label-text form-label w-25"
            >
              Invoice Amount (₹)
            </label>
            <input
              disabled
              id="invoiceNumber"
              name="invoiceNumber"
              type="text"
              className="kyc-input-field form-control w-75"
              value={activeDiscountingData?.invoiceAmount}
            />
          </div>

          <div className="kyc-fields-container d-flex align-items-center">
            <label
              htmlFor="selectAnchor"
              className="anchor-and-lender-select-label w-25"
            >
              Select Bank
            </label>
            <select
              value={selectLender}
              onChange={onChangeBankList}
              name="selectAnchor"
              className="form-select w-75"
              id="selectAnchor"
            >
              <option
                value=""
                disabled
                // defaultValue
              >
                Open this select menu
              </option>

              {selectLenderData.isLoading && (
                <option disabled>Loading...</option>
              )}

              {selectLenderData.data &&
                selectLenderData.data.map((eachLenderData) => (
                  <option
                    key={eachLenderData.customerId}
                    value={eachLenderData.emailId}
                  >
                    {eachLenderData.fullName}
                  </option>
                ))}
            </select>
          </div>
        </div>
      </div>

      <div className="d-flex align-items-center justify-content-end gap-3 mx-5 my-3">
        <button
          className="kyc-back-button bg-danger border-0 bg-opacity-100"
          onClick={toggleModal}
        >
          Cancel
        </button>
        <button
          className="kyc-back-button w-25 bg-success border-0"
          onClick={onClickSendForDiscounting}
        >
          Send for Discounting
        </button>
      </div>
    </div>
  );
};

const ManualInvoiceTable = (props) => {
  const dispatch = useDispatch();
  /* USESELECTOR */
  const uploadDocuments = useSelector((state) => state.generic.uploadDocuments);

  const [showSendForDiscountingModal, setShowSendForDiscountingModal] =
    useState(false);
  const [activeDiscountingData, setActiveDiscountingData] = useState([]);
  const [activeOthersUploadId, setActiveOthersUploadId] = useState(null);

  const toggleSendForDiscounting = () => {
    setShowSendForDiscountingModal((prev) => !prev);
  };

  const onClickSendForDiscountingData = (data) => {
    setActiveDiscountingData(data);
    toggleSendForDiscounting();
  };

  const updatePage = (currentPage) => {
    const newOffSet = props.tableQueryParams.tableLimit * (currentPage - 1);
    props.setTableQueryParams((prev) => ({
      ...prev,
      tableOffset: newOffSet,
      currentPage,
    }));
  };

  const onClickActiveSort = (activeSortValue) => {
    if (activeSortValue !== "") {
      let order = -1;
      if (activeSortValue === props.tableQueryParams.tableSort) {
        if (props.tableQueryParams.tableOrder === -1) {
          order = 1;
        }
      }
      props.setTableQueryParams((prev) => ({
        ...prev,
        tableSort: activeSortValue,
        tableOrder: order,
      }));
    }
  };

  const onClickViewMoreTableData = () => {
    let newLimit = props.tableQueryParams.tableLimit;
    if (newLimit === 5) {
      newLimit += 5;
    } else {
      newLimit -= 5;
    }
    props.setTableQueryParams((prev) => ({
      ...prev,
      tableLimit: newLimit,
    }));
  };

  const onChangeOthersDocument = (event, data) => {
    setActiveOthersUploadId(data.invoiceId);
    const body = new FormData();
    body.append("others", event.target.files[0]);
    body.append("invoiceId", data.invoiceId);
    body.append("manualInvoice", data.manualInvoice);
    body.append("sellerId", data.sellerId);

    dispatch(updateUploadDocuments(body))
      .unwrap()
      .then((response) => {
        props.onRefreshTable();
        toastMessage(response.success, "success");
        setActiveOthersUploadId(null);
      })
      .catch((error) => {
        console.log("error : ", error);
        toastMessage(error.message, "error");
        setActiveOthersUploadId(null);
      });
  };

  const handleDownloadBatchExcel = (invoiceNumber, sellerId) => {
    console.log("sellerId", sellerId);
    
    dispatch(downloadBatchExcel({invoiceNumber, sellerId}))
      .unwrap()
      .then(data => {
        const link = document.createElement("a");
        let name = data?.items?.[0]?.URL?.split("/");
        name = name[name.length - 1]?.split("-");
        name = name[name.length - 1];
        link.download = name;
        link.href = data?.items?.[0]?.URL;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(err => {
        toastMessage(err?.message, "error");
      });
  };

  return (
    <>
      {/* Modal Content */}
      <ReusableModal
        showModal={showSendForDiscountingModal}
        toggleModal={toggleSendForDiscounting}
        modalContent={
          <SendForDiscountingModal
            activeDiscountingData={activeDiscountingData}
            toggleModal={toggleSendForDiscounting}
            onRefreshTable={props.onRefreshTable}
          />
        }
        modalSizeClass="modal-lg"
      />
      <div className="table-container">
        <table className={props.className}>
          <thead>
            <tr>
              {props.tableHeader.map((eachHeader) => (
                <th
                  key={eachHeader.name}
                  scope="col"
                  role={
                    eachHeader.sortValue === ""
                      ? eachHeader.sortValue
                      : "button"
                  }
                  className={`${
                    props.tableQueryParams.tableSort === eachHeader.sortValue &&
                    eachHeader.sortValue !== "" &&
                    "active-sort-text"
                  }`}
                  onClick={() => onClickActiveSort(eachHeader.sortValue)}
                >
                  {eachHeader.name}
                  {eachHeader.sortValue !== "" && <BiSort />}
                </th>
              ))}
            </tr>
          </thead>
          {props.invoiceMetaData?.paging?.total<0?(<div className="no-data text-danger">Oops! there is no data to show. Please check back later</div>):null}
          {props.isLoading ? (
            <tbody>
              <tr>
                <td colSpan={7}>
                  <Loader className="my-5" />
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              {props.tableRows.map((eachRows, index) => (
                <tr key={eachRows.invoiceId} scope="col">
                  {sessionStorage.getItem("productCategory") !==
                    "Purchase Invoice Discounting" && (
                    <td>{eachRows?.buyerName ? eachRows?.buyerName : "-"}</td>
                  )}

                  <td>{eachRows.invoiceDate}</td>

                  <td>
                    {" "}
                    <Link
                      className="text-decoration-none"
                      to={eachRows.invoiceFileUrl}
                      target="_blank"
                    >
                      {eachRows.externalInvoiceId}{" "}
                    </Link>
                  </td>
                  {/* <td>{eachRows}</td> */}

                  {/* <td>{eachRows.poId}</td> */}
                  <td>{eachRows.invoiceAmount}</td>
                  <td>{eachRows.dueDate}</td>
                  <td className="row-hight-light-txt">
                      {
                        eachRows?.bulkInvoice ?
                          <button className="btn btn-outline-dark" onClick={() => handleDownloadBatchExcel(eachRows?.invoiceId, eachRows?.sellerId)}>
                            VIEW
                          </button>
                          : "N/A"
                      }
                    </td>
                  <td className="upload-button-container">
                    {uploadDocuments.isLoading &&
                    eachRows.invoiceId === activeOthersUploadId ? (
                      <Loader />
                    ) : (
                      <>
                        <label
                          htmlFor={`uploadManualOthersFile_${index}`}
                          role="button"
                          className="d-flex justify-content-between mx-auto align-items-center gap-2"
                        >
                          Upload
                          <span className="d-flex align-items-center">
                            <BsFillArrowUpCircleFill />
                          </span>
                        </label>
                        <input
                          onChange={(event) =>
                            onChangeOthersDocument(event, eachRows)
                          }
                          id={`uploadManualOthersFile_${index}`}
                          type="file"
                          className="d-none"
                          accept=".zip"
                        />
                      </>
                    )}
                  </td>

                  <td>
                    {eachRows?.status === "Accepted" ? (
                      eachRows?.invoiceStatus === "Unregistered" ? (
                        <button
                          // onClick={() => onClickSendForDiscountingData(eachRows)}
                          className=" 
                      py-1 
                      btn
                      btn-secondary
                      px-2 mx-auto 
                      rounded w-100 
                      my-cursor-disabled 
                      fw-semibold
                      "
                        >
                          Sent For Discounted
                        </button>
                      ) : (
                        <button
                          onClick={() =>
                            onClickSendForDiscountingData(eachRows)
                          }
                          role="button"
                          className="list-group-item list-group-item-action list-group-item-primary text-primary py-1 px-2 mx-auto rounded w-100 fw-semibold"
                        >
                          Send For Discounting
                        </button>
                      )
                    ) : (
                      <button type="button" onClick={ () => props?.handleEditManualInvoice(eachRows?.invoiceId, eachRows?.externalInvoiceId) } className="btn btn-outline-dark">Edit</button>
                    )}
                  </td>
                  {sessionStorage.getItem("productCategory") !==
                    "Purchase Invoice Discounting" &&
                    (eachRows?.status === "Accepted" ? (
                      eachRows?.invoiceStatus === "Unregistered" ? (
                        <td className="text-success">
                          {"Sent for Discounting"}
                        </td>
                      ) : (
                        <td className="text-success">{eachRows?.status}</td>
                      )
                    ) : eachRows?.status === "Rejected" ? (
                      <td className="text-danger">{eachRows?.status}</td>
                    ) : (
                      <td className="text-primary">
                        {"Awaiting approval by counter party"}
                      </td>
                    ))}
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
      
      {!props.isLoading && props.invoiceMetaData?.paging?.total>5 && (
          <div className="d-flex justify-content-end px-2">
            <span
              onClick={onClickViewMoreTableData}
              className="d-flex align-items-center gap-1 my-3 view-more-text"
            >
              {props.tableQueryParams.tableLimit === 5
              ? props.tableQueryParams.tableOffset+props.tableQueryParams.tableLimit<props.invoiceMetaData?.paging?.total?"View More ":""
              : props.tableQueryParams.tableOffset+props.tableQueryParams.tableLimit<props.invoiceMetaData?.paging?.total?"View Less ":""}
              <HiOutlineArrowNarrowDown
                className={`fs-5 ${
                  props.tableQueryParams.tableLimit === 5
                  ? props.tableQueryParams.tableOffset+props.tableQueryParams.tableLimit<props.invoiceMetaData?.paging?.total? "down-arrow":"d-none"
                  : props.tableQueryParams.tableOffset+props.tableQueryParams.tableLimit<props.invoiceMetaData?.paging?.total? "up-arrow":"d-none"}
                 }`}
              />
            </span>
          </div>
        )}
     
      <div className="d-flex align-items-center gap-2 page-data-text-container mt-3 px-3 w-100">
        <TablePagination
          pageSize={props.tableQueryParams.tableLimit}
          onUpdatePage={updatePage}
          current={props.tableQueryParams.currentPage}
          total={props.invoiceMetaData?.paging?.total}
        />
      </div>
    </>
  );
};

export default ManualInvoiceTable;
