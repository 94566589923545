import { HiOutlineArrowNarrowDown } from "react-icons/hi";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useEffect, useState } from "react";
// import Modal from "../LenderPopUp/ModalPopUp"
import "../../../style/components/lender.scss";
import compass from "../../../assests/icons/tachometer-fast-alt.svg";
import file from "../../../assests/icons/Icon ionic-md-card.svg";

const LenderInvoiceTable = (props) => {
  const navigate = useNavigate();
  const [popUp, setPopUp] = useState(false);
  // console.log(props.totalValue)
  const [totalvalue, settotal] = useState(props.totalValue);
  // console.log(props.items)
  const totalPage = 5;
  const dateOptions = {
    day: "numeric",
    year: "numeric",
    month: "short",
  };

  const showModal = () => {
    setPopUp(false);
  };

  const timeOptions = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  const handlePopUp = () => {
    setPopUp(true);
  };

  // console.log(props.items)

  return (
    <>
      <div className="table-container">
        <table className={props.className}>
          <thead>
            <tr>
              {props.tableHeader.map((eachHeader) => (
                <th key={eachHeader} scope="col">
                  {eachHeader}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {props.items != undefined &&
              props.items.map((item) => (
                <tr scope="col">
                  <td>{item.item || "N/A"}</td>
                  <td>{item.modelNumber}</td>
                  <td>{item.quantityOrdered}</td>
                  <td>{item.quantityAvailable}</td>
                  <td>{item.unitPrice}</td>
                  <td>{item.gst}</td>
                  <td>{item.totalAmount}</td>
                </tr>
              ))}
            <tr scope="col">
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>Total Amount</td>
              <td>{totalvalue}</td>
            </tr>
          </tbody>
        </table>
        <div className="d-flex justify-content-end px-2"></div>
      </div>
    </>
  );
};

export default LenderInvoiceTable;
