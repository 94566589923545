import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import api from "../../utilities/api";

const initialState = {
  creditLimitDetailsData: {
    data: null,
    isLoading: false,
    error: null,
  },
  updateMaxCreditLimit: {
    data: null,
    isLoading: false,
    error: null,
  },
  resetPassword:{
    data:null,
    isLoading:false,
    error:null,
  },
}

export const postResetPassword = createAsyncThunk(
  "post/restPassword",
  async (body) => {
    const user = JSON.parse(sessionStorage.getItem('user'))?.lenderId
    try {
      const url = `user/reset__password`;
      const response = await api.post(url,body, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
      throw new Error(error.response.data.error);
    }
  }
);
export const getCreditLimitDetails = createAsyncThunk(
  "get/creditLimit",
  async () => {
    const user = JSON.parse(sessionStorage.getItem('user'))?.lenderId
    console.log(user)
    try {
      const url = `/user/getuser/${user}`;
      const response = await api.get(url, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const underwriterUpdateMaxCreditLimit = createAsyncThunk(
  "put/underwriterUpdateMaxCreditLimit",
  async (obj) => {
    try {
      const url = `/underwriter/setmaxlimit`;

      const { data, } = await api.put(url, obj, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token"), },
      });

      return data;
    }
    catch (error) {
      throw new Error(error?.response?.data?.message);
    }
  }
);

const underwriterSlice = createSlice({

  name: "underwriter",
  initialState,
  reducers: {
    resetState: (state) => initialState,
  },
  extraReducers(builder) {
    builder

      .addCase(getCreditLimitDetails.pending, (state, action) => {
        state.creditLimitDetailsData.isLoading = true;
      })
      .addCase(getCreditLimitDetails.fulfilled, (state, action) => {
        state.creditLimitDetailsData.data = action.payload;
        state.creditLimitDetailsData.isLoading = false;
      })
      .addCase(getCreditLimitDetails.rejected, (state, action) => {
        state.creditLimitDetailsData.error = action.error.message;
        state.creditLimitDetailsData.isLoading = false;
      })
      
      .addCase(underwriterUpdateMaxCreditLimit.pending, (state, action) => {
        state.creditLimitDetailsData.isLoading = true;
      })
      .addCase(underwriterUpdateMaxCreditLimit.fulfilled, (state, action) => {
        state.creditLimitDetailsData.data = action.payload;
        state.creditLimitDetailsData.isLoading = false;
      })
      .addCase(underwriterUpdateMaxCreditLimit.rejected, (state, action) => {
        state.creditLimitDetailsData.error = action.error.message;
        state.creditLimitDetailsData.isLoading = false;
      })
      .addCase(postResetPassword.pending, (state, action) => {
        state.resetPassword.isLoading = true;
      })
      .addCase(postResetPassword.fulfilled, (state, action) => {
        state.resetPassword.data = action.payload;
        state.resetPassword.isLoading = false;
      })
      .addCase(postResetPassword.rejected, (state, action) => {
        state.resetPassword.error = action.error.message;
        state.resetPassword.isLoading = false;
      })
  }
})


export const { resetState } = underwriterSlice.actions;
export default underwriterSlice.reducer;