const BusinessInfo = ({ scoreCardDetails }) => {
  return (
    <div className="my-3">
      <div>
        <span className="fw-semibold text-secondary">Business Name : </span>
        <span className="fw-semibold text-success">
          {scoreCardDetails?.success?.companyFullName || (
            <span className="text-danger mx-3">N/A</span>
          )}
        </span>
      </div>

      <div>
        <span className="fw-semibold text-secondary">Business Type : </span>
        <span className="fw-semibold text-success">
          {scoreCardDetails?.success?.companyType || (
            <span className="text-danger mx-3">N/A</span>
          )}
        </span>
      </div>

      <div>
        <span className="fw-semibold text-secondary">Address : </span>
        <span className="fw-semibold text-success">
          {scoreCardDetails?.success?.companyFullAddress || (
            <span className="text-danger mx-3">N/A</span>
          )}
        </span>
      </div>
    </div>
  );
};

export default BusinessInfo;
