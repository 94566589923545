import { useContext, Fragment, useState, useEffect } from "react";

import { CamFormBlock } from "../../../../GenericComponent/CamForm/CamFormBlocks";
import { AdminCamTransactionDetails } from "./AdminCamTransactionDetails";
import { AdminCamAdditionalDetails } from "./AdminCamAdditionalDetails";
import { AdminCamSecurity } from "./AdmincamSecurity";
import { AdminCamGroupExposure } from "./AdminCamGroupExposure";
import { AdminCamApplicantDetails } from "./AdminCamApplicantDetails";
import { AdminCamDirAndPartners } from "./AdminCamDirAndPartners";
import { AdminCamCompanyDetails } from "./AdminCamCompanyDetails";
import { AdminCamStockWithDealership } from "./AdminCamStockWithDealership";
import { AdminDebtorsAgeing } from "./AdminCamDebtorsAgeing";
import { AdminCamTotalCapital } from "./AdminCamTotalCapital";
import { AdminCamBureauScore } from "./AdminCamBureauScore";
import { AdminCamDeviationAndApproval } from "./AdminCamDeviationAndApproval";
import { AdminCamContext } from "../../../../context/admin/cam/adminCamContext";
import { AdminCamMCA } from "./AdminCamMCA";
import { useSelector } from "react-redux";

const AdminUserTableCAMComponent = (props) => {
	const [camData, setCamData] = useState({
		dealer_name:'',
		customer_relation_no:'',
		product:'',
		location:'',
		city:'',
		date:'',
		customer_type:'',
		loan_request_amt:'',
		region:'',
		state:'',
		country:'',
		pincode:''
	});
	const [reduxData, setReduxData] = useState({});
	const { handleToggleEdit, editEnabled, additionalDetailsState,transactionDetailsState,securityState,groupExposureState,
		applicantDetailsState,dirAndPartnersState,companyPromotersState,dealershipStockState, debtorsState,totalWorkingCapitalState } = useContext(AdminCamContext);

	console.log("jknsdjkkjdkdajkndajknf", totalWorkingCapitalState);

	const [ $newLoanRequest, setNewLoanRequest ] = useState();
	const [ $newProduct, setNewProduct ] = useState();

	const dealerReduxState = useSelector(state=>state?.admin?.camByEmailIdData?.data?.data?.customerDetails);
	
	useEffect(()=>{
		if(reduxData != dealerReduxState){
			setCamData({...camData, dealer_name: dealerReduxState?.dealerName,
			loan_request_amt:dealerReduxState?.loanRequestAmount, location:dealerReduxState?.location,
			city:dealerReduxState?.city, country:dealerReduxState?.country, date: dealerReduxState?.date,
			state:dealerReduxState?.state, region: dealerReduxState?.region, customer_type: dealerReduxState?.customerType,
			customer_relation_no: dealerReduxState?.customerRelationshipNo, product: dealerReduxState?.product,
			pincode:dealerReduxState?.pincode});

			setNewLoanRequest(dealerReduxState?.loanRequestAmount);
			setNewProduct(dealerReduxState?.product)
		}
	},[dealerReduxState]);
	//
	return (
		<div className="d-flex flex-column gap-3">
			<div className="container">
				<div className="row">
					<div className="col-4 d-flex align-items-center gap-3">
						{
							editEnabled ?
								<Fragment>
									<button className="btn btn-success d-flex align-items-center gap-2">Save</button>
									<button onClick={ handleToggleEdit } className="btn btn-danger d-flex align-items-center gap-2">
										Cancel
									</button>
								</Fragment> :

								<Fragment>
									<button className="modal-export-pdf-button d-flex align-items-center gap-2">
										Export to PDF
										<svg
											xmlns="http://www.w3.org/2000/svg"
											// width="21.663"
											// height="25.799"
											// viewBox="0 0 21.663 25.799"
											width="20"
											height="20"
											viewBox="0 0 20 25"
										>
											<path
												id="Path_541"
												data-name="Path 541"
												d="M207.639,120.656l3.949-4.032a1.18,1.18,0,0,0-1.667-1.671l-1.352,1.361c-.308.31-.557.208-.557-.227V102.8a1.178,1.178,0,1,0-2.356,0v13.291c0,.434-.249.537-.557.227l-1.352-1.361a1.18,1.18,0,0,0-1.667,1.671l3.908,4.026a1.145,1.145,0,0,0,1.651.005Zm-8.464-6.96a1.379,1.379,0,0,0,1.95-1.95,8.074,8.074,0,1,1,11.419,0,1.379,1.379,0,0,0,1.95,1.95,10.832,10.832,0,1,0-15.318,0Z"
												transform="translate(-196.003 -95.205)"
												fill="#fff"
												fillRule="evenodd"
											/>
										</svg>
									</button>
									{/* {
										props?.showEdit !== false ?
										<button onClick={ handleToggleEdit } className="modal-export-pdf-button d-flex align-items-center gap-2">
											Edit
										</button>
										: null
									} */}
								</Fragment>
						}
					</div>

					<div className="col-4 text-center">
						<span className="fs-4 fw-bold modal-title-text">CAM</span>
					</div>
					<div className="col-4"></div>
				</div>
			</div>

			<div className="my-2 mx-4">
				<div className="admin-cam-modal-container container p-4">
					<div className="row mb-2">
						<div className="d-flex flex-column gap-2 col-12 col-md-6 col-lg-3">
							<span className="admin-user-cam-title">Name of Dealer</span>
							<span className="admin-user-cam-title-data">{camData.dealer_name}</span>
						</div>
						<div className="d-flex flex-column gap-2 col-12 col-md-6 col-lg-3">
							<span className="admin-user-cam-title">
								Customer Relationship No.
							</span>
							<span className="admin-user-cam-title-data">{camData.customer_relation_no}</span>
						</div>
						<div className="d-flex flex-column gap-2 col-12 col-md-6 col-lg-3">
							<span className="admin-user-cam-title">Product</span>
							{
								editEnabled ?
									<input className="form-control" type="text" value={ $newProduct } onChange={ e => setNewProduct(e.target.value) } />
								:
								<span className="admin-user-cam-title-data">{camData.product}</span>
							}
						</div>
						<div className="d-flex flex-column gap-2 col-12 col-md-6 col-lg-3">
							<span className="admin-user-cam-title">Location</span>
							<span className="admin-user-cam-title-data">{camData.location}</span>
						</div>
					</div>
					<div className="row mb-2">
						<div className="d-flex flex-column gap-2 col-12 col-md-6 col-lg-3">
							<span className="admin-user-cam-title">City</span>
							<span className="admin-user-cam-title-data">{camData.city}</span>
						</div>
						<div className="d-flex flex-column gap-2 col-12 col-md-6 col-lg-3">
							<span className="admin-user-cam-title">Date</span>
							<span className="admin-user-cam-title-data">{camData.date}</span>
						</div>
						<div className="d-flex flex-column gap-2 col-12 col-md-6 col-lg-3">
							<span className="admin-user-cam-title">Customer Type</span>
							<span className="admin-user-cam-title-data">{camData.customer_type}</span>
						</div>
						<div className="d-flex flex-column gap-2 col-12 col-md-6 col-lg-3">
							<span className="admin-user-cam-title">Loan Request Amount</span>
							{
								editEnabled ? 
									<input className="form-control" type="number" value={ $newLoanRequest } min={0} onChange={ e => setNewLoanRequest(e.target.value) } />
								:
								<span className="admin-user-cam-title-data">{camData.loan_request_amt}</span>
							}
						</div>
					</div>
					<div className="row">
						<div className="d-flex flex-column gap-2 col-12 col-md-6 col-lg-3">
							<span className="admin-user-cam-title">Region</span>
							<span className="admin-user-cam-title-data">{camData.region}</span>
						</div>
						<div className="d-flex flex-column gap-2 col-12 col-md-6 col-lg-3">
							<span className="admin-user-cam-title">State</span>
							<span className="admin-user-cam-title-data">{camData.state}</span>
						</div>
						<div className="d-flex flex-column gap-2 col-12 col-md-6 col-lg-3">
							<span className="admin-user-cam-title">Country</span>
							<span className="admin-user-cam-title-data">{camData.country}</span>
						</div>
						<div className="d-flex flex-column gap-2 col-12 col-md-6 col-lg-3">
							<span className="admin-user-cam-title">Pincode</span>
							<span className="admin-user-cam-title-data">{camData.pincode}</span>
						</div>
					</div>
				</div>


				{/* ADDITIONAL INFO */}

				<CamFormBlock blockHeader="Additional Info">
					<AdminCamAdditionalDetails />
				</CamFormBlock>

				{/* ADDITIONAL INFO END */}



				{/* TRANSACTION DETAILS */}

				<CamFormBlock blockHeader="Transaction Details">
					<AdminCamTransactionDetails />
				</CamFormBlock>

				{/* TRANSACTION DETAILS END */}



				{/* SECURITY */}

				<CamFormBlock blockHeader="Security">
					<AdminCamSecurity />
				</CamFormBlock>

				{/* SECURITY END */}



				{/* GROUP EXPOSURE */}

				<CamFormBlock blockHeader="Group Exposure">
					<AdminCamGroupExposure />
				</CamFormBlock>

				{/* GROUP EXPOSURE END */}



				{/* APPLICANT DETAILS */}

				<CamFormBlock blockHeader="Applicant Details">
					<AdminCamApplicantDetails />
				</CamFormBlock>

				{/* APPLICANT DETAILS END */}



				{/* DETAILS OF DIRECTORS/PARTNERS */}

				<CamFormBlock blockHeader="Details Of Directors/Partners">
					<AdminCamDirAndPartners />
				</CamFormBlock>

				{/* DETAILS OF DIRECTORS/PARTNERS END */}



				{/* DETAILS ABOUT OF THE COMPANY/PROMOTER */}

				<CamFormBlock blockHeader="Details About Of The Company/Promoter">
					<AdminCamCompanyDetails />
				</CamFormBlock>

				{/* DETAILS ABOUT OF THE COMPANY/PROMOTER END */}



				{/* STOCK WITH THE DEALERSHIP */}

				<CamFormBlock blockHeader="Stock With The Dealership">
					<AdminCamStockWithDealership />
				</CamFormBlock>

				{/* STOCK WITH THE DEALERSHIP END */}



				{/* DEBTORS AGEING */}

				<CamFormBlock blockHeader="Debtors Ageing">
					<AdminDebtorsAgeing />
				</CamFormBlock>

				{/* DEBTORS AGEING END */}



				{/* TOTAL WORKING CAPITAL/CHANNEL FINANCING UNIT LIMITS DETAILS */}

				<CamFormBlock blockHeader="Total Working Capital/Channel Financing Unit Limits Details">
					<AdminCamTotalCapital />
				</CamFormBlock>

				{/* TOTAL WORKING CAPITAL/CHANNEL FINANCING UNIT LIMITS DETAILS END */}





				{/* GST ANALYSIS REPORT */}

				<CamFormBlock blockHeader="GST Analysis Report">
					<h4 className="text-danger text-center">GST Report Not Yet available</h4>
				</CamFormBlock>

				{/* GST ANALYSIS REPORT END */}



				{/* BANK ANALYSIS REPORT */}

				<CamFormBlock blockHeader="Bank Analysis Report">
					<h4 className="text-danger text-center">BSA Report not yet available</h4>
				</CamFormBlock>

				{/* BANK ANALYSIS REPORT END */}



				{/* FINANCIAL STATEMENT REPORT */}

				<CamFormBlock blockHeader="Financial Statement Report">
					<AdminCamMCA></AdminCamMCA>
					{/* <h4 className="text-danger text-center">MCA / ITR Data Not Found</h4> */}
				</CamFormBlock>

				{/* FINANCIAL STATEMENT REPORT END */}

			</div>
		</div>
	);
};
export default AdminUserTableCAMComponent;
