import React from "react";
import { BsSearch } from "react-icons/bs";
import AdminCard from "../Admin/AdminCard";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminDashboardTable from "../Lender/LenderTables/LenderInvoiceTable";
import { getataInvoiceD } from "../../store/slices/lender.js";
import Loader from "../../layout/GenericLayout/Loader";
import noFound from "../../assests/images/IoMdSad";
import { useLocation, useNavigate } from "react-router-dom";

const Invoicedetail = (props) => {
  console.log(props);
  const location = useLocation();
  console.log("location : ", location);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [repeat, setreapeat] = useState(true);
  const [billing, setbilling] = useState();
  const [shipping, setshipping] = useState();
  const [screenData, setScreendata] = useState(false);
  const customerInvoiceData = useSelector(
    (state) => state.lender.invoicedata?.data?.items[0]
  );

  const [activeSellerId, setActiveSellerId] = useState(null);

  // const customerInvoiceData = invoiceData?.data?.items[0];

  console.log(customerInvoiceData);
  useEffect(() => {
    if (repeat == true) {
      dispatch(getataInvoiceD({ sellerId: location.state.data }));
      setreapeat(false);
    }
  }, [repeat, dispatch]);

  const handleChangesBilling = (e) => {
    setbilling(e.target.value);
  };

  const handleChangesShipping = (e) => {
    setshipping(e.target.value);
  };

  if (customerInvoiceData !== undefined) {
    return (
      <div className="d-flex flex-column gap-4 bg-white p-3 rounded">
        <div className="d-flex justify-content-between flex-column px-2 gap-2">
          <span className="dashboard-content-title">Invoice Details</span>
          <div className="d-flex flex-column gap-4 shadow rounded">
            <div
              className=""
              style={{
                backgroundColor: "#aeb7e5",
                borderRadius: "7px",
                padding: "7px",
                height: "40px",
              }}
            >
              <p style={{ color: "white" }}>Items Information</p>
            </div>

            <div className="d-flex flex-column" style={{ padding: "2rem" }}>
              <div>
                <AdminDashboardTable
                  className="admin-dashboard-table"
                  // tableRowsCount={tableRowsCount}
                  tableHeader={[
                    "Item",
                    "Model Number",
                    "Quantity Ordered",
                    "Available Quantity",
                    "Price/Unit",
                    "GST",
                    "Total Amount",
                  ]}
                  items={customerInvoiceData?.itemDetails}
                  totalValue={customerInvoiceData?.itemDetails[0]?.totalAmount}
                />
              </div>
            </div>
          </div>

          <div
            className="d-flex flex-column mt-4"
            style={{
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              borderRadius: "7px",
            }}
          >
            <div
              className=""
              style={{
                backgroundColor: "#aeb7e5",
                borderRadius: "7px",
                padding: "7px",
                height: "40px",
              }}
            >
              <span className="kyc-content-title my-3 fw-semibold text-white">
                Invoice Address
              </span>
            </div>

            <div className="details-container container p-3">
              <div className="row gy-md-4 gy-2">
                <div className="kyc-fields-container col-12  col-md-6">
                  <label
                    htmlFor="billing"
                    className="kyc-label-text form-label fw-semibold"
                  >
                    Billing Address
                  </label>
                  <input
                    disabled
                    id="billing"
                    type="text"
                    onChange={handleChangesBilling}
                    name="billing"
                    value={customerInvoiceData?.invoiceToAddress}
                    className="kyc-input-field form-control"
                  />
                </div>

                <div className="kyc-fields-container col-12  col-md-6">
                  <label
                    htmlFor="shipping"
                    className="kyc-label-text form-label fw-semibold"
                  >
                    Shipping Address
                  </label>
                  <input
                    id="shipping"
                    disabled
                    type="text"
                    onChange={handleChangesShipping}
                    name="shipping"
                    value={customerInvoiceData?.shippingToAddress}
                    className="kyc-input-field form-control"
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            className="d-flex flex-column mt-4"
            style={{
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              borderRadius: "7px",
            }}
          >
            <div
              className=""
              style={{
                backgroundColor: "#aeb7e5",
                borderRadius: "7px",
                padding: "7px",
                height: "40px",
              }}
            >
              <span className="kyc-content-title my-3 fw-semibold text-white">
                Transport Details
              </span>
            </div>

            <div className="details-container container p-3">
              <div className="row gy-md-4 gy-2">
                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="billing"
                    className="kyc-label-text form-label fw-semibold"
                  >
                    Shipment Terms
                  </label>
                  <input
                    disabled
                    id="billing"
                    type="text"
                    onChange={handleChangesBilling}
                    name="billing"
                    value={customerInvoiceData?.shippingTerms}
                    className="kyc-input-field form-control"
                  />
                </div>

                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="shipping"
                    className="kyc-label-text form-label fw-semibold"
                  >
                    Mode of Transport
                  </label>
                  <input
                    id="shipping"
                    disabled
                    type="text"
                    onChange={handleChangesShipping}
                    name="shipping"
                    value={customerInvoiceData?.modeOfTransport}
                    className="kyc-input-field form-control"
                  />
                </div>
                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="shipping"
                    className="kyc-label-text form-label fw-semibold"
                  >
                    Expected Date of Delivery
                  </label>
                  <input
                    id="shipping"
                    disabled
                    type="text"
                    onChange={handleChangesShipping}
                    name="shipping"
                    value={customerInvoiceData?.expectedDateOfDelivery}
                    className="kyc-input-field form-control"
                  />
                </div>
              </div>
              <div className="row gy-md-4 mt-2 gy-2">
                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="billing"
                    className="kyc-label-text form-label fw-semibold"
                  >
                    Delivery Location
                  </label>
                  <input
                    disabled
                    id="billing"
                    type="text"
                    onChange={handleChangesBilling}
                    name="billing"
                    value={customerInvoiceData?.locationOfDelivery}
                    className="kyc-input-field form-control"
                  />
                </div>

                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="shipping"
                    className="kyc-label-text form-label fw-semibold"
                  >
                    Payment Terms
                  </label>
                  <input
                    id="shipping"
                    disabled
                    type="text"
                    onChange={handleChangesShipping}
                    name="shipping"
                    value={customerInvoiceData?.paymentTerms}
                    className="kyc-input-field form-control"
                  />
                </div>
                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="shipping"
                    className="kyc-label-text form-label fw-semibold"
                  >
                    Additional Text
                  </label>
                  <input
                    id="shipping"
                    disabled
                    type="text"
                    onChange={handleChangesShipping}
                    name="shipping"
                    value={customerInvoiceData?.additionalText}
                    className="kyc-input-field form-control"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-end">
          <button
            onClick={() => navigate(-1)}
            className="btn btn-outline-dark px-5"
          >
            Back
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <div className="d-flex flex-column gap-4 bg-white p-3 rounded">
        <center>
          <label
            className="fw-bold"
            style={{ marginTop: "25%", fontSize: "30px", color: "#2e3c83" }}
          >
            {" "}
            No Data Found{" "}
          </label>
        </center>
        <center>
          {" "}
          <img
            src={noFound}
            alt=""
            style={{ width: "100px", marginBottom: "25%" }}
          />
        </center>
      </div>
    );
  }
};

export default Invoicedetail;
