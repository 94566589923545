import { BsDownload } from "react-icons/bs";

const GSTInfo = ({ scoreCardDetails }) => {
  return (
    <div className="my-3">
      <div>
        <span className="fw-semibold text-secondary">Address : </span>
        <span className="fw-semibold text-success">
          {scoreCardDetails[0]?.address || (
            <span className="text-danger mx-3">N/A</span>
          )}
        </span>
      </div>

      <div>
        <span className="fw-semibold text-secondary">Business Name : </span>
        <span className="fw-semibold text-success">
          {scoreCardDetails[0]?.business_name || (
            <span className="text-danger mx-3">N/A</span>
          )}
        </span>
      </div>

      <div>
        <span className="fw-semibold text-secondary">Email ID : </span>
        <span className="fw-semibold text-success">
          {scoreCardDetails[0]?.email || (
            <span className="text-danger mx-3">N/A</span>
          )}
        </span>
      </div>

      <div>
        <span className="fw-semibold text-secondary">GSTIN No. : </span>
        <span className="fw-semibold text-success">
          {scoreCardDetails[0]?.gstin_no || (
            <span className="text-danger mx-3">N/A</span>
          )}
        </span>
      </div>

      <div>
        <span className="fw-semibold text-secondary">Mobile No. : </span>
        <span className="fw-semibold text-success">
          {scoreCardDetails[0]?.mobile_no || (
            <span className="text-danger mx-3">N/A</span>
          )}
        </span>
      </div>

      <div>
        <span className="fw-semibold text-secondary">Registration Date : </span>
        <span className="fw-semibold text-success">
          {scoreCardDetails[0]?.registration_date || (
            <span className="text-danger mx-3">N/A</span>
          )}
        </span>
      </div>

      <div>
        <span className="fw-semibold text-secondary">Trade Name : </span>
        <span className="fw-semibold text-success">
          {scoreCardDetails[0]?.trade_name || (
            <span className="text-danger mx-3">N/A</span>
          )}
        </span>
      </div>

      <div>
        <span className="fw-semibold text-secondary">Status : </span>
        <span className="fw-semibold text-success">
          {scoreCardDetails[0]?.status || (
            <span className="text-danger mx-3">N/A</span>
          )}
        </span>
      </div>

      <div>
        <span className="fw-semibold text-secondary">GST Report : </span>

        {scoreCardDetails[0]?.gst_report ? (
          <a
            className="text-decoration-none btn btn btn-outline-success py-0"
            target="_blank"
            href={scoreCardDetails[0]?.gst_report}
            download
          >
            <span className="d-flex align-items-center gap-3">
              Download <BsDownload />
            </span>
          </a>
        ) : (
          <span className="fw-semibold text-danger">N/A</span>
        )}
      </div>
    </div>
  );
};

export default GSTInfo;
