import { Fragment, useId } from "react";

/**
 * @param {{ 
 * 	label: string,
 * 	onChangeHandler: (event: React.ChangeEvent<HTMLSelectElement>) => void,
 * 	onBlurHandler: (event: React.FocusEvent<HTMLSelectElement>) => void,
 * 	className?: string,
 * 	style?: React.CSSProperties,
 * 	name?: string,
 * 	isInvalid: boolean
 * 	value: string,
 * 	errorMessage?: string,
 * 	defaultValue?: string,
 * 	optionList: { label: string, value: string, id?: string, disabled?: boolean }[]
 * 	required?: boolean,
 * 	disabled?: boolean
 * }} props 
 */
export const GenericSelect = (props) => {

	const uniqueId = useId();

	return (
		<Fragment>
			<div className="kyc-fields-container col-12  col-md-4">
				<label htmlFor={ uniqueId } className="kyc-label-text form-label">
					{ props?.label ?? "" }
					{
						props?.required &&
							<span className="text-danger fs-5">*</span>
					}
				</label>

				<select
					onChange={ props?.onChangeHandler ?? null }
					id={ uniqueId }
					name={ props?.name ?? "" }
					className={`kyc-input-field form-select  ${ props?.isInvalid && "invalid-input" } ${ props?.className ?? "" }`}
					onBlur={ props?.onBlurHandler ?? null }
					value={ props?.value ?? "" }
					defaultValue={ props?.defaultValue ?? "" }
					disabled={ props?.disabled ?? false }
				>
					{
						props?.optionList?.map((item, index) => (
							<option key={ item?.id ?? index } disabled={ item?.disabled ?? false } value={ item.value }>{ item.label }</option>
						))
					}
				</select>

				<span className="text-danger px-1">
					{ (props?.isInvalid && props?.errorMessage) ?? null }
				</span>
			</div>
		</Fragment>
	);
};