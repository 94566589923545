import { Fragment, useState } from "react";

/**
 * @description
 * 	Common component for blocks in cam form
 * @param {{ blockHeader: string, children: React.ReactNode }} props necessary properties
 * 
 * ## Usage
 * ```jsx
 * import { Fragment } from "react";
 * import CamFormBlock from "path/to/CamFormBlock";
 * 
 * export const SomeComponent = () => {
 * 	return (
 * 		<CamFormBlock blockHeader="Block Heading">
 * 			... The main form content goes here
 * 			...
 * 		</CamFormBlock>
 * 	);
 * }
 * ```
 */
export const CamFormBlock = (props) => {

	const [ showContent, setShowContent ] = useState(true);

	// =====================================
	// Check if certain properties are missing
	// And if missing, return null
	// =====================================
	if (props.blockHeader === undefined || props.blockHeader === "") {
		return null;
	}

	const toggleShowContent = () => {
		setShowContent(previousState => !previousState);
	};

	return (
		<Fragment>
			<div className="my-4">
				<div className="d-flex gap-1 align-items-center">
					<div className="d-flex align-items-center flex-grow-1 mx-4">
						<hr className="admin-user-modal-title-line" />
					</div>
					<span className="fs-5 fw-semibold modal-title-text">
						{ props.blockHeader }
					</span>
					<div className="d-flex align-items-center flex-grow-1 mx-4">
						<hr className="admin-user-modal-title-line" />
					</div>
					<span onClick={ toggleShowContent } className="d-flex" style={{ cursor: "pointer", color: 'blue', fontFamily: "monospace" }}>
						[{ showContent ? "Hide" : "Show" }]
					</span>
				</div>
				{
					showContent ?
						<div className="container-fluid px-3 py-2">
							{ props.children ?? null }
						</div>
					: null
				}
			</div>
		</Fragment>
	);
};