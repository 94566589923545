import React, { Fragment, useEffect, useState } from "react";
import { BsPlusCircleFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toastMessage } from "../../../layout/GenericLayout/ToastMessage";
import {
  getAddMaterialItems,
  getMaterialRequestDeliveryLocation,
  getMaterialRequestFormSubmit,
} from "../../../store/slices/buyer";

const BuyerCreateMaterialRequest = () => {
  const [itemsInformation, setItemsInformation] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getCitiesResponse = useSelector(
    (state) => state.buyer.materialRequestGetLocations
  );

  const materialRequestFormSubmit = useSelector(
    (state) => state.buyer.materialRequestFormSubmit
  );
  const [deliveryLocation, setDeliveryLocation] = useState([]);
  const shipment = ["Single Shipment", "Multiple Shipment"];
  const [transPortDetails, setTransPortDetails] = useState({
    DeliveryLocation: "",
    RequiredDate: "",
    Shipment: "",
  });
  const [contactDetailsFormHandle, setContactDetailsFormHandle] = useState({
    name: "",
    phoneNumber: "",
    emailId: "",
  });

  const handleOnChangeContactDetails = (event) => {
    event.preventDefault();

    if (event.target.name === "phoneNumber") {
      const re = /^[0-9\b]+$/;

      if (re.test(event.target.value)) {
        setContactDetailsFormHandle({
          ...contactDetailsFormHandle,
          [event.target.name]: event.target.value,
        });
      } else {
        setContactDetailsFormHandle({
          ...contactDetailsFormHandle,
          [event.target.name]: "",
        });
      }
    } else if (event.target.name === "name") {
      const regex = /^[A-z ]*$/g;
      const value = event.target.value;

      if (regex.test(value)) {
        setContactDetailsFormHandle({
          ...contactDetailsFormHandle,
          name: value,
        });
      }
    } else {
      setContactDetailsFormHandle({
        ...contactDetailsFormHandle,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleOnChangeTransPortDetails = (event) => {
    setTransPortDetails({
      ...transPortDetails,
      [event.target.name]: event.target.value,
    });
  };

  const handleAddNewItem = () => {
    setItemsInformation([
      ...itemsInformation,
      {
        items: "",
        quantity: "",
        itemsDetails: [],
        modelNumber: "",
        disable: false,
      },
    ]);
  };

  const handleRemoveItem = (index) => {
    let data = [...itemsInformation];
    data.splice(index, 1);
    setItemsInformation(data);
  };

  useEffect(() => {
    dispatch(getMaterialRequestDeliveryLocation());
  }, [dispatch]);

  useEffect(() => {
    if (getCitiesResponse.data) {
      setDeliveryLocation(getCitiesResponse.data["items"][0].cities);
    }
  }, [getCitiesResponse]);

  const handleFormChange = (event, index) => {
    let data = [...itemsInformation];

    if (event.target.name === "quantity") {
      const pattern = /^\d+\.?\d*$/;
      if (event.target.value === "" || !pattern.test(event.target.value)) {
        data[index][event.target.name] = "";
      } else {
        data[index][event.target.name] = parseInt(event.target.value);
      }
    } else {
      data[index][event.target.name] = event.target.value;
    }

    if (event.target.name === "items") {
      if (event.target.value.length >= 1) {
        dispatch(getAddMaterialItems(event.target.value))
          .unwrap()
          .then((response) => {
            // console.log("getaddmaterial", response);
            data[index].itemsDetails = [
              ...response?.items?.map((ele) => {
                return (
                  ele?.productName + "," + ele?.HSN + "," + ele?.modelNumber
                );
              }),
            ];
            data[index].productName = response?.items[0]?.productName;
          })
          .catch((error) => {});
      } else {
        data[index].itemsDetails = [];
        data[index].productName = "";
      }
    }
    setItemsInformation([...data]);
  };

  const handleSave = (event, index, itemsList) => {
    let data = [...itemsInformation];

    // Don't accept quantity less than zero
    if (data[index]?.quantity <= 0) {
      toastMessage("Quantity should be positive number and non zero", "error");
      return;
    }
    //Don't continue if quantity is less than zero
    if (data[index]?.items === "") {
      toastMessage(
        'In "Product Name, HSN, Model Number" Field. Select Items from suggestion only',
        "error"
      );
      return;
    }

    //Check if the product is selected from the suggestion else don't save
    const temp = itemsInformation[index].items.split(",");
    if (temp.length == 3) {
      console.log("temp[0", temp[0]);
      dispatch(getAddMaterialItems(temp[0]))
        .unwrap()
        .then((response) => {
          console.log(response);
          response.items.forEach((each) => {
            if (
              each?.productName === temp[0] &&
              each?.HSN === temp[1] &&
              each?.modelNumber === temp[2]
            ) {
              data[index]["disable"] = true;
              toastMessage("Added successfully", "success");
              setItemsInformation(data);
            }
          });
        });
    } else {
      toastMessage(
        'In "Product Name, HSN, Model Number" Field. Select Items from suggestion only',
        "error"
      );
    }
  };

  const handleSubmit = () => {
    if (itemsInformation?.length === 0) {
      toastMessage("Minimum 1 item required", "error");
      return;
    }

    //Check if all the products entered are valid before submission
    let valid = true;

    itemsInformation.forEach((each) => {
      if (!each.disable) {
        valid = !valid;
      }
    });

    //Return if there is a invalid product
    if (!valid) {
      toastMessage("Enter a valid product name", "error");
      return;
    }

    const newObj = {};

    for (let item of itemsInformation) {
      if (!newObj[item?.items]) {
        newObj[item?.items] = {
          items: item?.items,
          quantity: +item?.quantity,
        };
      } else {
        newObj[item?.items]["quantity"] =
          newObj[item?.items]["quantity"] + +item?.quantity;
      }
    }

    const newArray = [];

    for (let item in newObj) {
      newArray.push(newObj[item]);
    }

    let itm = newArray.map((ele) => {
      const tmp = ele["items"].split(",");
      return {
        item: tmp[0],
        HSN: tmp[1],
        modelNumber: tmp[2],
        quantity: ele?.quantity,
      };
    });

    let data = {
      deliveryLocation: transPortDetails.DeliveryLocation,
      emailId: contactDetailsFormHandle.emailId,
      itemsDetails: itm,
      name: contactDetailsFormHandle.name,
      phoneNumber: Number(contactDetailsFormHandle.phoneNumber),
      requiredDate: transPortDetails.RequiredDate,
      shipment: itm.length>1?transPortDetails.Shipment:shipment[0],
    };

    const regex= /^(?!(9999999999|8888888888|7777777777|6666666666|9876543210)$)[6-9][0-9]{9}$/;

    if (data?.emailId === "") {
      toastMessage("emailId is missing, Fill all the fields", "error");
    } else if (data?.itemsDetails[0].item === "") {
      toastMessage("Minimum 1 item required", "error");
      return;
    } else if (data?.itemsDetails[0].quantity === "") {
      toastMessage("Minimum 1 item quantity required", "error");
      return;
    } else if (data?.itemsDetails === "") {
      toastMessage("Enter Items Information, Fill all the fields", "error");
    } else if (data?.name === "") {
      toastMessage("name is missing, Fill all the fields", "error");
    } else if (!regex.test(data?.phoneNumber)) {
      toastMessage("Invalid Phone Number", "error");
    } else if (data?.requiredDate === "") {
      toastMessage("Required Date is missing, Fill all the fields", "error");
    } else if (data?.shipment === "") {
      toastMessage("Shipment is missing, Fill all the fields", "error");
    } else if (
      data?.deliveryLocation === "" ||
      data?.deliveryLocation === "Delivery Location"
    ) {
      toastMessage(
        "Delivery Location is missing, Fill all the fields",
        "error"
      );
    } else {
      dispatch(getMaterialRequestFormSubmit(data))
        .unwrap()
        .then((response) => {
          toastMessage(response.success, "success");
          navigate(-1);
        })
        .catch((error) => {
          toastMessage(error.message, "error");
        });
    }
  };

  return (
    <>
      <form className="d-flex flex-column m-0 p-0 gap-4 request-details-cont mb-3">
        <div className="container-with-heading d-flex flex-column container my-4">
          <span className="content-title mb-3 row">
            Material Request Details
          </span>

          <div className="details-container ">
            <div className="fields-container container-with-heading  px-0 row ">
              <span className="content-title  ">Items Information</span>
              {itemsInformation.map((eachItem, index) => (
                <Fragment key={index}>
                  <div className="fields-container col-12 col-md-4 my-3">
                    <label
                      htmlFor="productName"
                      className="label-text form-label"
                    >
                      Product Name, HSN, Model Number
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        id={"productName"}
                        name="items"
                        // type="text"
                        list={`productNameOptions${index}`}
                        onChange={(event) =>
                          handleFormChange(
                            event,
                            index,
                            eachItem["itemsDetails"]
                          )
                        }
                        disabled={eachItem["disable"] && "disabled"}
                        value={itemsInformation[index]?.items}
                        type="search"
                        // onfocus={(event) => getSuggestionOfproductName(event, index)}
                        className={`input-field form-control ${
                          !eachItem.disable ? "box-shadow-red" : ""
                        } `}
                      />
                      <datalist id={`productNameOptions${index}`}>
                        {eachItem["itemsDetails"].map((ele, index1) => (
                          <option key={index1 + index + index1} value={ele}>
                            {ele}
                          </option>
                        ))}
                      </datalist>
                    </div>
                  </div>
                  <div
                    className="fields-container col-12 col-md-4 my-3"
                    style={{ position: "relative" }}
                  >
                    <label htmlFor="Quantity" className="label-text form-label">
                      Quantity
                    </label>
                    <input
                      id="Quantity"
                      name="quantity"
                      type="text"
                      min="1"
                      disabled={eachItem["disable"] && "disabled"}
                      onChange={(event) => handleFormChange(event, index)}
                      value={itemsInformation[index]?.quantity}
                      className={`input-field form-control`}
                    />
                  </div>
                  <div
                    className="col-12 
              col-md-2 
              d-flex justify-content-end
              align-items-end my-3"
                  >
                    <button
                      type="button"
                      className={`input-field btn btn-success`}
                      disabled={eachItem["disable"] && "disabled"}
                      onClick={(event) =>
                        handleSave(event, index, [...eachItem["itemsDetails"]])
                      }
                    >
                      Save
                    </button>
                  </div>
                  <div
                    className="col-12 col-md-2
               d-flex justify-content-start
              align-items-end my-3"
                  >
                    <button
                      type="button"
                      className={`btn btn-danger`}
                      onClick={() => handleRemoveItem(index)}
                    >
                      Delete
                    </button>
                  </div>
                </Fragment>
              ))}
              <div className="col-12">
                <button
                  className="btn btn-success px-2 my-3"
                  type="button"
                  onClick={handleAddNewItem}
                >
                  <BsPlusCircleFill className="mx-2 my-1" />
                  Add New Item
                </button>
              </div>
            </div>
          </div>

          {/********************************************
           *
           *  Transport Details Starts here
           *
           * ******************************************/}
          <div className="details-container ">
            <div className="fields-container container-with-heading  px-0 row ">
              <span className="content-title">Transport Details</span>

              <div className="fields-container col-12  col-md-4">
                <label
                  htmlFor="DeliveryLocation"
                  className="label-text form-label"
                >
                  Delivery Location <span style={{ color: "red" }}>&#42;</span>
                </label>
                <select
                  id="DeliveryLocation"
                  name="DeliveryLocation"
                  type="text"
                  required
                  onChange={(event) => handleOnChangeTransPortDetails(event)}
                  className={`input-field form-control`}
                >
                  <option disabled value={""} selected>
                    Select Delivery Location
                  </option>
                  {deliveryLocation.map((ele, index) => (
                    <option key={index} value={ele}>
                      {ele}
                    </option>
                  ))}
                </select>
                <span className="text-danger px-1"></span>
              </div>

              <div className="fields-container col-12  col-md-4">
                <label htmlFor="RequiredDate" className="label-text form-label">
                  Required Date <span style={{ color: "red" }}>&#42;</span>
                </label>
                <input
                  min={new Date().toISOString().split("T")[0]}
                  id="RequiredDate"
                  type="Date"
                  name="RequiredDate"
                  required
                  onChange={(event) => handleOnChangeTransPortDetails(event)}
                  // onChange={(event) => {
                  //   setRequiredDate(event.target.value);
                  // }}
                  className={`input-field form-control`}
                />
                <span className="text-danger px-1"></span>
              </div>
              <div className="fields-container col-12  col-md-4">
                <label htmlFor="Shipment" className="label-text form-label">
                  Shipment <span style={{ color: "red" }}>&#42;</span>
                </label>
                <select
                  id="Shipment"
                  type="text"
                  required
                  name="Shipment"
                  onChange={(event) => handleOnChangeTransPortDetails(event)}
                  className={`input-field form-control`}
                >
                  <option disabled value={""} selected>
                    Choose shipment
                  </option>

                  {itemsInformation.length <2 ? (
                    <Fragment key={0}>
                      <option value={shipment[0]}>{shipment[0]}</option>
                    </Fragment>
                  ) : (
                    shipment.map((ele, index) => (
                      <Fragment key={index}>
                        <option value={ele}>{ele}</option>
                      </Fragment>
                    ))
                  )}
                </select>
              </div>
            </div>
          </div>

          {/******************************
           *
           *     Contact Details
           *
           ********************************/}

          <div className="details-container ">
            <div className="fields-container container-with-heading  px-0 row ">
              <span className="content-title">Contact Details</span>

              <div className="fields-container col-12  col-md-4">
                <label htmlFor="Name" className="label-text form-label">
                  Name <span style={{ color: "red" }}>&#42;</span>
                </label>
                <input
                  id="Name"
                  type="text"
                  required
                  name="name"
                  onChange={(event) => handleOnChangeContactDetails(event)}
                  className={`input-field form-control`}
                  value={contactDetailsFormHandle.name}
                />
                <span className="text-danger px-1"></span>
              </div>

              <div className="fields-container col-12  col-md-4">
                <label htmlFor="Email" className="label-text form-label">
                  Email <span style={{ color: "red" }}>&#42;</span>
                </label>
                <input
                  style={{ textTransform: "lowercase" }}
                  id="Email"
                  type="email"
                  required
                  name="emailId"
                  onChange={(event) => handleOnChangeContactDetails(event)}
                  className={`input-field form-control`}
                  //   value={contactDetailsFormHandle?.emailId}
                />
                <span className="text-danger px-1"></span>
              </div>

              <div className="fields-container col-12  col-md-4">
                <label htmlFor="PhoneNumber" className="label-text form-label">
                  Phone Number <span style={{ color: "red" }}>&#42;</span>
                </label>
                <input
                  id="PhoneNumber"
                  type="text"
                  required
                  name="phoneNumber"
                  maxLength="10"
                  pattern="^(?!(9999999999|8888888888|7777777777|6666666666|9876543210)$)[6-9][0-9]{9}$"
                  onChange={(event) => handleOnChangeContactDetails(event)}
                  className={`input-field form-control`}
                  value={contactDetailsFormHandle?.phoneNumber}
                />
                <span className="text-danger px-1"></span>
              </div>
            </div>

            <div className="d-flex justify-content-end my-5">
              <button
                className="danger-button"
                type="button"
                onClick={() => {
                  navigate("/buyer/material-request");
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="success-button"
                onClick={handleSubmit}
              >
                {materialRequestFormSubmit.isLoading ? (
                  <span className="loader mx-auto"></span>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
        </div>
        {/* </div> */}
      </form>
    </>
  );
};

export default BuyerCreateMaterialRequest;
