import { HiOutlineArrowNarrowDown } from "react-icons/hi";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import { BiSort } from "react-icons/bi";
import TablePagination, {
  jumpToPage,
  nextPage,
  previousPage,
} from "../../../../layout/GenericLayout/TablePagination";
import { useState } from "react";
import Loader from "../../../../layout/GenericLayout/Loader";
import ReusableModal from "../../../../layout/GenericLayout/ReusableModal";



const ShowPdf = (props)=>{
  // console.log("I have PDF LINK cross Verifing: ", props.pdfLink)
  return (
    <div >
      <iframe src={props.pdfLink} width="100%" height="600px" title="PDF Viewer" />
    </div>
  )
}

const PurchaseOrderManualPOTable = (props) => {

  const [pdfLinkOfPurchaseOrderID, setPdfLinkOfPurchaseOrderID] = useState('');
  const [showRejectModalStatus, setShowRejectModalStatus] = useState(false);
    
  const toggleRejectModal = ()=>{
    setShowRejectModalStatus(!showRejectModalStatus);
  }

  const updatePage = (currentPage) => {
    const newOffSet = props.tableQueryParams.tableLimit * (currentPage - 1);
    props.setTableQueryParams((prev) => ({
      ...prev,
      tableOffset: newOffSet,
      currentPage,
    }));
  };

  const onClickActiveSort = (activeSortValue) => {
    if (activeSortValue !== "") {
      let order = -1;
      if (activeSortValue === props.tableQueryParams.tableSort) {
        if (props.tableQueryParams.tableOrder === -1) {
          order = 1;
        }
      }
      props.setTableQueryParams((prev) => ({
        ...prev,
        tableSort: activeSortValue,
        tableOrder: order,
      }));
    }
  };

  const onClickViewMoreTableData = () => {

    console.log("Table Editing: ", props.tableQueryParams.tableLimit)
    let newLimit = props.tableQueryParams.tableLimit;
    if (newLimit === 5) {
      newLimit += 5;
    } else {
      newLimit -= 5;
    }
    props.setTableQueryParams((prev) => ({
      ...prev,
      tableLimit: newLimit,
    }));

    console.log("This is Table Params: ", props.tableQueryParams)
  };

  

  const handlePurchaseOrderOnClick = (buyerID, profileUrl) =>{
      console.log("Handling Purchase Order On click ==> ", buyerID)
      setShowRejectModalStatus(true);
      setPdfLinkOfPurchaseOrderID(profileUrl);
  }

  return (
    <>
    {
      showRejectModalStatus && (
        <ReusableModal
        showModal={showRejectModalStatus}
        toggleModal={toggleRejectModal}
        modalContent={
          <ShowPdf
          pdfLink = {pdfLinkOfPurchaseOrderID}
          />
        }
        modalSizeClass="modal-lg"
      />)
    }
      <div 
      className="buyer-table-container" 
    style={{display:'relative'}}

       >
        <div className="table-inner-cont">
        <table className={props.className}>
          <thead>
            <tr>
              {props.tableHeader.map((eachHeader) => (
                <th
                  key={eachHeader.name}
                  scope="col"
                  // role={eachHeader.sortValue !== "" ? "button" : false}
                  className={`${
                    props.tableQueryParams.tableSort === eachHeader.sortValue &&
                    eachHeader.sortValue !== "" &&
                    "active-sort-text"
                  }`}
                  onClick={() => onClickActiveSort(eachHeader.sortValue)}
                >
                  {eachHeader.name}
                  {eachHeader.sortValue !== "" && <BiSort />}
                </th>
              ))}
            </tr>
          </thead>
          {props.materialRequestMetaData?.paging?.total<0?(<div className="no-data text-danger">Oops! there is no data to show. Please check back later</div>):null} 
          {props.isLoading ? (
            <tbody>
              <tr>
                <td colSpan={7}>
                  <Loader className="my-5" />
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              {props.tableRows.map((eachRows, index) => (
                <tr key={eachRows.buyerId.toString()+index.toString()} scope="col">
                  <td>{eachRows.addedDate}</td>
                  <td 
                  className="remove-underline-link row-hight-light-txt"
                  onClick={()=> handlePurchaseOrderOnClick(eachRows.buyerId, eachRows.poFileUrl)}
                  >{eachRows.poId}</td>
                  <td>{eachRows.buyerName}</td>
                  <td>{eachRows.sellerName}</td>
                  <td>{eachRows.totalAmount}</td>
                  <td>{eachRows.invoiceStatus}</td>
                  <td style={{textTransform:"capitalize"}}>{eachRows.lenderStatus}</td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
        </div>
        {!props.isLoading && props.materialRequestMetaData?.paging?.total>5 && (
          <div className="d-flex justify-content-end px-2">
            <span
              onClick={onClickViewMoreTableData}
              className="d-flex align-items-center gap-1 my-3 view-more-text"
            >
              {props.tableQueryParams.tableLimit === 5
               ? props.tableQueryParams.tableOffset+props.tableQueryParams.tableLimit<props.materialRequestMetaData?.paging?.total?"View More ":""
               : props.tableQueryParams.tableOffset+props.tableQueryParams.tableLimit<props.materialRequestMetaData?.paging?.total?"View Less ":""}
              <HiOutlineArrowNarrowDown
                className={`fs-5 ${
                  props.tableQueryParams.tableLimit === 5
                  ? props.tableQueryParams.tableOffset+props.tableQueryParams.tableLimit<props.materialRequestMetaData?.paging?.total? "down-arrow":"d-none"
                  : props.tableQueryParams.tableOffset+props.tableQueryParams.tableLimit<props.materialRequestMetaData?.paging?.total? "up-arrow":"d-none"}
                `}
              />
            </span>
          </div>
        )}
      </div>
      <div className="d-flex align-items-center gap-2 page-data-text-container mt-3 px-3">
        <TablePagination
          pageSize={props.tableQueryParams.tableLimit}
          onUpdatePage={updatePage}
          current={props.tableQueryParams.currentPage}
          total={props.materialRequestMetaData?.paging?.total}
        ></TablePagination>
      </div>
    </>
  );
};

export default PurchaseOrderManualPOTable;
