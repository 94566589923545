import { HiOutlineArrowNarrowDown } from "react-icons/hi";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";

const AdminAnchoAndLenderMappedTable = (props) => {
  /* TABLE OPTIONS */
  const totalPage = 5;
  return (
    <>
      <div className="table-container">
        <table className={props.className}>
          <thead>
            <tr>
              {props.tableHeader.map((eachHeader) => (
                <th key={eachHeader} scope="col">
                  {eachHeader}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {props.tableRows.map((eachRows, index) => (
              <tr key={eachRows.distributorId} scope="col">
                <td className="d-flex flex-column align-items-center">
                  {index + 1}
                </td>

                <td>{eachRows.seller_Name}</td>

                <td>{eachRows.lender_Name}</td>

                <td>
                  {eachRows?.status ? (
                    <span className="text-capitalize success-btn">
                      {eachRows?.status}
                    </span>
                  ) : (
                    <span className="text-capitalize danger-btn">NAN</span>
                  )}
                  {/* <span className="text-capitalize success-btn">
                    {eachRows.status}
                  </span> */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* <div className="d-flex justify-content-end px-2">
          <span
            // onClick={props.onClickViewMoreTableData}
            className="d-flex align-items-center gap-1 my-3 view-more-text"
          >
            View more <HiOutlineArrowNarrowDown className="fs-5" />
          </span>
        </div> */}
      </div>
      {/* <div className="d-flex align-items-center gap-2 page-data-text-container mt-3 px-3">
        <AiOutlineDoubleLeft
        // onClick={props.onClickPreviousPage}
        />
        <span className="page-data-text">
          Page {} of {totalPage}
        </span>
        <AiOutlineDoubleRight
        // onClick={props.onClickNextPage}
        />
      </div> */}
    </>
  );
};

export default AdminAnchoAndLenderMappedTable;
