import { useEffect, useState } from "react";
import { BsPlusCircleFill } from "react-icons/bs";

import { useDispatch, useSelector } from "react-redux";
import ReusableModal from "../../layout/GenericLayout/ReusableModal";
import { getIsEmailRegistered } from "../../store/slices/rm";

import { useNavigate } from "react-router-dom";
import { toastMessage } from "../../layout/GenericLayout/ToastMessage";
import { getListOfCustomersOnboarding } from "../../store/slices/corporate";
import { InputValidator } from "../../layout/GenericLayout/InputValidator";
import Loader from "../../layout/GenericLayout/Loader";
import RMListOfCustomerOnboardingTable from "./rmTables/RMListOfCustomerOnboardingTable";
import {Helmet} from "react-helmet";

/* MODALS OR CHILDREN COMPONENTS */
const ChooseOnboardType = ({ toggleOnboardModal, onRefreshTable }) => {
  /* FUNCTION DECLARATIONS */
  const navigate = useNavigate();
  const [emailId, setEmailId] = useState("");
  const [isEmail, setIsEmail] = useState(false);
  const [activePath, setActivePath] = useState("");
  const [isError, setIsError] = useState(null);
  const dispatch = useDispatch();


  const onChangeEmail = (event) => {
    setEmailId(event.target.value);
  };

  const onClickSetPath = (data) => {
    setActivePath(data);
    isEmailStatus();
  };

  const isEmailStatus = () => {
    setIsEmail((prev) => !prev);
  };

  const onClickRoute = () => {
    navigate(activePath, { state: { emailId } });
  };

  const handleOnsubmit = ()=>{
    if(!isEmail?.emailId){
      dispatch(getIsEmailRegistered(emailId))
      .unwrap()
      .then((response)=>{
        if(response?.success){
          navigate(activePath, { state: { emailId, currentPath: "/rm/customer-onboarding", } });
        }
      })
      .catch((error)=>{
        // console.log(error?.message)
         toastMessage(error?.message ?? "Something went wrong", "error");
          setIsError((prev)=> ({
            ...prev,
            [emailId]:error?.message,
          }))
      })
    }



    // navigate(activePath, { state: { emailId } });
  }

  const onBlurInputFeilds = (event) => {

    const name = event.target.name;
    const value = event.target.value;

    const validator = {
      emailId: {
        regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        message: "Please enter a valid email address",
      }
    };

    const errorMessgae = InputValidator({

      regex: validator[name].regex,
      value,
      message: validator[name].message,

    });

    setIsError((prev) => ({
      ...prev,
      [name]: errorMessgae,
    }));
  };

  return (
    <div className="pb-3">
      <span className="fs-4 fw-semibold">Onboard Anchor/Counter Party</span>
      <div className="details-container container mt-4">
        {isEmail ? (
          <div className="row gy-md-4 gy-2">
            <div className="kyc-fields-container col-12 ">

              <label
                htmlFor="emailId"
                className="kyc-label-text form-label fw-semibold"
              >
                Email Id <span style={{ color: "red" }}>*</span>
              </label>
              <input
                id="emailId"
                type="email"
                className={`kyc-input-field form-control ${isError?.emailId && 'invalid-input'}`}
                name="emailId"
                onChange={onChangeEmail}
                onBlur={onBlurInputFeilds}
                // style={{border:error?.errors?.state === undefined ? "1px solid lightgray" : '1px solid red'}}
              />
                  
              
              <span style={{color:'red'}}>{isError?.emailId}</span>
            </div>
            <div className="row text-center mt-4">
              <div className="kyc-fields-container col-12">
                <button
                  onClick={() => isEmailStatus()}
                  className="btn btn-primary mx-3"
                >
                  Back
                </button>

                <button
                  disabled={isError?.emailId}
                  // onClick={onClickRoute}
                  onClick={handleOnsubmit}
                  className="btn btn-success mx-3 my-2"
                
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="row gy-md-4 gy-2">
            <div className="kyc-fields-container col-12 col-md-6 text-center">
              <button
                onClick={() =>
                  onClickSetPath("/rm/rm-onboarding/anchor")
                }
                className="btn btn-outline-primary px-5 py-3 fs-5"
              >
                Onboard Anchor
              </button>
            </div>

            <div className="kyc-fields-container col-12 col-md-6 text-center">
              <button
                onClick={() =>
                  onClickSetPath(
                    "/rm/rm-onboarding/counter-party"
                  )
                }
                className="btn btn-outline-primary px-5 py-3 fs-5"
              >
                Onboard Counter Party
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

/* PARENT COMPONENT */
const RMCustomerOnboarding = () => {
  const dispatch = useDispatch();
  const listOfCustomersOnboardingData = useSelector(
    (state) => state.corporate.listOfCustomersOnboardingData
  );
  const [channelAllUsersTableRows, setChannelAllUsersTableRows] = useState([]);

  /* MODALS USESTATE */
  const [showChooseOnboardTypeModal, setShowLenderAddModal] = useState(false);

  /* MODALS TOGGLE */
  const toggleOnboardModal = () => {
    setShowLenderAddModal((prev) => !prev);
  };

  useEffect(() => {
    dispatch(getListOfCustomersOnboarding())
      .unwrap()
      .then((response) => {
        setChannelAllUsersTableRows(response?.success)
        console.log("response : ", response);
      })
      .catch((error) => {
        console.log("error : ", error);
      });
  }, []);

  return (
    <>
      {/* MODAL CONTENT */}
      {showChooseOnboardTypeModal && (
        <ReusableModal
          showModal={showChooseOnboardTypeModal}
          toggleModal={toggleOnboardModal}
          modalContent={
            <ChooseOnboardType toggleOnboardModal={toggleOnboardModal} />
          }
          modalSizeClass="modal-lg"
        />
      )}

      <div className="d-flex flex-column gap-4">
      <Helmet>
                <title>RM-Customer-Onboarding</title>
            </Helmet>
        <div className="bg-white admin-dashboard-card-container px-3 pt-3 d-flex flex-column gap-2 pb-5">
          <div className="d-flex justify-content-between align-items-center flex-wrap px-2 gap-2 py-2">
            <span className="dashboard-content-title">
              List Of Customer Onboarding
            </span>
            <div
              onClick={toggleOnboardModal}
              className="d-flex align-items-center"
              // type="button"
            >
              <button type="button" className="btn btn-outline-primary">
                <BsPlusCircleFill style={{ fontSize: "1.6rem", }}/>
                &nbsp;&nbsp;Add Anchor/Counter Party 
              </button>
            </div>
          </div>
          {listOfCustomersOnboardingData.isLoading ? (
            <Loader className="my-5" />
          ) : (
            <RMListOfCustomerOnboardingTable
              className="admin-channel-partner-data-table"
              tableHeader={[
                "Sector Name",
                "Email ID",
                "Roles",
                "Application Status",
              ]}
              tableRows={channelAllUsersTableRows}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default RMCustomerOnboarding;
