import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../layout/GenericLayout/Loader";
import SuperAdminTable from "./AdminTables/SuperAdminTable";
import AdminDashboardTable from "./AdminTables/SuperAdminTable";
import { getSuperAdminData, getSelectLender } from "../../store/slices/admin";
import {Helmet} from "react-helmet";

const AdminSuperAdmin = () => {
  /* FUNCTION DECLARATIONS */
  const dispatch = useDispatch();
  const [dataView, setdataView] = useState(undefined);
  const [activeTableData, setActiveTableData] = useState(1);
  const [selectedData, setSelectedData] = useState(undefined);

  /* USESELECTOR */
  const superAdminData = useSelector((state) => state.admin.superAdminData);

  /* USESTATES */
  const [superAdminDataTableRows, setSuperAdminTableRows] = useState([]);
  const[superAdminMetaData,setSuperAdminMetaData]=useState([]);
  const selectLenderData = useSelector((state) => state.admin.selectLenderData);
  const [activeMappingData] = useState({
    lender: "",
  });


  /* TABLE QUERY PARAMS */
  const [tableQueryParams, setTableQueryParams] = useState({
    tableOffset: 0,
    tableLimit: 5,
    tableSort: "modifiedDate",
    tableOrder: -1,
    currentPage: 1,
    search: "",
  });

  const onFocusSelectLender = () => {
    dispatch(getSelectLender());
  };

  const onClickDone = (event) => {
    setSelectedData(event.target.value);
    // const value = event.target.value;
    // const body = {
    //   lenderId: value,
    //   manual_invoice: false,
    // };
    // dispatch(getSuperAdminData(body))
    //   .unwrap()
    //   .then((response) => {
    //     console.log("SuperAdminData>>>>>>>>>>>>>>>>", response);
    //     setSuperAdminTableRows(response.items);
    //     setSuperAdminMetaData(response.metadata);
    //   })
    //   .catch((error) => {
    //     console.log("error : ", error);
    //   });
  };

  useEffect(()=>{
    const body = {
      lenderId: selectedData,
      manual_invoice: activeTableData===1?true:false ,
      limit: tableQueryParams.tableLimit,
      offset: tableQueryParams.tableOffset,
    };
    dispatch(getSuperAdminData(body))
      .unwrap()
      .then((response) => {
        // console.log("SuperAdminData>>>>>>>>>>>>>>>>", response);
        setSuperAdminTableRows(response.items);
        setSuperAdminMetaData(response.metadata);
      })
      .catch((error) => {
        console.log("error : ", error);
      });
  },[tableQueryParams,selectedData,activeTableData])

  const onClickActiveTable = (value) => {
    setActiveTableData(value);
    // if (value == 1) {
    //   setActiveTableData(value);
    //   const body = {
    //     lenderId: selectedData,
    //     manual_invoice: true,
    //   };
    //   dispatch(getSuperAdminData(body))
    //     .unwrap()
    //     .then((response) => {
    //       console.log("SuperAdminData>>>>>>>>>>>>>>>>", response);
    //       setSuperAdminTableRows(response.items);
    //     })
    //     .catch((error) => {
    //       console.log("error : ", error);
    //     });
    // } else {
    //   setActiveTableData(value);
    //   const body = {
    //     lenderId: selectedData,
    //     manual_invoice: false,
    //   };
    //   dispatch(getSuperAdminData(body))
    //     .unwrap()
    //     .then((response) => {
    //       console.log("SuperAdminData>>>>>>>>>>>>>>>>", response);
    //       setSuperAdminTableRows(response.items);
    //     })
    //     .catch((error) => {
    //       console.log("error : ", error);
    //     });
    // }
  };



  return (
    <div className="d-flex flex-column gap-4">
      <Helmet>
                <title>Super-Admin</title>
            </Helmet>
      <div className="bg-white admin-dashboard-card-container p-3">
        <div className="d-flex flex-column flex-sm-row align-items-center my-4">
          <div className="d-flex flex-column justify-content-start justify-content-sm-center gap-3 col-12 col-sm-6 ">
            <label
              htmlFor="selectAnchor"
              dataView
              className="anchor-and-lender-select-label"
            >
              Select Lender
            </label>
            <select
              value={selectedData}
              onFocus={onFocusSelectLender}
              onChange={onClickDone}
              name="lender"
              className="form-select w-75"
              id="selectAnchor"
            >
              {selectLenderData.isLoading ? (
                <option disabled>Loading...</option>
              ) : (
                <>
                  <option value="">Select Lender</option>
                  {selectLenderData.data &&
                    selectLenderData.data.map((eachLenderData) => (
                      <option
                        key={eachLenderData.customerId}
                        value={eachLenderData.customerId}
                      >
                        {eachLenderData.fullName}
                      </option>
                    ))}
                </>
              )}
            </select>
          </div>
        </div>
      </div>

      <div className="d-flex flex-column">
        <div className="d-flex">
          <span
            className={`dashboard-table-title ${
              activeTableData !== 1 && "dashboard-table-title-hover"
            } py-2 px-3 ${
              activeTableData === 1 && "active-table-data placeholder-wave"
            }`}
            onClick={() => onClickActiveTable(1)}
          >
            Manual Invoices
          </span>
          <span
            className={`dashboard-table-title ${
              activeTableData !== 2 && "dashboard-table-title-hover"
            } py-2 px-3 ${
              activeTableData === 2 && "active-table-data placeholder-wave"
            }`}
            onClick={() => onClickActiveTable(2)}
          >
            Digital Invoices
          </span>
        </div>
        <div>
          {superAdminData.isLoading ? (
            <div className="d-flex text-center w-100 py-5 d-flex justify-content-center">
              <Loader />
            </div>
          ) : (
            <>
              {activeTableData === 1 &&
                superAdminDataTableRows.length !== 0 && (
                  <>
                    <AdminDashboardTable
                      className="admin-dashboard-table"
                      tableHeader={[
                        "Added Date",
                        "Anchor",
                        "Anchor ID",
                        "Invoice ID",
                        "Invoice Amount",
                        "Disbursing Receive Amt",
                        "Counter Party Invoice Status",
                        "Invoice Status",
                      ]}
                      tableRows={superAdminDataTableRows}
                      superAdminTrailMetaData={superAdminMetaData}
                      tableQueryParams={tableQueryParams}
                      setTableQueryParams={setTableQueryParams}
                      isLoading={superAdminData.isLoading}
                    />
                    {/* <center>
                {userDisplay !== undefined &&
                    <Pagination userDisplay={userDisplay} activePage={activePage}  handlepaginate={handlepaginate} handlepage={handlepage} />
                }
              </center> */}
                  </>
                )}

              {activeTableData === 2 &&
                superAdminDataTableRows.length !== 0 && (
                  <>
                    <AdminDashboardTable
                      className="admin-dashboard-table"
                      // tableRowsCount={tableRowsCount}
                      tableHeader={[
                        "Date",
                        "Anchor",
                        "Anchor ID",
                        "Invoice ID",
                        "Invoice Amount",
                        "Disbursing Received Amount",
                        "Status",
                        "Invoice Status"
                      ]}
                      tableRows={superAdminDataTableRows}
                      superAdminTrailMetaData={superAdminMetaData}
                      tableQueryParams={tableQueryParams}
                      setTableQueryParams={setTableQueryParams}
                      isLoading={superAdminData.isLoading}
                    />

                    {/* <center>
                {userDisplay !== undefined &&
                    <Pagination userDisplay={userDisplay} activePage={activePage}  handlepaginate={handlepaginate} handlepage={handlepage} />
                }
              </center> */}
                  </>
                )}

              {(activeTableData !== 1 || activeTableData !== 2) &&
                superAdminDataTableRows.length === 0 && (
                  <div className="d-flex text-center w-100 py-5 d-flex justify-content-center">
                    <h2 className="text-danger">No Data Available!</h2>
                  </div>
                )}
            </>
          )}
        </div>
      </div>
      {/* <div className="bg-white admin-dashboard-card-container px-3 pt-3 d-flex flex-column gap-2 pb-5">
        <span className="dashboard-content-title">Super Admin</span>
        {superAdminData.isLoading ? (
          <Loader className="my-5" />
        ) : (
          <SuperAdminTable
            className="admin-channel-partner-data-table"
            tableHeader={[
              "Added Date",
              "Seller Name",
              "Seller ID",
              "Invoice ID",
              "Invoice Amount",
              "Disbursing Recieve Amt",
              "Buyer Invoice Status",
              "Invoice Status"
            ]}
            tableRows={superAdminDataTableRows}
          />
        )}
      </div> */}
    </div>
  );
};

export default AdminSuperAdmin;
