import { HiOutlineArrowNarrowDown } from "react-icons/hi";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import { BiSort } from "react-icons/bi";
import TablePagination, {
  jumpToPage,
  nextPage,
  previousPage,
} from "../../../../layout/GenericLayout/TablePagination";
import { useState } from "react";
import Loader from "../../../../layout/GenericLayout/Loader";
import ReusableModal from "../../../../layout/GenericLayout/ReusableModal";
import PaymentPage from "../../../../Pages/PaymentPage";
import { CiUndo } from "react-icons/ci";
import ReusableMessageModal from "../../../../layout/GenericLayout/ReusableMessageModel";
import { useDispatch, useSelector } from "react-redux";
import { updateBuyerManualStatus } from "../../../../store/slices/buyer";
import { toastMessage } from "../../../../layout/GenericLayout/ToastMessage";

const ShowPdf = (props) => {
  return (
    <div>
      <iframe
        src={props.pdfLink}
        width="100%"
        height="600px"
        // height="100%"
        title="PDF Viewer"
      />
    </div>
  );
};

const GetPaymentUser = (props) => {
  const [payment, setPayment] = useState(props?.amount);
  return (
    <div className="m-2 p-3 d-flex justify-content-center align-items-start flex-column gap-3">
      <h4>Enter the Payment Amount</h4>
      <div className="d-flex align-items-center gap-2 justify-content-center w-100">
        <input
          type="number"
          value={payment}
          placeholder="Enter the amount"
          onChange={(event) => {
            setPayment(event.target.value);
          }}
        />
        <CiUndo onClick={() => setPayment(props?.amount)} />
      </div>

      <div className="d-flex w-100 justify-content-center gap-5 my-3">
        <button
          type="button"
          className="btn btn-danger"
          onClick={props?.cancel}
        >
          cancel
        </button>
        <PaymentPage amount={payment}>
          <button type="button" className="btn btn-primary">
            Make Payment
          </button>
        </PaymentPage>
      </div>
    </div>
  );
};

const BillLodgingManual = (props) => {
  const dispatch = useDispatch();
  const [showInvoiceFileURL, setInvoiceFileURL] = useState(false);
  const toggleShowInvoiceFileURL = () => {
    setInvoiceFileURL(!showInvoiceFileURL);
  };

  const buyerManualInvoiceData = useSelector(
    (state) => state.buyer.buyerManualInvoiceData
  );
  const [invoiceNumberPdfURL, setInvoiceNumberPdfURL] = useState();
  const [showPaymentWindow, setShowPaymentWindow] = useState(false);
  const [paymentAmount, setPaymentAmount] = useState();

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const updatePage = (currentPage) => {
    const newOffSet = props.tableQueryParams.tableLimit * (currentPage - 1);
    props.setTableQueryParams((prev) => ({
      ...prev,
      tableOffset: newOffSet,
      currentPage,
    }));
  };

  const onClickActiveSort = (activeSortValue) => {
    if (activeSortValue !== "") {
      let order = -1;
      if (activeSortValue === props.tableQueryParams.tableSort) {
        if (props.tableQueryParams.tableOrder === -1) {
          order = 1;
        }
      }
      props.setTableQueryParams((prev) => ({
        ...prev,
        tableSort: activeSortValue,
        tableOrder: order,
      }));
    }
  };

  const onClickViewMoreTableData = () => {
    console.log("Table Editing: ", props.tableQueryParams.tableLimit);
    let newLimit = props.tableQueryParams.tableLimit;
    if (newLimit === 5) {
      newLimit += 5;
    } else {
      newLimit -= 5;
    }
    props.setTableQueryParams((prev) => ({
      ...prev,
      tableLimit: newLimit,
    }));

    console.log("This is Table Params: ", props.tableQueryParams);
  };

  const handleInvoiceNumberDetailsPdf = (pdfLink) => {
    setInvoiceNumberPdfURL(pdfLink);
    setInvoiceFileURL(true);
  };

  const returnProperDateFormat = (dateString) => {
    const date = new Date(dateString);
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthInText = monthNames[date.getMonth()];

    if (
      dateString === undefined ||
      date === undefined ||
      date === null ||
      isNaN(date)
    ) {
      return dateString;
    } else {
      if (date.getHours() === 0) {
        return date.getDate() + " " + monthInText + " " + date.getFullYear();
      } else {
        return (
          date.getDate() +
          " " +
          monthInText +
          " " +
          date.getFullYear() +
          " " +
          date.getHours() +
          ":" +
          date.getMinutes() +
          ":" +
          date.getSeconds()
        );
      }
      // return   dateString
    }
  };

  const toggleShowPaymentWindow = () => {
    setShowPaymentWindow(!showPaymentWindow);
  };
  const handleMakePayment = (amount) => {
    setShowPaymentWindow(true);
    setPaymentAmount(amount);
  };

  const onClickStatusButtonAction = ({ data, status }) => {
    const body = {
      invoiceId: data?.invoiceId,
      sellerId: data?.sellerId,
      status: status,
      acceptanceCriteria: true,
      rejectionRemarks: "",
    };

    dispatch(updateBuyerManualStatus(body))
      .unwrap()
      .then((response) => {
        console.log("response : ", response);
        toastMessage(response.success, "success");
        props.setActiveTableData(1);
      })
      .catch((error) => {
        console.log("error : ", error);
        toastMessage(error.message, "error");
      });

    // API CALL : https://devapi.tulipfin.com/buyer/manual_invoice
  };

  return (
    <>
      {showInvoiceFileURL ? (
        <>
          <ReusableModal
            showModal={showInvoiceFileURL}
            toggleModal={toggleShowInvoiceFileURL}
            modalContent={<ShowPdf pdfLink={invoiceNumberPdfURL} />}
            modalSizeClass="modal-lg"
          />
        </>
      ) : (
        <></>
      )}
      {showPaymentWindow && (
        <ReusableMessageModal
          showModal={showPaymentWindow}
          toggleModal={toggleShowPaymentWindow}
          //  msgType={"alert"}
          noBtn={true}
          modalContent={
            <GetPaymentUser
              amount={paymentAmount}
              cancel={toggleShowPaymentWindow}
            />
          }
          modalSizeClass="modal-lg"
        />
      )}
      <div className="buyer-table-container">
        <div className="table-inner-cont">
          <table className={props.className}>
            <thead>
              <tr>
                {props.tableHeader.map((eachHeader) => (
                  <th
                    key={eachHeader.name}
                    scope="col"
                    // role={eachHeader.sortValue !== "" ? "button" : false}
                    className={`${
                      props.tableQueryParams.tableSort ===
                        eachHeader.sortValue &&
                      eachHeader.sortValue !== "" &&
                      "active-sort-text"
                    }`}
                    onClick={() => onClickActiveSort(eachHeader.sortValue)}
                  >
                    {eachHeader.name}
                    {eachHeader.sortValue !== "" && <BiSort />}
                  </th>
                ))}
              </tr>
            </thead>
            {props.isLoading ? (
              <tbody>
                <tr>
                  <td colSpan={9}>
                    <Loader className="my-5" />
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {/* Invoice Date	
              Invoice Number	
              Total Amount (₹)	
              Due Date	
              Upload BOE	
              View BOE	
              View LR	
              status
              payment */}
                {props.tableRows.map((eachRows, index) => (
                  <tr
                    key={eachRows.invoiceId.toString() + index.toString()}
                    scope="col"
                  >
                    <td>{returnProperDateFormat(eachRows.invoiceDate)}</td>
                    <td
                      className="pointer-cursor"
                      onClick={() => {
                        handleInvoiceNumberDetailsPdf(eachRows?.invoiceFileUrl);
                      }}
                    >
                      {eachRows.externalInvoiceId}
                    </td>
                    <td>{eachRows.invoiceAmount}</td>
                    <td>{eachRows.dueDate}</td>
                    {/* <td>{'File Exist'}</td> */}
                    {/* <td>{'View'}</td> */}
                    {/* <td>{'View'}</td> */}
                    <td>
                      {
                      eachRows?.status === "Accepted"? (
                        <span
                          disabled
                          className={`w-75 ${
                            eachRows?.status === "Accepted"
                              ? "text-success"
                              : "text-danger"
                          }`}
                        >
                          {eachRows?.status}
                        </span>
                      ) :
                      eachRows?.status === "Rejected"? (
                        <span
                          disabled
                          className="w-75 hightLight-td bg-danger px-3 py-2"
                        >
                          {eachRows?.status}
                        </span>
                      ) : (
                        <div className="d-flex align-items-center gap-2">
                          {buyerManualInvoiceData.isLoading ? (
                            <Loader />
                          ) : (
                            <>
                              <button
                                onClick={() =>
                                  onClickStatusButtonAction({
                                    data: eachRows,
                                    status: "Accepted",
                                  })
                                }
                                className="w-75 hightLight-td bg-success text-white"
                              >
                                Accept
                              </button>

                              <button
                                onClick={() =>
                                  onClickStatusButtonAction({
                                    data: eachRows,
                                    status: "Rejected",
                                  })
                                }
                                className="w-75 hightLight-td bg-danger text-white"
                              >
                                Reject
                              </button>
                            </>
                          )}
                        </div>
                      )}
                      {/* <button disabled className="w-75 hightLight-td">
                        {eachRows?.status ? eachRows?.status : "N/A"}
                      </button> */}
                    </td>
                    <td>
                      {/* <PaymentPage
                        amount={eachRows?.invoiceAmount}
                      > */}
                      <button
                        className="w-75 hightLight-td bg-info"
                        onClick={() =>
                          handleMakePayment(eachRows?.invoiceAmount)
                        }
                      >
                        {"Make Payment"}
                      </button>
                      {/* </PaymentPage> */}
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
        {!props.isLoading && (
          <div className="d-flex justify-content-end px-2">
            <span
              onClick={onClickViewMoreTableData}
              className="d-flex align-items-center gap-1 my-3 view-more-text"
            >
              View more{" "}
              <HiOutlineArrowNarrowDown
                className={`fs-5 ${
                  props.tableQueryParams.tableLimit === 5
                    ? "down-arrow"
                    : "up-arrow"
                }`}
              />
            </span>
          </div>
        )}
      </div>
      <div className="d-flex align-items-center gap-2 page-data-text-container mt-3 px-3">
        <TablePagination
          pageSize={props.tableQueryParams.tableLimit}
          onUpdatePage={updatePage}
          current={props.tableQueryParams.currentPage}
          total={props.materialRequestMetaData?.paging?.total}
        ></TablePagination>
      </div>
    </>
  );
};

export default BillLodgingManual;
