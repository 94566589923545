import { Fragment } from "react";
import { useNavigate, useRouteError } from "react-router-dom";

export const GlobalErrorBoundary = (props) => {

	let error = useRouteError();
	const navigate = useNavigate();
	
	return (
		<Fragment>
			<div className="container text-align-center">
				<h1 className="display-6">Exception was thrown</h1>
				<section className="bg-light mb-4">{ error?.message ?? "Unknown Error Occurred!" }</section>
				<button className="btn btn-dark" onClick={ () => navigate("/login") }>Go To Login</button>
				<button className="btn btn-light" onClick={ () => window.location.reload() }>Refresh Page</button>
			</div>
		</Fragment>
	);
};