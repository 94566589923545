// Dev Base URL
export const BASE_URL = "https://uat_api.atoncap.com/";
export const DOC_URL = "https://uat_swarmdb.atoncap.com/";
export const CAM_URL = "https://uat_cam.atoncap.com";
// export const PAY_URL = ""

// Production Base URL
// export const BASE_URL = "https://api.atoncap.com/";
// export const DOC_URL = "https://swarmdb.atoncap.com/";
// export const CAM_URL = "https://cam.atoncap.com";
// export const PAY_URL = ""

/* FOR TESTING */
// export const BASE_URL = "https://devapi.tulipfin.com/";
// current domain : http://10.10.10.65



// export const  baseUrl='https://devapi.tulipfin.com/';
