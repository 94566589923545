import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { BsSearch } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getBillLodging,
  getBillLodging1,
  getSearchApi,
  getSearchApi1,
} from "../../store/slices/lender";
import "../../style/components/extra.css";
import LenderInvoiceTable from "../Lender/LenderTables/LenderBillLogdingTable";

const LenderBillLogding = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const proFormaInvoiceTableResponse = useSelector(
    (state) => state.lender.billLoding
  );
  const proFormaInvoiceTableResponse1 = useSelector(
    (state) => state.lender.billLoding1
  );
  const proFormaInvoiceTableResponse2 = useSelector(
    (state) => state.lender.billLoding2
  );
  const proFormaInvoiceTableResponse3 = useSelector(
    (state) => state.lender.billLoding3
  );

  const [proformaInvoiceTableRowData, setProformaInvoiceTableRowData] =
    useState([]);
  const [proformInvoiceTableMetaData, setProformInvoiceTableMetaData] =
    useState(null);
  const [searchByProformaID, setSearchByProformaID] = useState("");
  const [tabView, setTabView] = useState(1);
  const [valueManual, setManual] = useState(true);
  const [digiValue, setDigi] = useState(true);

  /* TABLE QUERY PARAMS */
  const [tableQueryParams, setTableQueryParams] = useState({
    tableOffset: 0,
    tableLimit: 5,
    tableSort: "proformaId",
    tableOrder: 1,
    currentPage: 1,
    search: "",
  });



  useEffect(() => {
    // //console.log("tableQueryParams : ", tabView);
    if(sessionStorage.getItem("digitalInvoice")===null||sessionStorage.getItem("digitalInvoice")==="true"){
      setTabView(1);
    }else{
      setTabView(2);
    }

    if (tabView === 1) {
      dispatch(getBillLodging(tableQueryParams));
      // }
    }
    if (tabView === 2) {
      dispatch(getBillLodging1(tableQueryParams));
    }
  }, [tableQueryParams, dispatch, tabView]);

  useEffect(() => {
    if (proFormaInvoiceTableResponse.data) {
      setProformaInvoiceTableRowData(proFormaInvoiceTableResponse.data.items);
      setProformInvoiceTableMetaData(
        proFormaInvoiceTableResponse.data.metadata
      );
      setDigi(false);
    }
  }, [proFormaInvoiceTableResponse]);

  useEffect(() => {
    if (proFormaInvoiceTableResponse1.data) {
      setProformaInvoiceTableRowData(proFormaInvoiceTableResponse1.data.items);
      setProformInvoiceTableMetaData(
        proFormaInvoiceTableResponse1.data.metadata
      );
      setManual(false);
    }
  }, [proFormaInvoiceTableResponse1]);

  useEffect(() => {
    if (proFormaInvoiceTableResponse2.data) {
      setProformaInvoiceTableRowData(proFormaInvoiceTableResponse2.data.items);
      setProformInvoiceTableMetaData(
        proFormaInvoiceTableResponse2.data.metadata
      );
    }
  }, [proFormaInvoiceTableResponse2]);

  useEffect(() => {
    if (proFormaInvoiceTableResponse3.data) {
      setProformaInvoiceTableRowData(proFormaInvoiceTableResponse3.data.items);
      setProformInvoiceTableMetaData(
        proFormaInvoiceTableResponse3.data.metadata
      );
    }
  }, [proFormaInvoiceTableResponse3]);


  const handleSearchData = () => {
    console.log("uygiu");
    if (tabView === 1) {
      dispatch(getSearchApi(searchByProformaID));
    } else {
      dispatch(getSearchApi1(searchByProformaID));
    }
  };

  useEffect(() => {
    // if (searchByProformaID !== "") {
      setTableQueryParams((prev) => ({ ...prev,tableOffset:0, search: searchByProformaID }));
    // }
  }, [searchByProformaID]);

  const handleSearchByProformaIDSubmit = (e) => {
    if (e.key === "Enter") {
      handleSearchData()

    }
  };

  const RedirectFunct = (data, data1) => {
    sessionStorage.setItem("CustomerId", data);
    sessionStorage.setItem("InvoiceId", data1);
    navigate("/lender/bill-lodging/invoice-details", { state: { data } });
  };

  const handleBillDetail = (data) => {
    sessionStorage.setItem("ReferenceBillId", data);
    navigate({ pathname: "/lender/bill-lodging/bill-deails" });
  };

  const handleUnregister = (data, data1, data2) => {
 //   console.log("invoiceId",data,"SellerId",data1,"manualInvoice",data2);
    sessionStorage.setItem("InvoiceId", data);
    sessionStorage.setItem("SellerId", data1);
    sessionStorage.setItem("digitalInvoice", data2);
    navigate({ pathname: "/lender/bill-lodging/unregistered_bill" });
  };

  const handlemanual = () => {
    sessionStorage.setItem("digitalInvoice","false");
    setTableQueryParams((prev) => ({ ...prev,tableOffset:0, search: "" }));
    setSearchByProformaID("");
    setTabView(2);
  };

  const handleDigi = () => {
    sessionStorage.setItem("digitalInvoice","true");
    setTableQueryParams((prev) => ({ ...prev,tableOffset:0, search: "" }));
    setSearchByProformaID("");
    setTabView(1);
  };

  return (
    <div className="d-flex flex-column gap-4">
      <Helmet>
        <title>Bill-Lodgement</title>
      </Helmet>
      <div className="bg-white admin-dashboard-card-container p-3">
        <div className="d-flex justify-content-between align-items-center flex-wrap px-2 gap-2 py-2">
          <span className="dashboard-content-title">List of Bills</span>

          <div className="search-container border border-secondary d-flex align-items-center ms-auto py-1 px-3 w-25">
            <input
              className="search-input-element d-flex flex-grow-1"
              type="text"
              placeholder="Search by Invoice Number"
              value={searchByProformaID}
              onChange={(e) => setSearchByProformaID(e.target.value)}
              // onKeyDown={handleSearchByProformaIDSubmit}
            />{" "}
            <BsSearch className="fs-5"  />
          </div>
        </div>
      </div>

      <div className="d-flex flex-column justify-content-between flex-wrap px-2">
        <div className="d-flex m-0 p-0">
          <span
            className={`dashboard-table-title ${tabView !== 1 && "dashboard-table-title-hover"
              } py-2 px-3 ${tabView === 1 && "active-table-data placeholder-wave"
              }`}
            onClick={() => handleDigi(1)} onKeyDown={ null }
          >
            Digital
          </span>

          <span
            className={`dashboard-table-title ${tabView !== 2 && "dashboard-table-title-hover"
              } py-2 px-3 ${tabView === 2 && "active-table-data placeholder-wave"
              }`}
            onClick={() => handlemanual(2)} onKeyDown={ null }
          >
            Manual
          </span>

          {/* <button
              onClick={() => handleDigi(1)}
              className={tabView === 1 ? "buttonActive" : "buttonUnActive"}
              style={{
                width: "fit-content",
                padding: "5px 20px",
              }}
            >
              Digital
            </button>
            <button
              onClick={() => handlemanual(2)}
              className={tabView === 2 ? "buttonActive" : "buttonUnActive"}
              style={{ width: "fit-content", padding: "5px 20px" }}
            >
              Manual
            </button> */}
        </div>
        <LenderInvoiceTable
          className="lender-anchor-and-lender-mapped-table1 placeholder-wave0"
          tableHeader={[
            { name: "CIF", sortValue: "addedDate" },
            { name: "Anchor", sortValue: "proformaId" },
            { name: "Counter Party", sortValue: "", },
            { name: "Invoice Number", sortValue: "expectedDateOfDelivery" },
            { name: "Invoice Amount", sortValue: "sellerName" },
            { name: "Compliance Check", sortValue: "paymentTerms" },
            {
              name: "Counter Party Confirmation",
              sortValue: "proformaStatus",
            },
            // { name: "Rejection Reason", sortValue: "proformaStatus" },
            { name: "Bill Reference Number", sortValue: "proformaStatus" },
            { name: "Status", sortValue: "proformaStatus" },
          ]}
          tableRows={proformaInvoiceTableRowData}
          materialRequestMetaData={proformInvoiceTableMetaData}
          tableQueryParams={tableQueryParams}
          setTableQueryParams={setTableQueryParams}
          isLoading={
            proFormaInvoiceTableResponse.isLoading ||
            proFormaInvoiceTableResponse1.isLoading
          }
          RedirectFunct={RedirectFunct}
          handleBillDetail={handleBillDetail}
          handleUnregister={handleUnregister}
          tabView={tabView}
        />
      </div>
    </div>
  );
};

export default LenderBillLogding;
