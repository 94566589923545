import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../layout/GenericLayout/Loader";
import { toastMessage } from "../../layout/GenericLayout/ToastMessage";
import {
  getUnregisteredBillDetail,
  postAccept,
} from "../../store/slices/lender.js";
import LenderDashboardTable from "../Lender/LenderTables/UnregisteredTable";
import {Helmet} from "react-helmet";

const UnregisteredBillDetail = ({ Route }) => {
  const navigate = useNavigate();
  console.log(Route);
  const dispatch = useDispatch();
  const [repeat, setreapeat] = useState(true);
  const[accept,setAccept]=useState(true);

  const customerInvoiceData = useSelector(
    (state) => state.lender.unregisteredData?.data?.items[0]
  );

  const acceptpo=useSelector((state)=>state.lender.acceptpo);

  const unregisteredData = useSelector(
    (state) => state.lender.unregisteredData
  );

  console.log(customerInvoiceData);
  useEffect(() => {
    if (repeat === true) {
      dispatch(getUnregisteredBillDetail());
      setreapeat(false);
    }
  }, [repeat, dispatch]);

  const handleAccept = (data) => {

    if(data==='Reject'){
      setAccept(false);
    }else{
      setAccept(true);
    }

    const body = {
      complianceCheck: 0,
      invoiceId: customerInvoiceData.invoiceId,
      manualInvoice: customerInvoiceData.manualInvoice,
      sellerId: customerInvoiceData.sellerId,
      status: data,
      rejectionRemarks: "",
    };

    dispatch(postAccept(body))
      .unwrap()
      .then((response) => {
        toastMessage(response.success, "success");
        navigate(-1);
      })
      .catch((error) => {
        console.log(error);
        toastMessage(error.message, "error");
      });
  };

  console.log(customerInvoiceData);
  return (
    <div className="d-flex flex-column gap-4 bg-white p-3 rounded">
      <Helmet>
                <title>Unregister-Bill</title>
            </Helmet>
      <div className="d-flex justify-content-between flex-column px-2 gap-2">
        <span className="dashboard-content-title">Bills</span>
        <div className="d-flex flex-column gap-4 shadow rounded">
          <div
            className=""
            style={{
              backgroundColor: "#aeb7e5",
              borderRadius: "7px",
              padding: "7px",
              height: "40px",
            }}
          >
            <p style={{ color: "white" }}>Bills Detail</p>
          </div>

          {unregisteredData.isLoading ? (
            <div className="py-5">
              {" "}
              <Loader />{" "}
            </div>
          ) : (
            <>
              <div className="d-flex flex-column" style={{ padding: "2rem" }}>
                <div>
                  <LenderDashboardTable
                    className="admin-dashboard-table"
                    // tableRowsCount={tableRowsCount}
                    tableHeader={[
                      "CIF ID",
                      "Anchor",
                      "Counter Party",
                      "Amount",
                      "Invoice Number",
                      "BOE",
                      // "LR",
                      "Status",
                    ]}
                    items={customerInvoiceData}
                    // totalValue={customerInvoiceData?.itemDetails[0]?.totalAmount}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-end align-items-center gap-4 px-5 pb-5">
                <button className="btn btn-dark" onClick={() => navigate(-1)}>
                  Back
                </button>
                <button
                  className="btn btn-success"
                  onClick={() => handleAccept("Approved")}
                >
                  {(acceptpo.isLoading&&accept) ? (
                  <span className="loader mx-auto"></span>
                    ) : (
                    "Accept"
                  )}
                </button>
                <button
                  className="btn btn-outline-danger"
                  onClick={() => handleAccept("Reject")}
                >
                  {(acceptpo.isLoading && !accept) ? (
                  <span className="loader mx-auto"></span>
                    ) : (
                    "Reject"
                  )}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default UnregisteredBillDetail;
