import { HiOutlineArrowNarrowDown } from "react-icons/hi";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useEffect, useState } from "react";
// import Modal from "../LenderPopUp/ModalPopUp"
import "../../../style/components/lender.scss";
import compass from "../../../assests/icons/tachometer-fast-alt.svg";
import file from "../../../assests/icons/Icon ionic-md-card.svg";

const LenderInvoiceTable = (props) => {
  const navigate = useNavigate();
  const [popUp, setPopUp] = useState(false);
  // console.log(props.totalValue)
  const [totalvalue, settotal] = useState(props.totalValue);
  // console.log(props.items)
  const totalPage = 5;
  const dateOptions = {
    day: "numeric",
    year: "numeric",
    month: "short",
  };

  const showModal = () => {
    setPopUp(false);
  };

  const timeOptions = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  const handlePopUp = () => {
    setPopUp(true);
  };

  console.log(props);

  return (
    <>
      <div className="table-container">
        <table className={props.className}>
          <thead>
            <tr>
              {props.tableHeader.map((eachHeader) => (
                <th key={eachHeader} scope="col">
                  {eachHeader}
                </th>
              ))}
            </tr>
          </thead>
          {props.items !== undefined && (
            <tbody>
              <tr scope="col">
                <td>{props.items.sellerId}</td>
                <td>{props.items.sellerName}</td>
                <td>{props.items.buyerName}</td>
                <td>{`₹ ${props.items.invoiceAmount}`}</td>
                <td>{`${props.items.manualInvoice?props.items.externalInvoiceId:props.items.invoiceId}`}</td>
                <td>
                  <a>View</a>
                </td>
                {/* <td>
                  <a>View</a>
                </td> */}
                <td>Unregistered</td>
              </tr>
            </tbody>
          )}
        </table>
        <div className="d-flex justify-content-end px-2"></div>
      </div>
    </>
  );
};

export default LenderInvoiceTable;
