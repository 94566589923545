import React, { useState } from "react";
import { BsSearch } from "react-icons/bs";
import { CiUndo } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PaymentPage from "../../Pages/PaymentPage";
import Loader from "../../layout/GenericLayout/Loader.js";
import ReusableMessageModal from "../../layout/GenericLayout/ReusableMessageModel";
import { getDisbursedData, postDiscount } from "../../store/slices/lender.js";
import {Helmet} from "react-helmet";

const GetPaymentUser = (props) => {
  const [payment, setPayment] = useState(props?.amount);
  const [notes, setNotes] = useState("");

  const  customerDetails={
    addedDate: props?.customerBillData?.addedDate,
    emailId: props?.customerBillData?.emailId,
    gst: props?.customerBillData?.gst,
    invoiceAmount: props?.customerBillData?.invoiceAmount,
    invoiceId: props?.customerBillData?.invoiceId,
    invoiceStatus: props?.customerBillData?.invoiceStatus,
    manualInvoice: props?.customerBillData?.manualInvoice,
    modifiedDate: props?.customerBillData?.modifiedDate,
    sellerId: props?.customerBillData?.sellerId
  }
  return (
    <div className="m-2 p-3 d-flex justify-content-center align-items-start flex-column gap-3">
      <h4>Enter the Payment Amount</h4>
      <div className="d-flex flex-column gap-2" style={{width:"100%"}}>
        <div> Amount:</div>
        <div className="d-flex">
          <input
            type="number"
            value={payment}
            placeholder="Enter the amount"
            className="w-100"
            onChange={(event) => {
              setPayment(event.target.value);
            }}
          />
          <div className="py-2"><CiUndo onClick={() => setPayment(props?.amount)} /></div>
        </div>
      </div>

      <div className="d-flex flex-column gap-2" style={{width:"95%"}}>
        <div>Additional Notes:</div>
        <input 
          type="text" 
          value={notes}
          className="w-100 p-1"
          onChange={(event) => {
            setNotes(event.target.value);
          }}  
        />
      </div>

      <div className="d-flex w-100 justify-content-end gap-5 my-3">
        <button
          type="button"
          className="btn btn-danger"
          onClick={props?.cancel}
        >
          Cancel
        </button>
        <PaymentPage
          closePayment={props?.cancel}
          handleSearch={props?.handleSearch}
          amount={payment}
          billReferenceNo={props?.customerBillData?.billReferenceNo}
          name={props?.customerBillData?.sellerName}
          email={props?.customerBillData?.emailId}
          mobile={props?.customerBillData?.mobileNumber}
          orderNote={notes}
          customerDetails={customerDetails}
        >
          <button type="button" className="btn btn-primary">
            Make Payment
          </button>
        </PaymentPage>
      </div>
    </div>
  );
};

const LenderDisbursed = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const disburshedData = useSelector((state) => state.lender.disburshedData);
  const [customerBillData, setcustomerBillData] = useState("");
  console.log(customerBillData);
  const [paymentAmount, setPaymentAmount] = useState();
  const [showPaymentWindow, setShowPaymentWindow] = useState(false);

  const toggleShowPaymentWindow = () => {
    setShowPaymentWindow(!showPaymentWindow);
  };

  const [search, setSearch] = useState("");
  const [discountData, setDiscount] = useState(true);

  const onChangeSearch = (e) => {
    setSearch(e.target.value);
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleSearch = () => {
    dispatch(getDisbursedData(search))
      .unwrap()
      .then((response) => {
        setcustomerBillData(response.items[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDiscount = () => {
    const body = {
      amountFinanced: customerBillData?.invoiceAmount,
      billReferenceNo: customerBillData?.billReferenceNo,
    };
    dispatch(postDiscount(body))
      .unwrap()
      .then((response) => {
        handleSearch();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleMakePayment = (amount) => {
    setShowPaymentWindow(true);
    setPaymentAmount(amount);
  };

  // console.log(customerBillData);
  return (
    <>
      {showPaymentWindow && (
        <ReusableMessageModal
          showModal={showPaymentWindow}
          toggleModal={toggleShowPaymentWindow}
          //  msgType={"alert"}
          noBtn={true}
          modalContent={
            <GetPaymentUser
              amount={paymentAmount}
              handleSearch={handleSearch}
              cancel={toggleShowPaymentWindow}
              customerBillData={customerBillData}
              // billReferenceNo={customerBillData?.billReferenceNo}
              // sellerId={customerBillData?.sellerId}
            />
          }
          modalSizeClass="modal-lg"
        />
      )}

      <div
        className="d-flex flex-column gap-4"
        style={{
          backgroundColor: "white",
          padding: "2rem",
          borderRadius: "10px",
        }}
      >
         <Helmet>
                <title>Discounting</title>
            </Helmet>
        <div className="d-flex justify-content-between flex-column px-2 gap-2">
          <span className="dashboard-content-title">Bill Discounting</span>
          <label
            style={{
              width: "100%",
              marginTop: "2rem",
              fontWeight: "bold",
              fontSize: "18px",
            }}
          >
            Enter Bill Reference Number
          </label>
          <div className="search-container d-flex align-items-center py-1 px-3 col-12 col-sm-4 ">
            <input
              className="search-input-element d-flex flex-grow-1"
              type="text"
              placeholder="Search"
              onChange={onChangeSearch}
              onKeyPress={onChangeSearch}
              value={search}
            />{" "}
            <BsSearch className="fs-5" onClick={handleSearch} />
          </div>

          {disburshedData.isLoading ? (
            <div className="py-5">
              <Loader />
            </div>
          ) : (
            customerBillData !== "" && (
              <div className="details-container container p-3">
                <div className="row gy-md-4 gy-2">
                  <div className="kyc-fields-container col-12  col-md-4">
                    <label
                      htmlFor="billing"
                      className="kyc-label-text form-label fw-semibold"
                    >
                      CIF
                    </label>
                    <input
                      disabled
                      id="billing"
                      type="text"
                      name="billing"
                      value={customerBillData?.sellerId}
                      className="kyc-input-field form-control"
                    />
                  </div>

                  <div className="kyc-fields-container col-12  col-md-4">
                    <label
                      htmlFor="shipping"
                      className="kyc-label-text form-label fw-semibold"
                    >
                      Anchor
                    </label>
                    <input
                      id="shipping"
                      disabled
                      type="text"
                      name="shipping"
                      className="kyc-input-field form-control"
                      value={customerBillData?.sellerName}
                    />
                  </div>
                  <div className="kyc-fields-container col-12  col-md-4">
                    <label
                      htmlFor="shipping"
                      className="kyc-label-text form-label fw-semibold"
                    >
                      Other Party
                    </label>
                    <input
                      id="shipping"
                      disabled
                      type="text"
                      name="shipping"
                      className="kyc-input-field form-control"
                      placeholder="Other Party"
                      value={customerBillData?.otherParty}
                    />
                  </div>
                </div>
                <div className="row gy-md-4 mt-2 gy-2">
                  <div className="kyc-fields-container col-12  col-md-4">
                    <label
                      htmlFor="billing"
                      className="kyc-label-text form-label fw-semibold"
                    >
                      Bill Type
                    </label>
                    <input
                      disabled
                      id="billing"
                      type="text"
                      name="billing"
                      className="kyc-input-field form-control"
                      placeholder="Bill Type"
                      value={customerBillData?.billType}
                    />
                  </div>

                  <div className="kyc-fields-container col-12  col-md-4">
                    <label
                      htmlFor="shipping"
                      className="kyc-label-text form-label fw-semibold"
                    >
                      Currency
                    </label>
                    <input
                      id="shipping"
                      disabled
                      type="text"
                      name="shipping"
                      className="kyc-input-field form-control"
                      value={customerBillData?.currency}
                    />
                  </div>
                  <div className="kyc-fields-container col-12  col-md-4">
                    <label
                      htmlFor="shipping"
                      className="kyc-label-text form-label fw-semibold"
                    >
                      Invoice Amount (₹)
                    </label>
                    <input
                      id="shipping"
                      disabled
                      type="text"
                      name="shipping"
                      className="kyc-input-field form-control"
                      value={customerBillData?.invoiceAmount}
                    />
                  </div>
                </div>
                <div className="row gy-md-4 mt-2 gy-2">
                  <div className="kyc-fields-container col-12  col-md-4">
                    <label
                      htmlFor="billing"
                      className="kyc-label-text form-label fw-semibold"
                    >
                      Margin (%)
                    </label>
                    <input
                      disabled
                      id="billing"
                      type="text"
                      name="billing"
                      className="kyc-input-field form-control"
                      value={customerBillData?.margin}
                    />
                  </div>

                  <div className="kyc-fields-container col-12  col-md-4">
                    <label
                      htmlFor="shipping"
                      className="kyc-label-text form-label fw-semibold"
                    >
                      Amount Financed (₹)
                    </label>
                    <input
                      id="shipping"
                      disabled
                      type="text"
                      name="shipping"
                      className="kyc-input-field form-control"
                      placeholder="Amount Financed"
                      value={customerBillData?.amountFinanced}
                    />
                  </div>
                  <div className="kyc-fields-container col-12  col-md-4">
                    <label
                      htmlFor="shipping"
                      className="kyc-label-text form-label fw-semibold"
                    >
                      Interest Rate (%)
                    </label>
                    <input
                      id="shipping"
                      disabled
                      type="text"
                      name="shipping"
                      className="kyc-input-field form-control"
                      value={customerBillData?.interestRate}
                    />
                  </div>
                </div>
                <div className="row gy-md-4 mt-2 gy-2">
                  <div className="kyc-fields-container col-12  col-md-4">
                    <label
                      htmlFor="billing"
                      className="kyc-label-text form-label fw-semibold"
                    >
                      Tenor(Days)
                    </label>
                    <input
                      disabled
                      id="billing"
                      type="text"
                      name="billing"
                      className="kyc-input-field form-control"
                      value={customerBillData?.tenure}
                    />
                  </div>

                  <div className="kyc-fields-container col-12  col-md-4">
                    <label
                      htmlFor="shipping"
                      className="kyc-label-text form-label fw-semibold"
                    >
                      Interest Amount (₹)
                    </label>
                    <input
                      id="shipping"
                      disabled
                      type="text"
                      name="shipping"
                      className="kyc-input-field form-control"
                      placeholder="Interest Amount"
                      value={customerBillData?.interestAmount}
                    />
                  </div>
                  <div className="kyc-fields-container col-12  col-md-4">
                    <label
                      htmlFor="shipping"
                      className="kyc-label-text form-label fw-semibold"
                    >
                      Processing Fees (%)
                    </label>
                    <input
                      id="shipping"
                      disabled
                      type="text"
                      name="shipping"
                      className="kyc-input-field form-control"
                      placeholder="Processing Fee"
                      value={customerBillData?.fees}
                    />
                  </div>
                </div>

                <div className="row gy-md-4 mt-2 gy-2">
                  <div className="kyc-fields-container col-12  col-md-4">
                    <label
                      htmlFor="billing"
                      className="kyc-label-text form-label fw-semibold"
                    >
                      Platform Fees (%)
                    </label>
                    <input
                      disabled
                      id="billing"
                      type="text"
                      name="billing"
                      className="kyc-input-field form-control"
                      value={customerBillData?.platformFee}
                    />
                  </div>

                  <div className="kyc-fields-container col-12  col-md-4">
                    <label
                      htmlFor="shipping"
                      className="kyc-label-text form-label fw-semibold"
                    >
                      Stamping Charges (%)
                    </label>
                    <input
                      id="shipping"
                      disabled
                      type="text"
                      name="shipping"
                      className="kyc-input-field form-control"
                      value={customerBillData?.stampingCharges}
                    />
                  </div>
                  <div className="kyc-fields-container col-12  col-md-4">
                    <label
                      htmlFor="shipping"
                      className="kyc-label-text form-label fw-semibold"
                    >
                      Insurance Charges (%)
                    </label>
                    <input
                      id="shipping"
                      disabled
                      type="text"
                      name="shipping"
                      className="kyc-input-field form-control"
                      value={customerBillData?.insuranceCharges}
                    />
                  </div>
                </div>

                <div className="row gy-md-4 mt-2 gy-2">
                  <div className="kyc-fields-container col-12  col-md-4">
                    <label
                      htmlFor="billing"
                      className="kyc-label-text form-label fw-semibold"
                    >
                      Other Charges (%)
                    </label>
                    <input
                      disabled
                      id="billing"
                      type="text"
                      name="billing"
                      className="kyc-input-field form-control"
                      value={customerBillData?.otherChanrges}
                    />
                  </div>

                  {(customerBillData?.invoiceStatus === "Disbursed" ||
                    customerBillData?.invoiceStatus === "Discounted" ||
                    customerBillData?.invoiceStatus==="Partial disbursement initiated") && (
                    <div className="kyc-fields-container col-12  col-md-4">
                      <label
                        htmlFor="shipping"
                        className="kyc-label-text form-label fw-semibold"
                      >
                        Disbursing Amount (₹)
                      </label>
                      <input
                        id="shipping"
                        disabled
                        type="text"
                        name="shipping"
                        className="kyc-input-field form-control"
                        value={customerBillData?.disbursingAmount}
                      />
                    </div>
                  )}

                  {(customerBillData?.invoiceStatus === "Disbursed" ||
                    customerBillData?.invoiceStatus === "Discounted" ||
                    customerBillData?.invoiceStatus==="Partial disbursement initiated") && (
                    <div className="kyc-fields-container col-12  col-md-4">
                      <label
                        htmlFor="shipping"
                        className="kyc-label-text form-label fw-semibold"
                      >
                        Disbursing Remaining Amount (₹)
                      </label>
                      <input
                        id="shipping"
                        disabled
                        type="text"
                        name="shipping"
                        className="kyc-input-field form-control"
                        value={customerBillData?.disbursingRemainingAmount}
                      />
                    </div>
                  )}

                  <div
                    className="d-flex justify-content-end gap-2 align-items-center gap-4"
                    style={{ padding: "2rem" }}
                  >
                    <div style={{}}>
                      {customerBillData.invoiceStatus === "Lodged" ||
                      customerBillData.invoiceStatus === "Unregistered" ? (
                        <button
                          className="btn btn-success px-5"
                          onClick={handleDiscount}
                        >
                          Discount
                        </button>
                      ) : (
                        <button className="btn btn-success px-5">
                          Discounted
                        </button>
                      )}
                    </div>
                    <div style={{}}>
                      {/* <PaymentPage amount={customerBillData?.outstandingAmount}> */}
                      <button
                        className="btn btn-outline-primary px-5"
                        onClick={() =>
                          handleMakePayment(
                            customerBillData?.disbursingRemainingAmount
                          )
                        }
                      >
                        Disburse
                      </button>
                      {/* </PaymentPage> */}
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </>
  );
};

export default LenderDisbursed;
