import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Loader from "../../layout/GenericLayout/Loader";
import {
  getCountryPartyUserDetails,
  getCountryPartyUserLimit,
} from "../../store/slices/anchor";
import AnchorCounterPartyTable from "./AnchorTables/AnchorCounterPartyTable";
import {Helmet} from "react-helmet";

/* PARENT COMPONENT */
const AnchorCounterParty = () => {
  /* FUNCTION DECLARATIONS */
  const dispatch = useDispatch();

  /* USESELECTOR */
  const counterPartyUserLimitData = useSelector(
    (state) => state.anchor.counterPartyUserLimitData
  );

  console.log("counterPartyUserLimitData : ", counterPartyUserLimitData);

  const counterPartyUserTableData = useSelector(
    (state) => state.anchor.counterPartyUserTableData
  );

  /* USESTATES */
  const [activeTableData, setActiveTableData] = useState("approved");
  const [activeTableRows, setActiveTableRows] = useState([]);

  /* USEEFFECTS */
  useEffect(() => {
    dispatch(getCountryPartyUserLimit());
    dispatch(getCountryPartyUserDetails())
      .unwrap()
      .then((response) => {
        setActiveTableRows(response.success);
      })
      .catch((error) => {
        console.log("error : ", error);
      });
  }, [dispatch]);

  /* EVENT LISTENERS */

  const onClickActiveTable = (value) => {
    setActiveTableData(value);
  };

  /* FILTER DATA */
  const filterTableRows = activeTableRows.filter(
    (eacHRows) => eacHRows.distributorStatus === activeTableData
  );

  const numFormater = (data) =>
    new Intl.NumberFormat("en-IN", { maximumSignificantDigits: 3 }).format(
      data
    );

  return (
    <div className="d-flex flex-column gap-4">
      <Helmet>
                <title>Counter-Party</title>
            </Helmet>
      <div className="bg-white admin-dashboard-card-container d-flex justify-content-between p-3">
        <div className="d-flex gap-2">
          <span className="dashboard-content-title">Name : </span>
          <span className="dashboard-content-title text-primary">
            {counterPartyUserLimitData.data?.success?.companyFullName || "N/A"}
          </span>
        </div>
        <div className="d-flex gap-3">
          <div className="d-flex align-items-center gap-2">
            <span className="dashboard-content-title">Approved Limit:</span>
            <span className="dashboard-content-title text-success">
              ₹
              {numFormater(
                counterPartyUserLimitData.data?.success?.maxLimit
              ) || "N/A"}
            </span>
          </div>
          <div className="d-flex align-items-center gap-2">
            <span className="dashboard-content-title"> Available Limit: </span>
            <span className="dashboard-content-title text-danger">
              {" "}
              ₹
              {numFormater(counterPartyUserLimitData.data?.success?.availableAmnt) ||
                "N/A"}
            </span>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between flex-wrap px-2 gap-2">
        <span className="dashboard-content-title">Counter Party Details</span>
      </div>

      <div className="d-flex flex-column">
        <div className="d-flex">
          <span
            className={`dashboard-table-title ${
              activeTableData !== "approved" && "dashboard-table-title-hover"
            } py-2 px-3 ${
              activeTableData === "approved" &&
              "active-table-data placeholder-wave"
            }`}
            onClick={() => onClickActiveTable("approved")}
          >
            Approved
          </span>
          <span
            className={`dashboard-table-title ${
              activeTableData !== "pending" && "dashboard-table-title-hover"
            } py-2 px-3 ${
              activeTableData === "pending" &&
              "active-table-data placeholder-wave"
            }`}
            onClick={() => onClickActiveTable("pending")}
          >
            Pending
          </span>
          <span
            className={`dashboard-table-title ${
              activeTableData !== "rejected" && "dashboard-table-title-hover"
            } py-2 px-3 ${
              activeTableData === "rejected" &&
              "active-table-data placeholder-wave"
            }`}
            onClick={() => onClickActiveTable("rejected")}
          >
            Rejected
          </span>
        </div>
        <div>
          {counterPartyUserTableData.isLoading ? (
            <Loader className="my-5" />
          ) : (
            <AnchorCounterPartyTable
              className="admin-dashboard-table"
              tableHeader={[
                "Name",
                "Email ID",
                "Approved Limit (₹)",
                "Available Limit (₹)",
                "Status",
                "Application Status",
                "Anchor Status",
              ]}
              tableRows={filterTableRows}
              activeTableData={activeTableData}
              // distributorId={
              //   counterPartyUserLimitData.data?.success?.customerId
              // }
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AnchorCounterParty;
