import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { BsSearch } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { getBulkInvoiceTable } from "../../../store/slices/anchor";
import ManualInvoiceTable from "./InvoiceManualTable";
import { toastMessage } from "../../../layout/GenericLayout/ToastMessage";


/* PARENT COMPONENT */
export const BuyerBatchInvoice = () => {
  const dispatch = useDispatch();

  /* USESELECT HOOKS */
  const manualInvoiceTableData = useSelector(
    (state) => state.anchor.bulkInvoiceTableData
  );
  /* USESTATES */

  const [invoiceMetaData, setInvoiceMetaData] = useState(null);
  const [invoiceTableRows, setInvoiceTableRows] = useState([]);
  const isHovered = false;


  /* TABLE QUERY PARAMS */
  const [tableQueryParams, setTableQueryParams] = useState({
    tableOffset: 0,
    tableLimit: 5,
    tableSort: "invoiceDate",
    tableOrder: -1,
    currentPage: 1,
  });

  useEffect(() => {
    dispatch(getBulkInvoiceTable({tableQueryParams}))
      .unwrap()
      .then(data => {
        console.log("manual invoice", data);
        setInvoiceTableRows(data?.items);
        setInvoiceMetaData(data?.metadata);
      })
      .catch(err => {
        console.log("error manual invoice", err?.message);
      });
  }, [ dispatch, tableQueryParams, ]);

  useEffect(() => {
    if (manualInvoiceTableData.data) {
      setInvoiceTableRows(manualInvoiceTableData.data.items);
      setInvoiceMetaData(manualInvoiceTableData.data.metadata);
    }
  }, [manualInvoiceTableData]);

  const onRefreshTable = () => {
    dispatch(getBulkInvoiceTable({tableQueryParams}))
      .unwrap()
      .then(data => {
        console.log("manual invoice", data);
        setInvoiceTableRows(data?.items);
        setInvoiceMetaData(data?.metadata);
      })
      .catch(err => {
        toastMessage(err?.message, "error");
      });
  };

  const onChangeSearch = (event) => {
    // setSearchInvoiceValue((prev, ))
    // console.log(event.target.value)
    const tabQuerySearch = {
      ...tableQueryParams,
      tableOffset: 0,
      tableLimit: 5,
      search: event.target.value,
    };

    dispatch(
      getBulkInvoiceTable({
        tabQuerySearch,
      })
    );
  };



  return (
    <>

      <div className="d-flex flex-column gap-4" style={{ overflowY: "auto" }}>
        <Helmet>
            <title>Batch Invoice | CounterParty</title>
        </Helmet>
        <div className="bg-white admin-dashboard-card-container p-3">
          <div className="d-flex justify-content-between align-items-center flex-wrap px-2 gap-2 py-2">
            <span className="dashboard-content-title">List of Batch Invoices</span>
          </div>

          <div className="d-flex flex-column flex-sm-row align-items-center mt-2 mb-1">
            <div className="d-flex flex-column justify-content-center gap-3 col-12 col-sm-6">
              <div className="search-container d-flex align-items-center py-1 px-3 col-12 col-sm-8">
                <input
                  className="search-input-element d-flex flex-grow-1"
                  type="text"
                  placeholder="Search with Batch No."
                  onChange={onChangeSearch}
                // value={search}
                />{" "}
                <BsSearch className="fs-5" />
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex flex-column">

          <div>
            <ManualInvoiceTable
              tableHeader={
                sessionStorage.getItem("productCategory") !==
                  "Purchase Invoice Discounting"
                  ? [
                      { name: "Batch Date", sortValue: "" },
                      { name: "Batch Number", sortValue: "" },
                      { name: "Download File", sortValue: "" },
                      { name: "Bulk Excel File", sortValue: "" },
                      { name: "Total Amount (₹)	", sortValue: "" },
                      { name: "Due Date", sortValue: "" },
                      { name: "Other Document(.zip)", sortValue: "" },
                      { name: "Status", sortValue: "" },
                    ]
                    : [
                      { name: "Batch Date", sortValue: "" },
                      { name: "Batch Number", sortValue: "" },
                      { name: "Download File", sortValue: "" },
                      { name: "PO Number", sortValue: "" },
                      { name: "Total Amount (₹)	", sortValue: "" },
                      { name: "Due Date", sortValue: "" },
                      { name: "Bulk Excel File", sortValue: "" },
                      { name: "Other Document(.zip)", sortValue: "" },
                    ]
              }
              tableRows={invoiceTableRows}
              invoiceMetaData={invoiceMetaData}
              tableQueryParams={tableQueryParams}
              setTableQueryParams={setTableQueryParams}
              isLoading={manualInvoiceTableData.isLoading}
              onRefreshTable={onRefreshTable}
              className="buyer-table"
            />
          </div>
        </div>
      </div>
    </>
  );
};

