import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "../../store/slices/admin";
import Loader from "../../layout/GenericLayout/Loader";
import AdminUsersTable from "./AdminTables/AdminUsersTable";
import { BsSearch } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import {Helmet} from "react-helmet";

const AdminUsers = () => {
  const dispatch = useDispatch();
  const usersData = useSelector((state) => state.admin.usersData);
  const [usersDataTableRows, setUserDataTableRows] = useState([]);
  const [ filteredData, setFilteredData ] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  useEffect(() => {
    if (usersData.error) {
      // error logic needs to be written
    }
  }, [usersData.error]);

  useEffect(() => {
    if (usersData.data) {
      setUserDataTableRows(usersData.data.success);
      setFilteredData(usersData?.data?.success)
    }
  }, [usersData.data]);

  const onChangeSearch = (event) => {
    const value = event.target.value;
    setSearch(value);

    if (event?.target?.value === "") {
      setFilteredData(usersDataTableRows);
      return;
    }

    console.log(usersDataTableRows);
    const filterResult = usersDataTableRows.filter(
      (eachRows) => eachRows.customerId.includes(value) || eachRows?.companyFullName.includes(value)
    );

    setFilteredData(filterResult);
    // setUserDataTableRows(filterResult);
  };

  return (
    <div className="d-flex flex-column gap-4">
      <Helmet>
                <title>Users</title>
            </Helmet>
      <div className="bg-white admin-dashboard-card-container px-3 pt-3 d-flex flex-column gap-2 pb-5">
        <div className="d-flex justify-content-between flex-wrap px-2 gap-2">
          <span className="dashboard-content-title">User Details</span>
          <div className="search-container d-flex align-items-center py-1 px-3 col-12 col-sm-4 ">
            <input
              className="search-input-element d-flex flex-grow-1"
              type="text"
              placeholder="Search"
              onChange={onChangeSearch}
              value={search}
            />{" "}
            <BsSearch className="fs-5" />
          </div>
        </div>
        {usersData.isLoading ? (
          <Loader className="my-5" />
        ) : (
          <AdminUsersTable
            className="admin-users-data-table"
            tableHeader={[
              "Customer ID",
              "Name",
              "Type",
              "Score",
              "CAM",
              "Status",
              "Admin Remarks",
            ]}
            tableRows={filteredData}
          />
        )}
      </div>
    </div>
  );
};

export default AdminUsers;
