import { HiOutlineArrowNarrowDown } from "react-icons/hi";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import { BiSort } from "react-icons/bi";
import { useState } from "react";
import Loader from "../../../../layout/GenericLayout/Loader";
import TablePagination from "../../../../layout/GenericLayout/TablePagination";
import { Link } from "react-router-dom";

const ManualPurchaseOrderTable = (props) => {
  const updatePage = (currentPage) => {
    const newOffSet = props.tableQueryParams.tableLimit * (currentPage - 1);
    props.setTableQueryParams((prev) => ({
      ...prev,
      tableOffset: newOffSet,
      currentPage,
    }));
  };

  const onClickActiveSort = (activeSortValue) => {
    if (activeSortValue !== "") {
      let order = -1;
      if (activeSortValue === props.tableQueryParams.tableSort) {
        if (props.tableQueryParams.tableOrder === -1) {
          order = 1;
        }
      }
      props.setTableQueryParams((prev) => ({
        ...prev,
        tableSort: activeSortValue,
        tableOrder: order,
      }));
    }
  };

  const onClickViewMoreTableData = () => {
    let newLimit = props.tableQueryParams.tableLimit;
    if (newLimit === 5) {
      newLimit += 5;
    } else {
      newLimit -= 5;
    }
    props.setTableQueryParams((prev) => ({
      ...prev,
      tableLimit: newLimit,
    }));
  };

  return (
    <>
      <div className="table-container">
        <table className={props.className}>
          <thead>
            <tr>
              {props.tableHeader.map((eachHeader) => (
                <th
                  key={eachHeader.name}
                  scope="col"
                  role={
                    eachHeader.sortValue === ""
                      ? eachHeader.sortValue
                      : "button"
                  }
                  className={`${
                    props.tableQueryParams.tableSort === eachHeader.sortValue &&
                    eachHeader.sortValue !== "" &&
                    "active-sort-text"
                  }`}
                  onClick={() => onClickActiveSort(eachHeader.sortValue)}
                >
                  {eachHeader.name}
                  {eachHeader.sortValue !== "" && <BiSort />}
                </th>
              ))}
            </tr>
          </thead>
          {props.materialRequestMetaData?.paging?.total<0?(<div className="no-data text-danger">Oops! there is no data to show. Please check back later</div>):null} 
          {props.isLoading ? (
            <tbody>
              <tr>
                <td colSpan={7}>
                  <Loader className="my-5" />
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              {props.tableRows.map((eachRows, index) => (
                <tr key={eachRows.poId} scope="col">
                  <td>{eachRows.addedDate.slice(0, 10)}</td>
                  <Link
                    className="text-decoration-none"
                    to={eachRows.poFileUrl}
                    target="_blank"
                  >
                    <td
                      style={{
                        color: "#aeb7e5",
                        cursor: "pointer",
                      }}
                      // onClick={() => onClickPOId(eachRows.poFileUrl)}
                    >
                      {eachRows.poId}
                    </td>
                  </Link>
                  <td>{eachRows.buyerName}</td>
                  {/* <td>{eachRows.sellerName}</td> */}
                  <td>{eachRows.totalAmount}</td>
                  <td>{eachRows.invoiceStatus}</td>
                  <td
                    className={`text-capitalize ${
                      eachRows.lenderStatus == "rejected" && "text-danger"
                    } ${
                      eachRows.lenderStatus == "approved" && "text-success"
                    } ${eachRows.lenderStatus == "new" && "text-info"}`}
                  >
                    {eachRows.lenderStatus}
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>

      {!props.isLoading && props.materialRequestMetaData?.paging?.total>5 && (
          <div className="d-flex justify-content-end px-2">
            <span
              onClick={onClickViewMoreTableData}
              className="d-flex align-items-center gap-1 my-3 view-more-text"
            >
              {props.tableQueryParams.tableLimit === 5
                ? props.tableQueryParams.tableOffset+props.tableQueryParams.tableLimit<props.materialRequestMetaData?.paging?.total?"View More ":""
                : props.tableQueryParams.tableOffset+props.tableQueryParams.tableLimit<props.materialRequestMetaData?.paging?.total?"View Less ":""
              }
              <HiOutlineArrowNarrowDown
                className={`fs-5 ${
                  props.tableQueryParams.tableLimit === 5
                  ? props.tableQueryParams.tableOffset+props.tableQueryParams.tableLimit<props.materialRequestMetaData?.paging?.total? "down-arrow":"d-none"
                  : props.tableQueryParams.tableOffset+props.tableQueryParams.tableLimit<props.materialRequestMetaData?.paging?.total? "up-arrow":"d-none"
                }`}
              />
            </span>
          </div>
        )}
        
      <div className="d-flex align-items-center gap-2 page-data-text-container mt-3 px-3 w-100">
        <TablePagination
          pageSize={props.tableQueryParams.tableLimit}
          onUpdatePage={updatePage}
          current={props.tableQueryParams.currentPage}
          total={props.materialRequestMetaData?.paging?.total}
        />
      </div>
    </>
  );
};

export default ManualPurchaseOrderTable;
