import { Fragment, useContext, useEffect, useState } from "react";
import { GenericCamTableComponent } from "../../../../GenericComponent/Table/tableComponent";
import { useSelector } from "react-redux";
import { AdminCamContext } from "../../../../context/admin/cam/adminCamContext";
import { AiFillDelete } from "react-icons/ai";
import {AdminKycActions} from "../../../../context/admin/cam/actionTypes";
/**
 * @type { { value: string, rowspan?: number, colspan?: number }[][] }
 */
const AdminCamTransactionDetailsTableHeaders = [
	[
		{ value: "Entity Name" },
		{ value: "Exposure (Rs. in lacs)" },
		{ value: "Current Utlization (Rs. in lacs)" },
		{ value: "Security Value(Rs.in lacs)" },
		{ value: "ROI"}
	],
];
export const AdminCamGroupExposure = () => {
	// const [reduxState, setReduxState] = useState([]);
	const [ newGroupExposureData, setNewGroupExposureData ] = useState([]);
	const [total,setTotal]=useState();
	
	const additionalReduxState = useSelector(state=>state?.admin?.camByEmailIdData?.data?.data?.camDetails?.groupExposure);
	const { editEnabled,groupExposureStateDispatch } = useContext(AdminCamContext); 

	useEffect(()=>{
		console.log("cam group", additionalReduxState[0]);
		setNewGroupExposureData(additionalReduxState[0]?.data);
		setTotal(additionalReduxState[0]?.total);
	},[ additionalReduxState ]);

	useEffect(() => {
		groupExposureStateDispatch({ type: AdminKycActions.GROUP_EXPOSURE.UPDATE_DETAILS, payload: {newGroupExposureData,total} });
	}, [ newGroupExposureData, total ]);

	const handleDelete = (index) => {
		setNewGroupExposureData(prev => [...prev?.slice(0, index), ...prev?.slice(index + 1)]);
	};

	const handleChangeValue = (e, index) => {
		const { name, value } = e.target;
		let newArray=[];

		if(Array.isArray(newGroupExposureData)){
			newArray=newGroupExposureData?.map((item, i)=>{
				if(i===index){
					return {...item,[name]:value}
				}
				return item
			})	
		}
		setNewGroupExposureData(newArray);
	};


	useEffect(()=>{
		const newTotal={entityName: 0, exposure: 0, currentUtilization: 0, securityValues: 0,rateOfInterest:0};
		newGroupExposureData?.map((item)=>{
			// newTotal.entityName=+newTotal.entityName+ +(item?.entityName);
			newTotal.exposure=+newTotal.exposure+ +(item?.exposure);
			newTotal.currentUtilization=+newTotal.currentUtilization+ +(item?.currentUtilization);
			newTotal.securityValues=+newTotal.securityValues+ +(item?.securityValues);
			newTotal.rateOfInterest=+newTotal.rateOfInterest+ +(item?.rateOfInterest);
		})
		setTotal(newTotal);
	},[newGroupExposureData])


	const handleAddNewRow = () => {
		setNewGroupExposureData([...newGroupExposureData, {
			entityName: "",
			exposure: "",
			currentUtilization: "",
			securityValues: "",
			rateOfInterest: "",
		}]);
		console.log(newGroupExposureData)
	};

	return (
		<Fragment>
			<GenericCamTableComponent handleAddNewRow={ handleAddNewRow } addNewRows={ true } tableHeaders={ AdminCamTransactionDetailsTableHeaders }>
				{
					!editEnabled ?
					newGroupExposureData?.length!=0 && newGroupExposureData?.map((val,indx)=>
						<tr key={"grpExp" + indx}>
							<td className="text-center">{val.entityName}</td>
							<td className="numbrLeft">{val.exposure}</td>
							<td className="numbrLeft">{val.currentUtilization}</td>
							<td className="numbrLeft">{val.securityValues}</td>
							<td className="numbrLeft">{val.rateOfInterest}</td>
						</tr>
					) : 
					newGroupExposureData?.length !==0 && newGroupExposureData?.map((val,indx)=>
						<tr key={"trans"+indx}>
							<td className="text-center">
								<input className="form-control" type="text" name="entityName" value={ val?.entityName } onChange={ e => handleChangeValue(e, indx) } />
							</td>
							<td className="numbrLeft">
								<input className="form-control" type="number" name="exposure" value={ val?.exposure } onChange={ e => handleChangeValue(e, indx) } />
							</td>
							<td className="numbrLeft">
								<input className="form-control" type="number" name="currentUtilization" value={ val?.currentUtilization } onChange={ e => handleChangeValue(e, indx) } />
							</td>
							<td className="numbrLeft">
								<input className="form-control" type="number" name="securityValues" value={ val?.securityValues } onChange={ e => handleChangeValue(e, indx) } />
							</td>
							<td className="numbrLeft">
								<input className="form-control" type="number" name="rateOfInterest" value={ val?.rateOfInterest } onChange={ e => handleChangeValue(e, indx) } />
							</td>
							<td className="text-center">
								<button className="btn" onClick={ () => handleDelete(indx) }>
									<AiFillDelete color="red" />
								</button>
							</td>
						</tr>
					)
				}
				<tr>
					<td className="text-center fw-bold">Total (₹)</td>
					<td className="numbrLeft">{total?.exposure}</td>
					<td className="numbrLeft">{total?.currentUtilization}</td>
					<td className="numbrLeft">{total?.securityValues}</td>
					<td className="numbrLeft">{total?.rateOfInterest}</td>
				</tr>
			</GenericCamTableComponent>
		</Fragment>
	);
};