import React, { useEffect, useState } from "react";
import { BsPlusCircleFill, BsSearch } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import ReusableModal from "../../layout/GenericLayout/ReusableModal";
import { toastMessage } from "../../layout/GenericLayout/ToastMessage";
import {
  getApprovedRetailerList,
  getManualPurchaseOrderTable,
  updateAddManualPO,
} from "../../store/slices/anchor";
import ManualPurchaseOrderTable from "./AnchorTables/PurchaseOrderTables.js/ManualPurchaseOrderTable";
import { Helmet } from "react-helmet";

/* *****ON REFRESH IS NOT IMPLEMENTED, TABLE WON'T UPDATE AFTER ADDING MANUAL PO***** */

/* MODALS OR CHILDREN COMPONENTS */
const PurchaseOrderAddModal = ({ togglePurchaseOrderAddModal, retailerId }) => {
  /* FUNCTION DECLARATIONS */
  const dispatch = useDispatch();

  /* USESTATES */
  const [addPODetailsFields, setAddPODetailsFields] = useState({});
  const [allCouterPartyList, setAllCounterPartyList] = useState([]);
  const [isError, setIsError] = useState({});


  const addInvReferenceState = useSelector((state) => state.anchor.addManualPO);

  /* USEEFFECTS */
  useEffect(() => {
    dispatch(getApprovedRetailerList())
      .unwrap()
      .then((response) => {
        const allCounterPartyls = [];
        response.success.map((eachData) => {
          allCounterPartyls.push({
            name: eachData.name,
            retailerId: eachData.retailerId,
          });
        });
        setAllCounterPartyList(allCounterPartyls);
        console.log(allCounterPartyls);
      })
      .catch((error) => {
        console.log("error : ", error);
      });
  }, [dispatch]);

  /* EVENT LISTENERS */
  const onChangeAddPODetailsFields = (event) => {
    const name = event.target.name;
    const value =
      name === "e_INV_Reference_No" ? event.target.files[0] : event.target.value;

    setAddPODetailsFields((prev) => ({ ...prev, [name]: value }));
  };

  const onBlurAddPODetailsFields = (event) => {};

  
  const handleBlurInvoiceAmount = (event) => {
    const value = parseInt(event?.target.value);

    if (isNaN(value) || value <= 0) {
      setIsError((prev) => ({
        ...prev,
        invoiceAmount: "Amount should be greater than 0",
      }));
    } else {
      setIsError((prev) => ({ ...prev, invoiceAmount: "" }));
    }
  };

  const validInvoiceNo = /^[a-zA-Z0-9\/\.\-]*$/;

  const onSubmitRMAddDetails = (event) => {
    event.preventDefault();

    console.log("------------------------", addPODetailsFields.counterParty);
    if (
      addPODetailsFields?.counter_Party === "Open this select menu" ||
      addPODetailsFields?.counter_Party === undefined
    ) {
      toastMessage("Select Counter Party", "error");

    }else if(!validInvoiceNo.test(addPODetailsFields?.INV_Reference_Number)){
      toastMessage("Invalid INV reference number format. It should not contain special characters other than '/', '.', and '-'","error");
      
    } else {
      const body = new FormData();
      body.append("po_doc", addPODetailsFields.e_INV_Reference_No);
      body.append("po_date", addPODetailsFields.INV_Reference_Date);
      body.append("poNumber", addPODetailsFields.INV_Reference_Number);
      body.append("finalAmount", addPODetailsFields.final_Amount);
      body.append("buyerId", addPODetailsFields.counter_Party);

      dispatch(updateAddManualPO(body))
        .unwrap()
        .then((response) => {
          toastMessage(response.success, "success");
          // onRefreshTable();
          togglePurchaseOrderAddModal();
        })
        .catch((error) => {
          toastMessage(error.message, "error");
        });
    }
  };

  console.log(addPODetailsFields);

  return (
    <>
      {/* {relationshipManagerAddData.isLoading && (
        <div className="blur-loader-container">
          <Loader />
        </div>
      )} */}
      <form className="" onSubmit={onSubmitRMAddDetails}>
        <span className="fs-4 fw-semibold">Add INV Reference Number</span>
        <div className="details-container container mt-4">
          <div className="row gy-md-4 gy-2">
            <div className="kyc-fields-container col-12  col-md-6">
              <label
                htmlFor="INV_Reference_Number"
                className="kyc-label-text form-label add-star"
              >
                INV Reference Number
              </label>
              <input
                required
                onChange={onChangeAddPODetailsFields}
                id="INV_Reference_Number"
                name="INV_Reference_Number"
                type="text"
                className="kyc-input-field form-control"
                value={addPODetailsFields.name}
              />
            </div>

            <div className="kyc-fields-container col-12  col-md-6">
              <label htmlFor="INV_Reference_Date" className="kyc-label-text form-label">
                INV Reference Number Date
              </label>
              <input
                onBlur={onBlurAddPODetailsFields}
                required
                onChange={onChangeAddPODetailsFields}
                id="INV_Reference_Date"
                type="date"
                name="INV_Reference_Date"
                className="kyc-input-field form-control"
                // value={addPODetailsFields.emailId}
              />
            </div>

            <div className="kyc-fields-container col-12  col-md-6">
              <label
                htmlFor="counter_Party"
                className="kyc-label-text form-label add-star"
              >
                Counter-Party
              </label>
              <select
                // onFocus={onFocusSelectAnchor}
                // value={activeMappingData.anchor}
                onChange={onChangeAddPODetailsFields}
                name="counter_Party"
                className="form-select w-100"
                id="counter_Party"
              >
                <option
                  // value=""
                  disabled
                  defaultValue
                  selected
                >
                  Open this select menu
                </option>

                {allCouterPartyList.length !== 0 &&
                  allCouterPartyList.map((eachanchorData, index) => (
                    <option
                      key={eachanchorData.retailerId}
                      value={eachanchorData.retailerId}
                      // selected={index === 0}
                    >
                      {eachanchorData.name}
                    </option>
                  ))}
              </select>
            </div>

            <div className="kyc-fields-container col-12  col-md-6">
              <label
                htmlFor="final_Amount"
                className="kyc-label-text form-label add-star"
              >
                Final Amount
              </label>
              <input
                required
                onChange={onChangeAddPODetailsFields}
                id="final_Amount"
                type="number"
                name="final_Amount"
                className={`kyc-input-field form-control ${isError?.invoiceAmount && "invalid-input"
              }`}
              onBlur={handleBlurInvoiceAmount}
               value={addPODetailsFields?.final_Amount}
              />
              <span className="text-danger">{isError?.invoiceAmount}</span>
            </div>

            <div className="kyc-fields-container col-12  col-md-6">
              <label
                htmlFor="e_INV_Reference_No"
                className="kyc-label-text form-label add-star"
              >
                E- INV Reference Number
              </label>
              <input
                required
                onChange={onChangeAddPODetailsFields}
                id="e_INV_Reference_No"
                type="file"
                name="e_INV_Reference_No"
                className="kyc-input-field form-control"
                // value={addPODetailsFields.mobileNumber}
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end m-2">
          <button
            type="button"
            onClick={togglePurchaseOrderAddModal}
            className="btn btn-outline-secondary mx-2"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-success"
            disabled={addInvReferenceState?.isLoading}
          >
            {addInvReferenceState?.isLoading ? (
              <span className="loader mx-auto"></span>
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </form>
    </>
  );
};

/* PARENT COMPONENT */
const InvReferenceNumber = () => {
  const dispatch = useDispatch();
  /* USESELECT HOOKS */
  const digitalPurchaseOrderTableData = useSelector(
    (state) => state.anchor.digitalPurchaseOrderTableData
  );
  const manualPurchaseOrderTableData = useSelector(
    (state) => state.anchor.manualPurchaseOrderTableData
  );
  /* USESTATES */
  const [activeTableData, setActiveTableData] = useState(2);
  const [materialRequestTableRows, setMaterialRequestTableRows] = useState([]);

  //const [ $filteredMaterialRequest, setFilteredMaterialRequest ] = useState([]);

  const [search, setSearch] = useState("");

  const [materialRequestMetaData, setMaterialRequestMetaData] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [showPurchaseOrderAddModal, setShowPurchaseOrderAddModal] =
    useState(false);

  /* TABLE QUERY PARAMS */
  const [tableQueryParams, setTableQueryParams] = useState({
    tableOffset: 0,
    tableLimit: 5,
    tableSort: "modifiedDate",
    tableOrder: -1,
    currentPage: 1,
  });

  function fetchList() {
    dispatch(
      getManualPurchaseOrderTable({
        tableQueryParams,
        search,
      })
    );
  }

  /* USEEFFECTS */
  useEffect(() => {
    // if (activeTableData === 1) {
    //   dispatch(
    //     getDigitalPurchaseOrderTable({
    //       tableQueryParams,
    //     })
    //   );
    // } else
    if (activeTableData === 2) {
      fetchList();
    }
  }, [dispatch, tableQueryParams, activeTableData, search]);

  // useEffect(() => {
  //   if (activeTableData === 2) {
  //     if (digitalPurchaseOrderTableData.data) {

  //       setMaterialRequestTableRows(digitalPurchaseOrderTableData.data.items);
  //       setFilteredMaterialRequest(digitalPurchaseOrderTableData.data.items);
  //       setMaterialRequestMetaData(digitalPurchaseOrderTableData.data.metadata);
  //     }
  //   }
  // }, [digitalPurchaseOrderTableData.data]);

  useEffect(() => {
    if (activeTableData === 2) {
      if (manualPurchaseOrderTableData.data) {
        setMaterialRequestTableRows(manualPurchaseOrderTableData.data.items);
        //  setFilteredMaterialRequest(manualPurchaseOrderTableData.data.items);
        setMaterialRequestMetaData(manualPurchaseOrderTableData.data.metadata);
      }
    }
  }, [manualPurchaseOrderTableData.data]);

  /* EVENT LISTENERS */
  const onClickActiveTable = (value) => {
    setActiveTableData(value);
    // setFilteredMaterialRequest([]);
    setSearch("");
  };

  const togglePurchaseOrderAddModal = () => {
    setShowPurchaseOrderAddModal((prev) => {
      if (prev === true) {
        fetchList();
      }

      return !prev;
    });
  };

  const onChangeSearch = (e) => {
    setSearch(e.target.value);

    // if (e.target.value === "") {
    //   setFilteredMaterialRequest(materialRequestTableRows);

    //   return;
    // }

    // const newList = [];

    // for (let item of materialRequestTableRows) {
    //   if (item.poId.includes(e.target.value)) {
    //     newList.push(item);
    //   }
    // }

    // setFilteredMaterialRequest(newList);
  };

  return (
    <>
      {/* Modal Content */}
      {showPurchaseOrderAddModal && (
        <ReusableModal
          showModal={showPurchaseOrderAddModal}
          toggleModal={togglePurchaseOrderAddModal}
          modalContent={
            <PurchaseOrderAddModal
              togglePurchaseOrderAddModal={togglePurchaseOrderAddModal}
            />
          }
          modalSizeClass="modal-lg"
        />
      )}
      <div className="d-flex flex-column gap-4" style={{ overflowY: "auto" }}>
        <Helmet>
          <title>INV-Reference-Number</title>
        </Helmet>
        <div className="bg-white admin-dashboard-card-container px-3">
          <div className="d-flex justify-content-between align-items-center flex-wrap px-2 gap-2">
            <span className="dashboard-content-title">
              INV Reference Number
            </span>
            <div
              onClick={togglePurchaseOrderAddModal}
              className="d-flex align-items-center"
              type="button"
            >
              <div className={`rm-add-button ${isHovered ? "show" : ""}`}>
                ADD
              </div>
              <button
                className="btn mx-3 mt-4"
                id="add_INV_Reference_Number"
                style={{
                  border: "1px solid #6378d4",
                  borderRadius: "10px",
                }}
              >
                {" "}
                Add INV Reference Number &nbsp;
                <BsPlusCircleFill className="rm-light-plus-button" />{" "}
              </button>

              {/* <BsPlusCircleFill
                className="rm-plus-button"
              /> */}
            </div>
          </div>

          <div className="d-flex flex-column flex-sm-row align-items-center my-4">
            <div className="d-flex flex-column justify-content-center gap-3 col-12 col-sm-6">
              {/* <label
                htmlFor="selectAnchor"
                className="anchor-and-lender-select-label"
              >
                Purchase Order ID
              </label> */}
              <div className="search-container d-flex align-items-center py-1 px-3 col-12 col-sm-8 border border-secondary">
                <input
                  className="search-input-element d-flex flex-grow-1"
                  type="text"
                  placeholder="Search with INV Reference Number"
                  onChange={onChangeSearch}
                  value={search}
                />{" "}
                <BsSearch className="fs-5" />
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex flex-column">
          <div className="d-flex">
            {/* <span
              className={`dashboard-table-title ${
                activeTableData !== 1 && "dashboard-table-title-hover"
              } py-2 px-3 ${
                activeTableData === 1 && "active-table-data placeholder-wave"
              }`}
              onClick={() => onClickActiveTable(1)}
            >
              Digital PO
            </span> */}
            <span
              className={`dashboard-table-title ${
                activeTableData !== 2 && "dashboard-table-title-hover"
              } py-2 px-3 ${
                activeTableData === 2 && "active-table-data placeholder-wave"
              }`}
              onClick={() => onClickActiveTable(2)}
            >
              INV Reference Number
            </span>
          </div>
          <div>
            {/* {activeTableData === 1 && (
              <DigitalPurchaseOrderTable
                className="admin-anchor-and-lender-mapped-table"
                tableHeader={[
                  { name: "Purchase Order Date", sortValue: "addedDate" },
                  { name: "Purchase Order ID", sortValue: "" },
                  { name: "Counter Party", sortValue: "buyerName" },
                  {
                    name: "Date Of Delivery",
                    sortValue: "expectedDateOfDelivery",
                  },
                  {
                    name: "Payment Terms (in days)",
                    sortValue: "paymentTerms",
                  },
                  { name: "Delivery Location", sortValue: "" },
                  { name: "Total Amount (₹)", sortValue: "" },
                ]}
                tableRows={$filteredMaterialRequest}
                materialRequestMetaData={materialRequestMetaData}
                tableQueryParams={tableQueryParams}
                setTableQueryParams={setTableQueryParams}
                isLoading={digitalPurchaseOrderTableData.isLoading}
              />
            )} */}
            {activeTableData === 2 && (
              <ManualPurchaseOrderTable
                className="admin-anchor-and-lender-mapped-table"
                tableHeader={[
                  { name: "INV Reference Date", sortValue: "addedDate" },
                  { name: "INV Reference ID", sortValue: "" },
                  { name: "Counter Party", sortValue: "buyerName" },
                  { name: "Total Amount (₹)", sortValue: "" },
                  { name: "Invoice Status", sortValue: "" },
                  { name: "Status", sortValue: "" },
                ]}
                tableRows={materialRequestTableRows}
                materialRequestMetaData={materialRequestMetaData}
                tableQueryParams={tableQueryParams}
                setTableQueryParams={setTableQueryParams}
                isLoading={manualPurchaseOrderTableData.isLoading}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default InvReferenceNumber;
