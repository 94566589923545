import React, { useEffect, useState } from "react";
import PurchaseOrderDigitalPOTable from "../BuyerTables/PurchaseOrder/PurchaseOrderDigitalPOTable";
import PurchaseOrderManualPOTable from "../BuyerTables/PurchaseOrder/PurchaseOrderManualPOTable";
import { useDispatch, useSelector } from "react-redux";
import { BsPlusCircleFill, BsSearch } from "react-icons/bs";
import {
  getPurchaseOrderDigitalPOTable,
  getPurchaseOrderManualPOTable,
} from "../../../store/slices/buyer";
import Loader from "../../../layout/GenericLayout/Loader";
import {Helmet} from "react-helmet";

const BuyerInvRefrence = () => {
  const dispatch = useDispatch();

  // Tabs Switching
  const [activeTableData, setActiveTableData] = useState(1);
  const [activeTableRows, setActiveTableRows] = useState([]);

  // Redux API Response Data
  const purchaseOrderDigitalPOResponse = useSelector(
    (state) => state.buyer.purchaseOrderDigitalPOTableData
  );
  const purchaseOrderManualPOResponse = useSelector(
    (state) => state.buyer.purchaseOrderManualPOTableData
  );

  // Table Data
  const [purchaseOrderDigitalTableRows, setPurchaseOrderDigitalTableRows] =
    useState([]);
  const [
    purchaseOrderDigitalTableMetaData,
    setPurchaseOrderDigitalTableMetaData,
  ] = useState();
  const [purchaseOrderManualTableRows, setPurchaseOrderManualTableRows] =
    useState([]);
  const [
    purchaseOrderManualTableMetaData,
    setPurchaseOrderManualTableMetaData,
  ] = useState();

  const onClickActiveTable = (value) => {
    setActiveTableData(value);
  };

  /* TABLE QUERY PARAMS Digital PO*/
  const [
    tableQueryParamsPurchaseOrderDigitalPO,
    setTableQueryParamsPurchaseOrderDigitalPO,
  ] = useState({
    tableOffset: 0,
    tableLimit: 5,
    tableSort: "poId",
    tableOrder: -1,
    currentPage: 0,
    search: "",
  });

  /* TABLE QUERY PARAMS Manual PO*/
  const [
    tableQueryParamsPurchaseOrderManualPO,
    setTableQueryParamsPurchaseOrderManualPO,
  ] = useState({
    tableOffset: 0,
    tableLimit: 5,
    tableSort: "buyerId",
    tableOrder: -1,
    currentPage: 0,
    search: "",
  });

  // This is For Searching by ID
  const [searchBothPOByID, setSearchBothPOByID] = useState("");
  const handleOnChangeSearchBothPOByID = (e) => {
    setSearchBothPOByID(e.target.value);
  };

  useEffect(() => {

    // if (activeTableData === 1) {

    //   if(searchBothPOByID.length>0){
    //     dispatch(
    //       getPurchaseOrderDigitalPOTable(`offset=${tableQueryParamsPurchaseOrderDigitalPO.tableOffset}&limit=${tableQueryParamsPurchaseOrderDigitalPO.tableLimit}&sort=${tableQueryParamsPurchaseOrderDigitalPO.tableSort}&search=${searchBothPOByID}&order=${tableQueryParamsPurchaseOrderDigitalPO.tableOrder}`)
    //     );
    //   }
    //   else{
    //     dispatch(
    //       getPurchaseOrderDigitalPOTable(`offset=${tableQueryParamsPurchaseOrderDigitalPO.tableOffset}&limit=${tableQueryParamsPurchaseOrderDigitalPO.tableLimit}&sort=${tableQueryParamsPurchaseOrderDigitalPO.tableSort}&order=${tableQueryParamsPurchaseOrderDigitalPO.tableOrder}`)
    //     );
    //   }
    // }

    if (activeTableData === 1) {

      if(searchBothPOByID.length>0){
        dispatch(
          getPurchaseOrderManualPOTable(`offset=${tableQueryParamsPurchaseOrderManualPO.tableOffset}&limit=${tableQueryParamsPurchaseOrderManualPO.tableLimit}&search=${searchBothPOByID}`)
        );
      }
      else{
        dispatch(
          getPurchaseOrderManualPOTable(`offset=${tableQueryParamsPurchaseOrderManualPO.tableOffset}&limit=${tableQueryParamsPurchaseOrderManualPO.tableLimit}`)
        );
      }

      // setSearchBothPOByID("");
    }
  }, [
     activeTableData,
     dispatch, 
     tableQueryParamsPurchaseOrderDigitalPO, 
     tableQueryParamsPurchaseOrderManualPO,
     searchBothPOByID
    ]);

  // useEffect(() => {
  //   if (purchaseOrderDigitalPOResponse.data) {
  //       setPurchaseOrderDigitalTableRows(
  //       purchaseOrderDigitalPOResponse.data.items
  //     );
  //     setPurchaseOrderDigitalTableMetaData(
  //       purchaseOrderDigitalPOResponse.data.metadata
  //     );
  //   }
  // }, [purchaseOrderDigitalPOResponse]);

  useEffect(() => {
    if (purchaseOrderManualPOResponse.data) {
      setPurchaseOrderManualTableRows(purchaseOrderManualPOResponse.data.items);
      setPurchaseOrderManualTableMetaData(
        purchaseOrderManualPOResponse.data.metadata
      );
    }
  }, [purchaseOrderManualPOResponse]);

  return (
    <>
      <div className="p-0"
      style={{overflowX:'hidden'}}
      >
        <Helmet>
                <title>INV-Reference Number</title>
            </Helmet>
        <div className="bg-white admin-dashboard-card-container p-3">
          <div className="d-flex justify-content-between align-items-center flex-wrap px-2 gap-2 py-2">
            <span className="dashboard-content-title">
              Counter Party INV Reference Number
            </span>
            {/* <div
              className="d-flex align-items-center"
              type="button"
            >
              <BsPlusCircleFill
                className="rm-plus-button"
              />
            </div> */}
          </div>

          <div className="d-flex flex-column flex-sm-row align-items-center my-4">
            <div className="d-flex flex-column justify-content-center gap-3 col-12 col-sm-6">
              {/* <label
                htmlFor="selectAnchor"
                className="anchor-and-lender-select-label"
              >
                Purchase Order
              </label> */}
              <div className="search-container d-flex align-items-center py-1 px-3 col-12 col-sm-8">
                <input
                  className="search-input-element d-flex flex-grow-1"
                  type="text"
                  placeholder="Search with ID"
                  value={searchBothPOByID}
                  onChange={handleOnChangeSearchBothPOByID}
                />{" "}
                <BsSearch className="fs-5" />
              </div>
            </div>
          </div>
        </div>


        {/* The Table container starts here */}

        <div className="d-flex flex-column mt-3">
          <div className="d-flex px-0">
            {/* <span
              className={`dashboard-table-title ${
                activeTableData !== 1 && "dashboard-table-title-hover"
              } py-2 px-3 ${
                activeTableData === 1 && "active-table-data placeholder-wave"
              }`}
              onClick={() => onClickActiveTable(1)}
            >
              Digital PO
            </span> */}
            <span
              className={`dashboard-table-title ${
                activeTableData !== 1 && "dashboard-table-title-hover"
              } py-2 px-3 ${
                activeTableData === 1 && "active-table-data placeholder-wave"
              }`}
              onClick={() => onClickActiveTable(1)}
            >
              INV-Reference-Number
            </span>
          </div>
          <div>


            {/* {activeTableData === 1 && (
              <div
              className="mb-5"
              style={{
                overflowX: "hidden",
                boxSizing: "border-box",
              }}
              >
                <PurchaseOrderDigitalPOTable
                  className='buyer-table'
                  tableHeader={[
                    { name: "Purchase Order Date", sortValue: "" },
                    { name: "Purchase Order ID", sortValue: "" },
                    { name: "Seller Name", sortValue: "" },
                    { name: "Date of Delivery", sortValue: "" },
                    { name: "Payment Terms (Days)", sortValue: "" },
                    { name: "Delivery Location", sortValue: "" },
                    { name: `Total Amount(₹)`, sortValue: "" },
                  ]}
                  tableRows={purchaseOrderDigitalTableRows}
                  materialRequestMetaData={purchaseOrderDigitalTableMetaData}
                  tableQueryParams={tableQueryParamsPurchaseOrderDigitalPO}
                  setTableQueryParams={
                    setTableQueryParamsPurchaseOrderDigitalPO
                  }
                  isLoading={purchaseOrderDigitalPOResponse.isLoading}
                />
              </div>
            )} */}
            {activeTableData === 1 && (
              <div
                className="mb-5"
                style={{
                  overflowX: "hidden",
                  overflowY: "hidden",
                  boxSizing: "border-box",
                }}
              >
                <PurchaseOrderManualPOTable
                  className="buyer-table placeholder-wave"
                  tableHeader={[
                    { name: "INV Reference Date", sortValue: "" },
                    { name: "INV Reference ID", sortValue: "poId" },
                    { name: "Counter Party", sortValue: "" },
                    { name: "Seller Name", sortValue: "" },
                    { name: "Total Amount(₹)", sortValue: "" },
                    { name: "Invoice Status", sortValue: "" },
                    { name: "Status", sortValue: "" },
                  ]}
                  tableRows={purchaseOrderManualTableRows}
                  materialRequestMetaData={purchaseOrderManualTableMetaData}
                  tableQueryParams={tableQueryParamsPurchaseOrderManualPO}
                  setTableQueryParams={setTableQueryParamsPurchaseOrderManualPO}
                  isLoading={purchaseOrderManualPOResponse.isLoading}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BuyerInvRefrence;
