import React from "react";
import { useEffect, useState } from "react";
import { HiOutlineArrowNarrowDown } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import { BiSort } from "react-icons/bi";
import TablePagination, {
  jumpToPage,
  nextPage,
  previousPage,
} from "../../../layout/GenericLayout/TablePagination";
import Loader from "../../../layout/GenericLayout/Loader";
import { getCamDataByEmailId } from "../../../store/slices/admin";
import { toastMessage } from "../../../layout/GenericLayout/ToastMessage";
import ReusableModal from "../../../layout/GenericLayout/ReusableModal";
import AdminUserTableCAMComponent from "../../Admin/AdminTables/AdminCam/AdminUserTableCAMComponent";
import {
  getScoreCardDetails,
  getScoreCardDataByEmailId,
} from "../../../store/slices/generic";
import ScoreCardDetails from "../LenderScoreCard/ScoreCardDetails";
import { AdminCamContextWrapper } from "../../../context/admin/cam/adminCamContext";

export const CamModal = ({ camByEmailIdData, showEdit }) => {
  return (
    <AdminCamContextWrapper>
      <AdminUserTableCAMComponent
        showEdit={showEdit}
        camByEmailIdData={camByEmailIdData}
      />
      ;
    </AdminCamContextWrapper>
  );
};

const LenderdashboardTable = (props) => {
  const dispatch = useDispatch();
  const [showCamModal, setShowCamModal] = useState(false);
  const [activeScoreCardEmailId, setActiveScoreCardEmailId] = useState(null);
  const [activeCamEmailId, setActiveCamEmailId] = useState(null);
  const [showScoreCardDetailsModal, setShowScoreCardDetailsModal] =
    useState(false);
  const scoreCardByEmailIdData = useSelector(
    (state) => state.generic.scoreCardByEmailIdData
  );
  const scoreCardDetails = useSelector(
    (state) => state.generic.scoreCardDetails
  );
  const camByEmailIdData = useSelector((state) => state.admin.camByEmailIdData);



  const updatePage = (currentPage) => {
    const newOffSet = props.tableQueryParams.tableLimit * (currentPage - 1);
    props.setTableQueryParams((prev) => ({
      ...prev,
      tableOffset: newOffSet,
      currentPage,
    }));
  };

  const onClickActiveSort = (activeSortValue = "") => {
    if (activeSortValue !== "") {
      let order = -1;
      if (activeSortValue === props.tableQueryParams.tableSort) {
        if (props.tableQueryParams.tableOrder === -1) {
          order = 1;
        }
      }
      props.setTableQueryParams((prev) => ({
        ...prev,
        tableSort: activeSortValue,
        tableOrder: order,
      }));
    }
  };

  const onClickViewMoreTableData = () => {
    let newLimit = props.tableQueryParams.tableLimit;
    if (newLimit === 5) {
      newLimit += 5;
    } else {
      newLimit -= 5;
    }
    props.setTableQueryParams((prev) => ({
      ...prev,
      tableLimit: newLimit,
    }));
  };

  const toggleCamModal = () => {
    setShowCamModal((prev) => !prev);
  };

  const toggleScoreCardDetailsModal = () => {
    setShowScoreCardDetailsModal((prev) => !prev);
  };

  const onClickScoreCardDetails = (emailId) => {
    setActiveScoreCardEmailId(emailId);
    dispatch(getScoreCardDataByEmailId(emailId))
      .unwrap()
      .then(() => {
        dispatch(getScoreCardDetails(emailId))
          .unwrap()
          .then((response) => {
            toggleScoreCardDetailsModal();
          })
          .catch((error) => {
            toastMessage(error.message, "error");
          });
      })
      .catch((error) => {
        toastMessage(error.message, "error");
      });
  };

  const onClickCam = (emailId) => {
    setActiveCamEmailId(emailId);
    dispatch(getCamDataByEmailId(emailId))
      .unwrap()
      .then((response) => {
        toggleCamModal();
      })
      .catch((error) => {
        toastMessage(error.message, "error");
      });
  };

  return (
    <>
     {showCamModal && (
        <ReusableModal
          showModal={showCamModal}
          toggleModal={toggleCamModal}
          modalContent={<CamModal camByEmailIdData={camByEmailIdData} />}
          modalSizeClass="modal-xl"
        />
      )}

      {showScoreCardDetailsModal && (
        <ReusableModal
          showModal={showScoreCardDetailsModal}
          toggleModal={toggleScoreCardDetailsModal}
          modalContent={
            <ScoreCardDetails
              scoreCardDetails={scoreCardDetails}
              scoreCardByEmailIdData={scoreCardByEmailIdData}
            />
          }
          modalSizeClass="modal-xl"
        />
      )}
      <div className="lender-table-container">
        <div className="table-inner-cont">
          <table className={props.className}>
            <thead>
              <tr>
                {props.tableHeader.map((eachHeader) => (
                  <th
                    key={eachHeader}
                    scope="col"
                    // className={`${
                     //   props.tableQueryParams.tableSort === eachHeader.sortValue &&
                    //   eachHeader.sortValue !== "" &&
                    //   "active-sort-text"
                    // }`}
                    onClick={() => onClickActiveSort(eachHeader?.sortValue)}
                  >
                    {eachHeader}
                    {eachHeader.sortValue !== "" && <BiSort />}
                  </th>
                ))}
              </tr>
            </thead>
            {props.isLoading ? (
              <tbody>
                <tr>
                  <td colSpan={5}>
                    <Loader className="my-5" />
                  </td>
                </tr>
              </tbody>
            ) : (
              <>
                {props.tabValue === "1" && (
                  <tbody>
                    {props.tableRows !== undefined &&
                      props.tableRows.map((eachRows, index) => (
                        <tr key={eachRows.customerId} scope="col">
                          <td>{eachRows.customerId}</td>
                          <td>{eachRows.companyFullName}</td>
                          <td>{eachRows.emailId}</td>
                          <td
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              onClickScoreCardDetails(eachRows.emailId)
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              width="26.134"
                              height="20.259"
                              viewBox="0 0 26.134 20.259"
                            >
                              <defs>
                                <linearGradient
                                  id="linear-gradient"
                                  x1="-0.067"
                                  y1="1.022"
                                  x2="1.081"
                                  y2="1"
                                  gradientUnits="objectBoundingBox"
                                >
                                  <stop offset="0" stopColor="#ff6363" />
                                  <stop offset="0.207" stopColor="#d59759" />
                                  <stop offset="0.296" stopColor="#c3aa55" />
                                  <stop offset="0.571" stopColor="#efc53c" />
                                  <stop offset="1" stopColor="#21c91a" />
                                </linearGradient>
                              </defs>
                              <path
                                id="tachometer-fast-alt"
                                d="M15.067,5A13.067,13.067,0,0,0,3.751,24.6a1.311,1.311,0,1,0,2.274-1.307,10.349,10.349,0,0,1-1.411-5.227,10.454,10.454,0,1,1,19.509,5.227A1.306,1.306,0,1,0,26.383,24.6,13.067,13.067,0,0,0,15.067,5Zm3.711,7.527-2.025,2.012a3.8,3.8,0,0,0-1.686-.392,3.92,3.92,0,1,0,3.92,3.92,3.789,3.789,0,0,0-.392-1.673l2.025-2.012a1.307,1.307,0,1,0-1.842-1.856Zm-3.711,6.847a1.307,1.307,0,1,1,.915-2.248h0a1.312,1.312,0,0,1-.915,2.248Z"
                                transform="translate(-2 -5)"
                                fill="url(#linear-gradient)"
                              />
                            </svg>
                          </td>
                          <td
                            className={`${
                              eachRows.role === "seller"
                                ? "text-warning"
                                : "text-primary"
                            }`}
                          >
                            {eachRows.role == "seller"
                              ? "Anchor"
                              : "Counter Party"}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                )}
                {props.tabValue === "2" && (
                  <tbody>
                    {props.tableRows !== undefined &&
                      props.tableRows.map((eachRows, index) => (
                        <tr key={eachRows.customerId} scope="col">
                          <td>{eachRows.customerId}</td>
                          <td>{eachRows.companyFullName}</td>
                          <td>{eachRows.emailId}</td>
                          <td onClick={() => onClickCam(eachRows.emailId)}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25.62"
                              height="20.13"
                              viewBox="0 0 25.62 20.13"
                            >
                              <path
                                id="Icon_ionic-md-card"
                                data-name="Icon ionic-md-card"
                                d="M25.308,5.625H4.812A2.52,2.52,0,0,0,2.261,8.141l-.011,15.1a2.53,2.53,0,0,0,2.562,2.516h20.5a2.53,2.53,0,0,0,2.562-2.516V8.141A2.53,2.53,0,0,0,25.308,5.625Zm0,17.614H4.812V15.69h20.5Zm0-12.581H4.812V8.141h20.5Z"
                                transform="translate(-2.25 -5.625)"
                                fill="#2e3c83"
                              />
                            </svg>
                          </td>
                          <td
                            className={`${
                              eachRows.role === "seller"
                                ? "text-warning"
                                : "text-primary"
                            }`}
                          >
                            {eachRows.role == "seller"
                              ? "Anchor"
                              : "Counter Party"}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                )}

                {props.tabValue === "3" && (
                  <tbody>
                    {props.tableRows !== undefined &&
                      props.tableRows.map((eachRows, index) => (
                        <tr key={eachRows.customerId} scope="col">
                          <td>{eachRows.customerId}</td>
                          <td>{eachRows.companyFullName}</td>
                          <td
                            className={`${
                              eachRows.role === "seller"
                                ? "text-warning"
                                : "text-primary"
                            }`}
                          >
                            {eachRows.role == "seller"
                              ? "Anchor"
                              : "Counter Party"}
                          </td>
                          <td>{eachRows.maxLimit}</td>
                          <td>{eachRows.totalUtilisedAmnt}</td>
                        </tr>
                      ))}
                  </tbody>
                )}
              </>
            )}
          </table>
        </div>
        {/* {!props.isLoading && (
          <div className="d-flex justify-content-end px-2">
            <span
              // onClick={onClickViewMoreTableData}
              className="d-flex align-items-center gap-1 my-3 view-more-text"
            >
              View more{" "}
              <HiOutlineArrowNarrowDown
              // className={`fs-5 ${
              //   props.tableQueryParams.tableLimit === 5
              //     ? "down-arrow"
              //     : "up-arrow"
              // }`}
              />
            </span>
          </div>
        )} */}
      </div>
      <div className="d-flex align-items-center gap-2 page-data-text-container mt-3 px-3 w-100">
        {/* <TablePagination
          pageSize={props.tableQueryParams.tableLimit}
          onUpdatePage={updatePage}
          current={props.tableQueryParams.currentPage}
          total={props.materialRequestMetaData?.paging?.total}
        ></TablePagination> */}
      </div>
    </>
  );
};

export default LenderdashboardTable;
