import { Fragment, useContext, useEffect, useState } from "react";
import { GenericCamTableComponent } from "../../../../GenericComponent/Table/tableComponent";
import { useSelector } from "react-redux";
import { AdminCamContext } from "../../../../context/admin/cam/adminCamContext";
import { AiFillDelete } from "react-icons/ai";
import {AdminKycActions} from "../../../../context/admin/cam/actionTypes";


/**
 * @type { { value: string, rowspan?: number, colspan?: number }[][] }
 */
const AdminCamTransactionDetailsTableHeaders = [
	[
		{ value: "Type Of Limit", rowspan: 2 },
		{ value: "(All Figures in Rs. Lacs)", colspan: 3, },
		{ value: "Tranche Tenor", rowspan: 2 },
	],
	[
		{ value: "Existing Limit" },
		{ value: "Proposed Limit" },
		{ value: "Total Limit" },
	],
];

export const AdminCamTransactionDetails = () => {
	const [reduxState, setReduxState] = useState([]);
	const [total,setTotal]=useState();
	const[otherCharges,setOtherCharges]=useState(0);
	const[processingFees,setProcessingFees]=useState(0);
	const[rateOfInterest,setRateOfInterest]=useState(0);

	const additionalReduxState = useSelector(state=>state?.admin?.camByEmailIdData?.data?.data?.camDetails?.transactionDetails);

	const { editEnabled, transactionDetailsDispatch } = useContext(AdminCamContext);

	// console.log("transaction details", additionalReduxState);

	useEffect(()=> {
		// console.log(additionalReduxState)
		setReduxState(additionalReduxState[0]?.data);
		setTotal(additionalReduxState[0]?.total);
		setOtherCharges(additionalReduxState[0]?.otherCharges);
		setProcessingFees(additionalReduxState[0]?.processingFees);
		setRateOfInterest(additionalReduxState[0]?.rateOfInterest);
	},[additionalReduxState]);


	const handleDelete = (index) => {
		setReduxState(prev => [...prev?.slice(0, index), ...prev?.slice(index + 1)]);
	};



	const handleChangeValue = (e, index) => {
		const { name, value } = e.target;
		let newArray=[];

		if(Array.isArray(reduxState)){
			newArray=reduxState?.map((item, i)=>{
				if(i===index){
					return {...item,[name]:value}
				}
				return item
			})	
		}
		
		
		setReduxState(newArray);
	};

	useEffect(()=>{
		const newTotal={existingLimit: 0, proposedLimit: 0, totalLimit: 0, trancheTenor: 0};
		reduxState?.map((item)=>{
			newTotal.existingLimit=+newTotal.existingLimit+ +(item?.existingLimit);
			newTotal.proposedLimit=+newTotal.proposedLimit+ +(item?.proposedLimit);
			newTotal.totalLimit=+newTotal.totalLimit+ +(item?.totalLimit);
			newTotal.trancheTenor=+newTotal.trancheTenor+ +(item?.trancheTenor);
		})
		setTotal(newTotal);
	},[reduxState])


	const handleAddNewRow = () => {
		setReduxState([...reduxState, {
			typeOfLimit: "",
			existingLimit: "",
			proposedLimit: "",
			totalLimit: "",
			trancheTenor: "",
		}]);
		console.log(reduxState)
	};

	useEffect(()=>{
		transactionDetailsDispatch({type:AdminKycActions.TRANSACTION_DETAILS.UPDATE_DETAILS,payload:{reduxState,total,otherCharges,processingFees,rateOfInterest}});
	},[reduxState,total,otherCharges,processingFees,rateOfInterest])
	
	return (
		<>
			<GenericCamTableComponent handleAddNewRow={ handleAddNewRow } addNewRows={ true } tableHeaders={ AdminCamTransactionDetailsTableHeaders }>
				{
					!editEnabled ?
					reduxState?.length!=0 && reduxState?.map((val,indx)=>
						<tr key={"trans"+indx}>
							<td className="text-center">{val.typeOfLimit}</td>
							<td className="numbrLeft">{val.existingLimit}</td>
							<td className="numbrLeft">{val.proposedLimit}</td>
							<td className="numbrLeft">{val.totalLimit}</td>
							<td className="numbrLeft">{val.trancheTenor}</td>
						</tr>
					) :
					reduxState?.length!=0 && reduxState?.map((val,indx)=>
						<tr key={"trans"+indx}>
							<td className="text-center">
								<input className="form-control" type="text" name="typeOfLimit" value={ val.typeOfLimit } onChange={ e => handleChangeValue(e, indx) } />
							</td>
							<td className="numbrLeft">
								<input className="form-control" type="number" name="existingLimit" value={ val.existingLimit } onChange={ e => handleChangeValue(e, indx) } />
							</td>
							<td className="numbrLeft">
								<input className="form-control" type="number" name="proposedLimit" value={ val.proposedLimit } onChange={ e => handleChangeValue(e, indx) } />
							</td>
							<td className="numbrLeft">
								<input className="form-control" type="number" name="totalLimit" value={ val.totalLimit } onChange={ e => handleChangeValue(e, indx) } />
							</td>
							<td className="numbrLeft">
								<input className="form-control" type="number" name="trancheTenor" value={ val.trancheTenor } onChange={ e => handleChangeValue(e, indx) } />
							</td>
							<td className="text-center">
								<button className="btn" onClick={ () => handleDelete(indx) }>
									<AiFillDelete color="red" />
								</button>
							</td>
						</tr>
					)
				}
				
				
				
				<tr>
					<td className="numbrLeft">{total?.typeOfLimit}</td>
					<td className="numbrLeft">{total?.existingLimit}</td>
					<td className="numbrLeft">{total?.proposedLimit}</td>
					<td className="numbrLeft">{total?.totalLimit}</td>
					<td className="numbrLeft">{total?.trancheTenor}</td>
				</tr>
			</GenericCamTableComponent>
			<div className="row m-0">
				<div className="col-md-4 px-2">
					<span>Rate of Interest :</span>
					{
						!editEnabled ?
							<span className="ml-2"> &nbsp; {reduxState[0]?.rateOfInterest}</span>
						:
						<input className="form-control" type="number" name="rateOfInterest" onChange={(e)=> setRateOfInterest(e.target.value) } value={rateOfInterest } />
					}
				</div>
				<div className="col-md-4 px-3">
					<span>Processing Fees :</span>
					{
						!editEnabled ?
						<span className="ml-2"> &nbsp; {reduxState[0]?.processingFees}</span>
						:
						<input className="form-control" type="number" name="processingFees" onChange={(e)=> setProcessingFees(e.target.value) } value={processingFees } />
					}
				</div>
				<div className="col-md-4 px-3">
					<span>Other Charges :</span>
					{
						!editEnabled ?
						<span className="ml-2"> &nbsp; {reduxState[0]?.otherCharges}</span>
						:
						<input className="form-control" type="number" name="otherCharges" onChange={ (e)=>{ setOtherCharges(e.target.value)} } value={ otherCharges } />
					}
				</div>
			</div>
		</>
	);
};