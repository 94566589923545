import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../utilities/api";
import axios from "axios";
import { BASE_URL, DOC_URL } from "../../utilities/config";

const initialState = {

  totalRejectedUsersData: {
    data: null,
    isLoading: false,
    error: null,
  },
  totalApprovedUsersData: {
    data: null,
    isLoading: false,
    error: null,
  },
  totalOutstandingInvoicesData: {
    data: null,
    isLoading: false,
    error: null,
  },
  totalNotDiscountedInvoicesData: {
    data: null,
    isLoading: false,
    error: null,
  },
  totalPendingUsersData: {
    data: null,
    isLoading: false,
    error: null,
  },
  totalOnboardingUsersData: {
    data: null,
    isLoading: false,
    error: null,
  },
  listOfCustomersOnboardingData: {
    data: null,
    isLoading: false,
    error: null,
  },
  isEmailRegistered: {
    data: null,
    isLoading: false,
    error: null,
  },
  isPanVerification: {
    data: null,
    isLoading: false,
    error: null,
  },
  uploadAadhaarFrontByRMEmail: {
    data: null,
    isLoading: false,
    error: null,
  },
  uploadAadhaarFrontByAnchorEmail: {
    data: null,
    isLoading: false,
    error: null,
  },
  uploadAadhaarBackByRMEmail: {
    data: null,
    isLoading: false,
    error: null,
  },
  uploadAadhaarBackByAnchorEmail: {
    data: null,
    isLoading: false,
    error: null,
  },
  uploadProfilePic: {
    data: null,
    isLoading: false,
    error: null,
  },
  coApplicantIsPanVerification: {
    data: null,
    isLoading: false,
    error: null,
  },
  uploadCoApplicantAadhaarFrontByRMEmail: {
    data: null,
    isLoading: false,
    error: null,
  },
  uploadCoApplicantFrontByAnchorEmail: {
    data: null,
    isLoading: false,
    error: null,
  },
  bankDetailsBankStatements: {
    data: null,
    isLoading: false,
    error: null,
  },
  bankDetailsIFSCCode: {
    data: null,
    isLoading: false,
    error: null,
  },
  GSTDetailsGSTNumberValidate: {
    data: null,
    isLoading: false,
    error: null,
  },
  otherDetailsUploadDocument: {
    data: null,
    isLoading: false,
    error: null,
  },
  onboardAnchor: {
    data: null,
    isLoading: false,
    error: null,
  },
  onboardCounterParty: {
    data: null,
    isLoading: false,
    error: null,
  },
  rmUpdatePassword: {
    data: null,
    isLoading: false,
    error: null,
  },
  rmInvoicesData: {
    data: null,
    isLoading: false,
    error: null,
  },
  listOfApprovedUsers: {
    data: null,
    isLoading: false,
    error: null,
  },
  uploadFiles: {
    data: null, isLoading: false, error: null,
  },
  fetchFilesOfAnchorAndCounterParty: { data: null, isLoading: false, error: null, },
  fetchOnBoardedUsersDetails: { data: null, isLoading: false, error: null, },
};

export const fetchOnBoardedUsersDetails = createAsyncThunk(
  "get/fet_on_boarded_users_details",
  async (emailId) => {
    try {
      if (!emailId) {
        throw new Error("Invalid Email Id");
      }
      
      const url = `admin/kyc_cp_users/${ emailId }`;

      /**
       * @type { import("axios").AxiosRequestConfig['headers'] }
       */
      const headers = { Authorization: `Bearer ${ sessionStorage.getItem("token") }`, };
      
      const { data } = await api.get(url, { headers, });

      return data;
    }
    catch (err) {
      if (err?.response) {
        throw new Error(err?.response?.data);
      }

      throw new Error(err?.message);
    }
  }
);

export const fetchFilesOfAnchorAndCounterParty = createAsyncThunk(
  "get/fetch_all_documents",
  async (emailId) => {
    try {
      const url = `${DOC_URL}kyc/get_all_docs/${emailId}`;

      /**
       * @type { import("axios").AxiosRequestConfig['headers'] }
       */
      const headers = { Authorization: `Bearer ${ sessionStorage.getItem("token") }`, };
      
      const { data } = await axios.get(url, { headers });

      return data;
    }
    catch (err) {
      if (err?.response) {
        throw new Error(err?.response?.data);
      }
      throw new Error(err);
    }
  }
);

export const rmUploadFiles = createAsyncThunk(
  "post/rm/upload_files",
  async (body) => {
    try {
      const url = `${DOC_URL}kyc/docs_for_cp`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const { data } = await axios.post(url, body, head);
      return data;
    } catch (error) {
      if (error?.response) {
        throw new Error(JSON.parse(JSON.stringify(error?.response)));
      }
      throw new Error(error);
    }
  },
);

export const rmOnboardAnchorDispatch = createAsyncThunk(
  "post/rm_onboard_anchor",
  async (data) => {
    try {
      const url = "relationmanager/user_kyc";
      const headers = { Authorization: `Bearer ${ sessionStorage.getItem("token") }` };

      const { data: responseData } = await api.post(url, data, { headers });

      return responseData;
    }
    catch (err) {
      throw new Error(err?.response?.data?.error);
    }
  }
);

export const rmOnboardCounterPartyDispatch = createAsyncThunk(
  "post/rm_onboard_counter_party",
  async (data) => {
    try {
      const url = "relationmanager/user_kyc";
      const headers = { Authorization: `Bearer ${ sessionStorage.getItem("token") }` };

      const { data: responseData } = await api.post(url, data, { headers });

      return responseData;
    }
    catch (err) {
      throw new Error(err?.response?.data?.error);
    }
  }
);

export const rmChangePassword = createAsyncThunk(
  "post/rm_change_password",
  async (body) => {
    try {
      const url = "/user/reset__password";
      const headers = { Authorization: `Bearer ${ sessionStorage.getItem("token") }` };

      const { data: responseData } = await api.post(url, body, { headers });

      return responseData;
    }
    catch (err) {
      throw new Error(err?.response?.data?.error);
    }
  }
);


export const getTotalRejectedUsers = createAsyncThunk("get/total_rejected_users", async () => {
  try {
    const url = `channelpartner/total_rejected_users`;
    const head = {
      headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
    };
    const response = await api.get(url, head);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
});
export const getTotalApprovedUsers = createAsyncThunk("get/total_approved_users", async () => {
  try {
    const url = `channelpartner/total_approved_users`;
    const head = {
      headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
    };
    const response = await api.get(url, head);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
});
export const getTotalOutstandingInvoices = createAsyncThunk("get/total_outstanding_invoices", async () => {
  try {
    const url = `relation_manager/rm_dashboard`;
    const head = {
      headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
    };
    const response = await api.get(url, head);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
});
export const getTotalNotDiscountedInvoices = createAsyncThunk("get/total_not_discounted_invoices", async () => {
  try {
    const url = `relation_manager/total_not_discounted_invoices`;
    const head = {
      headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
    };
    const response = await api.get(url, head);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
});
export const getTotalPendingUsers = createAsyncThunk("get/total_pending_users", async () => {
  try {
    const url = `channelpartner/total_pending_users`;
    const head = {
      headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
    };
    const response = await api.get(url, head);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
});
export const getTotalOnboardingUsers = createAsyncThunk("get/total_onboarded_users", async () => {
  try {
    const url = `channelpartner/total_onboarded_users`;
    const head = {
      headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
    };
    const response = await api.get(url, head);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
});
export const getListOfCustomersOnboarding = createAsyncThunk("get/get_all_user_by_channel", async () => {
  try {
    const url = `channelpartner/get_all_user_by_channel`;
    const head = {
      headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
    };
    const response = await api.get(url, head);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
});

export const getIsEmailRegistered = createAsyncThunk("get/isRegistered",
  async (Email) => {
    try {
      const url = `user/isRegistered/${Email}`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.get(url, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  });

export const postPanCardVerification = createAsyncThunk("post/PanVerification",
  async (panNumEmail) => {
    try {
      const url = `cam/pan_verification`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.post(url, panNumEmail, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message);
    }
  });

export const postUploadAadhaarFrontByRMEmail = createAsyncThunk("post/test/kyc_orc_aadhar",
  async (body) => {
    try {
      const url = `${BASE_URL}/test/kyc_orc_aadhar`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await axios.post(url, body, head);
      return JSON.parse(JSON.stringify(response));
    } catch (error) {

      if (error?.response) {
        throw new Error(JSON.parse(JSON.stringify(error?.response)));
      }
      throw new Error(error);
    }
  });

export const postUploadAadhaarFrontByAnchorEmail = createAsyncThunk("post/kyc/docs_for_cp",
  async (body) => {
    try {
      const url = `${DOC_URL}kyc/docs_for_cp`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await axios.post(url, body, head);

      return JSON.parse(JSON.stringify(response));
    } catch (error) {

      if (error?.response) {
        throw new Error(JSON.parse(JSON.stringify(error?.response)));
      }
      throw new Error(error);
    }
  });

export const postUploadAadhaarBackByRMEmail = createAsyncThunk("post/test/back/kyc_orc_aadhar",
  async (body) => {
    try {
      const url = `${BASE_URL}/test/kyc_orc_aadhar`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await axios.post(url, body, head);
      return response?.data;
    } catch (error) {
      if (error?.response) {
        throw new Error(JSON.parse(JSON.stringify(error?.response)));
      }
      throw new Error(error);
    }
  });

export const postUploadAadhaarBackByAnchorEmail = createAsyncThunk("post/kyc/back/docs_for_cp",
  async (body) => {
    try {
      const url = `${DOC_URL}kyc/docs_for_cp`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      // const response = await api.get(url, head);
      const response = await axios.post(url, body, head);
      return response?.data;
    } catch (error) {
      if (error?.response) {
        throw new Error(JSON.parse(JSON.stringify(error?.response)));
      }
      throw new Error(error);
    }
  });

export const postUploadProfilePic = createAsyncThunk("post/kyc/profile/docs_for_cp",
  async (body) => {
    try {
      const url = `${DOC_URL}kyc/docs_for_cp`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      // const response = await api.get(url, head);
      const response = await axios.post(url, body, head);

      return response?.data;
    } catch (error) {
      if (error?.response) {
        throw new Error(JSON.parse(JSON.stringify(error?.response)));
      }
      throw new Error(error);
    }
  });

export const postCoApplicantIsPanVerified = createAsyncThunk("post/coApplicant/cam/pan_verification",
  async (body) => {
    try {
      const url = `${BASE_URL}cam/pan_verification      `;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      // const response = await api.get(url, head);
      const response = await axios.post(url, body, head);

      return response?.data;
    } catch (error) {
      if (error?.response) {
        throw new Error(error?.response?.data?.message);
      }
      throw new Error(error);
    }
  });

export const postUploadCoApplicantAadhaarFrontByRMEmail = createAsyncThunk("post/test/coApplicants/kyc_orc_aadhar",
  async (body) => {
    try {
      const url = `${BASE_URL}test/kyc_orc_aadhar`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await axios.post(url, body, head);
      return JSON.parse(JSON.stringify(response));
    } catch (error) {

      if (error?.response) {
        throw new Error(JSON.parse(JSON.stringify(error?.response)));
      }
      throw new Error(error);
    }
  });

export const postUploadCoApplicantFrontByAnchorEmail = createAsyncThunk("post/kyc/coApplicants/docs_for_cp",
  async (body) => {
    try {
      const url = `${DOC_URL}kyc/docs_for_cp`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await axios.post(url, body, head);

      return JSON.parse(JSON.stringify(response));
    } catch (error) {

      if (error?.response) {
        throw new Error(JSON.parse(JSON.stringify(error?.response)));
      }
      throw new Error(error);
    }
  });

export const postUploadBankDetailsBankName = createAsyncThunk("post/bankDetails/bankStatements",
  async (body) => {
    try {
      const url = `${DOC_URL}kyc/docs_for_cp`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await axios.post(url, body, head);

      return JSON.parse(JSON.stringify(response));
    } catch (error) {

      if (error?.response) {
        throw new Error(JSON.parse(JSON.stringify(error?.response)));
      }
      throw new Error(error);
    }
  });

export const postBankDetailsIFSCCodeVerification = createAsyncThunk("post/bankDetails/IFSC/code",
  async (body) => {
    try {
      const url = `${BASE_URL}validation/ifsc`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await axios.post(url, body, head);
      return JSON.parse(JSON.stringify(response));
    } catch (error) {
      if (error?.response) {
        //console.log(error?.response?.data?.error)
        throw new Error(error?.response?.data?.error);
      }
      throw new Error(error);
    }
  });

export const getGSTDetailsGSTNumberValidate = createAsyncThunk("get/GST/number/validate",
  async (GSTNumber) => {
    try {
      const url = `${BASE_URL}user/verify_gst/${GSTNumber}`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await axios.get(url, head);
      return JSON.parse(JSON.stringify(response));
    } catch (error) {
      if (error?.response) {
        throw new Error(error?.response?.data?.error);
      }
      throw new Error(error);
    }
  }
)

export const otherDetailsUploadDocument = createAsyncThunk("get/kyc/docs_for_cp/otherDetails/LoadAmount",
  async (body) => {
    try {
      const url = `${DOC_URL}kyc/docs_for_cp`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await axios.get(url, body, head);
      return JSON.parse(JSON.stringify(response));
    } catch (error) {
      if (error?.response) {
        throw new Error(error?.response?.data?.error);
      }
      throw new Error(error);
    }
  }
)

export const getRMInvoicesData = createAsyncThunk(
  "get/RMInvoices",
  async (body) => {
    try {
      const url = `/relationmanager/invoice/manual?limit=${body?.limit}&offset=${body?.offset}`;
      const response = await api.post(url, body, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

export const getListOfApprovedUsers = createAsyncThunk(
  "get/listOfApprovedUsers",
  async (body) => {
    try {
      const url = `/relationmanager/list_of_approved_users`;
      const response = await api.get(url, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data.customers;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

const rmSlice = createSlice({
  name: "rm",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      // total_rejected_users
      .addCase(getTotalRejectedUsers.pending, (state, action) => {
        state.totalRejectedUsersData.isLoading = true;
        state.totalRejectedUsersData.data = null;
        state.totalRejectedUsersData.error = null;
      })
      .addCase(getTotalRejectedUsers.fulfilled, (state, action) => {
        state.totalRejectedUsersData.data = action.payload;
        state.totalRejectedUsersData.isLoading = false;
        state.totalRejectedUsersData.error = null;
      })
      .addCase(getTotalRejectedUsers.rejected, (state, action) => {
        state.totalRejectedUsersData.error = action.error.message;
        state.totalRejectedUsersData.isLoading = false;
        state.totalRejectedUsersData.data = null;
      })

      // total_approved_users
      .addCase(getTotalApprovedUsers.pending, (state, action) => {
        state.totalApprovedUsersData.isLoading = true;
        state.totalApprovedUsersData.data = null;
        state.totalApprovedUsersData.error = null;
      })
      .addCase(getTotalApprovedUsers.fulfilled, (state, action) => {
        state.totalApprovedUsersData.data = action.payload;
        state.totalApprovedUsersData.isLoading = false;
        state.totalApprovedUsersData.error = null;

      })
      .addCase(getTotalApprovedUsers.rejected, (state, action) => {
        state.totalApprovedUsersData.error = action.error.message;
        state.totalApprovedUsersData.data = null;
        state.totalApprovedUsersData.isLoading = false;
      })

      // total_outstanding_invoices
      .addCase(getTotalOutstandingInvoices.pending, (state, action) => {
        state.totalOutstandingInvoicesData.isLoading = true;
        state.totalOutstandingInvoicesData.data = null;
        state.totalOutstandingInvoicesData.error = null;

      })
      .addCase(getTotalOutstandingInvoices.fulfilled, (state, action) => {
        state.totalOutstandingInvoicesData.data = action.payload;
        state.totalOutstandingInvoicesData.isLoading = false;
        state.totalOutstandingInvoicesData.error = null;

      })
      .addCase(getTotalOutstandingInvoices.rejected, (state, action) => {
        state.totalOutstandingInvoicesData.error = action.error.message;
        state.totalOutstandingInvoicesData.data = null;
        state.totalOutstandingInvoicesData.isLoading = false;
      })


      // total_not_discounted_invoices
      .addCase(getTotalNotDiscountedInvoices.pending, (state, action) => {
        state.totalNotDiscountedInvoicesData.isLoading = true;
        state.totalNotDiscountedInvoicesData.data = null;
        state.totalNotDiscountedInvoicesData.error = null;

      })
      .addCase(getTotalNotDiscountedInvoices.fulfilled, (state, action) => {
        state.totalNotDiscountedInvoicesData.data = action.payload;
        state.totalNotDiscountedInvoicesData.isLoading = false;
        state.totalNotDiscountedInvoicesData.error = null;

      })
      .addCase(getTotalNotDiscountedInvoices.rejected, (state, action) => {
        state.totalNotDiscountedInvoicesData.error = action.error.message;
        state.totalNotDiscountedInvoicesData.data = null;
        state.totalNotDiscountedInvoicesData.isLoading = false;
      })


      // total_pending_users
      .addCase(getTotalPendingUsers.pending, (state, action) => {
        state.totalPendingUsersData.isLoading = true;
        state.totalPendingUsersData.data = null;
        state.totalPendingUsersData.error = null;


      })
      .addCase(getTotalPendingUsers.fulfilled, (state, action) => {
        state.totalPendingUsersData.data = action.payload;
        state.totalPendingUsersData.isLoading = false;
        state.totalPendingUsersData.error = null;

      })
      .addCase(getTotalPendingUsers.rejected, (state, action) => {
        state.totalPendingUsersData.error = action.error.message;
        state.totalPendingUsersData.data = null;
        state.totalPendingUsersData.isLoading = false;
      })


      // total_onboarded_users
      .addCase(getTotalOnboardingUsers.pending, (state, action) => {
        state.totalOnboardingUsersData.isLoading = true;
        state.totalOnboardingUsersData.data = null;
        state.totalOnboardingUsersData.error = null;

      })
      .addCase(getTotalOnboardingUsers.fulfilled, (state, action) => {
        state.totalOnboardingUsersData.data = action.payload;
        state.totalOnboardingUsersData.isLoading = false;
        state.totalOnboardingUsersData.error = null;

      })
      .addCase(getTotalOnboardingUsers.rejected, (state, action) => {
        state.totalOnboardingUsersData.error = action.error.message;
        state.totalOnboardingUsersData.data = null;
        state.totalOnboardingUsersData.isLoading = false;
      })

      // List Of Customer Onboarding
      .addCase(getListOfCustomersOnboarding.pending, (state, action) => {
        state.listOfCustomersOnboardingData.isLoading = true;
        state.listOfCustomersOnboardingData.data = null;
        state.listOfCustomersOnboardingData.error = null;

      })
      .addCase(getListOfCustomersOnboarding.fulfilled, (state, action) => {
        state.listOfCustomersOnboardingData.data = action.payload;
        state.listOfCustomersOnboardingData.isLoading = false;
        state.listOfCustomersOnboardingData.error = null;

      })
      .addCase(getListOfCustomersOnboarding.rejected, (state, action) => {
        state.listOfCustomersOnboardingData.error = action.error.message;
        state.listOfCustomersOnboardingData.isLoading = false;
        state.listOfCustomersOnboardingData.data = null;
      })

      // Check if Email is registered or not
      .addCase(getIsEmailRegistered.pending, (state, action) => {
        state.isEmailRegistered.isLoading = true;
        state.isEmailRegistered.data = null;
        state.isEmailRegistered.error = null;
      })
      .addCase(getIsEmailRegistered.fulfilled, (state, action) => {
        state.isEmailRegistered.data = action.payload;
        state.isEmailRegistered.isLoading = false;
        state.isEmailRegistered.error = null;
      })
      .addCase(getIsEmailRegistered.rejected, (state, action) => {
        state.isEmailRegistered.error = action.error.message;
        state.isEmailRegistered.data = null;
        state.isEmailRegistered.isLoading = false;
      })

      // Check if Email is registered or not
      .addCase(postPanCardVerification.pending, (state, action) => {
        state.isPanVerification.isLoading = true;
        state.isPanVerification.data = null;
        state.isPanVerification.error = null;

      })
      .addCase(postPanCardVerification.fulfilled, (state, action) => {
        state.isPanVerification.data = action.payload;
        state.isPanVerification.isLoading = false;
        state.isPanVerification.error = null;

      })
      .addCase(postPanCardVerification.rejected, (state, action) => {
        state.isPanVerification.error = action.error.message;
        state.isPanVerification.data = null;
        state.isPanVerification.isLoading = false;
      })

      // upload Aadhaar front with MR Email
      .addCase(postUploadAadhaarFrontByRMEmail.pending, (state, action) => {
        state.uploadAadhaarFrontByRMEmail.isLoading = true;
        state.uploadAadhaarFrontByRMEmail.data = null;
        state.uploadAadhaarFrontByRMEmail.error = null
      })
      .addCase(postUploadAadhaarFrontByRMEmail.fulfilled, (state, action) => {
        state.uploadAadhaarFrontByRMEmail.data = action.payload;
        state.uploadAadhaarFrontByRMEmail.isLoading = false;
        state.uploadAadhaarFrontByRMEmail.error = null
      })
      .addCase(postUploadAadhaarFrontByRMEmail.rejected, (state, action) => {
        state.uploadAadhaarFrontByRMEmail.error = action.error.message;
        state.uploadAadhaarFrontByRMEmail.data = null;
        state.uploadAadhaarFrontByRMEmail.isLoading = false;
      })

      // upload Aadhaar front with Anchor Email
      .addCase(postUploadAadhaarFrontByAnchorEmail.pending, (state, action) => {
        state.uploadAadhaarFrontByAnchorEmail.isLoading = true;
        state.uploadAadhaarFrontByAnchorEmail.error = null
        state.uploadAadhaarFrontByAnchorEmail.data = null;

      })
      .addCase(postUploadAadhaarFrontByAnchorEmail.fulfilled, (state, action) => {
        state.uploadAadhaarFrontByAnchorEmail.data = action.payload;
        state.uploadAadhaarFrontByAnchorEmail.isLoading = false;
        state.uploadAadhaarFrontByAnchorEmail.error = null
      })
      .addCase(postUploadAadhaarFrontByAnchorEmail.rejected, (state, action) => {
        state.uploadAadhaarFrontByAnchorEmail.data = null;
        state.uploadAadhaarFrontByAnchorEmail.error = action.error.message;
        state.uploadAadhaarFrontByAnchorEmail.isLoading = false;
      })

      // upload Aadhaar front with MR Email
      .addCase(postUploadAadhaarBackByRMEmail.pending, (state, action) => {
        state.uploadAadhaarBackByRMEmail.isLoading = true;
        state.uploadAadhaarBackByRMEmail.data = null;
        state.uploadAadhaarBackByRMEmail.error = null;


      })
      .addCase(postUploadAadhaarBackByRMEmail.fulfilled, (state, action) => {
        state.uploadAadhaarBackByRMEmail.data = action.payload;
        state.uploadAadhaarBackByRMEmail.isLoading = false;
        state.uploadAadhaarBackByRMEmail.error = null;

      })
      .addCase(postUploadAadhaarBackByRMEmail.rejected, (state, action) => {
        state.uploadAadhaarBackByRMEmail.error = action.error.message;
        state.uploadAadhaarBackByRMEmail.isLoading = false;
        state.uploadAadhaarBackByRMEmail.data = null;

      })

      // upload Aadhaar Back with Anchor Email
      .addCase(postUploadAadhaarBackByAnchorEmail.pending, (state, action) => {
        state.uploadAadhaarBackByAnchorEmail.isLoading = true;
        state.uploadAadhaarBackByAnchorEmail.data = null;
        state.uploadAadhaarBackByAnchorEmail.error = null;

      })
      .addCase(postUploadAadhaarBackByAnchorEmail.fulfilled, (state, action) => {
        state.uploadAadhaarBackByAnchorEmail.data = action.payload;
        state.uploadAadhaarBackByAnchorEmail.isLoading = false;
        state.uploadAadhaarBackByAnchorEmail.error = null;

      })
      .addCase(postUploadAadhaarBackByAnchorEmail.rejected, (state, action) => {
        state.uploadAadhaarBackByAnchorEmail.error = action.error.message;
        state.uploadAadhaarBackByAnchorEmail.data = null;
        state.uploadAadhaarBackByAnchorEmail.isLoading = false;
      })

      // upload Profile Pic
      .addCase(postUploadProfilePic.pending, (state, action) => {
        state.uploadProfilePic.isLoading = true;
        state.uploadProfilePic.data = null;
        state.uploadProfilePic.error = null;


      })
      .addCase(postUploadProfilePic.fulfilled, (state, action) => {
        state.uploadProfilePic.data = action.payload;
        state.uploadProfilePic.isLoading = false;
        state.uploadProfilePic.error = null;

      })
      .addCase(postUploadProfilePic.rejected, (state, action) => {
        state.uploadProfilePic.error = action.error.message;
        state.uploadProfilePic.data = null;
        state.uploadProfilePic.isLoading = false;
      })

      // upload Profile Pic
      .addCase(postCoApplicantIsPanVerified.pending, (state, action) => {
        state.coApplicantIsPanVerification.isLoading = true;
        state.coApplicantIsPanVerification.data = null;
        state.coApplicantIsPanVerification.error = null;


      })
      .addCase(postCoApplicantIsPanVerified.fulfilled, (state, action) => {
        state.coApplicantIsPanVerification.data = action.payload;
        state.coApplicantIsPanVerification.isLoading = false;
        state.coApplicantIsPanVerification.error = null;

      })
      .addCase(postCoApplicantIsPanVerified.rejected, (state, action) => {
        state.coApplicantIsPanVerification.error = action.error.message;
        state.coApplicantIsPanVerification.data = null;
        state.coApplicantIsPanVerification.isLoading = false;
      })


      // upload Aadhaar Card Co-Applicant by RMEmail
      .addCase(postUploadCoApplicantAadhaarFrontByRMEmail.pending, (state, action) => {
        state.uploadCoApplicantAadhaarFrontByRMEmail.isLoading = true;
        state.uploadCoApplicantAadhaarFrontByRMEmail.data = null;
        state.uploadCoApplicantAadhaarFrontByRMEmail.error = null;


      })
      .addCase(postUploadCoApplicantAadhaarFrontByRMEmail.fulfilled, (state, action) => {
        state.uploadCoApplicantAadhaarFrontByRMEmail.data = action.payload;
        state.uploadCoApplicantAadhaarFrontByRMEmail.isLoading = false;
        state.uploadCoApplicantAadhaarFrontByRMEmail.error = null;

      })
      .addCase(postUploadCoApplicantAadhaarFrontByRMEmail.rejected, (state, action) => {
        state.uploadCoApplicantAadhaarFrontByRMEmail.error = action.error.message;
        state.uploadCoApplicantAadhaarFrontByRMEmail.data = null;
        state.uploadCoApplicantAadhaarFrontByRMEmail.isLoading = false;
      })



      // upload Aadhaar Card Co-Applicant by AnchorEmail
      .addCase(postUploadCoApplicantFrontByAnchorEmail.pending, (state, action) => {
        state.uploadCoApplicantFrontByAnchorEmail.isLoading = true;
        state.uploadCoApplicantFrontByAnchorEmail.data = null;
        state.uploadCoApplicantFrontByAnchorEmail.error = null;


      })
      .addCase(postUploadCoApplicantFrontByAnchorEmail.fulfilled, (state, action) => {
        state.uploadCoApplicantFrontByAnchorEmail.data = action.payload;
        state.uploadCoApplicantFrontByAnchorEmail.isLoading = false;
        state.uploadCoApplicantFrontByAnchorEmail.error = null;

      })
      .addCase(postUploadCoApplicantFrontByAnchorEmail.rejected, (state, action) => {
        state.uploadCoApplicantFrontByAnchorEmail.error = action.error.message;
        state.uploadCoApplicantFrontByAnchorEmail.data = null;
        state.uploadCoApplicantFrontByAnchorEmail.isLoading = false;
      })

      // upload BankDetails Bank Statements
      .addCase(postUploadBankDetailsBankName.pending, (state, action) => {
        state.bankDetailsBankStatements.isLoading = true;
        state.bankDetailsBankStatements.data = null;
        state.bankDetailsBankStatements.error = null;
      })
      .addCase(postUploadBankDetailsBankName.fulfilled, (state, action) => {
        state.bankDetailsBankStatements.data = action.payload;
        state.bankDetailsBankStatements.isLoading = false;
        state.bankDetailsBankStatements.error = null;

      })
      .addCase(postUploadBankDetailsBankName.rejected, (state, action) => {
        state.bankDetailsBankStatements.error = action.error.message;
        state.bankDetailsBankStatements.data = null;
        state.bankDetailsBankStatements.isLoading = false;
      })

      // upload BankDetails Bank Statements
      .addCase(postBankDetailsIFSCCodeVerification.pending, (state, action) => {
        state.bankDetailsIFSCCode.isLoading = true;
        state.bankDetailsIFSCCode.data = null;
        state.bankDetailsIFSCCode.error = null;
      })
      .addCase(postBankDetailsIFSCCodeVerification.fulfilled, (state, action) => {
        state.bankDetailsIFSCCode.data = action.payload;
        state.bankDetailsIFSCCode.isLoading = false;
        state.bankDetailsIFSCCode.error = null;

      })
      .addCase(postBankDetailsIFSCCodeVerification.rejected, (state, action) => {
        state.bankDetailsIFSCCode.error = action.error.message;
        state.bankDetailsIFSCCode.data = null;
        state.bankDetailsIFSCCode.isLoading = false;
      })

      // GST Details GST Number Validation
      .addCase(getGSTDetailsGSTNumberValidate.pending, (state, action) => {
        state.GSTDetailsGSTNumberValidate.isLoading = true;
        state.GSTDetailsGSTNumberValidate.data = null;
        state.GSTDetailsGSTNumberValidate.error = null;
      })
      .addCase(getGSTDetailsGSTNumberValidate.fulfilled, (state, action) => {
        state.GSTDetailsGSTNumberValidate.data = action.payload;
        state.GSTDetailsGSTNumberValidate.isLoading = false;
        state.GSTDetailsGSTNumberValidate.error = null;

      })
      .addCase(getGSTDetailsGSTNumberValidate.rejected, (state, action) => {
        state.GSTDetailsGSTNumberValidate.error = action.error.message;
        state.GSTDetailsGSTNumberValidate.data = null;
        state.GSTDetailsGSTNumberValidate.isLoading = false;
      })

      // GST Details GST Number Validation
      .addCase(otherDetailsUploadDocument.pending, (state, action) => {
        state.otherDetailsUploadDocument.isLoading = true;
        state.otherDetailsUploadDocument.data = null;
        state.otherDetailsUploadDocument.error = null;
      })
      .addCase(otherDetailsUploadDocument.fulfilled, (state, action) => {
        state.otherDetailsUploadDocument.data = action.payload;
        state.otherDetailsUploadDocument.isLoading = false;
        state.otherDetailsUploadDocument.error = null;

      })
      .addCase(otherDetailsUploadDocument.rejected, (state, action) => {
        state.otherDetailsUploadDocument.error = action.error.message;
        state.otherDetailsUploadDocument.data = null;
        state.otherDetailsUploadDocument.isLoading = false;
      })

      .addCase(rmOnboardAnchorDispatch.pending, (state, action) => {
        state.onboardAnchor.isLoading = true;
        state.onboardAnchor.data = null;
        state.onboardAnchor.error = null; 
      })
      .addCase(rmOnboardAnchorDispatch.fulfilled, (state, action) => {
        state.onboardAnchor.isLoading = false;
        state.onboardAnchor.data = action.payload;
        state.onboardAnchor.error = null;
      })
      .addCase(rmOnboardAnchorDispatch.rejected, (state, action) => {
        state.onboardAnchor.isLoading = false;
        state.onboardAnchor.data = null;
        state.onboardAnchor.error = action.error.message;
      })
      
      .addCase(rmChangePassword.pending, (state, action) => {
        state.rmUpdatePassword.isLoading = true;
        state.rmUpdatePassword.data = null;
        state.rmUpdatePassword.error = null; 
      })
      .addCase(rmChangePassword.fulfilled, (state, action) => {
        state.rmUpdatePassword.isLoading = false;
        state.rmUpdatePassword.data = action.payload;
        state.rmUpdatePassword.error = null;
      })
      .addCase(rmChangePassword.rejected, (state, action) => {
        state.rmUpdatePassword.isLoading = false;
        state.rmUpdatePassword.data = null;
        state.rmUpdatePassword.error = action.error.message;
      })

      .addCase(rmOnboardCounterPartyDispatch.pending, (state, action) => {
        state.onboardCounterParty.isLoading = true;
        state.onboardCounterParty.data = null;
        state.onboardCounterParty.error = null; 
      })
      .addCase(rmOnboardCounterPartyDispatch.fulfilled, (state, action) => {
        state.onboardCounterParty.isLoading = false;
        state.onboardCounterParty.data = action.payload;
        state.onboardCounterParty.error = null;
      })
      .addCase(rmOnboardCounterPartyDispatch.rejected, (state, action) => {
        state.onboardCounterParty.isLoading = false;
        state.onboardCounterParty.data = null;
        state.onboardCounterParty.error = action.error.message;
      })
      
      .addCase(rmUploadFiles.pending, (state, action) => {
        state.uploadFiles.isLoading = true;
        state.uploadFiles.data = null;
        state.uploadFiles.error = null; 
      })
      .addCase(rmUploadFiles.fulfilled, (state, action) => {
        state.uploadFiles.isLoading = false;
        state.uploadFiles.data = action.payload;
        state.uploadFiles.error = null;
      })
      .addCase(rmUploadFiles.rejected, (state, action) => {
        state.uploadFiles.isLoading = false;
        state.uploadFiles.data = null;
        state.uploadFiles.error = action.error.message;
      })
      
      .addCase(fetchFilesOfAnchorAndCounterParty.pending, (state, action) => {
        state.fetchFilesOfAnchorAndCounterParty.isLoading = true;
        state.fetchFilesOfAnchorAndCounterParty.data = null;
        state.fetchFilesOfAnchorAndCounterParty.error = null; 
      })
      .addCase(fetchFilesOfAnchorAndCounterParty.fulfilled, (state, action) => {
        state.fetchFilesOfAnchorAndCounterParty.isLoading = false;
        state.fetchFilesOfAnchorAndCounterParty.data = action.payload;
        state.fetchFilesOfAnchorAndCounterParty.error = null;
      })
      .addCase(fetchFilesOfAnchorAndCounterParty.rejected, (state, action) => {
        state.fetchFilesOfAnchorAndCounterParty.isLoading = false;
        state.fetchFilesOfAnchorAndCounterParty.data = null;
        state.fetchFilesOfAnchorAndCounterParty.error = action.error.message;
      })
      
      .addCase(fetchOnBoardedUsersDetails.pending, (state, action) => {
        state.fetchOnBoardedUsersDetails.isLoading = true;
        state.fetchOnBoardedUsersDetails.data = null;
        state.fetchOnBoardedUsersDetails.error = null; 
      })
      .addCase(fetchOnBoardedUsersDetails.fulfilled, (state, action) => {
        state.fetchOnBoardedUsersDetails.isLoading = false;
        state.fetchOnBoardedUsersDetails.data = action.payload;
        state.fetchOnBoardedUsersDetails.error = null;
      })
      .addCase(fetchOnBoardedUsersDetails.rejected, (state, action) => {
        state.fetchOnBoardedUsersDetails.isLoading = false;
        state.fetchOnBoardedUsersDetails.data = null;
        state.fetchOnBoardedUsersDetails.error = action.error.message;
      })

      .addCase(getRMInvoicesData.pending, (state, action) => {
        state.rmInvoicesData.isLoading = true;
        state.rmInvoicesData.data = null;
        state.rmInvoicesData.error = null; 
      })
      .addCase(getRMInvoicesData.fulfilled, (state, action) => {
        state.rmInvoicesData.isLoading = false;
        state.rmInvoicesData.data = action.payload;
        state.rmInvoicesData.error = null;
      })
      .addCase(getRMInvoicesData.rejected, (state, action) => {
        state.rmInvoicesData.isLoading = false;
        state.rmInvoicesData.data = null;
        state.rmInvoicesData.error = action.error.message;
      })

      .addCase(getListOfApprovedUsers.pending, (state, action) => {
        state.listOfApprovedUsers.isLoading = true;
        state.listOfApprovedUsers.data = null;
        state.listOfApprovedUsers.error = null; 
      })
      .addCase(getListOfApprovedUsers.fulfilled, (state, action) => {
        state.listOfApprovedUsers.isLoading = false;
        state.listOfApprovedUsers.data = action.payload;
        state.listOfApprovedUsers.error = null;
      })
      .addCase(getListOfApprovedUsers.rejected, (state, action) => {
        state.listOfApprovedUsers.isLoading = false;
        state.listOfApprovedUsers.data = null;
        state.listOfApprovedUsers.error = action.error.message;
      })
  },
});


export default rmSlice.reducer;
