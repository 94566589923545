import React, { Fragment, useState } from "react";
import Loader from "../../../layout/GenericLayout/Loader";
import { useDispatch, useSelector } from "react-redux";
import { toastMessage } from "../../../layout/GenericLayout/ToastMessage";
import ReusableModal from "../../../layout/GenericLayout/ReusableModal";
import ScoreCardDetails from "../LenderScoreCard/ScoreCardDetails";
import {
  getScoreCardDetails,
  getScoreCardDataByEmailId,
} from "../../../store/slices/generic";
import { fetchCounterPartyUsingAnchorId, updateCreditLimitStatus } from "../../../store/slices/lender";
import { BsPlusCircleFill, BsDashCircleFill } from "react-icons/bs";

const ApproveLimitTable = (props) => {
  const dispatch = useDispatch();
  const [activeScoreCardEmailId, setActiveScoreCardEmailId] = useState(null);

  const [expandedRow, setExpandedRow] = useState(null);

  const [showScoreCardDetailsModal, setShowScoreCardDetailsModal] =
    useState(false);
  const scoreCardByEmailIdData = useSelector(
    (state) => state.generic.scoreCardByEmailIdData
  );
  const scoreCardDetails = useSelector(
    (state) => state.generic.scoreCardDetails
  );

  const allUsersByCustomerIdData = useSelector(state => state.lender.fetchCounterPartyUsingAnchorId);
  console.log("all users by id", allUsersByCustomerIdData);

  const updatePage = (currentPage) => {
    const newOffSet = props.tableQueryParams.tableLimit * (currentPage - 1);
    props.setTableQueryParams((prev) => ({
      ...prev,
      tableOffset: newOffSet,
      currentPage,
    }));
  };

  const onClickActiveSort = (activeSortValue) => {
    if (activeSortValue !== "") {
      let order = -1;
      if (activeSortValue === props.tableQueryParams.tableSort) {
        if (props.tableQueryParams.tableOrder === -1) {
          order = 1;
        }
      }
      props.setTableQueryParams((prev) => ({
        ...prev,
        tableSort: activeSortValue,
        tableOrder: order,
      }));
    }
  };

  const onClickViewMoreTableData = () => {
    let newLimit = props.tableQueryParams.tableLimit;
    if (newLimit === 5) {
      newLimit += 5;
    } else {
      newLimit -= 5;
    }
    props.setTableQueryParams((prev) => ({
      ...prev,
      tableLimit: newLimit,
    }));
  };

  const toggleScoreCardDetailsModal = () => {
    setShowScoreCardDetailsModal((prev) => !prev);
  };

  const onClickScoreCardDetails = (emailId) => {
    setActiveScoreCardEmailId(emailId);
    dispatch(getScoreCardDataByEmailId(emailId))
      .unwrap()
      .then(() => {
        dispatch(getScoreCardDetails(emailId))
          .unwrap()
          .then((response) => {
            toggleScoreCardDetailsModal();
          })
          .catch((error) => {
            toastMessage(error.message, "error");
          });
      })
      .catch((error) => {
        toastMessage(error.message, "error");
      });
  };

  /**
   *
   * @param { string } customerId
   * @param { 'approved' | 'rejected' } status
   */
  function handleUpdateCreditLimitRowStatus(customerId, status) {
    const lenderId = sessionStorage.getItem("customerId");

    if (lenderId === null) {
      toastMessage("Lender id is not available", "error");
      return;
    } else if (customerId === "") {
      toastMessage("Customer Id is invalid", "error");
      return;
    }

    dispatch(
      updateCreditLimitStatus({ customerId, lenderId, maxLimitStatus: status })
    )
      .unwrap()
      .then((response) => {
        console.log("status update response", response);
        props.refreshTable();
        toastMessage("Status Updated Successfully", "success");
      })
      .catch((err) => {
        toastMessage(err.message, "error");
      });
  }

  function onClickDisplayCounterParty(customerId, index) {

    dispatch(fetchCounterPartyUsingAnchorId(customerId));

    setExpandedRow((prevExpandedRow) =>
      prevExpandedRow === index ? null : index
    );
  }

  return (
    <>
      {showScoreCardDetailsModal && (
        <ReusableModal
          showModal={showScoreCardDetailsModal}
          toggleModal={toggleScoreCardDetailsModal}
          modalContent={
            <ScoreCardDetails
              scoreCardDetails={scoreCardDetails}
              scoreCardByEmailIdData={scoreCardByEmailIdData}
            />
          }
          modalSizeClass="modal-xl"
        />
      )}
      <div className="lender-table-container">
        <div className="table-inner-cont">
          <table className={props.className}>
            <thead>
              <tr>
                {props.tableHeader.map((eachHeader) => (
                  <th
                    key={eachHeader.name}
                    scope="col"
                  // role={eachHeader.sortValue !== "" ? "button" : false}
                  // className={`${
                  //   props.tableQueryParams.tableSort ===
                  //     eachHeader.sortValue &&
                  //   eachHeader.sortValue !== "" &&
                  //   "active-sort-text"
                  // }`}
                  // onClick={() => onClickActiveSort(eachHeader.sortValue)}
                  >
                    {eachHeader.name}
                  </th>
                ))}
              </tr>
            </thead>
            {props.isLoading ? (
              <tbody>
                <tr>
                  <td colSpan={5}>
                    <Loader className="my-5" />
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {props.tableRows.map((eachRows, index) => (
                  <Fragment>
                    <tr key={eachRows.customerId} scope="col">
                      <td>
                        {
                          expandedRow === index ?
                          <BsDashCircleFill onClick={ () => setExpandedRow(null) } className="rm-plus-button fs-5" /> :
                          <BsPlusCircleFill onClick={() => onClickDisplayCounterParty(eachRows.customerId, index)} className={`rm-plus-button fs-5 ${expandedRow === index && "sub-table-expand-button"}`} />
                        }
                      </td>
                      <td>{eachRows.customerId}</td>
                      <td>{eachRows.companyFullName}</td>
                      <td>{eachRows.maxLimit}</td>
                      <td>{eachRows.totalUtilisedAmnt}</td>
                      <td>{eachRows.availableAmnt}</td>
                      <td>{eachRows.holdAmnt}</td>
                      <td>{eachRows.suspendedAmnt}</td>
                      <td>{eachRows.grade}</td>
                      <td>{eachRows.maxLateCharge}</td>
                      <td>{eachRows.limitExpiry}</td>
                      <td>{eachRows.limitNextRenewal}</td>
                      <td>{eachRows.lineOfCredit}</td>
                      <td
                        style={{ cursor: "pointer" }}
                        onClick={() => onClickScoreCardDetails(eachRows.emailId)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="26.134"
                          height="20.259"
                          viewBox="0 0 26.134 20.259"
                        >
                          <defs>
                            <linearGradient
                              id="linear-gradient"
                              x1="-0.067"
                              y1="1.022"
                              x2="1.081"
                              y2="1"
                              gradientUnits="objectBoundingBox"
                            >
                              <stop offset="0" stopColor="#ff6363" />
                              <stop offset="0.207" stopColor="#d59759" />
                              <stop offset="0.296" stopColor="#c3aa55" />
                              <stop offset="0.571" stopColor="#efc53c" />
                              <stop offset="1" stopColor="#21c91a" />
                            </linearGradient>
                          </defs>
                          <path
                            id="tachometer-fast-alt"
                            d="M15.067,5A13.067,13.067,0,0,0,3.751,24.6a1.311,1.311,0,1,0,2.274-1.307,10.349,10.349,0,0,1-1.411-5.227,10.454,10.454,0,1,1,19.509,5.227A1.306,1.306,0,1,0,26.383,24.6,13.067,13.067,0,0,0,15.067,5Zm3.711,7.527-2.025,2.012a3.8,3.8,0,0,0-1.686-.392,3.92,3.92,0,1,0,3.92,3.92,3.789,3.789,0,0,0-.392-1.673l2.025-2.012a1.307,1.307,0,1,0-1.842-1.856Zm-3.711,6.847a1.307,1.307,0,1,1,.915-2.248h0a1.312,1.312,0,0,1-.915,2.248Z"
                            transform="translate(-2 -5)"
                            fill="url(#linear-gradient)"
                          />
                        </svg>
                      </td>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="25.62"
                          height="20.13"
                          viewBox="0 0 25.62 20.13"
                        >
                          <path
                            id="Icon_ionic-md-card"
                            data-name="Icon ionic-md-card"
                            d="M25.308,5.625H4.812A2.52,2.52,0,0,0,2.261,8.141l-.011,15.1a2.53,2.53,0,0,0,2.562,2.516h20.5a2.53,2.53,0,0,0,2.562-2.516V8.141A2.53,2.53,0,0,0,25.308,5.625Zm0,17.614H4.812V15.69h20.5Zm0-12.581H4.812V8.141h20.5Z"
                            transform="translate(-2.25 -5.625)"
                            fill="#2e3c83"
                          />
                        </svg>
                      </td>
                      <td>
                        {eachRows.role === "seller" ? "Anchor" : "Counter Party"}
                      </td>
                      <td>
                        <div
                          className="d-flex justify-content-center gap-2 w-100"
                          style={{ fontSize: "1.1rem" }}
                        >
                          {eachRows.maxLimitStatus === "approved" ? (
                            <span className="text-capitalize">
                              {eachRows.maxLimitStatus}
                            </span>
                          ) : (
                            <>
                              <button
                                className="btn btn-success"
                                onClick={() =>
                                  handleUpdateCreditLimitRowStatus(
                                    eachRows?.customerId ?? "",
                                    "approved"
                                  )
                                }
                                disabled={eachRows?.maxLimitStatus === "approved"}
                              >
                                Approve
                              </button>
                              <button
                                className="btn btn-danger"
                                onClick={() =>
                                  handleUpdateCreditLimitRowStatus(
                                    eachRows?.customerId ?? "",
                                    "rejected"
                                  )
                                }
                                disabled={eachRows?.maxLimitStatus === "approved"}
                              >
                                Reject
                              </button>
                            </>
                          )}
                        </div>
                      </td>
                      {/* <td><span >Approve</span><span id="Create4">sent back</span><span id="Create5">Reject</span></td> */}
                    </tr>

                    {expandedRow === index && (
                      <>
                        {allUsersByCustomerIdData.isLoading && (
                          <tr>
                            <td colSpan={8}>
                              <Loader className="my-5" />
                            </td>
                          </tr>
                        )}
                        {allUsersByCustomerIdData.error && (
                          <tr>
                            <td
                              colSpan={8}
                              className="text-center fs-4 text-danger"
                            >
                              {allUsersByCustomerIdData.error}
                            </td>
                          </tr>
                        )}
                        {allUsersByCustomerIdData.data &&
                          allUsersByCustomerIdData.data.success.map((eachRows) => (
                            <tr key={eachRows.customerId} scope="col" style={{ fontWeight: "400", }}>
                              <td></td>
                              <td style={{ fontWeight: "400", }}>{eachRows.customerId}</td>
                              <td style={{ fontWeight: "400", }}>{eachRows.companyFullName}</td>
                              <td style={{ fontWeight: "400", }}>{eachRows.maxLimit}</td>
                              <td style={{ fontWeight: "400", }}>{eachRows.totalUtilisedAmnt}</td>
                              <td style={{ fontWeight: "400", }}>{eachRows.availableAmnt}</td>
                              <td style={{ fontWeight: "400", }}>{eachRows.holdAmnt}</td>
                              <td style={{ fontWeight: "400", }}>{eachRows.suspendedAmnt}</td>
                              <td style={{ fontWeight: "400", }}>{eachRows.grade}</td>
                              <td style={{ fontWeight: "400", }}>{eachRows.maxLateCharge}</td>
                              <td style={{ fontWeight: "400", }}>{eachRows.limitExpiry}</td>
                              <td style={{ fontWeight: "400", }}>{eachRows.limitNextRenewal}</td>
                              <td style={{ fontWeight: "400", }}>{eachRows.lineOfCredit}</td>
                              <td
                                style={{ cursor: "pointer", fontWeight: "400", }}
                                onClick={() => onClickScoreCardDetails(eachRows.emailId)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  width="26.134"
                                  height="20.259"
                                  viewBox="0 0 26.134 20.259"
                                >
                                  <defs>
                                    <linearGradient
                                      id="linear-gradient"
                                      x1="-0.067"
                                      y1="1.022"
                                      x2="1.081"
                                      y2="1"
                                      gradientUnits="objectBoundingBox"
                                    >
                                      <stop offset="0" stopColor="#ff6363" />
                                      <stop offset="0.207" stopColor="#d59759" />
                                      <stop offset="0.296" stopColor="#c3aa55" />
                                      <stop offset="0.571" stopColor="#efc53c" />
                                      <stop offset="1" stopColor="#21c91a" />
                                    </linearGradient>
                                  </defs>
                                  <path
                                    id="tachometer-fast-alt"
                                    d="M15.067,5A13.067,13.067,0,0,0,3.751,24.6a1.311,1.311,0,1,0,2.274-1.307,10.349,10.349,0,0,1-1.411-5.227,10.454,10.454,0,1,1,19.509,5.227A1.306,1.306,0,1,0,26.383,24.6,13.067,13.067,0,0,0,15.067,5Zm3.711,7.527-2.025,2.012a3.8,3.8,0,0,0-1.686-.392,3.92,3.92,0,1,0,3.92,3.92,3.789,3.789,0,0,0-.392-1.673l2.025-2.012a1.307,1.307,0,1,0-1.842-1.856Zm-3.711,6.847a1.307,1.307,0,1,1,.915-2.248h0a1.312,1.312,0,0,1-.915,2.248Z"
                                    transform="translate(-2 -5)"
                                    fill="url(#linear-gradient)"
                                  />
                                </svg>
                              </td>
                              <td>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="25.62"
                                  height="20.13"
                                  viewBox="0 0 25.62 20.13"
                                >
                                  <path
                                    id="Icon_ionic-md-card"
                                    data-name="Icon ionic-md-card"
                                    d="M25.308,5.625H4.812A2.52,2.52,0,0,0,2.261,8.141l-.011,15.1a2.53,2.53,0,0,0,2.562,2.516h20.5a2.53,2.53,0,0,0,2.562-2.516V8.141A2.53,2.53,0,0,0,25.308,5.625Zm0,17.614H4.812V15.69h20.5Zm0-12.581H4.812V8.141h20.5Z"
                                    transform="translate(-2.25 -5.625)"
                                    fill="#2e3c83"
                                  />
                                </svg>
                              </td>
                              <td style={{ fontWeight: "400", }} className="text-info">
                                {eachRows.role === "seller" ? "Anchor" : "Counter Party"}
                              </td>
                              <td>
                                <div
                                  className="d-flex justify-content-center gap-2 w-100"
                                  style={{ fontSize: "1.1rem" }}
                                >
                                  {eachRows.maxLimitStatus === "approved" ? (
                                    <span className="text-capitalize" style={{ fontWeight: "400", fontSize: "0.9rem", }}>
                                      {eachRows.maxLimitStatus}
                                    </span>
                                  ) : (
                                    <>
                                      <button
                                        className="btn btn-outline-success"
                                        onClick={() =>
                                          handleUpdateCreditLimitRowStatus(
                                            eachRows?.customerId ?? "",
                                            "approved"
                                          )
                                        }
                                        disabled={eachRows?.maxLimitStatus === "approved"}
                                        style={{ fontWeight: "400", fontSize: "0.9rem", }}
                                      >
                                        Approve
                                      </button>
                                      <button
                                        className="btn btn-outline-danger"
                                        onClick={() =>
                                          handleUpdateCreditLimitRowStatus(
                                            eachRows?.customerId ?? "",
                                            "rejected"
                                          )
                                        }
                                        disabled={eachRows?.maxLimitStatus === "approved"}
                                        style={{ fontWeight: "400", fontSize: "0.9rem", }}
                                      >
                                        Reject
                                      </button>
                                    </>
                                  )}
                                </div>
                              </td>
                              {/* <td><span >Approve</span><span id="Create4">sent back</span><span id="Create5">Reject</span></td> */}
                            </tr>
                          ))}
                      </>
                    )}
                  </Fragment>
                ))}
              </tbody>
            )}
          </table>
        </div>
        
      </div>
      {/* <div className="d-flex align-items-center gap-2 page-data-text-container mt-3 px-3 w-100">
        <TablePagination
          pageSize={props.tableQueryParams.tableLimit}
          onUpdatePage={updatePage}
          current={props.tableQueryParams.currentPage}
          total={props.materialRequestMetaData?.paging?.total}
        ></TablePagination>
      </div> */}
    </>
  );
};

export default ApproveLimitTable;
