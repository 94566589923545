export const BanksNames = [
	"Allahabad Bank", "Andhra Bank", "AU Small Finance Bank", "Axis Bank", "Bandhan Bank",
	"Bank of Baroda", "Bank of India", "Bank of Maharashtra", "Canara Bank", "Catholic Syrian Bank",
	"Central Bank of India", "Citi Bank", "City Union Bank", "Corporation Bank", "DBS Bank", "DCB Bank",
	"Dena Bank", "Deutsche Bank", "Dhanlaxmi Bank", "Federal Bank", "HDFC Bank", "HSBC Bank", "ICICI Bank",
	"IDBI Bank", "IDFC First Bank", "Indian Bank", "Indian Overseas Bank", "IndusInd Bank", "J&K Bank", "Karnataka Bank",
	"Karur Vysys Bank", "Kotak Mahindra Bank", "Lakshmi Vilas Bank", "Naintal Bank", "Oriental Bank of Commerce", 
	"Punjab & Sind Bank", "Punjab National bank", "RBL Bank", "Saraswat Co-operative Bank", "South Indian Bank",
	"Standard Chartered Bank", "State Bank of India", "SIDBI", "Syndicate Bank", "Union Bank of India", "United Bank of India",
	"Vijaya Bank", "Yes Bank", "GP Parsik Bank", "Cosmos Bank", "TJSB Sahakari Bank", "Sunderlal Sawji Urban Co-operative Bank",
	"The Kalupur Commercial Co-operative Bank", "Abhyudaya Co-operative Bank", "Apna Sahakari Bank", "Progressive Co-operative Bank",
	"Punjab & Maharashtra Co-operative Bank", "Shree Kadi Nagarik Sahakari Bank", "State Bank of Mauritius", "The Vijay Co-operative Bank",
	"Vardhaman Mahila Co-operative Urban Bank", "Saurashtra Gramin Bank", "Sir M Visvesvaraya Co-operative Bank", "Rajkot Poeoples Co-operative Bank",
	"Bharat Co-operative Bank", "Msant Sopankaka Sahakari Bank", "Solapur Janata Sahakari Bank", "Khamgaon Urban Co-operative Bank",
	"Pandharpur Urban Co-operative Bank", "Sangli Urban Co-operative Bank", "Janata Sahakari Bank Pune", "Janaseva Sahakari Bank",
	"The karad Urban Co-operative Bank", "Capital Small Finance Bank", "Shamrao Vithal Co-operative Bank", "Nutan Nagarik Sahakari Bank",
	"Nutan Nagarik Sahakari Bank", "Bombay Mercantile Co-operative Bank", "Dombivili Nagaril Shakari Bank", "The Surat People Co-operative Bank",
	"The Varachha Co-operative Bank", "Gujarat State Co-operative Bank", "Mahesh Bank", "Citizen Credit Co-operative Bank", "BNP Paribas",
	"Pune Urban Co-operative Bank",
];