import { Fragment, useContext, useEffect, useState, } from "react";


import { GenericInputField } from "../../../../GenericComponent/Input/Input";
import { ChannelPartnerKycContext } from "../channelPartnerKycContext";
import { ChannelPartnerKycActions } from "../actionTypes";
import { GenericFileInput } from "../../../../GenericComponent/FileInput/FileInput";
import { useDispatch } from "react-redux";
import { updatePANVerification } from "../../../../store/slices/generic";
import { GenericSelect } from "../../../../GenericComponent/Select/Select";
import { gstVerification } from "../../../../store/slices/anchor";
import { indianStateList } from "../../../../utilities/indianStateList";

/**
 * @type { { label: string, value: string, id?: string, disabled?: boolean }[] }
 */
const companyTypeList = [
	{ label: "Choose Type", value: "", disabled: true, id: "0" },
	{ label: "Private Limited", value: "Private Limited", id: "1" },
	{ label: "Public Limited", value: "Public Limited", id: "2" },
	{ label: "Limited Liability Partnership", value: "Limited Liability Partnership", id: "3" },
	{ label: "Partnership", value: "Partnership", id: "4" },
	{ label: "Proprietorship", value: "Proprietorship", id: "5" },
];

const statesList = (() => {
	/**
	 * @type { { label: string, value: string, id?: string, disabled?: boolean }[] }
	 */
	const states = [{ label: "Select State", value: "", disabled: true, id: "0" }];

	for (let i = 0; i < indianStateList.length; i++) {
		states.push({
			label: indianStateList[i].stateName,
			value: indianStateList[i].stateName,
			id: (i + 1).toString(),
		});
	}
	return states;
})();

const actions = ChannelPartnerKycActions.PERSONAL_DETAILS;

export const AnchorKYCPersonalDetails = (props) => {

	// ---------------------------> States and Reducers
	const [aadhaarFiles, setAadhaarFiles] = useState([]);
	const [profilePics, setProfilePics] = useState([]);
	const [addressProofFiles, setAddressProofFiles] = useState([]);
	const [bureauReportFiles, setBureauReportFiles] = useState([]);
	const [businessProfileFiles, setBusinessProfileFiles] = useState([]);

	// ---------------------------> HOOKS
	const { personalDetails, personalDetailsDispatch, files, emailId, uploadFiles } = useContext(ChannelPartnerKycContext);
	const dispatch = useDispatch();

	// --------> useEffects 
	useEffect(() => {
		if (files?.length > 0 && files?.[0]?.documents && Array.isArray(files[0].documents)) {
			const
				aadhaarTemp = [],
				profilePicsTemp = [],
				addressProofTemp = [],
				bureauReportTemp = [],
				businessProfileTemp = [];

			for (let item of files[0].documents) {
				if (item?.adharImage !== undefined) {
					aadhaarTemp.push(item);
				}
				else if (item?.userProfilePic !== undefined) {
					profilePicsTemp.push(item);
				}
				else if (item?.addressProof !== undefined) {
					addressProofTemp.push(item);
				}
				else if (item?.buerauReport !== undefined) {
					bureauReportTemp.push(item);
				}
				else if (item?.businessProfile !== undefined) {
					businessProfileTemp.push(item);
				}
			}
			
			personalDetailsDispatch({ type: actions.AADHAAR_UPDATE_API, payload: aadhaarTemp });

			personalDetailsDispatch({ type: actions.PROFILE_PIC_UPDATE_API, payload: profilePicsTemp });

			personalDetailsDispatch({ type: actions.ADDRESS_PROOF_PIC_UPDATE_API, payload: addressProofTemp });

			personalDetailsDispatch({ type: actions.BUREAU_REPORT_PIC_UPDATE_API, payload: bureauReportTemp });

			personalDetailsDispatch({ type: actions.BUSINESS_PROFILE_PIC_UPDATE_API, payload: businessProfileTemp });

			setAadhaarFiles(aadhaarTemp);
			setProfilePics(profilePicsTemp);
			setAddressProofFiles(addressProofTemp);
			setBureauReportFiles(bureauReportTemp);
			setBusinessProfileFiles(businessProfileTemp);
		}
	}, [files, personalDetailsDispatch]);

	// -----------------------------------> Handlers and Functions

	/**
	 * @param { string } value 
	 */
	const handleNameChange = (value) => {
		personalDetailsDispatch({ type: actions.CHANGE_NAME, payload: value });
	};

	/**
	 * @param { string } value 
	 */
	const handleNameBlur = () => {
		personalDetailsDispatch({ type: actions.BLUR_NAME, });
	}

	const handlePanBlur = () => {
		if (personalDetails.panNo.value.length === 10) {
			const body = {
				panNumber: personalDetails.panNo.value,
				userid: emailId,
			};

			dispatch(updatePANVerification(body))
				.unwrap()
				.then(response => {
					if (response?.status) {
						personalDetailsDispatch({ type: actions.BLUR_PAN, payload: true });
					}
				})
				.catch(error => {
					personalDetailsDispatch({ type: actions.BLUR_PAN, payload: false });
					console.log("pan blur error", error);
				});
		}
		personalDetailsDispatch({ type: actions.BLUR_PAN, payload: false });

	};

	/**
	 * @param { React.ChangeEvent<HTMLInputElement> } event 
	 */
	const handleAadhaarChange = (event) => {
		const formData = new FormData();

		console.log("event", event);

		formData.append("adharImage", event?.target?.files?.[0]);

		uploadFiles(formData, "adharImage");
	};

	/**
	 * @param { React.ChangeEvent<HTMLInputElement> } event 
	 */
	const handleProfilePicChange = (event) => {
		const formData = new FormData();

		formData.append("userProfilePic", event.target.files?.[0]);

		uploadFiles(formData, "userProfilePic");
	};

	/**
	 * @param { React.ChangeEvent<HTMLInputElement> } event 
	 */
	const handleAddressProofChange = (event) => {
		const formData = new FormData();

		formData.append("addressProof", event.target.files?.[0]);

		uploadFiles(formData, "addressProof");
	};
	/**
	 * @param { React.ChangeEvent<HTMLInputElement> } event 
	 */
	const handleBureauReportChange = (event) => {
		const formData = new FormData();

		formData.append("buerauReport", event.target.files?.[0]);

		uploadFiles(formData, "buerauReport");
	};

	/**
	 * @param { React.ChangeEvent<HTMLInputElement> } event 
	 */
	const handleBusinessProfileChange = (event) => {
		const formData = new FormData();

		formData.append("businessProfile", event.target.files?.[0]);

		uploadFiles(formData, "businessProfile");
	};

	const handleGSTBlur = () => {
		if (personalDetails.gstNumber.value.length === 15) {
			dispatch(gstVerification(personalDetails.gstNumber.value))
				.unwrap()
				.then(response => {
					if (response?.success) {
						personalDetailsDispatch({ type: actions.BLUR_GST, payload: true });
					}
					else {
						personalDetailsDispatch({ type: actions.BLUR_GST, payload: false });
					}
				})
				.catch(error => {
					personalDetailsDispatch({ type: actions.BLUR_GST, payload: false });
				});
		}

	};

	return (
		<Fragment>
			<div className="container-with-heading d-flex flex-column container">
				<span className="kyc-content-title mb-3 row">Personal Details</span>
				<div className="details-container pb-0">
					<div className="row gy-md-4 gy-2">

						<GenericFileInput
							label="Profile Pic" required={true} fileList={profilePics}
							fileType="userProfilePic" isVerified={personalDetails.profilePic.isVerified}
							onChangeHandler={handleProfilePicChange} isInvalid={ personalDetails.profilePic.isTouched && !personalDetails.profilePic.isVerified }
						/>

						<GenericInputField
							label="Name"
							value={personalDetails.name.value}
							isInvalid={personalDetails.name.isTouched && !personalDetails.name.isValid}
							errorMessage={personalDetails.name.value === "" ? "Name is required" : "Name should have at least 4 characters"}
							required={true}
							onChangeHandler={event => handleNameChange(event?.target?.value)}
							onBlurHandler={_ => handleNameBlur()}
						/>

						<GenericInputField
							label="Email" required={true} value={emailId} disabled={true}
						/>

						<GenericInputField
							label="Mobile Number"
							value={personalDetails.mobileNumber.value}
							isInvalid={personalDetails.mobileNumber.isTouched && !personalDetails.mobileNumber.isValid}
							errorMessage={personalDetails.mobileNumber.value === "" ? "Mobile Number is required" : "Invalid Mobile Number"}
							required={true}
							onChangeHandler={event => personalDetailsDispatch({ type: actions.CHANGE_MOBILE, payload: event.target.value })}
							onBlurHandler={_ => personalDetailsDispatch({ type: actions.BLUR_MOBILE, })}
						/>

						<GenericSelect
							optionList={companyTypeList} required={true} value={personalDetails.companyType.value} errorMessage={personalDetails.companyType.value === "" ? "Company Type is Required" : "Invalid Company Type"}
							label="Company Type" onChangeHandler={event => personalDetailsDispatch({ type: actions.CHANGE_COMPANY_TYPE, payload: event.target.value })}
							onBlurHandler={() => personalDetailsDispatch({ type: actions.BLUR_COMPANY_TYPE, })} isInvalid={personalDetails.companyType.isTouched && !personalDetails.companyType.isValid}
						/>

						<GenericInputField
							label="PAN Number"
							value={personalDetails.panNo.value}
							isInvalid={(personalDetails.panNo.isTouched && !personalDetails.panNo.isValid) || (personalDetails.panNo.isTouched && !personalDetails.panNo.isVerified)}
							errorMessage={personalDetails.panNo.value === "" ? "PAN Number is required" : "Invalid PAN Number"}
							required={true}
							onChangeHandler={event => personalDetailsDispatch({ type: actions.CHANGE_PAN, payload: event.target.value })}
							onBlurHandler={() => handlePanBlur()}
							verified={personalDetails.panNo.isVerified}
							successMessage="PAN Verified"
						/>

						<GenericInputField
							label="GST Number" required={true} value={personalDetails.gstNumber.value} isInvalid={personalDetails.gstNumber.isTouched && (!personalDetails.gstNumber.isValid || !personalDetails.gstNumber.isVerified)}
							errorMessage={!personalDetails.gstNumber.isValid ? "Invalid GST Number" : "GST Not Verified"}
							verified={personalDetails.gstNumber.isVerified} successMessage="GST Number is Verified" onChangeHandler={event => personalDetailsDispatch({ type: actions.CHANGE_GST, payload: event.target.value })}
							onBlurHandler={() => handleGSTBlur()}
						/>

						<GenericFileInput
							label="Upload Aadhaar" required={true} fileList={aadhaarFiles}
							fileType="adharImage" onChangeHandler={handleAadhaarChange}
							isVerified={personalDetails.profilePic.isVerified} isInvalid={personalDetails.aadhaarImage.isTouched && !personalDetails.aadhaarImage.isVerified}
						/>

						<GenericInputField
							label="Address" required={true} value={personalDetails.address.value} isInvalid={personalDetails.address.isTouched && !personalDetails.address.isValid}
							errorMessage={!personalDetails.address.isValid && "Invalid Address"}
							onChangeHandler={event => personalDetailsDispatch({ type: actions.CHANGE_ADDRESS, payload: event.target.value })}
							onBlurHandler={() => personalDetailsDispatch({ type: actions.BLUR_ADDRESS })}
						/>

						<GenericInputField
							label="City" required={true} value={personalDetails.city.value} isInvalid={personalDetails.city.isTouched && !personalDetails.city.isValid}
							errorMessage={!personalDetails.city.isValid && "Invalid City"}
							onChangeHandler={event => personalDetailsDispatch({ type: actions.CHANGE_CITY, payload: event.target.value })}
							onBlurHandler={() => personalDetailsDispatch({ type: actions.BLUR_CITY })}
						/>

						<GenericSelect
							optionList={statesList} required={true} isInvalid={personalDetails.state.isTouched && !personalDetails.state.isValid} value={personalDetails.state.value}
							errorMessage="State is Required" onChangeHandler={event => personalDetailsDispatch({ type: actions.CHANGE_STATE, payload: event.target.value })}
							label="State" onBlurHandler={() => personalDetailsDispatch({ type: actions.BLUR_STATE, })}
						/>

						<GenericInputField
							label="Pin Code" required={true} value={personalDetails.pinCode.value} isInvalid={personalDetails.pinCode.isTouched && !personalDetails.pinCode.isValid}
							errorMessage={!personalDetails.pinCode.isValid && "Invalid PinCode"}
							onChangeHandler={event => personalDetailsDispatch({ type: actions.CHANGE_PIN, payload: event.target.value })}
							onBlurHandler={() => personalDetailsDispatch({ type: actions.BLUR_PIN })}
						/>

						<GenericFileInput
							label="Address Proof" required={true} fileList={addressProofFiles}
							fileType="addressProof" isInvalid={ personalDetails.addressProof.isTouched && !personalDetails.addressProof.isVerified }
							onChangeHandler={handleAddressProofChange}
						/>

						<GenericFileInput
							label="Bureau Report" required={true} fileList={bureauReportFiles}
							fileType="buerauReport" isInvalid={ personalDetails.bureauReport.isTouched && !personalDetails.bureauReport.isVerified }
							onChangeHandler={handleBureauReportChange}
						/>

						<GenericFileInput
							label="Business Profile" required={true} fileList={businessProfileFiles}
							fileType="businessProfile" isInvalid={ personalDetails.businessProfile.isTouched && !personalDetails.businessProfile.isVerified }
							onChangeHandler={handleBusinessProfileChange}
						/>
					</div>
				</div>

			</div>
		</Fragment>
	);
};