import { Navigate, Outlet } from "react-router-dom";

const RMPrivateRoute = () => {
  const token = sessionStorage.getItem("token");
  const role = sessionStorage.getItem("role");
  const isAuthenticated =
    token === null || token === undefined || role !== "relation_manager";

  return isAuthenticated ? <Navigate to="/login" replace /> : <Outlet />;
};

export default RMPrivateRoute;
