import { useEffect, useState } from "react";
import { BsSearch } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../GenericComponent/PaginatedList";
import Loader from "../../layout/GenericLayout/Loader";
import {Helmet} from "react-helmet";
import {
  getCorporateOnboardData,
  getCustomerOnboardData,
  getPartnerOnboardData,
  getRelationManagerOnBoardData,
  getDashboardData
} from "../../store/slices/admin";
import AdminCard from "./AdminCard";
import AdminDashboardTable from "./AdminTables/AdminDashboardTable";
/* PARENT COMPONENT */
const AdminDashboard = () => {
  /* FUNCTION DECLARATIONS */
  const dispatch = useDispatch();
  const [userDisplay, setuserDisplay] = useState(undefined);
  const [activePage, setActivePage] = useState(1);
  const [dataView, setdataView] = useState([]);
  const [perItem, setPerItem] = useState(10);

  // useState for  dashboard
  const [dashboardData, setDashboardData] = useState(0);
  

  /* USESELECTOR */
  const customerOnboardData = useSelector(
    (state) => state.admin.customerOnBoardData
  );

  const partnerOnboardData = useSelector(
    (state) => state.admin.partnerOnboardData
  );

  const corporateOnboardData = useSelector(
    (state) => state.admin.corporateOnboardData
  );
  
  const rmOnboardData = useSelector(
    (state) => state.admin.corporateOnboardData
  );

  /* USESTATES */
  const [search, setSearch] = useState("");
  const [activeTableData, setActiveTableData] = useState(1);
  const [activeTableRows, setActiveTableRows] = useState([]);

  const chunk = (items, size) => {
    const chunks = [];
    items = [].concat(...items);

    while (items.length) {
      chunks.push(items.splice(0, size));
    }

    return chunks;
  };

  /* USEEFFECTS */
  useEffect(() => {
    if (activeTableData === 1) {
      dispatch(getCustomerOnboardData())
        .unwrap()
        .then((response) => {
          setActivePage(1);
          setActiveTableRows(response.success);
          const userDisplay = chunk(response.success, perItem);
          setuserDisplay(userDisplay);
          setdataView(userDisplay[0]);
        })
        .catch((error) => {
          console.log("error : ", error);
        });
    }
    else if (activeTableData === 2) {
      dispatch(getPartnerOnboardData())
        .unwrap()
        .then((response) => {
          setActivePage(1);
          setActiveTableRows(response.success);
          const userDisplay = chunk(response.success, perItem);
          setuserDisplay(userDisplay);
          setdataView(userDisplay[0]);
        })
        .catch((error) => {
          console.log("error : ", error);
        });
    }

    else if (activeTableData === 3) {
      dispatch(getCorporateOnboardData())
        .unwrap()
        .then((response) => {
          setActivePage(1);
          setActiveTableRows(response.success);
          const userDisplay = chunk(response.success, perItem);
          setuserDisplay(userDisplay);
          setdataView(userDisplay[0]);
        })
        .catch((error) => {
          console.log("error : ", error);
        });
    }
    else if (activeTableData === 4) {
      dispatch(getRelationManagerOnBoardData())
        .unwrap()
        .then((response) => {
          setActivePage(1);
          setActiveTableRows(response.success);
          const userDisplay = chunk(response.success, perItem);
          setuserDisplay(userDisplay);
          setdataView(userDisplay[0]);
        })
        .catch((error) => {
          console.log("error : ", error);
        });
    }
  }, [dispatch, activeTableData]);



  useEffect(()=>{
    dispatch(getDashboardData())
    .unwrap()
    .then((data) => {
      setDashboardData(data?.data)
    })
    .catch((error) => {
      console.log("error", error?.message);
    });
  },[])


  /* EVENT LISTENERS */
  const onChangeSearch = (event) => {
    setSearch(event.target.value);
  };

  const onClickActiveTable = (value) => {
    setActiveTableData(value);
  };

  const handlepaginate = (data) => {
    console.log(data);
    setActivePage(data + 1);
    setdataView(userDisplay[data]);
  };

  const handlepage = (data) => {
    console.log(data);
    if (data === "left") {
      setActivePage(activePage - 1);
      setdataView(userDisplay[activePage - 1]);
    } else {
      setActivePage(activePage + 1);
      setdataView(userDisplay[activePage + 1]);
    }
  };

  return (
    <div className="d-flex flex-column gap-4">
      <Helmet>
                <title>Dashboard</title>
            </Helmet>
      <div className="bg-white admin-dashboard-card-container px-3 pt-3">
        <div className="row">
          <div className="col-12 col-md-6 col-xl-3 mb-3">
            <AdminCard
              title="No. of Lenders"
              count={dashboardData?.lender_count}
              // status="200+lastweek"
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25.166"
                  height="28.907"
                  viewBox="0 0 25.166 28.907"
                >
                  <g
                    id="noun-files-3972654"
                    transform="matrix(-1, -0.017, 0.017, -1, 97.085, 60.505)"
                  >
                    <path
                      id="Path_517"
                      data-name="Path 517"
                      d="M153.217,32.852v20.88a1.75,1.75,0,0,1-1.75,1.74H140.092a.87.87,0,0,1-.618-.256l-7-6.96a.87.87,0,0,1-.257-.614V32.851a1.75,1.75,0,0,1,1.75-1.74h17.5a1.75,1.75,0,0,1,1.75,1.74Z"
                      transform="translate(-59.332 -0.78)"
                      fill="#2e3c83"
                    />
                    <path
                      className="sidebar-icons"
                      id="Path_518"
                      data-name="Path 518"
                      d="M243.86,124.45v21.96h-18.3v1.83h18.3a1.835,1.835,0,0,0,1.83-1.83V124.45Z"
                      transform="translate(-147.979 -89.431)"
                      fill="#2e3c83"
                    />
                  </g>
                </svg>
              }
              value=""
            />
          </div>

          <div className="col-12 col-md-6 col-xl-3 mb-3">
            <AdminCard
              title="No. of Anchors"
              count={dashboardData?.seller_count}
              // status=""
              icon={
                // <svg
                //   xmlns="http://www.w3.org/2000/svg"
                //   width="25.166"
                //   height="28.907"
                //   viewBox="0 0 25.166 28.907"
                // >
                //   <g
                //     id="noun-files-3972654"
                //     transform="matrix(-1, -0.017, 0.017, -1, 97.085, 60.505)"
                //   >
                //     <path
                //       id="Path_517"
                //       data-name="Path 517"
                //       d="M153.217,32.852v20.88a1.75,1.75,0,0,1-1.75,1.74H140.092a.87.87,0,0,1-.618-.256l-7-6.96a.87.87,0,0,1-.257-.614V32.851a1.75,1.75,0,0,1,1.75-1.74h17.5a1.75,1.75,0,0,1,1.75,1.74Z"
                //       transform="translate(-59.332 -0.78)"
                //       fill="#2e3c83"
                //     />
                //     <path
                //       className="sidebar-icons"
                //       id="Path_518"
                //       data-name="Path 518"
                //       d="M243.86,124.45v21.96h-18.3v1.83h18.3a1.835,1.835,0,0,0,1.83-1.83V124.45Z"
                //       transform="translate(-147.979 -89.431)"
                //       fill="#2e3c83"
                //     />
                //   </g>
                // </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 21.019 21.045">
                <g id="Group_381" data-name="Group 381" transform="translate(-575.961 -194.955)">
                  <path id="Icon_open-people" data-name="Icon open-people" d="M14.45,0a3.746,3.746,0,0,0-3.205,2.312,8.663,8.663,0,0,1,1.892,5.6,9.268,9.268,0,0,1-.236,2.128,3.089,3.089,0,0,0,1.55.5c2.181,0,3.941-2.365,3.941-5.255S16.631.026,14.45.026ZM6.568,2.627c-2.181,0-3.941,2.365-3.941,5.255s1.76,5.255,3.941,5.255,3.941-2.365,3.941-5.255S8.749,2.627,6.568,2.627Zm12.48,8.3a5.986,5.986,0,0,1-4.44,2.207,6.07,6.07,0,0,1,1.156,3.521v1.734h5.255V14.03A3.518,3.518,0,0,0,19.048,10.9ZM1.971,13.557A3.518,3.518,0,0,0,0,16.684v4.361H13.137V16.684a3.518,3.518,0,0,0-1.971-3.127,5.96,5.96,0,0,1-4.6,2.207,6.045,6.045,0,0,1-4.6-2.207Z" transform="translate(575.961 194.955)" fill="#2e3c83"/>
                </g>
              </svg>
              
              }
              value=""
            />
          </div>

          <div className="col-12 col-md-6 col-xl-3 mb-3">
            <AdminCard
              title="No. of Counter Parties"
              count={dashboardData?.buyer_count}
              // status=""
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20px"
                  height="20px"
                  viewBox="0 0 19.081 20.663"
                >
                  <g id="noun-person-2237338" transform="translate(-118.864 -29.7)">
                    <path
                      className="sidebar-icons"
                      id="Path_467"
                      data-name="Path 467"
                      d="M201.025,36.4a6.7,6.7,0,1,1-6.7-6.7,6.7,6.7,0,0,1,6.7,6.7"
                      transform="translate(-65.918)"
                      fill="#2e3c83"
                    />
                    <path
                      className="sidebar-icons"
                      id="Path_468"
                      data-name="Path 468"
                      d="M121.815,393.2h13.178a2.951,2.951,0,1,0,0-5.9H121.815a2.951,2.951,0,0,0,0,5.9Z"
                      transform="translate(0 -342.839)"
                      fill="#2e3c83"
                    />
                  </g>
                </svg>
              }
              value=""
            />
          </div>

          <div className="col-12 col-md-6 col-xl-3 mb-3">
            <AdminCard
              title="Active Lenders"
              count={dashboardData?.active_lender_count}
              // status="now"
              icon={
                <svg xmlns="http://www.w3.org/2000/svg" 
                  width="20" 
                  height="20" 
                  viewBox="0 0 17.966 19.348">
                  <path id="noun-person-5619298" 
                  d="M122.5,52.966a1.382,1.382,0,0,0,1.382,1.382h15.2a1.382,1.382,0,0,0,1.377-1.269l0-.113v0a6.219,6.219,0,0,0-6.219-6.219h-5.528A6.219,6.219,0,0,0,122.5,52.966ZM131.483,35a5.183,5.183,0,1,0,5.183,5.183A5.185,5.185,0,0,0,131.483,35Z" 
                  transform="translate(-122.5 -35)" fill="#2e3c83" fill-rule="evenodd"/>
                </svg>
              }
              icon2={
                <svg xmlns="http://www.w3.org/2000/svg" 
                  width="10.349" height="20.674" 
                  viewBox="0 0 10.349 20.674">
                    <path id="noun-flash-4433288" 
                    d="M234.127,81.484a.919.919,0,0,1-.683-1.533L242.4,70l-2.864,7.637a1.149,1.149,0,0,0,1.075,1.552h2.022a.919.919,0,0,1,.683,1.533l-8.957,9.952,2.864-7.637a1.149,1.149,0,0,0-1.075-1.552Z" 
                    transform="translate(-233.206 -69.998)" fill="#2e3c83"/>
                </svg>
              }
              value=""
            />
          </div>


        </div>

        <div className="row">
          <div className="col-12 col-md-6 col-xl-3 mb-3">
            <AdminCard
              title="No. of Invoices Discounted"
              count={ dashboardData?.total_discounted_invoice_count }
              // status="+ 19% lastweek"
              icon={
                <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 19.426 19.426">
                  <g id="noun-bill-1954704"
                      transform="translate(-166.25 -96.25)">
                      <path id="Path_465"
                          //className={props.styleClassName}
                          data-name="Path 465"
                          d="M433.375,98.1h-3.7a.925.925,0,1,1,0-1.85h3.7a.925.925,0,1,1,0,1.85Z"
                          transform="translate(-248.624)" fill="#2e3c83" />
                      <path id="Path_466"
                          //className={props.styleClassName}
                          data-name="Path 466"
                          d="M178.275,96.25h-11.1a.925.925,0,0,0-.925.925v17.576a.925.925,0,0,0,.925.925h11.1a.925.925,0,0,0,.925-.925V97.175A.925.925,0,0,0,178.275,96.25ZM175.5,111.976h-5.55a.463.463,0,1,1,0-.925h5.55a.463.463,0,1,1,0,.925Zm0-3.238h-5.55a.463.463,0,1,1,0-.925h5.55a.463.463,0,1,1,0,.925Zm0-3.7h-5.55a.463.463,0,1,1,0-.925h5.55a.463.463,0,1,1,0,.925Zm0-3.238h-5.55a.463.463,0,1,1,0-.925h5.55a.463.463,0,1,1,0,.925Z"
                          fill="#2e3c83" />
                  </g>
              </svg>
              }
              value=""
            />
          </div>
          <div className="col-12 col-md-6 col-xl-3 mb-3">
            <AdminCard
              title="No. of Mapping Done"
              count={dashboardData?.lender_anchor_mapping_count}
              // status="+43%"
              icon={
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 17.77 22.454">
                  <g id="noun-mapping-2432426" transform="translate(-128.41)">
                    <path id="Path_520" data-name="Path 520" 
                    d="M137.258,0a8.917,8.917,0,0,0-8.848,8.848c0,5.13,5.056,10.558,8.922,13.606,3.717-3.048,8.848-8.476,8.848-13.606A8.933,8.933,0,0,0,137.258,0Zm0,4.684a4.127,4.127,0,1,1-4.089,4.164,4.157,4.157,0,0,1,4.089-4.164Z" 
                    fill="#2e3c83" fill-rule="evenodd"/>
                  </g>
                </svg>
              }
              value=""
            />
          </div>
          <div className="col-12 col-md-6 col-xl-3 mb-3">
            <AdminCard
              title="Invoice status Pending"
              count={dashboardData?.total_pending_invoices_count}
              // status="Still Pending"
              icon={
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 31.828 31.828">
                  <path id="noun-pending-2186776" d="M128.32,46.664a11.649,11.649,0,1,0,5.825,1.56,11.65,11.65,0,0,0-5.825-1.56Zm1.165,11.65a1.164,1.164,0,0,1-.338.827l-3.495,3.495a1.165,1.165,0,0,1-1.643-1.643l3.145-3.157V52.489a1.165,1.165,0,1,1,2.33,0Z" 
                  transform="translate(156.199 2.255) rotate(150)" fill="#2e3c83"/>
                </svg>
              }
              value=""
            />
          </div>
          <div className="col-12 col-md-6 col-xl-3 mb-3">
            <AdminCard
              title="No. of Invoice Rejected"
              count={dashboardData?.total_rejected_invoices_count}
              // status="-25% lastweek"
              icon={
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21.963" viewBox="0 0 16.106 21.963">
                    <g id="noun-rejected-3283376" transform="translate(-157.499 -17.5)">
                    <path id="Path_520" data-name="Path 520" d="M437.5,27.824v3.961h3.961Z" transform="translate(-268.288 -9.892)" fill="#2e3c83"/>
                    <path id="Path_521" data-name="Path 521" d="M168.48,23.357a.732.732,0,0,1-.732-.732V17.5H159.52a2.028,2.028,0,0,0-2.021,2.021V37.442a2.028,2.028,0,0,0,2.021,2.021h12.065a2.028,2.028,0,0,0,2.021-2.021V23.357Zm.52,9a.735.735,0,1,1-1.04,1.04l-2.409-2.416-2.409,2.416a.735.735,0,1,1-1.04-1.04l2.416-2.409L162.1,27.537a.735.735,0,0,1,1.04-1.04l2.409,2.416L167.96,26.5a.735.735,0,0,1,1.04,1.04l-2.416,2.409Z" fill="#2e3c83"/>
                  </g>
                </svg>
              }
              value=""
            />
          </div>
        </div> 
      </div>

      <div className="d-flex justify-content-between flex-wrap px-2 gap-2">
        <span className="admin-dashboard-content-title">Application Details</span>
        <div className="search-container d-flex align-items-center py-1 px-3 col-12 col-sm-4 ">
          <input
            className="search-input-element d-flex flex-grow-1"
            type="text"
            placeholder="Search"
            onChange={onChangeSearch}
            value={search}
          />{" "}
          <BsSearch className="fs-5" />
        </div>
      </div>

      <div className="d-flex flex-column">
        <div className="d-flex">
          <span
            className={`admin-dashboard-table-title ${
              activeTableData !== 1 && "dashboard-table-title-hover"
            } py-2 px-3 ${
              activeTableData === 1 && "active-table-data placeholder-wave"
            }`}
            onClick={() => onClickActiveTable(1)}
          >
            Customer Onboarded
          </span>
          <span
            className={`admin-dashboard-table-title ${
              activeTableData !== 2 && "dashboard-table-title-hover"
            } py-2 px-3 ${
              activeTableData === 2 && "active-table-data placeholder-wave"
            }`}
            onClick={() => onClickActiveTable(2)}
          >
            Partners Onboarded
          </span>
          <span
            className={`admin-dashboard-table-title ${
              activeTableData !== 3 && "dashboard-table-title-hover"
            } py-2 px-3 ${
              activeTableData === 3 && "active-table-data placeholder-wave"
            }`}
            onClick={() => onClickActiveTable(3)}
          >
            Corporate Onboarded
          </span>
          <span
            className={`admin-dashboard-table-title ${
              activeTableData !== 4 && "dashboard-table-title-hover"
            } py-2 px-3 ${
              activeTableData === 4 && "active-table-data placeholder-wave"
            }`}
            onClick={() => onClickActiveTable(4)}
          >
            RM Onboarded
          </span>
        </div>
        <div>
          {activeTableData === 1 &&
            (customerOnboardData.isLoading ? (
              <Loader className="my-5" />
            ) : (
              <>
                <AdminDashboardTable
                  className="admin-dashboard-table"
                  tableHeader={[
                     "Date",
                    "Requested Date",
                    "Sector Name",
                    "Email ID",
                    "Roles",
                    "Status",
                    "Remarks",
                  ]}
                  tableRows={dataView}
                />
                <center>
                  {userDisplay !== undefined && (
                    <Pagination
                      userDisplay={userDisplay}
                      activePage={activePage}
                      handlepaginate={handlepaginate}
                      handlepage={handlepage}
                    />
                  )}
                </center>
              </>
            ))}
          {activeTableData === 2 &&
            (partnerOnboardData.isLoading ? (
              <Loader className="my-5" />
            ) : (
              <>
                <AdminDashboardTable
                  className="admin-dashboard-table"
                  // tableRowsCount={tableRowsCount}
                  tableHeader={[
                     "Date",
                    "Requested Date",
                    "Sector Name",
                    "Email ID",
                    "Roles",
                    "Status",
                    "Remarks",
                  ]}
                  tableRows={dataView}
                />

                <center>
                  {userDisplay !== undefined && (
                    <Pagination
                      userDisplay={userDisplay}
                      activePage={activePage}
                      handlepaginate={handlepaginate}
                      handlepage={handlepage}
                    />
                  )}
                </center>
              </>
            ))}
          {activeTableData === 3 &&
            (corporateOnboardData.isLoading ? (
              <Loader className="my-5" />
            ) : (
              <>
                <AdminDashboardTable
                  className="admin-dashboard-table"
                  // tableRowsCount={tableRowsCount}
                  tableHeader={[
                    "Date",
                    "Requested Date",
                    "Sector Name",
                    "Email ID",
                    "Roles",
                    "Status",
                    "Remarks",
                  ]}
                  tableRows={dataView}
                />

                <center>
                  {userDisplay !== undefined && (
                    <Pagination
                      userDisplay={userDisplay}
                      activePage={activePage}
                      handlepaginate={handlepaginate}
                      handlepage={handlepage}
                    />
                  )}
                </center>
              </>
            ))}
          
          {activeTableData === 4 &&
            (rmOnboardData.isLoading ? (
              <Loader className="my-5" />
            ) : (
              <>
                <AdminDashboardTable
                  className="admin-dashboard-table"
                  // tableRowsCount={tableRowsCount}
                  tableHeader={[
                    "Date",
                    "Requested Date",
                    "Sector Name",
                    "Email ID",
                    "Roles",
                    "Status",
                    "Remarks",
                  ]}
                  tableRows={dataView}
                />

                <center>
                  {userDisplay !== undefined && (
                    <Pagination
                      userDisplay={userDisplay}
                      activePage={activePage}
                      handlepaginate={handlepaginate}
                      handlepage={handlepage}
                    />
                  )}
                </center>
              </>
            ))}
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
