import React, { useEffect, useState } from "react";
import InvoiceDigitalTable from "../BuyerTables/invoiceTables/InvoiceDigitalTable";
import { BsSearch } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import {Helmet} from "react-helmet";
import {
  getInvoiceDigitalTable,
  getInvoiceManualTable,
} from "../../../store/slices/buyer";
import InvoiceManualTable from "../BuyerTables/invoiceTables/InvoiceManualTable";

const BuyerInvoice = () => {
  const dispatch = useDispatch();

  // Tabs Switching
  const [activeTableData, setActiveTableData] = useState(1);

  // Redux API Response Data
  const invoiceOrderDigitalPOResponse = useSelector(
    (state) => state.buyer.invoiceDigitalTableData
  );
  const invoiceOrderManualPOResponse = useSelector(
    (state) => state.buyer.invoiceManualTableData
  );

  // Table Data
  const [invoiceOrderDigitalTableRows, setinvoiceOrderDigitalTableRows] =
    useState([]);

  const [
    invoiceOrderDigitalTableMetaData,
    setinvoiceOrderDigitalTableMetaData,
  ] = useState();
  const [invoiceOrderManualTableRows, setinvoiceOrderManualTableRows] =
    useState([]);
  const [invoiceOrderManualTableMetaData, setinvoiceOrderManualTableMetaData] =
    useState();

  const onClickActiveTable = (value) => {
    setActiveTableData(value);
  };

  /* TABLE QUERY PARAMS Digital PO*/
  const [
    tableQueryParamsinvoiceOrderDigitalPO,
    setTableQueryParamsinvoiceOrderDigitalPO,
  ] = useState({
    tableOffset: 0,
    tableLimit: 5,
    tableSort: "invoiceId",
    tableOrder: -1,
    currentPage: 0,
    search: "",
  });

  /* TABLE QUERY PARAMS Manual PO*/
  const [
    tableQueryParamsinvoiceOrderManualPO,
    setTableQueryParamsinvoiceOrderManualPO,
  ] = useState({
    tableOffset: 0,
    tableLimit: 5,
    tableSort: "invoiceId",
    tableOrder: -1,
    currentPage: 0,
    search: "",
  });

  // This is For Searching by ID
  const [searchBothPOByID, setSearchBothPOByID] = useState("");

  const handleOnChangeSearchBothPOByID = (event) => {
    setSearchBothPOByID(event.target.value);
  };

  useEffect(() => {
    if (activeTableData === 1) {
      if (searchBothPOByID.length > 1) {
        const tabQueryValuesWithSearch = `offset=${tableQueryParamsinvoiceOrderDigitalPO?.tableOffset}&limit=${tableQueryParamsinvoiceOrderDigitalPO?.tableLimit}&search=${searchBothPOByID}`;
        dispatch(getInvoiceDigitalTable(tabQueryValuesWithSearch));
      } else {
        const tabQueryValuesWithoutSearch = `offset=${tableQueryParamsinvoiceOrderDigitalPO?.tableOffset}&limit=${tableQueryParamsinvoiceOrderDigitalPO?.tableLimit}&sort=${tableQueryParamsinvoiceOrderDigitalPO?.tableSort}&order=${tableQueryParamsinvoiceOrderDigitalPO?.tableOrder}`;
        dispatch(getInvoiceDigitalTable(tabQueryValuesWithoutSearch));
      }
    }

    if (activeTableData === 2) {
      if (searchBothPOByID.length > 1) {
        const tabQueryValuesWithSearch = `offset=${tableQueryParamsinvoiceOrderManualPO?.tableOffset}&limit=${tableQueryParamsinvoiceOrderManualPO?.tableLimit}&search=${searchBothPOByID}`;
        dispatch(getInvoiceManualTable(tabQueryValuesWithSearch));
      } else {
        const tabQueryValuesWithoutSearch = `offset=${tableQueryParamsinvoiceOrderManualPO?.tableOffset}&limit=${tableQueryParamsinvoiceOrderManualPO?.tableLimit}&sort=${tableQueryParamsinvoiceOrderManualPO?.tableSort}&order=${tableQueryParamsinvoiceOrderManualPO?.tableOrder}`;
        dispatch(getInvoiceManualTable(tabQueryValuesWithoutSearch));
      }
    }
  }, [
    activeTableData,
    dispatch,
    tableQueryParamsinvoiceOrderDigitalPO,
    tableQueryParamsinvoiceOrderManualPO,
    searchBothPOByID,
  ]);

  useEffect(() => {
    if (invoiceOrderDigitalPOResponse.data) {
      setinvoiceOrderDigitalTableRows(invoiceOrderDigitalPOResponse.data.items);
      setinvoiceOrderDigitalTableMetaData(
        invoiceOrderDigitalPOResponse.data.metadata
      );
    }
  }, [invoiceOrderDigitalPOResponse]);

  useEffect(() => {
    if (invoiceOrderManualPOResponse.data) {
      setinvoiceOrderManualTableRows(invoiceOrderManualPOResponse.data.items);
      setinvoiceOrderManualTableMetaData(
        invoiceOrderManualPOResponse.data.metadata
      );
    }
  }, [invoiceOrderManualPOResponse]);

  // console.log("This is Rows: ",invoiceOrderDigitalTableRows);
  // console.log("This is Meta Data: ", invoiceOrderDigitalTableMetaData);
  // console.log("This is Manual PO: ", invoiceOrderDigitalTableRows);

  return (
    <>
      <div className="p-0" style={{ overflowX: "hidden" }}>
      <Helmet>
                <title>Invoice</title>
            </Helmet>
        <div className="bg-white admin-dashboard-card-container p-3">
          <div className="d-flex justify-content-between align-items-center flex-wrap px-2 gap-2 py-2">

            {
              activeTableData === 1 ?
                <span className="dashboard-content-title">
                  Digital Invoice List
                </span> :
                activeTableData === 2 ?
                  <span className="dashboard-content-title">
                    Manual Invoice List
                  </span> :
                  <span></span>
            }

            <div
              // onClick={toggleRMAddModal}
              className="d-flex align-items-center"
              type="button"
            >
              {/* <BsPlusCircleFill
                className="rm-plus-button"
              /> */}
            </div>
          </div>

          <div className="d-flex flex-column flex-sm-row align-items-center my-4">
            <div className="d-flex flex-column justify-content-center gap-3 col-12 col-sm-6">
              <label
                htmlFor="selectAnchor"
                className="anchor-and-lender-select-label"
              >
              </label>
              <div className="search-container d-flex align-items-center py-1 px-3 col-12 col-sm-8">
                <input
                  className="search-input-element d-flex flex-grow-1"
                  type="text"
                  placeholder="Search with ID"
                  onChange={handleOnChangeSearchBothPOByID}
                  value={searchBothPOByID}
                // onKeyDown={handleOnSubmitSearchBothPOByID}

                // value={search}
                />{" "}
                <BsSearch className="fs-5" />
              </div>
            </div>
          </div>
        </div>

        {/* The Table container starts here */}

        <div className="mt-3" style={{ overflowX: "hidden" }}>
          {/* This is For the Tab Switch */}
          <div className="d-flex px-0">
            <span
              className={`dashboard-table-title ${activeTableData !== 1 && "dashboard-table-title-hover"
                } py-2 px-3 ${activeTableData === 1 && "active-table-data placeholder-wave"
                }`}
              onClick={() => onClickActiveTable(1)}
            >
              Digital Invoice
            </span>
            <span
              className={`dashboard-table-title ${activeTableData !== 2 && "dashboard-table-title-hover"
                } py-2 px-3 ${activeTableData === 2 && "active-table-data placeholder-wave"
                }`}
              onClick={() => onClickActiveTable(2)}
            >
              Manual Invoice
            </span>
          </div>
          {/* END of Tab Switch */}

          <div>
            {activeTableData === 1 && (
              <div
                className="mb-5"
                style={{
                  overflowX: "hidden",
                  boxSizing: "border-box",
                }}
              >
                <InvoiceDigitalTable
                  className="buyer-table"
                  tableHeader={[
                    { name: "Invoice Date", sortValue: "" },
                    { name: "Invoice Number", sortValue: "" },
                    { name: "PO Number", sortValue: "" },
                    { name: "Total Amount (₹)", sortValue: "" },
                    { name: "Invoice Status", sortValue: "" },
                    { name: "Due Date", sortValue: "" },
                    { name: "Outstanding Amount (₹)", sortValue: "" },
                    { name: "Overdue Amount (₹)	", sortValue: "" },
                    { name: "Remaining Amount (₹)", sortValue: "" },
                    { name: "BOE Number", sortValue: "" },
                    { name: "Upload BOE", sortValue: "" },
                    { name: "View BOE", sortValue: "" },
                    { name: "View LR", sortValue: "" },
                    { name: "Payment", sortValue: "" },
                  ]}
                  tableRows={invoiceOrderDigitalTableRows}
                  materialRequestMetaData={invoiceOrderDigitalTableMetaData}
                  tableQueryParams={tableQueryParamsinvoiceOrderDigitalPO}
                  setTableQueryParams={setTableQueryParamsinvoiceOrderDigitalPO}
                  isLoading={invoiceOrderDigitalPOResponse.isLoading}
                />
              </div>
            )}
            {activeTableData === 2 && (
              <div
                className=""
                style={{
                  overflowX: "hidden",
                  boxSizing: "border-box",
                }}
              >
                <InvoiceManualTable
                  className="buyer-table"
                  tableHeader={[
                    { name: "Invoice Date", sortValue: "" },
                    { name: "Invoice Number", sortValue: "" },
                    { name: "Download File", sortValue: "" },
                    { name: "Batch Excel List (.xslx)", sortValue: "", },
                    { name: "Total Amount (₹)", sortValue: "" },
                    { name: "Due Date", sortValue: "" },
                    // { name: "Upload BOE", sortValue: "" },
                    // { name: "View BoE", sortValue: "" },
                    // { name: "View LR", sortValue: "" },
                    { name: "Status", sortValue: "" },
                    { name: "Payment", sortValue: "" },
                  ]}
                  tableRows={invoiceOrderManualTableRows}
                  materialRequestMetaData={invoiceOrderManualTableMetaData}
                  tableQueryParams={tableQueryParamsinvoiceOrderManualPO}
                  setTableQueryParams={setTableQueryParamsinvoiceOrderManualPO}
                  isLoading={invoiceOrderManualPOResponse.isLoading}
                  setActiveTableData={setActiveTableData}
                  tableQueryParamsinvoiceOrderManualPO={tableQueryParamsinvoiceOrderManualPO}
                />
              </div>
            )}
          </div>
        </div>

        {/* <div className="d-flex flex-column justify-content-between flex-wrap px-2 gap-2"> */}
        {/* <ProformaInvoiceTable
          className="admin-anchor-and-lender-mapped-table"
          tableHeader={[
            { name: "Proforma Date", sortValue: "addedDate" },
            { name: "Proforma Invoice ID", sortValue: "proformaId" },
            { name: "Seller Name", sortValue: "sellerName" },
            { name: "Date of Delivery", sortValue: "expectedDateOfDelivery" },
            { name: "Payment Terms (Days)", sortValue: "paymentTerms" },
            { name: "Status", sortValue: "proformaStatus" },
          ]}
          tableRows={proformaInvoiceTableRowData}
          materialRequestMetaData={proformInvoiceTableMetaData}
          tableQueryParams={tableQueryParams}
          setTableQueryParams={setTableQueryParams}
          isLoading={proFormaInvoiceTableResponse.isLoading}
        /> */}
        {/* </div> */}
      </div>
    </>
  );
};

export default BuyerInvoice;
