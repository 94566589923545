import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  redirect,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  getKycData,
  updateKycApproveData,
  userDataKycSubmitToLender,
} from "../../../store/slices/admin";
import Loader from "../../../layout/GenericLayout/Loader";
import ReusableModal from "../../../layout/GenericLayout/ReusableModal";
import Swal from "sweetalert2";
import { toastMessage } from "../../../layout/GenericLayout/ToastMessage";
import { BsEyeFill } from "react-icons/bs";
import { FaDownload } from "react-icons/fa";
import {Helmet} from "react-helmet";

/* MODALS OR CHILDREN COMPONENTS */
const RejectModalContent = ({
  kycData,
  toggleRejectModal,
  onClickSubmitStatus,
}) => {
  const [rejectReason, setRejectReason] = useState("");

  const onChangeRejectReason = (event) => {
    setRejectReason(event.target.value);
  };
  const onClickSubmitRejectReason = () => {
    onClickSubmitStatus({ kycStatus: "rejected", kycRemarks: rejectReason });
  };

  return (
    <div className="container">
      <span className="fs-4 fw-semibold">Rejection Reason</span>
      <div className="row">
        <div className="kyc-fields-container col-12">
          <label htmlFor="rejectReason" className="kyc-label-text form-label">
            Remarks <span className="text-danger">*</span>
          </label>
          <input
            onChange={onChangeRejectReason}
            id="rejectReason"
            name="rejectReason"
            type="text"
            className="kyc-input-field form-control"
            value={rejectReason}
          />
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-end gap-3 mx-5 my-3">
        <button className="kyc-back-button" onClick={toggleRejectModal}>
          Cancel
        </button>
        <button className="kyc-back-button" onClick={onClickSubmitRejectReason}>
          Submit
        </button>
      </div>
    </div>
  );
};

const SendBackModalContent = ({
  kycData,
  toggleSendBackModal,
  onClickSubmitStatus,
}) => {
  const [sendBackReason, setSendBackReason] = useState("");

  const onChangeSendBackReason = (event) => {
    setSendBackReason(event.target.value);
  };
  const onClickSubmitSendBackReason = () => {
    onClickSubmitStatus({ kycStatus: "send back", kycRemarks: sendBackReason });
  };

  return (
    <div className="container">
      <span className="fs-4 fw-semibold">Sent Back Reason</span>
      <div className="row">
        <div className="kyc-fields-container col-12">
          <label htmlFor="rejectReason" className="kyc-label-text form-label">
            Remarks <span className="text-danger">*</span>
          </label>
          <input
            onChange={onChangeSendBackReason}
            id="rejectReason"
            name="rejectReason"
            type="text"
            className="kyc-input-field form-control"
            value={sendBackReason}
          />
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-end gap-3 mx-5 my-3">
        <button className="kyc-back-button" onClick={toggleSendBackModal}>
          Cancel
        </button>
        <button
          className="kyc-back-button"
          onClick={onClickSubmitSendBackReason}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

const AadharView = ({ kycData, toggleModal }) => {
  let aadharImage = null;
  kycData.data?.success?.documents.forEach((element) => {
    if (element.adharImage) {
      console.log(element);
      aadharImage = element.adharImage;
    }
  });

  console.log(aadharImage);
  return (
    <div>
      <span className="fs-4 fw-semibold">Aadhaar Card Front View</span>
      {aadharImage === null ? (
        <div className="m-4 d-flex justify-content-center align-items-center my-5">
          <h4 className="text-danger ">Aadhaar is not available!</h4>
        </div>
      ) : (
        <>
          <div className="m-4">
            <img
              alt="Aadhaar Card Front View"
              src={aadharImage}
              className="w-100 h-100"
            />
          </div>
          <div>
            <a
              target="_blank"
              href={aadharImage}
              download
              className="d-flex justify-content-center align-items-center gap-3 list-group-item list-group-item-action list-group-item-primary w-50 mx-auto py-2 rounded fw-semibold"
            >
              Download <FaDownload className="fs-4" />
            </a>
          </div>
        </>
      )}
    </div>
  );
};

const BankStatementView = ({ kycData, toggleModal }) => {
  let bankStatement = null;
  kycData.data?.success?.documents.forEach((element) => {
    console.log(element);
    if (element.bankstatement) {
      bankStatement = element.bankstatement;
    }
  });

  return (
    <div>
      <span className="fs-4 fw-semibold">Bank Statement</span>
      {bankStatement === null ? (
        <div className="m-4 d-flex justify-content-center align-items-center my-5">
          <h4 className="text-danger ">Bank Statement is not available!</h4>
        </div>
      ) : (
        <>
          <div className="m-4">
            <object
              data={bankStatement}
              type="application/pdf"
              width="100%"
              height="500px"
            ></object>
          </div>
          <div>
            <a
              target="_blank"
              href={bankStatement}
              download
              className="d-flex justify-content-center align-items-center gap-3 list-group-item list-group-item-action list-group-item-primary w-50 mx-auto py-2 rounded fw-semibold"
            >
              Download <FaDownload className="fs-4" />
            </a>
          </div>
        </>
      )}
    </div>
  );
};

/* PARENT COMPONENT */
const AdminUserKyc = () => {
  /* FUNCTION DECLARATIONS */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  /* OBJECT DESTRUCTURE */
  const { emailId } = useParams();
  const location = useLocation();
  /* USESELECTOR */
  const kycData = useSelector((state) => state.admin.kycData);
  const kycApproveData = useSelector((state) => state.admin.kycApproveData);
  /* USESTATES */
  const [kycUserData, setKycUserData] = useState(null);
  const [kycDataError, setKycDataError] = useState(null);
  const [showRejectModalStatus, setShowRejectModalStatus] = useState(false);
  const [showSendBackModalStatus, setShowSendBackModalStatus] = useState(false);
  const [showAadhaarModal, setShowAdharModal] = useState(false);
  const [showBankStatementModal, setShowBankStatementModal] = useState(false);


  const [ files, setFiles, ] = useState([]);

  // console.log("location : ", location.state.isUser);

  /* USEEFFECTS */
  useEffect(() => {
    dispatch(getKycData(emailId))
      .unwrap()
      .then((response) => {
        console.log("response : ", response);
        if (response?.success) {
          if (response?.success?.documents !== undefined && Array.isArray(response?.success?.documents) && response?.success?.documents?.length > 0) {
            const newList = [];

            for (let file of response.success.documents) {
              // if (file.name) {
                newList.push(file);
              // }
            }

            setFiles(newList);
          }
        }
      })
      .catch((error) => {
        console.log("error : ", error);
        setKycDataError(error.message);
      });
  }, [dispatch]);
  /* EVENT LISTENERS */
  const toggleRejectModal = () => {
    setShowRejectModalStatus((prev) => !prev);
  };
  const toggleSendBackModal = () => {
    setShowSendBackModalStatus((prev) => !prev);
  };
  const onClickBack = () => {
    navigate(-1, { replace: true });
  };
  const onClickSubmitStatus = ({ kycStatus, kycRemarks }) => {
    // const body = {
    //   emailId: kycData.data?.success?.emailId,
    //   remarks: kycRemarks,
    //   status: kycStatus,
    // };

    // console.log("body : ", body);

    const body = {
      customerId: kycData.data?.success?.customerId,
      status: kycStatus,
      adminRemarks: kycRemarks,
    };
    console.log("body : ", body);

    dispatch(userDataKycSubmitToLender(body))
      .unwrap()
      .then((response) => {
        console.log("response : ", response);
        toastMessage(response.success, "success");
        navigate(-1, { replace: true });
      })
      .catch((error) => {
        console.log("error : ", error);
        toastMessage(error.message, "error");
      });
  };

  const toggleAadhaarView = () => {
    setShowAdharModal((prev) => !prev);
  };

  const toggleBankStatementView = () => {
    setShowBankStatementModal((prev) => !prev);
  };

  /* RENDER */
  if (kycDataError) {
    return (
      <div className="bg-white admin-kyc-card-container px-3 py-4 h-100">
        <div className="d-flex flex-column justify-content-center align-items-center w-100 h-100">
          <h2 className="text-danger">{kycData.error}</h2>
          <div className="d-flex align-items-center justify-content-center mx-5 my-3">
            <button className="kyc-back-button" onClick={onClickBack}>
              Back
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {/* MODALS */}
      {showRejectModalStatus && (
        <ReusableModal
          showModal={showRejectModalStatus}
          toggleModal={toggleRejectModal}
          modalContent={
            <RejectModalContent
              kycData={kycData}
              toggleRejectModal={toggleRejectModal}
              onClickSubmitStatus={onClickSubmitStatus}
            />
          }
          modalSizeClass="modal-lg"
        />
      )}
      {showSendBackModalStatus && (
        <ReusableModal
          showModal={showSendBackModalStatus}
          toggleModal={toggleSendBackModal}
          modalContent={
            <SendBackModalContent
              kycData={kycData}
              toggleSendBackModal={toggleSendBackModal}
              onClickSubmitStatus={onClickSubmitStatus}
            />
          }
          modalSizeClass="modal-lg"
        />
      )}
      {showAadhaarModal && (
        <ReusableModal
          showModal={showAadhaarModal}
          toggleModal={toggleAadhaarView}
          modalContent={
            <AadharView kycData={kycData} toggleModal={toggleAadhaarView} />
          }
          modalSizeClass="modal-lg"
        />
      )}
      {showBankStatementModal && (
        <ReusableModal
          showModal={showBankStatementModal}
          toggleModal={toggleBankStatementView}
          modalContent={
            <BankStatementView
              kycData={kycData}
              toggleModal={toggleBankStatementView}
            />
          }
          modalSizeClass="modal-lg"
        />
      )}
      <div className="bg-white admin-kyc-card-container px-3 py-4">
      <Helmet>
                <title>User-KYC</title>
            </Helmet>
        {kycData.isLoading && (
          <div className="d-flex justify-content-center align-items-center w-100 min-vh-100">
            <Loader />
          </div>
        )}

        {kycData.data !== null && (
          <div className="d-flex flex-column m-0 p-0">
            <div className="d-flex flex-column">
              <span className="kyc-content-title my-3">Personal Details</span>
              <div className="details-container container">
                <div className="row gy-md-4 gy-2">
                  <div className="kyc-fields-container col-12 col-md-4">
                    <label htmlFor="name" className="kyc-label-text form-label">
                      Name
                    </label>
                    <input
                      disabled
                      id="name"
                      name="name"
                      type="text"
                      className="kyc-input-field form-control"
                      value={kycData.data?.success?.aadharSDK?.name}
                    />
                  </div>

                  <div className="kyc-fields-container col-12 col-md-4">
                    <label htmlFor="pan" className="kyc-label-text form-label">
                      Pan No.
                    </label>
                    <input
                      disabled
                      id="pan"
                      type="text"
                      name="pan"
                      className="kyc-input-field form-control"
                      // value={kycData.data?.success?.PANNumber}
                    />
                  </div>

                  <div className="kyc-fields-container col-12 col-md-4">
                    <label
                      htmlFor="email"
                      className="kyc-label-text form-label"
                    >
                      Email
                    </label>
                    <input
                      disabled
                      id="email"
                      type="text"
                      name="email"
                      className="kyc-input-field form-control"
                      value={kycData.data?.success?.emailId}
                    />
                  </div>

                  <div className="kyc-fields-container col-12 col-md-4">
                    <label
                      htmlFor="mobileNumber"
                      className="kyc-label-text form-label"
                    >
                      Mobile Number
                    </label>
                    <input
                      disabled
                      id="mobileNumber"
                      type="datextte"
                      name="mobileNumber"
                      className="kyc-input-field form-control"
                      value={kycData.data?.success?.mobileNumber}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex flex-column">
              <span className="kyc-content-title my-3">
                Company Information
              </span>
              <div className="details-container container">
                <div className="row gy-md-4 gy-2">
                  <div className="kyc-fields-container col-12  col-md-4">
                    <label
                      htmlFor="companyFullName"
                      className="kyc-label-text form-label"
                    >
                      Company Full Name
                    </label>
                    <input
                      disabled
                      id="companyFullName"
                      type="text"
                      name="companyFullName"
                      className="kyc-input-field form-control"
                      value={kycData.data?.success?.companyFullName}
                    />
                  </div>

                  <div className="kyc-fields-container col-12  col-md-4">
                    <label
                      htmlFor="companyType"
                      className="kyc-label-text form-label"
                    >
                      Company Type
                    </label>
                    <input
                      disabled
                      id="companyType"
                      type="text"
                      name="companyType"
                      className="kyc-input-field form-control"
                      value={kycData.data?.success?.companyType}
                    />
                  </div>

                  <div className="kyc-fields-container col-12  col-md-4">
                    <label
                      htmlFor="websiteAddress"
                      className="kyc-label-text form-label"
                    >
                      Website Address
                    </label>
                    <input
                      disabled
                      id="websiteAddress"
                      type="text"
                      name="websiteAddress"
                      className="kyc-input-field form-control"
                      value={kycData.data?.success?.websiteAddress}
                    />
                  </div>

                  <div className="kyc-fields-container col-12  col-md-4">
                    <label htmlFor="pan" className="kyc-label-text form-label">
                      Pan No.
                    </label>
                    <input
                      disabled
                      id="pan"
                      type="text"
                      name="pan"
                      className="kyc-input-field form-control"
                      value={kycData.data?.success?.PANNumber}
                    />
                  </div>

                  <div className="kyc-fields-container col-12  col-md-4">
                    <label htmlFor="gst" className="kyc-label-text form-label">
                      GST No.
                    </label>
                    <input
                      disabled
                      id="gst"
                      type="text"
                      name="gst"
                      className="kyc-input-field form-control"
                      value={kycData.data?.success?.GSTNumber}
                    />
                  </div>

                  <div className="kyc-fields-container col-12  col-md-4">
                    <label htmlFor="cin" className="kyc-label-text form-label">
                      CIN No.
                    </label>
                    <input
                      disabled
                      id="cin"
                      type="text"
                      name="cin"
                      className="kyc-input-field form-control"
                      value={kycData.data?.success?.CIN}
                    />
                  </div>

                  <div className="kyc-fields-container col-12  col-md-4">
                    <label
                      htmlFor="productCategory"
                      className="kyc-label-text form-label"
                    >
                      Product Category
                    </label>
                    <input
                      disabled
                      id="productCategory"
                      type="text"
                      name="productCategory"
                      className="kyc-input-field form-control"
                      value={kycData.data?.success?.productCategory}
                    />
                  </div>

                  <div className="kyc-fields-container col-12  col-md-4">
                    <label
                      htmlFor="companyFullAddress"
                      className="kyc-label-text form-label"
                    >
                      Company Full Address
                    </label>
                    <input
                      disabled
                      id="companyFullAddress"
                      type="text"
                      name="companyFullAddress"
                      className="kyc-input-field form-control"
                      value={kycData.data?.success?.companyFullAddress}
                    />
                  </div>

                  <div className="kyc-fields-container col-12  col-md-4">
                    <label htmlFor="city" className="kyc-label-text form-label">
                      City
                    </label>
                    <input
                      disabled
                      id="city"
                      type="text"
                      name="city"
                      className="kyc-input-field form-control"
                      value={kycData.data?.success?.city}
                    />
                  </div>

                  <div className="kyc-fields-container col-12  col-md-4">
                    <label
                      htmlFor="state"
                      className="kyc-label-text form-label"
                    >
                      State
                    </label>
                    <input
                      disabled
                      id="state"
                      type="text"
                      name="state"
                      className="kyc-input-field form-control"
                      value={kycData.data?.success?.state}
                    />
                  </div>

                  <div className="kyc-fields-container col-12  col-md-4">
                    <label
                      htmlFor="pinCode"
                      className="kyc-label-text form-label"
                    >
                      Pin code
                    </label>
                    <input
                      disabled
                      id="pinCode"
                      type="text"
                      name="pinCode"
                      className="kyc-input-field form-control"
                      value={kycData.data?.success?.pinCode}
                    />
                  </div>

                  <div className="kyc-fields-container col-12  col-md-4">
                    <label
                      htmlFor="region"
                      className="kyc-label-text form-label"
                    >
                      Region
                    </label>
                    <input
                      disabled
                      id="region"
                      type="text"
                      name="region"
                      className="kyc-input-field form-control"
                      value={kycData.data?.success?.region}
                    />
                  </div>

                  <div className="kyc-fields-container col-12  col-md-4">
                    <label
                      htmlFor="country"
                      className="kyc-label-text form-label"
                    >
                      Country
                    </label>
                    <input
                      disabled
                      id="country"
                      type="text"
                      name="country"
                      className="kyc-input-field form-control"
                      value={kycData.data?.success?.country}
                    />
                  </div>

                  <div className="kyc-fields-container col-12  col-md-4">
                    <label
                      htmlFor="telephone"
                      className="kyc-label-text form-label"
                    >
                      Telephone Number
                    </label>
                    <input
                      disabled
                      id="telephone"
                      type="text"
                      name="telephone"
                      className="kyc-input-field form-control"
                      value={kycData.data?.success?.phoneNumber}
                    />
                  </div>

                  <div className="kyc-fields-container col-12  col-md-4">
                    <label
                      htmlFor="selectedAnchor"
                      className="kyc-label-text form-label"
                    >
                      Selected Anchor
                    </label>
                    <input
                      disabled
                      id="selectedAnchor"
                      type="text"
                      name="selectedAnchor"
                      className="kyc-input-field form-control"
                      value={kycData.data?.success?.distributorCompanyName}
                    />
                  </div>

                  <div className="kyc-fields-container col-12  col-md-8">
                    <label
                      htmlFor="aboutCompany"
                      className="kyc-label-text form-label"
                    >
                      About Company
                    </label>
                    <textarea
                      disabled
                      id="aboutCompany"
                      type="text"
                      name="aboutCompany"
                      className="kyc-input-field form-control"
                      value={kycData.data?.success?.detailsAboutCompany}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex flex-column">
              <span className="kyc-content-title my-3">Bank Details</span>
              <div className="details-container container">
                <div className="row gy-md-4 gy-2">
                  <div className="kyc-fields-container col-12  col-md-4">
                    <label htmlFor="name" className="kyc-label-text form-label">
                      Account Number
                    </label>
                    <input
                      disabled
                      id="name"
                      name="name"
                      maxLength={20}
                      type="text"
                      className="kyc-input-field form-control"
                      value={kycData.data?.success?.accountNumber}
                    />
                  </div>

                  <div className="kyc-fields-container col-12  col-md-4">
                    <label htmlFor="pan" className="kyc-label-text form-label">
                      Account Holder's Name
                    </label>
                    <input
                      disabled
                      id="pan"
                      type="text"
                      name="pan"
                      className="kyc-input-field form-control"
                      value={kycData.data?.success?.accountHolderName}
                    />
                  </div>

                  <div className="kyc-fields-container col-12  col-md-4">
                    <label
                      htmlFor="email"
                      className="kyc-label-text form-label"
                    >
                      Account Type
                    </label>
                    <input
                      disabled
                      id="email"
                      type="text"
                      name="email"
                      className="kyc-input-field form-control"
                      value={kycData.data?.success?.accountType}
                    />
                  </div>

                  <div className="kyc-fields-container col-12  col-md-4">
                    <label
                      htmlFor="mobileNumber"
                      className="kyc-label-text form-label"
                    >
                      Bank Name
                    </label>
                    <input
                      disabled
                      id="mobileNumber"
                      type="datextte"
                      name="mobileNumber"
                      className="kyc-input-field form-control"
                      value={kycData.data?.success?.bankName}
                    />
                  </div>

                  <div className="kyc-fields-container col-12  col-md-4">
                    <label
                      htmlFor="mobileNumber"
                      className="kyc-label-text form-label"
                    >
                      IFSC Code
                    </label>
                    <input
                      disabled
                      id="mobileNumber"
                      type="datextte"
                      name="mobileNumber"
                      className="kyc-input-field form-control"
                      value={kycData.data?.success?.IFSCCode}
                    />
                  </div>

                  <div className="kyc-fields-container col-12  col-md-4">
                    <label
                      htmlFor="mobileNumber"
                      className="kyc-label-text form-label"
                    >
                      Loan Request Amount
                    </label>
                    <input
                      disabled
                      id="mobileNumber"
                      type="datextte"
                      name="mobileNumber"
                      className="kyc-input-field form-control"
                      value={kycData.data?.success?.loanRequestAmount}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex flex-column">
              <span className="kyc-content-title my-3">Document Details</span>
              <div className="details-container container">
                <div className="row gy-md-4 gy-2">
                  <div className="kyc-fields-container col-12  col-md-4">
                    <label htmlFor="name" className="kyc-label-text form-label">
                      Aadhaar Card Front
                    </label>

                    <button
                      onClick={toggleAadhaarView}
                      className="d-flex justify-content-center align-items-center gap-3 list-group-item list-group-item-action list-group-item-primary w-100 py-2 rounded fw-semibold"
                    >
                      View Aadhaar <BsEyeFill className="fs-4" />{" "}
                    </button>
                  </div>

                  <div className="kyc-fields-container col-12  col-md-4">
                    <label htmlFor="pan" className="kyc-label-text form-label">
                      Bank Statement
                    </label>
                    <button
                      onClick={toggleBankStatementView}
                      className="d-flex justify-content-center align-items-center gap-3 list-group-item list-group-item-action list-group-item-primary w-100 py-2 rounded fw-semibold"
                    >
                      View Bank Statement <BsEyeFill className="fs-4" />{" "}
                    </button>
                  </div>

                  {console.log(files)}
                  {
                    files.length > 0 && files.map((file, index) => (
                      <div className="kyc-fields-container col-12  col-md-4" key={file.name ?? index}>
                        <label htmlFor="pan" className="kyc-label-text form-label" style={{ textTransform: "capitalize" }}>
                          {/* { file?.name ?? "Document-" + index } */}
                          { file?.name ? "Document-" + file?.name:"Other Documents" }
                        </label>
                        <a
                          // href={file?.otherDocs}
                          href={file?.otherDocs||file?.profilePicture||file?.bankstatement||file?.userProfilePic||file?.adharImage||file?.coAadharImage}
                          title={file?.name} target="_blank" rel="noreferrer"
                          className="d-flex justify-content-center align-items-center gap-3 list-group-item list-group-item-action list-group-item-primary w-100 py-2 rounded fw-semibold"
                        >
                          View Document <BsEyeFill className="fs-4" />{" "}
                        </a>
                      </div>
                    ))
                  }

                </div>
              </div>
            </div>

            <div className="d-flex flex-column flex-md-row align-items-center justify-content-end gap-3 mx-5 my-3">
              <button className="btn btn-outline-dark" onClick={onClickBack}>
                Back
              </button>

              <div className="d-flex align-items-center gap-3">
                {kycData.data?.success?.adminSubmitStatus === "submitted" && (
                  <button disabled className="btn btn-success">
                    {kycApproveData.isLoading ? <Loader /> : "Approved"}
                  </button>
                )}
                {/* {kycData.data?.success?.status === "sent back" && (
                  <button disabled className="btn btn-warning">
                    Sent Back
                  </button>
                )}
                {kycData.data?.success?.status === "rejected" && (
                  <button disabled className="btn btn-outline-danger">
                    Reject
                  </button>
                )} */}
              </div>

              {(!kycData.data?.success?.adminSubmitStatus ||
                kycData.data?.success?.adminSubmitStatus === "pending") && (
                <div className="d-flex align-items-center gap-3">
                  <button
                    className="btn btn-success"
                    onClick={() =>
                      onClickSubmitStatus({
                        kycStatus: "submitted",
                        kycRemarks: "Cam is proper",
                      })
                    }
                  >
                    {kycApproveData.isLoading ? <Loader /> : "Approve"}
                  </button>
                  <button
                    className="btn btn-warning"
                    onClick={toggleSendBackModal}
                  >
                    Sent Back
                  </button>
                  <button
                    className="btn btn-outline-danger"
                    onClick={toggleRejectModal}
                  >
                    Reject
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AdminUserKyc;
