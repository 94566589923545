export const ChannelPartnerKycActions = Object.freeze({
	// Common for all the objects
	UPDATE_FROM_API: "UPDATE_FROM_API",

	// Types for Personal Details
	PERSONAL_DETAILS: Object.freeze({
		CHANGE_NAME: "CHANGE_NAME",
		BLUR_NAME: "BLUR_NAME",

		CHANGE_PAN: "CHANGE_PAN",
		BLUR_PAN: "BLUR_PAN",

		CHANGE_MOBILE: "CHANGE_MOBILE",
		BLUR_MOBILE: "BLUR_MOBILE",

		CHANGE_AADHAAR: "CHANGE_AADHAAR",
		BLUR_AADHAAR: "BLUR_AADHAAR",
		AADHAAR_UPDATE_API: "AADHAAR_UPDATE_API",

		CHANGE_PROFILE_PIC: "CHANGE_PROFILE_PIC",
		BLUR_PROFILE_PIC: "BLUR_PROFILE_PIC",
		PROFILE_PIC_UPDATE_API: "PROFILE_PIC_UPDATE_API",

		CHANGE_COMPANY_TYPE: "CHANGE_COMPANY_TYPE",
		BLUR_COMPANY_TYPE: "BLUR_COMPANY_TYPE",
		
		CHANGE_GST: "CHANGE_GST",
		BLUR_GST: "BLUR_GST",

		CHANGE_ADDRESS: "CHANGE_ADDRESS",
		BLUR_ADDRESS: "BLUR_ADDRESS",

		CHANGE_CITY: "CHANGE_CITY",
		BLUR_CITY: "BLUR_CITY",

		CHANGE_STATE: "CHANGE_STATE",
		BLUR_STATE: "BLUR_STATE",

		CHANGE_PIN: "CHANGE_PIN",
		BLUR_PIN: "BLUR_PIN",

		CHANGE_ADDRESS_PROOF_PIC: "CHANGE_ADDRESS_PROOF_PIC",
		BLUR_ADDRESS_PROOF_PIC: "BLUR_ADDRESS_PROOF_PIC",
		ADDRESS_PROOF_PIC_UPDATE_API: "ADDRESS_PROOF_PIC_UPDATE_API",

		CHANGE_BUREAU_REPORT_PIC: "CHANGE_BUREAU_REPORT_PIC",
		BLUR_BUREAU_REPORT_PIC: "BLUR_BUREAU_REPORT_PIC",
		BUREAU_REPORT_PIC_UPDATE_API: "BUREAU_REPORT_PIC_UPDATE_API",

		CHANGE_BUSINESS_PROFILE_PIC: "CHANGE_BUSINESS_PROFILE_PIC",
		BLUR_BUSINESS_PROFILE_PIC: "BLUR_BUSINESS_PROFILE_PIC",
		BUSINESS_PROFILE_PIC_UPDATE_API: "BUSINESS_PROFILE_PIC_UPDATE_API",
		
		TOUCH_ALL: "TOUCH_ALL",
	}),

	DOCUMENT_DETAIL: Object.freeze({
		CHANGE_LOAN: "CHANGE_LOAN",
		BLUR_LOAN: "BLUR_LOAN",
		TOUCH_ALL: "TOUCH_ALL",
	}),
});
