import React from "react";
import { BsSearch } from "react-icons/bs";
import AdminCard from "../Admin/AdminCard";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminDashboardTable from "../Lender/LenderTables/LenderInvoiceTable";
import { getBillDetail } from "../../store/slices/lender.js";
import Loader from "../../layout/GenericLayout/Loader";
import { useNavigate } from "react-router-dom";

const BillDetail = ({ Route }) => {
  const navigate = useNavigate();
  console.log(Route);
  const dispatch = useDispatch();
  const customerBillData = useSelector(
    (state) => state.lender.billData?.data?.items[0]
  );

  const billData = useSelector((state) => state.lender.billData);
  console.log(customerBillData);

  useEffect(() => {
    dispatch(getBillDetail());
  }, [dispatch]);

  return (
    <div className="d-flex flex-column gap-4 bg-white p-3 rounded">
      <div className="d-flex justify-content-between flex-column px-2 gap-2">
        <div
          className="d-flex flex-column mt-4"
          style={{
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            borderRadius: "7px",
          }}
        >
          <div
            className=""
            style={{
              backgroundColor: "#aeb7e5",
              borderRadius: "7px",
              padding: "7px",
              height: "40px",
            }}
          >
            <span className="kyc-content-title my-3 fw-semibold text-white">
              Bill Details
            </span>
          </div>

          {billData.isLoading ? (
            <div className="py-5">
              {" "}
              <Loader />{" "}
            </div>
          ) : (
            <div className="details-container container p-3">
              <div className="row gy-md-4 gy-2">
                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="billing"
                    className="kyc-label-text form-label fw-semibold"
                  >
                    Bill Reference No.
                  </label>
                  <input
                    disabled
                    id="billing"
                    type="text"
                    name="billing"
                    value={customerBillData?.billReferenceNo}
                    className="kyc-input-field form-control"
                  />
                </div>

                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="shipping"
                    className="kyc-label-text form-label fw-semibold"
                  >
                    Invoice No.
                  </label>
                  <input
                    id="shipping"
                    disabled
                    type="text"
                    name="shipping"
                    className="kyc-input-field form-control"
                    value={customerBillData?.invoiceId}
                  />
                </div>
                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="shipping"
                    className="kyc-label-text form-label fw-semibold"
                  >
                    Invoice Date
                  </label>
                  <input
                    id="shipping"
                    disabled
                    type="text"
                    name="shipping"
                    className="kyc-input-field form-control"
                    value={customerBillData?.invoiceDate}
                  />
                </div>
              </div>
              <div className="row gy-md-4 mt-2 gy-2">
                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="billing"
                    className="kyc-label-text form-label fw-semibold"
                  >
                    Invoice Amount
                  </label>
                  <input
                    disabled
                    id="billing"
                    type="text"
                    name="billing"
                    className="kyc-input-field form-control"
                    value={customerBillData?.invoiceAmount}
                  />
                </div>

                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="shipping"
                    className="kyc-label-text form-label fw-semibold"
                  >
                    Lodgement Date
                  </label>
                  <input
                    id="shipping"
                    disabled
                    type="text"
                    name="shipping"
                    className="kyc-input-field form-control"
                    value={customerBillData?.lodgementDate}
                  />
                </div>
                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="shipping"
                    className="kyc-label-text form-label fw-semibold"
                  >
                    Due Date
                  </label>
                  <input
                    id="shipping"
                    disabled
                    type="text"
                    name="shipping"
                    className="kyc-input-field form-control"
                    value={customerBillData?.dueDate}
                  />
                </div>
              </div>
              <div className="row gy-md-4 mt-2 gy-2">
                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="billing"
                    className="kyc-label-text form-label fw-semibold"
                  >
                    Fee Amount
                  </label>
                  <input
                    disabled
                    id="billing"
                    type="text"
                    name="billing"
                    className="kyc-input-field form-control"
                    value={customerBillData?.fees}
                  />
                </div>

                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="shipping"
                    className="kyc-label-text form-label fw-semibold"
                  >
                    Interest Amount
                  </label>
                  <input
                    id="shipping"
                    disabled
                    type="text"
                    name="shipping"
                    className="kyc-input-field form-control"
                    value={customerBillData?.interestAmount}
                  />
                </div>
                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="shipping"
                    className="kyc-label-text form-label fw-semibold"
                  >
                    Interest Rate (10%)
                  </label>
                  <input
                    id="shipping"
                    disabled
                    type="text"
                    name="shipping"
                    className="kyc-input-field form-control"
                    value={customerBillData?.interestRate}
                  />
                </div>
              </div>
              <div className="row gy-md-4 mt-2 gy-2">
                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="billing"
                    className="kyc-label-text form-label fw-semibold"
                  >
                    Amount Financed
                  </label>
                  <input
                    disabled
                    id="billing"
                    type="text"
                    name="billing"
                    className="kyc-input-field form-control"
                    value={customerBillData?.amountFinanced}
                  />
                </div>

                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="shipping"
                    className="kyc-label-text form-label fw-semibold"
                  >
                    Tenure (Days)
                  </label>
                  <input
                    id="shipping"
                    disabled
                    type="text"
                    name="shipping"
                    className="kyc-input-field form-control"
                    value={customerBillData?.tenure}
                  />
                </div>
                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="shipping"
                    className="kyc-label-text form-label fw-semibold"
                  >
                    Bill Discounted date
                  </label>
                  <input
                    id="shipping"
                    disabled
                    type="text"
                    name="shipping"
                    className="kyc-input-field form-control"
                    value={customerBillData?.discountedDate}
                  />
                </div>
              </div>

              <div className="row gy-md-4 mt-2 gy-2">
                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="billing"
                    className="kyc-label-text form-label fw-semibold"
                  >
                    Outstanding Amount
                  </label>
                  <input
                    disabled
                    id="billing"
                    type="text"
                    name="billing"
                    className="kyc-input-field form-control"
                    value={customerBillData?.outstandingAmount}
                  />
                </div>

                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="shipping"
                    className="kyc-label-text form-label fw-semibold"
                  >
                    Margin Amount
                  </label>
                  <input
                    id="shipping"
                    disabled
                    type="text"
                    name="shipping"
                    className="kyc-input-field form-control"
                    value={customerBillData?.marginAmount}
                  />
                </div>
                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="shipping"
                    className="kyc-label-text form-label fw-semibold"
                  >
                    Margin Rate
                  </label>
                  <input
                    id="shipping"
                    disabled
                    type="text"
                    name="shipping"
                    className="kyc-input-field form-control"
                    value={customerBillData?.margin}
                  />
                </div>
              </div>

              <div className="row gy-md-4 mt-2 gy-2">
                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="billing"
                    className="kyc-label-text form-label fw-semibold"
                  >
                    Disbursed Amount
                  </label>
                  <input
                    disabled
                    id="billing"
                    type="text"
                    name="billing"
                    className="kyc-input-field form-control"
                    value={customerBillData?.disbursingAmount}
                  />
                </div>

                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="shipping"
                    className="kyc-label-text form-label fw-semibold"
                  >
                    Remaining Amount
                  </label>
                  <input
                    id="shipping"
                    disabled
                    type="text"
                    name="shipping"
                    className="kyc-input-field form-control"
                    value={customerBillData?.outstandingRemainingAmount}
                  />
                </div>
                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="shipping"
                    className="kyc-label-text form-label fw-semibold"
                  >
                    Overdue Amount
                  </label>
                  <input
                    id="shipping"
                    disabled
                    type="text"
                    name="shipping"
                    className="kyc-input-field form-control"
                    value={customerBillData?.complianceCheck}
                  />
                </div>
              </div>
            </div>
          )}

          <div className="d-flex justify-content-end p-4">
            <button
              onClick={() => navigate(-1)}
              className="btn btn-outline-dark px-5"
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillDetail;
