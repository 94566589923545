import { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useOutlet } from "react-router-dom";
import { BsSearch } from "react-icons/bs";
import { TbBellRingingFilled } from "react-icons/tb";
import { FcAdvertising } from "react-icons/fc";
import { FaBars } from "react-icons/fa";
import { RxCross1 } from "react-icons/rx";
import { MdOutlineManageAccounts } from "react-icons/md";
import { AiFillLeftCircle, AiFillSetting } from "react-icons/ai";
import { FiLogOut } from "react-icons/fi";
import atonLogo from "../../assests/images/Aton-logo.png";
import notificationDeclined from "../../assests/icons/notification-declined.svg";
import notificationActive from "../../assests/icons/notification-active.svg";
import {
  DashBoardIcon,
  MaterialRequestIcon,
  ProformaInvoiceIcon,
  PurchaseOrderIcon,
  InvoiceIcon,
  // DisbursedIcon,
  PaymentReconciliationIcon,
  CounterPartyIcon,
  AgreeMentIcon,
} from "../../assests/icons/sidebar/Anchor/DashBoard";

import profilePic from '../../assests/images/profilePicture.jpg'
import {profilePicture} from '../../store/slices/generic'
import { useDispatch, useSelector } from "react-redux";

const AnchorLayout = () => {
  const outlet = useOutlet();
  const navigate = useNavigate();
  const userDetails = JSON.parse(sessionStorage.getItem("user"));
  const [activeMenu, setActiveMenu] = useState("/anchor/dashboard");
  const [notification, setNotification] = useState([]);
  const [mobileNavbarStatus, setMobileNavbarBar] = useState(false);


  const sidebarRef=useRef(null);
  const sidebarMenuTextRef=useRef(null);
  const sidebarCircleRef=useRef(null);
  const dispatch=useDispatch();


   //profile picture
   const [profilePictureImage, setProfilePictureImage]=useState(profilePic);
   const profilePictureData=useSelector(
     (state)=> state.generic.profilePictureData
   )
 
 
   useEffect(()=>{
     dispatch(profilePicture());
   },[])
 
 
   useEffect(()=>{
     console.log(profilePictureData);
     if(profilePictureData?.data){
      const fileExtension = profilePictureData?.data?.items[0]?.URL?.split('.')?.pop()?.toLowerCase();
      console.log(profilePictureData?.data?.items[0]?.URL, fileExtension)
      if(fileExtension && fileExtension !== 'pdf'){
        setProfilePictureImage(profilePictureData?.data?.items[0]?.URL)
      }
    }
   },[profilePictureData.data])



  const onClickActiveMenu = (path) => {
    setActiveMenu(path);
    setMobileNavbarBar((prev) => !prev);
    navigate(path);
  };

  const onClickSidebarCollaspe = () => {
    // let sidebar = document.querySelector(".admin-sidebar");
    const sidebar=sidebarRef.current;
    if (sidebar.style.minWidth === "120px") {
      sidebar.style.minWidth = "350px";
      sidebar.style.width = "350px";
    } else {
      sidebar.style.minWidth = "120px";
      sidebar.style.width = "120px";
    }

    // let sidebarMenuText = document.querySelectorAll(".sidebar-text");
    let sidebarMenuText= sidebarMenuTextRef.current.childNodes;
    sidebarMenuText.forEach((eachMenuText) => {
      if (eachMenuText.lastChild.style.display === "none") {
        eachMenuText.lastChild.style.display = "";
      } else {
        eachMenuText.lastChild.style.display = "none";
      }
    });

    // let sidebarCircle = document.querySelector(".sidebar-circle-icon");
    const sidebarCircle= sidebarCircleRef.current;
    if (sidebarCircle.style.transform === "rotate(180deg)") {
      sidebarCircle.style.transform = "rotate(0deg)";
    } else {
      sidebarCircle.style.transform = "rotate(180deg)";
    }
  };

  const onChangeAdminSearch = (event) => {
    // Search resutls to be handled
  };

  const onClickLogout = () => {
    sessionStorage.clear();
    window.location.href = "/login";
  };

  const onClickMyProfile = () => {
    navigate("/anchor/my-profile");
  };

  // ********small and medium devices events********
  const onClickMobileNavbar = () => {
    setMobileNavbarBar((prev) => !prev);
  };

  console.log("cookie : ", document.cookie);

  return (
    <>
      {/* desktop and tablet layout */}
      <div className="d-md-flex gap-2 max-vh-100 admin-layout-bg-container p-3 pb-5 pe-0 d-none">
        <div className="admin-sidebar mh-100 d-flex flex-column py-1" ref={sidebarRef}>
          <div className="sidebar-circle" ref={sidebarCircleRef}>
            <AiFillLeftCircle
              className="sidebar-circle-icon"
              onClick={onClickSidebarCollaspe}
            />
          </div>
          <Link to="/anchor/dashboard">
            <div className="d-flex justify-content-center align-items-center">
              <img src={atonLogo} className="admin-sidebar-image-logo" />
            </div>
          </Link>

          <div className="sidebar-menu-container d-flex flex-column gap-2" ref={sidebarMenuTextRef}>
            <div
              className={`d-flex align-items-end px-5 gap-3 py-2 ${
                window.location.pathname.includes("/anchor/dashboard")
                  ? "sidebar-active-menu"
                  : "menu-container"
              }`}
              title="Dashboard"
              onClick={() => onClickActiveMenu("/anchor/dashboard")}
            >
              <DashBoardIcon />
              <span className="sidebar-text">Dashboard</span>
            </div>

            <div
              className={`d-flex align-items-center px-5 gap-3 py-2 ${
                window.location.pathname.includes("/anchor/view-inventory")
                  ? "sidebar-active-menu"
                  : "menu-container"
              }`}
              onClick={() => onClickActiveMenu("/anchor/view-inventory")}
              title="Inventory"
            >
              <MaterialRequestIcon />
              <span className="sidebar-text">Inventory</span>
            </div>

            <div
              className={`d-flex align-items-center px-5 gap-3 py-2 ${
                window.location.pathname.includes("/anchor/material-request")
                  ? "sidebar-active-menu"
                  : "menu-container"
              }`}
              onClick={() => onClickActiveMenu("/anchor/material-request")}
              title="Material Request"
            >
              <MaterialRequestIcon />
              <span className="sidebar-text">Material Request</span>
            </div>

            <div
              className={`d-flex align-items-center px-5 gap-3 py-2 ${
                window.location.pathname.includes("/anchor/proforma-invoice")
                  ? "sidebar-active-menu"
                  : "menu-container"
              }`}
              onClick={() => onClickActiveMenu("/anchor/proforma-invoice")}
              title="Proforma Invoice"
            >
              <ProformaInvoiceIcon />
              <span className="sidebar-text">Proforma Invoice</span>
            </div>

            <div
              className={`d-flex align-items-center px-5 gap-3 py-2 ${
                window.location.pathname.includes("/anchor/purchase-order")
                  ? "sidebar-active-menu"
                  : "menu-container"
              }`}
              onClick={() => onClickActiveMenu("/anchor/purchase-order")}
              title="Purchase Order"
            >
              <PurchaseOrderIcon />
              <span className="sidebar-text">Purchase Order</span>
            </div> 

            <div
              className={`d-flex align-items-center px-5 gap-3 py-2 ${
                window.location.pathname.includes("/anchor/INV-reference-number")
                  ? "sidebar-active-menu"
                  : "menu-container"
              }`}
              onClick={() => onClickActiveMenu("/anchor/INV-reference-number")}
              title="INV Reference Number"
            >
              <PurchaseOrderIcon />
              <span className="sidebar-text" id="INV_reference_number">INV Reference Number</span>
            </div>

            <div
              className={`d-flex align-items-center  px-5 gap-3 py-2 ${
                window.location.pathname.includes("/anchor/invoice")
                  ? "sidebar-active-menu"
                  : "menu-container"
              }`}
              onClick={() => onClickActiveMenu("/anchor/invoice")}
              title="Invoice"
            >
              <InvoiceIcon />
              <span className="sidebar-text">Invoice</span>
            </div>
            
            <div
              className={`d-flex align-items-center  px-5 gap-3 py-2 ${
                window.location.pathname.includes("/anchor/bulk_invoice")
                  ? "sidebar-active-menu"
                  : "menu-container"
              }`}
              onClick={() => onClickActiveMenu("/anchor/bulk_invoice")}
              title="Batch Invoice"
            >
              <InvoiceIcon />
              <span className="sidebar-text">Batch Invoice</span>
            </div>

            {/* <div
              className={`d-flex align-items-center px-5 gap-3 py-2 ${
                window.location.pathname.includes("/anchor/disbursed")
                  ? "sidebar-active-menu"
                  : "menu-container"
              }`}
              onClick={() => onClickActiveMenu("/anchor/disbursed")}
            >
              <DisbursedIcon />
              <span className="sidebar-text">Disbursed Invoice</span>
            </div> */}

            <div
              className={`d-flex align-items-center px-5 gap-3 py-2 ${
                window.location.pathname.includes("/anchor/payment")
                  ? "sidebar-active-menu"
                  : "menu-container"
              }`}
              onClick={() => onClickActiveMenu("/anchor/payment")}
              title="Payment Reconciliation"
            >
              <PaymentReconciliationIcon />
              <span className="sidebar-text">Payment Reconciliation</span>
            </div>

            <div
              className={`d-flex align-items-center px-5 gap-3 py-2 ${
                window.location.pathname.includes("/anchor/counter-party")
                  ? "sidebar-active-menu"
                  : "menu-container"
              }`}
              onClick={() => onClickActiveMenu("/anchor/counter-party")}
              title="Counter Party"
            >
              <CounterPartyIcon />
              <span className="sidebar-text">Counter Party</span>
            </div>

            <div
              className={`d-flex align-items-center px-5 gap-3 py-2 ${
                window.location.pathname.includes("/anchor/agreement")
                  ? "sidebar-active-menu"
                  : "menu-container"
              }`}
              onClick={() => onClickActiveMenu("/anchor/agreement")}
              title="Agreement"
            >
              <AgreeMentIcon />
              <span className="sidebar-text">Agreement</span>
            </div>
          </div>
        </div>

        <div className="d-flex flex-column gap-2 flex-grow-1">
          <div className={`d-flex align-items-center pe-3 search justify-content-end py-2`}>
            
          {/* { window.location.pathname.includes("/anchor/dashboard")?
            <div className="admin-search-container d-flex align-items-center py-1 px-4 mx-3 mb-3">
              <input
                className="admin-search-input-element d-flex flex-grow-1"
                type="search"
                placeholder="Search"
                onChange={onChangeAdminSearch}
              />{" "}
              <BsSearch />
            </div>
            :""} */}

            <div className="d-flex gap-3 align-items-center">
              <div className="dropdown-center">
                <button
                  className="notification-bell border-0"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {notification.length === 0 ? (
                    <img src={notificationDeclined} />
                  ) : (
                    <img src={notificationActive} />
                  )}
                </button>
                <ul
                  className="dropdown-menu dropdown-menu-end border-0 shadow"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <h6 className="dropdown-header d-flex align-items-center gap-2">
                      <TbBellRingingFilled /> Notification
                    </h6>
                  </li>
                  <li className="dropdown-item d-flex flex-column justify-content-center align-items-center">
                    <FcAdvertising className="fs-3" />
                    No new notification!
                  </li>
                </ul>
              </div>
              <div className="d-flex align-items-center gap-2">
                <div className="dropdown-center">
                  <button
                    className="border-0"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      className="admin-profile-pic rounded-circle"
                      src={profilePictureImage}
                    />
                  </button>
                  <ul
                    className="dropdown-menu dropdown-menu-start border-0 shadow"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <h6 className="dropdown-header d-flex align-items-center gap-2">
                        <AiFillSetting /> Menu
                      </h6>
                    </li>
                    <li
                      className="dropdown-item fw-semibold d-flex align-items-center gap-2"
                      style={{ cursor: "pointer" }}
                      onClick={onClickMyProfile}
                    >
                      {" "}
                      <MdOutlineManageAccounts /> My Profile
                    </li>
                    <li
                      className="dropdown-item fw-semibold d-flex align-items-center gap-2"
                      style={{ cursor: "pointer" }}
                      onClick={onClickLogout}
                    >
                      {" "}
                      <FiLogOut /> Logout
                    </li>
                  </ul>
                </div>

                <div className="d-flex flex-column justify-content=evenly">
                  <span className="admin-name">
                    {userDetails.name === null ? "N/A" : "Hi, " + userDetails.name}
                  </span>
                  <span className="role">Anchor!</span>
                </div>
              </div>
            </div>
          </div>
          <div className="admin-content p-2">
            {window.innerWidth > 767 && outlet}
            <footer className="footer">
              <div>Copyright &copy; 2023 Aton</div>
            </footer>
          </div>
        </div>
      </div>
      {/* mobile layout */}
      <div
        className={`d-md-none admin-layout-small-devices-bg-container d-flex flex-column ${
          mobileNavbarStatus && "active-navbar"
        }`}
      >
        <div className="topnav d-flex flex-column">
          <div className="d-flex justify-content-between align-items-center px-2 py-2 only-navbar">
            <div className="d-flex align-items-center gap-2">
              <button
                onClick={onClickMobileNavbar}
                type="button"
                className="btn outline-0 border-0"
              >
                {mobileNavbarStatus ? (
                  <RxCross1 className="fs-1" />
                ) : (
                  <FaBars className="fs-2" />
                )}
              </button>

              <Link to="/admin/dashboard">
                <div className="d-flex justify-content-center align-items-center">
                  <img src={atonLogo} className="mobile-navbar-image-logo" />
                </div>
              </Link>
            </div>

            <div className="d-flex align-items-center gap-2">
              <div className="dropdown-center">
                <button
                  className="border-0 bg-transparent"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {notification.length === 0 ? (
                    <img src={notificationDeclined} />
                  ) : (
                    <img src={notificationActive} />
                  )}
                </button>
                <ul
                  className="dropdown-menu dropdown-menu-end border-0 shadow"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <h6 className="dropdown-header d-flex align-items-center gap-2">
                      <TbBellRingingFilled /> Notification
                    </h6>
                  </li>
                  <li className="dropdown-item d-flex flex-column justify-content-center align-items-center">
                    <FcAdvertising className="fs-3" />
                    No new notification!
                  </li>
                </ul>
              </div>

              <div className="d-flex align-items-center gap-2">
                <div className="dropdown-center">
                  <button
                    className="border-0 bg-transparent"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      className="admin-profile-pic rounded-circle"
                      src={profilePictureImage}
                    />
                  </button>
                  <ul
                    className="dropdown-menu dropdown-menu-start border-0 shadow"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <h6 className="dropdown-header d-flex align-items-center gap-2">
                        <AiFillSetting /> Admin!
                      </h6>
                    </li>

                    <li className="dropdown-item d-flex flex-column justify-content-center align-items-center">
                      {userDetails.name === null ? "N/A" : userDetails.name}
                    </li>
                  </ul>
                </div>
              </div>

              <div
                className="fw-semibold d-flex align-items-center justify-content-center"
                style={{ cursor: "pointer" }}
                onClick={onClickLogout}
              >
                <FiLogOut className="fs-2" />
              </div>
            </div>
          </div>

          {mobileNavbarStatus && (
            <div className="min-vh-100">
              <div
                className="d-flex flex-column gap-1 justify-content-center"
                style={{ padding: "0 100px" }}
              >
                <div
                  className={`d-flex align-items-center gap-3 py-2 ${
                    window.location.pathname.includes("/admin/dashboard")
                      ? "mobile-sidebar-active-menu"
                      : "mobile-menu-container"
                  }`}
                  onClick={() => onClickActiveMenu("/admin/dashboard")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26.826"
                    height="26.816"
                    viewBox="0 0 26.826 26.816"
                  >
                    <g
                      id="noun-dashboard-3644009"
                      transform="translate(-98.002 -28.113)"
                    >
                      <path
                        className="sidebar-icons"
                        id="Path_6"
                        data-name="Path 6"
                        d="M100.684,28.113h6.626a2.882,2.882,0,0,1,2.682,2.682v3.914a2.682,2.682,0,0,1-2.682,2.682h-6.626A2.882,2.882,0,0,1,98,34.709V30.8a2.682,2.682,0,0,1,2.682-2.682Z"
                        fill="#2e3c83"
                      />
                      <path
                        className="sidebar-icons"
                        id="Path_7"
                        data-name="Path 7"
                        d="M379.394,28.113h6.626A2.882,2.882,0,0,1,388.7,30.8v9.192a2.682,2.682,0,0,1-2.682,2.682h-6.626a2.882,2.882,0,0,1-2.682-2.682V30.8A2.682,2.682,0,0,1,379.394,28.113Z"
                        transform="translate(-263.875 0)"
                        fill="#2e3c83"
                      />
                      <path
                        className="sidebar-icons"
                        id="Path_8"
                        data-name="Path 8"
                        d="M100.684,258.44h6.626a2.882,2.882,0,0,1,2.682,2.682v9.192A2.683,2.683,0,0,1,107.311,273h-6.626A2.882,2.882,0,0,1,98,270.315v-9.192A2.682,2.682,0,0,1,100.684,258.44Z"
                        transform="translate(0 -218.068)"
                        fill="#2e3c83"
                      />
                      <path
                        className="sidebar-icons"
                        id="Path_9"
                        data-name="Path 9"
                        d="M379.394,357.56h6.626a2.882,2.882,0,0,1,2.682,2.682v3.914a2.682,2.682,0,0,1-2.682,2.682h-6.626a2.882,2.882,0,0,1-2.682-2.682v-3.914A2.682,2.682,0,0,1,379.394,357.56Z"
                        transform="translate(-263.875 -311.912)"
                        fill="#2e3c83"
                      />
                    </g>
                  </svg>{" "}
                  <span className="sidebar-text">Dashboard</span>
                </div>

                <hr className="px-5 mobile-nav-line my-2" />

                <div
                  className={`d-flex align-items-center gap-3 py-2 ${
                    window.location.pathname.includes(
                      "/admin/anchor-and-lender"
                    )
                      ? "mobile-sidebar-active-menu"
                      : "mobile-menu-container"
                  }`}
                  onClick={() => onClickActiveMenu("/admin/anchor-and-lender")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25.166"
                    height="28.907"
                    viewBox="0 0 25.166 28.907"
                  >
                    <g
                      id="noun-files-3972654"
                      transform="matrix(-1, -0.017, 0.017, -1, 97.085, 60.505)"
                    >
                      <path
                        className="sidebar-icons"
                        id="Path_517"
                        data-name="Path 517"
                        d="M153.217,32.852v20.88a1.75,1.75,0,0,1-1.75,1.74H140.092a.87.87,0,0,1-.618-.256l-7-6.96a.87.87,0,0,1-.257-.614V32.851a1.75,1.75,0,0,1,1.75-1.74h17.5a1.75,1.75,0,0,1,1.75,1.74Z"
                        transform="translate(-59.332 -0.78)"
                        fill="#2e3c83"
                      />
                      <path
                        className="sidebar-icons"
                        id="Path_518"
                        data-name="Path 518"
                        d="M243.86,124.45v21.96h-18.3v1.83h18.3a1.835,1.835,0,0,0,1.83-1.83V124.45Z"
                        transform="translate(-147.979 -89.431)"
                        fill="#2e3c83"
                      />
                    </g>
                  </svg>
                  <span className="sidebar-text">Achor & Lender</span>
                </div>

                <hr className="px-5 mobile-nav-line my-2" />

                <div
                  className={`d-flex align-items-center gap-3 py-2 ${
                    window.location.pathname.includes("/admin/users")
                      ? "mobile-sidebar-active-menu"
                      : "mobile-menu-container"
                  }`}
                  onClick={() => onClickActiveMenu("/admin/users")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="29.434"
                    height="20.604"
                    viewBox="0 0 29.434 20.604"
                  >
                    <path
                      className="sidebar-icons"
                      id="Icon_awesome-users"
                      data-name="Icon awesome-users"
                      d="M4.415,11.08A2.943,2.943,0,1,0,1.472,8.137,2.946,2.946,0,0,0,4.415,11.08Zm20.6,0a2.943,2.943,0,1,0-2.943-2.943A2.946,2.946,0,0,0,25.019,11.08Zm1.472,1.472H23.548a2.935,2.935,0,0,0-2.074.855,6.727,6.727,0,0,1,3.454,5.031h3.035a1.47,1.47,0,0,0,1.472-1.472V15.5A2.946,2.946,0,0,0,26.491,12.552Zm-11.774,0A5.151,5.151,0,1,0,9.566,7.4,5.148,5.148,0,0,0,14.717,12.552Zm3.532,1.472h-.382a7.112,7.112,0,0,1-6.3,0h-.382a5.3,5.3,0,0,0-5.3,5.3v1.325a2.208,2.208,0,0,0,2.208,2.208H21.34a2.208,2.208,0,0,0,2.208-2.208V19.322A5.3,5.3,0,0,0,18.249,14.024ZM7.961,13.407a2.935,2.935,0,0,0-2.074-.855H2.943A2.946,2.946,0,0,0,0,15.5v1.472a1.47,1.47,0,0,0,1.472,1.472H4.5A6.744,6.744,0,0,1,7.961,13.407Z"
                      transform="translate(0 -2.25)"
                      fill="#2e3c83"
                    />
                  </svg>
                  <span className="sidebar-text">Users</span>
                </div>

                <hr className="px-5 mobile-nav-line my-2" />

                <div
                  className={`d-flex align-items-center gap-3 py-2 ${
                    window.location.pathname.includes("/admin/channel-partner")
                      ? "mobile-sidebar-active-menu"
                      : "mobile-menu-container"
                  }`}
                  onClick={() => onClickActiveMenu("/admin/channel-partner")}
                >
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21.505"
                    height="26.032"
                    viewBox="0 0 21.505 26.032"
                  >
                    <path
                      className="sidebar-icons"
                      id="Icon_map-post-box"
                      data-name="Icon map-post-box"
                      d="M17.027,2.865A7.288,7.288,0,0,0,9.671,10.17v17.3H12.5V21.813h9.055v5.659h2.83V10.17a7.288,7.288,0,0,0-7.357-7.305ZM2.88,1.44,7.047,8.231h.435A7.391,7.391,0,0,1,12.5,2.03V1.44Z"
                      transform="translate(-2.88 -1.44)"
                      fill="#2e3c83"
                    />
                  </svg>
                  <span className="sidebar-text">Channel Partner</span>
                </div>

                <hr className="px-5 mobile-nav-line my-2" />

                <div
                  className={`d-flex align-items-center gap-3 py-2 ${
                    window.location.pathname.includes(
                      "/admin/relationship-manager"
                    )
                      ? "mobile-sidebar-active-menu"
                      : "mobile-menu-container"
                  }`}
                  onClick={() =>
                    onClickActiveMenu("/admin/relationship-manager")
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22.088"
                    height="21.96"
                    viewBox="0 0 22.088 21.96"
                  >
                    <g
                      id="noun-manager-5101694"
                      transform="translate(-172.19 -103.216)"
                    >
                      <path
                        className="sidebar-icons"
                        id="Path_516"
                        data-name="Path 516"
                        d="M177.136,109.313a6.1,6.1,0,1,1,1.786,4.311A6.1,6.1,0,0,1,177.136,109.313Zm1.879,8.165h0a.318.318,0,0,0-.3-.219H177.16a4.971,4.971,0,0,0-4.971,4.971v2.206a.74.74,0,0,0,.74.739h8.159a.317.317,0,0,0,.3-.416Zm10.29-.219h-1.548a.317.317,0,0,0-.3.219l-2.374,7.282a.317.317,0,0,0,.3.416h8.154a.74.74,0,0,0,.739-.739V122.23a4.971,4.971,0,0,0-4.971-4.971Zm-6.073.172h0a.952.952,0,0,0-.952.952v5.077a.952.952,0,1,0,1.9,0v-5.077a.952.952,0,0,0-.952-.952Z"
                        transform="translate(0 0)"
                        fill="#2e3c83"
                      />
                    </g>
                  </svg>

                  <span className="sidebar-text">Relationship Manager</span>
                </div>

                <hr className="px-5 mobile-nav-line my-2" />

                <div
                  className={`d-flex align-items-center gap-3 py-2 ${
                    window.location.pathname.includes("/admin/super-admin")
                      ? "mobile-sidebar-active-menu"
                      : "mobile-menu-container"
                  }`}
                  onClick={() => onClickActiveMenu("/admin/super-admin")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24.632"
                    height="27.224"
                    viewBox="0 0 24.632 27.224"
                  >
                    <g
                      id="noun-admin-2805233"
                      transform="translate(-109.2 -13.722)"
                    >
                      <path
                        className="sidebar-icons"
                        id="Path_514"
                        data-name="Path 514"
                        d="M131.713,17.316a17.353,17.353,0,0,1-8.679-3.036,2.342,2.342,0,0,0-3.036,0,17.51,17.51,0,0,1-8.679,3.036,2.34,2.34,0,0,0-2.12,2.349c0,15.954,10.913,20.88,11.371,21.081a2.327,2.327,0,0,0,1.89,0c.458-.2,11.371-5.127,11.371-21.081a2.34,2.34,0,0,0-2.12-2.349Zm-4.726,16.555-.115-1.031a3.96,3.96,0,0,0-2.349-3.179,5.815,5.815,0,0,1-2.979.831,5.893,5.893,0,0,1-2.979-.831,4.017,4.017,0,0,0-2.349,3.179L116.1,33.9c-2.291-2.893-4.44-7.39-4.5-14.121,6.359-.63,9.91-3.494,9.882-3.58a19.726,19.726,0,0,0,9.91,3.552c-.058,6.731-2.177,11.228-4.411,14.121Z"
                        transform="translate(0)"
                        fill="#2e3c83"
                      />
                      <path
                        className="sidebar-icons"
                        id="Path_515"
                        data-name="Path 515"
                        d="M284.146,158.873a3.752,3.752,0,1,1-3.752-3.752,3.752,3.752,0,0,1,3.752,3.752"
                        transform="translate(-158.878 -134.167)"
                        fill="#fff"
                      />
                    </g>
                  </svg>
                  <span className="sidebar-text">Super Admin</span>
                </div>

                <hr className="px-5 mobile-nav-line my-2" />
              </div>
            </div>
          )}
        </div>
        {!mobileNavbarStatus && (
          <div className="min-vh-100 p-2">
            {window.innerWidth < 768 && outlet}
            <footer className="text-center " style={{fontSize:"0.8rem"}}>
              <div>Copyright &copy; 2023 Aton</div>
            </footer>
          </div>
        )}
      </div>
    </>
  );
};

export default AnchorLayout;
