import React, { useEffect, useState } from "react";
import { BsPlusCircleFill, BsSearch } from "react-icons/bs";
import Loader from "../../layout/GenericLayout/Loader";
import { useDispatch, useSelector } from "react-redux";
import { getMaterialRequestTable } from "../../store/slices/anchor";
import AnchorMaterialRequestTable from "./AnchorTables/AnchorMaterialRequestTable";
import { Helmet } from "react-helmet";

/* PARENT COMPONENT */
const AnchorMaterialRequest = () => {
  const dispatch = useDispatch();
  /* USESELECT HOOKS */
  const materialRequestTableData = useSelector(
    (state) => state.anchor.materialRequestTableData
  );
  const [materialRequestTableRows, setMaterialRequestTableRows] = useState([]);
  const [materialRequestMetaData, setMaterialRequestMetaData] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [searchByMaterialID, setSearchByMaterialID] = useState("");

  /* TABLE QUERY PARAMS */
  const [tableQueryParams, setTableQueryParams] = useState({
    tableOffset: 0,
    tableLimit: 5,
    tableSort: "modifiedDate",
    tableOrder: -1,
    currentPage: 1,
    search: "",
  });

  useEffect(() => {
    let tableData = ``;
    if (searchByMaterialID.length > 0) {
      tableData = `offset=${tableQueryParams.tableOffset}&limit=${tableQueryParams.tableLimit}&search=${searchByMaterialID}`;
      // &sort=${tableQueryParams.tableSort}&search=${searchByProformaID}&order=${tableQueryParams.tableOrder}`;
      dispatch(getMaterialRequestTable(tableData));
    } else {
      tableData = `offset=${tableQueryParams.tableOffset}&limit=${tableQueryParams.tableLimit}`;
      // &sort=${tableQueryParams.tableSort}&order=${tableQueryParams.tableOrder}`;
      dispatch(getMaterialRequestTable(tableData));
    }
  }, [tableQueryParams, dispatch, searchByMaterialID]);

  useEffect(() => {
    if (materialRequestTableData.data) {
      // console.log("inside useeffect ", materialRequestTableData);
      setMaterialRequestTableRows(materialRequestTableData.data.items);
      setMaterialRequestMetaData(materialRequestTableData.data.metadata);
    }
  }, [materialRequestTableData.data]);

  const handleSearchByMaterialIDOnchange = (e) => {
    setSearchByMaterialID(e.target.value);
  };

  // console.log(materialRequestMetaData)

  return (
    <div className="d-flex flex-column gap-4" style={{ overflowY: "auto" }}>
      <Helmet>
        <title>Material-Request</title>
      </Helmet>
      <div className="bg-white admin-dashboard-card-container p-3">
        <div className="d-flex justify-content-between align-items-center flex-wrap px-2 gap-2 py-2">
          <span className="dashboard-content-title">Material Request</span>
          {/* <div
            // onClick={toggleRMAddModal}
            className="d-flex align-items-center"
            type="button"
          >
            <div
              className={`rm-add-button ${isHovered ? "show" : ""}`}
              // onMouseEnter={handleMouseEnter}
              // onMouseLeave={handleMouseLeave}
            >
              ADD
            </div>
            <BsPlusCircleFill
              className="rm-plus-button"
              // onMouseEnter={handleMouseEnter}
              // onMouseLeave={handleMouseLeave}
            />
          </div> */}
        </div>

        <div className="d-flex flex-column flex-sm-row align-items-center mt-2 mb-1">
          <div className="d-flex flex-column justify-content-center gap-3 col-12 col-sm-6">
            <div className="search-container d-flex align-items-center py-1 px-3 col-12 col-sm-8">
              <input
                className="search-input-element d-flex flex-grow-1"
                type="text"
                placeholder="Search with Material Request ID"
                value={searchByMaterialID}
                onChange={handleSearchByMaterialIDOnchange}
              />{" "}
              <BsSearch className="fs-5" />
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex flex-column justify-content-between flex-wrap px-2 gap-2">
        <AnchorMaterialRequestTable
          className="admin-anchor-and-lender-mapped-table"
          tableHeader={[
            { name: "Material Request ID", sortValue: "mrId" },
            { name: "Counter Party", sortValue: "sellerCompanyFullName" },
            { name: "Items Count", sortValue: "" },
            { name: "Quantity Requested", sortValue: "" },
            { name: "Requested Date", sortValue: "" },
            { name: "Modified Date", sortValue: "modifiedDate" },
            { name: "Status", sortValue: "" },
          ]}
          tableRows={materialRequestTableRows}
          materialRequestMetaData={materialRequestMetaData}
          tableQueryParams={tableQueryParams}
          setTableQueryParams={setTableQueryParams}
          isLoading={materialRequestTableData.isLoading}
        />
      </div>
    </div>
  );
};

export default AnchorMaterialRequest;
