import { HiOutlineArrowNarrowDown } from "react-icons/hi";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import { BiSort } from "react-icons/bi";
import { BsPencil } from "react-icons/bs";
import TablePagination, {
  jumpToPage,
  nextPage,
  previousPage,
} from "../../../layout/GenericLayout/TablePagination";
import { useState, Fragment, useEffect } from "react";
import Loader from "../../../layout/GenericLayout/Loader";
import { Link, useLocation } from "react-router-dom";
import tachometer from "../../../assests/icons/tachometer-fast-alt.svg";
import { useDispatch } from "react-redux";
import { underwriterUpdateMaxCreditLimit } from "../../../store/slices/underwriter";
import { toastMessage } from "../../../layout/GenericLayout/ToastMessage";

/**
 * 
 * @param {{
 *  rowItem: {
 *   customerId: string,
 *   companyFullName: string,
 *   role: string,
 *   maxLimit: number,
 *   totalUtilisedAmnt: number,
 *   availableAmnt: number,
 *   grade: string | number,
 *   limitExpiry: string | number,
 *   limitNextRenewal: string | number,
 *   lineOfCredit: string,
 *   maxLimitStatus: number | string,
 * },
 * fetchCreditLimitDetails: () => void,
 * }} props 
 */
const TableRow = ({ rowItem, fetchCreditLimitDetails }) => {

  const [ $editEnabled, setEditEnabled ] = useState(false);
  const [ $editMaxLimit, setEditMaxLimit ] = useState(rowItem.maxLimit);

  const dispatch = useDispatch();

  function handleOnSubmit() {

    if (!parseInt($editMaxLimit) && !(parseInt($editMaxLimit) > 0)) {
      toastMessage("Invalid Limit", "error");
      return;
    }
    
    const data = {
      availableAmnt: rowItem.availableAmnt,
      customerId: rowItem?.customerId,
      grade: rowItem?.grade,
      holdAmnt: "",
      limitExpiry: rowItem?.limitExpiry,
      limitNextRenewal: rowItem?.limitNextRenewal,
      lineOfCredit: rowItem?.lineOfCredit,
      maxLateCharge: "",
      maxLimit: parseInt($editMaxLimit),
      suspendedAmnt: "",
      totalUtilisedAmnt: rowItem?.totalUtilisedAmnt,
      underwriterId: ""
    };

    dispatch(underwriterUpdateMaxCreditLimit(data))
      .unwrap()
      .then(response => {
        toastMessage("Credit Limit Updated successfully", "success");
        fetchCreditLimitDetails();
      })
      .catch(error => {
        toastMessage(error?.message ?? "Unable to update credit limit", "error");
      });
  }
  

  function handleScoreCard(){
    console.log(rowItem.emailId)
    //need to dispatch https://uat_api.atoncap.com/user/getdetails/distributoranchor@gmail.com and 
  //https://uat_api.atoncap.com/cam/get_score/distributoranchor@gmail.com
  }

  function handleCam(){
    console.log(rowItem.emailId)
    //need to dispatch https://uat_api.atoncap.com/cam/get_cam/distributoranchor@gmail.com
  }
  return (
    <Fragment>
      <tr>
        <td className="">{rowItem?.customerId}</td>
        <td className="">{rowItem?.companyFullName}</td>
        <td className="">{rowItem?.role === "seller" ? 
          <span className="text-warning">Anchor</span> 
          : rowItem.role === "buyer" ? 
          <span className="text-primary">Counter Party</span> 
          : "" }</td>
        <td className="">{
          !$editEnabled ?
            rowItem?.maxLimit :
          <input type="number" value={ $editMaxLimit } onChange={ e => setEditMaxLimit(e.target.value) } min={0} className="form-control" />
        }</td>
        <td className="">{rowItem?.totalUtilisedAmnt}</td>
        <td className="">{rowItem?.availableAmnt}</td>
        <td className="">{rowItem?.grade}</td>
        <td className="">{rowItem?.limitExpiry}</td>
        <td className="">{rowItem?.limitNextRenewal}</td>
        <td className="">{rowItem?.lineOfCredit === '' && <>{'SCF'}</>}</td>
        <td className="" onClick={handleScoreCard} style={{cursor:"pointer"}}><img src={tachometer} /></td>
        <td className="" onClick={handleCam} style={{cursor:"pointer"}}> 
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25.62"
            height="20.13"
            viewBox="0 0 25.62 20.13"
          >
            <path
              id="Icon_ionic-md-card"
              data-name="Icon ionic-md-card"
              d="M25.308,5.625H4.812A2.52,2.52,0,0,0,2.261,8.141l-.011,15.1a2.53,2.53,0,0,0,2.562,2.516h20.5a2.53,2.53,0,0,0,2.562-2.516V8.141A2.53,2.53,0,0,0,25.308,5.625Zm0,17.614H4.812V15.69h20.5Zm0-12.581H4.812V8.141h20.5Z"
              transform="translate(-2.25 -5.625)"
              fill="#2e3c83"
            />
          </svg>
        </td>
        <td> 
          <div className={rowItem?.maxLimitStatus==='approved'?
            "text-capitalize hightLight-success-td ":rowItem?.maxLimitStatus==='rejected'?
            "text-capitalize hightLight-danger-td ":"text-capitalize hightLight-warning-td "}
            style={{cursor:"default"}}>
            {rowItem?.maxLimitStatus}
          </div>
        </td>
        <td>
          {
          rowItem.maxLimitStatus === 'pending' ?
            <Fragment>
              {
                !$editEnabled ? 
                  <button className="btn btn-secondary" onClick={ () => setEditEnabled(true) }><BsPencil/></button>
                  :
                  <div className="btn-group my-1" role="group" aria-label="Edit Actions">
                    <button className="btn btn-success" onClick={ handleOnSubmit }>Save</button>
                    <button className="btn btn-danger" onClick={ () => setEditEnabled(false) }>Cancel</button>
                  </div>
              }
            </Fragment> :""
          }
        </td>
      </tr>
    </Fragment>
  );
};

const UnderwriterTables = (props) => {
  const currentLocation = useLocation();
  // console.log(currentLocation.pathname);

  return (<>
    <div
      className="buyer-table-container mt-3 mb-0"
    >
      <div className="table-inner-cont">
        <table className={`${props.className}`}>
          <thead>
            <tr>
              {props.tableHeader.map((eachHeader) => (
                <th
                  key={eachHeader.name}
                  scope="col"
                  className={`${props.tableQueryParams.tableSort === eachHeader.sortValue &&
                    eachHeader.sortValue !== "" &&
                    "active-sort-text"
                    }`}
                >
                  {eachHeader.name}
                  {eachHeader.sortValue !== "" && <BiSort />}
                </th>
              ))}
              <th>Action</th>
            </tr>
          </thead>
          {props.isLoading ? (
            <tbody>
              <tr>
                <td colSpan={11}>
                  <Loader className="my-5" />
                </td>
              </tr>
            </tbody>
          ) : props.tableRows.length === 0 ? <>
            <tbody>
              <tr>
                <td colSpan={13}>
                  <div>
                    <h4>NO DATA</h4>
                  </div>
                </td>
              </tr>
            </tbody>
          </> : (
            <tbody >
              {props.tableRows.map((eachRows, index) => <TableRow rowItem={ eachRows } fetchCreditLimitDetails={ props?.fetchCreditLimitDetails } key={ eachRows.customerId } />)}
            </tbody>
          )}
        </table>
      </div>
    </div>
    <div className="d-flex align-items-center gap-2 page-data-text-container mt-3 px-3 w-100">
      <TablePagination
        pageSize={props.tableQueryParams.tableLimit}
        // onUpdatePage={updatePage}
        current={props.tableQueryParams.currentPage}
        total={props.materialRequestMetaData?.paging?.total}
      ></TablePagination>
    </div>
  </>);
};

export default UnderwriterTables;
