/* eslint-disable jsx-a11y/anchor-is-valid */
import { Component } from "react";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import { BsThreeDots } from "react-icons/bs";

class Pagination extends Component {
  render() {
    console.log(this.props);
    return (
      <div>
        <div
          className="d-flex justify-content-center"
          style={{ width: "400px", height: "40px", display: "flex" }}
        >
          <AiOutlineDoubleLeft
            style={{ marginTop: "10px", marginRight: "10px" }}
            onClick={() => this.props.handlepage("left")}
          />
          {this.props.userDisplay.length < 5 ? (
            <>
              {this.props.userDisplay !== "" &&
                this.props.userDisplay.map((item, indx) => (
                  <a
                    onClick={() => this.props.handlepaginate(indx)}
                    style={{
                      border: "1px solid #2e3c83",
                      marginLeft: "5px",
                      padding: "7px",
                      borderRadius: "15px",
                      backgroundColor:
                        this.props.activePage === indx + 1 ? "#2e3c83" : "",
                      color:
                        this.props.activePage === indx + 1 ? "white" : "black",
                    }}
                  >
                    {indx + 1}
                  </a>
                ))}
              <AiOutlineDoubleRight
                style={{ marginTop: "10px", marginLeft: "10px" }}
                onClick={() => this.props.handlepage("right")}
              />
            </>
          ) : (
            <>
              {this.props.activePage !== 1 ? (
                <div style={{ display: "flex" }}>
                  <BsThreeDots style={{ marginTop: "10px" }} />
                  <a
                    onClick={() =>
                      this.props.handlepaginate(this.props.activePage - 1)
                    }
                    style={{
                      border: "1px solid #2e3c83",
                      marginLeft: "5px",
                      padding: "7px",
                      borderRadius: "15px",
                      backgroundColor: "",
                      color: "black",
                    }}
                  >
                    {this.props.activePage - 1}
                  </a>
                  <a
                    onClick={() =>
                      this.props.handlepaginate(this.props.activePage)
                    }
                    style={{
                      border: "1px solid #2e3c83",
                      marginLeft: "5px",
                      padding: "7px",
                      borderRadius: "15px",
                      backgroundColor: "#2e3c83",
                      color: "white",
                    }}
                  >
                    {this.props.activePage}
                  </a>
                  <a
                    onClick={() =>
                      this.props.handlepaginate(this.props.activePage + 1)
                    }
                    style={{
                      border: "1px solid #2e3c83",
                      marginLeft: "5px",
                      padding: "7px",
                      borderRadius: "15px",
                      backgroundColor: "",
                      color: "black",
                    }}
                  >
                    {this.props.activePage + 1}
                  </a>
                  <BsThreeDots style={{ marginTop: "10px" }} />
                </div>
              ) : (
                <div style={{ display: "flex" }}>
                  <a
                    onClick={() =>
                      this.props.handlepaginate(this.props.activePage)
                    }
                    style={{
                      border: "1px solid #2e3c83",
                      marginLeft: "5px",
                      padding: "7px",
                      borderRadius: "15px",
                      backgroundColor: "#2e3c83",
                      color: "white",
                    }}
                  >
                    {this.props.activePage}
                  </a>
                  <a
                    onClick={() =>
                      this.props.handlepaginate(this.props.activePage + 1)
                    }
                    style={{
                      border: "1px solid #2e3c83",
                      marginLeft: "5px",
                      padding: "7px",
                      borderRadius: "15px",
                      backgroundColor: "",
                      color: "black",
                    }}
                  >
                    {this.props.activePage + 1}
                  </a>
                  <a
                    onClick={() =>
                      this.props.handlepaginate(this.props.activePage + 2)
                    }
                    style={{
                      border: "1px solid #2e3c83",
                      marginLeft: "5px",
                      padding: "7px",
                      borderRadius: "15px",
                      backgroundColor: "",
                      color: "black",
                    }}
                  >
                    {this.props.activePage + 2}
                  </a>
                  <BsThreeDots style={{ marginTop: "10px" }} />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}
export default Pagination;
