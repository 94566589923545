import { maximumDOB, minimumDOB } from "../../../../utilities/moment";
// eslint-disable-next-line no-unused-vars
import { AnchorKYCFormTemplate } from "../anchorKycContext";
import { AnchorKycActions } from "../actionTypes";
import moment from "moment";

const actions = AnchorKycActions.PERSONAL_DETAILS;

/**
 * @param { typeof AnchorKYCFormTemplate.personalDetails } state
 * @param {{ type: AnchorKycActions['PERSONAL_DETAILS'], payload: string | BufferSource }} param1
 * @returns { typeof AnchorKYCFormTemplate.personalDetails }
 */
export function PersonalDetailsReducer(state, { type, payload }) {
  if (type === actions.CHANGE_NAME) {
    const value = payload ?? state.name.value;

    return {
      ...state,
      name: { isTouched: true, value, isValid: value.length > 3 },
    };
  } else if (type === actions.BLUR_NAME) {
    return {
      ...state,
      name: {
        ...state.name,
        isTouched: true,
        isValid: state.name.value.length > 3,
      },
    };
  } else if (type === actions.CHANGE_DOB) {
    return { ...state, DOB: { ...state.DOB, value: payload, isTouched: true } };
  } else if (type === actions.BLUR_DOB) {
    const value = payload ?? state.DOB.value;

    const isInvalid =
      !moment(value).isValid() ||
      moment(value).isBefore(moment(minimumDOB)) ||
      moment(value).isAfter(maximumDOB);

    if (isInvalid) {
      return { ...state, DOB: { isTouched: true, value: "", isValid: false } };
    } else {
      return { ...state, DOB: { value, isValid: true, isTouched: true } };
    }
  } else if (type === actions.CHANGE_PAN) {
    const value = payload ?? state.panNo.value;
    console.log(payload,"pan cahge", state.panNo.value);
    return {
      ...state,
      panNo: {
        isTouched: true,
        value: value,
        isValid: value.length === 10,
        isVerified: false,
      },
    };
  } else if (type === actions.BLUR_PAN) {
    console.log(payload,"pan blur", state.panNo.value);
    return {
      ...state,
      panNo: { ...state.panNo, isVerified: payload ?? false, isTouched: true },
    };
  } else if (type === actions.CHANGE_MOBILE) {
    return {
      ...state,
      mobileNumber: { ...state.mobileNumber, value: payload ?? state.mobileNumber.value, isTouched: true },
    };
  } else if (type === actions.BLUR_MOBILE) {
    const value = state.mobileNumber.value;

    const reg = /^(\+91)?(-)?0?[6789]\d{9}$/g;

    return {
      ...state,
      mobileNumber: {
        ...state.mobileNumber,
        isTouched: true,
        isValid: reg.test(value),
      },
    };
  } else if (type === actions.AADHAAR_UPDATE_API) {
    return {
      ...state,
      aadhaarImage: {
        ...state.aadhaarImage,
        isTouched: true,
        isVerified: state.aadhaarImage.verificationStatus !== null,
        fileList: payload ?? [],
      },
    };
  } else if (type === actions.CHANGE_AADHAAR) {
    return {
      ...state,
      aadhaarImage: {
        ...state.aadhaarImage,
        isTouched: true,
        isVerified: payload.status,
        verificationStatus: payload,
      },
    };
  } else if (type === actions.PROFILE_PIC_UPDATE_API) {
    return {
      ...state,
      profilePic: {
        fileList: payload ?? [],
        isTouched: true,
        isValid: true,
        isVerified: true,
      },
    };
  } else if (type === actions.TOUCH_ALL) {
    for (let item in state) {
      for (let obj in state[item]) {
        if (obj === "isTouched") {
          state[item][obj] = true;
        }
      }
    }
    return { ...state };
  }

  return state;
}
