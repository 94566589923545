import {
  Fragment,
  createContext,
  useReducer,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { PersonalDetailsReducer } from "./personalDetails/personalDetailsReducer";
import { CoApplicantDetailsReducer } from "./coApplicantDetails/coApplicantDetailsReducer";
import { useDispatch, } from "react-redux";
import { toastMessage } from "../../../layout/GenericLayout/ToastMessage";
import {
  getUserKYCDocument,
  updateAadharOCRDetails,
  updateDeleteFile,
  updateUploadKYCDocuments,
  updateUploadProfilePicture,
} from "../../../store/slices/generic";
import { useLocation, useNavigate } from "react-router-dom";
import { CompanyDetailsReducer } from "./storeDetails/companyDetailsReducer";
import { BankDetailsReducer } from "./bankDetails/bankDetailsReducer";
import { DocumentDetailsReducer } from "./documentDetails/documentDetailsReducer";
import { GstDetailsReducer } from "./gstDetails/gstDetailsReducer";
import { BuyerKycActions } from "./actionTypes";
import { updateBuyerKYCVerification } from "../../../store/slices/buyer";
import { rmOnboardCounterPartyDispatch, fetchFilesOfAnchorAndCounterParty, rmUploadFiles, fetchOnBoardedUsersDetails } from "../../../store/slices/rm";
import {postEquifaxscore} from "../../../store/slices/generic";

function generateTemplate(value = "") {
  return {
    value,
    isTouched: false,
    isValid: false,
  };
}

function generateTemplateForFile() {
  return {
    fileList: [],
    isTouched: false,
    isVerified: false,
  };
}

const personalDetailsTemplate = {
  name: generateTemplate(),
  DOB: generateTemplate(),
  mobileNumber: generateTemplate(),
  panNo: { ...generateTemplate(), isVerified: false },
  aadhaarImage: {
    ...generateTemplateForFile(),
    isVerified: false,
    verificationStatus: null,
  },
  profilePic: { ...generateTemplateForFile(), isVerified: false },
};

const coApplicantDetailsTemplate = {
  name: generateTemplate(),
  mobileNumber: generateTemplate(),
  panNo: { ...generateTemplate(), isVerified: false },
  aadhaarImage: {
    ...generateTemplateForFile(),
    isVerified: false,
    verificationStatus: null,
  },
};

const companyDetailsTemplate = {
  logo: { ...generateTemplateForFile(), isVerified: false },
  companyName: generateTemplate(),
  companyType: generateTemplate(),
  companyAddress: generateTemplate(),
  panNumber: { ...generateTemplate(), isVerified: false },
  city: generateTemplate(),
  state: generateTemplate(),
  country: generateTemplate(),
  pinCode: generateTemplate(),
  region: generateTemplate(),
  storeDetails: generateTemplate(),
  distributor: generateTemplate(),
};

const bankDetailsTemplate = {
  accountType: generateTemplate(),
  bankName: generateTemplate(),
  accountNumber: generateTemplate(),
  accountHolderName: generateTemplate(),
  IFSCCode: generateTemplate(),
  bankStatement: generateTemplateForFile(),
};

const gstDetailsTempalate = {
  gst: { ...generateTemplate(), isVerified: false },
};

const documentDetailsTemplate = {
  documents: [
    {
      documentName: generateTemplate(),
      bankStatement: generateTemplateForFile(),
    },
  ],
  loanAmount: generateTemplate(),
};

export const BuyerKYCFormTemplate = {
  personalDetails: personalDetailsTemplate,
  coApplicantAvailable: false,
  coApplicantDetails: coApplicantDetailsTemplate,
  companyDetails: companyDetailsTemplate,
  bankDetails: bankDetailsTemplate,
  gstDetails: gstDetailsTempalate,
  documentDetails: documentDetailsTemplate,
  personalDetailsDispatch: ({ type, payload }) => {},
  coApplicantDetailsDispatch: ({ type, payload }) => {},
  companyDetailsDispatch: ({ type, payload }) => {},
  bankDetailsDispatch: ({ type, payload }) => {},
  documentDetailsDispatch: ({ type, payload }) => {},
  gstDetailsDispatch: ({ type, payload }) => {},
  setCoApplicantAvailable: (isAvailable) => {},
  handleSubmitForm: () => {},
  files: [
    {
      email: "",
      documents: [
        {
          imageFileFormat: "image/jpeg",
          name: "",
          modifiedDate: "2023-07-12 20:23:06.476000",
          coAadharImage:
            "https://atoncap.s3.ap-south-1.amazonaws.com/monherry9+wxobz@gmail.com/coAadharImage/2023-07-12 20:23:06.374819-2023-05-15 13_01_27.395279-adhar.jpg",
        },
      ],
    },
  ],
  deleteFiles: (key, url) => {},
  emailId: "",
  uploadFiles: (formData, type) => {},
  disableEdit: false,
};

export const BuyerKycContext = createContext(BuyerKYCFormTemplate);

/**
 * @param {{ children: React.ReactNode, roles?: "buyer" }} props
 */
export const BuyerKycContextWrapper = (props) => {
  /**
   * @type { [ typeof BuyerKYCFormTemplate['personalDetails'], React.DispatchWithoutAction ] }
   */
  const [personalDetails, personalDetailsDispatch] = useReducer(
    PersonalDetailsReducer,
    BuyerKYCFormTemplate.personalDetails
  );

  const [coApplicantDetails, coApplicantDetailsDispatch] = useReducer(
    CoApplicantDetailsReducer,
    BuyerKYCFormTemplate.coApplicantDetails
  );

  const [companyDetails, companyDetailsDispatch] = useReducer(
    CompanyDetailsReducer,
    BuyerKYCFormTemplate.companyDetails
  );

  const [bankDetails, bankDetailsDispatch] = useReducer(
    BankDetailsReducer,
    BuyerKYCFormTemplate.bankDetails
  );

  const [documentDetails, documentDetailsDispatch] = useReducer(
    DocumentDetailsReducer,
    BuyerKYCFormTemplate.documentDetails
  );

  const [gstDetails, gstDetailsDispatch] = useReducer(
    GstDetailsReducer,
    BuyerKYCFormTemplate.gstDetails
  );

  const [coApplicantAvailable, setCoApplicantAvailable] = useState(true);
  const [ disableEdit, setDisableEdit ] = useState(false);

  const [files, setFiles] = useState([]);
  const [ isFetched, setIsFetched ] = useState(false);

  const dispatch = useDispatch();

  const { state } = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    if (state?.disableEdit) {
      setDisableEdit(state?.disableEdit);
    }
  }, [state]);

  const emailId = useMemo(() => {
    if (state?.emailId && typeof state?.emailId === "string") {
      return state.emailId?.toLowerCase();
    }
    else if (state?.loginData?.items[0].user.emailId) {
      return state.loginData.items[0].user.emailId;
    }
    toastMessage("session Expired", "error");

    setTimeout(() => navigate("/login", { replace: true }), 2000);
  }, [state, navigate]);

  const roles = useMemo(() => {
    if (props?.roles && typeof props?.roles === "string" && props?.roles === "buyer") {
      return [props?.roles];
    }
    else if (state?.loginData?.items[0].user.roles) {
      return state.loginData.items[0].user.roles;
    }
    toastMessage("session Expired", "error");

    setTimeout(() => navigate("/login", { replace: true }), 2000);
  }, [state, navigate, props?.roles]);

  /**
   * @param {{ name: string, dateOfBirth: string, panValidationStatus: boolean, PANNumber: string, mobileNumber: string, aadhaarValidationStatus: boolean, aadharSDK: { dateOfBirth: string, name: string, gender: "MALE" | "FEMALE" } }} payload 
   */
  const updatePersonalDetails = ({ name, PANNumber, dateOfBirth, mobileNumber, aadhaarValidationStatus,  aadharSDK, panValidationStatus }) => {

    const parsedDOB = dateOfBirth?.split("/")?.reverse()?.join("-");
    
    personalDetailsDispatch({ type: BuyerKycActions.PERSONAL_DETAILS.CHANGE_NAME, payload: name });
    personalDetailsDispatch({ type: BuyerKycActions.PERSONAL_DETAILS.CHANGE_PAN, payload: PANNumber });
    personalDetailsDispatch({ type: BuyerKycActions.PERSONAL_DETAILS.BLUR_DOB , payload: parsedDOB });
    personalDetailsDispatch({ type: BuyerKycActions.PERSONAL_DETAILS.CHANGE_MOBILE, payload: mobileNumber });
    personalDetailsDispatch({ type: BuyerKycActions.PERSONAL_DETAILS.BLUR_MOBILE });

    if (aadharSDK) {
      personalDetailsDispatch({ type: BuyerKycActions.PERSONAL_DETAILS.CHANGE_AADHAAR, payload: { status: aadhaarValidationStatus, response: aadharSDK, }, });
    }

    personalDetailsDispatch({ type: BuyerKycActions.PERSONAL_DETAILS.BLUR_PAN, payload: panValidationStatus });

  };

  /**
   * @param {{ coApplicant: { coPanValidationStatus: boolean, PANNumber: string, name: string, mobileNumber: string, coAadhaarValidationStatus: boolean, aadharSDK: { name: string, dateOfBirth: string, gender: "MALE" | "FEMALE" } }[] }} param0 
   */
  const updateCoApplicantDetails = ({ coApplicant }) => {

    coApplicantDetailsDispatch({ type: BuyerKycActions.CO_APPLICANT_DETAILS.CHANGE_NAME, payload: coApplicant?.[0]?.name });
    coApplicantDetailsDispatch({ type: BuyerKycActions.CO_APPLICANT_DETAILS.CHANGE_PAN, payload: coApplicant?.[0]?.PANNumber });
    coApplicantDetailsDispatch({ type: BuyerKycActions.CO_APPLICANT_DETAILS.CHANGE_MOBILE, payload: coApplicant?.[0]?.mobileNumber });
    coApplicantDetailsDispatch({ type: BuyerKycActions.CO_APPLICANT_DETAILS.BLUR_MOBILE });
    
    if (coApplicant?.[0]?.aadharSDK) {
      coApplicantDetailsDispatch({ type: BuyerKycActions.CO_APPLICANT_DETAILS.CHANGE_AADHAAR, payload: { status: coApplicant[0].coAadhaarValidationStatus, response: coApplicant[0].aadharSDK } });
    }

    coApplicantDetailsDispatch({ type: BuyerKycActions.CO_APPLICANT_DETAILS.BLUR_PAN, payload: coApplicant?.[0]?.coPanValidationStatus });
  };

  /**
   * @param {{
   *  companyFullName: string,
   *  companyPAN: string,
   *  companyPanValidationStatus: boolean,
   *  companyType: string,
   *  distributorCompanyName: string,
   *  companyFullAddress: string,
   *  city: string,
   *  state: string,
   *  region: string,
   *  pinCode: number,
   *  DetailAboutCompany: string,
   *  distributorId: string[],
   *  country: string,
   * }} param0 
   */
  const updateStoreDetails = ({ city, companyFullAddress, companyFullName, companyPAN, companyPanValidationStatus, companyType, detailsAboutCompany, distributorCompanyName, pinCode, region, state, country, distributorId }) => {
    companyDetailsDispatch({ type: BuyerKycActions.COMPANY_DETAILS.CHANGE_COMPANY_NAME, payload: companyFullName });
    companyDetailsDispatch({ type: BuyerKycActions.COMPANY_DETAILS.CHANGE_PAN, payload: companyPAN });
    companyDetailsDispatch({ type: BuyerKycActions.COMPANY_DETAILS.CHANGE_COMPANY_TYPE, payload: companyType });

    if (
      distributorId && distributorCompanyName && 
      distributorCompanyName !== "undefined" && 
      Array.isArray(distributorId) && distributorId?.[0] !== "undefined"
    ) {
      companyDetailsDispatch({ type: BuyerKycActions.COMPANY_DETAILS.CHANGE_DISTRIBUTOR, payload: distributorId?.[0] + "_!" + distributorCompanyName });
    }
    
    companyDetailsDispatch({ type: BuyerKycActions.COMPANY_DETAILS.CHANGE_COMPANY_ADDRESS, payload: companyFullAddress });
    companyDetailsDispatch({ type: BuyerKycActions.COMPANY_DETAILS.CHANGE_CITY, payload: city });
    companyDetailsDispatch({ type: BuyerKycActions.COMPANY_DETAILS.BLUR_CITY });
    companyDetailsDispatch({ type: BuyerKycActions.COMPANY_DETAILS.CHANGE_STATE, payload: state });
    companyDetailsDispatch({ type: BuyerKycActions.COMPANY_DETAILS.CHANGE_COUNTRY, payload: country });
    companyDetailsDispatch({ type: BuyerKycActions.COMPANY_DETAILS.CHANGE_PIN, payload: pinCode?.toString() });
    companyDetailsDispatch({ type: BuyerKycActions.COMPANY_DETAILS.CHANGE_REGION, payload: region });
    companyDetailsDispatch({ type: BuyerKycActions.COMPANY_DETAILS.CHANGE_STORE_DETAILS, payload: detailsAboutCompany });

    companyDetailsDispatch({ type: BuyerKycActions.COMPANY_DETAILS.BLUR_PAN, payload: companyPanValidationStatus });
  };

  /**
   * @param {{ gstValidationStatus: boolean, GSTNumber: string }} param0 
   */
  const updateGstDetails = ({ GSTNumber, gstValidationStatus }) => {
    gstDetailsDispatch({ type: BuyerKycActions.GST_DETAILS.CHANGE_GST, payload: GSTNumber });

    gstDetailsDispatch({ type: BuyerKycActions.GST_DETAILS.BLUR_GST, payload: gstValidationStatus });
  };

  /**
   * @param {{
   *  accountNumber: string,
   *  accountHolderName: string,
   *  bankName: string,
   *  accountType: string,
   *  IFSCCode: string,
   * }} param0 
   */
  const updateBankDetails = ({ IFSCCode, accountHolderName, accountNumber, accountType, bankName }) => {
    bankDetailsDispatch({ type: BuyerKycActions.BANK_DETAILS.CHANGE_ACC_NO, payload: accountNumber });
    bankDetailsDispatch({ type: BuyerKycActions.BANK_DETAILS.BLUR_ACC_NO });
    bankDetailsDispatch({ type: BuyerKycActions.BANK_DETAILS.CHANGE_ACC_HOLDER_NAME, payload: accountHolderName });
    bankDetailsDispatch({ type: BuyerKycActions.BANK_DETAILS.BLUR_ACC_HOLDER_NAME });
    bankDetailsDispatch({ type: BuyerKycActions.BANK_DETAILS.CHANGE_BANK_NAME, payload: bankName });
    bankDetailsDispatch({ type: BuyerKycActions.BANK_DETAILS.CHANGE_ACC_TYPE, payload: accountType });
    bankDetailsDispatch({ type: BuyerKycActions.BANK_DETAILS.CHANGE_IFSC_CODE, payload: IFSCCode });
    bankDetailsDispatch({ type: BuyerKycActions.BANK_DETAILS.BLUR_IFSC_CODE });
  };

  /**
   * @param {{ loanRequestAmount: number }} param0 
   */
  const updateDocumentDetails = ({ loanRequestAmount }) => {
    documentDetailsDispatch({ type: BuyerKycActions.DOCUMENT_DETAIL.CHANGE_LOAN, payload: loanRequestAmount?.toString() });
  };

  const fetchDocuments = useCallback(() => {
    if (window.location.pathname.includes("/rm")) {
      dispatch(fetchFilesOfAnchorAndCounterParty(emailId))
        .unwrap()
        .then(response => {
          setFiles(response?.items);
        })
        .catch(error => {
          console.error("error while fetching files", error?.message);
        });

        dispatch(fetchOnBoardedUsersDetails(emailId))
        .unwrap()
        .then(data => {
          if (data?.success && !isFetched) {
            updateDocumentDetails(data?.success);
            updateGstDetails(data?.success);
            updateStoreDetails(data?.success);
            updateBankDetails(data?.success);
            updatePersonalDetails(data?.success);
            updateCoApplicantDetails(data?.success);
          }
        })
        .catch(err => {
          if (disableEdit) {
            toastMessage("Unable to fetch KYC details", "error");
          }
          console.log("error", err?.message);
        });
      
      setIsFetched(true);
      return;
    }
    
    dispatch(getUserKYCDocument())
      .unwrap()
      .then((response) => {
        setFiles(response?.items);
        
        if (response?.items?.length > 0 && !isFetched) {
          updateStoreDetails(response?.items?.[response?.items?.length - 1]);
          updateDocumentDetails(response?.items?.[response?.items?.length - 1]);
          updateGstDetails(response?.items?.[response?.items?.length - 1]);
          updateBankDetails(response?.items?.[response?.items?.length - 1]);
          updatePersonalDetails(response?.items?.[response?.items?.length - 1]);
          updateCoApplicantDetails(response?.items?.[response?.items?.length - 1]);
        }
        setIsFetched(true);
      })
      .catch((error) => {
        toastMessage(error?.message ?? "Unknown Error Occurred", "error");
        setIsFetched(true);
      });
  }, [dispatch, isFetched]);

  useEffect(() => {
    fetchDocuments();
  }, [fetchDocuments]);

  const handleSubmitForm = () => {
    const states = [
      personalDetails,
      coApplicantDetails,
      companyDetails,
      bankDetails,
      documentDetails,
      gstDetails,
    ];
    
    //! TRIGGER FOCUS EVENT ON THE FIELDS WHICH ARE NOT FILLED
    personalDetailsDispatch({ type: "TOUCH_ALL" });
    coApplicantDetailsDispatch({ type: "TOUCH_ALL" });
    companyDetailsDispatch({ type: "TOUCH_ALL" });
    bankDetailsDispatch({ type: "TOUCH_ALL" });
    documentDetailsDispatch({ type: "TOUCH_ALL" });
    gstDetailsDispatch({ type: "TOUCH_ALL" });

    if (window.location.pathname !== "/rm/rm-onboarding/counter-party") {
      for (let state of states) {
        for (let obj in state) {
          for (let item in state[obj]) {
            if (
              (item === "isVerified" || item === "isValid") &&
              state[obj][item] === false
            ) {
              return;
            }
          }
        }
      }
    }

    if (personalDetails.aadhaarImage.verificationStatus === null) {
      toastMessage("Upload Aadhaar In Personal Details", "error");
      return;
    } else if (
      coApplicantAvailable &&
      coApplicantDetails.aadhaarImage.verificationStatus === null
    ) {
      toastMessage("Upload Aadhaar In Co-Applicant Details", "error");
      return;
    }

    const data = {
      name: personalDetails.name.value,
      dateOfBirth: personalDetails.DOB.value.split("-").reverse().join("/"),
      emailId,
      mobileNumber: personalDetails.mobileNumber.value,
      panValidationStatus: personalDetails.panNo.isVerified, //
      PANNumber: personalDetails.panNo.value.toUpperCase(),
      profilePicture:
        personalDetails.profilePic.fileList?.[0]?.userProfilePic ?? null, // url
      aadhaarValidationStatus: personalDetails.aadhaarImage.isVerified,
      aadharSDK: {
        dateOfBirth:
          personalDetails.aadhaarImage.verificationStatus?.response?.dob ??
          null,
        name:
          personalDetails.aadhaarImage.verificationStatus?.response?.name ??
          null,
        gender:
          personalDetails.aadhaarImage.verificationStatus?.response?.gender ??
          null,
      },
      coApplicant: [
        {
          coPanValidationStatus: coApplicantDetails.panNo.isVerified,
          PANNumber: coApplicantDetails.panNo.value ?? null,
          name: coApplicantDetails.name.value ?? null,
          mobileNumber: coApplicantDetails.mobileNumber.value ?? null,
          coAadhaarValidationStatus: coApplicantDetails.aadhaarImage.isVerified,
          aadharSDK: {
            dateOfBirth:
              coApplicantDetails.aadhaarImage.verificationStatus?.response
                ?.dob ?? null,
            name:
              coApplicantDetails.aadhaarImage.verificationStatus?.response
                ?.name ?? null,
            gender:
              coApplicantDetails.aadhaarImage.verificationStatus?.response
                ?.gender ?? null,
          },
        },
      ],

      companyLogo: companyDetails.logo.fileList?.[0]?.profilePicture ?? null,
      companyFullName: companyDetails.companyName.value,
      companyType: companyDetails.companyType.value,
      companyEmail: emailId,
      companyPanValidationStatus: companyDetails.panNumber.isVerified,
      companyPAN: companyDetails.panNumber.value,
      companyFullAddress: companyDetails.companyAddress.value,
      city: companyDetails.city.value,
      state: companyDetails.state.value,
      pinCode: +companyDetails.pinCode.value,
      country: companyDetails.country.value,
      region: companyDetails.region.value,
      detailsAboutCompany: companyDetails.storeDetails.value,
      distributorCompanyName: companyDetails.distributor.value.split("_!")?.[1] ?? "",
      distributorId: [companyDetails.distributor.value.split("_!")?.[0] ?? ""],

      accountNumber: bankDetails.accountNumber.value,
      accountHolderName: bankDetails.accountHolderName.value,
      bankName: bankDetails.bankName.value,
      accountType: bankDetails.accountType.value,
      IFSCCode: bankDetails.IFSCCode.value.toUpperCase(),
      bankstatement:
        bankDetails.bankStatement.fileList?.[0]?.bankstatement ?? null,
      roles,
      isPrimary: true,
      manufacturer: [],
      gstValidationStatus: gstDetails.gst.isVerified,
      GSTNumber: gstDetails.gst.value.toUpperCase(),
      loanRequestAmount: +documentDetails.loanAmount.value,
      otherDetails: [
        {
          documentName: null,
          document: null,
        },
      ],
    };

    if (window.location.pathname === "/rm/rm-onboarding/counter-party") {
      data.createdBy = sessionStorage.getItem("email");

      dispatch(rmOnboardCounterPartyDispatch(data))
        .unwrap()
        .then(dat => {
          toastMessage(
            dat?.success ?? "KYC Uploaded Successfully",
            "success"
          );
          if(dat?.code==200){
            dispatch(postEquifaxscore({emailId:data?.emailId, role:"buyer"}));
          }

          setTimeout(() => {
            navigate("/rm/customer-onboarding");
          }, 3000);
        })
        .catch(err => {
          toastMessage(err.message ?? "Unable to Upload KYC", "error");
        });
      
        return;
    }
    
    dispatch(updateBuyerKYCVerification(data))
      .unwrap()
      .then((response) => {
        toastMessage(
          response?.success ?? "KYC Uploaded Successfully",
          "success"
        );
          if(response?.code==200){
            dispatch(postEquifaxscore({emailId:data?.emailId, role:"buyer"}));
          }
        setTimeout(() => window.location.pathname = "/login", 3200);
      })
      .catch((error) => {
        console.log("error", error);
        toastMessage(error?.message ?? "Unable to Upload KYC", "error");
      });
  };

  const deleteFiles = (key, url) => {
    const body = { email_id: emailId, image_url: url, key };

    dispatch(updateDeleteFile(body))
      .unwrap()
      .then((response) => {
        toastMessage("Document deleted Successfully", "success");
        fetchDocuments();
      })
      .catch((error) => {
        toastMessage(error?.message ?? "Unable to delete file", "error");
      });
  };

  const uploadFiles = (formData, type) => {
    formData.append("emailId", emailId);
    formData.append("roles", roles);

    if (window.location.pathname.includes("rm")) {
      if (type === "adharImage" || type === "coAadharImage") {
        dispatch(updateAadharOCRDetails(formData))
          .unwrap()
          .then((validationResponse) => {
            if (validationResponse.status) {
              toastMessage(validationResponse?.message ?? "Verification Successfully for Aadhar", "success");
              if (type === "adharImage") {
                personalDetailsDispatch({
                  type: BuyerKycActions.PERSONAL_DETAILS.CHANGE_AADHAAR,
                  payload: {
                    status: validationResponse.status,
                    response: validationResponse?.data?.result?.[0]?.details,
                  },
                });
              } else {
                coApplicantDetailsDispatch({
                  type: BuyerKycActions.PERSONAL_DETAILS.CHANGE_AADHAAR,
                  payload: {
                    status: validationResponse.status,
                    response: validationResponse?.data?.result?.[0]?.details,
                  },
                });
              }
  
              dispatch(rmUploadFiles(formData))
                .unwrap()
                .then((response) => {
                  fetchDocuments();
                })
                .catch((error) => {
                  console.log("error while uploading Aadhaar", error);
                });
            }
          })
          .catch((error) => {
            toastMessage(
              error?.message ?? error?.error ?? "Unable to verify Aadhaar",
              "error"
            );
          });
        } else {
          dispatch(rmUploadFiles(formData))
            .unwrap()
            .then(data => {
              console.log("uploaded file in rm");
              fetchDocuments();
            })
            .catch(err => {
              console.error("error while uploading", err);
            });
      }
      return;
    }

    if (type === "userProfilePic") {
      dispatch(updateUploadProfilePicture(formData))
        .unwrap()
        .then((response) => {
          fetchDocuments();
        })
        .catch((error) => {
          toastMessage(
            error?.message ?? error?.error ?? "Unable to Upload Profile Pic",
            "error"
          );
        });

      return;
    } else if (type === "adharImage" || type === "coAadharImage") {
      dispatch(updateAadharOCRDetails(formData))
        .unwrap()
        .then((validationResponse) => {
          if (validationResponse.status) {
            if (type === "adharImage") {
              personalDetailsDispatch({
                type: BuyerKycActions.PERSONAL_DETAILS.CHANGE_AADHAAR,
                payload: {
                  status: validationResponse.status,
                  response: validationResponse?.data?.result?.[0]?.details,
                },
              });
            } else {
              coApplicantDetailsDispatch({
                type: BuyerKycActions.PERSONAL_DETAILS.CHANGE_AADHAAR,
                payload: {
                  status: validationResponse.status,
                  response: validationResponse?.data?.result?.[0]?.details,
                },
              });
            }

            dispatch(updateUploadKYCDocuments(formData))
              .unwrap()
              .then((response) => {
                fetchDocuments();
              })
              .catch((error) => {
                console.log("error while uploading Aadhaar", error);
              });
          }
        })
        .catch((error) => {
          toastMessage(
            error?.message ?? error?.error ?? "Unable to verify Aadhaar",
            "error"
          );
        });
      return;
    }

    dispatch(updateUploadKYCDocuments(formData))
      .unwrap()
      .then((response) => {
        fetchDocuments();
      })
      .catch((error) => {
        toastMessage("Unable to delete File", "error");
      });
  };

  const value = {
    personalDetails,
    personalDetailsDispatch,
    coApplicantDetails,
    coApplicantDetailsDispatch,
    companyDetails,
    companyDetailsDispatch,
    bankDetails,
    bankDetailsDispatch,
    documentDetails,
    documentDetailsDispatch,
    gstDetails,
    gstDetailsDispatch,
    coApplicantAvailable,
    setCoApplicantAvailable,
    files,
    deleteFiles,
    emailId,
    uploadFiles,
    handleSubmitForm, disableEdit,
  };

  return (
    <Fragment>
      <BuyerKycContext.Provider value={value}>
        {props?.children ?? null}
      </BuyerKycContext.Provider>
    </Fragment>
  );
};
