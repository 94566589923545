import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Legend,
  LabelList,
  Label,
} from "recharts";

const ScoreInfo = ({ scoreCardDetails }) => {
  const data = [
    {
      name: "Equifax Score",
      value: parseInt(scoreCardDetails[0]?.equifax_score),
    },
    {
      name: "Remaining",
      value: 1000 - parseInt(scoreCardDetails[0]?.equifax_score),
    },
  ];

  const COLORS = ["#0088FE", "#BFBFBF"];

  return (
    <div style={{ width: 400, height: 300 }}>
      <ResponsiveContainer>
        <PieChart width="100%" height="100%">
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            innerRadius={70}
            outerRadius={100}
            fill="#8884d8"
            paddingAngle={5}
            dataKey="value"
            // label
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
            <Label
              content={
                <g>
                  <text
                    x="50%"
                    y="45%"
                    className="recharts-text recharts-label"
                    textAnchor="middle"
                    dominantBaseline="central"
                    alignmentBaseline="middle"
                    fill="#0088FE"
                    fontSize="60"
                    fontWeight="600"
                  >
                    {scoreCardDetails[0]?.equifax_score}
                  </text>
                </g>
              }
              position="center"
            />
          </Pie>
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ScoreInfo;
