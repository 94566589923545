import React, { useState } from "react";
import BannerImg from "../assests/images/rodion-kutsaiev-JKGjV3E-HnY-unsplash@2x.png";
import atonLogo from "../assests/images/Aton-logo.png";
import { useNavigate } from "react-router-dom";

const MobileVerification = () => {
  const navigate = useNavigate();
  const [mobileNumber, setMobileNumber] = useState("");

  const onChangeMobileNumber = (event) => {
    setMobileNumber(event.target.value.replace(/[^0-9]/g, ""));
  };

  const onSubmitGetOtp = (event) => {
    event.preventDefault();
    navigate("/otp-verification", { replace: true, state: { mobileNumber } });
  };

  const onClickBack = () => {
    navigate("/sign-up", { replace: true });
  };

  return (
    <>
      <form
        onSubmitCapture={onSubmitGetOtp}
        style={{ position: "relative", width: "100%", height: "100%" }}
      >
        <div className="row m-0 p-0">
          <div className="col-md-2 d-flex jusitfy-content-center ">
            <span
              className="mt-4 mx-4 fw-semibold back-text"
              onClick={onClickBack}
            >
              Back
            </span>
          </div>
          <div className="col-md-8 mt-5">
            <div className="row d-flex align-items-center">
              <div className="col-md-8">
                <span className="txt-welc">OTP Verification</span> <br />
                <span className="txt-yr-acc">
                  We will send you one time password to this mobile number.
                </span>
              </div>
              <div className="col-md-4 d-flex justify-content-end">
                <img src={atonLogo} style={{ width: "80px" }} />
              </div>
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>

        <div className="row mt-3">
          <div className="col-md-2"></div>
          <div className="col-md-8 mt-5">
            <div className="row px-3 mb-3">
              <label className="label-text">Mobile Number</label>
              <div className="input-container form-control d-flex align-items-center">
                <input
                  onChange={onChangeMobileNumber}
                  value={mobileNumber}
                  className="input-element d-flex flex-grow-1"
                  style={{ borderRadius: "10px" }}
                  placeholder="Enter Mobile Number"
                  name="mobile"
                  type="text"
                  required
                />
              </div>
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>

        <button type="submit" className="sign-in-btn">
          Get OTP
        </button>
      </form>
    </>
  );
};

export default MobileVerification;
