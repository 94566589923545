import { nanoid } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMyProfile, updateResetPassowrd } from "../../store/slices/generic";
import { toastMessage } from "../../layout/GenericLayout/ToastMessage";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const BuyerMyProfile = ({ labelText, valueText, uniqueId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const myprofileData = useSelector((state) => state.generic.myprofileData);
  const resetPasswordData = useSelector(
    (state) => state.generic.resetPasswordData
  );
  const [companyInfo, setCompanyInfo] = useState([
    {
      name: "Company Full Name",
      id: "companyFullName",
    },

    {
      name: "Company Type",
      id: "companyType",
    },

    {
      name: "Website Address",
      id: "websiteAddress",
    },

    {
      name: "PAN Number",
      id: "PANNumber",
    },

    {
      name: "GST Number",
      id: "GSTNumber",
    },

    {
      name: "Company Full Address",
      id: "companyFullAddress",
    },

    {
      name: "City",
      id: "city",
    },

    {
      name: "State",
      id: "state",
    },

    {
      name: "Pincode",
      id: "pinCode",
    },

    {
      name: "Country",
      id: "country",
    },

    {
      name: "Email",
      id: "emailId",
    },

    {
      name: "Phone Number",
      id: "phoneNumber",
    },

    {
      name: "Mobile Number",
      id: "mobileNumber",
    },
  ]);

  const [bankDetails, setBankDetails] = useState([
    {
      name: "Account Number",
      id: "accountNumber",
    },

    {
      name: "Account Holder's Name",
      id: "accountHolderName",
    },

    {
      name: "Bank Name",
      id: "bankName",
    },

    {
      name: "IFSC Code",
      id: "IFSCCode",
    },
  ]);

  const [changePassword, setChangePassword] = useState({
    currentPassword: "",
    newPassword: "",
  });

  useEffect(() => {
    dispatch(getMyProfile())
      .unwrap()
      .then((response) => {
        console.log("response : ", response);
        const newCompanyInfo = [];
        companyInfo.map((eachData) =>
          newCompanyInfo.push({
            ...eachData,
            value: response.success[eachData.id],
          })
        );

        const newBankDetails = [];
        bankDetails.map((eachData) =>
          newBankDetails.push({
            ...eachData,
            value: response.success[eachData.id],
          })
        );

        setBankDetails(newBankDetails);
        setCompanyInfo(newCompanyInfo);
      })
      .catch((error) => {
        console.log("error : ", error);
        toastMessage(error.message, "error");
      });
  }, [dispatch]);

  const CompnayInformation = [
    {
      value: "",
      labelText: "Company Full Name",
      id: nanoid(),
    },
  ];

  const onChangeChangePassword = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setChangePassword((prev) => ({ ...prev, [name]: value }));
  };

  const onClickUpdatePassword = () => {
    const body = {
      emailId: sessionStorage.getItem("email"),
      newPassword: changePassword.newPassword,
      oldPassword: changePassword.currentPassword,
    };

    console.log("Update is Clicked ^^^^^^^^^^^^^^");

    dispatch(updateResetPassowrd(body))
      .unwrap()
      .then((response) => {
        console.log("#$$$$$$$$$$$$$$$$$$$$$", response);
        toastMessage(response.success, "success");
        setChangePassword({ currentPassword: "", newPassword: "" });
      })
      .catch((error) => {
        console.log("@@@@@@@@@@@@@@@@@@################", error);

        toastMessage(error.message, "error");
      });
  };
  const InputFieldLayout = ({ uniqueId, labelText, valueText }) => (
    <div className="kyc-fields-container col-12  col-md-4">
      <label htmlFor={uniqueId} className="kyc-label-text form-label ">
        {labelText}
      </label>
      <input
        id={uniqueId}
        disabled
        type="text"
        className="kyc-input-field form-control"
        value={valueText}
      />
    </div>
  );

  const onClickBack = () => {
    navigate(-1);
  };

  return (
    <div className="bg-white admin-dashboard-card-container p-3 mb-3">
      <Helmet>
        <title>My-Profile</title>
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6">
            <span className="dashboard-content-title">User Profile</span>
          </div>

          <div className="col-12 col-md-6 d-flex justify-content-end gap-2 align-items-center">
            <span className="dashboard-content-title">
              Application Status :{" "}
            </span>
            <span className="dashboard-content-title text-uppercase text-primary">
              {myprofileData?.data?.success?.status}
            </span>
          </div>
        </div>
      </div>

      <div className="d-flex flex-column align-items-center my-4 details-page-container">
        <div className="d-flex flex-column details-header">
          Company Information
        </div>

        <div className="container p-3">
          <div className="row gy-3">
            {companyInfo.map((eachData) => (
              <InputFieldLayout
                key={eachData.id}
                uniqueId={eachData.id}
                labelText={eachData.name}
                valueText={eachData.value}
              />
            ))}
          </div>
        </div>
      </div>

      <div className="d-flex flex-column align-items-center my-4 details-page-container">
        <div className="d-flex flex-column details-header">Bank Details</div>

        <div className="container p-3">
          <div className="row gy-3">
            {bankDetails.map((eachData) => (
              <InputFieldLayout
                key={eachData.id}
                uniqueId={eachData.id}
                labelText={eachData.name}
                valueText={eachData.value}
              />
            ))}
          </div>
        </div>
      </div>

      <div className="d-flex flex-column align-items-center my-4 details-page-container">
        <div className="d-flex flex-column details-header">Change Password</div>

        <div className="container p-3">
          <div className="row gy-3 d-flex align-items-end">
            <div className="kyc-fields-container col-12  col-md-4">
              <label
                htmlFor="currentPassword"
                className="kyc-label-text form-label "
              >
                Current Password
              </label>
              <input
                onChange={onChangeChangePassword}
                id="currentPassword"
                name="currentPassword"
                type="text"
                className="kyc-input-field form-control"
                value={changePassword.currentPassword}
              />
            </div>

            <div className="kyc-fields-container col-12  col-md-4">
              <label
                htmlFor="newPassword"
                className="kyc-label-text form-label "
              >
                New Password
              </label>
              <input
                onChange={onChangeChangePassword}
                id="newPassword"
                name="newPassword"
                type="text"
                className="kyc-input-field form-control"
                value={changePassword.newPassword}
              />
            </div>

            <div className="kyc-fields-container col-12 col-md-4 text-center">
              <button
                onClick={onClickUpdatePassword}
                className="btn btn-primary px-5"
              >
                {resetPasswordData.isLoading ? (
                  <span className="loader mx-auto"></span>
                ) : (
                  "Update"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-end">
        <button onClick={onClickBack} className="btn btn-warning px-5">
          Back
        </button>
      </div>
    </div>
  );
};

export default BuyerMyProfile;
