import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getDigitalPurchaseOrderById,
  getProformaInvoiceById,
} from "../../../../store/slices/anchor";
import { updateGenerateDigitalPurchaseOrder } from "../../../../store/slices/buyer";
import { toastMessage } from "../../../../layout/GenericLayout/ToastMessage";

const DigitalPurchaseOrderDetailsById = () => {
  /* FUNCTION DECLARATIONS */
  const { buyerId, proformaId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  /* USESELECTOR */
  const proformaInvoiceByIdData = useSelector(
    (state) => state.anchor.proformaInvoiceByIdData
  );
  /* USESTATES */
  const [tableRows, setTableRows] = useState([]);
  const [inputValues, setInputValues] = useState({});
  const [shippingAddress, setShippingAddress] = useState("");

  const [ shippingAddressSimilarToBillingAddress, setShippingAddressSimilarToBillingAddress ] = useState(false);

  /* USEEFFECTS */
  useEffect(() => {
    dispatch(getDigitalPurchaseOrderById({ buyerId, proformaId }))
      .unwrap()
      .then((response) => {
        const sellerId = response.items[0].sellerId;
        const proformaId = response.items[0].proformaId;
        dispatch(getProformaInvoiceById({ sellerId, proformaId }))
          .unwrap()
          .then((response) => {
            console.log("res : ", response);
          });
        setTableRows(response.items[0].itemDetails);
        setInputValues((prev) => ({ ...prev, ...response.items[0] }));
      });
  }, [dispatch]);

  const onClickBack = () => {
    navigate(-1, { replace: true });
  };

  const handleSetShippingAddressSimilarToBillingAddress = () => {
    setShippingAddressSimilarToBillingAddress(prev => {
      if (prev === true) {
        setShippingAddress("");
      } else {
        setShippingAddress(inputValues?.sellerAddress);
      }

      return !prev;
    });
  };

  const onClickGenerateInvoice = () => {
    const body = {
      buyerId,
      gst: tableRows[0].gst,
      invoiceToAddress: inputValues.sellerAddress,
      poId: proformaId,
      shippingToAddress: shippingAddress,
      tanNumber: "",
    };

    console.log("body : ", body);
    dispatch(updateGenerateDigitalPurchaseOrder(body))
      .unwrap()
      .then((response) => {
        console.log("response : ", response);
        toastMessage(response.success, "success");
        onClickBack();
      })
      .catch((error) => {
        console.log("error : ", error);
        toastMessage(error.message, "error");
      });
  };

  const onChangeShippingAddress = (event) => {
    setShippingAddress(event.target.value);
  };

  /* OPTIONS */
  const totalAmount =
    tableRows.length === 0
      ? 0
      : tableRows.reduce(
        (accumulator, currentValue) => accumulator + currentValue.totalAmount,
        0
      );

  return (
    <div className="bg-white admin-dashboard-card-container p-3">
      <div className="d-flex justify-content-between align-items-center flex-wrap px-2 gap-2 py-2">
        <span className="dashboard-content-title">
          Proforma Invoice Details
        </span>
        <button className="modal-export-pdf-button d-flex align-items-center gap-2">
          Export to PDF
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 25"
          >
            <path
              id="Path_541"
              data-name="Path 541"
              d="M207.639,120.656l3.949-4.032a1.18,1.18,0,0,0-1.667-1.671l-1.352,1.361c-.308.31-.557.208-.557-.227V102.8a1.178,1.178,0,1,0-2.356,0v13.291c0,.434-.249.537-.557.227l-1.352-1.361a1.18,1.18,0,0,0-1.667,1.671l3.908,4.026a1.145,1.145,0,0,0,1.651.005Zm-8.464-6.96a1.379,1.379,0,0,0,1.95-1.95,8.074,8.074,0,1,1,11.419,0,1.379,1.379,0,0,0,1.95,1.95,10.832,10.832,0,1,0-15.318,0Z"
              transform="translate(-196.003 -95.205)"
              fill="#fff"
              fillRule="evenodd"
            />
          </svg>
        </button>
      </div>

      <div className="d-flex flex-column align-items-center my-4 details-page-container">
        <div className="d-flex flex-column details-header">
          Items Information
        </div>
        <div className="p-3 w-100">
          <div className="details-page-table-container">
            <table className="details-page-table">
              <thead>
                <tr>
                  <th scope="col">Item</th>
                  <th scope="col">Model Number</th>
                  <th scope="col">Quantity Ordered</th>
                  <th scope="col">Available Quantity</th>
                  <th scope="col">Price/Unit (₹)</th>
                  <th scope="col">GST (%)</th>
                  <th scope="col">Total Amount</th>
                </tr>
              </thead>

              <tbody>
                {tableRows.map((eachRows, index) => (
                  <tr key={eachRows.id} scope="col">
                    <td>{eachRows.item}</td>
                    <td>{eachRows.modelNumber}</td>
                    <td>{eachRows.quantityOrdered}</td>
                    <td>{eachRows.quantityAvailable}</td>
                    <td>{eachRows.unitPrice}</td>
                    <td>{eachRows.gst}</td>
                    <td>{eachRows.totalAmount}</td>
                  </tr>
                ))}
                <tr className="total-table-text">
                  <td className="fw-bold" colSpan={6}>
                    TOTAL
                  </td>
                  <td className="text-center fw-bold">{totalAmount}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="d-flex flex-column align-items-center my-4 details-page-container">
        <div className="d-flex flex-column details-header">Invoice Address</div>
        <div className="details-container container p-3">
          <div className="row gy-md-4 gy-2">
            <div className="kyc-fields-container col-12">
              <label
                htmlFor="billingAddress"
                className="kyc-label-text form-label"
              >
                Billing Address
              </label>
              <input
                id="billingAddress"
                disabled
                type="text"
                className="kyc-input-field form-control"
                value={inputValues.sellerAddress}
              />
            </div>
            <div className="col-12 form-check">
              <input type="checkbox" className="form-check-input" onClick={handleSetShippingAddressSimilarToBillingAddress} checked={shippingAddressSimilarToBillingAddress} />
              <label className="form-form-check-label">Shipping Address is same as billing address</label>
            </div>
            {
              !shippingAddressSimilarToBillingAddress &&
                <div className="kyc-fields-container col-12">
                  <label
                    htmlFor="shippingAddress"
                    className="kyc-label-text form-label"
                  >
                    Shipping Address
                  </label>
                  <input
                    onChange={onChangeShippingAddress}
                    id="shippingAddress"
                    type="text"
                    className="kyc-input-field form-control shadow"
                  // value={inputValues.shippingTerms}
                  />
                </div>
            }
          </div>
        </div>
      </div>

      <div className="d-flex flex-column align-items-center my-4 details-page-container">
        <div className="d-flex flex-column details-header">
          Transport Details
        </div>
        <div className="details-container container p-3">
          <div className="row gy-md-4 gy-2">
            <div className="kyc-fields-container col-12  col-md-4">
              <label htmlFor="name" className="kyc-label-text form-label">
                Shipment Terms
              </label>
              <input
                disabled
                type="text"
                className="kyc-input-field form-control"
                value={inputValues.shippingTerms}
              />
            </div>

            <div className="kyc-fields-container col-12  col-md-4">
              <label htmlFor="name" className="kyc-label-text form-label">
                Mode of Transport
              </label>
              <input
                disabled
                type="text"
                className="kyc-input-field form-control"
                value={inputValues.modeOfTransport}
              />
            </div>

            <div className="kyc-fields-container col-12  col-md-4">
              <label htmlFor="name" className="kyc-label-text form-label">
                Expected Date of delivery
              </label>
              <input
                disabled
                type="text"
                className="kyc-input-field form-control"
                value={inputValues.expectedDateOfDelivery}
              />
            </div>

            <div className="kyc-fields-container col-12  col-md-4">
              <label htmlFor="name" className="kyc-label-text form-label">
                Delivery Location
              </label>
              <input
                disabled
                type="text"
                className="kyc-input-field form-control"
                value={inputValues.locationOfDelivery}
              />
            </div>

            <div className="kyc-fields-container col-12  col-md-4">
              <label htmlFor="name" className="kyc-label-text form-label">
                Payment Terms
              </label>
              <input
                disabled
                type="text"
                className="kyc-input-field form-control"
                value={inputValues.paymentTerms}
              />
            </div>

            {/* <div className="kyc-fields-container col-12  col-md-4">
              <label htmlFor="name" className="kyc-label-text form-label">
              Additional Text
              </label>
              <input
                disabled
                type="text"
                className="kyc-input-field form-control"
                value={inputValues.shippingTerms}
              />
            </div> */}
          </div>
        </div>
      </div>

      <div className="d-flex flex-column flex-md-row align-items-center justify-content-end gap-3 mx-5 my-3">
        <button className="kyc-back-button" onClick={onClickBack}>
          Back
        </button>

        <button
          className="kyc-back-button btn btn-primary w-25"
          onClick={onClickGenerateInvoice}
        >
          Generate Invoice
        </button>
      </div>
    </div>
  );
};

export default DigitalPurchaseOrderDetailsById;
