import { useState } from "react";
import { BiSort } from "react-icons/bi";
import { HiOutlineArrowNarrowDown } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import Loader from "../../../layout/GenericLayout/Loader";
import TablePagination from "../../../layout/GenericLayout/TablePagination";
import { toastMessage } from "../../../layout/GenericLayout/ToastMessage";
import { AUDIT_ACTIONS } from "../audit_trail/audit_actions";

/* PARENT COMPONENT */
export const AdminAuditTrailTable = (props) => {
  const navigate = useNavigate();
  const [, setShowModal] = useState(false);
  const [, setActiveItemsDetails] = useState([]);

  const updatePage = (currentPage) => {
    const newOffSet = props.tableQueryParams.tableLimit * (currentPage - 1);
    props.setTableQueryParams((prev) => ({
      ...prev,
      tableOffset: newOffSet,
      currentPage,
    }));
  };

  const onClickActiveSort = (activeSortValue) => {
    if (activeSortValue !== "") {
      let order = -1;
      if (activeSortValue === props.tableQueryParams.tableSort) {
        if (props.tableQueryParams.tableOrder === -1) {
          order = 1;
        }
      }
      props.setTableQueryParams((prev) => ({
        ...prev,
        tableSort: activeSortValue,
        tableOrder: order,
      }));
    }
  };

  const onClickViewMoreTableData = () => {
    let newLimit = props.tableQueryParams.tableLimit;
    if (newLimit === 5) {
      newLimit += 5;
    } else {
      newLimit -= 5;
    }
    props.setTableQueryParams((prev) => ({
      ...prev,
      tableLimit: newLimit,
    }));
  };

  const toggleModal = () => {
    setShowModal((prev) => !prev);
  };

  const onclickItemsCount = (data) => {
    setActiveItemsDetails(data);
    toggleModal();
  };

  const onClickMetarialId = ({ buyerId, mrId, status }) => {
    // console.log(buyerId, mrId);
    if (status?.toLowerCase() === "new") {
      navigate(`${buyerId}/${mrId}`);
      // toastMessage("Navigating", "success");
    } else {
      toastMessage("Already Raised", "error");
    }
    // console.log("------------------------------>>>>>",status.toLowerCase())
    // navigate(`${buyerId}/${mrId}`);
  };

  return (
    <>
      <div className="table-container">
        <table className={props.className}>
          <thead>
            <tr>
              {props.tableHeader.map((eachHeader) => (
                <th
                  key={eachHeader.name}
                  scope="col"
                  role={
                    eachHeader.sortValue === ""
                      ? eachHeader.sortValue
                      : "button"
                  }
                  className={`${
                    props.tableQueryParams.tableSort === eachHeader.sortValue &&
                    eachHeader.sortValue !== "" &&
                    "active-sort-text"
                  }`}
                  onClick={() => onClickActiveSort(eachHeader.sortValue)}
                >
                  {eachHeader.name}
                  {eachHeader.sortValue !== "" && <BiSort />}
                </th>
              ))}
            </tr>
          </thead>
          {props.auditTrailMetaData?.paging?.total<0?(<div className="no-data text-danger">Oops! there is no data to show. Please check back later</div>):null} 
          {props.isLoading ? (
            <tbody>
              <tr>
                <td colSpan={6}>
                  <Loader className="my-5" />
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              {props.tableRows?.map((eachRows, index) => (
                <tr key={eachRows.mrId} scope="col">
                  <td>{eachRows.action}</td>
                  {/* <td>{AUDIT_ACTIONS[eachRows.action]}</td> */}
                  <td>{eachRows.role?.toUpperCase()==='SELLER'?'ANCHOR':eachRows.role?.toUpperCase()==='BUYER'?'COUNTER PARTY':eachRows?.role?.toUpperCase()}</td>
                  <td>
                    {eachRows?.seller_id||eachRows?.sellerId}
                  </td>
                  <td>
                    {eachRows?.object?.payload?.buyer_id||eachRows?.buyerId}
                  </td>
                  <td>{eachRows?.bankerId}</td>
                  <td>{eachRows?.timestamp}</td>
                  <td style={{ textTransform: "capitalize" }} className={ eachRows?.result=='SUCCESS'||eachRows?.object?.result =='SUCCESS'?"text-success":"text-danger"}>
                    {(
                      eachRows?.result ??
                      eachRows?.object?.result ??
                      "-"
                    ).toLowerCase()}
                  </td>
                  <td>{eachRows.object.error_message}</td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
      
      {!props.isLoading && props.auditTrailMetaData?.paging?.total>5 && (
          <div className="d-flex justify-content-end px-2">
            <span
              onClick={onClickViewMoreTableData}
              className="d-flex align-items-center gap-1 my-3 view-more-text"
            >
              {props.tableQueryParams.tableLimit === 5
               ? props.tableQueryParams.tableOffset+props.tableQueryParams.tableLimit<props.auditTrailMetaData?.paging?.total?"View More ":""
               : props.tableQueryParams.tableOffset+props.tableQueryParams.tableLimit<props.auditTrailMetaData?.paging?.total?"View Less ":""}
              <HiOutlineArrowNarrowDown
                className={`fs-5 ${
                  props.tableQueryParams.tableLimit === 5
                  ? props.tableQueryParams.tableOffset+props.tableQueryParams.tableLimit<props.auditTrailMetaData?.paging?.total? "down-arrow":"d-none"
                  : props.tableQueryParams.tableOffset+props.tableQueryParams.tableLimit<props.auditTrailMetaData?.paging?.total? "up-arrow":"d-none"}
                `}
              />
            </span>
          </div>
        )}

      <div className="d-flex align-items-center gap-2 page-data-text-container mt-3 px-3 w-100">
        <TablePagination
          pageSize={props.tableQueryParams.tableLimit}
          onUpdatePage={updatePage}
          current={props.tableQueryParams.currentPage}
          total={props.auditTrailMetaData?.paging?.total}
        ></TablePagination>
      </div>
    </>
  );
};
