import { Fragment, useContext, useState } from "react";
import { BuyerKycNav } from "./buyerKycNav";
import { BuyerKycContext, BuyerKycContextWrapper } from "./buyerKycContext";
import { BuyerKYCPersonalDetails } from "./personalDetails";
import { BuyerKYCStoreDetails } from "./storeDetails";
import { BuyerKYCBankDetails } from "./bankDetails";
import { BuyerKYCGstDetails } from "./gstDetails";
import { BuyerKYCDocumentDetails } from "./documentDetails";
import { toastMessage } from "../../../layout/GenericLayout/ToastMessage";
import { useSelector } from "react-redux";
import { useNavigate, useLocation, } from "react-router-dom";
import {Helmet} from "react-helmet";

const BuyerKYC = () => {

	const [ termsAndConditions, setTermsAndConditions ] = useState(false);
	
	const { handleSubmitForm, disableEdit } = useContext(BuyerKycContext);
	const navigate = useNavigate();

	const { state } = useLocation();
	
	const buyerSelfKyc = useSelector(state => state.buyer.buyerKYCVerification);
	const rmKycUpload = useSelector(state => state.rm.onboardCounterParty);

	const handleSubmit = () => {
		if (!termsAndConditions) {
			toastMessage("Please Accept declaration", "error");
			return;
		}
		
		handleSubmitForm();
	}

	return (
		<Fragment>
			{
				window.location.pathname !== "/rm/rm-onboarding/counter-party" &&
					<BuyerKycNav />
			}
			 <Helmet>
                <title>KYC | Counter-Party</title>
            </Helmet>
			<div className="admin-kyc-verification-container px-3 py-5">

				<form className="d-flex flex-column m-0 p-0 gap-4">

					{/*===============================================
					*  PERSONAL DETAILS
					* ==============================================*/}
					<BuyerKYCPersonalDetails />

					{/*===============================================
					*  COMPANY DETAILS
					* ==============================================*/}
					<BuyerKYCStoreDetails />


					{/*===============================================
					*  BANK DETAILS
					* ==============================================*/}
					<BuyerKYCBankDetails />


					{/*===============================================
					*  GST DETAILS
					* ==============================================*/}
					<BuyerKYCGstDetails />


					{/*===============================================
					*  OTHER DETAILS
					* ==============================================*/}
					<BuyerKYCDocumentDetails />

					<Fragment>
						{
							!disableEdit &&
								<div className="mx-5">
									<div
										className={`d-flex gap-2 mx-5 align-items-center my-2 details-page-container ${ termsAndConditions ? "bg-success" : "bg-danger" } bg-opacity-25 p-2`}
									>
										<input
											id="termAndCondition"
											type="checkbox"
											className="form-check-input"
											value={ termsAndConditions }
											onChange={ () => setTermsAndConditions(previous => !previous) }
											required
										/>
										<label
											htmlFor="termAndCondition"
											className="kyc-label-text form-check-label"
										>
											I hereby declare that this invoice is not funded/discounted with
											any financier outside ATON
										</label>
									</div>
								</div>
						}

						<div className="d-flex flex-column flex-md-row align-items-center justify-content-end gap-3 mx-5 mb-5">
							{
								!disableEdit && 
									<button disabled={buyerSelfKyc.isLoading || rmKycUpload.isLoading} type="button" onClick={ handleSubmit } style={{ cursor: "pointer" }} className="kyc-back-button">
										{buyerSelfKyc.isLoading || rmKycUpload.isLoading ? (
											<span className="loader mx-auto"></span>
											) : (
											"Submit"
										)}
									</button>
							}
							{
								state?.currentPath && typeof state?.currentPath === "string" &&
									<button type="button" onClick={ () => navigate(state?.currentPath) } style={{ cursor: "pointer" }} className="kyc-back-button">
										Back
									</button>
							}
						</div>
					</Fragment>


				</form>

				<div className="d-flex   justify-content-center mt-4">
					<span>Copyright 2020 Aton.</span>
				</div>
			</div>
		</Fragment>
	);
};

/**
 * @param {{ roles: "buyer" }} props 
 */
const BuyerKycWrapper = (props) => {

	return (
		<BuyerKycContextWrapper roles={props?.roles}>
			<BuyerKYC />
		</BuyerKycContextWrapper>
	);
};

export default BuyerKycWrapper;
