import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {getPurchaseDigitalPODetails} from "../../../store/slices/buyer";
import Loader from "../../../layout/GenericLayout/Loader";


const BuyerPurchaseOrderDetails = () => {
 
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const PurchaseOrderDigitalPODetailsResponse = useSelector((state) => state.buyer.purchaseIDDigitalPODetailState)
  const [purchaseOrderDigitalPODetails, setPurchaseOrderDigitalPODetails] = useState();

  const {paramsPurchaseOrderID} = useParams();
  const location = useLocation();

  useEffect(()=>{
      dispatch(getPurchaseDigitalPODetails({
        'sellerID':location.state,
        'PoID':paramsPurchaseOrderID,
      }));
  }, [dispatch])

  useEffect(()=>{
    if(PurchaseOrderDigitalPODetailsResponse.data){
      setPurchaseOrderDigitalPODetails(PurchaseOrderDigitalPODetailsResponse.data?.items[0])
    }
  }, [PurchaseOrderDigitalPODetailsResponse.data]);

  console.log(`This is Purchase Order Digital Po Details ${paramsPurchaseOrderID}`,purchaseOrderDigitalPODetails);
  return (
    <>
    <div
        className="d-flex flex-column m-0 p-0 gap-4 request-details-cont mb-3"
      >
        <div className="container-with-heading d-flex flex-column container my-4">
          <span className="content-title mb-3 row">Purchase Order</span>
          <div className="details-container ">
            
              <div className="fields-container container-with-heading  px-0 row ">
              <span className="content-title  ">Items Information</span>
                <table  
                className='w-100 col-12 m-0 '>
                  <thead>
                    <tr >
                      <th>Item</th>
                      <th>Model Number</th>
                      <th>Quantity Ordered</th>
                      <th>Available Quantity</th>
                      <th>Price/Unit</th>
                      <th>Tax(%)</th>
                      <th>Total Amount</th>
                    </tr>
                  </thead>
                  {PurchaseOrderDigitalPODetailsResponse.isLoading ? (
          <tbody>
            <tr>
              <td colSpan={7}>
                <Loader className="my-5" />
              </td>
            </tr>
          </tbody>
        ):<tbody>
                  <tr >
                    <td>{purchaseOrderDigitalPODetails?.itemDetails[0]['item']}</td>
                    <td>{purchaseOrderDigitalPODetails?.itemDetails[0]['modelNumber']}</td>
                    <td>{purchaseOrderDigitalPODetails?.itemDetails[0]['quantityOrdered']}</td>
                    <td>{purchaseOrderDigitalPODetails?.itemDetails[0]['quantityAvailable']}</td>
                    <td>{purchaseOrderDigitalPODetails?.itemDetails[0]['unitPrice']}</td>
                    <td>{`${purchaseOrderDigitalPODetails?.itemDetails[0]['tax']} %`}</td>
                    <td>{`₹ ${purchaseOrderDigitalPODetails?.itemDetails[0]['totalAmount'].toFixed(2)}`}</td>
                  </tr>
                  <tr >
                    <td colSpan={6}>{'Total'}</td>
                    <td >{`₹ ${purchaseOrderDigitalPODetails?.itemDetails[0]['totalAmount'].toFixed(2)}`}</td>
                  </tr>
                  </tbody>
}
                </table>
              </div>

              {/* TransPort Details */}
        
         

              <div className="fields-container container-with-heading px-0 row mt-3 gy-3">
              <span className="content-title">Transport Details</span>
              <div className='col-12  col-md-4'>
                <label
                  htmlFor="DeliveryLocation"
                  className="label-text form-label"
                >
                  Shipment Terms
                </label>
                <input
                  id="DeliveryLocation"
                  name="DeliveryLocation"
                  type="text"
                  disabled
                  value={purchaseOrderDigitalPODetails?.shippingTerms}
                  className={`input-field form-control`}
                />
                <span className="text-danger px-1">
                </span>
              </div>

              <div className="fields-container col-12  col-md-4">
                <label
                  htmlFor="RequiredDate"
                  className="label-text form-label"
                >
                 Mode of Transport
                </label>
                <input
                  id="RequiredDate"
                  type="text"
                  name="RequiredDate"
                  disabled
                  value={purchaseOrderDigitalPODetails?.modeOfTransport}
                  className={`input-field form-control`}
                />
             
                <span className="text-danger px-1">
                </span>
            
              </div>

              <div className="fields-container col-12  col-md-4">
                <label
                  htmlFor="Shipment"
                  className="label-text form-label"
                >
                  Expected Date of delivery
                </label>
                <input
                  id="Shipment"
                  type="text"
                  name="Shipment"
                  className={`input-field form-control`}
                  disabled
                  value={purchaseOrderDigitalPODetails?.expectedDateOfDelivery}
                />
                <span className="text-danger px-1">
                </span>
              </div>
              <div className="fields-container col-12  col-md-4">
                <label
                  htmlFor="Shipment"
                  className="label-text form-label"
                >
                  Delivery Location
                </label>
                <input
                  id="Shipment"
                  type="text"
                  name="Shipment"
                  className={`input-field form-control`}
                  disabled
                  value={purchaseOrderDigitalPODetails?.locationOfDelivery}
                />
                <span className="text-danger px-1">
                </span>
              </div>

              <div className="fields-container col-12  col-md-4">
                <label
                  htmlFor="Shipment"
                  className="label-text form-label"
                >
                  Payment Terms
                </label>
                <input
                  id="Shipment"
                  type="text"
                  name="Shipment"
                  className={`input-field form-control`}
                  disabled
                  value={purchaseOrderDigitalPODetails?.paymentTerms}
                />
                <span className="text-danger px-1">
                </span>
              </div>

              <div className="fields-container col-12  col-md-4">
                <label
                  htmlFor="Shipment"
                  className="label-text form-label"
                >
                  Additional Text
                </label>
                <input
                  id="Shipment"
                  type="text"
                  name="Shipment"
                  className={`input-field form-control`}
                  disabled
                  // The API IS INKNOWN
                  value={purchaseOrderDigitalPODetails?.additionalText}
                />
                <span className="text-danger px-1">
                </span>
              </div>



              </div>
     
            {/* </div> */}
            
          </div>
          <div className='d-flex justify-content-end my-2 px-4 container mb-5'>
         <button className='back-button' onClick={()=>{
                navigate('/buyer/purchase-order')
         }}>Back</button>
         
         
         </div>
        </div>
    </div>
    </>
  )
}


export default BuyerPurchaseOrderDetails;
