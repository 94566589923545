import { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useOutlet } from "react-router-dom";
import { BsSearch } from "react-icons/bs";
import { TbBellRingingFilled } from "react-icons/tb";
import { FcAdvertising } from "react-icons/fc";
import { FaBars } from "react-icons/fa";
import { RxCross1 } from "react-icons/rx";
import { AiFillLeftCircle, AiFillSetting } from "react-icons/ai";
import { FiLogOut } from "react-icons/fi";
import atonLogo from "../../assests/images/Aton-logo.png";
import notificationDeclined from "../../assests/icons/notification-declined.svg";
import notificationActive from "../../assests/icons/notification-active.svg";
import { MdOutlineManageAccounts } from "react-icons/md";
import profilePic from '../../assests/images/profilePicture.jpg'
import {profilePicture} from '../../store/slices/generic'
import { useDispatch, useSelector } from "react-redux";


const LenderLayout = () => {
  const outlet = useOutlet();
  const navigate = useNavigate();
  const dispatch=useDispatch();
  const userDetails = JSON.parse(sessionStorage.getItem("user"));

  const [activeMenu, setActiveMenu] = useState("/lender/dashboard");
  const [notification, setNotification] = useState([]);
  const [mobileNavbarStatus, setMobileNavbarBar] = useState(false);

  const sidebarRef=useRef(null);
  const sidebarMenuTextRef=useRef(null);
  const sidebarCircleRef=useRef(null);


  //profile picture
  const [profilePictureImage, setProfilePictureImage]=useState(profilePic);
  const profilePictureData=useSelector(
    (state)=> state.generic.profilePictureData
  )


  useEffect(()=>{
    dispatch(profilePicture());
  },[])


  useEffect(()=>{
    console.log(profilePictureData);
    if(profilePictureData?.data){
     const fileExtension = profilePictureData?.data?.items[0]?.URL?.split('.')?.pop()?.toLowerCase();
     console.log(profilePictureData?.data?.items[0]?.URL, fileExtension)
     if(fileExtension && fileExtension !== 'pdf'){
       setProfilePictureImage(profilePictureData?.data?.items[0]?.URL)
     }
   }
  },[profilePictureData.data])




  const onClickActiveMenu = (path) => {
    setActiveMenu(path);
    setMobileNavbarBar((prev) => !prev);
    navigate(path);
  };

  const onClickSidebarCollaspe = () => {
    // let sidebar = document.querySelector(".admin-sidebar");
    // if (sidebar.style.width === "120px") {
    //   sidebar.style.width = "350px";
    // } else {
    //   sidebar.style.width = "120px";
    // }

    // let sidebar = document.querySelector(".admin-sidebar");
    const sidebar=sidebarRef.current;
    if (sidebar.style.minWidth === "120px") {
      sidebar.style.minWidth = "350px";
      sidebar.style.width = "350px";
    } else {
      sidebar.style.minWidth = "120px";
      sidebar.style.width = "120px";
    }

    // let sidebarMenuText = document.querySelectorAll(".sidebar-text");
    let sidebarMenuText= sidebarMenuTextRef.current.childNodes;
    sidebarMenuText.forEach((eachMenuText) => {
      if (eachMenuText.lastChild.style.display === "none") {
        eachMenuText.lastChild.style.display = "";
      } else {
        eachMenuText.lastChild.style.display = "none";
      }
    });

    // let sidebarCircle = document.querySelector(".sidebar-circle-icon");
    const sidebarCircle= sidebarCircleRef.current;
    if (sidebarCircle.style.transform === "rotate(180deg)") {
      sidebarCircle.style.transform = "rotate(0deg)";
    } else {
      sidebarCircle.style.transform = "rotate(180deg)";
    }
  };

  const onChangeAdminSearch = (event) => {
    // Search resutls to be handled
  };

  const onClickLogout = () => {
    sessionStorage.clear();
    window.location.href = "/login";
  };

  const onClickMyProfile = () => {
    navigate("/lender/my-profile");
  };

  // ********small and medium devices events********
  const onClickMobileNavbar = () => {
    setMobileNavbarBar((prev) => !prev);
  };

  return (
    <>
      {/* desktop and tablet layout */}
      <div className="d-md-flex gap-2 min-vh-100 max-vh-100 vw-100 admin-layout-bg-container p-3 pe-0 d-none">
        <div className="admin-sidebar vh-100 d-flex flex-column py-1" ref={sidebarRef}>
          <div className="sidebar-circle" ref={sidebarCircleRef}>
            <AiFillLeftCircle
              className="sidebar-circle-icon"
              onClick={onClickSidebarCollaspe}
            />
          </div>
          <Link to="/lender/dashboard">
            <div className="d-flex justify-content-center align-items-center">
              <img
                src={atonLogo}
                className="admin-sidebar-image-logo"
                alt="adminlogo"
              />
            </div>
          </Link>

          <div className="sidebar-menu-container d-flex flex-column gap-2" ref={sidebarMenuTextRef}>
            <div
              className={`d-flex align-items-center px-5 gap-3 py-2 ${window.location.pathname.includes("/lender/dashboard")
                  ? "sidebar-active-menu"
                  : "menu-container"
                }`}
              onClick={() => onClickActiveMenu("/lender/dashboard")}
              title="Dashboard"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26.826"
                height="26.816"
                viewBox="0 0 26.826 26.816"
              >
                <g
                  id="noun-dashboard-3644009"
                  transform="translate(-98.002 -28.113)"
                >
                  <path
                    className="sidebar-icons"
                    id="Path_6"
                    data-name="Path 6"
                    d="M100.684,28.113h6.626a2.882,2.882,0,0,1,2.682,2.682v3.914a2.682,2.682,0,0,1-2.682,2.682h-6.626A2.882,2.882,0,0,1,98,34.709V30.8a2.682,2.682,0,0,1,2.682-2.682Z"
                    fill="#2e3c83"
                  />
                  <path
                    className="sidebar-icons"
                    id="Path_7"
                    data-name="Path 7"
                    d="M379.394,28.113h6.626A2.882,2.882,0,0,1,388.7,30.8v9.192a2.682,2.682,0,0,1-2.682,2.682h-6.626a2.882,2.882,0,0,1-2.682-2.682V30.8A2.682,2.682,0,0,1,379.394,28.113Z"
                    transform="translate(-263.875 0)"
                    fill="#2e3c83"
                  />
                  <path
                    className="sidebar-icons"
                    id="Path_8"
                    data-name="Path 8"
                    d="M100.684,258.44h6.626a2.882,2.882,0,0,1,2.682,2.682v9.192A2.683,2.683,0,0,1,107.311,273h-6.626A2.882,2.882,0,0,1,98,270.315v-9.192A2.682,2.682,0,0,1,100.684,258.44Z"
                    transform="translate(0 -218.068)"
                    fill="#2e3c83"
                  />
                  <path
                    className="sidebar-icons"
                    id="Path_9"
                    data-name="Path 9"
                    d="M379.394,357.56h6.626a2.882,2.882,0,0,1,2.682,2.682v3.914a2.682,2.682,0,0,1-2.682,2.682h-6.626a2.882,2.882,0,0,1-2.682-2.682v-3.914A2.682,2.682,0,0,1,379.394,357.56Z"
                    transform="translate(-263.875 -311.912)"
                    fill="#2e3c83"
                  />
                </g>
              </svg>{" "}
              <span className="sidebar-text">Dashboard</span>
            </div>

            <div
              className={`d-flex align-items-center px-5 gap-3 py-2 ${window.location.pathname.includes("/lender/risk-management")
                  ? "sidebar-active-menu"
                  : "menu-container"
                }`}
              onClick={() => onClickActiveMenu("/lender/risk-management")}
              title="Risk Management System"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18.308"
                height="27.244"
                viewBox="0 0 18.308 27.244"
              >
                <path
                  className="sidebar-icons"
                  id="Path_488"
                  data-name="Path 488"
                  d="M178.6,24.384h6.021a1.35,1.35,0,0,0,0-2.7H178.6a1.349,1.349,0,1,0,0,2.7Zm6.634.824a45.7,45.7,0,0,1,3.071,3.712l-2.524,2.8,1.322,1.512-1.25,3.135,3.523-3.149-1.207-1.441,1.11-1.419q.185.3.351.589c3.445,6.049-1.456,12.193-8.019,12.082-6.564.111-11.465-6.033-8.02-12.083a34.193,34.193,0,0,1,4.4-5.74,2.241,2.241,0,0,0,.613.085h6.021a2.257,2.257,0,0,0,.613-.085Zm-3.683,11.635a1.844,1.844,0,0,1-1.869-1.28.453.453,0,0,0-.88.216,2.647,2.647,0,0,0,2.328,1.937v.612a.454.454,0,1,0,.908,0v-.6c1.171-.138,2.372-.823,2.34-2.151q0-.116-.014-.21c-.173-1.425-1.456-1.925-2.7-2.129-.718-.118-1.684-.39-1.726-1.266a1.376,1.376,0,0,1,1.242-1.355,2.156,2.156,0,0,1,.938.022,1.731,1.731,0,0,1,1.2,1.029.454.454,0,1,0,.837-.351,2.643,2.643,0,0,0-2.114-1.617v-.61a.454.454,0,0,0-.908,0V29.7a2.293,2.293,0,0,0-2.1,2.307c.067,1.381,1.293,1.921,2.485,2.117.79.13,1.831.4,1.945,1.342.006.052.01.094.01.125.021.9-1.07,1.23-1.8,1.248-.039,0-.08,0-.118,0Zm-2.807-16.066-1.3-1.3a1.387,1.387,0,0,1,1.731-2.147.713.713,0,0,0,1.082-.448,1.387,1.387,0,0,1,2.71,0,.713.713,0,0,0,1.082.448,1.387,1.387,0,0,1,1.731,2.147l-1.3,1.3Z"
                  transform="translate(-172.453 -15.787)"
                  fill="#2e3c83"
                  fillRule="evenodd"
                />
              </svg>

              <span className="sidebar-text">Risk Management System</span>
            </div>
            
            <div
              className={`d-flex align-items-center px-5 gap-3 py-2 ${window.location.pathname.includes("/lender/trans-maintain")
                  ? "sidebar-active-menu"
                  : "menu-container"
                }`}
              onClick={() => onClickActiveMenu("/lender/trans-maintain")}
              title="Transaction Maintenance"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24.664"
                height="24.664"
                viewBox="0 0 24.664 24.664"
              >
                <path
                  className="sidebar-icons"
                  id="noun-transaction-3383537"
                  d="M137.678,66.259a.507.507,0,0,1,.534-.474.574.574,0,0,1,.333.11.946.946,0,0,0,1.112,0,.574.574,0,0,1,.333-.11.478.478,0,1,1,0,.949.574.574,0,0,1-.333-.11.946.946,0,0,0-1.112,0,.574.574,0,0,1-.333.11A.507.507,0,0,1,137.678,66.259Zm7.589-7.115a.948.948,0,0,0-.949-.949H125.346a.949.949,0,0,0-.949.949v.949h20.87ZM124.4,61.99h20.87v8.538a.948.948,0,0,1-.949.949H125.346a.948.948,0,0,1-.949-.949Zm11.383,4.269a2.4,2.4,0,0,0,2.431,2.372,2.434,2.434,0,0,0,.889-.167,2.434,2.434,0,0,0,.889.167,2.372,2.372,0,1,0,0-4.743,2.434,2.434,0,0,0-.889.167,2.433,2.433,0,0,0-.889-.167,2.4,2.4,0,0,0-2.431,2.372Zm-8.538-1.423a.948.948,0,0,0,.949.949h1.9a.949.949,0,1,0,0-1.9h-1.9a.948.948,0,0,0-.949.949Zm0,2.846a.948.948,0,0,0,.949.949h1.9a.949.949,0,1,0,0-1.9h-1.9a.948.948,0,0,0-.949.949Zm18.972,4.743a.948.948,0,0,0-.949.949v.949H125.346a.949.949,0,0,0-1.619-.671l-.949.949a.948.948,0,0,0,0,1.341l.949.949a.949.949,0,0,0,1.619-.671h20.87a.949.949,0,0,0,.949-.949v-1.9a.949.949,0,0,0-.949-.949ZM123.449,57.247a.948.948,0,0,0,.949-.949V55.35h19.921a.949.949,0,0,0,1.619.671l.949-.949a.948.948,0,0,0,0-1.341l-.949-.949a.949.949,0,0,0-1.619.671h-20.87a.948.948,0,0,0-.949.949v1.9a.948.948,0,0,0,.949.949Z"
                  transform="translate(-122.5 -52.504)"
                  fill="#2e3c83"
                />
              </svg>

              <span className="sidebar-text">Transaction Maintenance</span>
            </div>

            <div
              className={`d-flex align-items-center px-5 gap-3 py-2 ${window.location.pathname.includes("/lender/bill-lodging")
                  ? "sidebar-active-menu"
                  : "menu-container"
                }`}
              onClick={() => onClickActiveMenu("/lender/bill-lodging")}
              title="Bill Lodgement"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18.308"
                height="22.492"
                viewBox="0 0 18.308 22.492"
              >
                <path
                  className="sidebar-icons"
                  id="Path_490"
                  data-name="Path 490"
                  d="M147.661,51.66h14.646a1.833,1.833,0,0,0,1.831-1.831V35.445h-4.446a1.833,1.833,0,0,1-1.831-1.831V29.168h-10.2A1.833,1.833,0,0,0,145.83,31V49.829a1.833,1.833,0,0,0,1.831,1.831Zm5.754-14.646h3.138a.785.785,0,1,1,0,1.569h-3.138a.785.785,0,1,1,0-1.569Zm-3.138-.261a1.046,1.046,0,1,1-.74.306A1.046,1.046,0,0,1,150.276,36.752Zm-.523,3.4h10.462a.785.785,0,0,1,0,1.569H149.753a.785.785,0,0,1,0-1.569Zm0,3.138h10.462a.785.785,0,0,1,0,1.569H149.753a.785.785,0,0,1,0-1.569Zm0,3.138h10.462a.785.785,0,0,1,0,1.569H149.753a.785.785,0,0,1,0-1.569Z"
                  transform="translate(-145.83 -29.168)"
                  fill="#2e3b83"
                />
              </svg>

              <span className="sidebar-text">Bill Lodgement</span>
            </div>

            <div
              className={`d-flex align-items-center px-5 gap-3 py-2 ${window.location.pathname === "/lender/disbursed"
                  ? "sidebar-active-menu"
                  : "menu-container"
                }`}
              onClick={() => onClickActiveMenu("/lender/disbursed")}
              title="Discounting"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24.969"
                height="22.888"
                viewBox="0 0 24.969 22.888"
              >
                <g
                  id="noun-payment-1635902"
                  transform="translate(-105.283 -74.664)"
                >
                  <path
                    className="sidebar-icons"
                    id="Path_494"
                    data-name="Path 494"
                    d="M200.66,373.33h2.081v2.081H200.66Z"
                    transform="translate(-91.216 -282.02)"
                    fill="#2e3c83"
                  />
                  <path
                    className="sidebar-icons"
                    id="Path_495"
                    data-name="Path 495"
                    d="M275.34,373.33h2.08v2.081h-2.08Z"
                    transform="translate(-161.734 -282.02)"
                    fill="#2e3c83"
                  />
                  <path
                    className="sidebar-icons"
                    id="Path_496"
                    data-name="Path 496"
                    d="M387.33,373.33h6.242v2.081H387.33Z"
                    transform="translate(-267.482 -282.02)"
                    fill="#2e3c83"
                  />
                  <path
                    className="sidebar-icons"
                    id="Path_497"
                    data-name="Path 497"
                    d="M148.882,95.471H128.075v-10.4H142.64V82.987H128.075V80.907l19.336,0-2.69,2.688,1.471,1.471,4.467-4.466a1.041,1.041,0,0,0,0-1.471l-4.466-4.466-1.471,1.471,2.689,2.69H128.075a2.087,2.087,0,0,0-2.081,2.081V95.472a2.087,2.087,0,0,0,2.081,2.08h20.807a2.087,2.087,0,0,0,2.081-2.08v-10.4h-2.081Z"
                    transform="translate(-20.712)"
                    fill="#2e3c83"
                  />
                </g>
              </svg>

              <span className="sidebar-text">Discounting</span>
            </div>

            <div
              className={`d-flex align-items-center px-5 gap-3 py-2 ${window.location.pathname.includes("/lender/liquidation")
                  ? "sidebar-active-menu"
                  : "menu-container"
                }`}
              onClick={() => onClickActiveMenu("/lender/liquidation")}
              title="Liquidation"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23.565"
                height="23.555"
                viewBox="0 0 23.565 23.555"
              >
                <g
                  id="noun-power-distribution-2155854"
                  transform="translate(-165.131 -159.531)"
                >
                  <path
                    className="sidebar-icons"
                    id="Path_498"
                    data-name="Path 498"
                    d="M184.107,161.982a11.781,11.781,0,1,0,2.128,16.525A11.793,11.793,0,0,0,184.107,161.982ZM175.1,180.351l1.915-7.021-5.6-.071,7.837-11.064-1.312,6.986h5.284Z"
                    fill="#2e3c83"
                  />
                </g>
              </svg>

              <span className="sidebar-text">Liquidation</span>
            </div>

            <div
              className={`d-flex align-items-center px-5 gap-3 py-2 ${window.location.pathname === "/lender/disbursed-invoices"
                  ? "sidebar-active-menu"
                  : "menu-container"
                }`}
              onClick={() => onClickActiveMenu("/lender/disbursed-invoices")}
              title="Disbursed Invoices"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24.664"
                height="24.664"
                viewBox="0 0 24.664 24.664"
              >
                <path
                  className="sidebar-icons"
                  id="noun-transaction-3383537"
                  d="M137.678,66.259a.507.507,0,0,1,.534-.474.574.574,0,0,1,.333.11.946.946,0,0,0,1.112,0,.574.574,0,0,1,.333-.11.478.478,0,1,1,0,.949.574.574,0,0,1-.333-.11.946.946,0,0,0-1.112,0,.574.574,0,0,1-.333.11A.507.507,0,0,1,137.678,66.259Zm7.589-7.115a.948.948,0,0,0-.949-.949H125.346a.949.949,0,0,0-.949.949v.949h20.87ZM124.4,61.99h20.87v8.538a.948.948,0,0,1-.949.949H125.346a.948.948,0,0,1-.949-.949Zm11.383,4.269a2.4,2.4,0,0,0,2.431,2.372,2.434,2.434,0,0,0,.889-.167,2.434,2.434,0,0,0,.889.167,2.372,2.372,0,1,0,0-4.743,2.434,2.434,0,0,0-.889.167,2.433,2.433,0,0,0-.889-.167,2.4,2.4,0,0,0-2.431,2.372Zm-8.538-1.423a.948.948,0,0,0,.949.949h1.9a.949.949,0,1,0,0-1.9h-1.9a.948.948,0,0,0-.949.949Zm0,2.846a.948.948,0,0,0,.949.949h1.9a.949.949,0,1,0,0-1.9h-1.9a.948.948,0,0,0-.949.949Zm18.972,4.743a.948.948,0,0,0-.949.949v.949H125.346a.949.949,0,0,0-1.619-.671l-.949.949a.948.948,0,0,0,0,1.341l.949.949a.949.949,0,0,0,1.619-.671h20.87a.949.949,0,0,0,.949-.949v-1.9a.949.949,0,0,0-.949-.949ZM123.449,57.247a.948.948,0,0,0,.949-.949V55.35h19.921a.949.949,0,0,0,1.619.671l.949-.949a.948.948,0,0,0,0-1.341l-.949-.949a.949.949,0,0,0-1.619.671h-20.87a.948.948,0,0,0-.949.949v1.9a.948.948,0,0,0,.949.949Z"
                  transform="translate(-122.5 -52.504)"
                  fill="#2e3c83"
                />
              </svg>

              <span className="sidebar-text">Disbursed Invoices</span>
            </div>

            <div
              className={`d-flex align-items-center px-5 gap-3 py-2 ${window.location.pathname.includes("/lender/under-writer")
                  ? "sidebar-active-menu"
                  : "menu-container"
                }`}
              onClick={() => onClickActiveMenu("/lender/under-writer")}
              title="Underwriter"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19.081"
                height="20.663"
                viewBox="0 0 19.081 20.663"
              >
                <g
                  id="noun-person-2237338"
                  transform="translate(-118.864 -29.7)"
                >
                  <path
                    className="sidebar-icons"
                    id="Path_467"
                    data-name="Path 467"
                    d="M201.025,36.4a6.7,6.7,0,1,1-6.7-6.7,6.7,6.7,0,0,1,6.7,6.7"
                    transform="translate(-65.918)"
                    fill="#2e3c83"
                  />
                  <path
                    className="sidebar-icons"
                    id="Path_468"
                    data-name="Path 468"
                    d="M121.815,393.2h13.178a2.951,2.951,0,1,0,0-5.9H121.815a2.951,2.951,0,0,0,0,5.9Z"
                    transform="translate(0 -342.839)"
                    fill="#2e3c83"
                  />
                </g>
              </svg>

              <span className="sidebar-text">UnderWriter</span>
            </div>

            <div
              className={`d-flex align-items-center px-5 gap-3 py-2 ${window.location.pathname.includes("/lender/agreement")
                  ? "sidebar-active-menu"
                  : "menu-container"
                }`}
              onClick={() => onClickActiveMenu("/lender/agreement")}
              title="Agreement"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15.719"
                height="21.613"
                viewBox="0 0 15.719 21.613"
              >
                <path
                  className="sidebar-icons"
                  id="noun-contract-2850030"
                  d="M173.16,39.057h5.324l-5.324,5.315ZM171.2,44.952h-6.877a.983.983,0,0,1-.983-.983V24.321a.983.983,0,0,1,.983-.983h13.754a.983.983,0,0,1,.983.983V37.092h-6.877a.973.973,0,0,0-.982.982Zm-3.93-16.7a.982.982,0,0,0,.982.982h5.895a.982.982,0,1,0,0-1.965h-5.895a.982.982,0,0,0-.982.982Zm.982,4.912h5.895a.982.982,0,0,0,0-1.965h-5.895a.982.982,0,0,0,0,1.965Z"
                  transform="translate(-163.336 -23.338)"
                  fill="#2e3c83"
                />
              </svg>

              <span className="sidebar-text">Agreement</span>
            </div>

            <div
              className={`d-flex align-items-center px-5 gap-3 py-2 ${window.location.pathname.includes("/lender/approve-limit")
                  ? "sidebar-active-menu"
                  : "menu-container"
                }`}
              onClick={() => onClickActiveMenu("/lender/approve-limit")}
              title="Approve Limit"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15.872"
                height="25.591"
                viewBox="0 0 15.872 25.591"
              >
                <g
                  id="noun-hourglass-2035192"
                  transform="translate(-225.316 -79.844)"
                >
                  <path
                    className="sidebar-icons"
                    id="Path_506"
                    data-name="Path 506"
                    d="M263.539,293.974l-5.676-4.317-5.563,4.314a1.883,1.883,0,0,0-.735,1.5v2.383h12.726v-2.369a1.908,1.908,0,0,0-.752-1.507Z"
                    transform="translate(-24.571 -196.4)"
                    fill="#2e3c83"
                  />
                  <path
                    className="sidebar-icons"
                    id="Path_507"
                    data-name="Path 507"
                    d="M225.422,79.844h15.659a.114.114,0,0,1,.106.106v2.794a.106.106,0,0,1-.106.106H225.422a.114.114,0,0,1-.106-.106V79.95A.106.106,0,0,1,225.422,79.844Z"
                    fill="#2e3c83"
                  />
                  <path
                    className="sidebar-icons"
                    id="Path_508"
                    data-name="Path 508"
                    d="M225.422,433.12h15.659a.106.106,0,0,1,.106.106v2.794h0a.106.106,0,0,1-.106.106H225.422a.106.106,0,0,1-.106-.106v-2.794A.106.106,0,0,1,225.422,433.12Z"
                    transform="translate(0 -330.691)"
                    fill="#2e3c83"
                  />
                  <path
                    className="sidebar-icons"
                    id="Path_509"
                    data-name="Path 509"
                    d="M250.47,144.381a1.888,1.888,0,0,0,.714,1.479l5.648,4.516,5.65-4.516a1.888,1.888,0,0,0,.715-1.479v-2.189H250.47Z"
                    transform="translate(-23.546 -58.362)"
                    fill="#2e3c83"
                  />
                </g>
              </svg>

              <span className="sidebar-text">Approve Limit</span>
            </div>

            <div
              className={`d-flex align-items-center px-5 gap-3 py-2 ${window.location.pathname.includes("/lender/purchase-order")
                  ? "sidebar-active-menu"
                  : "menu-container"
                }`}
              onClick={() => onClickActiveMenu("/lender/purchase-order")}
              title="Purchase Order"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18.858"
                height="24.001"
                viewBox="0 0 18.858 24.001"
              >
                <g id="noun-order-4924410" transform="translate(-157.5 -35)">
                  <path
                    className="sidebar-icons"
                    id="Path_502"
                    data-name="Path 502"
                    d="M173.786,70h-.857v1.714a.857.857,0,1,1-1.714,0V70h-3.429v1.714a.857.857,0,1,1-1.714,0V70h-3.429v1.714a.857.857,0,1,1-1.714,0V70h-.857a2.579,2.579,0,0,0-2.572,2.572V89.715a2.579,2.579,0,0,0,2.572,2.572h13.715a2.579,2.579,0,0,0,2.572-2.572V72.572A2.579,2.579,0,0,0,173.786,70Zm-6,17.144h-5.143a.857.857,0,1,1,0-1.714h5.143a.857.857,0,1,1,0,1.714Zm3.429-4.286h-8.572a.857.857,0,1,1,0-1.714h8.572a.857.857,0,1,1,0,1.714Zm0-4.286h-8.572a.857.857,0,1,1,0-1.714h8.572a.857.857,0,1,1,0,1.714Z"
                    transform="translate(0 -33.285)"
                    fill="#2e3c83"
                  />
                  <path
                    className="sidebar-icons"
                    id="Path_503"
                    data-name="Path 503"
                    d="M229.214,35.857a.857.857,0,1,0-1.714,0v.857h1.714Z"
                    transform="translate(-66.571)"
                    fill="#2e3c83"
                  />
                  <path
                    className="sidebar-icons"
                    id="Path_504"
                    data-name="Path 504"
                    d="M439.214,35.857a.857.857,0,1,0-1.714,0v.857h1.714Z"
                    transform="translate(-266.285)"
                    fill="#2e3c83"
                  />
                  <path
                    className="sidebar-icons"
                    id="Path_505"
                    data-name="Path 505"
                    d="M334.214,35.857a.857.857,0,1,0-1.714,0v.857h1.714Z"
                    transform="translate(-166.428)"
                    fill="#2e3c83"
                  />
                </g>
              </svg>

              <span className="sidebar-text">Purchase Order</span>
            </div>

            <div
              className={`d-flex align-items-center px-5 gap-3 py-2 ${window.location.pathname.includes("/lender/report")
                  ? "sidebar-active-menu"
                  : "menu-container"
                }`}
              onClick={() => onClickActiveMenu("/lender/report")}
              title="Reports"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18.028"
                height="22.361"
                viewBox="0 0 18.028 22.361"
              >
                <path
                  className="sidebar-icons"
                  id="Path_510"
                  data-name="Path 510"
                  d="M140.247,0h-7.686V5.765a2.565,2.565,0,0,1-2.551,2.551h-5.765V20.334a2.028,2.028,0,0,0,2.026,2.026h13.975a2.028,2.028,0,0,0,2.026-2.026V2.027A2.028,2.028,0,0,0,140.247,0Zm-11.32,19.845h-1.363v-4.4h1.363Zm3.354,0h-1.363V13.8h1.363Zm3.319,0h-1.363V12.123H135.6Zm3.319,0h-1.363v-9.4h1.363Zm1.328-12.718h-5.415V5.765h5.415Zm0-3.214h-5.415V2.551h5.415Z"
                  transform="translate(-124.245)"
                  fill="#2e3c83"
                />
              </svg>

              <span className="sidebar-text">Reports</span>
            </div>
          </div>
        </div>

        <div
          className="d-flex flex-column gap-2 flex-grow-1"
          style={{ width: "60%" }}
        >
          <div className={`d-flex align-items-center pe-3 search justify-content-end py-2`}>
          {/* {window.location.pathname.includes("/lender/dashboard")?
            <div className="admin-search-container d-flex align-items-center py-1 px-4 mx-3 mb-3">
              <input
                className="admin-search-input-element d-flex flex-grow-1"
                type="search"
                placeholder="Search"
                onChange={onChangeAdminSearch}
              />{" "}
              <BsSearch />
            </div>
            :""} */}

            <div className="d-flex gap-3 align-items-center">
              <div className="dropdown-center">
                <button
                  className="notification-bell border-0"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {notification.length === 0 ? (
                    <img
                      src={notificationDeclined}
                      alt="notification declined"
                    />
                  ) : (
                    <img src={notificationActive} alt="notification active" />
                  )}
                </button>
                <ul
                  className="dropdown-menu dropdown-menu-end border-0 shadow"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <h6 className="dropdown-header d-flex align-items-center gap-2">
                      <TbBellRingingFilled /> Notification
                    </h6>
                  </li>
                  <li className="dropdown-item d-flex flex-column justify-content-center align-items-center">
                    <FcAdvertising className="fs-3" />
                    No new notification!
                  </li>
                </ul>
              </div>
              <div className="d-flex align-items-center gap-2">
                <div className="dropdown-center">
                  <button
                    type="button"
                    id="dropdownMenuButton1"
                    className="border-0"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      className="admin-profile-pic rounded-circle"
                      src={profilePictureImage}
                      alt="len pic"
                    />
                  </button>
                  <ul
                    className="dropdown-menu dropdown-menu-start border-0 shadow"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <h6 className="dropdown-header d-flex align-items-center gap-2">
                        <AiFillSetting /> Menu
                      </h6>
                    </li>
                    <li
                      className="dropdown-item fw-semibold d-flex align-items-center gap-2"
                      style={{ cursor: "pointer" }}
                      onClick={onClickMyProfile}
                    >
                      {" "}
                      <MdOutlineManageAccounts /> My Profile
                    </li>
                    <li
                      className="dropdown-item fw-semibold d-flex align-items-center gap-2"
                      style={{ cursor: "pointer" }}
                      onClick={onClickLogout}
                    >
                      {" "}
                      <FiLogOut /> Logout
                    </li>
                  </ul>
                </div>

                <div className="d-flex flex-column justify-content=evenly">
                  <span className="admin-name">
                    {userDetails.name === null ? "N/A" : "Hi, " + userDetails.name}
                  </span>
                  <span className="role">Lender!</span>
                </div>
              </div>
            </div>
          </div>
          <div className="admin-content p-2 lender-content-layout">
            {window.innerWidth > 767 && outlet}
            <footer className="footer">
              <div>Copyright &copy; 2023 Aton</div>
            </footer>
          </div>
        </div>
      </div>
      {/* mobile layout */}
      <div
        className={`d-md-none admin-layout-small-devices-bg-container d-flex flex-column ${mobileNavbarStatus && "active-navbar"
          }`}
      >
        <div className="topnav d-flex flex-column">
          <div className="d-flex justify-content-between align-items-center px-2 py-2 only-navbar">
            <div className="d-flex align-items-center gap-2">
              <button
                onClick={onClickMobileNavbar}
                type="button"
                className="btn outline-0 border-0"
              >
                {mobileNavbarStatus ? (
                  <RxCross1 className="fs-1" />
                ) : (
                  <FaBars className="fs-2" />
                )}
              </button>

              <Link to="/admin/dashboard">
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    src={atonLogo}
                    alt="aton logo"
                    className="mobile-navbar-image-logo"
                  />
                </div>
              </Link>
            </div>

            <div className="d-flex align-items-center gap-2">
              <div className="dropdown-center">
                <button
                  className="border-0 bg-transparent"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {notification.length === 0 ? (
                    <img
                      src={notificationDeclined}
                      alt="notification declined"
                    />
                  ) : (
                    <img src={notificationActive} alt="notification active" />
                  )}
                </button>
                <ul
                  className="dropdown-menu dropdown-menu-end border-0 shadow"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <h6 className="dropdown-header d-flex align-items-center gap-2">
                      <TbBellRingingFilled /> Notification
                    </h6>
                  </li>
                  <li className="dropdown-item d-flex flex-column justify-content-center align-items-center">
                    <FcAdvertising className="fs-3" />
                    No new notification!
                  </li>
                </ul>
              </div>

              <div className="d-flex align-items-center gap-2">
                <div className="dropdown-center">
                  <button
                    className="border-0 bg-transparent"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      className="admin-profile-pic rounded-circle"
                      src={profilePictureImage}
                      alt="admin-profile-pic"
                    />
                  </button>
                  <ul
                    className="dropdown-menu dropdown-menu-start border-0 shadow"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <h6 className="dropdown-header d-flex align-items-center gap-2">
                        <AiFillSetting /> Admin!
                      </h6>
                    </li>

                    <li className="dropdown-item d-flex flex-column justify-content-center align-items-center">
                      {userDetails.name === null ? "N/A" : userDetails.name}
                    </li>
                  </ul>
                </div>
              </div>

              <div
                className="fw-semibold d-flex align-items-center justify-content-center"
                style={{ cursor: "pointer" }}
                onClick={onClickLogout}
              >
                <FiLogOut className="fs-2" />
              </div>
            </div>
          </div>

          {mobileNavbarStatus && (
            <div className="min-vh-100">
              <div
                className="d-flex flex-column gap-1 justify-content-center"
                style={{ padding: "0 100px" }}
              >
                <div
                  className={`d-flex align-items-center px-5 gap-3 py-2 ${window.location.pathname.includes("/lender/dashboard")
                      ? "mobile-sidebar-active-menu"
                      : "mobile-menu-container"
                    }`}
                  onClick={() => onClickActiveMenu("/lender/dashboard")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26.826"
                    height="26.816"
                    viewBox="0 0 26.826 26.816"
                  >
                    <g
                      id="noun-dashboard-3644009"
                      transform="translate(-98.002 -28.113)"
                    >
                      <path
                        className="sidebar-icons"
                        id="Path_6"
                        data-name="Path 6"
                        d="M100.684,28.113h6.626a2.882,2.882,0,0,1,2.682,2.682v3.914a2.682,2.682,0,0,1-2.682,2.682h-6.626A2.882,2.882,0,0,1,98,34.709V30.8a2.682,2.682,0,0,1,2.682-2.682Z"
                        fill="#2e3c83"
                      />
                      <path
                        className="sidebar-icons"
                        id="Path_7"
                        data-name="Path 7"
                        d="M379.394,28.113h6.626A2.882,2.882,0,0,1,388.7,30.8v9.192a2.682,2.682,0,0,1-2.682,2.682h-6.626a2.882,2.882,0,0,1-2.682-2.682V30.8A2.682,2.682,0,0,1,379.394,28.113Z"
                        transform="translate(-263.875 0)"
                        fill="#2e3c83"
                      />
                      <path
                        className="sidebar-icons"
                        id="Path_8"
                        data-name="Path 8"
                        d="M100.684,258.44h6.626a2.882,2.882,0,0,1,2.682,2.682v9.192A2.683,2.683,0,0,1,107.311,273h-6.626A2.882,2.882,0,0,1,98,270.315v-9.192A2.682,2.682,0,0,1,100.684,258.44Z"
                        transform="translate(0 -218.068)"
                        fill="#2e3c83"
                      />
                      <path
                        className="sidebar-icons"
                        id="Path_9"
                        data-name="Path 9"
                        d="M379.394,357.56h6.626a2.882,2.882,0,0,1,2.682,2.682v3.914a2.682,2.682,0,0,1-2.682,2.682h-6.626a2.882,2.882,0,0,1-2.682-2.682v-3.914A2.682,2.682,0,0,1,379.394,357.56Z"
                        transform="translate(-263.875 -311.912)"
                        fill="#2e3c83"
                      />
                    </g>
                  </svg>{" "}
                  <span className="sidebar-text">Dashboard</span>
                </div>

                <hr className="px-5 mobile-nav-line my-2" />

                <div
                  className={`d-flex align-items-center px-5 gap-3 py-2 ${window.location.pathname.includes("/lender/risk-management")
                      ? "mobile-sidebar-active-menu"
                      : "mobile-menu-container"
                    }`}
                  onClick={() => onClickActiveMenu("/lender/risk-management")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18.308"
                    height="27.244"
                    viewBox="0 0 18.308 27.244"
                  >
                    <path
                      className="sidebar-icons"
                      id="Path_488"
                      data-name="Path 488"
                      d="M178.6,24.384h6.021a1.35,1.35,0,0,0,0-2.7H178.6a1.349,1.349,0,1,0,0,2.7Zm6.634.824a45.7,45.7,0,0,1,3.071,3.712l-2.524,2.8,1.322,1.512-1.25,3.135,3.523-3.149-1.207-1.441,1.11-1.419q.185.3.351.589c3.445,6.049-1.456,12.193-8.019,12.082-6.564.111-11.465-6.033-8.02-12.083a34.193,34.193,0,0,1,4.4-5.74,2.241,2.241,0,0,0,.613.085h6.021a2.257,2.257,0,0,0,.613-.085Zm-3.683,11.635a1.844,1.844,0,0,1-1.869-1.28.453.453,0,0,0-.88.216,2.647,2.647,0,0,0,2.328,1.937v.612a.454.454,0,1,0,.908,0v-.6c1.171-.138,2.372-.823,2.34-2.151q0-.116-.014-.21c-.173-1.425-1.456-1.925-2.7-2.129-.718-.118-1.684-.39-1.726-1.266a1.376,1.376,0,0,1,1.242-1.355,2.156,2.156,0,0,1,.938.022,1.731,1.731,0,0,1,1.2,1.029.454.454,0,1,0,.837-.351,2.643,2.643,0,0,0-2.114-1.617v-.61a.454.454,0,0,0-.908,0V29.7a2.293,2.293,0,0,0-2.1,2.307c.067,1.381,1.293,1.921,2.485,2.117.79.13,1.831.4,1.945,1.342.006.052.01.094.01.125.021.9-1.07,1.23-1.8,1.248-.039,0-.08,0-.118,0Zm-2.807-16.066-1.3-1.3a1.387,1.387,0,0,1,1.731-2.147.713.713,0,0,0,1.082-.448,1.387,1.387,0,0,1,2.71,0,.713.713,0,0,0,1.082.448,1.387,1.387,0,0,1,1.731,2.147l-1.3,1.3Z"
                      transform="translate(-172.453 -15.787)"
                      fill="#2e3c83"
                      fillRule="evenodd"
                    />
                  </svg>

                  <span className="sidebar-text">Risk Management System</span>
                </div>

                <hr className="px-5 mobile-nav-line my-2" />

                <div
                  className={`d-flex align-items-center px-5 gap-3 py-2 ${window.location.pathname.includes("/lender/bill-lodging")
                      ? "mobile-sidebar-active-menu"
                      : "mobile-menu-container"
                    }`}
                  onClick={() => onClickActiveMenu("/lender/bill-lodging")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18.308"
                    height="22.492"
                    viewBox="0 0 18.308 22.492"
                  >
                    <path
                      className="sidebar-icons"
                      id="Path_490"
                      data-name="Path 490"
                      d="M147.661,51.66h14.646a1.833,1.833,0,0,0,1.831-1.831V35.445h-4.446a1.833,1.833,0,0,1-1.831-1.831V29.168h-10.2A1.833,1.833,0,0,0,145.83,31V49.829a1.833,1.833,0,0,0,1.831,1.831Zm5.754-14.646h3.138a.785.785,0,1,1,0,1.569h-3.138a.785.785,0,1,1,0-1.569Zm-3.138-.261a1.046,1.046,0,1,1-.74.306A1.046,1.046,0,0,1,150.276,36.752Zm-.523,3.4h10.462a.785.785,0,0,1,0,1.569H149.753a.785.785,0,0,1,0-1.569Zm0,3.138h10.462a.785.785,0,0,1,0,1.569H149.753a.785.785,0,0,1,0-1.569Zm0,3.138h10.462a.785.785,0,0,1,0,1.569H149.753a.785.785,0,0,1,0-1.569Z"
                      transform="translate(-145.83 -29.168)"
                      fill="#2e3b83"
                    />
                  </svg>

                  <span className="sidebar-text">Bill Lodgement</span>
                </div>

                <hr className="px-5 mobile-nav-line my-2" />

                <div
                  className={`d-flex align-items-center px-5 gap-3 py-2 ${window.location.pathname.includes("/lender/disbursed")
                      ? "mobile-sidebar-active-menu"
                      : "mobile-menu-container"
                    }`}
                  onClick={() => onClickActiveMenu("/lender/disbursed")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24.969"
                    height="22.888"
                    viewBox="0 0 24.969 22.888"
                  >
                    <g
                      id="noun-payment-1635902"
                      transform="translate(-105.283 -74.664)"
                    >
                      <path
                        className="sidebar-icons"
                        id="Path_494"
                        data-name="Path 494"
                        d="M200.66,373.33h2.081v2.081H200.66Z"
                        transform="translate(-91.216 -282.02)"
                        fill="#2e3c83"
                      />
                      <path
                        className="sidebar-icons"
                        id="Path_495"
                        data-name="Path 495"
                        d="M275.34,373.33h2.08v2.081h-2.08Z"
                        transform="translate(-161.734 -282.02)"
                        fill="#2e3c83"
                      />
                      <path
                        className="sidebar-icons"
                        id="Path_496"
                        data-name="Path 496"
                        d="M387.33,373.33h6.242v2.081H387.33Z"
                        transform="translate(-267.482 -282.02)"
                        fill="#2e3c83"
                      />
                      <path
                        className="sidebar-icons"
                        id="Path_497"
                        data-name="Path 497"
                        d="M148.882,95.471H128.075v-10.4H142.64V82.987H128.075V80.907l19.336,0-2.69,2.688,1.471,1.471,4.467-4.466a1.041,1.041,0,0,0,0-1.471l-4.466-4.466-1.471,1.471,2.689,2.69H128.075a2.087,2.087,0,0,0-2.081,2.081V95.472a2.087,2.087,0,0,0,2.081,2.08h20.807a2.087,2.087,0,0,0,2.081-2.08v-10.4h-2.081Z"
                        transform="translate(-20.712)"
                        fill="#2e3c83"
                      />
                    </g>
                  </svg>

                  <span className="sidebar-text">Disbursed</span>
                </div>

                <hr className="px-5 mobile-nav-line my-2" />

                <div
                  className={`d-flex align-items-center px-5 gap-3 py-2 ${window.location.pathname.includes("/lender/liquidation")
                      ? "mobile-sidebar-active-menu"
                      : "mobile-menu-container"
                    }`}
                  onClick={() => onClickActiveMenu("/lender/liquidation")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23.565"
                    height="23.555"
                    viewBox="0 0 23.565 23.555"
                  >
                    <g
                      id="noun-power-distribution-2155854"
                      transform="translate(-165.131 -159.531)"
                    >
                      <path
                        className="sidebar-icons"
                        id="Path_498"
                        data-name="Path 498"
                        d="M184.107,161.982a11.781,11.781,0,1,0,2.128,16.525A11.793,11.793,0,0,0,184.107,161.982ZM175.1,180.351l1.915-7.021-5.6-.071,7.837-11.064-1.312,6.986h5.284Z"
                        fill="#2e3c83"
                      />
                    </g>
                  </svg>

                  <span className="sidebar-text">Liquidation</span>
                </div>

                <hr className="px-5 mobile-nav-line my-2" />

                <div
                  className={`d-flex align-items-center px-5 gap-3 py-2 ${window.location.pathname.includes("/lender/trans-maintain")
                      ? "mobile-sidebar-active-menu"
                      : "mobile-menu-container"
                    }`}
                  onClick={() => onClickActiveMenu("/lender/trans-maintain")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24.664"
                    height="24.664"
                    viewBox="0 0 24.664 24.664"
                  >
                    <path
                      className="sidebar-icons"
                      id="noun-transaction-3383537"
                      d="M137.678,66.259a.507.507,0,0,1,.534-.474.574.574,0,0,1,.333.11.946.946,0,0,0,1.112,0,.574.574,0,0,1,.333-.11.478.478,0,1,1,0,.949.574.574,0,0,1-.333-.11.946.946,0,0,0-1.112,0,.574.574,0,0,1-.333.11A.507.507,0,0,1,137.678,66.259Zm7.589-7.115a.948.948,0,0,0-.949-.949H125.346a.949.949,0,0,0-.949.949v.949h20.87ZM124.4,61.99h20.87v8.538a.948.948,0,0,1-.949.949H125.346a.948.948,0,0,1-.949-.949Zm11.383,4.269a2.4,2.4,0,0,0,2.431,2.372,2.434,2.434,0,0,0,.889-.167,2.434,2.434,0,0,0,.889.167,2.372,2.372,0,1,0,0-4.743,2.434,2.434,0,0,0-.889.167,2.433,2.433,0,0,0-.889-.167,2.4,2.4,0,0,0-2.431,2.372Zm-8.538-1.423a.948.948,0,0,0,.949.949h1.9a.949.949,0,1,0,0-1.9h-1.9a.948.948,0,0,0-.949.949Zm0,2.846a.948.948,0,0,0,.949.949h1.9a.949.949,0,1,0,0-1.9h-1.9a.948.948,0,0,0-.949.949Zm18.972,4.743a.948.948,0,0,0-.949.949v.949H125.346a.949.949,0,0,0-1.619-.671l-.949.949a.948.948,0,0,0,0,1.341l.949.949a.949.949,0,0,0,1.619-.671h20.87a.949.949,0,0,0,.949-.949v-1.9a.949.949,0,0,0-.949-.949ZM123.449,57.247a.948.948,0,0,0,.949-.949V55.35h19.921a.949.949,0,0,0,1.619.671l.949-.949a.948.948,0,0,0,0-1.341l-.949-.949a.949.949,0,0,0-1.619.671h-20.87a.948.948,0,0,0-.949.949v1.9a.948.948,0,0,0,.949.949Z"
                      transform="translate(-122.5 -52.504)"
                      fill="#2e3c83"
                    />
                  </svg>

                  <span className="sidebar-text">Transaction Maintenance</span>
                </div>

                <hr className="px-5 mobile-nav-line my-2" />

                <div
                  className={`d-flex align-items-center px-5 gap-3 py-2 ${window.location.pathname.includes("/lender/under-writer")
                      ? "mobile-sidebar-active-menu"
                      : "mobile-menu-container"
                    }`}
                  onClick={() => onClickActiveMenu("/lender/under-writer")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19.081"
                    height="20.663"
                    viewBox="0 0 19.081 20.663"
                  >
                    <g
                      id="noun-person-2237338"
                      transform="translate(-118.864 -29.7)"
                    >
                      <path
                        className="sidebar-icons"
                        id="Path_467"
                        data-name="Path 467"
                        d="M201.025,36.4a6.7,6.7,0,1,1-6.7-6.7,6.7,6.7,0,0,1,6.7,6.7"
                        transform="translate(-65.918)"
                        fill="#2e3c83"
                      />
                      <path
                        className="sidebar-icons"
                        id="Path_468"
                        data-name="Path 468"
                        d="M121.815,393.2h13.178a2.951,2.951,0,1,0,0-5.9H121.815a2.951,2.951,0,0,0,0,5.9Z"
                        transform="translate(0 -342.839)"
                        fill="#2e3c83"
                      />
                    </g>
                  </svg>

                  <span className="sidebar-text">UnderWriter</span>
                </div>

                <hr className="px-5 mobile-nav-line my-2" />

                <div
                  className={`d-flex align-items-center px-5 gap-3 py-2 ${window.location.pathname.includes("/lender/agreement")
                      ? "mobile-sidebar-active-menu"
                      : "mobile-menu-container"
                    }`}
                  onClick={() => onClickActiveMenu("/lender/agreement")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15.719"
                    height="21.613"
                    viewBox="0 0 15.719 21.613"
                  >
                    <path
                      className="sidebar-icons"
                      id="noun-contract-2850030"
                      d="M173.16,39.057h5.324l-5.324,5.315ZM171.2,44.952h-6.877a.983.983,0,0,1-.983-.983V24.321a.983.983,0,0,1,.983-.983h13.754a.983.983,0,0,1,.983.983V37.092h-6.877a.973.973,0,0,0-.982.982Zm-3.93-16.7a.982.982,0,0,0,.982.982h5.895a.982.982,0,1,0,0-1.965h-5.895a.982.982,0,0,0-.982.982Zm.982,4.912h5.895a.982.982,0,0,0,0-1.965h-5.895a.982.982,0,0,0,0,1.965Z"
                      transform="translate(-163.336 -23.338)"
                      fill="#2e3c83"
                    />
                  </svg>

                  <span className="sidebar-text">Agreement</span>
                </div>

                <hr className="px-5 mobile-nav-line my-2" />

                <div
                  className={`d-flex align-items-center px-5 gap-3 py-2 ${window.location.pathname.includes("/lender/approve-limit")
                      ? "mobile-sidebar-active-menu"
                      : "mobile-menu-container"
                    }`}
                  onClick={() => onClickActiveMenu("/lender/approve-limit")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15.872"
                    height="25.591"
                    viewBox="0 0 15.872 25.591"
                  >
                    <g
                      id="noun-hourglass-2035192"
                      transform="translate(-225.316 -79.844)"
                    >
                      <path
                        className="sidebar-icons"
                        id="Path_506"
                        data-name="Path 506"
                        d="M263.539,293.974l-5.676-4.317-5.563,4.314a1.883,1.883,0,0,0-.735,1.5v2.383h12.726v-2.369a1.908,1.908,0,0,0-.752-1.507Z"
                        transform="translate(-24.571 -196.4)"
                        fill="#2e3c83"
                      />
                      <path
                        className="sidebar-icons"
                        id="Path_507"
                        data-name="Path 507"
                        d="M225.422,79.844h15.659a.114.114,0,0,1,.106.106v2.794a.106.106,0,0,1-.106.106H225.422a.114.114,0,0,1-.106-.106V79.95A.106.106,0,0,1,225.422,79.844Z"
                        fill="#2e3c83"
                      />
                      <path
                        className="sidebar-icons"
                        id="Path_508"
                        data-name="Path 508"
                        d="M225.422,433.12h15.659a.106.106,0,0,1,.106.106v2.794h0a.106.106,0,0,1-.106.106H225.422a.106.106,0,0,1-.106-.106v-2.794A.106.106,0,0,1,225.422,433.12Z"
                        transform="translate(0 -330.691)"
                        fill="#2e3c83"
                      />
                      <path
                        className="sidebar-icons"
                        id="Path_509"
                        data-name="Path 509"
                        d="M250.47,144.381a1.888,1.888,0,0,0,.714,1.479l5.648,4.516,5.65-4.516a1.888,1.888,0,0,0,.715-1.479v-2.189H250.47Z"
                        transform="translate(-23.546 -58.362)"
                        fill="#2e3c83"
                      />
                    </g>
                  </svg>

                  <span className="sidebar-text">Approve Limit</span>
                </div>

                <hr className="px-5 mobile-nav-line my-2" />

                <div
                  className={`d-flex align-items-center px-5 gap-3 py-2 ${window.location.pathname.includes("/lender/purchase-order")
                      ? "mobile-sidebar-active-menu"
                      : "mobile-menu-container"
                    }`}
                  onClick={() => onClickActiveMenu("/lender/purchase-order")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18.858"
                    height="24.001"
                    viewBox="0 0 18.858 24.001"
                  >
                    <g
                      id="noun-order-4924410"
                      transform="translate(-157.5 -35)"
                    >
                      <path
                        className="sidebar-icons"
                        id="Path_502"
                        data-name="Path 502"
                        d="M173.786,70h-.857v1.714a.857.857,0,1,1-1.714,0V70h-3.429v1.714a.857.857,0,1,1-1.714,0V70h-3.429v1.714a.857.857,0,1,1-1.714,0V70h-.857a2.579,2.579,0,0,0-2.572,2.572V89.715a2.579,2.579,0,0,0,2.572,2.572h13.715a2.579,2.579,0,0,0,2.572-2.572V72.572A2.579,2.579,0,0,0,173.786,70Zm-6,17.144h-5.143a.857.857,0,1,1,0-1.714h5.143a.857.857,0,1,1,0,1.714Zm3.429-4.286h-8.572a.857.857,0,1,1,0-1.714h8.572a.857.857,0,1,1,0,1.714Zm0-4.286h-8.572a.857.857,0,1,1,0-1.714h8.572a.857.857,0,1,1,0,1.714Z"
                        transform="translate(0 -33.285)"
                        fill="#2e3c83"
                      />
                      <path
                        className="sidebar-icons"
                        id="Path_503"
                        data-name="Path 503"
                        d="M229.214,35.857a.857.857,0,1,0-1.714,0v.857h1.714Z"
                        transform="translate(-66.571)"
                        fill="#2e3c83"
                      />
                      <path
                        className="sidebar-icons"
                        id="Path_504"
                        data-name="Path 504"
                        d="M439.214,35.857a.857.857,0,1,0-1.714,0v.857h1.714Z"
                        transform="translate(-266.285)"
                        fill="#2e3c83"
                      />
                      <path
                        className="sidebar-icons"
                        id="Path_505"
                        data-name="Path 505"
                        d="M334.214,35.857a.857.857,0,1,0-1.714,0v.857h1.714Z"
                        transform="translate(-166.428)"
                        fill="#2e3c83"
                      />
                    </g>
                  </svg>

                  <span className="sidebar-text">Purchase Order</span>
                </div>

                <hr className="px-5 mobile-nav-line my-2" />

                <div
                  className={`d-flex align-items-center px-5 gap-3 py-2 ${window.location.pathname.includes("/lender/report")
                      ? "mobile-sidebar-active-menu"
                      : "mobile-menu-container"
                    }`}
                  onClick={() => onClickActiveMenu("/lender/report")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18.028"
                    height="22.361"
                    viewBox="0 0 18.028 22.361"
                  >
                    <path
                      className="sidebar-icons"
                      id="Path_510"
                      data-name="Path 510"
                      d="M140.247,0h-7.686V5.765a2.565,2.565,0,0,1-2.551,2.551h-5.765V20.334a2.028,2.028,0,0,0,2.026,2.026h13.975a2.028,2.028,0,0,0,2.026-2.026V2.027A2.028,2.028,0,0,0,140.247,0Zm-11.32,19.845h-1.363v-4.4h1.363Zm3.354,0h-1.363V13.8h1.363Zm3.319,0h-1.363V12.123H135.6Zm3.319,0h-1.363v-9.4h1.363Zm1.328-12.718h-5.415V5.765h5.415Zm0-3.214h-5.415V2.551h5.415Z"
                      transform="translate(-124.245)"
                      fill="#2e3c83"
                    />
                  </svg>

                  <span className="sidebar-text">Reports</span>
                </div>

                <hr className="px-5 mobile-nav-line my-2" />
              </div>
            </div>
          )}
        </div>
        {!mobileNavbarStatus && (
          <div className="min-vh-100 p-2" style={{ overflowY: 'scroll', border: '3px solid green', paddingBottom: '30px' }}>
            {window.innerWidth < 768 && outlet}
            <footer className="text-center " style={{fontSize:"0.8rem"}}>
              <div>Copyright &copy; 2023 Aton</div>
            </footer>
          </div>
        )}
      </div>
    </>
  );
};

export default LenderLayout;
