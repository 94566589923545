import { HiOutlineArrowNarrowDown } from "react-icons/hi";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ReusableModal from "../../../layout/GenericLayout/ReusableModal";
import { BsPencil } from "react-icons/bs";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { getIsEmailRegistered } from "../../../store/slices/generic";
import {
  deleteRelationshipManager,
  getAllRelationshipManager,
  updateRelationshipManager,
} from "../../../store/slices/admin";
import { toastMessage } from "../../../layout/GenericLayout/ToastMessage";
import Loader from "../../../layout/GenericLayout/Loader";
import { RxCrossCircled } from "react-icons/rx";

/* MODAL/CHILDREN COMPONENT */
const EditRMModal = ({ toggleModal, activeEditData, onRefreshTable }) => {
  /* FUNCTION DECLARATION */
  const dispatch = useDispatch();

  /* USESTATES */
  const [editRMDetailsFields, setAddRMDetailsFields] = useState({
    name: "",
    emailId: "",
    address: "",
    mobileNumber: "",
  });
  const [ isModified, setIsModified ] = useState(false);

  /* USESELECTORS */
  const isEmailRegisteredData = useSelector(
    (state) => state.generic.isEmailRegisteredData
  );
  const relationshipManagerEditData = useSelector(
    (state) => state.admin.relationshipManagerEditData
  );

  /* USEEFFECTS */

  useEffect(() => {
    setAddRMDetailsFields((prev) => ({
      ...prev,
      name: activeEditData.name,
      emailId: activeEditData.emailId,
      address: activeEditData.address,
      mobileNumber: activeEditData.mobileNumber,
    }));
  }, [activeEditData]);

  /* EVENT LISTENERS */
  const onChangeAddRMDetailsFields = (event) => {
    setIsModified(true);
    const name = event.target.name;
    const value = event.target.value;
    setAddRMDetailsFields((prev) => ({ ...prev, [name]: value }));
  };

  const onBlurAddRMDetailsFields = (event) => {
    const name = event.target.name;
    const validity = event.target.checkValidity();

    if (name === "emailId" && validity) {
      dispatch(getIsEmailRegistered(editRMDetailsFields.emailId))
        .unwrap()
        .then((response) => {
          toastMessage(response.success, "success");
        })
        .catch((error) => {
          toastMessage(error.message, "error");
        });
    }
  };

  const onSubmitRMAddDetails = (event) => {
    event.preventDefault();
    dispatch(updateRelationshipManager(editRMDetailsFields))
      .unwrap()
      .then((response) => {
        onRefreshTable();
        toastMessage(response.success, "success");
        toggleModal();
      })
      .catch((error) => {
        toastMessage(error.message, "error");
      });
  };

  return (
    <>
      {relationshipManagerEditData.isLoading && (
        <div className="blur-loader-container">
          <Loader />
        </div>
      )}
      <form className="" onSubmit={onSubmitRMAddDetails}>
        <span className="fs-4 fw-semibold">
          Edit Relationship Manager Details
        </span>
        <div className="details-container container mt-4">
          <div className="row gy-md-4 gy-2">
            <div className="kyc-fields-container col-12  col-md-6">
              <label htmlFor="name" className="kyc-label-text form-label">
                Name
              </label>
              <input
                required
                onChange={onChangeAddRMDetailsFields}
                id="name"
                name="name"
                type="text"
                className="kyc-input-field form-control"
                value={editRMDetailsFields.name}
              />
            </div>

            <div className="kyc-fields-container col-12  col-md-6">
              <label htmlFor="pan" className="kyc-label-text form-label">
                Email ID
              </label>
              <input
                disabled
                onBlur={onBlurAddRMDetailsFields}
                required
                onChange={onChangeAddRMDetailsFields}
                id="emailId"
                type="email"
                name="emailId"
                className="kyc-input-field form-control"
                value={editRMDetailsFields.emailId}
              />
            </div>

            <div className="kyc-fields-container col-12  col-md-6">
              <label htmlFor="email" className="kyc-label-text form-label">
                Address
              </label>
              <input
                required
                onChange={onChangeAddRMDetailsFields}
                id="address"
                type="text"
                name="address"
                className="kyc-input-field form-control"
                value={editRMDetailsFields.address}
              />
            </div>

            <div className="kyc-fields-container col-12  col-md-6">
              <label
                htmlFor="mobileNumber"
                className="kyc-label-text form-label"
              >
                Mobile Number
              </label>
              <input
                required
                onChange={onChangeAddRMDetailsFields}
                id="mobileNumber"
                type="tel"
                name="mobileNumber"
                className="kyc-input-field form-control"
                value={editRMDetailsFields.mobileNumber}
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end gap-4 mt-5">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={toggleModal}
          >
            Back
          </button>
          <button type="submit" className="btn btn-success"
           onClick={ () => isModified && onSubmitRMAddDetails() }
           style={{
            backgroundColor: isModified ? "green" : "gray",
          }}>
            Update
          </button>
        </div>
      </form>
    </>
  );
};

const DeleteRMModal = ({ toggleModal, activeDeleteData, onRefreshTable }) => {
  /* FUNCTION DECLARATIONS */
  const dispatch = useDispatch();

  /* USESELECTOR */
  const relationshipManagerDeleteData = useSelector(
    (state) => state.admin.relationshipManagerDeleteData
  );
  /* EVENT LISTENERS */
  const onClickCancel = () => {
    toggleModal();
  };
  const onClickDelete = () => {
    const body = {
      emailId: activeDeleteData.emailId,
    };
    console.log(body);
    dispatch(deleteRelationshipManager(body))
      .unwrap()
      .then((response) => {
        onRefreshTable();
        toastMessage(response.success, "success");
        toggleModal();
      })
      .catch((error) => {
        console.log("error : ", error);
        toastMessage(error.message, "error");
      });
  };
  return (
    <>
      {relationshipManagerDeleteData.isLoading && (
        <div className="blur-loader-container">
          <Loader />
        </div>
      )}
      <div className="d-flex justify-content-center align-items-center flex-column gap-4 p-4">
        <div className="d-flex justify-content-center align-items-center">
          <RxCrossCircled className="text-danger" size={100} />
        </div>
        <div className="d-flex justify-content-center align-items-center flex-column gap-2">
          <h2 className="fw-semibold">Are you sure?</h2>
          <span className="fs-5 text-secondary text-center">
            Do you really want to delete this records? <br /> This process
            cannot be undone.
          </span>
        </div>
        <div className="d-flex justify-content-center align-items-center gap-3">
          <button onClick={onClickCancel} className="btn btn-secondary">
            Cancel
          </button>
          <button onClick={onClickDelete} className="btn btn-danger">
            Delete
          </button>
        </div>
      </div>
    </>
  );
};

/* PARENT COMPONENT */
const AdminRMDetailsTable = (props) => {
  const navigate = useNavigate();
  const [activeEditData, setActiveEditData] = useState([]);
  const [activeDeleteData, setActiveDeleteData] = useState([]);

  /* TABLE OPTIONS */
  const totalPage = 5;

  /* MODALS USESTATE */
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  /* MODALS TOGGLE */
  const toggleEditModal = () => {
    setShowEditModal((prev) => !prev);
  };
  const toggleDeleteModal = () => {
    setShowDeleteModal((prev) => !prev);
  };

  const onClickEdit = (data) => {
    setActiveEditData(data);
    toggleEditModal();
  };

  const onClickDelete = (data) => {
    setActiveDeleteData(data);
    toggleDeleteModal();
  };
  console.log("props", props);
  return (
    <>
      {/* MODALS */}
      {showEditModal && (
        <ReusableModal
          showModal={showEditModal}
          toggleModal={toggleEditModal}
          modalContent={
            <EditRMModal
              activeEditData={activeEditData}
              toggleModal={toggleEditModal}
              onRefreshTable={props.onRefreshTable}
            />
          }
          modalSizeClass="modal-lg"
        />
      )}
      {showDeleteModal && (
        <ReusableModal
          showModal={showDeleteModal}
          toggleModal={toggleDeleteModal}
          modalContent={
            <DeleteRMModal
              activeDeleteData={activeDeleteData}
              toggleModal={toggleDeleteModal}
              onRefreshTable={props.onRefreshTable}
            />
          }
          modalSizeClass="modal-lg"
        />
      )}
      <div className="table-container">
        <table className={props.className}>
          <thead>
            <tr>
              {props.tableHeader.map((eachHeader) => (
                <th key={eachHeader} scope="col">
                  {eachHeader}
                </th>
              ))}
            </tr>
          </thead>
          {props.getAllRelationshipManagerMetaData?.paging?.total<0?(<div className="no-data text-danger">Oops! there is no data to show. Please check back later</div>):null} 

          <tbody>
            {props.tableRows === "No Relation manager Found" ? "" : props.tableRows.map((eachRows) => (
              <tr key={eachRows.mobileNumber} scope="col">
                <td>{eachRows.name}</td>
                <td>{eachRows.emailId}</td>
                <td>{eachRows.mobileNumber}</td>
                <td>{eachRows.address}</td>
                <td>
                  <span
                    onClick={() => onClickEdit(eachRows)}
                    className="text-capitalize mx-1 text-primary highlight-btn"
                    type="button"
                  >
                    <BsPencil />
                  </span>
                  {/* <span
                    onClick={() => onClickDelete(eachRows)}
                    className="text-capitalize mx-1 text-danger danger-btn"
                    type="button"
                  >
                    <RiDeleteBin6Line />
                  </span> */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* <div className="d-flex justify-content-end px-2">
          <span
            // onClick={props.onClickViewMoreTableData}
            className="d-flex align-items-center gap-1 my-3 view-more-text"
          >
            View more <HiOutlineArrowNarrowDown className="fs-5" />
          </span>
        </div> */}
      </div>
      {/* <div className="d-flex align-items-center gap-2 page-data-text-container mt-3 px-3">
        <AiOutlineDoubleLeft
        // onClick={props.onClickPreviousPage}
        />
        <span className="page-data-text">
          Page {} of {totalPage}
        </span>
        <AiOutlineDoubleRight
        // onClick={props.onClickNextPage}
        />
      </div> */}
    </>
  );
};

export default AdminRMDetailsTable;
