
/**
 *
 * @param {showModal, toggleModal, modalContent, modalSizeClass, msgType, noBtn} param0
 * @returns
 */

const ReusableMessageModal = ({
  showModal,
  toggleModal,
  modalContent,
  modalSizeClass,
  msgType,
  noBtn
}) => {
  console.log(modalContent.props.message)
  return (
    <div
      className={`modal msg-modal fade ${showModal ? "show" : ""}`}
      style={{
        display: showModal ? "block" : "none",
      }}
    >
      <div
        className={`modal-dialog modal-dialog-centered  ${modalSizeClass}`}
        role="document"
      >
        <div
          className="modal-content shadow modalblur"
          style={{
            borderRadius: "6px",
            borderWidth: "0",
          }}
        >
          <div
            className="modal-body p-0"
            style={{ fontSize: "16px", overflow: "auto", position: "relative" }}
          >
            {/* <button
              className="p-1 d-flex justify-content-end align-items-center rounded-circle"
              type="button"
              aria-hidden="true"
              onClick={toggleModal}
              style={{
                color: "#ffffff",
                cursor: "pointer",
                backgroundColor: "#F5DBDB",
                color: "red",
                outline: "none",
                fontSize: "16px",
                borderWidth: "0",
                // float: "right",
                position: "absolute",
                right: "4px",
                top: "4px",
                height: "fit-content",
              }}
            >
              <AiOutlineClose style={{ color: "red", fontSize: "18px" }} />
            </button> */}
            <div className="mt-0 mb-2">

 
            {
              msgType === 'alert' && <div className="msg-wrapper ">
              <svg 
              xmlns="http://www.w3.org/2000/svg" 
              viewBox="0 0 512 512"
              className="alerter m-5"
              >
               <path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/>
               <rect
                  className="alerter__circle"
                  cx="50"
                  cy="50"
                  r="50"
                  fill="none"
                />
                <path
                  className="alerter__check"
                  fill="none"
                  d="M14.1 27.2l7.1 7.2 16.7-16.8"
                />
              </svg>
              </div>
            }
            {
              msgType === 'ok' &&        
              <div className="msg-wrapper ">
              <svg
                className="checkmark"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 52 52"
              >
                <circle
                  className="checkmark__circle"
                  cx="26"
                  cy="26"
                  r="25"
                  fill="none"
                />
                <path
                  className="checkmark__check"
                  fill="none"
                  d="M14.1 27.2l7.1 7.2 16.7-16.8"
                />
              </svg>
              </div>
            }
              {/* <p>sss</p> */}
            {modalContent}
            {
              noBtn ? <></>:<>
            <div className="d-flex justify-content-center align-items-center mt-5">
            <button
              className="btn btn-success px-4 py-1 font-weight-bold"
              type="button"
              aria-hidden="true"
              onClick={toggleModal}
            >
              {'ok'}
            </button>
            </div>
              </>
            }

            
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReusableMessageModal;
