import { BuyerKycActions } from "../actionTypes";
// eslint-disable-next-line no-unused-vars
import { BuyerKYCFormTemplate } from "../buyerKycContext";

export const actions = BuyerKycActions.COMPANY_DETAILS;

/**
 * @param { typeof BuyerKYCFormTemplate.companyDetails } state 
 * @param { {type: typeof actions, payload: string | BufferSource} } param1 
 * @returns { typeof BuyerKYCFormTemplate.companyDetails }
 */
export function CompanyDetailsReducer(state, { type, payload }) {
	
	if (type === actions.LOGO_UPDATE_API) {
		return { ...state, logo: { ...state.logo, fileList: payload ?? [], isVerified: true, isTouched: true } };
	}

	else if (type === actions.CHANGE_COMPANY_NAME) {
		const value = payload ?? state.companyName.value;
		return { ...state, companyName: { value, isTouched: true, isValid: value.length > 3 } };
	}

	else if (type === actions.BLUR_COMPANY_NAME) {
		return { ...state, companyName: { ...state.companyName, isTouched: true }};
	}

	else if (type === actions.CHANGE_COMPANY_TYPE) {
		const value = payload ?? state.companyType.value;
		return { ...state, companyType: { value, isTouched: true, isValid: value !== "" }};
	}

	else if (type === actions.BLUR_COMPANY_TYPE) {
		return { ...state, companyType: { ...state.companyType, isTouched: true } };
	}
	
	else if (type === actions.CHANGE_DISTRIBUTOR) {
		const value = payload ?? state.companyType.value;
		return { ...state, distributor: { value, isTouched: true, isValid: value !== "" }};
	}

	else if (type === actions.BLUR_DISTRIBUTOR) {
		return { ...state, distributor: { ...state.distributor, isTouched: true } };
	}

	else if (type === actions.CHANGE_PAN) {
		const value = payload ?? state.panNumber.value;
		return { ...state, panNumber: { isVerified: false, value, isTouched: true, isValid: value.length === 10 } };
	}
	
	else if (type === actions.BLUR_PAN) {
		return { ...state, panNumber: { ...state.panNumber, isTouched: true, isVerified: payload ?? false } };
	}

	else if (type === actions.CHANGE_COMPANY_ADDRESS) {
		const value = payload ?? state.companyAddress.value;
		return { ...state, companyAddress: { value, isTouched: true, isValid: value.length > 5 } };
	}
	
	else if (type === actions.BLUR_COMPANY_ADDRESS) {
		return { ...state, companyAddress: { ...state.companyAddress, isTouched: true } };
	}

	else if (type === actions.CHANGE_CITY) {
		const value = payload ?? state.city.value;
		return { ...state, city: { value, isTouched: true, isValid: false } }
	}

	else if (type === actions.BLUR_CITY) {
		const pattern = /^[A-Za-z]{3,}$/g;
		
		return { ...state, city: { ...state.city, isTouched: true, isValid: pattern.test(state.city.value), } };
	}
	
	else if (type === actions.CHANGE_STATE) {
		const value = payload ?? state.state.value;
		return { ...state, state: { value, isTouched: true, isValid: value.length > 3 } }
	}

	else if (type === actions.BLUR_STATE) {
		return { ...state, state: { ...state.state, isTouched: true } };
	}
	
	
	else if (type === actions.CHANGE_COUNTRY) {
		const value = payload ?? state.country.value;
		return { ...state, country: { value, isTouched: true, isValid: value.length > 3 } }
	}

	else if (type === actions.BLUR_COUNTRY) {
		return { ...state, country: { ...state.country, isTouched: true } };
	}
	
	else if (type === actions.CHANGE_PIN) {
		const value = payload ?? state.pinCode.value;
		return { ...state, pinCode: { value, isTouched: true, isValid: value.length === 6 } }
	}

	else if (type === actions.BLUR_PIN) {
		return { ...state, pinCode: { ...state.pinCode, isTouched: true } };
	}

	else if (type === actions.CHANGE_REGION) {
		const value = payload ?? state.region.value;
		return { ...state, region: { value, isTouched: true, isValid: value.length !== "" } }
	}

	else if (type === actions.BLUR_REGION) {
		return { ...state, region: { ...state.region, isTouched: true } };
	}

	else if (type === actions.CHANGE_STORE_DETAILS) {
		const value = payload ?? state.storeDetails.value;

		return { ...state, storeDetails: { value, isValid: value.length > 5, isTouched: true } };
	}

	else if (type === actions.BLUR_STORE_DETAILS) {
		const value = state.storeDetails.value.trim();
		return { ...state, storeDetails: { ...state.storeDetails, isTouched: true, value, isValid: value.length > 5, } };
	}

	else if (type === actions.TOUCH_ALL) {
		for (let item in state) {
			for (let obj in state[item]) {
				if (obj === "isTouched") {
					state[item][obj] = true;
				}
			}
		}
		return { ...state };
	}
	
	return state;
}