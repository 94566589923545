import { BsSearch } from "react-icons/bs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../layout/GenericLayout/Loader";
import ChannelCard from "./ChannelCard";
import {
  totalApprovedtUsers,
  totalOnboardUsers,
  totalRejectUsers,
} from "../../store/slices/channel";
import {Helmet} from "react-helmet";

/* PARENT COMPONENT */
const ChannelDashboard = () => {
  /* FUNCTION DECLARATIONS */
  const dispatch = useDispatch();

  /* USESELECTOR */
  const totalRejectUsersData = useSelector(
    (state) => state.channel.totalRejectUsersData
  );

  const totalApprovedUsersData = useSelector(
    (state) => state.channel.totalApprovedUsersData
  );

  const totalOnBoardUsersData = useSelector(
    (state) => state.channel.totalOnBoardUsersData
  );

  /* USESTATES */
  const [search, setSearch] = useState("");

  /* USEEFFECTS */
  useEffect(() => {
    dispatch(totalRejectUsers());
    dispatch(totalApprovedtUsers());
    dispatch(totalOnboardUsers());
  }, [dispatch]);

  /* EVENT LISTENERS */
  const onChangeSearch = (event) => {
    setSearch(event.target.value);
  };

  console.log("totalRejectUsersData", totalRejectUsersData);

  return (
    <div className="d-flex flex-column gap-4">
       <Helmet>
                <title>Dashboard</title>
            </Helmet>
      <div className="bg-white admin-dashboard-card-container px-3 pt-3">
        <div className="row">
          <div className="col-12 col-md-6 col-xl-4 mb-3">
            <ChannelCard
              title="Total Onboarded"
              count={totalOnBoardUsersData?.data?.success}
              status="200+ lastweek"
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25.166"
                  height="28.907"
                  viewBox="0 0 25.166 28.907"
                >
                  <g
                    id="noun-files-3972654"
                    transform="matrix(-1, -0.017, 0.017, -1, 97.085, 60.505)"
                  >
                    <path
                      id="Path_517"
                      data-name="Path 517"
                      d="M153.217,32.852v20.88a1.75,1.75,0,0,1-1.75,1.74H140.092a.87.87,0,0,1-.618-.256l-7-6.96a.87.87,0,0,1-.257-.614V32.851a1.75,1.75,0,0,1,1.75-1.74h17.5a1.75,1.75,0,0,1,1.75,1.74Z"
                      transform="translate(-59.332 -0.78)"
                      fill="#2e3c83"
                    />
                    <path
                      className="sidebar-icons"
                      id="Path_518"
                      data-name="Path 518"
                      d="M243.86,124.45v21.96h-18.3v1.83h18.3a1.835,1.835,0,0,0,1.83-1.83V124.45Z"
                      transform="translate(-147.979 -89.431)"
                      fill="#2e3c83"
                    />
                  </g>
                </svg>
              }
              value=""
            />
          </div>
          <div className="col-12 col-md-6 col-xl-4 mb-3">
            <ChannelCard
              title="Total Approved"
              count={totalApprovedUsersData?.data?.success}
              status="200+ lastweek"
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25.166"
                  height="28.907"
                  viewBox="0 0 25.166 28.907"
                >
                  <g
                    id="noun-files-3972654"
                    transform="matrix(-1, -0.017, 0.017, -1, 97.085, 60.505)"
                  >
                    <path
                      id="Path_517"
                      data-name="Path 517"
                      d="M153.217,32.852v20.88a1.75,1.75,0,0,1-1.75,1.74H140.092a.87.87,0,0,1-.618-.256l-7-6.96a.87.87,0,0,1-.257-.614V32.851a1.75,1.75,0,0,1,1.75-1.74h17.5a1.75,1.75,0,0,1,1.75,1.74Z"
                      transform="translate(-59.332 -0.78)"
                      fill="#2e3c83"
                    />
                    <path
                      className="sidebar-icons"
                      id="Path_518"
                      data-name="Path 518"
                      d="M243.86,124.45v21.96h-18.3v1.83h18.3a1.835,1.835,0,0,0,1.83-1.83V124.45Z"
                      transform="translate(-147.979 -89.431)"
                      fill="#2e3c83"
                    />
                  </g>
                </svg>
              }
              value=""
            />
          </div>
          <div className="col-12 col-md-6 col-xl-4 mb-3">
            <ChannelCard
              title="Total Rejected"
              count={totalRejectUsersData?.data?.success}
              status="200+ lastweek"
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25.166"
                  height="28.907"
                  viewBox="0 0 25.166 28.907"
                >
                  <g
                    id="noun-files-3972654"
                    transform="matrix(-1, -0.017, 0.017, -1, 97.085, 60.505)"
                  >
                    <path
                      id="Path_517"
                      data-name="Path 517"
                      d="M153.217,32.852v20.88a1.75,1.75,0,0,1-1.75,1.74H140.092a.87.87,0,0,1-.618-.256l-7-6.96a.87.87,0,0,1-.257-.614V32.851a1.75,1.75,0,0,1,1.75-1.74h17.5a1.75,1.75,0,0,1,1.75,1.74Z"
                      transform="translate(-59.332 -0.78)"
                      fill="#2e3c83"
                    />
                    <path
                      className="sidebar-icons"
                      id="Path_518"
                      data-name="Path 518"
                      d="M243.86,124.45v21.96h-18.3v1.83h18.3a1.835,1.835,0,0,0,1.83-1.83V124.45Z"
                      transform="translate(-147.979 -89.431)"
                      fill="#2e3c83"
                    />
                  </g>
                </svg>
              }
              value=""
            />
          </div>
        </div>
      </div>

      {/* <div className="d-flex justify-content-between flex-wrap px-2 gap-2">
        <span className="dashboard-content-title">Application Details</span>
        <div className="search-container d-flex align-items-center py-1 px-3 col-12 col-sm-4 ">
          <input
            className="search-input-element d-flex flex-grow-1"
            type="text"
            placeholder="Search"
            onChange={onChangeSearch}
            value={search}
          />{" "}
          <BsSearch className="fs-5" />
        </div>
      </div> */}
    </div>
  );
};

export default ChannelDashboard;
