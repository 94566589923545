import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getInventoryTable,
  getMaterialRequestById,
  updateMaterialRequestAddById,
  updateMaterialRequestRejectById,
} from "../../../store/slices/anchor";
import { nanoid } from "@reduxjs/toolkit";
import { InputValidator } from "../../../layout/GenericLayout/InputValidator";
import { BsCheck, BsCheck2, BsPencil } from "react-icons/bs";
import { RiDeleteBin6Line } from "react-icons/ri";
import ReusableModal from "../../../layout/GenericLayout/ReusableModal";
import { toastMessage } from "../../../layout/GenericLayout/ToastMessage";

/* MODALS OR CHILDREN COMPONENTS */
const RejectModal = ({ toggleModal, data, onClickBack }) => {
  const dispatch = useDispatch();
  const [reasonText, setReasonText] = useState("");

  const onChangeReason = (event) => {
    setReasonText(event.target.value);
  };

  const onClickSubmitReasonText = () => {
    const rejectBody = {
      buyerId: data.buyerId,
      mrId: data.mrId,
      remarks: reasonText,
      sellerId: data.sellerId,
      status: "Rejected",
    };

    // //console.log(rejectBody);
    dispatch(updateMaterialRequestRejectById(rejectBody))
      .unwrap()
      .then((response) => {
        toastMessage(response.success, "success");
        toggleModal();
        onClickBack();
      })
      .catch((error) => {
        toastMessage(error.message, "error");
      });
  };
  return (
    <>
      <span className="fs-4 fw-semibold">Reject Reason</span>
      <div className="d-flex flex-column px-4">
        <div className="kyc-fields-container w-100">
          <label htmlFor="rejectReason" className="kyc-label-text form-label">
            Reason
          </label>

          <input
            onChange={onChangeReason}
            id="rejectReason"
            type="text"
            className={`kyc-input-field form-control`}
            value={reasonText}
          />
        </div>

        <div className="d-flex flex-column flex-md-row align-items-center justify-content-end my-3">
          <button
            onClick={onClickSubmitReasonText}
            className="px-4 py-1 rounded-pill btn btn-success"
          >
            Submit
          </button>
        </div>
      </div>
    </>
  );
};

/* PARENT COMPONENT */
const AnchorMaterialRequestTableById = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { buyerId, mrId } = useParams();
  const materialRequestByIdData = useSelector(
    (state) => state.anchor.materialRequestByIdData
  );
  // const [itemDetailsTableData, setItemDetailsTableData] = useState([]);
  const [isError, setIsError] = useState({});
  const [transportFields, setTransportFields] = useState({});
  // const [isEdit, setIsEdit] = useState(false);
  const [tableEditData, setTableEditData] = useState([]);
  const [showRejectModal, setShowRejectModal] = useState(false);

  const shipmentTermsOptions = [
    { name: "Cost & Freight(CNF)", id: nanoid() },
    { name: "Free On-Board(FOB)", id: nanoid() },
    { name: "Cost Insurance & Freight(CIF)", id: nanoid() },
  ];

  const modeOfTransportOptions = [
    { name: "Courier", id: nanoid() },
    { name: "Rail", id: nanoid() },
    { name: "Road", id: nanoid() },
    { name: "Air", id: nanoid() },
  ];

  //console.log("**********************************",tableEditData)

  useEffect(() => {
    dispatch(getInventoryTable())
      .unwrap()
      .then((inventoryResponse) => {
        //console.log("inv response : ", inventoryResponse.items);
        dispatch(getMaterialRequestById({ buyerId, mrId }))
          .unwrap()
          .then((response) => { 
            // //console.log("res ; ", response?.items[0].deliveryLocation);
            setTransportFields((prev) => ({
              ...prev,
              locationOfDelivery: response?.items[0]?.deliveryLocation,
              requiredDateOfDelivery: response?.items[0]?.requiredDate,
            }));
            const newItemDetails = response?.items[0]?.itemsDetails.map(
              (eachData, index) => {
                // console.log("This is Each Data ->  ", eachData)
                // //console.log("Model Number",)
                const activeInventory = inventoryResponse.items.find(
                  (element) => element.HSN === eachData.HSN
                );

                //console.log("newUnitPrice : ", activeInventory);
                return {
                  id: index,
                  unitPrice: parseFloat(activeInventory.unitPrice) || 0,
                  totalAmount: 0,
                  itemAvailability: true,
                  modelNumber: eachData?.modelNumber,
                  item:eachData?.item,
                  quantityOrdered: eachData?.quantity,
                  disabled: true,
                  quantityAvailable: "",
                  tax: "",
                  gst: "",
                };
              }
            );
            // setItemDetailsTableData(newItemDetails);
            setTableEditData(newItemDetails);
          })
          .catch((error) => {
            //console.log("error : ", error);
            toastMessage(error.message, "error");
          });
      })
      .catch((error) => {
        //console.log("error : ", error);
      });
  }, []);

  // //console.log("state of itemDetailsTableData: ",itemDetailsTableData)

  const onBlurInputFeilds = (event) => {
    const name = event.target.id;
    const value = event.target.value;

    // //console.log(name, value);

    const validator = () => {
      return {
        regex: /^.+$/,
        message: "Field is required",
      };
    };

    const errorMessgae = InputValidator({
      regex: validator().regex,
      value,
      message: validator().message,
    });

    setIsError((prev) => ({
      ...prev,
      [name]: errorMessgae,
    }));
  };

  const onChangeTransportDetails = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const type = event.target.type;
    // //console.log("type : ", parseInt(value));
    setTransportFields((prev) => ({
      ...prev,
      [name]: type === "number" ? parseInt(value) : value,
    }));
  };

  const onClickDelete = () => {};

  const onClickEdit = (index) => {
    let temp = [...tableEditData];
    temp[index].disabled = !temp[index].disabled;
    setTableEditData(temp);
  };

  const onChangeEditTableData = (event, index) => {
    const name = event.target.name;
    const value = event.target.value;
    let temp = [...tableEditData];

    if (name === "quantityAvailable") {
      temp[index].quantityAvailable = parseInt(value) || 0;
      temp[index].tax = parseInt(value) || 0;
    } else if (name === "gst") {
      temp[index].gst = parseInt(value) || 0;
      temp[index].tax = parseInt(value) || 0;
    }

    const quantityAvailable = +temp[index]?.quantityAvailable;
    const quantityOrdered = +temp[index]?.quantityOrdered;

    let newTotalAmount =
      +temp[index].unitPrice * (quantityAvailable < quantityOrdered ? quantityAvailable : quantityOrdered);

    newTotalAmount = newTotalAmount + newTotalAmount * (temp[index].gst / 100);

    temp[index].totalAmount = newTotalAmount;

    setTableEditData(temp);
    // //console.log(tableEditData);
  };

  const onClickGenerateProformaInvoice = () => {
    // toastMessage("got it", "success");
    // const newItemDetails =
    // //console.log("---------------------------------Clicked Geenarte PO")
    const { disabled, ...restAllProperties } = tableEditData[0];
    const body = {
      ...transportFields,
      mrId,
      buyerId,
      // itemDetails: [restAllProperties],
      itemDetails: tableEditData,
    };

    // console.log(transportFields);
    // ////console.log("gen : ", tableEditData);
    // console.log(restAllProperties, tableEditData);

    const transportFieldsName=["shippingTerms","modeOfTransport", "paymentTerms","expectedDateOfDelivery"];

    transportFieldsName.forEach((each)=>{
      // console.log(transportFields[each],"transportFields[each]")
      if(transportFields[each]===undefined){
        console.log(each);
        setIsError((prev) => ({
          ...prev,
          [each]: "Field is required",
        }));
        console.log(isError);
      }
    });

    

    if (
      restAllProperties?.totalAmount !== 0 &&
      restAllProperties?.quantityAvailable !== "" &&
      restAllProperties?.gst !== "" &&
      restAllProperties?.tax !== ""
    ) {
      console.log(isError);
      console.log("Verified!!!!!!!!!!!!!!!!!");
      dispatch(updateMaterialRequestAddById(body))
        .unwrap()
        .then((response) => {
          //console.log(response);
          toastMessage(response.success, "success");
          onClickBack();
        })
        .catch((error) => {
          toastMessage(error.message, "error");
          //console.log("gen : ", body);
        });
    } else {
      if(restAllProperties?.quantityAvailable===''){
        toastMessage("quantityAvailable is required","error");

      }else if(restAllProperties?.gst===''){
        toastMessage("GST% is required","error");

      }else{
        toastMessage("Items Information is not correct", "error");
      }
    }
  };

  const onClickRejectInvoice = () => {
    setShowRejectModal((prev) => !prev);
  };

  const onClickBack = () => {
    navigate(-1, { replace: true });
  };

  const handlePrintPage = () => {
    let billOfExchange = document.getElementById(
      "print-lender-material-request-byID"
    );
    let w = window.document.open("", "", "height=1000,width=1000");
    w.document.write(billOfExchange.innerHTML);
    w.print();
    // w.save();
    w.document.close();
    // window.print()
    // window.save();
  };

  return (
    <>
      {showRejectModal && (
        <ReusableModal
          showModal={showRejectModal}
          toggleModal={onClickRejectInvoice}
          modalContent={
            <RejectModal
              toggleModal={onClickRejectInvoice}
              onClickBack={onClickBack}
              data={{
                buyerId: materialRequestByIdData.data?.items[0]?.buyerId,
                mrId: materialRequestByIdData.data?.items[0]?.mrId,
                sellerId: materialRequestByIdData.data?.items[0]?.sellerId,
              }}
            />
          }
          modalSizeClass="modal-lg"
        />
      )}

      <div
        className="bg-white admin-dashboard-card-container p-3"
        id="print-lender-material-request-byID"
      >
        <div className="d-flex justify-content-between align-items-center flex-wrap px-2 gap-2 py-2">
          <span className="dashboard-content-title">
            Material Request Details
          </span>
          <button
            className="modal-export-pdf-button d-flex align-items-center gap-2"
            onClick={handlePrintPage}
          >
            Export to PDF
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 25"
            >
              <path
                id="Path_541"
                data-name="Path 541"
                d="M207.639,120.656l3.949-4.032a1.18,1.18,0,0,0-1.667-1.671l-1.352,1.361c-.308.31-.557.208-.557-.227V102.8a1.178,1.178,0,1,0-2.356,0v13.291c0,.434-.249.537-.557.227l-1.352-1.361a1.18,1.18,0,0,0-1.667,1.671l3.908,4.026a1.145,1.145,0,0,0,1.651.005Zm-8.464-6.96a1.379,1.379,0,0,0,1.95-1.95,8.074,8.074,0,1,1,11.419,0,1.379,1.379,0,0,0,1.95,1.95,10.832,10.832,0,1,0-15.318,0Z"
                transform="translate(-196.003 -95.205)"
                fill="#fff"
                fillRule="evenodd"
              />
            </svg>
          </button>
        </div>

        <div className="d-flex flex-column align-items-center my-4 details-page-container">
          <div className="d-flex flex-column details-header">
            Items Information
          </div>
          <div className="p-3 w-100">
            <div className="details-page-table-container">
              <table className="details-page-table">
                <thead>
                  <tr>
                    <th scope="col">Item</th>
                    <th scope="col">Model Number</th>
                    <th scope="col">Quantity Ordered</th>
                    <th scope="col">Available Quantity</th>
                    <th scope="col">Price/Unit (₹)</th>
                    <th scope="col">GST (%)</th>
                    <th scope="col">Total Amount</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>

                <tbody>
                  {tableEditData.map((eachRows, index) => (
                    <tr key={eachRows?.modelNumber}>
                      <td>{eachRows?.item}</td>
                      <td>{eachRows?.modelNumber}</td>
                      <td>{eachRows?.quantityOrdered}</td>
                      <td>
                        <input
                          disabled={eachRows?.disabled}
                          onChange={(event) => {
                            onChangeEditTableData(event, index);
                          }}
                          name={"quantityAvailable"}
                          className="kyc-input-field form-control w-50 m-auto"
                          value={eachRows?.quantityAvailable}
                          placeholder="Quantity*"
                        />
                      </td>
                      <td>{eachRows?.unitPrice.toFixed(2)}</td>
                      <td>
                        <input
                          disabled={eachRows?.disabled}
                          onChange={(event) => {
                            onChangeEditTableData(event, index);
                          }}
                          name={"gst"}
                          className="kyc-input-field form-control w-50 m-auto"
                          value={eachRows?.gst}
                          placeholder="GST*"

                          // value={isEdit ? tableEditData[index]?.gst : eachRows?.gst}
                        />
                      </td>
                      <td>{eachRows?.totalAmount || 0}</td>
                      <td>
                        <div className="d-flex align-items-center gap-2">
                          <span
                            onClick={() => onClickEdit(index)}
                            className="text-capitalize mx-1 text-primary"
                            type="button"
                          >
                            {eachRows?.disabled ? (
                              <BsPencil className="fs-5" />
                            ) : (
                              <BsCheck2 className="fs-5" />
                            )}
                          </span>
                          <span
                            onClick={() => onClickDelete(eachRows)}
                            className="text-capitalize mx-1 text-danger"
                            type="button"
                          >
                            <RiDeleteBin6Line className="fs-5" />
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                  {/* <tr className="total-table-text">
                <td className="fw-bold" colSpan={6}>
                  TOTAL
                </td>
                <td className="text-center fw-bold">{totalAmount}</td>
              </tr> */}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="m-2 text-secondary">Note: * is the mandatory symbol .</div>

        <div className="d-flex flex-column align-items-center my-4 details-page-container">
          <div className="d-flex flex-column details-header">
            Transport Details
          </div>
          <div className="details-container container p-3">
            <div className="row gy-md-4 gy-2">
              <div className="kyc-fields-container col-12  col-md-4">
                <label
                  htmlFor="shipmentTerms"
                  className="kyc-label-text form-label"
                >
                  Shipment Terms<span style={{ color: "red" }}>&#42;</span>
                </label>

                <select
                  onChange={onChangeTransportDetails}
                  name="shippingTerms"
                  className={`kyc-input-field form-select  ${
                    isError?.shippingTerms && "invalid-input"
                  }`}
                  onBlur={onBlurInputFeilds}
                  value={transportFields?.shippingTerms}
                  id="shippingTerms"
                >
                  <option value="" defaultValue>
                  Choose terms
                  </option>

                  {shipmentTermsOptions.map((eachTerms) => (
                    <option key={eachTerms.id} value={eachTerms.name}>
                      {eachTerms.name}
                    </option>
                  ))}
                </select>
                <span className="text-danger px-1">
                  {isError?.shippingTerms}
                </span>
              </div>

              <div className="kyc-fields-container col-12  col-md-4">
                <label
                  htmlFor="modeOfTransport"
                  className="kyc-label-text form-label"
                >
                  Mode Of Transport<span style={{ color: "red" }}>&#42;</span>
                </label>

                <select
                  onChange={onChangeTransportDetails}
                  name="modeOfTransport"
                  className={`kyc-input-field form-select  ${
                    isError?.modeOfTransport && "invalid-input"
                  }`}
                  onBlur={onBlurInputFeilds}
                  value={transportFields?.modeOfTransport}
                  id="modeOfTransport"
                >
                  <option value="" defaultValue>
                  Choose mode of transport
                  </option>

                  {modeOfTransportOptions.map((eachMode) => (
                    <option key={eachMode.id} value={eachMode.name}>
                      {eachMode.name}
                    </option>
                  ))}
                </select>
                <span className="text-danger px-1">
                  {isError?.modeOfTransport}
                </span>
              </div>

              <div className="kyc-fields-container col-12  col-md-4">
                <label
                  htmlFor="locationOfDelivery"
                  className="kyc-label-text form-label"
                >
                  Delivery Location
                </label>
                <input
                  disabled
                  name="locationOfDelivery"
                  id="locationOfDelivery"
                  type="text"
                  className={`kyc-input-field form-control`}
                  value={transportFields?.locationOfDelivery}
                />
              </div>

              <div className="kyc-fields-container col-12  col-md-4">
                <label
                  htmlFor="expectedDateOfDelivery"
                  className="kyc-label-text form-label"
                >
                  Required Date
                </label>
                <input
                  disabled
                  name="requiredDateOfDelivery"
                  id="requiredDateOfDelivery"
                  type="text"
                  className={`kyc-input-field form-control`}
                  value={transportFields?.requiredDateOfDelivery}
                />
              </div>

              <div className="kyc-fields-container col-12  col-md-4">
                <label
                  htmlFor="expectedDateOfDelivery"
                  className="kyc-label-text form-label"
                >
                  Expected Date of delivery<span style={{ color: "red" }}>&#42;</span>
                </label>
                <input min={new Date().toISOString().split('T')[0]}
                  name="expectedDateOfDelivery"
                  id="expectedDateOfDelivery"
                  type="date"
                  className={`kyc-input-field form-control  ${
                    isError?.expectedDateOfDelivery && "invalid-input"
                  }`}
                  
                  onBlur={onBlurInputFeilds}
                  onChange={onChangeTransportDetails}
                />
                <span className="text-danger px-1">
                  {isError?.expectedDateOfDelivery}
                </span>
              </div>

              <div className="kyc-fields-container col-12  col-md-4">
                <label
                  htmlFor="paymentTerms"
                  className="kyc-label-text form-label"
                >
                  Payment Terms (In Days)<span style={{ color: "red" }}>&#42;</span>
                </label>
                <input
                onKeyDown={e => (e.which === 38 || e.which === 40) && e.preventDefault()}
                  name="paymentTerms"
                  id="paymentTerms"
                  type="number"
                  className={`kyc-input-field form-control  ${
                    isError?.paymentTerms && "invalid-input"
                  }`}
                  value={transportFields?.paymentTerms}
                  onBlur={onBlurInputFeilds}
                  onChange={onChangeTransportDetails}
                />
                <span className="text-danger px-1">
                  {isError?.paymentTerms}
                </span>
              </div>

              <div className="kyc-fields-container col-12  col-md-4">
                <label
                  htmlFor="additionalText"
                  className="kyc-label-text form-label"
                >
                  Additional Text
                </label>
                <textarea
                  name="additionalText"
                  id="additionalText"
                  type="text"
                  className={`kyc-input-field form-control  ${
                    isError?.additionalText && "invalid-input"
                  }`}
                  value={transportFields?.additionalText}
                  onBlur={onBlurInputFeilds}
                  onChange={onChangeTransportDetails}
                />
                <span className="text-danger px-1">
                  {isError?.additionalText}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex flex-column flex-md-row align-items-center justify-content-end gap-3 mx-5 my-3">
          <button className="px-4 py-1  btn btn-dark" onClick={onClickBack}>
            Back
          </button>

          <button
            onClick={onClickRejectInvoice}
            className="px-4 py-1  btn btn-danger"
          >
            Reject
          </button>

          <button
            onClick={onClickGenerateProformaInvoice}
            className="px-4 py-1  btn btn-success"
          >
            Generate Proforma Invoice
          </button>
        </div>
      </div>
    </>
  );
};

export default AnchorMaterialRequestTableById;
