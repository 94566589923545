import { Fragment } from "react";

import atonLogo from "../../../assests/images/Aton-logo.png";
import { AiFillSetting } from "react-icons/ai";
import { FiLogOut } from "react-icons/fi";

export const AnchorKycNav = () => {
  // Handlers and Functions

  const onClickLogout = () => {
    sessionStorage.clear();
    window.location.href = "/login";
  };

  return (
    <Fragment>
      <nav className="navbar bg-dark bg-opacity-50 p-0">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center w-100">
            <div className="navbar-brand px-4">
              <img src={atonLogo} alt="aton logo" className="" width={50} />
            </div>

            <div className="d-flex align-items-center gap-2 px-4">
              <div className="dropdown-center">
                <div
                  className="bg-transparent"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    className="rounded-circle"
                    src="https://picsum.photos/200"
                    alt="admin pro"
                    width={50}
                  />
                </div>
                <ul
                  className="dropdown-menu dropdown-menu-start border-0 shadow"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <h6 className="dropdown-header d-flex align-items-center gap-2">
                      <AiFillSetting /> Menu
                    </h6>
                  </li>
                  <li
                    className="dropdown-item fw-semibold d-flex align-items-center gap-2"
                    style={{ cursor: "pointer" }}
                    onClick={onClickLogout}
                  >
                    {" "}
                    <FiLogOut /> Logout
                  </li>
                </ul>
              </div>

              <div className="d-flex flex-column justify-content=evenly">
                <span className="admin-name"></span>
                <span className="role fw-semibold fs-5">Anchor</span>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </Fragment>
  );
};
