import { Fragment, useContext, useEffect,useState } from "react";
import { GenericInputField } from "../../../../GenericComponent/Input/Input";
import { actions } from "./gstDetailsReducer"
import { BuyerKycContext } from "../buyerKycContext";
import { useDispatch } from "react-redux";
import { gstVerification } from "../../../../store/slices/anchor";

export const BuyerKYCGstDetails = (props) => {
	const [valid,setValid]=useState(true);
	const [changed,setChanged]=useState(false);

	const { gstDetailsDispatch: setState, gstDetails: { gst }, disableEdit } = useContext(BuyerKycContext);

	const dispatch = useDispatch();

	const handleGSTBlur = () => {
		if (gst.value.length === 15) {
			setChanged(true);
			dispatch(gstVerification(gst.value))
				.unwrap()
				.then(response => {
					setState({ type: actions.BLUR_GST, payload: !response?.success });
					setValid(response?.success);
				})
				.catch(error => {
					setState({ type: actions.BLUR_GST, payload: false });
					setValid(false);
				});
		}
		
	};

	return (
		<Fragment>
			<div className="container-with-heading d-flex flex-column container">
				<span className="kyc-content-title mb-3 row">GST Details</span>
				<div className="details-container ">
					<div className="row gy-md-4 gy-2">

						<GenericInputField
							label="GST Number" required={true} value={gst.value} isInvalid={gst.isTouched && (!gst.isValid || !gst.isVerified)}
							errorMessage={gst.value === "" ? "GST Number is Required" : !gst.isValid ? "Invalid GST Number" : valid&&changed? "GST Number already exists":""}
							verified={gst.isVerified} successMessage="GST Number is Verified" onChangeHandler={event => setState({ type: actions.CHANGE_GST, payload: event.target.value.toUpperCase() })}
							onBlurHandler={() => handleGSTBlur() } disabled={ disableEdit }
						/>

					</div>
				</div>
			</div>
		</Fragment>
	);
};
