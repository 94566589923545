import React from "react";
import { HiOutlineArrowNarrowDown } from "react-icons/hi";
import {
  AiOutlineCheckCircle,
  AiOutlineDoubleLeft,
  AiOutlineDoubleRight,
} from "react-icons/ai";
import { BiSort } from "react-icons/bi";
import TablePagination, {
  jumpToPage,
  nextPage,
  previousPage,
} from "../../../layout/GenericLayout/TablePagination";
import { useState } from "react";
import Loader from "../../../layout/GenericLayout/Loader";
import axios from "axios";
import { BASE_URL } from "../../../utilities/config";
import { useDispatch } from "react-redux";
import { getLenderManualInoviceClick } from "../../../store/slices/lender";
import { BsFillCheckCircleFill } from "react-icons/bs";

const LenderBillLogdingTable = (props) => {
  const dispatch = useDispatch();
  const updatePage = (currentPage) => {
    const newOffSet = props.tableQueryParams.tableLimit * (currentPage - 1);
    props.setTableQueryParams((prev) => ({
      ...prev,
      tableOffset: newOffSet,
      currentPage,
    }));
  };

  const onClickActiveSort = (activeSortValue) => {
    if (activeSortValue !== "") {
      let order = -1;
      if (activeSortValue === props.tableQueryParams.tableSort) {
        if (props.tableQueryParams.tableOrder === -1) {
          order = 1;
        }
      }
      props.setTableQueryParams((prev) => ({
        ...prev,
        tableSort: activeSortValue,
        tableOrder: order,
      }));
    }
  };

  const onClickViewMoreTableData = () => {
    let newLimit = props.tableQueryParams.tableLimit;
    if (newLimit === 5) {
      newLimit += 5;
    } else {
      newLimit -= 5;
    }
    props.setTableQueryParams((prev) => ({
      ...prev,
      tableLimit: newLimit,
    }));
  };

  const onClickManualInvoice = (data) => {
    console.log("data : ", data);

    dispatch(
      getLenderManualInoviceClick({
        invoiceId: data?.invoiceId,
        sellerId: data?.sellerId,
      })
    )
      .unwrap()
      .then((response) => {
        console.log("response : ", response?.items?.invoiceFileUrl);
        const a = document.createElement("a");
        a.href = response?.items?.invoiceFileUrl;
        a.target = "_blank";
        a.click();
      })
      .catch((error) => {
        console.log("error : ", error);
      });
  };

  return (
    <>
      <div className="lender-table-container">
        <div className="table-inner-cont">
          <table className={props.className}>
            <thead>
              <tr>
                {props.tableHeader.map((eachHeader) => (
                  <th
                    key={eachHeader.name}
                    scope="col"
                    // role={eachHeader.sortValue !== "" ? "button" : false}
                    className={`${
                      props.tableQueryParams.tableSort ===
                        eachHeader.sortValue &&
                      eachHeader.sortValue !== "" &&
                      "active-sort-text"
                    }`}
                    onClick={() => onClickActiveSort(eachHeader.sortValue)}
                  >
                    {eachHeader.name}
                    {eachHeader.sortValue !== "" && <BiSort />}
                  </th>
                ))}
              </tr>
            </thead>
            {props.materialRequestMetaData?.paging?.total<0?(<div className="no-data text-danger">Oops! there is no data to show. Please check back later</div>):null} 
            {props.isLoading ? (
              <tbody>
                <tr>
                  <td colSpan={9}>
                    <Loader className="my-5" />
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {props.tableRows.map((eachRows, index) => (
                  <tr key={eachRows.proformaId} scope="col">
                    <td>{eachRows.sellerId}</td>
                    <td>{eachRows.sellerName}</td>
                    <td>{eachRows?.buyerName}</td>
                    <td
                      className="cursor-pointer text-info"
                      onClick={() =>
                        props.tabView === 1
                          ? props.RedirectFunct(
                              eachRows.sellerId,
                              eachRows.invoiceId
                            )
                          : onClickManualInvoice(eachRows)
                      }
                    >
                      {props.tabView === 1
                        ? eachRows.invoiceId
                        : eachRows.externalInvoiceId}
                    </td>
                    <td>{eachRows.invoiceAmount}</td>
                    <td>
                      {eachRows.complianceCheck === 0 ? (
                        <BsFillCheckCircleFill className="fs-3 text-success" />
                      ) : (
                        ""
                      )}
                    </td>
                    <td
                      className={`${
                        eachRows.buyerInvoiceStatus === "Accepted" &&
                        "text-success"
                      }`}
                    >
                      {eachRows.buyerInvoiceStatus}
                    </td>
                    <td
                      className="cursor-pointer text-info"
                      onClick={() =>
                        props.handleBillDetail(eachRows.billReferenceNo)
                      }
                    >
                      {eachRows.billReferenceNo}
                    </td>
                    <td
                      role={
                        eachRows.invoiceStatus === "Unregistered" && "button"
                      }
                      className={`${
                        eachRows.invoiceStatus === "Unregistered" && "text-info"
                      } ${
                        eachRows.invoiceStatus === "Discrepant" && "text-danger"
                      }`}
                      onClick={
                        eachRows.invoiceStatus === "Unregistered"
                          ? () =>
                              props.handleUnregister(
                                eachRows.invoiceId,
                                eachRows.sellerId,
                                !eachRows.manualInvoice,
                                props.tabView===2??eachRows.externalInvoiceId,
                              )
                          : (e) => e.preventDefault()
                      }
                    >
                      {eachRows.invoiceStatus}
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
      {!props.isLoading && props.materialRequestMetaData?.paging?.total>5 && (
          <div className="d-flex justify-content-end px-2">
            <span
              onClick={onClickViewMoreTableData}
              className="d-flex align-items-center gap-1 my-3 view-more-text"
            >
              {props.tableQueryParams.tableLimit === 5
              ? props.tableQueryParams.tableOffset+props.tableQueryParams.tableLimit<props.materialRequestMetaData?.paging?.total?"View More ":""
              : props.tableQueryParams.tableOffset+props.tableQueryParams.tableLimit<props.materialRequestMetaData?.paging?.total?"View Less ":""}
            
              <HiOutlineArrowNarrowDown
                className={`fs-5 ${
                  props.tableQueryParams.tableLimit === 5
                  ? props.tableQueryParams.tableOffset+props.tableQueryParams.tableLimit<props.materialRequestMetaData?.paging?.total? "down-arrow":"d-none"
                  : props.tableQueryParams.tableOffset+props.tableQueryParams.tableLimit<props.materialRequestMetaData?.paging?.total? "up-arrow":"d-none"}
                 `}
              />
            </span>
          </div>
        )}
      <div className="d-flex align-items-center gap-2 page-data-text-container mt-3 px-3 w-100">
        <TablePagination
          pageSize={props.tableQueryParams.tableLimit}
          onUpdatePage={updatePage}
          current={props.tableQueryParams.currentPage}
          total={props.materialRequestMetaData?.paging?.total}
        ></TablePagination>
      </div>
    </>
  );
};

export default LenderBillLogdingTable;
