import { HiOutlineArrowNarrowDown } from "react-icons/hi";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import { BiSort } from "react-icons/bi";
import TablePagination, {
  jumpToPage,
  nextPage,
  previousPage,
} from "../../../../layout/GenericLayout/TablePagination";
import { useEffect, useState } from "react";
import Loader from "../../../../layout/GenericLayout/Loader";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CiUndo } from "react-icons/ci";
import { getLenderManualInoviceClick } from "../../../../store/slices/lender";
import ReusableModal from "../../../../layout/GenericLayout/ReusableModal";
import ReusableMessageModal from "../../../../layout/GenericLayout/ReusableMessageModel";
import PaymentPage from "../../../../Pages/PaymentPage";

const ShowPdf = (props) => {
  // console.log("I have PDF LINK cross Verifing: ", props.pdfLink)
  return (
    <div>
      <iframe
        src={props.pdfLink}
        width="100%"
        height="600px"
        title="PDF Viewer"
      />
    </div>
  );
};

const AlertResponse = (props) => {
  // console.log("I have PDF LINK cross Verifing: ", props.alertMsg)
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ maxWidth: "100%" }}
    >
      <p>{props?.alertMsg}</p>
    </div>
  );
};

const GetPaymentUser = (props) => {
  const [payment, setPayment] = useState(props?.amount);
  return (
    <div className="m-2 p-3 d-flex justify-content-center align-items-start flex-column gap-3">
      <h4>Enter the Payment Amount</h4>
      <div className="d-flex align-items-center gap-2 justify-content-center w-100">
        <input
          type="number"
          value={payment}
          placeholder="Enter the amount"
          onChange={(event) => {
            setPayment(event.target.value);
          }}
        />
        <CiUndo onClick={() => setPayment(props?.amount)} />
      </div>

      <div className="d-flex w-100 justify-content-center gap-5 my-3">
        <button
          type="button"
          className="btn btn-danger"
          onClick={props?.cancel}
        >
          cancel
        </button>
        <PaymentPage amount={payment}>
          <button type="button" className="btn btn-primary">
            Make Payment
          </button>
        </PaymentPage>
      </div>
    </div>
  );
};

const BillLodgingDigital = (props) => {

  
  const currentLocation = useLocation();
  const dispatch = useDispatch();



  const updatePage = (currentPage) => {
    const newOffSet = props.tableQueryParams.tableLimit * (currentPage - 1);
    props.setTableQueryParams((prev) => ({
      ...prev,
      tableOffset: newOffSet,
      currentPage,
    }));
  };

  const onClickActiveSort = (activeSortValue) => {
    if (activeSortValue !== "") {
      let order = -1;
      if (activeSortValue === props.tableQueryParams.tableSort) {
        if (props.tableQueryParams.tableOrder === -1) {
          order = 1;
        }
      }
      props.setTableQueryParams((prev) => ({
        ...prev,
        tableSort: activeSortValue,
        tableOrder: order,
      }));
    }
  };

  const onClickViewMoreTableData = () => {
    let newLimit = props.tableQueryParams.tableLimit;
    if (newLimit === 5) {
      newLimit += 5;
    } else {
      newLimit -= 5;
    }
    props.setTableQueryParams((prev) => ({
      ...prev,
      tableLimit: newLimit,
    }));
  };


 



  const onClickManualInvoice = (data) => {
    console.log("data : ", data);

    dispatch(
      getLenderManualInoviceClick({
        invoiceId: data?.invoiceId,
        sellerId: data?.sellerId,
      })
    )
      .unwrap()
      .then((response) => {
        console.log("response : ", response?.items?.invoiceFileUrl);
        const a = document.createElement("a");
        a.href = response?.items?.invoiceFileUrl;
        a.target = "_blank";
        a.click();
      })
      .catch((error) => {
        console.log("error : ", error);
      });
  };

  return (
    <>
      <div className="buyer-table-container">
        <div className="table-inner-cont">
          <table className={props.className}>
            <thead>
              <tr>
                {props.tableHeader.map((eachHeader) => (
                  <th
                    key={eachHeader.name}
                    scope="col"
                    // role={eachHeader.sortValue !== "" ? "button" : false}
                    className={`${
                      props.tableQueryParams.tableSort ===
                        eachHeader.sortValue &&
                      eachHeader.sortValue !== "" &&
                      "active-sort-text"
                    }`}
                    onClick={() => onClickActiveSort(eachHeader.sortValue)}
                  >
                    {eachHeader.name === `Total Amount` ? (
                      <>
                        {eachHeader.name} &#8377;
                        {eachHeader.sortValue !== "" && <BiSort />}
                      </>
                    ) : (
                      <>
                        {eachHeader.name}
                        {eachHeader.sortValue !== "" && <BiSort />}
                      </>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            {props.isLoading ? (
              <tbody>
                <tr>
                  <td colSpan={14}>
                    <Loader className="my-5" />
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {props.tableRows.map((eachRows, index) => (
                            <tr key={eachRows.proformaId} scope="col">
                            <td>{eachRows.sellerId}</td>
                            <td>{eachRows.sellerName}</td>
                            <td>{eachRows.invoiceAmount}</td>
                            <td
                              className="cursor-pointer text-info"
                              onClick={() =>
                                props.tabView === 1
                                  ? props.RedirectFunct(
                                      eachRows.sellerId,
                                      eachRows.invoiceId
                                    )
                                  : onClickManualInvoice(eachRows)
                              }
                            >
                              {props.tabView === 1
                                ? eachRows.invoiceId
                                : eachRows.externalInvoiceId}
                            </td>
                            <td>{eachRows.complianceCheck}</td>
                            <td
                              className={`${
                                eachRows.buyerInvoiceStatus === "Accepted" &&
                                "text-success"
                              }`}
                            >
                              {eachRows.buyerInvoiceStatus}
                            </td>
                            <td
                              className="cursor-pointer text-info"
                              onClick={() =>
                                props.handleBillDetail(eachRows.billReferenceNo)
                              }
                            >
                              {eachRows.billReferenceNo}
                            </td>
                            <td
                              role={
                                eachRows.invoiceStatus == "Unregistered" && "button"
                              }
                              className={`${
                                eachRows.invoiceStatus == "Unregistered" && "text-info"
                              } ${
                                eachRows.invoiceStatus == "Discrepant" && "text-danger"
                              }`}
                              onClick={
                                eachRows.invoiceStatus == "Unregistered"
                                  ? () =>
                                      props.handleUnregister(
                                        eachRows.invoiceId,
                                        eachRows.sellerId,
                                        eachRows.manualInvoice
                                      )
                                  : (e) => e.preventDefault()
                              }
                            >
                              {eachRows.invoiceStatus}
                            </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
        {!props.isLoading && (
          <div className="d-flex justify-content-end px-2">
            <span
              onClick={onClickViewMoreTableData}
              className="d-flex align-items-center justify-content-start gap-1 my-3 view-more-text"
            >
              View more{" "}
              <HiOutlineArrowNarrowDown
                className={`fs-5 ${
                  props.tableQueryParams.tableLimit === 5
                    ? "down-arrow"
                    : "up-arrow"
                }`}
              />
            </span>
          </div>
        )}
      </div>
      <div className="d-flex align-items-center gap-2 page-data-text-container mt-3 px-3 w-100">
        <TablePagination
          pageSize={props.tableQueryParams.tableLimit}
          onUpdatePage={updatePage}
          current={props.tableQueryParams.currentPage}
          total={props.materialRequestMetaData?.paging?.total}
        ></TablePagination>
      </div>
    </>
  );
};

export default BillLodgingDigital;
