import { HiOutlineArrowNarrowDown } from "react-icons/hi";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ReusableModal from "../../../layout/GenericLayout/ReusableModal";
import { BsPencil } from "react-icons/bs";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { getIsEmailRegistered } from "../../../store/slices/generic";
import { updateLender } from "../../../store/slices/admin";
import { toastMessage } from "../../../layout/GenericLayout/ToastMessage";
import Loader from "../../../layout/GenericLayout/Loader";
import { RxCrossCircled } from "react-icons/rx";

/* MODAL/CHILDREN COMPONENT */
const EditLenderModal = ({ toggleModal, activeEditData, onRefreshTable }) => {
  /* FUNCTION DECLARATION */
  const dispatch = useDispatch();

  /* USESTATES */
  const [editLenderDetailsFields, setAddLenderDetailsFields] = useState({
    name: "",
    emailId: "",
    address: "",
    mobileNumber: "",
  });

  /* USESELECTORS */
  const isEmailRegisteredData = useSelector(
    (state) => state.generic.isEmailRegisteredData
  );
  const lenderEditData = useSelector((state) => state.admin.lenderEditData);

  /* USEEFFECTS */

  useEffect(() => {
    setAddLenderDetailsFields((prev) => ({
      ...prev,
      name: activeEditData.name,
      emailId: activeEditData.emailId,
      address: activeEditData.address,
      mobileNumber: activeEditData.mobileNumber,
    }));
  }, [activeEditData]);

  /* EVENT LISTENERS */
  const onChangeAddLenderDetailsFields = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setAddLenderDetailsFields((prev) => ({ ...prev, [name]: value }));
  };

  const onBlurAddLenderDetailsFields = (event) => {
    const name = event.target.name;
    const validity = event.target.checkValidity();

    if (name === "emailId" && validity) {
      dispatch(getIsEmailRegistered(editLenderDetailsFields.emailId))
        .unwrap()
        .then((response) => {
          toastMessage(response.success, "success");
        })
        .catch((error) => {
          toastMessage(error.message, "error");
        });
    }
  };

  const onSubmitLenderAddDetails = (event) => {
    event.preventDefault();
    dispatch(updateLender(editLenderDetailsFields))
      .unwrap()
      .then((response) => {
        onRefreshTable();
        toastMessage(response.success, "success");
        toggleModal();
      })
      .catch((error) => {
        toastMessage(error.message, "error");
      });
  };

  return (
    <>
      {lenderEditData.isLoading && (
        <div className="blur-loader-container">
          <Loader />
        </div>
      )}
      <form className="" onSubmit={onSubmitLenderAddDetails}>
        <span className="fs-4 fw-semibold">
          Edit Relationship Manager Details
        </span>
        <div className="details-container container mt-4">
          <div className="row gy-md-4 gy-2">
            <div className="kyc-fields-container col-12  col-md-6">
              <label htmlFor="name" className="kyc-label-text form-label">
                Name
              </label>
              <input
                required
                onChange={onChangeAddLenderDetailsFields}
                id="name"
                name="name"
                type="text"
                className="kyc-input-field form-control"
                value={editLenderDetailsFields.name}
              />
            </div>

            <div className="kyc-fields-container col-12  col-md-6">
              <label htmlFor="pan" className="kyc-label-text form-label">
                Email ID
              </label>
              <input
                onBlur={onBlurAddLenderDetailsFields}
                required
                onChange={onChangeAddLenderDetailsFields}
                id="emailId"
                type="email"
                name="emailId"
                className="kyc-input-field form-control"
                value={editLenderDetailsFields.emailId}
              />
            </div>

            <div className="kyc-fields-container col-12  col-md-6">
              <label htmlFor="email" className="kyc-label-text form-label">
                Address
              </label>
              <input
                required
                onChange={onChangeAddLenderDetailsFields}
                id="address"
                type="text"
                name="address"
                className="kyc-input-field form-control"
                value={editLenderDetailsFields.address}
              />
            </div>

            <div className="kyc-fields-container col-12  col-md-6">
              <label
                htmlFor="mobileNumber"
                className="kyc-label-text form-label"
              >
                Mobile Number
              </label>
              <input
                required
                onChange={onChangeAddLenderDetailsFields}
                id="mobileNumber"
                type="tel"
                name="mobileNumber"
                className="kyc-input-field form-control"
                value={editLenderDetailsFields.mobileNumber}
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end gap-4 mt-5">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={toggleModal}
          >
            Back
          </button>
          <button type="submit" className="btn btn-success">
            Update
          </button>
        </div>
      </form>
    </>
  );
};

/* PARENT COMPONENT */
const RMListOfCustomerOnboardingTable = (props) => {
  const [activeEditData, setActiveEditData] = useState([]);

  /* TABLE OPTIONS */
  const totalPage = 5;

  /* MODALS USESTATE */
  const [showEditModal, setShowEditModal] = useState(false);

  const navigate = useNavigate();

  /* MODALS TOGGLE */
  const toggleEditModal = () => {
    setShowEditModal((prev) => !prev);
  };

  const onClickEdit = (data) => {
    setActiveEditData(data);
    toggleEditModal();
  };

  const handleRedirectToDetails = (emailId, role) => {
    let url = "";
    if (role === "seller") {
      url = "/rm/rm-onboarding/anchor";
    } else if (role === "buyer") {
      url = "/rm/rm-onboarding/counter-party"
    } else {
      toastMessage("Invalid Role", "error");
      return;
    }

    navigate(url, { state: { emailId, disableEdit: true, currentPath: "/rm/customer-onboarding" } });
  };
  
  const handleRedirectToEdit = (emailId, role) => {
    let url = "";
    if (role === "seller") {
      url = "/rm/rm-onboarding/anchor";
    } else if (role === "buyer") {
      url = "/rm/rm-onboarding/counter-party"
    } else {
      toastMessage("Invalid Role", "error");
      return;
    }

    navigate(url, { state: { emailId, currentPath: "/rm/customer-onboarding" } });
  };

  return (
    <>
      {/* MODALS */}
      {/* {showEditModal && (
        <ReusableModal
          showModal={showEditModal}
          toggleModal={toggleEditModal}
          modalContent={
            <EditLenderModal
              activeEditData={activeEditData}
              toggleModal={toggleEditModal}
              onRefreshTable={props.onRefreshTable}
            />
          }
          modalSizeClass="modal-lg"
        />
      )} */}

      <div className="table-container">
        <table className={props.className}>
          <thead>
            <tr>
              {props.tableHeader.map((eachHeader) => (
                <th key={eachHeader} scope="col">
                  {eachHeader}
                </th>
              ))}
              <th></th>
            </tr>
          </thead>

          <tbody>
            {props.tableRows.length === 0 && (
              <tr scope="col">
                <td colSpan={4}>
                  <h3 className="mt-4 fw-semibold text-danger">
                    {" "}
                    No Data Available!
                  </h3>
                </td>
              </tr>
            )}
            {props.tableRows.map((eachRows) => (
              <tr key={eachRows._} scope="col">
                <td>{eachRows?.companyFullName}</td>
                <td>
                  <span className="text-info" style={{ cursor: "pointer" }} onClick={() => handleRedirectToDetails(eachRows?.emailId, eachRows?.roles[0])}>
                    {eachRows?.emailId}
                  </span>
                </td>
                <td>{
                  eachRows?.roles[0] === "buyer" ?
                    <span className="text-warning">Counter Party</span>
                    : <span className="text-info">Anchor</span>
                }</td>
                <td>{eachRows.status?.toUpperCase?.()}</td>
                {eachRows?.status?.toUpperCase()!=='APPROVED' && 
                <td>
                  <button onClick={ () => handleRedirectToEdit(eachRows?.emailId, eachRows?.roles[0]) } type="button" className="btn btn-outline-secondary">Edit</button>
                </td>
                }
              </tr>
            ))}
          </tbody>
        </table>
        {/* <div className="d-flex justify-content-end px-2"> */}
          {/* <span */}
            {/* onClick={props.onClickViewMoreTableData} */}
            {/* className="d-flex align-items-center gap-1 my-3 view-more-text" */}
          {/* > */}
            {/* View more <HiOutlineArrowNarrowDown className="fs-5" /> */}
          {/* </span> */}
        {/* </div> */}
      </div>
      <div className="d-flex align-items-center gap-2 page-data-text-container mt-3 px-3">
        <AiOutlineDoubleLeft
        // onClick={props.onClickPreviousPage}
        />
        <span className="page-data-text">
          Page {} of {1}
        </span>
        <AiOutlineDoubleRight
        // onClick={props.onClickNextPage}
        />
      </div>
    </>
  );
};

export default RMListOfCustomerOnboardingTable;
