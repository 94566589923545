import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getMaterialRequestDetails } from "../../../store/slices/buyer";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../../layout/GenericLayout/Loader";

const MaterialRequestDetails = () => {
  const { mr } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [itemsInformation, setItemsInformation] = useState();
  const [items, setItems] = useState("");
  const [quantity, setQuantity] = useState(0);

  const materialRequestDetailsResponse = useSelector(
    (state) => state.buyer.MaterialRequestDetails
  );

  useEffect(() => {
    dispatch(getMaterialRequestDetails(mr));
  }, [dispatch, mr]);

  useEffect(() => {
    if (materialRequestDetailsResponse.data?.items) {
      setItemsInformation(materialRequestDetailsResponse.data?.items[0]);
      setItems(
        materialRequestDetailsResponse.data?.items[0]?.itemsDetails[0]?.item
      );
      setQuantity(
        materialRequestDetailsResponse.data?.items[0]?.itemsDetails[0]?.quantity
      );
    }
  }, [materialRequestDetailsResponse.data]);

  console.log(materialRequestDetailsResponse);
  return (
    <div className="d-flex flex-column m-0 p-0 gap-4 request-details-cont mb-3">
      <div className="container-with-heading d-flex flex-column container my-4">
        <span className="content-title mb-3 row">Material Request Details</span>
        <div className="details-container ">
          {/* <div className="row gy-md-4 gy-2"> */}

          <div className="fields-container container-with-heading px-0 row">
            <span className="content-title">Items Information</span>
            {/* <div className="fields-container col-12"> */}
            <table style={{ border: "1px solid black" }} className="w-100">
              {materialRequestDetailsResponse?.isLoading ? (
                <tbody>
                  {/*  true ?  <tbody> */}
                  <tr>
                    <td colSpan={2}>
                      <Loader />
                    </td>
                  </tr>
                </tbody>
              ) : (
                <>
                  <thead>
                    <tr style={{ border: "1px solid black" }}>
                      <th className="text-center">Item</th>
                      <th className="text-center">Quantity Ordered</th>
                      <th className="text-center">HSN</th>
                    </tr>
                  </thead>
                  <tbody>
            
                    
                      {
                        materialRequestDetailsResponse?.data?.items[0]?.itemsDetails.map((ele)=>
                      
                        <tr style={{ border: "1px solid black" }}>
                        <td className="text-center">{ele?.item ? ele?.item : 0}</td>
                        <td className="text-center">{ele?.quantity ? ele?.quantity :0}</td>
                        <td className="text-center">{ele?.HSN ? ele?.HSN :0}</td>
                        </tr>
            
                        )
                      }
                     
                    

                  </tbody>
                </>
              )}
            </table>
            {/* </div> */}
          </div>

          {/* TransPort Details */}
          <div className="fields-container container-with-heading px-0 row mt-3 gy-0">
            <span className="content-title">Transport Details</span>

            {
            materialRequestDetailsResponse?.isLoading ? (
              // true ? (
              <div className="col-12 my-3">
                <Loader/>
                </div>
            ) : (
              <>
                <div className="fields-container col-12  col-md-4 mt-3">
                  <label
                    htmlFor="DeliveryLocation"
                    className="label-text form-label"
                  >
                    Delivery Location
                  </label>
                  <input
                    id="DeliveryLocation"
                    name="DeliveryLocation"
                    type="text"
                    disabled
                    value={itemsInformation?.deliveryLocation ? itemsInformation?.deliveryLocation : 'NAN'}
                    className={`input-field form-control`}
                  />
                  <span className="text-danger px-1"></span>
                </div>

                <div className="fields-container col-12  col-md-4 mt-3">
                  <label
                    htmlFor="RequiredDate"
                    className="label-text form-label"
                  >
                    Required Date
                  </label>
                  <input
                    id="RequiredDate"
                    type="text"
                    name="RequiredDate"
                    disabled
                    value={itemsInformation?.requiredDate ? itemsInformation?.requiredDate: 'NAN'}
                    className={`input-field form-control`}
                  />
                  <span className="text-danger px-1"></span>
                </div>
                <div className="fields-container col-12  col-md-4 mt-3">
                  <label htmlFor="Shipment" className="label-text form-label">
                    Shipment
                  </label>
                  <input
                    id="Shipment"
                    type="text"
                    name="Shipment"
                    className={`input-field form-control`}
                    disabled
                    value={itemsInformation?.shipment?itemsInformation?.shipment:'NAN'}
                  />
                  <span className="text-danger px-1"></span>
                </div>
              </>
            )}
          </div>

          <div className="fields-container container-with-heading px-0 row mt-3 gy-0">
            <span className="content-title">Contact Details</span>
              {
                materialRequestDetailsResponse?.isLoading ? <>
                 {/* true ? <> */}
                      <div className="col-12 my-3">
                <Loader/>
                </div>
                </>:<>
                <div className="fields-container col-12  col-md-4">
              <label htmlFor="Name" className="label-text form-label">
                Name
              </label>
              <input
                id="Name"
                type="text"
                name="Name"
                className={`input-field form-control`}
                disabled
                value={itemsInformation?.name}
              />
              <span className="text-danger px-1"></span>
            </div>

            <div className="fields-container col-12  col-md-4">
              <label htmlFor="Email" className="label-text form-label">
                Email
              </label>
              <input
                id="Email"
                type="email"
                name="Email"
                className={`input-field form-control`}
                disabled
                value={itemsInformation?.emailId}
              />
              <span className="text-danger px-1"></span>
            </div>

            <div className="fields-container col-12  col-md-4">
              <label htmlFor="PhoneNumber" className="label-text form-label">
                Phone Number
              </label>
              <input
                id="PhoneNumber"
                type="number"
                name="PhoneNumber"
                className={`input-field form-control`}
                disabled
                value={itemsInformation?.phoneNumber}
              />
              <span className="text-danger px-1"></span>
            </div>
                </>
              }
    
          </div>
        </div>
        <div className="container d-flex justify-content-end py-0 px-0 mt-1 mb-3 row">
          <div className="col-2 d-flex justify-content-end px-0">
            <button
              className="back-button"
              onClick={() => {
                navigate("/buyer/material-request");
              }}
            >
              Back
            </button>
          </div>
        </div>
      </div>

      {/* </div> */}
    </div>
  );
};

export default MaterialRequestDetails;
