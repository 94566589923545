import { BuyerKycActions } from "../actionTypes";
// eslint-disable-next-line no-unused-vars
import { BuyerKYCFormTemplate } from "../buyerKycContext";

export const actions = BuyerKycActions.GST_DETAILS;

/**
 * @param { typeof BuyerKYCFormTemplate.gstDetails } state 
 * @param {{ type: typeof actions, paylaod: string | BufferSource }} param1
 * @returns { typeof BuyerKYCFormTemplate.gstDetails }
 */
export function GstDetailsReducer(state, { type, payload }) {

	if (type === actions.CHANGE_GST) {
		const value = payload ?? state.gst.value;

		return { gst: { value, isValid: value.length === 15, isTouched: true, isVerified: false } };
	}

	else if (type === actions.BLUR_GST) {
		console.log('payload', payload);
		return { gst: { ...state.gst, isTouched: true, isVerified: payload ?? false } };
	}

	else if (type === actions.TOUCH_ALL) {
		for (let item in state) {
			for (let obj in state[item]) {
				if (obj === "isTouched") {
					state[item][obj] = true;
				}
			}
		}
		return { ...state };
	}

	return state;
}
