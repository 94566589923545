import { useState } from "react";
import { Link, useNavigate, useOutlet } from "react-router-dom";
import { BsSearch } from "react-icons/bs";
import { TbBellRingingFilled } from "react-icons/tb";
import { FcAdvertising } from "react-icons/fc";
import { FaBars } from "react-icons/fa";
import { RxCross1 } from "react-icons/rx";
import { AiFillLeftCircle, AiFillSetting } from "react-icons/ai";
import { FiLogOut } from "react-icons/fi";
import atonLogo from "../../assests/images/Aton-logo.png";
import notificationDeclined from "../../assests/icons/notification-declined.svg";
import notificationActive from "../../assests/icons/notification-active.svg";

// importing Icons of Side Bar in Buyer
import {
  DashBoardIcon,
  MaterialRequestIcon,
  ProformaInvoiceIcon,
  PurchaseOrderIcon,
  InvoiceIcon,
} from "../../assests/icons/sidebar/BuyerSideBarIcons/BuyerSideBarIcons";

// import { DashBoardBuyerIco } from "../../assests/icons/sidebar/Buyer/dash.svg";

import { MdOutlineManageAccounts } from "react-icons/md";

const UnderWriterLayout = () => {
  const navigate = useNavigate();
  const outlet = useOutlet();
  const userDetails = JSON.parse(sessionStorage.getItem("user"));

  const [activeMenu, setActiveMenu] = useState("/buyer/dashboard");
  const [notification, setNotification] = useState([]);
  const [mobileNavbarStatus, setMobileNavbarBar] = useState(false);

  const onClickActiveMenu = (path) => {
    setActiveMenu(path);
    setMobileNavbarBar((prev) => !prev);
    navigate(path);
  };

  const onClickSidebarCollaspe = () => {
    let sidebar = document.querySelector(".admin-sidebar");

    // console.log('buyer layouyt side bar widht check -->',sidebar.style);
    if (sidebar.style.width === "120px") {
      sidebar.style.minWidth = "350px";
      sidebar.style.width = "350px";
    } else {
      sidebar.style.minWidth = "120px";
      sidebar.style.width = "120px";
    }

    let sidebarMenuText = document.querySelectorAll(".sidebar-text");
    sidebarMenuText.forEach((eachMenuText) => {
      if (eachMenuText.style.display === "none") {
        eachMenuText.style.display = "";
      } else {
        eachMenuText.style.display = "none";
      }
    });

    let sidebarCircle = document.querySelector(".sidebar-circle-icon");
    if (sidebarCircle.style.transform === "rotate(180deg)") {
      sidebarCircle.style.transform = "rotate(0deg)";
    } else {
      sidebarCircle.style.transform = "rotate(180deg)";
    }
  };

  const onChangeAdminSearch = (event) => {
    // Search resutls to be handled
  };

  const onClickLogout = () => {
    sessionStorage.clear();
    window.location.href = "/login";
  };
  const onClickMyProfile = () => {
    navigate("/underwriter/my-profile");
  };

  // ********small and medium devices events********
  const onClickMobileNavbar = () => {
    setMobileNavbarBar((prev) => !prev);
  };

  return (
    <>
      {/* desktop and tablet layout */}
      <div className="d-md-flex gap-2 min-vh-100 admin-layout-bg-container p-3 pe-0 d-none">
        <div className="admin-sidebar mh-100 d-flex flex-column py-1">
          <div className="sidebar-circle">
            <AiFillLeftCircle
              className="sidebar-circle-icon"
              onClick={onClickSidebarCollaspe}
            />
          </div>

          <Link to="/underwriter/limit">
            <div className="d-flex justify-content-center align-items-center">
              <img src={atonLogo} alt="aton logo" className="admin-sidebar-image-logo" />
            </div>
          </Link>

          <div className="sidebar-menu-container d-flex flex-column gap-2">
            <div
              className={`d-flex align-items-center px-5 gap-3 py-2 ${
                window.location.pathname.includes("/underwriter/limit")
                  ? "sidebar-active-menu"
                  : "menu-container"
              }`}
              onClick={() => onClickActiveMenu("/underwriter/limit")}
            >
              <DashBoardIcon styleClassName="sidebar-icons" />
              <span className="sidebar-text">Limit</span>
            </div>
          </div>
        </div>
        <div
          className="flex-grow-1 px-0 mb-5"
          style={{
            overflowY: "hidden",
            overflowX: "hidden",
            boxSizing: "border-box",
          }}
        >
          <div className="d-flex justify-content-between align-items-center pe-3 py-3">
            <div className="admin-search-container d-flex align-items-center py-1 px-4 mx-3">
              <input
                className="admin-search-input-element d-flex flex-grow-1"
                type="search"
                placeholder="Search"
                onChange={onChangeAdminSearch}
              />{" "}
              <BsSearch />
            </div>
            <div className="d-flex gap-3 align-items-center">
              <div className="dropdown-center">
                <button
                  className="notification-bell border-0"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {notification.length === 0 ? (
                    <img src={notificationDeclined} alt="notification declined" />
                  ) : (
                    <img src={notificationActive} alt="notification active" />
                  )}
                </button>
                <ul
                  className="dropdown-menu dropdown-menu-end border-0 shadow"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <h6 className="dropdown-header d-flex align-items-center gap-2">
                      <TbBellRingingFilled /> Notification
                    </h6>
                  </li>
                  <li className="dropdown-item d-flex flex-column justify-content-center align-items-center">
                    <FcAdvertising className="fs-3" />
                    No new notification!
                  </li>
                </ul>
              </div>
              <div className="d-flex align-items-center gap-2">
                <div className="dropdown-center">
                  <button
                    className="border-0"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      className="admin-profile-pic rounded-circle"
                      src="https://picsum.photos/200" alt="admin profile pic"
                    />
                  </button>
                  <ul
                    className="dropdown-menu dropdown-menu-start border-0 shadow"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <h6 className="dropdown-header d-flex align-items-center gap-2">
                        <AiFillSetting /> Menu
                      </h6>
                    </li>
                    <li
                      className="dropdown-item fw-semibold d-flex align-items-center gap-2"
                      style={{ cursor: "pointer" }}
                      onClick={onClickMyProfile}
                    >
                      {" "}
                      <MdOutlineManageAccounts /> My Profile
                    </li>
                    <li
                      className="dropdown-item fw-semibold d-flex align-items-center gap-2"
                      style={{ cursor: "pointer" }}
                      onClick={onClickLogout}
                    >
                      {" "}
                      <FiLogOut /> Logout
                    </li>
                  </ul>
                </div>

                <div className="d-flex flex-column justify-content=evenly">
                  <span className="admin-name">
                    {userDetails.name === null ? "N/A" : "Hi, " + userDetails.name}
                  </span>
                  <span className="role">UnderWriter!</span>
                </div>
              </div>
            </div>
          </div>

          <div className="buyer-content">
            {window.innerWidth > 767 && outlet}
          </div>
        </div>
      </div>
      {/* mobile layout */}
      <div
        className={`d-md-none admin-layout-small-devices-bg-container d-flex flex-column ${
          mobileNavbarStatus && "active-navbar"
        }`}
      >
        <div className="topnav d-flex flex-column">
          <div className="d-flex justify-content-between align-items-center px-2 py-2 only-navbar">
            <div className="d-flex align-items-center gap-2">
              <button
                onClick={onClickMobileNavbar}
                type="button"
                className="btn outline-0 border-0"
              >
                {mobileNavbarStatus ? (
                  <RxCross1 className="fs-1" />
                ) : (
                  <FaBars className="fs-2" />
                )}
              </button>

              <Link to="/underwriter/limit">
                <div className="d-flex justify-content-center align-items-center">
                  <img src={atonLogo} alt="aton logo" className="mobile-navbar-image-logo" />
                </div>
              </Link>
            </div>

            <div className="d-flex align-items-center gap-2">
              <div className="dropdown-center">
                <button
                  className="border-0 bg-transparent"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {notification.length === 0 ? (
                    <img src={notificationDeclined} alt="notification declined" />
                  ) : (
                    <img src={notificationActive} alt="notification active" />
                  )}
                </button>
                <ul
                  className="dropdown-menu dropdown-menu-end border-0 shadow"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <h6 className="dropdown-header d-flex align-items-center gap-2">
                      <TbBellRingingFilled /> Notification
                    </h6>
                  </li>
                  <li className="dropdown-item d-flex flex-column justify-content-center align-items-center">
                    <FcAdvertising className="fs-3" />
                    No new notification!
                  </li>
                </ul>
              </div>

              <div className="d-flex align-items-center gap-2">
                <div className="dropdown-center">
                  <button
                    className="border-0 bg-transparent"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      className="admin-profile-pic rounded-circle"
                      src="https://picsum.photos/40" alt="admin profile pic"
                    />
                  </button>
                  <ul
                    className="dropdown-menu dropdown-menu-start border-0 shadow"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <h6 className="dropdown-header d-flex align-items-center gap-2">
                        <AiFillSetting /> Admin!
                      </h6>
                    </li>

                    <li className="dropdown-item d-flex flex-column justify-content-center align-items-center">
                      {userDetails.name === null ? "N/A" : userDetails.name}
                    </li>
                  </ul>
                </div>
              </div>

              <div
                className="fw-semibold d-flex align-items-center justify-content-center"
                style={{ cursor: "pointer" }}
                onClick={onClickLogout}
              >
                <FiLogOut className="fs-2" />
              </div>
            </div>
          </div>

          {mobileNavbarStatus && (
            <div className="min-vh-100">
              <div
                className="d-flex flex-column gap-1 justify-content-center"
                style={{ padding: "0 100px" }}
              >
                <div
                  className={`d-flex align-items-center gap-3 py-2 ${
                    window.location.pathname.includes("/admin/dashboard")
                      ? "mobile-sidebar-active-menu"
                      : "mobile-menu-container"
                  }`}
                  onClick={() => onClickActiveMenu("/admin/dashboard")}
                >
                  <DashBoardIcon styleClassName="sidebar-icon" />
                  <span className="sidebar-text">Dashboard</span>
                </div>

                <hr className="px-5 mobile-nav-line my-2" />

                <div
                  className={`d-flex align-items-center gap-3 py-2 ${
                    window.location.pathname.includes("/buyer/dashboard")
                      ? "mobile-sidebar-active-menu"
                      : "mobile-menu-container"
                  }`}
                  onClick={() => onClickActiveMenu("/buyer/dashboard")}
                >
                  <MaterialRequestIcon styleClassName="sidebar-icon" />
                  <span className="sidebar-text">Material Request</span>
                </div>

                <hr className="px-5 mobile-nav-line my-2" />

                <div
                  className={`d-flex align-items-center gap-3 py-2 ${
                    window.location.pathname.includes("/admin/users")
                      ? "mobile-sidebar-active-menu"
                      : "mobile-menu-container"
                  }`}
                  onClick={() => onClickActiveMenu("/admin/users")}
                >
                  <ProformaInvoiceIcon styleClassName="sidebar-icon" />
                  <span className="sidebar-text">Proforma Invoice</span>
                </div>

                <hr className="px-5 mobile-nav-line my-2" />

                <div
                  className={`d-flex align-items-center gap-3 py-2 ${
                    window.location.pathname.includes("/admin/channel-partner")
                      ? "mobile-sidebar-active-menu"
                      : "mobile-menu-container"
                  }`}
                  onClick={() => onClickActiveMenu("/admin/channel-partner")}
                >
                  <PurchaseOrderIcon styleClassName="sidebar-icon" />
                  <span className="sidebar-text">Purchase Order</span>
                </div>

                <hr className="px-5 mobile-nav-line my-2" />

                <div
                  className={`d-flex align-items-center gap-3 py-2 ${
                    window.location.pathname.includes(
                      "/admin/relationship-manager"
                    )
                      ? "mobile-sidebar-active-menu"
                      : "mobile-menu-container"
                  }`}
                  onClick={() =>
                    onClickActiveMenu("/admin/relationship-manager")
                  }
                >
                  <InvoiceIcon styleClassName="sidebar-icon" />
                  <span className="sidebar-text">Invoice</span>
                </div>

                <hr className="px-5 mobile-nav-line my-2" />
              </div>
            </div>
          )}
        </div>
        {!mobileNavbarStatus && (
          // <div className="min-vh-100">{props.children}</div>
          <div className="min-vh-100 p-2">
            {window.innerWidth < 768 && outlet}
          </div>
        )}
      </div>
    </>
  );
};

export default UnderWriterLayout;
