import React, { useEffect, useState } from "react";
import ProformaInvoiceTable from "../Lender/LenderTables/PurchaseOrderTable";
import { BsPlusCircleFill, BsSearch } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { getPurchaseOrder } from "../../store/slices/lender";
import {Helmet} from "react-helmet";

const LenderPurchaseOrder = () => {
  const dispatch = useDispatch();
  const proFormaInvoiceTableResponse = useSelector(
    (state) => state.lender.PurchaseData
  );

  const [proformaInvoiceTableRowData, setProformaInvoiceTableRowData] =
    useState([]);
  const [proformInvoiceTableMetaData, setProformInvoiceTableMetaData] =
    useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [searchByProformaID, setSearchByProformaID] = useState("");

  /* TABLE QUERY PARAMS */
  const [tableQueryParams, setTableQueryParams] = useState({
    tableOffset: 0,
    tableLimit: 5,
    tableSort: "proformaId",
    tableOrder: 1,
    currentPage: 0,
    search: "",
  
  });

  useEffect(() => {
    // if (searchByProformaID.length>0) {
    //   setTableQueryParams((prev) => ({ ...prev, search: searchByProformaID }));
    // }

    dispatch(getPurchaseOrder({tableQueryParams,search: searchByProformaID}));

  }, [tableQueryParams, dispatch,searchByProformaID]);

  useEffect(() => {
    if (proFormaInvoiceTableResponse.data) {
     // console.log(proFormaInvoiceTableResponse)
      setProformaInvoiceTableRowData(proFormaInvoiceTableResponse.data.items);
      setProformInvoiceTableMetaData(
        proFormaInvoiceTableResponse.data.metadata
      );
    }
  }, [proFormaInvoiceTableResponse]);

  const handleSearchByProformaIDOnchange = (e) => {
    setSearchByProformaID(e.target.value);
  };


  const handleSearchByProformaIDSubmit = (e) => {
    if (e.key === "Enter") {
     // console.log("Thiis is search by ProfoID", searchByProformaID);
      setTableQueryParams((prev) => ({ ...prev, search: searchByProformaID }));
      // setSearchByProformaID('')
    }
  };

  // console.log("The Response or the Data Itself",proFormaInvoiceTableResponse);
  //console.log("The Data Itself ", proformaInvoiceTableRowData);
  //console.log("The Meta Data ", proformInvoiceTableMetaData);

  // console.log(searchByProformaID.length)

  return (
    <>
   
      <div className="d-flex flex-column gap-4">
      <Helmet>
                <title>Purchase-Order</title>
            </Helmet>
        <div className="bg-white admin-dashboard-card-container p-3">
          <div className="d-flex justify-content-between align-items-center flex-wrap px-2 gap-2 py-2">
            <span className="dashboard-content-title">Purchase Order</span>
            
          </div>

          <div className="d-flex flex-column flex-sm-row align-items-center my-4">
            <div className="d-flex flex-column justify-content-center gap-3 col-12 col-sm-6">
              
              <div className="search-container d-flex align-items-center py-1 px-3 col-12 col-sm-8">
                <input
                  className="search-input-element d-flex flex-grow-1"
                  type="text"
                  placeholder="Search with Purchase ID"
                  value={searchByProformaID}
                  onChange={handleSearchByProformaIDOnchange}
                  // onKeyDown={handleSearchByProformaIDSubmit}

                  // value={search}
                />{" "}
                <BsSearch className="fs-5" />
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex flex-column justify-content-between flex-wrap px-2 gap-2">
          <ProformaInvoiceTable
            className="lender-anchor-and-lender-mapped-table placeholder-wave0"
            tableHeader={[
              { name: "Purchase Order Date", sortValue: "addedDate" },
              { name: "Purchase Order ID", sortValue: "proformaId" },
              { name: "CounterParty", sortValue: "sellerName" },
              { name: "Seller Number", sortValue: "expectedDateOfDelivery" },
              { name: "Invoice Status", sortValue: "paymentTerms" },
              { name: "Total Amount", sortValue: "proformaStatus" },
              // { name: "Status", sortValue: "" },

            ]}
            tableRows={proformaInvoiceTableRowData}
            materialRequestMetaData={proformInvoiceTableMetaData}
            tableQueryParams={tableQueryParams}
            setTableQueryParams={setTableQueryParams}
            isLoading={proFormaInvoiceTableResponse.isLoading}
          />
        </div>
      </div>
    </>
  );
};

export default LenderPurchaseOrder;
