export const DashBoardIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26.826 26.816"
    >
      <g id="noun-dashboard-3644009" transform="translate(-98.002 -28.113)">
        <path
          className="sidebar-icons"
          id="Path_6"
          data-name="Path 6"
          d="M100.684,28.113h6.626a2.882,2.882,0,0,1,2.682,2.682v3.914a2.682,2.682,0,0,1-2.682,2.682h-6.626A2.882,2.882,0,0,1,98,34.709V30.8a2.682,2.682,0,0,1,2.682-2.682Z"
          fill="#2e3c83"
        />
        <path
          className="sidebar-icons"
          id="Path_7"
          data-name="Path 7"
          d="M379.394,28.113h6.626A2.882,2.882,0,0,1,388.7,30.8v9.192a2.682,2.682,0,0,1-2.682,2.682h-6.626a2.882,2.882,0,0,1-2.682-2.682V30.8A2.682,2.682,0,0,1,379.394,28.113Z"
          transform="translate(-263.875 0)"
          fill="#2e3c83"
        />
        <path
          className="sidebar-icons"
          id="Path_8"
          data-name="Path 8"
          d="M100.684,258.44h6.626a2.882,2.882,0,0,1,2.682,2.682v9.192A2.683,2.683,0,0,1,107.311,273h-6.626A2.882,2.882,0,0,1,98,270.315v-9.192A2.682,2.682,0,0,1,100.684,258.44Z"
          transform="translate(0 -218.068)"
          fill="#2e3c83"
        />
        <path
          className="sidebar-icons"
          id="Path_9"
          data-name="Path 9"
          d="M379.394,357.56h6.626a2.882,2.882,0,0,1,2.682,2.682v3.914a2.682,2.682,0,0,1-2.682,2.682h-6.626a2.882,2.882,0,0,1-2.682-2.682v-3.914A2.682,2.682,0,0,1,379.394,357.56Z"
          transform="translate(-263.875 -311.912)"
          fill="#2e3c83"
        />
      </g>
    </svg>
  );
};
export const MaterialRequestIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 23.277 23.277"
    >
      <path
        className="sidebar-icons"
        id="noun-withdraw-4909190"
        d="M124.953,35H108.326a3.325,3.325,0,0,0,0,6.651V54.12a4.157,4.157,0,0,0,4.157,4.157H120.8a4.157,4.157,0,0,0,4.157-4.157V41.651a3.325,3.325,0,0,0,0-6.651Zm-16.626,4.988a1.663,1.663,0,1,1,0-3.325ZM120.555,50.72l-2.743,2.743a1.663,1.663,0,0,1-2.344,0l-2.743-2.743a.832.832,0,1,1,1.181-1.172l1.9,1.9V40.163a.831.831,0,1,1,1.663,0V51.452l1.9-1.9a.832.832,0,1,1,1.181,1.172Zm5.57-11.223a1.663,1.663,0,0,1-1.172.49V36.663a1.658,1.658,0,0,1,1.172,2.835Z"
        transform="translate(-105.001 -35)"
        fill="#2e3c83"
      />
    </svg>
  );
};
export const ProformaInvoiceIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 29.434 20.604"
    >
      <path
        className="sidebar-icons"
        id="Icon_awesome-users"
        data-name="Icon awesome-users"
        d="M4.415,11.08A2.943,2.943,0,1,0,1.472,8.137,2.946,2.946,0,0,0,4.415,11.08Zm20.6,0a2.943,2.943,0,1,0-2.943-2.943A2.946,2.946,0,0,0,25.019,11.08Zm1.472,1.472H23.548a2.935,2.935,0,0,0-2.074.855,6.727,6.727,0,0,1,3.454,5.031h3.035a1.47,1.47,0,0,0,1.472-1.472V15.5A2.946,2.946,0,0,0,26.491,12.552Zm-11.774,0A5.151,5.151,0,1,0,9.566,7.4,5.148,5.148,0,0,0,14.717,12.552Zm3.532,1.472h-.382a7.112,7.112,0,0,1-6.3,0h-.382a5.3,5.3,0,0,0-5.3,5.3v1.325a2.208,2.208,0,0,0,2.208,2.208H21.34a2.208,2.208,0,0,0,2.208-2.208V19.322A5.3,5.3,0,0,0,18.249,14.024ZM7.961,13.407a2.935,2.935,0,0,0-2.074-.855H2.943A2.946,2.946,0,0,0,0,15.5v1.472a1.47,1.47,0,0,0,1.472,1.472H4.5A6.744,6.744,0,0,1,7.961,13.407Z"
        transform="translate(0 -2.25)"
        fill="#2e3c83"
      />
    </svg>
  );
};
export const PurchaseOrderIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 21.505 26.032"
    >
      <path
        className="sidebar-icons"
        id="Icon_map-post-box"
        data-name="Icon map-post-box"
        d="M17.027,2.865A7.288,7.288,0,0,0,9.671,10.17v17.3H12.5V21.813h9.055v5.659h2.83V10.17a7.288,7.288,0,0,0-7.357-7.305ZM2.88,1.44,7.047,8.231h.435A7.391,7.391,0,0,1,12.5,2.03V1.44Z"
        transform="translate(-2.88 -1.44)"
        fill="#2e3c83"
      />
    </svg>
  );
};
export const InvoiceIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 19.426 19.426"
    >
      <g id="noun-bill-1954704" transform="translate(-166.25 -96.25)">
        <path
          className="sidebar-icons"
          data-name="Path 465"
          d="M433.375,98.1h-3.7a.925.925,0,1,1,0-1.85h3.7a.925.925,0,1,1,0,1.85Z"
          transform="translate(-248.624)"
          fill="#2e3c83"
        />
        <path
          className="sidebar-icons"
          data-name="Path 466"
          d="M178.275,96.25h-11.1a.925.925,0,0,0-.925.925v17.576a.925.925,0,0,0,.925.925h11.1a.925.925,0,0,0,.925-.925V97.175A.925.925,0,0,0,178.275,96.25ZM175.5,111.976h-5.55a.463.463,0,1,1,0-.925h5.55a.463.463,0,1,1,0,.925Zm0-3.238h-5.55a.463.463,0,1,1,0-.925h5.55a.463.463,0,1,1,0,.925Zm0-3.7h-5.55a.463.463,0,1,1,0-.925h5.55a.463.463,0,1,1,0,.925Zm0-3.238h-5.55a.463.463,0,1,1,0-.925h5.55a.463.463,0,1,1,0,.925Z"
          fill="#2e3c83"
        />
      </g>
    </svg>
  );
};
export const DisbursedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 14.508 18.452"
    >
      <path
        className="sidebar-icons"
        id="noun-invoice-4676829"
        d="M166.4,31.109l-2.416,1.321-2.422-1.321-2.41,1.321-2.422-1.321-2.422,1.321V48.246a1.315,1.315,0,0,0,1.32,1.315H167.5a1.3,1.3,0,0,0,1.32-1.315V32.43Zm-6.816,4.613h3.956v1.32h-3.956Zm5.934,10.541h-7.911V44.948h7.911Zm0-2.635h-7.911v-1.32h7.911Zm0-2.635h-7.911V39.672h7.911Z"
        transform="translate(-154.311 -31.109)"
        fill="#2e3c83"
        fillRule="evenodd"
      />
    </svg>
  );
};
export const PaymentReconciliationIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 20.417 18.472"
    >
      <path
        className="sidebar-icons"
        id="noun-verified-wallet-3848849"
        d="M127.881,80.367a4.055,4.055,0,0,0-.389,1.235,1.083,1.083,0,0,0-.058.185H117.226a2.431,2.431,0,0,1-2.431-2.431V69.633a2.431,2.431,0,0,1,2.431-2.431h12.639a2.431,2.431,0,0,1,2.431,2.431v1.944h-3.4a2.917,2.917,0,1,0,0,5.833h3.4V77.9a2.646,2.646,0,0,0-.486,0,3.9,3.9,0,0,0-.622.048,4.336,4.336,0,0,0-3.306,2.421Zm.972-7.778h3.889v3.889h-3.889a1.944,1.944,0,0,1,0-3.889Zm.224,1.419a.476.476,0,0,0-.525.107.467.467,0,0,0,0,.681.438.438,0,0,0,.34.146.486.486,0,0,0,.34-.826.35.35,0,0,0-.156-.107Zm6.135,8.264a3.4,3.4,0,1,1-6.806,0,2.671,2.671,0,0,1,0-.486,3.066,3.066,0,0,1,.3-.972,3.374,3.374,0,0,1,2.615-1.944,3.065,3.065,0,0,1,.972,0,3.4,3.4,0,0,1,2.917,3.4Zm-2.149-1.206h0a.486.486,0,0,0-.681.117l-.875,1.215-.272-.4h0a.486.486,0,1,0-.8.544l.651.972a.475.475,0,0,0,.788.01l1.3-1.74a.486.486,0,0,0-.117-.719Z"
        transform="translate(-114.795 -67.203)"
        fill="#2e3c83"
      />
    </svg>
  );
};
export const CounterPartyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 19.081 20.663"
    >
      <g id="noun-person-2237338" transform="translate(-118.864 -29.7)">
        <path
          className="sidebar-icons"
          id="Path_467"
          data-name="Path 467"
          d="M201.025,36.4a6.7,6.7,0,1,1-6.7-6.7,6.7,6.7,0,0,1,6.7,6.7"
          transform="translate(-65.918)"
          fill="#2e3c83"
        />
        <path
          className="sidebar-icons"
          id="Path_468"
          data-name="Path 468"
          d="M121.815,393.2h13.178a2.951,2.951,0,1,0,0-5.9H121.815a2.951,2.951,0,0,0,0,5.9Z"
          transform="translate(0 -342.839)"
          fill="#2e3c83"
        />
      </g>
    </svg>
  );
};
export const AgreeMentIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 15.719 21.613"
    >
      <path
        className="sidebar-icons"
        id="noun-contract-2850030"
        d="M173.16,39.057h5.324l-5.324,5.315ZM171.2,44.952h-6.877a.983.983,0,0,1-.983-.983V24.321a.983.983,0,0,1,.983-.983h13.754a.983.983,0,0,1,.983.983V37.092h-6.877a.973.973,0,0,0-.982.982Zm-3.93-16.7a.982.982,0,0,0,.982.982h5.895a.982.982,0,1,0,0-1.965h-5.895a.982.982,0,0,0-.982.982Zm.982,4.912h5.895a.982.982,0,0,0,0-1.965h-5.895a.982.982,0,0,0,0,1.965Z"
        transform="translate(-163.336 -23.338)"
        fill="#2e3c83"
      />
    </svg>
  );
};
