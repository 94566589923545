
import { Fragment, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AdminCamContext } from "../../../../context/admin/cam/adminCamContext";

export const AdminCamDirAndPartners = () => {
	const [reduxState, setReduxState] = useState([]);
	const additionalReduxState = useSelector(state=>state?.admin?.camByEmailIdData?.data?.data?.directorPartnerDetails);

	const [ newDirAndPartnersData, setNewDirAndPartnersData ] = useState([]);
	
	const { editEnabled } = useContext(AdminCamContext);
	
	useEffect(()=>{
		if(reduxState != additionalReduxState){
			setReduxState(additionalReduxState);
			setNewDirAndPartnersData(additionalReduxState);
		}
	},[additionalReduxState, reduxState]);

	useState(() => {
		setNewDirAndPartnersData([ ...reduxState ]);
	}, []);

	function changeValue(e, index) {}

	return (
		<Fragment>
			<div className="row">
				{reduxState.length!=0 && reduxState.map((val,indx)=><div className="col-md-6 mt-2" key={"Dir"+indx}>
					<div className="cam-data-card">
						<div className="row">
							<div className="col-md-2 mb-2">
								Name: 
							</div>
							<div className="col-md-3 mb-2">
								{val.name}
							</div>
							<div className="col-md-2 mb-2">
								Designation: 
							</div>
							<div className="col-md-5 mb-2">
								{!editEnabled ?
									val.designation
									: 
									<input type="text" className="form-control" name="designation" value={ newDirAndPartnersData[indx].designation } onChange={ e => changeValue(e, indx) }/>
								}
							</div>
							
							<div className="col-md-2 mb-2">
								Address: 
							</div>
							<div className="col-md-10 mb-2" >
								{!editEnabled ?
									val.address
									:
									<input type="text" className="form-control" name="address" value={ newDirAndPartnersData[indx].address } onChange={ e => changeValue(e, indx) } />
								}
							</div>
							<div className="col-md-2 mb-2">
								Pan: 
							</div>
							<div className="col-md-3 mb-2">
								{!editEnabled ?
									val.panCard
								:
									<input type="text" className="form-control" name="panCard" value={ newDirAndPartnersData[indx]?.panCard } onChange={ e => changeValue(e, indx) } />
								}
							</div>
							<div className="col-md-2">
							</div>
							<div className="col-md-5" >
							</div>
							<div className="col-md-2 mb-2">
								Net worth: 
							</div>
							<div className="col-md-3 mb-2">
								{!editEnabled ?
									val.networth
									:
									<input className="form-control" type="text" value={ newDirAndPartnersData[indx].networth } name="networth" onChange={ e => changeValue(e, indx) } />
								}
							</div>
							<div className="col-md-2 mb-2">
								Stack: 
							</div>
							<div className="col-md-5 mb-2">
							{!editEnabled ?
									val.stack
									:
									<input className="form-control" type="text" value={ newDirAndPartnersData[indx].stack } name="stack" onChange={ e => changeValue(e, indx) } />
								}
							</div>
							<div className="col-md-2 mb-2">
								Status: 
							</div>
							<div className="col-md-3 mb-2">
								{	!editEnabled ?
									val.status=="Active"?<span className="text-success">Active</span>:(val.status=="NA"?<span className="text-secondary">NA</span>:<span className="text-danger">Inactive</span>)
									: <select name="status" className="form-control" onChange={ e => changeValue(e, indx) } value={ newDirAndPartnersData[indx].status }>
										<option value="Active">Active</option>
										<option value="Inactive">Inactive</option>
										<option value="NA">NA</option>
									</select>
								}
							</div>
						</div>
					</div>
				</div>)}
			</div>
		</Fragment>
	);
};