import { Fragment, useEffect, useState, useContext } from "react";
import { v4 } from "uuid";
import { useSelector } from "react-redux";
import { AdminCamContext } from "../../../../context/admin/cam/adminCamContext";
import {AdminKycActions} from "../../../../context/admin/cam/actionTypes";

export const AdminCamAdditionalDetails = () => {
	const [reduxState, setReduxState] = useState([]);
	const [additionalDetails, setAdditionalDetails] = useState([])
	const additionalReduxState = useSelector(state => state?.admin?.camByEmailIdData?.data?.data?.otherInfo);

	const { editEnabled,additionalDetailsDispatch } = useContext(AdminCamContext);

	// console.log("additional info", additionalReduxState);

	// useEffect(() => {
	// 	if (reduxState !== additionalReduxState) {

	// 		if (Array.isArray(additionalReduxState)) {
	// 			let tempArr = [];
	// 			for (let obj of additionalReduxState) {
	
	// 				let temp = {
	// 					otherInfoFiveKey: '',
	// 					otherInfoFiveValue: '',
	// 					otherInfoFourKey: '',
	// 					otherInfoFourValue: '',
	// 					otherInfoOneKey: '',
	// 					otherInfoOneValue: '',
	// 					otherInfoThreeKey: '',
	// 					otherInfoThreeValue: '',
	// 					otherInfoTwoKey: '',
	// 					otherInfoTwoValue: '',
	// 					id: v4(),
	// 				}
	
	// 				temp.otherInfoFiveKey = obj.otherInfoFiveKey;
	// 				temp.otherInfoFiveValue = obj.otherInfoFiveValue;
	// 				temp.otherInfoFourKey = obj.otherInfoFourKey;
	// 				temp.otherInfoFourValue = obj.otherInfoFourValue;
	// 				temp.otherInfoOneKey = obj.otherInfoOneKey;
	// 				temp.otherInfoOneValue = obj.otherInfoOneValue;
	// 				temp.otherInfoThreeKey = obj.otherInfoThreeKey;
	// 				temp.otherInfoTwoKey = obj.otherInfoTwoKey;
	// 				temp.otherInfoTwoValue = obj.otherInfoTwoValue;
	
	// 				tempArr.push(temp);
	// 			}
	// 			setAdditionalDetails(tempArr);
	// 			setReduxState(additionalReduxState);
	// 		}
	// 	}

	// }, [ additionalReduxState, reduxState ])
	
	useEffect(()=>{
		setAdditionalDetails(additionalReduxState[0]);
	},[additionalReduxState])

	const handleChangeValue = (e) => {
		const { name, value } = e.target;
		// console.log(additionalDetails)
		setAdditionalDetails(prev => {
			return {
				...prev,
				[name]: value
			}
		})
	};

	useEffect(()=>{
		additionalDetailsDispatch({type:AdminKycActions.ADDITIONAL_INFO.CHANGE_DETAIL, payload: additionalDetails});
	},[additionalDetails])

	return (
		<Fragment>
			{editEnabled?(
				// additionalDetails.map((val, indx) => 
				<div className="row m-0">
					<div className="d-flex justify-content-around m-1">
						<input name="otherInfoOneKey" value={additionalDetails?.otherInfoOneKey} className="form-control" style={{width:'30%'}} onChange={(e)=>handleChangeValue(e)}/>
						<input name="otherInfoOneValue" value={additionalDetails?.otherInfoOneValue} className="form-control" style={{width:'30%'}} onChange={(e)=>handleChangeValue(e)}/>
					</div>
					<div className="d-flex justify-content-around m-1">
						<input name="otherInfoTwoKey" value={additionalDetails?.otherInfoTwoKey}className="form-control" style={{width:'30%'}} onChange={(e)=>handleChangeValue(e)}/>
						<input name="otherInfoTwoValue" value={additionalDetails?.otherInfoTwoValue} className="form-control" style={{width:'30%'}} onChange={(e)=>handleChangeValue(e)}/>
					</div>
					<div className="d-flex justify-content-around m-1">
						<input name="otherInfoThreeKey" value={additionalDetails?.otherInfoThreeKey} className="form-control" style={{width:'30%'}} onChange={(e)=>handleChangeValue(e)}/>
						<input name="otherInfoThreeValue" value={additionalDetails?.otherInfoThreeValue} className="form-control" style={{width:'30%'}} onChange={(e)=>handleChangeValue(e)}/>
					</div>
					<div className="d-flex justify-content-around m-1">
						<input name="otherInfoFourKey" value={additionalDetails?.otherInfoFourKey} className="form-control" style={{width:'30%'}} onChange={(e)=>handleChangeValue(e)}/>
						<input name="otherInfoFourValue" value={additionalDetails?.otherInfoFourValue} className="form-control" style={{width:'30%'}} onChange={(e)=>handleChangeValue(e)}/>
					</div>
					<div className="d-flex justify-content-around m-1">
						<input name="otherInfoFiveKey" value={additionalDetails?.otherInfoFiveKey} className="form-control" style={{width:'30%'}} onChange={(e)=>handleChangeValue(e)}/>
						<input name="otherInfoFiveValue" value={additionalDetails?.otherInfoFiveValue} className="form-control" style={{width:'30%'}} onChange={(e)=>handleChangeValue(e)}/>
					</div>
				</div>
				// )
			):(
			// additionalDetails.map((val, indx) => 
			<div className="row m-0">
				<div className="col-md-2">
					{additionalDetails.otherInfoOneKey}
					{additionalDetails.otherInfoOneValue}
				</div>
				<div className="col-md-2">
					{additionalDetails.otherInfoTwoKey}
					{additionalDetails.otherInfoTwoValue}
				</div>
				<div className="col-md-2">
					{additionalDetails.otherInfoThreeKey}
					{additionalDetails.otherInfoThreeValue}
				</div>
				<div className="col-md-2">
					{additionalDetails.otherInfoFourKey}
					{additionalDetails.otherInfoFourValue}
				</div>
				<div className="col-md-2">
					{additionalDetails.otherInfoFiveKey}
					{additionalDetails.otherInfoFiveValue}
				</div>
			</div>
			// )
		)
			}
		</Fragment>
	);
};