import { HiOutlineArrowNarrowDown } from "react-icons/hi";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import ReusableModal from "../../../layout/GenericLayout/ReusableModal";
import { GiNotebook } from "react-icons/gi";

/* MODALS OR CHILDREN COMPONENTS */
const StatusHistoryModal = (props) => {
  /* OBJECT DESTRUCTURE */
  const { activeRemarks } = props;

  /* TABLE OPTIONS */
  const dateOptions = {
    day: "numeric",
    year: "numeric",
    month: "short",
  };

  const timeOptions = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };
  return (
    <div className="">
      <span className="fs-4 fw-semibold">Status History</span>
      <div>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Sl No.</th>
              <th scope="col">Remarks</th>
              <th scope="col">Date</th>
            </tr>
          </thead>
          <tbody>
            {activeRemarks.map((eachRows, index) => (
              <tr key={`${eachRows.remark}${eachRows.timestamp}`} scope="col">
                <th scope="row">{index + 1}</th>
                <td>{eachRows.remark}</td>
                <td className="d-flex flex-column">
                  <span className="">
                    {new Date(eachRows.timestamp).toLocaleDateString(
                      "en-IN",
                      dateOptions
                    )}
                  </span>
                  <span className="text-uppercase">
                    {new Date(eachRows.timestamp).toLocaleTimeString(
                      "en-IN",
                      timeOptions
                    )}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const AdminDashboardTable = (props) => {
  /* FUNCTION DECLARATIONS */
  const navigate = useNavigate();

  console.log(props);

  /* USESTATES */
  const [showModal, setShowModal] = useState(false);
  const [activeRemarks, setActiveRemarks] = useState([]);

  /* EVENT LISTENERS */
  const toggleModal = () => {
    setShowModal((prev) => !prev);
  };

  const onClickAccountHolderName = (emailId) => {
    navigate(`/admin/dashboard/kyc/${emailId}`, { state: { isUser: false } });
  };

  const onClickStatus = (remarks) => {
    // console.log(remarks);
    setActiveRemarks(remarks);
    setShowModal(!showModal);
  };

  /* TABLE OPTIONS */
  const totalPage = 5;
  const dateOptions = {
    day: "numeric",
    year: "numeric",
    month: "short",
  };

  const timeOptions = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  return (
    <>
      {/* Modal Content */}
      {showModal && (
        <ReusableModal
          showModal={showModal}
          toggleModal={toggleModal}
          modalContent={<StatusHistoryModal activeRemarks={activeRemarks} />}
          modalSizeClass="modal-lg"
        />
      )}
      <div className="table-container">
        <table className={props.className}>
          <thead>
            <tr className="table-header">
              {props.tableHeader.map((eachHeader) => (
                <th key={eachHeader} className="each-header" scope="col">
                  {eachHeader}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {props.tableRows.map((eachRows, index) => (
              <>
              <tr key={eachRows.emailId} className="each-body py-4" scope="col">
                <td className="">
                  <div className="">
                    {new Date(eachRows.addedDate).toLocaleDateString(
                      "en-IN",
                      dateOptions
                    )}
                  </div>
                </td>
                <td className="">
                  <div className="">
                    {new Date(eachRows.addedDate).toLocaleDateString(
                      "en-IN",
                      dateOptions
                    )}
                  </div>
                  <div className="text-uppercase">
                    {new Date(eachRows.addedDate).toLocaleTimeString(
                      "en-IN",
                      timeOptions
                    )}
                  </div>
                </td>
                {/* <td className="">
                  <div className="">
                    {new Date(eachRows.addedDate).toLocaleDateString(
                      "en-IN",
                      dateOptions
                    )}
                  </div>
                  <div className=" text-uppercase">
                    {new Date(eachRows.addedDate).toLocaleTimeString(
                      "en-IN",
                      timeOptions
                    )}
                  </div>
                </td> */}
                <td
                  // className="text-primary"
                  onClick={() => onClickAccountHolderName(eachRows.emailId)}
                >
                  {eachRows.companyFullName}
                </td>
                <td>{eachRows.emailId}</td>
                <td
                  className={`${
                    eachRows.roles[0] === "seller"
                      ? "text-warning"
                      : "text-primary"
                  }`}
                >
                  {(eachRows.roles[0] === "seller" && "Anchor") ||
                    (eachRows.roles[0] === "buyer" && "Counter Party")}
                </td>{" "}
                <td>
                  <span className="text-capitalize status p-1 px-3">
                    {eachRows.status}
                  </span>
                </td>
                <td
                  className=""
                  onClick={() => onClickStatus(eachRows.remarks)}
                  style={{ cursor: "pointer" }}
                >
                  <GiNotebook className="text-primary display-6" />
                </td>
              </tr>
             
              </>
            ))}
          </tbody>
        </table>
        <div className="d-flex justify-content-end px-2">
          <span
            // onClick={props.onClickViewMoreTableData}
            className="d-flex align-items-center gap-1 my-3 view-more-text"
          >
            View more <HiOutlineArrowNarrowDown className="fs-5" />
          </span>
        </div>
      </div>
      <div className="d-flex align-items-center gap-2 page-data-text-container mt-3 px-3">
        <span className="page-data-text"></span>
      </div>
    </>
  );
};

export default AdminDashboardTable;
