import { Fragment, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AdminCamContext } from "../../../../context/admin/cam/adminCamContext";
import {AdminKycActions} from	"../../../../context/admin/cam/actionTypes";

export const AdminCamSecurity = () => {
	const [ newSecurity, setNewSecurity ] = useState("");

	const { editEnabled,securityStateDispatch } = useContext(AdminCamContext);
	
	const additionalReduxState = useSelector(state=>state?.admin?.camByEmailIdData?.data?.data?.camDetails?.security);
	useEffect(()=>{
		setNewSecurity(additionalReduxState);
	},[additionalReduxState]);

	useEffect(() => {
		securityStateDispatch({ type:AdminKycActions.SECURITY.UPDATE_DETAILS, payload: newSecurity })
	}, [ newSecurity ])

	return (
		<Fragment>

			<div className="row">
				<div className="col-md-4">
					{
						!editEnabled ?
							<span className="fw-bold">
								{newSecurity}
							</span>
						: 
						<input className="form-control" value={ newSecurity } onChange={ e => setNewSecurity(e.target.value) } type="text" />
					}
				</div>
			</div>
		</Fragment>
	);
};