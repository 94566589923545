import React, { useEffect, useState } from "react";
import { BsPlusCircleFill,BsSearch } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import ReusableModal from "../../layout/GenericLayout/ReusableModal";
import { getUnderWriter } from "../../store/slices/lender";
import ProformaInvoiceTable from "../Lender/LenderTables/LenderUnderWriterTable";
import EditComp from "./EditUnderWriter";
import {Helmet} from "react-helmet";

const Edit = ({ editData, screenData }) => {
  return <EditComp editData={editData} screenData={screenData} />;
};

const LenderUnderWriter = () => {
  const dispatch = useDispatch();
  const [editModal, setEditModal] = useState(false);
  const [editData, setEditData] = useState("");
  const [screenData, setscreenData] = useState("");
  const [addData, setaddData] = useState(false);
  const proFormaInvoiceTableResponse = useSelector(
    (state) => state.lender.underWriter
  );

  const [proformaInvoiceTableRowData, setProformaInvoiceTableRowData] =
    useState([]);
  const [proformInvoiceTableMetaData, setProformInvoiceTableMetaData] =
    useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [searchByName, setSearchByName] = useState("");

  /* TABLE QUERY PARAMS */
  const [tableQueryParams, setTableQueryParams] = useState({
    tableOffset: 0,
    tableLimit: 5,
    tableSort: "proformaId",
    tableOrder: 1,
    currentPage: 0,
    search: "",
  });

  useEffect(() => {
    dispatch(getUnderWriter(tableQueryParams));
  }, [tableQueryParams, dispatch]);

  useEffect(() => {
    if (proFormaInvoiceTableResponse.data) {
      // console.log(proFormaInvoiceTableResponse);
      setProformaInvoiceTableRowData(proFormaInvoiceTableResponse?.data?.items);
      setProformInvoiceTableMetaData(
        proFormaInvoiceTableResponse?.data?.metadata
      );
    }
  }, [proFormaInvoiceTableResponse]);

  const handleSearchByName = (e) => {
    setSearchByName(e.target.value);
  };

  useEffect(() => {
      setTableQueryParams((prev) => ({ ...prev, search: searchByName }));
  }, [searchByName]);

  // const handleSearchByNameSubmit = (e) => {
  //   if (e.key === "Enter") {
  //     console.log("Thiis is search by ProfoID", searchByName);
  //     setTableQueryParams((prev) => ({ ...prev, search: searchByName }));
  //     // setSearchByName('')
  //   }
  // };

  const handleEdit = (data, data1) => {
    setscreenData(data1);
    if (proformaInvoiceTableRowData.length !== 0) {
      for (var i = 0; i < proformaInvoiceTableRowData.length; i++) {
        if (proformaInvoiceTableRowData[i].customerId === data) {
          setEditData(proformaInvoiceTableRowData[i]);
        }
      }
    }
    setEditModal(!editModal);
  };

  const toggleRMAddModal = () => {
    setaddData(!addData);
  };

  return (
    <>
      <ReusableModal
        showModal={editModal}
        toggleModal={handleEdit}
        modalContent={
          editData !== "" && (
            <Edit editData={editData} screenData={screenData} />
          )
        }
        modalSizeClass="modal-xl"
      />
      <div className="d-flex flex-column gap-4">
      <Helmet>
                <title>UnderWriter</title>
            </Helmet>
        <div className="bg-white admin-dashboard-card-container p-3">
          <div className="d-flex justify-content-between align-items-center flex-wrap px-2 gap-2 py-2">
            <span className="dashboard-content-title">Underwriter Details</span>
            <div
              onClick={toggleRMAddModal}
              className="d-flex align-items-center gap-2 border border-primary px-3 py-1 rounded"
              type="button"
            >
              <div
              // className={`rm-add-button ${isHovered ? "show" : ""}`}
              >
                ADD Underwriter
              </div>
              <BsPlusCircleFill className="rm-plus-button" />
            </div>
          </div>

          

          <div className="d-flex flex-column flex-sm-row align-items-center my-3">
            <div className="d-flex flex-column justify-content-center gap-3 col-12 col-sm-6">
              <div className="search-container d-flex align-items-center py-1 px-3 col-6 col-sm-8">
                <input
                  className="search-input-element d-flex flex-grow-1"
                  type="text"
                  placeholder="Search with Name"
                  value={searchByName}
                  onChange={handleSearchByName}
                />{" "}
                <BsSearch className="fs-5" />
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex flex-column justify-content-between flex-wrap px-2 gap-2">
          <ProformaInvoiceTable
            className="lender-anchor-and-lender-mapped-table placeholder-wave0"
            tableHeader={[
              { name: "Name", sortValue: "addedDate" },
              { name: "Phone No", sortValue: "proformaId" },
              { name: "Email Id", sortValue: "sellerName" },
              { name: "Status", sortValue: "expectedDateOfDelivery" },
              // { name: "Action", sortValue: "" },
            ]}
            tableRows={proformaInvoiceTableRowData}
            materialRequestMetaData={proformInvoiceTableMetaData}
            tableQueryParams={tableQueryParams}
            setTableQueryParams={setTableQueryParams}
            isLoading={proFormaInvoiceTableResponse.isLoading}
            handleEdit={handleEdit}
            addData={addData}
            toggleRMAddModal={toggleRMAddModal}
          />
        </div>
      </div>
    </>
  );
};

export default LenderUnderWriter;
