import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import {
  getAnchorAndLenderMapped,
  getSelectAnchor,
  getSelectLender,
  updateAnchorAndLenderMapped,
} from "../../store/slices/admin";
import Loader from "../../layout/GenericLayout/Loader";
import AdminAnchoAndLenderMappedTable from "./AdminTables/AdminAnchoAndLenderMappedTable";
import { RiArrowGoBackFill } from "react-icons/ri";
import { toastMessage } from "../../layout/GenericLayout/ToastMessage";
import {Helmet} from "react-helmet";

/* PARENT COMPONENT */
const AdminAnchorAndLender = () => {
  /* FUNCTION DECLARATIONS */
  const dispatch = useDispatch();

  /* USESELECTOR */
  const selectAnchorData = useSelector((state) => state.admin.selectAnchorData);
  const selectLenderData = useSelector((state) => state.admin.selectLenderData);
  const anchorAndLenderMappedData = useSelector(
    (state) => state.admin.anchorAndLenderMappedData
  );

  // const anchorAndLenderUpdatedMapData = useSelector(
  //   (state) => state.admin.anchorAndLenderUpdatedMapData
  // );

  /* USESTATES */
  const [mappedTableRows, setMappedTableRows] = useState([]);
  const [activeMappingData, setActiveMappingData] = useState({
    anchor: "",
    lender: "",
  });
  const [newMap, setNewMap] = useState('');

  /* USEEFFECTS */
  useEffect(() => {
    dispatch(getAnchorAndLenderMapped())
      .unwrap()
      .then((response) => {
        setMappedTableRows(response.data);
      })
      .catch((error) => {
        console.log("error : ", error);
      });
  }, [dispatch,newMap]);

  /* EVENT LISTENERS */
  const onFocusSelectAnchor = () => {
    dispatch(getSelectAnchor());
  };

  const onFocusSelectLender = () => {
    dispatch(getSelectLender());
  };

  const onChangeSelect = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setActiveMappingData((prev) => ({ ...prev, [name]: value }));
  };

  const onClickDone = () => {
    const body = {
      distributorId: activeMappingData.anchor,
      lenderId: activeMappingData.lender,
      status: "active",
    };
    dispatch(updateAnchorAndLenderMapped(body))
      .unwrap()
      .then((response) => {
        toastMessage(response.success, "success");
        setNewMap(response?.success);
      })
      .catch((error) => {
        toastMessage(error.message, "error");
      });
  };

  const onClickReset = () => {
    setActiveMappingData((prev) => ({ ...prev, anchor: "", lender: "" }));
  };

  return (
    <div className="d-flex flex-column gap-4">
      <Helmet>
                <title>Anchor&Lender</title>
            </Helmet>
      <div className="bg-white admin-dashboard-card-container p-3">
        <p className="dashboard-content-title m-0">Anchor & Lender Mapping</p>
        <div className="d-flex justify-content-between align-items-center my-4">
          <span className="anchor-and-lender-highlight-title">
            Create Anchor & Lender Mapping
          </span>
          <div className="d-flex flex-column flex-md-row align-items-center gap-3">
            <button
              disabled={
                activeMappingData.anchor === "" &&
                activeMappingData.lender === ""
              }
              onClick={onClickReset}
              className="btn btn-outline-secondary anchor-and-lender-done-button px-4 py-1 d-flex align-items-center gap-2"
            >
              <RiArrowGoBackFill />
              Reset
            </button>
            <button
              onClick={onClickDone}
              className="btn btn-outline-primary anchor-and-lender-done-button px-4 py-1"
            >
              Done
            </button>
          </div>
        </div>

        <div className="d-flex flex-column flex-sm-row align-items-center my-4">
          <div className="d-flex flex-column justify-content-center gap-3 col-12 col-sm-6">
            <label
              htmlFor="selectAnchor"
              className="anchor-and-lender-select-label"
            >
              Select Anchor
            </label>
            <select
              onFocus={onFocusSelectAnchor}
              value={activeMappingData.anchor}
              onChange={onChangeSelect}
              name="anchor"
              className="form-select w-75"
              id="selectAnchor"
            >
              <option value="" disabled>
              Choose Anchor
              </option>

              {selectAnchorData.isLoading && (
                <option disabled>Loading...</option>
              )}

              {selectAnchorData.data &&
                selectAnchorData.data.success.map((eachanchorData) => (
                  <option
                    key={eachanchorData.customerId}
                    value={eachanchorData.customerId}
                  >
                    {eachanchorData.companyFullName}
                  </option>
                ))}
            </select>
          </div>

          <div className="d-flex flex-column justify-content-start justify-content-sm-center gap-3 col-12 col-sm-6 ">
            <label
              htmlFor="select_lender"
              className="anchor-and-lender-select-label"
            >
              Select Lender
            </label>
            <select
              value={activeMappingData.lender}
              onFocus={onFocusSelectLender}
              onChange={onChangeSelect}
              name="lender"
              className="form-select w-75"
              id="select_lender"
            >
              <option value="" disabled>
              Choose Lender
              </option>

              {selectLenderData.isLoading && (
                <option disabled>Loading...</option>
              )}

              {selectLenderData.data &&
                selectLenderData.data.map((eachLenderData) => (
                  <option
                    key={eachLenderData.customerId}
                    value={eachLenderData.customerId}
                  >
                    {eachLenderData.fullName}
                  </option>
                ))}
            </select>
          </div>
        </div>
      </div>

      <div className="d-flex flex-column justify-content-between flex-wrap px-2 gap-2">
        <span className="dashboard-content-title">
          Anchor & Lender Mapping Details
        </span>
        {anchorAndLenderMappedData.isLoading ? (
          <Loader className="my-5" />
        ) : (
          <AdminAnchoAndLenderMappedTable
            className="admin-anchor-and-lender-mapped-table"
            tableHeader={["Sl No.", "Anchor Name", "Lender Name", "Status"]}
            tableRows={mappedTableRows}
          />
        )}
      </div>
    </div>
  );
};

export default AdminAnchorAndLender;
