import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllChannelPartner } from "../../store/slices/admin";
import Loader from "../../layout/GenericLayout/Loader";
import AdminChannelPartnerTable from "./AdminTables/AdminChannelPartnerTable";
import {Helmet} from "react-helmet";

const AdminChannelPartner = () => {
  /* FUNCTION DECLARATIONS */
  const dispatch = useDispatch();

  /* USESELECTOR */
  const channelPartnerData = useSelector(
    (state) => state.admin.channelPartnerData
  );

  /* USESTATES */
  const [channelPartnerDataTableRows, setChannelDataTableRows] = useState([]);

  /* USEEFFECTS */
  useEffect(() => {
    dispatch(getAllChannelPartner())
      .unwrap()
      .then((response) => {
        setChannelDataTableRows(response.success);
      })
      .catch((error) => {
        console.log("error : ", error);
      });
  }, [dispatch]);

  return (
    <div className="d-flex flex-column gap-4">
      <Helmet>
                <title>Channel-Partner</title>
            </Helmet>
      <div className="bg-white admin-dashboard-card-container px-3 pt-3 d-flex flex-column gap-2 pb-5">
        <span className="dashboard-content-title">Channel Details</span>
        {channelPartnerData.isLoading ? (
          <Loader className="my-5" />
        ) : (
          <AdminChannelPartnerTable
            className="admin-channel-partner-data-table"
            tableHeader={[
              "Customer ID",
              "Anchor",
              "Email ID",
              "Status",
            ]}
            tableRows={channelPartnerDataTableRows}
          />
        )}
      </div>
    </div>
  );
};

export default AdminChannelPartner;
