const PersonalKYC = ({ scoreCardDetails }) => {
  return (
    <div className="py-2 d-flex flex-column justify-content-around h-100">
      <div>
        <span className="fw-semibold text-secondary">Aadhaar Name : </span>
        <span className="fw-semibold text-success">
          {scoreCardDetails?.success?.aadharSDK?.name || (
            <span className="text-danger mx-3">N/A</span>
          )}
        </span>
      </div>

      <div>
        <span className="fw-semibold text-secondary">Date Of Birth : </span>
        <span className="fw-semibold text-success">
          {scoreCardDetails?.success?.aadharSDK?.dateOfBirth || (
            <span className="text-danger mx-3">N/A</span>
          )}
        </span>
      </div>

      <div>
        <span className="fw-semibold text-secondary">Gender : </span>
        <span className="fw-semibold text-success">
          {scoreCardDetails?.success?.aadharSDK?.gender || (
            <span className="text-danger mx-3">N/A</span>
          )}
        </span>
      </div>

      <div>
        <span className="fw-semibold text-secondary">PAN Number : </span>
        <span className="fw-semibold text-success">
          {scoreCardDetails?.success?.PANNumber || (
            <span className="text-danger mx-3">N/A</span>
          )}
        </span>
      </div>

      <div>
        <span className="fw-semibold text-secondary">Contact number : </span>
        <span className="fw-semibold text-success">
          {scoreCardDetails?.success?.mobileNumber || (
            <span className="text-danger mx-3">N/A</span>
          )}
        </span>
      </div>
    </div>
  );
};

export default PersonalKYC;
