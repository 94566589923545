import React, { useState } from "react";
import BannerImg from "../assests/images/rodion-kutsaiev-JKGjV3E-HnY-unsplash@2x.png";
import atonLogo from "../assests/images/Aton-logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { MdEmail } from "react-icons/md";
import { IoIosUnlock, IoIosLock } from "react-icons/io";
import { InputValidator } from "../layout/GenericLayout/InputValidator";
import { useDispatch, useSelector } from "react-redux";
import { updateSignUp } from "../store/slices/generic";
import { toastMessage } from "../layout/GenericLayout/ToastMessage";
import {Helmet} from "react-helmet";
const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    mobileNumber: "",
    role: location.state.activeRole,
  });
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);
  const [terms, setTerms] = useState(false);
  const [isError, setIsError] = useState(null);

  const signUpData = useSelector((state) => state.generic.signUpData);

  const onChangeCredentials = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setCredentials((prev) => ({ ...prev, [name]: value }));
  };

  const onChangeTerms = (event) => {
    setTerms(event.target.checked);
  };

  const onSubmitSignUp = (event) => {
    event.preventDefault();
    if (
      !isError?.email &&
      !isError.password &&
      !isError?.confirmPassword &&
      !isError?.mobileNumber
    ) {
      // navigate("/mobile-verification");
      const body = {
        answer: "",
        emailId: credentials.email.toLowerCase(),
        mobileNumber: credentials.mobileNumber,
        password: credentials.password,
        question: "",
        roles: [credentials.role],
        termsAndConditions: terms,
      };

      dispatch(updateSignUp(body))
        .unwrap()
        .then((response) => {
          console.log("response : ", response);
          toastMessage(response.success, "success");
          navigate("/");
        })
        .catch((error) => {
          console.log("error : ", error);
          toastMessage(error.message, "error");
        });
    }
  };

  const onClickPasswordVisibilityStatus = () => {
    setIsPasswordVisible((prev) => !prev);
  };

  const onClickConfirmPasswordVisibilityStatus = () => {
    setIsConfirmPasswordVisible((prev) => !prev);
  };

  const onClickSignIn = () => {
    navigate("/");
  };

  const onBlurInputFields = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    const validator = {
      email: {
        regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        message: "Please enter a valid email address",
      },
      mobileNumber: {
        regex:
        /^(?!(9999999999|8888888888|7777777777|6666666666|9876543210)$)[6-9][0-9]{9}$/,
        message: "Please enter a valid mobile number",
      },
      password: {
        regex: /^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()\-__+.]){1,}).{8,15}$/gm,
        message: value === "" ? "Password is required" : "8-15 chars long, at least 1 uppercase, 1 lowercase, 1 number, 1 special character required",
      },
      confirmPassword: {
        regex: /^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()\-__+.]){1,}).{8,15}$/gm,
        message: value === "" ? "Confirm Password is required" : value !== credentials.password ? "does not match with password" : "" ,
      },
    };

    let errorMessage = InputValidator({
      regex: validator[name].regex,
      value,
      message: validator[name].message,
    });

    if (name === 'confirmPassword' && credentials.password !== credentials.confirmPassword) {
      errorMessage ||= "Confirm Password should match Password";
    }

    setIsError((prev) => ({
      ...prev,
      [name]: errorMessage,
    }));
  };

  return (
    <>
      <form
        onSubmit={onSubmitSignUp}
        style={{ position: "relative", width: "100%", height: "100%" }}
      >
        <Helmet>
                <title>Sign-Up</title>
            </Helmet>
        <div className="row m-0">
          <div className="col-md-1"></div>
          <div className="col-md-10 mt-3">
            <div className="row d-flex align-items-center">
              <div className="col-md-8">
                <span className="txt-welc">Welcome!</span> <br />
                <span className="txt-yr-acc">Sign up for your account</span>
              </div>
              <div className="col-md-4 d-flex justify-content-end">
                <img src={atonLogo} style={{ width: "80px" }} />
              </div>
            </div>
          </div>
          <div className="row mx-auto">
            <div className="col-md-1"></div>
            <div className="col-md-10 mt-2">
              <div className="row px-3 mb-1">
                <label className="label-text m-0">Email</label>
                <div
                  className={`input-container form-control d-flex align-items-center ${
                    isError?.email && "invalid-input"
                  }`}
                >
                  <input
                    value={credentials.email}
                    onBlur={onBlurInputFields}
                    onChange={onChangeCredentials}
                    className="input-element d-flex flex-grow-1"
                    placeholder="Enter Email Id"
                    name="email"
                    type="email"
                    // required
                  />
                  <MdEmail className="input-icon" />
                </div>
                <span className="text-danger px-1">{isError?.email}</span>
              </div>

              <div className="row px-3 mb-1">
                <label className="label-text m-0">Mobile Number</label>
                <div
                  className={`input-container form-control d-flex align-items-center ${
                    isError?.mobileNumber && "invalid-input"
                  }`}
                >
                  <input
                    value={credentials.mobileNumber}
                    onBlur={onBlurInputFields}
                    onChange={onChangeCredentials}
                    className="input-element d-flex flex-grow-1"
                    style={{ borderRadius: "10px" }}
                    placeholder="Enter Mobile Number"
                    name="mobileNumber"
                    type="tel"
                    maxLength={10}
                  />
                </div>
                <span className="text-danger px-1">
                  {isError?.mobileNumber}
                </span>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 px-3 mb-1">
                  <label className="label-text m-0">Password</label>
                  <div
                    className={`input-container form-control d-flex align-items-center ${
                      isError?.password && "invalid-input"
                    }`}
                  >
                    <input
                      value={credentials.password}
                      onBlur={onBlurInputFields}
                      onChange={onChangeCredentials}
                      className="input-element d-flex flex-grow-1"
                      placeholder="Enter Password"
                      name="password"
                      type={isPasswordVisible ? "text" : "password"}
                      required
                    />
                    {isPasswordVisible ? (
                      <IoIosLock
                        className="input-icon"
                        onClick={onClickPasswordVisibilityStatus}
                      />
                    ) : (
                      <IoIosUnlock
                        className="input-icon"
                        onClick={onClickPasswordVisibilityStatus}
                      />
                    )}
                  </div>
                  <span className="text-danger px-1">{isError?.password}</span>
                </div>

                <div className="col-12 col-md-6 mb-1">
                  <label className="label-text m-0">Confirm Password</label>
                  <div
                    className={`input-container form-control d-flex align-items-center ${
                      isError?.confirmPassword && "invalid-input"
                    }`}
                  >
                    <input
                      value={credentials.confirmPassword}
                      onBlur={onBlurInputFields}
                      onChange={onChangeCredentials}
                      className="input-element d-flex flex-grow-1"
                      placeholder="Enter Password"
                      name="confirmPassword"
                      type={isConfirmPasswordVisible ? "text" : "password"}
                      required
                    />
                    {isPasswordVisible ? (
                      <IoIosLock
                        className="input-icon"
                        onClick={onClickConfirmPasswordVisibilityStatus}
                      />
                    ) : (
                      <IoIosUnlock
                        className="input-icon"
                        onClick={onClickConfirmPasswordVisibilityStatus}
                      />
                    )}
                  </div>
                  <span className="text-danger px-1">
                    {isError?.confirmPassword}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-1"></div>
          </div>
          <div className="col-md-1"></div>

          <div className="row sign-up-text p-0 d-flex align-items-center justify-content-between mt-3 mx-auto">
            <div className="col-md-1"></div>
            <div className="col-12 col-md-5 text-end p-0">
              <input
                onChange={onChangeTerms}
                type="checkbox"
                className="mx-2"
                name="terms"
                required
              />
              <a
                href="https://atoncap.com/privacy-policy.html"
                rel="noreferrer"
                target="_blank"
                className="sign-up-text-highlighted fw-semibold"
              >
                Privacy Policy
              </a>
              <span className="mx-1">and</span>
              <a
                rel="noreferrer"
                target="_blank"
                className="sign-up-text-highlighted fw-semibold"
                href="https://atoncap.com/terms-of-use.html"
              >
                Terms & Conditions
              </a>
            </div>
            <div className="col-md-1"></div>
            <div
              className="col-12 col-md-5 sign-up-text p-0"
              onClick={onClickSignIn}
            >
              Already have an account?{" "}
              <span className="sign-up-text-highlighted">Sign in</span>
            </div>
            
          </div>
        </div>
        <button type="submit" className="sign-in-btn" disabled={signUpData.isLoading}>
          {signUpData.isLoading ? (
              <span className="loader mx-auto"></span>
            ) : (
              "Sign Up"
            )}
        </button>
      </form>
      {/* </div>
        </div>
      </div> */}
    </>
  );
};

export default SignUp;
