// ===============================
import { AdminKycActions } from "./actionTypes";


const Types = Object.freeze({
	UpdatePayload: "UpdatePayload",
});

// ===============================

export function additionalDetailsReducer(state, { type, payload }) {
	// console.log(state,"asas",payload,"sasas",type);	
	if (type === AdminKycActions.ADDITIONAL_INFO.CHANGE_DETAIL) {
		return payload??state;
	}
	else {
		return state;
	}
}

export function transactionDetailsReducer(state, { type, payload }) {
	// console.log("reducer state",state,payload,type);
	if (type === AdminKycActions.TRANSACTION_DETAILS.UPDATE_DETAILS) {
		return [{...state[0],
			data:payload.reduxState??state[0].data,
			otherCharges:payload.otherCharges??state[0].otherCharges,
			processingFees:payload.processingFees??state[0].processingFees,
			total:payload.total??state[0].total,
			rateOfInterest:payload.rateOfInterest??state[0].rateOfInterest}];
	}
	
	else {
		return state;
	}
}

export function securityReducer(state, { type, payload }) {
	// console.log("security state",state,payload,type);
	if (type === AdminKycActions.SECURITY.UPDATE_DETAILS) {
		return payload??state;
	}
	else {
		return state;
	}
}

export function groupExposureReducer(state, { type, payload }) {
	// console.log("group exposure state",state,payload,type);

	if (type === AdminKycActions.GROUP_EXPOSURE.UPDATE_DETAILS) {
		return [{...state[0],
			data:payload?.newGroupExposureData??state[0].data,
			total:payload?.total??state[0].total}];
	}
	else {
		return state;
	}
}


export function applicantDetailsReducer(state, { type, payload }) {
		// console.log("state",state,payload,type);

	if (type === AdminKycActions.APPLICANT_DETAILS.UPDATE_DETAILS) {
		return payload??state;
	}
	else {
		return state;
	}
}

export function directorAndPartnerReducer(state, { type, payload }) {
			// console.log("state",state,payload,type);

	if (type === AdminKycActions.DIR_AND_PARTNERS.UPDATE_DETAILS) {
		return payload;
	}
	else {
		return state;
	}
}
export function companyPromoterReducer(state, { type, payload }) {
	// console.log("state",state,payload,type);

	if (type === AdminKycActions.PROMOTER.UPDATE_DETAILS) {
		return payload??state;
	}
	else {
		return state;
	}
}
export function dealershipStockReducer(state, { type, payload }) {
		// console.log("state",state,payload,type);
	if (type === AdminKycActions.STOCK_DEALERSHIP.UPDATE_DETAILS) {
		return [{...state[0],
			data:payload?.reduxState??state[0].data,
			total:payload?.total??state[0].total}];
	}
	else {
		return state;
	}
}
export function debtorReducer(state, { type, payload }) {
	// console.log("state",state,payload,type);

	if (type === AdminKycActions.DEBTORS.UPDATE_DETAILS) {
		return [{
			...state[0],
			data:payload?.reduxState??state[0].data,
			total:payload?.total??state[0].total
		}];
	}
	else {
		return state;
	}
}
export function totalWorkingCapitalReducer(state, { type, payload }) {
	console.log("total working capital state",state,payload,type);
	if (type === AdminKycActions.TOTAL_WORKING_CAPITAL.UPDATE_DETAILS) {
		return [{
			...state[0],
			data:payload?.reduxState??state[0].data,
			total:payload?.total??state[0].total
		}];
	}
	else {
		return state;
	}
}
export function bureauScoreReducer(state, { type, payload }) {
	if (type === Types.UpdatePayload) {
		return payload;
	}
	else {
		return state;
	}
}
export function deviationAndApprovalReducer(state, { type, payload }) {
	if (type === Types.UpdatePayload) {
		return payload;
	}
	else {
		return state;
	}
}
export function gstAnalysisReducer(state, { type, payload }) {
	if (type === Types.UpdatePayload) {
		return payload;
	}
	else {
		return state;
	}
}

export function bankAnalysisReducer(state, { type, payload }) {
	if (type === Types.UpdatePayload) {
		return payload;
	}
	else {
		return state;
	}
}

export function financialStatementReducer(state, { type, payload }) {
	if (type === Types.UpdatePayload) {
		return payload;
	}
	else {
		return state;
	}
}


