
// import { Document, Page } from 'react-pdf';

const PdfView = (props) => {
  console.log(props)
  return (
    <div className="d-flex flex-column gap-3">
      <div className="container">
    
      <object width="100%" height="650" data={props.pdfData} type="application/pdf">   </object>
      </div>
    </div>
  );
};
export default PdfView;
