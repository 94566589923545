import { Fragment, useContext, useEffect, useState } from "react";
import { GenericCamTableComponent } from "../../../../GenericComponent/Table/tableComponent";
import { useSelector } from "react-redux";
import { AdminCamContext } from "../../../../context/admin/cam/adminCamContext";
import { AdminKycActions } from "../../../../context/admin/cam/actionTypes";
/**
 * @type { { value: string, rowspan?: number, colspan?: number }[][] }
 */
const AdminCamTransactionDetailsTableHeaders = [
	[
		{ value: "Ageing of Stocks" },
		{ value: "Units" },
		{ value: "Value (Rs. Lacs)	" },
		{ value: "% of Total Stock" }
	],
];

export const AdminCamStockWithDealership = () => {
	const [reduxState, setReduxState] = useState([]);
	const [total,setTotal]=useState();
	const { editEnabled,dealershipStockDispatch } = useContext(AdminCamContext);
	const additionalReduxState = useSelector(state=>state?.admin?.camByEmailIdData?.data?.data?.camDetails?.stocks);

	const [tableData, setTableData] = useState([
		{title:'<= 30days', units:'', value:'', total_stock:'' },
		{title:'> 30 Days & < = 60 Days', units:'', value:'', total_stock:'' },
		{title:'> 60 Days & < = 90 Days', units:'', value:'', total_stock:'' },
		{title:'> 90 Days & < =180 Days', units:'', value:'', total_stock:'' },
		{title:'> 180 Days & < =365 Days	', units:'', value:'', total_stock:'' },
		{title:'> 365 Days', units:'', value:'', total_stock:'' },
		{title:'Total (₹)', units:'', value:'', total_stock:'' }
	]) 


	useEffect(()=>{
		if(reduxState != additionalReduxState){
			setReduxState(additionalReduxState[0]?.data);
			setTotal(additionalReduxState[0]?.total);
		}
	},[additionalReduxState]);

	const handleChangeValue = (e, index) => {
		const { name, value } = e.target;
		let newArray=[];

		if(Array.isArray(reduxState)){
			newArray=reduxState?.map((item, i)=>{
				if(i===index){
					return {...item,[name]:value}
				}
				return item
			})	
		}
		
		
		setReduxState(newArray);
	};

	useEffect(()=>{
		const newTotal={units: 0, value: 0, totalStockPercentage: 0};
		reduxState?.map((item)=>{
			newTotal.units=+newTotal.units+ +(item?.units);
			newTotal.value=+newTotal.value+ +(item?.value);
			newTotal.totalStockPercentage=+newTotal.totalStockPercentage+ +(item?.totalStockPercentage);
		})
		setTotal(newTotal);
	},[reduxState])

	useEffect(()=>{
		dealershipStockDispatch({type:AdminKycActions.STOCK_DEALERSHIP.UPDATE_DETAILS,payload:{reduxState,total}})
	},[reduxState,total])

	return (
		<Fragment>
			<GenericCamTableComponent addNewRows={ false } tableHeaders={AdminCamTransactionDetailsTableHeaders ?? []} >
				{reduxState?.length!=0 && reduxState?.map((val,idx)=><tr key={"stock"+idx}>
					<td className="fw-bold text-center">{val.ageingOfStock}</td>
					<td className="numbrLeft">{editEnabled?<input className="form-control" type="number" name="units" value={ val.units } onChange={ e => handleChangeValue(e, idx)} />:val.units}</td>
					<td className="numbrLeft">{editEnabled?<input className="form-control" type="number" name="value" value={ val.value }  onChange={ e => handleChangeValue(e, idx)} />:val.value}</td>
					<td className="numbrLeft">{editEnabled?<input className="form-control" type="number" name="totalStockPercentage" value={ val.totalStockPercentage } onChange={ e => handleChangeValue(e, idx)}  />:val.totalStockPercentage}</td>
				</tr>)}
				<tr>
					<td className="fw-bold text-center">Total</td>
					<td className="numbrLeft">{total?.units}</td>
					<td className="numbrLeft">{total?.value}</td>
					<td className="numbrLeft">{total?.totalStockPercentage}</td>
				</tr>
			</GenericCamTableComponent>
		</Fragment>
	);
};