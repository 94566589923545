import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toastMessage } from "../../../layout/GenericLayout/ToastMessage";
import {
  updateCountryPartyApprove,
  updateCountryPartyDecline,
} from "../../../store/slices/anchor";

const AnchorCounterPartyTable = (props) => {
  /* FUNCTION DECLARATIONS */
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /* USESTATES */
  const [isSelected, setIsSelected] = useState([]);

  /* EVENT LISTENERS */

  /* TABLE OPTIONS */
  const totalPage = 5;

  const onChangeAllCheck = (event) => {
    let allData = [];
    if (event.target.checked === true) {
      allData = props.tableRows.map((eachRows) => ({
        status: true,
        retailerId: eachRows.retailerId,
      }));
    }

    setIsSelected(allData);
  };

  const onChangeEachCheck = (event, data) => {
    const newData = {
      status: event.target.checked,
      retailerId: data.retailerId,
    };

    const isAvailable = isSelected.some(
      (eachData) => eachData?.retailerId === data.retailerId
    );
    if (event.target.checked === true) {
      !isAvailable && setIsSelected((prev) => [...prev, newData]);
    }

    if (event.target.checked === false) {
      const filteredData = isSelected.filter(
        (eachData) => eachData?.retailerId !== data.retailerId
      );
      isAvailable && setIsSelected(filteredData);
    }
  };

  console.log(">>>>>>>>>>>>>>>>>>", isSelected);

  const onClickApprove = () => {
    const body = {
      // retailerId: isSelected.map((eachData) => eachData.retailerId),
      distributorId: sessionStorage.getItem("customerId"),
      retailerId: isSelected[0]?.retailerId,
    };

    console.log("approve body : ", body);
    dispatch(updateCountryPartyApprove(body))
      .unwrap()
      .then((response) => {
        toastMessage(response.success, "success");
        navigate(0);
      })
      .catch((error) => {
        toastMessage(error.message, "error");
      });
  };

  const onClickDecline = () => {
    const body = {
      // retailerId: isSelected.map((eachData) => eachData.retailerId),
      distributorId: sessionStorage.getItem("customerId"),
      retailerId: isSelected[0]?.retailerId,
    };

    console.log("reject body : ", body);
    dispatch(updateCountryPartyDecline(body));
  };

  useEffect(() => { }, []);

  return (
    <>
      <div className="table-container">
        <table className={props.className}>
          <thead>
            <tr>
              {props.activeTableData === "pending" && (
                <th className="text-center">
                  <div>
                    <input
                      onChange={onChangeAllCheck}
                      className="form-check-input border border-dark p-2"
                      type="checkbox"
                      checked={isSelected.length === props.tableRows.length}
                    />
                  </div>
                  {isSelected.length !== 0 && (
                    <div className="text-center">
                      <button
                        onClick={onClickApprove}
                        className="btn btn-success mx-1"
                      >
                        Approve
                      </button>
                      <button
                        onClick={onClickDecline}
                        className="btn btn-danger mx-1"
                      >
                        Decline
                      </button>
                    </div>
                  )}
                </th>
              )}
              {props.tableHeader.map((eachHeader) => (
                <th key={eachHeader} scope="col">
                  {eachHeader}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {props.tableRows.map((eachRows) => (
              <tr key={eachRows.emailId}>
                {props.activeTableData === "pending" && (
                  <td className="px-3">
                    <input
                      onChange={(event) => onChangeEachCheck(event, eachRows)}
                      className="form-check-input border border-dark p-2"
                      type="checkbox"
                      checked={isSelected.some(
                        (eachData) =>
                          eachData.retailerId === eachRows.retailerId
                      )}
                    />
                  </td>
                )}
                <td>{eachRows.name}</td>
                <td>{eachRows.emailId}</td>
                <td>{eachRows.approvedLimit}</td>
                <td>{eachRows.availableLimit}</td>
                <td className="text-capitalize text-primary fw-semibold">
                  {eachRows.status}
                </td>
                <td
                  className={`text-capitalize fw-semibold ${eachRows.kycStatus === "approved" && "text-success"
                    } ${eachRows.kycStatus === "rejected" && "text-danger"}`}
                >
                  {eachRows.kycStatus}
                </td>
                <td
                  className={`text-capitalize fw-semibold ${eachRows.distributorStatus === "approved" && "text-success"
                    } ${eachRows.distributorStatus === "rejected" && "text-danger"
                    } ${eachRows.distributorStatus === "pending" && "text-secondary"
                    }`}
                >
                  {eachRows.distributorStatus}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default AnchorCounterPartyTable;
