// eslint-disable-next-line no-unused-vars
import { ChannelPartnerKYCFormTemplate } from "../channelPartnerKycContext";
import { ChannelPartnerKycActions } from "../actionTypes";

const actions = ChannelPartnerKycActions.PERSONAL_DETAILS;

/**
 * @param { typeof ChannelPartnerKYCFormTemplate.personalDetails } state 
 * @param {{ type: ChannelPartnerKycActions['PERSONAL_DETAILS'], payload: string | BufferSource }} param1 
 * @returns { typeof ChannelPartnerKYCFormTemplate.personalDetails }
 */
export function PersonalDetailsReducer(state, { type, payload }) {

	if (type === actions.CHANGE_NAME) {	
		const value = payload ?? state.name.value;
		return { ...state, name: { isTouched: true, value, isValid: value.length > 3 } };
	}
	
	else if (type === actions.BLUR_NAME) {
		return { ...state, name: { ...state.name, isTouched: true, isValid: state.name.value.length > 3 } };
	}

	else if (type === actions.CHANGE_PAN) {
		const value = payload ?? state.panNo.value;
		return { ...state, panNo: { isTouched: true, value, isValid: value.length === 10, isVerified: false } };
	}

	else if (type === actions.BLUR_PAN) {
		return { ...state, panNo: { ...state.panNo, isVerified: payload ?? false, isTouched: true, } };
	}
	
	else if (type === actions.CHANGE_COMPANY_TYPE) {
		const value = payload ?? state.panNo.value;
		return { ...state, companyType: { isTouched: true, value, isValid: value !== "", } };
	}

	else if (type === actions.BLUR_COMPANY_TYPE) {
		return { ...state, companyType: { ...state.companyType, isTouched: true, } };
	}

	else if (type === actions.CHANGE_MOBILE) {
		return { ...state, mobileNumber: { ...state.DOB, value: payload, isTouched: true } };
	}

	else if (type === actions.BLUR_MOBILE) {
		const value = state.mobileNumber.value;

		const reg = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[789]\d{9}$/;

		return { ...state, mobileNumber: { ...state.mobileNumber, isTouched: true, isValid: reg.test(value) } };
	}

	if (type === actions.CHANGE_GST) {
		console.log("payload", payload);
		
		const value = payload ?? state.gstNumber.value;

		return { ...state, gstNumber: { value, isValid: value.length === 15, isTouched: true, isVerified: false } };
	}

	else if (type === actions.BLUR_GST) {
		return { ...state, gstNumber: { ...state.gstNumber, isTouched: true, isVerified: payload ?? false } };
	}

	else if (type === actions.CHANGE_ADDRESS) {
		const value = payload ?? state.address.value;
		return { ...state, address: { ...state.address, isTouched: true, value, isValid: value.length > 5 } };
	}

	else if (type === actions.BLUR_ADDRESS) {
		return { ...state, address: { ...state.address, isTouched: true } };
	}

	else if (type === actions.CHANGE_CITY) {
		const value = payload ?? state.city.value;
		return { ...state, city: { ...state.city, isTouched: true, value, isValid: value !== "" } };
	}

	else if (type === actions.BLUR_CITY) {
		return { ...state, city: { ...state.city, isTouched: true } };
	}

	else if (type === actions.CHANGE_STATE) {
		const value = payload ?? state.state.value;
		return { ...state, state: { ...state.state, isTouched: true, value, isValid: value !== "" } };
	}

	else if (type === actions.BLUR_STATE) {
		return { ...state, state: { ...state.state, isTouched: true } };
	}
	
	else if (type === actions.CHANGE_PIN) {
		const value = payload ?? state.pinCode.value;
		return { ...state, pinCode: { ...state.pinCode, isTouched: true, value, isValid: value.length === 6 } };
	}

	else if (type === actions.BLUR_PIN) {
		return { ...state, pinCode: { ...state.pinCode, isTouched: true } };
	}

	else if (type === actions.AADHAAR_UPDATE_API) {
		const fileList = payload ?? [];
		
		return { 
			...state, 
			aadhaarImage: { 
				...state.aadhaarImage, 
				isTouched: true, 
				isVerified: (fileList.length > 0 && state.aadhaarImage.verificationStatus !== null) ? true : false, 
				fileList,
				verificationStatus: fileList.length > 0 ? state.aadhaarImage.verificationStatus : null,
			},
		};
	}

	else if (type === actions.CHANGE_AADHAAR) {
		return { ...state, aadhaarImage: { ...state.aadhaarImage, isTouched: true, isVerified: payload.status, verificationStatus: payload } }
	}

	else if (type === actions.PROFILE_PIC_UPDATE_API) {
		const fileList = payload ?? [];
		return { ...state, profilePic: { fileList, isTouched: true, isVerified: fileList.length > 0 } };
	}

	else if (type === actions.ADDRESS_PROOF_PIC_UPDATE_API) {
		const fileList = payload ?? [];
		return { ...state, addressProof: { ...state.addressProof, fileList, isTouched: true, isVerified: fileList.length > 0, } };
	}

	else if (type === actions.BUREAU_REPORT_PIC_UPDATE_API) {
		const fileList = payload ?? [];

		return { ...state, bureauReport: { ...state.bureauReport, fileList, isTouched: true, isVerified: fileList.length > 0, } };
	}

	else if (type === actions.BUSINESS_PROFILE_PIC_UPDATE_API) {
		const fileList = payload ?? [];

		return { ...state, businessProfile: { ...state.businessProfile, fileList, isTouched: true, isVerified: fileList.length > 0, } };
	}

	else if (type === actions.TOUCH_ALL) {
		for (let item in state) {
			for (let obj in state[item]) {
				if (obj === "isTouched") {
					state[item][obj] = true;
				}
			}
		}
		return { ...state };
	}

	return state;
}