import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { BsSearch } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { getMaterialRequestTable } from "../../../store/slices/buyer";
import { AdminAuditTrailTable } from "../AdminTables/AdminAuditTrailTable";
import { fetchAuditTrail } from "../../../store/slices/admin";

export const AdminAuditTrail = () => {
  const dispatch = useDispatch();

  const auditTrails = useSelector((state) => state.admin.fetchAuditTrail);

  const [auditTrailTableRows, setAuditTrailTableRows] = useState([]);
  const [auditTrailMetaData, setAuditTrailMetaData] = useState(null);
  const [searchByAuditAction, setSearchByAuditAction] = useState("");

  /* TABLE QUERY PARAMS */
  const [tableQueryParams, setTableQueryParams] = useState({
    tableOffset: 0,
    tableLimit: 5,
    tableSort: "modifiedDate",
    tableOrder: -1,
    currentPage: 1,
    search: "",
  });
  const [setSelectedField, setSelectedFieldsetSelectedField] = useState('action'); // You can initialize it to the default value if needed

  

  useEffect(() => {
    let tableData = ``;
    // if (searchByAuditAction.length > 0) {
    //   tableData = `offset=${tableQueryParams.tableOffset}&limit=${tableQueryParams.tableLimit}&search=${searchByAuditAction}`;
    //   // &sort=${tableQueryParams.tableSort}&search=${searchByProformaID}&order=${tableQueryParams.tableOrder}`;
    //   dispatch(getMaterialRequestTable(tableData));
    // } else {
    //   tableData = `offset=${tableQueryParams.tableOffset}&limit=${tableQueryParams.tableLimit}`;
    //   // &sort=${tableQueryParams.tableSort}&order=${tableQueryParams.tableOrder}`;
    //   dispatch(getMaterialRequestTable(tableData));
    // }

    dispatch(
      fetchAuditTrail({
        field:setSelectedField,
        search: searchByAuditAction.toLowerCase(),
        limit: tableQueryParams.tableLimit,
        offset: tableQueryParams.tableOffset,
      })
    );
  }, [tableQueryParams, dispatch, searchByAuditAction]);

  useEffect(() => {
    if (auditTrails.data) {

      setAuditTrailTableRows(auditTrails.data.audit_trail);
      setAuditTrailMetaData(auditTrails.data.metadata)
    }
  }, [auditTrails]);

  const handleSearchByAuditActionOnchange = (e) => {
    setSearchByAuditAction(e.target.value);
  };

  // Event handler for onChange event of the select element
  const handleSelectChange = (event) => {
    setSelectedFieldsetSelectedField(event.target.value);
  };



  return (
    <div className="d-flex flex-column gap-4" style={{ overflowY: "auto" }}>
      <Helmet>
        <title>Audit Trail | Admin</title>
      </Helmet>

 

      <div className="bg-white admin-dashboard-card-container px-3 py-3">
        <div className="d-flex justify-content-between align-items-center flex-wrap px-2 gap-2 py-2">
          <span className="dashboard-content-title">Audit Trail</span>
          {/* <div
            // onClick={toggleRMAddModal}
            className="d-flex align-items-center"
            type="button"
          >
            <div
              className={`rm-add-button ${isHovered ? "show" : ""}`}
              // onMouseEnter={handleMouseEnter}
              // onMouseLeave={handleMouseLeave}
            >
              ADD
            </div>
            <BsPlusCircleFill
              className="rm-plus-button"
              // onMouseEnter={handleMouseEnter}
              // onMouseLeave={handleMouseLeave}
            />
          </div> */}
        </div>

        <div className="d-flex flex-column flex-sm-row align-items-center my-4">

          <select
            className="form-select select-container"
            aria-label="Default select example"
            value={setSelectedField}
            onChange={handleSelectChange}
          >
            <option disabled>Select the search parameter</option>
            <option value="action">Action</option>
            <option value="role">Role</option>
            <option value="seller_id">Anchor Id</option>
            <option value="buyerId">Counter Party Id</option>
            <option value="bankerId">Lender Id</option>
            {/* <option value="timestamps">Timestamps</option> */}
          </select>

          <div className="d-flex flex-column justify-content-center gap-3 col-12 col-sm-6">
            <div className="search-container d-flex align-items-center py-1 px-3 col-12 col-sm-8">
              <input
                className="search-input-element d-flex flex-grow-1"
                type="text"
                placeholder="Search for Particular Action in Audit"
                value={searchByAuditAction}
                onChange={handleSearchByAuditActionOnchange}
              />{" "}
              <BsSearch className="fs-5" />
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex flex-column justify-content-between flex-wrap px-2 gap-2">
        <AdminAuditTrailTable
          className="admin-anchor-and-lender-mapped-table"
          tableHeader={[
            { name: "Audit Actions", sortValue: "action" },
            { name: "Role", sortValue: "role" },
            { name: "Anchor Id", sortValue: "" },
            { name: "Counter Party Id", sortValue: "" },
            {name:"Lender Id",sortValue:""},
            { name: "TimeStamp", sortValue: "" },
            { name: "Outcome", sortValue: "" },
            { name: "Error Message", sortValue: "" },
          ]}
          tableRows={auditTrailTableRows}
          auditTrailMetaData={auditTrailMetaData}
          tableQueryParams={tableQueryParams}
          setTableQueryParams={setTableQueryParams}
          isLoading={auditTrails.isLoading}
        />
      </div>
    </div>
  );
};
