import { HiOutlineArrowNarrowDown } from "react-icons/hi";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import { nanoid } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";

/* PARENT COMPONENT */

const AnchorPaymentReconciliationTable = (props) => {
  const navigate = useNavigate();
  /* USESTATES */
  //   const [activeData, setActiveData] = useState([]);

  /* EVENT LISTENERS */

  const onClickStatus = (data) => {
    // setActiveRemarks(data);
  };

  const onClickInvoiceId = ({ invoiceId }) => {
    const sellerId = sessionStorage.getItem("customerId");
    console.log(sellerId, invoiceId);
    navigate(`${sellerId}/${invoiceId}`);
  };

  /* TABLE OPTIONS */
  const totalPage = 5;

  return (
    <>
      <div className="table-container">
        <table className={props.className}>
          <thead>
            <tr>
              {props.tableHeader.map((eachHeader) => (
                <th key={nanoid()} scope="col">
                  {eachHeader}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {props.tableRows.map((eachRows) => (
              <tr key={eachRows.invoiceId} scope="col">
                <td>{eachRows.buyerName}</td>
                <td
                  onClick={() =>
                    onClickInvoiceId({
                      invoiceId: eachRows.invoiceId,
                    })
                  }
                >
                  {eachRows.invoiceId}
                </td>
                <td>{eachRows.amountPaid}</td>
                <td>{eachRows.amountFinanced}</td>
                <td>{eachRows.dueDate}</td>
                <td>{eachRows.outstandingAmount}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="d-flex justify-content-end px-2">
          <span
            // onClick={props.onClickViewMoreTableData}
            className="d-flex align-items-center gap-1 my-3 view-more-text"
          >
            View more <HiOutlineArrowNarrowDown className="fs-5" />
          </span>
        </div>
      </div>
      <div className="d-flex align-items-center gap-2 page-data-text-container mt-3 px-3">
        <AiOutlineDoubleLeft
        // onClick={props.onClickPreviousPage}
        />
        <span className="page-data-text">
          Page {} of {totalPage}
        </span>
        <AiOutlineDoubleRight
        // onClick={props.onClickNextPage}
        />
      </div>
    </>
  );
};

export default AnchorPaymentReconciliationTable;
