import { Fragment, useContext, useState } from "react";
import { AnchorKycNav } from "./anchorKycNav";
import { AnchorKycContext, AnchorKycContextWrapper } from "./anchorKycContext";
import { AnchorKYCPersonalDetails } from "./personalDetails";
import { AnchorKYCCompanyDetails } from "./companyDetails";
import { AnchorKYCBankDetails } from "./bankDetails";
import { AnchorKYCGstDetails } from "./gstDetails";
import { AnchorKYCDocumentDetails } from "./documentDetails";
import { toastMessage } from "../../../layout/GenericLayout/ToastMessage";
import { useSelector } from "react-redux";
import { useNavigate, useLocation, } from "react-router-dom";
import {Helmet} from "react-helmet";

const AnchorKYC = () => {

	const [ termsAndConditions, setTermsAndConditions ] = useState(false);
	
	const { handleSubmitForm, disableEdit } = useContext(AnchorKycContext);
	const navigate = useNavigate();

	const { state } = useLocation();

	const anchorSelfKyc = useSelector(state => state.anchor.sellerKYCVerification);
	const rmKycUpload = useSelector(state => state.rm.onboardAnchor);

	const handleSubmit = () => {
		if (!termsAndConditions) {
			toastMessage("Please Accept declaration", "error");
			return;
		}

		handleSubmitForm();
	}

	return (
		<Fragment>
			{
				window.location.pathname !== "/rm/rm-onboarding/anchor" &&
					<AnchorKycNav />
			}
			<Helmet>
                <title>KYC | Anchor</title>
            </Helmet>
			<div className="admin-kyc-verification-container px-3 py-5">

				<form className="d-flex flex-column m-0 p-0 gap-4">

					{/*===============================================
					*  PERSONAL DETAILS
					* ==============================================*/}
					<AnchorKYCPersonalDetails />

					{/*===============================================
					*  COMPANY DETAILS
					* ==============================================*/}
					<AnchorKYCCompanyDetails />


					{/*===============================================
					*  BANK DETAILS
					* ==============================================*/}
					<AnchorKYCBankDetails />


					{/*===============================================
					*  GST DETAILS
					* ==============================================*/}
					<AnchorKYCGstDetails />


					{/*===============================================
					*  OTHER DETAILS
					* ==============================================*/}
					<AnchorKYCDocumentDetails />

					<Fragment>
						{
							!disableEdit &&
								<div className="mx-5">
									<div
										className={`d-flex gap-2 mx-5 align-items-center my-2 details-page-container ${ termsAndConditions ? "bg-success" : "bg-danger" } bg-opacity-25 p-2`}
									>
										<input
											id="termAndCondition"
											type="checkbox"
											className="form-check-input"
											value={ termsAndConditions }
											onChange={ () => setTermsAndConditions(previous => !previous) }
											required
										/>
										<label
											htmlFor="termAndCondition"
											className="kyc-label-text form-check-label"
										>
											I hereby declare that this invoice is not funded/discounted with
											any financier outside ATON
										</label>
									</div>
								</div>
						}

						<div className="d-flex flex-column flex-md-row align-items-center justify-content-end gap-3 mx-5 mb-5">
							{
								!disableEdit && 
									<button disabled={anchorSelfKyc.isLoading || rmKycUpload.isLoading} type="button" onClick={ handleSubmit } style={{ cursor: "pointer" }} className="kyc-back-button">
										{anchorSelfKyc.isLoading || rmKycUpload.isLoading ? (
											<span className="loader mx-auto"></span>
											) : (
											"Submit"
										)}
									</button>
							}
							{
								state?.currentPath && typeof state?.currentPath === "string" &&
									<button type="button" onClick={ () => navigate(state?.currentPath) } style={{ cursor: "pointer" }} className="kyc-back-button">
										Back
									</button>
							}
						</div>
					</Fragment>

				</form>

				<div className="d-flex   justify-content-center mt-4">
					<span>Copyright 2020 Aton.</span>
				</div>
			</div>
		</Fragment>
	);
};


/**
 * @param {{ roles?: "seller" }} props 
 */
const AnchorKycWrapper = (props) => {

	return (
		<AnchorKycContextWrapper roles={props?.roles}>
			<AnchorKYC />
		</AnchorKycContextWrapper>
	);
};

export default AnchorKycWrapper;
