import { useNavigate, useOutlet } from "react-router-dom";
import logo from "../../assests/images/Aton-logo.png"

// import { DashBoardBuyerIco } from "../../assests/icons/sidebar/Buyer/dash.svg";

const Kyclayout = () => {
    const outlet = useOutlet();
    const navigate = useNavigate();
 

  return (
    <div className="container-fluid w-100"style={{overflow:'auto'}} >     
        <div className="w-100" >
            <div className="w-100 mt-2 d-flex p-3" style={{boxShadow: 'rgba(0, 0, 0, 0.45) 0px 13px 27px -21px',background:'white'}}>
                <div ClassName="" style={{width:'50%'}}>
                    <img src={logo} alt="" style={{width:'8%'}}/>
                </div>
                <div ClassName="" style={{width:'50%',textAlign:'end'}}>
                    <label 
                    className="fw-bold" style={{color:'#2e3c83',fontSize:'18px',marginTop:'20px',cursor:'pointer'}} 
                    onClick={()=> window.location.pathname = "/login" }>Logout</label>
                </div>
            </div>
            <div className="mt-3" style={{backgroundColor:'#F4F6F8'}}>
                {window.innerWidth > 767 && outlet}
            </div>
        </div>
    </div>
  );
};

export default Kyclayout;
