import { Navigate, Outlet } from "react-router-dom";

const KyclenderPrivateRoute = () => {
  const token = sessionStorage.getItem("token");
  const role = sessionStorage.getItem("role");
  const isAuthenticated =
    token === null || token === undefined || role !== "banker";

  return isAuthenticated ? <Navigate to="/login" replace /> : <Outlet />;
};

export default KyclenderPrivateRoute;
