const RiskAnalysis = ({ scoreCardDetails }) => {
  return (
    <div className="py-2 d-flex flex-column justify-content-around h-100">
      <div>
        <span className="fw-semibold text-secondary">
          Existing Credit Score Record :{" "}
        </span>
        <span className="fw-semibold text-success">
          {scoreCardDetails[0]?.existing_credit_score_record || (
            <span className="text-danger mx-3">N/A</span>
          )}
        </span>
      </div>

      <div>
        <span className="fw-semibold text-secondary">Debt / Income : </span>
        <span className="fw-semibold text-success">
          {scoreCardDetails[0]?.debt_to_income || (
            <span className="text-danger mx-3">N/A</span>
          )}
        </span>
      </div>

      {/* <div>
        <span className="fw-semibold text-secondary">
          Repayment / Income :{" "}
        </span>
        <span className="fw-semibold text-success">N/A</span>
      </div> */}

      <div>
        <span className="fw-semibold text-secondary">
          Probability to default :{" "}
        </span>
        <span className="fw-semibold text-success">
          {scoreCardDetails[0]?.probability_to_default || (
            <span className="text-danger mx-3">N/A</span>
          )}
        </span>
      </div>

      <div>
        <span className="fw-semibold text-secondary">Risk Factor : </span>
        <span className="fw-semibold text-success">
          {scoreCardDetails[0]?.risk_factor || (
            <span className="text-danger mx-3">N/A</span>
          )}
        </span>
      </div>
    </div>
  );
};

export default RiskAnalysis;
