import { BuyerKYCFormTemplate } from "../buyerKycContext";

import { BuyerKycActions } from "../actionTypes";

const actions = BuyerKycActions.CO_APPLICANT_DETAILS;

/**
 * @param { typeof BuyerKYCFormTemplate.coApplicantDetails } state 
 * @param {{ type: BuyerKycActions['CO_APPLICANT_DETAILS'], payload: string | BufferSource }} param1 
 * @returns { typeof BuyerKYCFormTemplate.coApplicantDetails }
 */
export function CoApplicantDetailsReducer(state, { type, payload }) {
	if (type === actions.CHANGE_NAME) {
		const value = payload ?? state.name.value;

		return { ...state, name: { isTouched: true, value, isValid: value.length > 3 } };
	}

	else if (type === actions.BLUR_NAME) {
		return { ...state, name: { ...state.name, isTouched: true, isValid: state.name.value.length > 3 }, };
	}

	else if (type === actions.CHANGE_PAN) {
		const value = payload ?? state.panNo.value;
		return { ...state, panNo: { isTouched: true, isValid: value.length === 10, isVerified: false, value }, };
	}

	else if (type === actions.BLUR_PAN) {
		return { ...state, panNo: { ...state.panNo, isVerified: payload ?? false, isTouched: true } };
	}

	else if (type === actions.CHANGE_MOBILE) {
		return { ...state, mobileNumber: { ...state.DOB, value: payload, isTouched: true } };
	}

	else if (type === actions.BLUR_MOBILE) {
		const value = state.mobileNumber.value;

		const reg = /^(\+91)?(-)?0?[6789]\d{9}$/g;

		return { ...state, mobileNumber: { ...state.mobileNumber, isTouched: true, isValid: reg.test(value) } };
	}

	else if (type === actions.AADHAAR_UPDATE_API) {
		return { ...state, aadhaarImage: { ...state.aadhaarImage, isTouched: true, isVerified: state.aadhaarImage.verificationStatus !== null, } };
	}

	else if (type === actions.CHANGE_AADHAAR) {
		return { ...state, aadhaarImage: { ...state.aadhaarImage, fileList: state.aadhaarImage.fileList, isTouched: true, isVerified: payload.status, verificationStatus: payload } }
	}
	
	else if (type === actions.EXCLUDE_CO_APPLICANT) {
		return {
			name: { value: "", isTouched: true, isValid: true },
			mobileNumber: { value: "", isTouched: true, isValid: true },
			panNo: { value: "", isTouched: true, isValid: true, isVerified: true },
			aadhaarImage: { fileList: state.aadhaarImage, isTouched: true, isVerified: true  },
		}
	}

	else if (type === actions.INCLUDE_CO_APPLICANT) {
		return { ...BuyerKYCFormTemplate.coApplicantDetails };
	}

	else if (type === actions.TOUCH_ALL) {
		for (let item in state) {
			for (let obj in state[item]) {
				if (obj === "isTouched") {
					state[item][obj] = true;
				}
			}
		}
		return { ...state };
	}
	
	return state;
}