import { AiOutlineClose } from "react-icons/ai";

/**
 *
 * @param {showModal, toggleModal, modalContent, modalSizeClass} param0
 * @returns
 */

const ReusableModal = ({
  showModal,
  toggleModal,
  modalContent,
  modalSizeClass,
}) => {
  return (
    <div
      className={`modal fade ${showModal ? "show" : ""}`}
      style={{
        display: showModal ? "block" : "none",
      }}
    >
      <div
        className={`modal-dialog modal-dialog-centered modal-dialog-scrollable ${modalSizeClass}`}
        role="document"
      >
        <div
          className="modal-content shadow modalblur"
          style={{
            borderRadius: "8px",
            borderWidth: "0",
          }}
        >
          <div
            className="modal-body p-2"
            style={{ fontSize: "16px", overflow: "auto", position: "relative" }}
          >
            <button
              className="p-1 d-flex justify-content-end align-items-center rounded-circle"
              type="button"
              aria-hidden="true"
              onClick={toggleModal}
              style={{
                color: "#ffffff",
                cursor: "pointer",
                backgroundColor: "#F5DBDB",
                color: "red",
                outline: "none",
                fontSize: "16px",
                borderWidth: "0",
                // float: "right",
                position: "absolute",
                right: "4px",
                top: "4px",
                height: "fit-content",
              }}
            >
              <AiOutlineClose style={{ color: "red", fontSize: "18px" }} />
            </button>
            {modalContent}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReusableModal;
