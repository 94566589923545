import React from "react";
import { HiOutlineArrowNarrowDown } from "react-icons/hi";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import { BiSort } from "react-icons/bi";
import TablePagination, {
  jumpToPage,
  nextPage,
  previousPage,
} from "../../../layout/GenericLayout/TablePagination";
import { useState } from "react";
import Loader from "../../../layout/GenericLayout/Loader";
import { Link, useLocation } from "react-router-dom";

const ProformaInvoiceTable = (props) => {
  const currentLocation = useLocation();

  const updatePage = (currentPage) => {
    const newOffSet = props.tableQueryParams.tableLimit * (currentPage - 1);
    props.setTableQueryParams((prev) => ({
      ...prev,
      tableOffset: newOffSet,
      currentPage,
    }));
  };

  const onClickActiveSort = (activeSortValue) => {
    if (activeSortValue !== "") {
      let order = -1;
      if (activeSortValue === props.tableQueryParams.tableSort) {
        if (props.tableQueryParams.tableOrder === -1) {
          order = 1;
        }
      }
      props.setTableQueryParams((prev) => ({
        ...prev,
        tableSort: activeSortValue,
        tableOrder: order,
      }));
    }
  };

  const onClickViewMoreTableData = () => {
    let newLimit = props.tableQueryParams.tableLimit;
    if (newLimit === 5) {
      newLimit += 5;
    } else {
      newLimit -= 5;
    }
    props.setTableQueryParams((prev) => ({
      ...prev,
      tableLimit: newLimit,
    }));
  };

  // console.log(props.tableRows)

  return (
    <>
      <div className="buyer-table-container">
        <div className="table-inner-cont">
          <table className={props.className}>
            <thead>
              <tr>
                {props.tableHeader.map((eachHeader) => (
                  <th
                    key={eachHeader.name}
                    scope="col"
                    className={`${
                      props.tableQueryParams.tableSort ===
                        eachHeader.sortValue &&
                      eachHeader.sortValue !== "" &&
                      "active-sort-text"
                    }`}
                    onClick={() => onClickActiveSort(eachHeader.sortValue)}
                  >
                    {eachHeader.name}
                    {eachHeader.sortValue !== "" && <BiSort />}
                  </th>
                ))}
              </tr>
            </thead>
            {props.materialRequestMetaData?.paging?.total<0?(<div className="no-data text-danger">Oops! there is no data to show. Please check back later</div>):null} 
            {props.isLoading ? (
              <tbody>
                <tr>
                  <td colSpan={6}>
                    <Loader className="my-5" />
                  </td>
                </tr>
              </tbody>
            ) : props.tableRows.length === 0 ? (
              <>
                <tbody>
                  <tr>
                    <td colSpan={6}>
                      <div>
                        <h4>NO DATA</h4>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </>
            ) : (
              <tbody>
                {props.tableRows.map((eachRows, index) => (
                  <tr key={eachRows.proformaId}>
                    <td>{eachRows.addedDate.substring(0, 10).split("-")?.reverse()?.join("-") || "NAN"}</td>
                    <td>
                      <Link
                        to={`${currentLocation.pathname}/${eachRows.proformaId}`}
                        state={eachRows.sellerId}
                        className="remove-underline-link"
                      >
                        {eachRows.proformaId}
                      </Link>
                    </td>
                    <td>{eachRows?.sellerName || "NAN"}</td>
                    <td>{eachRows?.expectedDateOfDelivery?.split("-")?.reverse()?.join("-") || "NAN"}</td>
                    <td>{eachRows?.paymentTerms || "NAN"}</td>
                    <td className="row-hight-light-txt">
                      {eachRows?.proformaStatus ? (
                        <>
                          {eachRows?.proformaStatus === "Proforma Accepted" ? (
                            <button className="w-75 hightLight-success-td ">
                              {"Accepted"}
                            </button>
                          ) : eachRows?.proformaStatus === "Rejected" ? (
                            <button className="w-75 hightLight-danger-td ">
                              {eachRows?.proformaStatus}
                            </button>
                          ) : eachRows?.proformaStatus === "Negotiated" ?(
                            <button className="w-75 hightLight-warning-td ">
                              {eachRows?.proformaStatus}
                            </button>
                          ):
                            eachRows?.proformaStatus === "New" ? (
                            <button className="w-75 hightLight-primary-td ">
                              {eachRows?.proformaStatus}
                            </button>
                          ) : (
                            <button className="w-75 hightLight-danger-td ">
                              {"NAN"}
                            </button>
                          )}
                        </>
                      ) : (
                        "NAN"
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
        {!props.isLoading && props.materialRequestMetaData?.paging?.total>5 && (
          <div className="d-flex justify-content-end px-2">
            <span
              onClick={onClickViewMoreTableData}
              className="d-flex align-items-center gap-1 my-3 view-more-text"
            >
             {props.tableQueryParams.tableLimit === 5
              ? props.tableQueryParams.tableOffset+props.tableQueryParams.tableLimit<props.materialRequestMetaData?.paging?.total?"View More ":""
              : props.tableQueryParams.tableOffset+props.tableQueryParams.tableLimit<props.materialRequestMetaData?.paging?.total?"View Less ":""}
              <HiOutlineArrowNarrowDown
                className={`fs-5 ${
                  props.tableQueryParams.tableLimit === 5
                  ? props.tableQueryParams.tableOffset+props.tableQueryParams.tableLimit<props.materialRequestMetaData?.paging?.total? "down-arrow":"d-none"
                  : props.tableQueryParams.tableOffset+props.tableQueryParams.tableLimit<props.materialRequestMetaData?.paging?.total? "up-arrow":"d-none"}
               `}
              />
            </span>
          </div>
        )}
      </div>
      <div className="d-flex align-items-center gap-2 page-data-text-container mt-3 px-3 w-100">
        <TablePagination
          pageSize={props.tableQueryParams.tableLimit}
          onUpdatePage={updatePage}
          current={props.tableQueryParams.currentPage}
          total={props.materialRequestMetaData?.paging?.total}
        ></TablePagination>
      </div>
    </>
  );
};

export default ProformaInvoiceTable;
