// import { Document, Page } from 'react-pdf';
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useOutlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { postModifyData } from "../../store/slices/lender";
import { toastMessage } from "../../layout/GenericLayout/ToastMessage";
import { getDropDownData } from "../../store/slices/lender";

const EditComp = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const modifySucc = useSelector((state) => state.lender.modifyApi);
  const [ isModified, setIsModified ] = useState(false);
  const dropDownData = useSelector(
    (state) => state.lender.dropDown?.data?.success
  );
  const [data,setData]=useState({});

  useEffect(() => {
    setData({
      name: props.editData.name,
      fldg: props.editData.FLDG,
      category: props.editData.category,
      cif: props.editData.customerId,
      product: props.editData.product,
      InterestRate: props.editData.interestRate,
      ProcessingFee: props.editData.fees,
      tenure: props.editData.tenure,
      IntCalcu: props.editData.interestCalculation,
      PlatFee: props.editData.platformFee,
      stamping: props.editData.stampingCharges,
      Insurance: props.editData.insuranceCharges,
      OthChar: props.editData.otherChanrges,
      margin: props.editData.margin,
      odc: props.editData.ODC,
    })
  }, [props.editData]);

  const handleChange = (event) => {
    setIsModified(true);
    const name = event.target.name;
    const value = event.target.value;
    console.log(name);
    console.log(value);
    setData((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    dispatch(getDropDownData());
  }, [dispatch]);

  const handleModify = () => {

    if (+data.tenure < 1) {
      toastMessage("Tenure should be at least 1", "error");
      return;
    }
    
    const body = {
      FLDG: data.fldg,
      ODC: data.odc,
      category: data.category,
      customerId: data.cif,
      fees: parseFloat(data.ProcessingFee),
      insuranceCharges: data.Insurance,
      interestCalculation: data.IntCalcu,
      interestRate: parseFloat(data.InterestRate),
      margin: data.margin,
      name: data.name,
      otherChanrges: data.OthChar,
      platformFee: data.PlatFee,
      product: data.product,
      stampingCharges: data.stamping,
      tenure: +data.tenure,
    };
    dispatch(postModifyData(body))
      .unwrap()
      .then((response) => {
        toastMessage(response.success, "success");
        props.setEditModal(false);
        navigate("/lender/trans-maintain");
      })
      .catch((error) => {
        console.log(error);
        toastMessage(error.message, "error");
      });
    setIsModified(false);
  };

  console.log(data);
  return (
    <div className="d-flex flex-column gap-3">
      {props.title && <div className="fs-2 fw-semibold m-auto p-1">{props?.title}</div>}
      <div className="container" style={{ padding: props.title ? "0rem":"2rem" }}>
        <div className="row gy-md-4 gy-2 mt-1">
          <div className="kyc-fields-container col-12  col-md-4">
            <label
              htmlFor="billing"
              className="kyc-label-text form-label fw-semibold"
            >
              Name
            </label>
           <div className="kyc-input-field form-control">{data?.name}</div>
          </div>
          <div className="kyc-fields-container col-12  col-md-4">
            <label
              htmlFor="billing"
              className="kyc-label-text form-label fw-semibold"
            >
              CIF
            </label>
            <div className="kyc-input-field form-control">
              {data.cif}
            </div>
            {/* <select
              name="cif"
              onChange={handleChange}
              value={data.cif}
              className="search-input-element1 d-flex flex-grow-1"
            >
              <option></option>
              {dropDownData !== undefined &&
                dropDownData.map((item) => (
                  <option value={item.distributorId}>
                    {item.companyFullName}
                  </option>
                ))}
            </select> */}
          </div>

          <div className="kyc-fields-container col-12  col-md-4">
            <label
              htmlFor="shipping"
              className="kyc-label-text form-label fw-semibold"
            >
              category
            </label>
            <select
              name="category"
              onChange={handleChange}
              value={data.category}
              className="search-input-element1 d-flex flex-grow-1"
            >
              <option></option>
              <option value="Secured">Secured</option>
              <option value="Unsecured">Unsecured</option>
            </select>
          </div>
        </div>

        <div className="row gy-md-4 gy-2 mt-1">
          <div className="kyc-fields-container col-12  col-md-4">
              <label
                htmlFor="shipping"
                className="kyc-label-text form-label fw-semibold"
              >
                FLDG (%)
              </label>
              <input
                id="shipping"
                name="fldg"
                onChange={handleChange}
                className="kyc-input-field form-control"
                type="number"
                value={data.fldg}
              />
            </div>

          <div className="kyc-fields-container col-12  col-md-4">
            <label
              htmlFor="shipping"
              className="kyc-label-text form-label fw-semibold"
            >
              Product
            </label>
            <input
              id="shipping"
              type="text"
              name="product"
              onChange={handleChange}
              className="kyc-input-field form-control"
              value={data.product}
            />
          </div>
          <div className="kyc-fields-container col-12  col-md-4">
            <label
              htmlFor="shipping"
              className="kyc-label-text form-label fw-semibold"
            >
              Interest Rate (%)
            </label>
            <input
              id="shipping"
              type="number"
              name="InterestRate"
              onChange={handleChange}
              className="kyc-input-field form-control"
              value={data.InterestRate}
            />
          </div>
        </div>

        <div className="row gy-md-4 gy- mt-1">
          <div className="kyc-fields-container col-12  col-md-4">
            <label
              htmlFor="billing"
              className="kyc-label-text form-label fw-semibold"
            >
              Processing Fees (%)
            </label>
            <input
              id="billing"
              type="number"
              name="ProcessingFee"
              onChange={handleChange}
              value={data.ProcessingFee}
              className="kyc-input-field form-control"
            />
          </div>

          <div className="kyc-fields-container col-12  col-md-4">
            <label
              htmlFor="shipping"
              className="kyc-label-text form-label fw-semibold"
            >
              Tenure (In Days)
            </label>
            <input
              id="shipping"
              type="number"
              name="tenure"
              onChange={handleChange}
              className="kyc-input-field form-control"
              value={data.tenure}
            />
          </div>
          <div className="kyc-fields-container col-12  col-md-4">
            <label
              htmlFor="shipping"
              className="kyc-label-text form-label fw-semibold"
            >
              Interest Calculation
            </label>
            <select
              onChange={handleChange}
              name="IntCalcu"
              value={data.IntCalcu}
              className="search-input-element1 d-flex flex-grow-1"
            >
              <option></option>
              <option value="Daily">Daily</option>
              <option value="Weekly">Weekly</option>
              <option value="Monthly">Monthly</option>
              <option value="Quarterly">Quarterly</option>
              <option value="Yearly">Yearly</option>
            </select>
          </div>
        </div>

        <div className="row gy-md-4 gy-2 mt-1">
          <div className="kyc-fields-container col-12  col-md-4">
            <label
              htmlFor="billing"
              className="kyc-label-text form-label fw-semibold"
            >
              Platform Fees (%)
            </label>
            <input
              id="billing"
              type="number"
              name="PlatFee"
              onChange={handleChange}
              value={data.PlatFee}
              className="kyc-input-field form-control"
            />
          </div>

          <div className="kyc-fields-container col-12  col-md-4">
            <label
              htmlFor="shipping"
              className="kyc-label-text form-label fw-semibold"
            >
              Stamping Charges (%)
            </label>
            <input
              id="shipping"
              type="number"
              name="stamping"
              onChange={handleChange}
              className="kyc-input-field form-control"
              value={data.stamping}
            />
          </div>
          <div className="kyc-fields-container col-12  col-md-4">
            <label
              htmlFor="shipping"
              className="kyc-label-text form-label fw-semibold"
            >
              Insurance Charges (%)
            </label>
            <input
              id="shipping"
              type="number"
              name="Insurance"
              onChange={handleChange}
              className="kyc-input-field form-control"
              value={data.Insurance}
            />
          </div>
        </div>

        <div className="row gy-md-4 gy-2 mt-1">
          <div className="kyc-fields-container col-12  col-md-4">
            <label
              htmlFor="billing"
              className="kyc-label-text form-label fw-semibold"
            >
              Other Charges (%)
            </label>
            <input
              id="billing"
              type="number"
              name="OthChar"
              onChange={handleChange}
              value={data.OthChar}
              className="kyc-input-field form-control"
            />
          </div>

          <div className="kyc-fields-container col-12  col-md-4">
            <label
              htmlFor="shipping"
              className="kyc-label-text form-label fw-semibold"
            >
              Margin (%)
            </label>
            <input
              id="shipping"
              type="number"
              name="margin"
              onChange={handleChange}
              className="kyc-input-field form-control"
              value={data.margin}
            />
          </div>
          <div className="kyc-fields-container col-12  col-md-4">
            <label
              htmlFor="shipping"
              className="kyc-label-text form-label fw-semibold"
            >
              ODC (%)
            </label>
            <input
              id="shipping"
              type="number"
              name="odc"
              onChange={handleChange}
              className="kyc-input-field form-control"
              value={data.odc}
            />
          </div>
        </div>

        <div className="row gy-md-4 gy-2 mt-1">
          <div className="kyc-fields-container col-12  col-md-4"></div>

          <div className="kyc-fields-container col-12  col-md-4"></div>
          <div
            className="kyc-fields-container col-12 col-md-4"
            style={{ marginBottom: "2rem", textAlign: "right", }}
          >
            <button
              onClick={() => props?.setEditModal?.(false)}
              id="buttons"
              style={{
                padding: "5px 20px",
                backgroundColor: "#6c757d",
                border: "1px solid #6c757d",
                color: "white",
                fontWeight: "bold",
                borderRadius: "5px",
              }}
            >
              Cancel
            </button>
            <button
              onClick={ () => isModified && handleModify() }
              id="buttons"
              style={{
                padding: "5px 20px",
                backgroundColor: isModified ? "green" : "gray",
                border: "1px solid #6c757d",
                color: "white",
                fontWeight: "bold",
                borderRadius: "5px",
                marginLeft: "20px",
              }}
            >
               {modifySucc.isLoading ? (
                  <span className="loader mx-auto"></span>
                    ) : (
                    "Modify"
                  )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EditComp;
