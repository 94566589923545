import { useDispatch, useSelector } from "react-redux";
import { BsPlusCircleFill } from "react-icons/bs";
import {
  getAllUsersByCustomerId,
  getCamDataByEmailId,
} from "../../../store/slices/admin";
import { useState, Fragment } from "react";
import Loader from "../../../layout/GenericLayout/Loader";
import ReusableModal from "../../../layout/GenericLayout/ReusableModal";
import { toastMessage } from "../../../layout/GenericLayout/ToastMessage";
import AdminUserTableCAMComponent from "./AdminCam/AdminUserTableCAMComponent";
import { useNavigate } from "react-router-dom";
import { AdminCamContextWrapper } from "../../../context/admin/cam/adminCamContext";
import ScoreCardDetails from "../AdminScoreCard/ScoreCardDetails";
import {
  getScoreCardDetails,
  getScoreCardDataByEmailId,
} from "../../../store/slices/generic";
import { GiNotebook } from "react-icons/gi";

/* MODALS OR CHILDREN COMPONENTS */
export const CamModal = ({ camByEmailIdData, showEdit }) => {
  return (
    <AdminCamContextWrapper>
      <AdminUserTableCAMComponent
        showEdit={showEdit}
        camByEmailIdData={camByEmailIdData}
      />
      ;
    </AdminCamContextWrapper>
  );
};

const StatusHistoryModal = (props) => {
  /* OBJECT DESTRUCTURE */
  const { activeRemarks } = props;

  /* TABLE OPTIONS */
  const dateOptions = {
    day: "numeric",
    year: "numeric",
    month: "short",
  };

  const timeOptions = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  return (
    <div className="">
      <span className="fs-4 fw-semibold">Status History</span>
      <div>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Sl No.</th>
              <th scope="col">Remarks</th>
              <th scope="col">Date</th>
            </tr>
          </thead>
          <tbody>
            {activeRemarks.map((eachRows, index) => (
              <tr key={`${eachRows.remark}${eachRows.timestamp}`} scope="col">
                <th scope="row">{index + 1}</th>
                <td>{eachRows.remarks}</td>
                <td className="d-flex flex-column">
                  <span className="">
                    {/* {new Date(eachRows.timestamp).toLocaleDateString(
                      "en-IN",
                      dateOptions
                    )}
                  </span>
                  <span className="text-uppercase">
                    {new Date(eachRows.timestamp).toLocaleTimeString(
                      "en-IN",
                      timeOptions
                    )} */}
                    {eachRows.timestamp}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

/* PARENT COMPONENT */
const AdminUsersTable = (props) => {
  /* FUNCTION DECLARATIONS */
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /* USESELECTOR */
  const allUsersByCustomerIdData = useSelector(
    (state) => state.admin.allUsersByCustomerIdData
  );
  const camByEmailIdData = useSelector((state) => state.admin.camByEmailIdData);

  const scoreCardByEmailIdData = useSelector(
    (state) => state.generic.scoreCardByEmailIdData
  );
  const scoreCardDetails = useSelector(
    (state) => state.generic.scoreCardDetails
  );

  /* USESTATES */
  const [expandedRow, setExpandedRow] = useState(null);
  const [showCamModal, setShowCamModal] = useState(false);
  const [showScoreCardDetailsModal, setShowScoreCardDetailsModal] =
    useState(false);
  const [activeCamEmailId, setActiveCamEmailId] = useState(null);
  const [activeScoreCardEmailId, setActiveScoreCardEmailId] = useState(null);

  /* */
  const [showRemarksModal, setShowRemarksModal] = useState(false);
  const [activeRemarks, setActiveRemarks] = useState([]);

  const toggleRemarksModal = () => {
    setShowRemarksModal((prev) => !prev);
  };

  const onClickRemarks = (remarks) => {
    console.log("remarks : ", remarks);
    setActiveRemarks(remarks);
    setShowRemarksModal((prev) => !prev);
  };

  /* EVENT LISTENERS */
  const toggleCamModal = () => {
    setShowCamModal((prev) => !prev);
  };

  const toggleScoreCardDetailsModal = () => {
    setShowScoreCardDetailsModal((prev) => !prev);
  };

  const onClickCam = (emailId) => {
    setActiveCamEmailId(emailId);
    dispatch(getCamDataByEmailId(emailId))
      .unwrap()
      .then((response) => {
        toggleCamModal();
      })
      .catch((error) => {
        toastMessage(error.message, "error");
      });
  };

  const onClickScoreCardDetails = (emailId) => {
    setActiveScoreCardEmailId(emailId);
    dispatch(getScoreCardDataByEmailId(emailId))
      .unwrap()
      .then(() => {
        dispatch(getScoreCardDetails(emailId))
          .unwrap()
          .then((response) => {
            toggleScoreCardDetailsModal();
          })
          .catch((error) => {
            toastMessage(error.message, "error");
          });
      })
      .catch((error) => {
        toastMessage(error.message, "error");
      });
  };

  const onClickAccountHolderName = (emailId) => {
    navigate(`/admin/users/kyc/${emailId}`, { state: { isUser: true } });
  };

  const onClickDispatchAllUsersByCustomerId = (customerId, index) => {
    if (expandedRow !== index) {
      dispatch(getAllUsersByCustomerId(customerId))
        .unwrap()
        .then((response) => {
          // setAllUsersByCustomerIdDataSuccess(response);
          // setAllUsersByCustomerIdDataError(null);
        })
        .catch((error) => {
          // setAllUsersByCustomerIdDataError(error.message);
          // setAllUsersByCustomerIdDataSuccess(null);
        });
    }
    setExpandedRow((prevExpandedRow) =>
      prevExpandedRow === index ? null : index
    );
  };

  /* TABLE OPTIONS */
  const totalPage = 5;

  return (
    <>
      {/* Modal Content */}
      {showCamModal && (
        <ReusableModal
          showModal={showCamModal}
          toggleModal={toggleCamModal}
          modalContent={<CamModal camByEmailIdData={camByEmailIdData} />}
          modalSizeClass="modal-xl"
        />
      )}
      {showScoreCardDetailsModal && (
        <ReusableModal
          showModal={showScoreCardDetailsModal}
          toggleModal={toggleScoreCardDetailsModal}
          modalContent={
            <ScoreCardDetails
              scoreCardDetails={scoreCardDetails}
              scoreCardByEmailIdData={scoreCardByEmailIdData}
            />
          }
          modalSizeClass="modal-xl"
        />
      )}
      {showRemarksModal && (
        <ReusableModal
          showModal={showRemarksModal}
          toggleModal={toggleRemarksModal}
          modalContent={<StatusHistoryModal activeRemarks={activeRemarks} />}
          modalSizeClass="modal-lg"
        />
      )}
      <div className="table-container fw-normal">
        <table className={props.className}>
          <thead>
            <tr>
              <th>
                {" "}
                <BsPlusCircleFill className="rm-plus-button text-white fs-5" />
              </th>
              {props.tableHeader.map((eachHeader) => (
                <th key={eachHeader} scope="col">
                  {eachHeader}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {props.tableRows.map((eachRows, index) => (
              <Fragment key={eachRows.customerId}>
                <tr className="fw-semibold" scope="col">
                  <td>
                    <BsPlusCircleFill
                      onClick={() =>
                        onClickDispatchAllUsersByCustomerId(
                          eachRows.customerId,
                          index
                        )
                      }
                      className={`rm-plus-button fs-5 ${
                        expandedRow === index && "sub-table-expand-button"
                      }`}
                    />
                  </td>

                  <td
                    role="button"
                    onClick={() => onClickAccountHolderName(eachRows.emailId)}
                    className="userCustomerId"
                  >
                    {eachRows.customerId}
                  </td>

                  <td
                    className=" userCompanyName"
                    role="button"
                    onClick={() => onClickAccountHolderName(eachRows.emailId)}
                  >
                    {eachRows.companyFullName}
                  </td>

                  <td
                    className={`${
                      eachRows.roles[0] === "seller"
                        ? "text-warning"
                        : "text-primary"
                    }`}
                  >
                    {(eachRows.roles[0] === "seller" && "Anchor") ||
                      (eachRows.roles[0] === "buyer" && "Counter Party")}
                  </td>

                  <td onClick={() => onClickScoreCardDetails(eachRows.emailId)}>
                    {scoreCardByEmailIdData.isLoading &&
                    activeScoreCardEmailId === eachRows.emailId ? (
                      <Loader />
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="26.134"
                        height="20.259"
                        viewBox="0 0 26.134 20.259"
                      >
                        <defs>
                          <linearGradient
                            id="linear-gradient"
                            x1="-0.067"
                            y1="1.022"
                            x2="1.081"
                            y2="1"
                            gradientUnits="objectBoundingBox"
                          >
                            <stop offset="0" stopColor="#ff6363" />
                            <stop offset="0.207" stopColor="#d59759" />
                            <stop offset="0.296" stopColor="#c3aa55" />
                            <stop offset="0.571" stopColor="#efc53c" />
                            <stop offset="1" stopColor="#21c91a" />
                          </linearGradient>
                        </defs>
                        <path
                          id="tachometer-fast-alt"
                          d="M15.067,5A13.067,13.067,0,0,0,3.751,24.6a1.311,1.311,0,1,0,2.274-1.307,10.349,10.349,0,0,1-1.411-5.227,10.454,10.454,0,1,1,19.509,5.227A1.306,1.306,0,1,0,26.383,24.6,13.067,13.067,0,0,0,15.067,5Zm3.711,7.527-2.025,2.012a3.8,3.8,0,0,0-1.686-.392,3.92,3.92,0,1,0,3.92,3.92,3.789,3.789,0,0,0-.392-1.673l2.025-2.012a1.307,1.307,0,1,0-1.842-1.856Zm-3.711,6.847a1.307,1.307,0,1,1,.915-2.248h0a1.312,1.312,0,0,1-.915,2.248Z"
                          transform="translate(-2 -5)"
                          fill="url(#linear-gradient)"
                        />
                      </svg>
                    )}
                  </td>

                  <td onClick={() => onClickCam(eachRows.emailId)}>
                    {camByEmailIdData.isLoading &&
                    activeCamEmailId === eachRows.emailId ? (
                      <Loader />
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25.62"
                        height="20.13"
                        viewBox="0 0 25.62 20.13"
                      >
                        <path
                          id="Icon_ionic-md-card"
                          data-name="Icon ionic-md-card"
                          d="M25.308,5.625H4.812A2.52,2.52,0,0,0,2.261,8.141l-.011,15.1a2.53,2.53,0,0,0,2.562,2.516h20.5a2.53,2.53,0,0,0,2.562-2.516V8.141A2.53,2.53,0,0,0,25.308,5.625Zm0,17.614H4.812V15.69h20.5Zm0-12.581H4.812V8.141h20.5Z"
                          transform="translate(-2.25 -5.625)"
                          fill="#2e3c83"
                        />
                      </svg>
                    )}
                  </td>

                  <td>
                    {eachRows?.adminSubmitStatus === "submitted" && (
                      <span className="w-75 text-capitalize success-btn">
                        {eachRows?.adminSubmitStatus}
                      </span>
                    )}

                    {eachRows?.adminSubmitStatus === "rejected" && (
                      <span className="w-75 text-capitalize danger-btn">
                        {eachRows?.adminSubmitStatus}
                      </span>
                    )}

                    {eachRows?.adminSubmitStatus === "send back" && (
                      <span className="w-75 text-capitalize success-warning">
                        {eachRows?.adminSubmitStatus}
                      </span>
                    )}
                     {!eachRows?.adminSubmitStatus && (
                        <span className="w-75 text-capitalize warning-btn">
                          Approval Pending
                        </span>
                      )}
                  </td>

                  {eachRows.adminRemarks ? (
                    <td
                      className=""
                      onClick={() => onClickRemarks(eachRows.adminRemarks)}
                      style={{ cursor: "pointer" }}
                    >
                      <GiNotebook className="text-primary display-6" />
                    </td>
                  ) : (
                    <td>N/A</td>
                  )}
                </tr>

                {expandedRow === index && (
                  <>
                    {allUsersByCustomerIdData.isLoading && (
                      <tr>
                        <td colSpan={8}>
                          <Loader className="my-5" />
                        </td>
                      </tr>
                    )}
                    {allUsersByCustomerIdData.error && (
                      <tr>
                        <td
                          colSpan={8}
                          className="text-center fs-4 text-danger"
                        >
                          {allUsersByCustomerIdData.error}
                        </td>
                      </tr>
                    )}
                    {allUsersByCustomerIdData.data &&
                      allUsersByCustomerIdData.data.success.map((eachRows) => (
                        <tr
                          className={`bg-light bg-gradient admin-user-expand-row ${
                            !allUsersByCustomerIdData.isLoading ===
                            "admin-user-expand-row-active"
                          }`}
                          key={eachRows.retailerId}
                        >
                          <td className="ps-2">
                            {/* <AiOutlineMinus className="rm-plus-button fs-5" /> */}
                          </td>
                          <td
                            role="button"
                            onClick={() =>
                              onClickAccountHolderName(eachRows.emailId)
                            }
                          >
                            {eachRows.retailerId}
                          </td>
                          <td
                            className="text-info"
                            role="button"
                            onClick={() =>
                              onClickAccountHolderName(eachRows.emailId)
                            }
                          >
                            {eachRows.name}
                          </td>
                          <td className="text-primary">
                            {" "}
                            {eachRows.roles === "buyer" && "Counter Party"}
                          </td>
                          <td
                            onClick={() =>
                              onClickScoreCardDetails(eachRows.emailId)
                            }
                          >
                            {scoreCardByEmailIdData.isLoading &&
                            activeScoreCardEmailId === eachRows.emailId ? (
                              <Loader />
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="26.134"
                                height="20.259"
                                viewBox="0 0 26.134 20.259"
                              >
                                <defs>
                                  <linearGradient
                                    id="linear-gradient"
                                    x1="-0.067"
                                    y1="1.022"
                                    x2="1.081"
                                    y2="1"
                                    gradientUnits="objectBoundingBox"
                                  >
                                    <stop offset="0" stopColor="#ff6363" />
                                    <stop offset="0.207" stopColor="#d59759" />
                                    <stop offset="0.296" stopColor="#c3aa55" />
                                    <stop offset="0.571" stopColor="#efc53c" />
                                    <stop offset="1" stopColor="#21c91a" />
                                  </linearGradient>
                                </defs>
                                <path
                                  id="tachometer-fast-alt"
                                  d="M15.067,5A13.067,13.067,0,0,0,3.751,24.6a1.311,1.311,0,1,0,2.274-1.307,10.349,10.349,0,0,1-1.411-5.227,10.454,10.454,0,1,1,19.509,5.227A1.306,1.306,0,1,0,26.383,24.6,13.067,13.067,0,0,0,15.067,5Zm3.711,7.527-2.025,2.012a3.8,3.8,0,0,0-1.686-.392,3.92,3.92,0,1,0,3.92,3.92,3.789,3.789,0,0,0-.392-1.673l2.025-2.012a1.307,1.307,0,1,0-1.842-1.856Zm-3.711,6.847a1.307,1.307,0,1,1,.915-2.248h0a1.312,1.312,0,0,1-.915,2.248Z"
                                  transform="translate(-2 -5)"
                                  fill="url(#linear-gradient)"
                                />
                              </svg>
                            )}
                          </td>
                          <td onClick={() => onClickCam(eachRows.emailId)}>
                            {camByEmailIdData.isLoading &&
                            activeCamEmailId === eachRows.emailId ? (
                              <Loader />
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25.62"
                                height="20.13"
                                viewBox="0 0 25.62 20.13"
                              >
                                <path
                                  id="Icon_ionic-md-card"
                                  data-name="Icon ionic-md-card"
                                  d="M25.308,5.625H4.812A2.52,2.52,0,0,0,2.261,8.141l-.011,15.1a2.53,2.53,0,0,0,2.562,2.516h20.5a2.53,2.53,0,0,0,2.562-2.516V8.141A2.53,2.53,0,0,0,25.308,5.625Zm0,17.614H4.812V15.69h20.5Zm0-12.581H4.812V8.141h20.5Z"
                                  transform="translate(-2.25 -5.625)"
                                  fill="#2e3c83"
                                />
                              </svg>
                            )}
                          </td>

                          <td className="fw-bold">
                            {eachRows?.adminSubmitStatus === "sent back" && (
                              <span className="w-75 text-capitalize text-warning">
                                {eachRows?.adminSubmitStatus}
                              </span>
                            )}

                            {eachRows?.adminSubmitStatus === "pending" && (
                              <span className="w-75 text-capitalize text-info">
                                {eachRows?.adminSubmitStatus}
                              </span>
                            )}

                            {eachRows?.adminSubmitStatus === "submitted" && (
                              <span className="w-75 text-capitalize text-success">
                                {eachRows?.adminSubmitStatus}
                              </span>
                            )}

                            {eachRows?.adminSubmitStatus === "rejected" && (
                              <span className="w-75 text-capitalize text-danger">
                                {eachRows?.adminSubmitStatus}
                              </span>
                            )}

                            {!eachRows?.adminSubmitStatus && (
                              <span className="w-75 text-capitalize text-dark">
                                Approval Pending
                              </span>
                            )}
                          </td>

                          {eachRows?.adminSubmitStatus === "submitted" ||
                          eachRows?.adminSubmitStatus === "rejected" ||
                          eachRows?.adminSubmitStatus === "sent back" ||
                          eachRows?.adminSubmitStatus === "pending" ? (
                            <td
                              onClick={() =>
                                onClickRemarks(eachRows.adminRemarks)
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <GiNotebook className="text-primary display-6" />
                            </td>
                          ) : (
                            <td className="fw-semibold">N/A</td>
                          )}
                        </tr>
                      ))}
                  </>
                )}
              </Fragment>
            ))}
          </tbody>
        </table>
        {/* <div className="d-flex justify-content-end px-2">
          <span
            // onClick={props.onClickViewMoreTableData}
            className="d-flex align-items-center gap-1 my-3 view-more-text"
          >
            View more <HiOutlineArrowNarrowDown className="fs-5" />
          </span>
        </div> */}
      </div>
      {/* <div className="d-flex align-items-center gap-2 page-data-text-container mt-3 px-3">
        <AiOutlineDoubleLeft
        // onClick={props.onClickPreviousPage}
        />
        <span className="page-data-text">
          Page {} of {totalPage}
        </span>
        <AiOutlineDoubleRight
        // onClick={props.onClickNextPage}
        />
      </div> */}
    </>
  );
};

export default AdminUsersTable;
