import { Fragment, useEffect, useState } from "react";
import { BsSearch } from "react-icons/bs";
import AdminCard from "../Admin/AdminCard";
import AdminDashboardTable from "../Lender/LenderTables/LenderdashboardTable";
import { useDispatch, useSelector } from "react-redux";
import { getRiskManagementData } from "../../store/slices/lender.js";
import Loader from "../../layout/GenericLayout/Loader";
import { Helmet } from "react-helmet";

const LenderRiskManagementSystem = () => {
  const dispatch = useDispatch();
  const customerOnboardData = useSelector(
    (state) => state.lender.corporateOnboardData
  );
  // console.log(customerOnboardData)

  const [search, setSearch] = useState("");
  const [activeTableData, setActiveTableData] = useState(1);
  const [activeTableRows, setActiveTableRows] = useState([]);
  // const [tableRowsCount, setTableRowsCount] = useState(5);

  const onChangeSearch = (event) => {
    setSearch(event.target.value);
  };

  const onClickActiveTable = (value) => {
    setActiveTableData(value);
  };

  useEffect(() => {
    dispatch(getRiskManagementData());
  }, [dispatch]);

  useEffect(() => {
    if (customerOnboardData?.data) {
      setActiveTableRows(customerOnboardData?.data?.success);
    }
  }, [customerOnboardData]);

  // console.log(activeTableRows)

  return (
    <div className="d-flex flex-column gap-4">
      <Helmet>
        <title>Risk-Management</title>
      </Helmet>
      {/* <div className="bg-white admin-dashboard-card-container px-3 pt-3">
        <div className="row">
          <div
            className="col-12 col-md-6 col-xl-3 mb-3"
            style={{ cursor: "pointer" }}
            onClick={() => onClickActiveTable(1)}
          >
            <AdminCard
              title="Customer Data Sheet"
              count="2890"
              status="200+ lastweek"
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25.166"
                  height="28.907"
                  viewBox="0 0 25.166 28.907"
                >
                  <g
                    id="noun-files-3972654"
                    transform="matrix(-1, -0.017, 0.017, -1, 97.085, 60.505)"
                  >
                    <path
                      id="Path_517"
                      data-name="Path 517"
                      d="M153.217,32.852v20.88a1.75,1.75,0,0,1-1.75,1.74H140.092a.87.87,0,0,1-.618-.256l-7-6.96a.87.87,0,0,1-.257-.614V32.851a1.75,1.75,0,0,1,1.75-1.74h17.5a1.75,1.75,0,0,1,1.75,1.74Z"
                      transform="translate(-59.332 -0.78)"
                      fill="#2e3c83"
                    />
                    <path
                      className="sidebar-icons"
                      id="Path_518"
                      data-name="Path 518"
                      d="M243.86,124.45v21.96h-18.3v1.83h18.3a1.835,1.835,0,0,0,1.83-1.83V124.45Z"
                      transform="translate(-147.979 -89.431)"
                      fill="#2e3c83"
                    />
                  </g>
                </svg>
              }
              value=""
            />
          </div>
          <div
            className="col-12 col-md-6 col-xl-3 mb-3"
            onClick={() => onClickActiveTable(2)}
          >
            <AdminCard
              title="CAM"
              count="5348"
              status="200+ lastweek"
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25.166"
                  height="28.907"
                  viewBox="0 0 25.166 28.907"
                >
                  <g
                    id="noun-files-3972654"
                    transform="matrix(-1, -0.017, 0.017, -1, 97.085, 60.505)"
                  >
                    <path
                      id="Path_517"
                      data-name="Path 517"
                      d="M153.217,32.852v20.88a1.75,1.75,0,0,1-1.75,1.74H140.092a.87.87,0,0,1-.618-.256l-7-6.96a.87.87,0,0,1-.257-.614V32.851a1.75,1.75,0,0,1,1.75-1.74h17.5a1.75,1.75,0,0,1,1.75,1.74Z"
                      transform="translate(-59.332 -0.78)"
                      fill="#2e3c83"
                    />
                    <path
                      className="sidebar-icons"
                      id="Path_518"
                      data-name="Path 518"
                      d="M243.86,124.45v21.96h-18.3v1.83h18.3a1.835,1.835,0,0,0,1.83-1.83V124.45Z"
                      transform="translate(-147.979 -89.431)"
                      fill="#2e3c83"
                    />
                  </g>
                </svg>
              }
              value=""
            />
          </div>
          <div
            className="col-12 col-md-6 col-xl-3 mb-3" onKeyDown={ null }
            onClick={() => onClickActiveTable(3)}
          >
            <AdminCard
              title="Limits"
              count="2387"
              onClick={() => onClickActiveTable(3)}
              status="200+ lastweek"
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25.166"
                  height="28.907"
                  viewBox="0 0 25.166 28.907"
                >
                  <g
                    id="noun-files-3972654"
                    transform="matrix(-1, -0.017, 0.017, -1, 97.085, 60.505)"
                  >
                    <path
                      id="Path_517"
                      data-name="Path 517"
                      d="M153.217,32.852v20.88a1.75,1.75,0,0,1-1.75,1.74H140.092a.87.87,0,0,1-.618-.256l-7-6.96a.87.87,0,0,1-.257-.614V32.851a1.75,1.75,0,0,1,1.75-1.74h17.5a1.75,1.75,0,0,1,1.75,1.74Z"
                      transform="translate(-59.332 -0.78)"
                      fill="#2e3c83"
                    />
                    <path
                      className="sidebar-icons"
                      id="Path_518"
                      data-name="Path 518"
                      d="M243.86,124.45v21.96h-18.3v1.83h18.3a1.835,1.835,0,0,0,1.83-1.83V124.45Z"
                      transform="translate(-147.979 -89.431)"
                      fill="#2e3c83"
                    />
                  </g>
                </svg>
              }
              value=""
            />
          </div>
        </div>
      </div> */}

      <div className="d-flex justify-content-between flex-column px-2 gap-2">
        <span className="dashboard-content-title">Score Card Details</span>
        {/* <div className="search-container d-flex align-items-center py-1 px-3 col-12 col-sm-4 ">
          <input
            className="search-input-element d-flex flex-grow-1"
            type="text"
            placeholder="Search"
            onChange={onChangeSearch}
            value={search}
          />{" "}
          <BsSearch className="fs-5" />
        </div> */}
      </div>

      <div className="d-flex flex-column">
        <div className="d-flex m-0 p-0">
          {
            [
              { id: "customer_data_sheet", tabName: "Customer Data Sheet", tabValue: 1 },
              { id: "cam", tabName: "CAM", tabValue: 2 },
              { id: "limits", tabName: "Limits", tabValue: 3 },
            ].map(item => (
              <Fragment key={item.id}>
              <span
                  className={`dashboard-table-title ${activeTableData !== item.tabValue && "dashboard-table-title-hover"} py-2 px-3 ${activeTableData === item.tabValue && "active-table-data placeholder-wave"}`}
                  onClick={() => setActiveTableData(item.tabValue)} onKeyDown={ null }
                >{ item.tabName }</span>
              </Fragment>
            ))
          }

        </div>
        <div>
          {activeTableData === 1 &&
            (customerOnboardData?.isLoading ? (
              <Loader className="my-5" />
            ) : (
              <AdminDashboardTable
                className="lender-dashboard-table"
                // tableRowsCount={tableRowsCount}
                tableHeader={[
                  "Customer",
                  "Name",
                  "Email",
                  "Score Card",
                  "Type",
                ]}
                tableRows={activeTableRows}
                tabValue="1"
              />
            ))}
          {activeTableData === 2 &&
            (customerOnboardData?.isLoading ? (
              <Loader className="my-5" />
            ) : (
              <AdminDashboardTable
                className="lender-dashboard-table"
                // tableRowsCount={tableRowsCount}
                tableHeader={["Customer", "Name", "Email", "CAM", "Type"]}
                tableRows={activeTableRows}
                tabValue="2"
              />
            ))}
          {activeTableData === 3 &&
            (customerOnboardData?.isLoading ? (
              <Loader className="my-5" />
            ) : (
              <AdminDashboardTable
                className="lender-dashboard-table"
                // tableRowsCount={tableRowsCount}
                tableHeader={[
                  "Customer",
                  "Name",
                  "Type",
                  "Max Limit",
                  "Total Utilized Amount",
                ]}
                tableRows={activeTableRows}
                tabValue="3"
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default LenderRiskManagementSystem;
