import { BsSearch } from "react-icons/bs";
import AnchorDashCard from "./AnchorDashCard/AnchorDashCard";

// react components imports
import { useEffect, useState } from "react";

// imports wrt graphs
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {
  BuyerDashPieOne,
  BuyerDashPieTwo,
  BuuyerDashBarOne,
  DashLineGraphData,
} from "./DummyData/DummyData";
import { PieChart, Pie, Sector, Cell, Label } from "recharts";
import { BarChart, Bar } from "recharts";

//for pie chart
import { VictoryPie } from "victory";


import AnchorProgressBar from "./AnchorProgressBar/AnchorProgressBar";
import {Helmet} from "react-helmet";

import atonLogo from "../../assests/images/Aton-logo.png";
import {
  DashBoardIcon,
  MaterialRequestIcon,
  ProformaInvoiceIcon,
  PurchaseOrderIcon,
  DisbursedIcon,
  PaymentReconciliationIcon,
  CounterPartyIcon,
} from "../../assests/icons/sidebar/Anchor/DashBoard";

import imgPowder from "../../assests/images/progress.png";

// Redux realted imports
import { useDispatch, useSelector } from "react-redux";
import {
  getCountryPartyUserLimit,
  getDashboardData,
  getLiquidationDue

} from "../../store/slices/anchor";



const DashboardCardIco = ({width,height}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 25.166 28.907"
    >
      <g
        id="noun-files-3972654"
        transform="matrix(-1, -0.017, 0.017, -1, 97.085, 60.505)"
      >
        <path
          id="Path_517"
          data-name="Path 517"
          d="M153.217,32.852v20.88a1.75,1.75,0,0,1-1.75,1.74H140.092a.87.87,0,0,1-.618-.256l-7-6.96a.87.87,0,0,1-.257-.614V32.851a1.75,1.75,0,0,1,1.75-1.74h17.5a1.75,1.75,0,0,1,1.75,1.74Z"
          transform="translate(-59.332 -0.78)"
          fill="#2e3c83"
        />
        <path
          className="sidebar-icons"
          id="Path_518"
          data-name="Path 518"
          d="M243.86,124.45v21.96h-18.3v1.83h18.3a1.835,1.835,0,0,0,1.83-1.83V124.45Z"
          transform="translate(-147.979 -89.431)"
          fill="#2e3c83"
        />
      </g>
    </svg>
  );
};

export const InvoiceIcon = ({width,height}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 19.426 19.426"
    >
      <g id="noun-bill-1954704" transform="translate(-166.25 -96.25)">
        <path
          className="sidebar-icons"
          data-name="Path 465"
          d="M433.375,98.1h-3.7a.925.925,0,1,1,0-1.85h3.7a.925.925,0,1,1,0,1.85Z"
          transform="translate(-248.624)"
          fill="#2e3c83"
        />
        <path
          className="sidebar-icons"
          data-name="Path 466"
          d="M178.275,96.25h-11.1a.925.925,0,0,0-.925.925v17.576a.925.925,0,0,0,.925.925h11.1a.925.925,0,0,0,.925-.925V97.175A.925.925,0,0,0,178.275,96.25ZM175.5,111.976h-5.55a.463.463,0,1,1,0-.925h5.55a.463.463,0,1,1,0,.925Zm0-3.238h-5.55a.463.463,0,1,1,0-.925h5.55a.463.463,0,1,1,0,.925Zm0-3.7h-5.55a.463.463,0,1,1,0-.925h5.55a.463.463,0,1,1,0,.925Zm0-3.238h-5.55a.463.463,0,1,1,0-.925h5.55a.463.463,0,1,1,0,.925Z"
          fill="#2e3c83"
        />
      </g>
    </svg>
  );
};


/**
 *
 * The Colors and radius for Pie Chart
 */

const COLORS = ["#BFDDA5", "#AEE9EB", "#2E3C83", "#9ABADC"];
const OUTER=[23,30,20,35];

const dues=["Due Today","1 - 30days","31 - 60days","61 - 90days" ];


//dummy data for overdue graph
const overDueData=[{
  name:'Anchor',
  value:20,
},
{
  name:'CounterParty',
  value:90,
}
];



/**
 * This is for converting API response to recharts data structure
 * @params: {Key1, Key2}
 * ** Remember this works only for 2 Keys ***
 * Returns the required Data structure
 */
const convertRechartData = (key1, key2, validResponse) => {
  // let validResponse = []
  let validData = [];

  try {
    // have to get this in arg, the response
    // validResponse =
    for (let ele in validResponse) {
      if (validResponse[ele][key1].length !== validResponse[ele][key2].length) {
        throw Error;
      }

      for (let in1 in validResponse[ele][key1]) {
        let tmp = {};
        tmp[key1] = validResponse[ele][key1][in1];
        tmp[key2] = validResponse[ele][key2][in1];
        validData.push(tmp);
      }
    }
    return validData;
  } catch (e) {
    // console.log(e);
  }
  return null;
};

const BuyerDashboard = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [activeTableData, setActiveTableData] = useState(1);
  const [lineGraphData, setLineGraphData] = useState([{}]);

  // var wrt Redux api's

  // total purchases
  const totalPurchasesResponse = useSelector(
    (state) => state.buyer.totaPurchaseState
  );
  const [totalPurchaseData, setTotalPurchaseData] = useState([{}]);

  // Buyer details
  const BuyerDetailsResponse = useSelector(
    (state) => state.buyer.buyerDetailsState
  );
  const [buyerDetails, setBuyerDetails] = useState({});

  // purchasedBasedDetailSeller
  const purchasedBasedDetailSellerResponse = useSelector(
    (state) => state.buyer.purchasedBasedDetailSellerState
  );


    /* Counter Parties User Limit */
const counterPartyUserLimitData = useSelector(
  (state) => state.anchor.counterPartyUserLimitData
);
const [totalLimitedIssued, setTotalLimitedIssued] = useState(0);
const [totalUtilizedScale, setTotalUtilizedScale] = useState(0);
const [progressPercentage, setProgressPercentage] = useState(0);
const [totalDiscountedInvoices,setTotalDiscountedInvoices]=useState();
const [buyerKeys, setBuyerKeys]=useState([]);


  //get dashboard data
const dashboardData = useSelector(
  (state) => state.anchor.dashboardData
);
const [dashboard, setDashboard] = useState({});


 // Liquidation Datum
 const liquidationDueResponse = useSelector(
  (state) => state.anchor.LiquidationDueData
);
const [liquidationDueGraphData, setLiquidationDueGraphData] = useState({});


var dat = [
  {
    month: 'Jan',
  },
  {
    month: 'Feb',
  },
  {
    month: 'Mar',
  },
  {
    month: 'Apr',
  },
  {
    month: 'May',
  },
  {
    month: 'Jun',
  },
  {
    month: 'Jul',
  },
  {
    month: 'Aug',
  },
  {
    month: 'Sept',
  },
  {
    month: 'Oct',
  },
  {
    month: 'Nov',
  },
  {
    month: 'Dec',
  },
];

const date= new Date();

  // API CALLS
  useEffect(() => {
    dispatch(getDashboardData());
    dispatch(getCountryPartyUserLimit());
    dispatch(getLiquidationDue());
    // dispatch(getPurchasedBasedDetailSeller());
  }, [dispatch]);

  //update the value of TotalInvoices, counterPartiesOnBoarded, Counter Parties User Limit
  useEffect(()=>{
      setDashboard(dashboardData?.data?.data);
      setBuyerKeys(Object.keys(dashboardData?.data?.data?.total_value_of_discounted_invoices_of_top_four_buyers??{}));
      setTotalLimitedIssued((counterPartyUserLimitData?.data?.success?.totalUtilisedAmnt / 10000000)?.toFixed(2));
      setTotalUtilizedScale((counterPartyUserLimitData?.data?.success?.maxLimit  / 10000000)?.toFixed(2));
      setProgressPercentage(totalLimitedIssued*100/totalUtilizedScale);
      setLiquidationDueGraphData(liquidationDueResponse?.data?.items[0]);
    
      dat.forEach((each, index) => {
        const month="month_"+(index+1);
        const data= dashboardData?.data?.data?.discounted_invoices_count_and_value_by_month?.[month];
        if(data!==undefined){
          each.count = data.discounted_invoice_count;
          each.fill= index % 2 === 0 ? '#9ABADC' : '#BFDDA5';
          each.value= data.discounted_invoice_amount
        }
        
      });
      setTotalDiscountedInvoices(dat);
  },[dashboardData.data, counterPartyUserLimitData.data,liquidationDueResponse.data,totalLimitedIssued,totalUtilizedScale])
  
  // response of the Total purchase API
  useEffect(() => {
    if (totalPurchasesResponse.error) {
      // console.log("Failed to fetch Total Purchase API");
    } else if (totalPurchasesResponse.data) {
      setTotalPurchaseData(
        convertRechartData("year", "amount", totalPurchasesResponse.data.items)
      );
    }
  }, [totalPurchasesResponse]);

  // response of the Total purchases API
  useEffect(() => {
    // // console.log(BuyerDetailsResponse.data)
    if (BuyerDetailsResponse.error) {
      // console.log("Failed to fetch Byer details API");
    } else if (BuyerDetailsResponse.data) {
      setBuyerDetails(BuyerDetailsResponse.data["items"][0]);
    }
  }, [BuyerDetailsResponse]);

  // response of the purchased based seller API
  useEffect(() => {
    if (purchasedBasedDetailSellerResponse.error) {
      // console.log("Failed to fetch purchased based seller API");
    } else if (purchasedBasedDetailSellerResponse.data) {
      // console.log(purchasedBasedDetailSellerResponse.data.items);
      // setPurchaseBasedDetailSeller(convertRechartData())
      // setPurchaseBasedDetailSeller(convertRechartData(purchasedBasedDetailSellerResponse.data['items'][0]))
      const dummy = convertRechartData("invoiceAmount", "year", [
        purchasedBasedDetailSellerResponse.data["items"][0].invoiceAmount,
        purchasedBasedDetailSellerResponse.data["items"][0].year,
      ]);
    }
  }, [purchasedBasedDetailSellerResponse]);


  //calculate the maxLimitTotalInvoiceDiscounted
const [maxLimitTotalInvoiceDiscounted, setMaxLimitTotalInvoiceDiscounted]=useState(1000);

  useEffect(()=>{
    if(dashboard?.total_value_of_discounted_invoices_of_top_four_buyers){
      const values= Object.values(dashboard?.total_value_of_discounted_invoices_of_top_four_buyers); //get all the values in the object
      const max = values.reduce((a, b) => Math.max(a, b), -Infinity);//get the max value in the values array
      const exponent = Math.ceil(Math.log10(max)); // fix the exponent of the max value  like for one digit--->1, 2 digit --> 2, 3 digit -->3 
      setMaxLimitTotalInvoiceDiscounted (10 ** exponent); //

    }
  },[dashboard])

  const CustomTooltip = ({ active, payload }) => {
    if (active) {
      return (
        <div className="custom-tooltip">
          {payload[0]?.payload?.month?<p>{`Month: ${payload[0]?.payload?.month}`}</p>:""}
          {payload[0]?.payload?.count!==undefined?<p>{`Count: ${payload[0]?.payload?.count}`}</p>:""}
          {payload[0]?.payload?.value!== undefined?<p>{`Value: ${payload[0]?.payload?.value} Cr`}</p>:""}
        </div>
      );
    }
    return null;
  };




  const onChangeSearch = (event) => {
    setSearch(event.target.value);
  };

  const onClickActiveTable = (value) => {
    setActiveTableData(value);
  };


  return (
    <div className="d-flex flex-column gap-4">
      <Helmet>
                <title>DashBoard</title>
            </Helmet>
      {/* <div className="container bg-white admin-dashboard-card-container px-3 pt-3"> */}
        {/* <div className="row"> */}
          {/* <div className="col-12 col-md-6 col-xl-3 mb-3">
            <BuyerDashCard
              title="Total Invoices"
              count={totalInvoices}
              icon={<DashboardCardIco />}
              value=""
            />
          </div> */}
          {/* <div className="col-12 col-md-6 col-xl-3 mb-3">
            <BuyerDashCard
              title="Counter Parties Onboarded"
              count={counterPartiesOnBoarded}
             // status="200+ lastweek"
              icon={<DashboardCardIco />}
              value=""
            />
          </div> */}
          {/* <div className="col-12 col-md-6 col-xl-3 mb-3">
            <BuyerDashCard
              title="Order Placed"
              count={buyerDetails["orderPlaced"]}
              status="200+ lastweek"
              icon={<DashboardCardIco />}
              value=""
            />
          </div>
          <div className="col-12 col-md-6 col-xl-3 mb-3">
            <BuyerDashCard
              title="Payment Due"
              count={buyerDetails["paymentDue"]}
              status="200+ lastweek"
              icon={<DashboardCardIco />}
              value=""
            />
          </div> */}
        {/* </div> */}
      {/* </div> */}

      {/* -------------------------------------------------------------
          Graph starts here (Row 1)
          ------------------------------------------------------------- */}

      <div className="bg-white admin-dashboard-card-container p-3">
        <div className="container mw-100">
          {/* <div className="row gx-1 gy-5 gy-md-2 my-3 mx-0 w-100 d-flex"> */}
            {/* <div className="col-lg-6 col-12  d-flex justify-content-center "> */}
              {/* <div className="p-2 bg-white anchor_graph_cont flex-column "> */}
                {/* <p>Total Purchases</p> */}
                {/* <ResponsiveContainer> */}
                  {/* <LineChart
                    data={DashLineGraphData}
                    margin={{ top: 20, left: 5, right: 0, bottom: 0 }}
                  >
                    <CartesianGrid
                      strokeDasharray="1 0"
                      vertical={false}
                      horizontal={true}
                    />
                    <XAxis dataKey="name" axisLine={false} dx={0} dy={12} />
                    <YAxis dataKey="pv" axisLine={false} dy={-3} dx={-12} />
                    <Tooltip cursor={{ stroke: "#b5e8e5", strokeWidth: 2 }} />
                    <Legend
                      wrapperStyle={{
                        padding: "1.3% 10px 0px 0px",
                        fontFamily: "Montserrat",
                        fontSize: "20px",
                        display: "flex",
                        justifyContent: "end",
                      }}
                    />
                    <Line
                      type="monotone"
                      dataKey="pv"
                      stroke="#82ca9d"
                      activeDot={{ r: 3 }}
                      strokeWidth={3}
                    />
                  </LineChart> */}
                {/* </ResponsiveContainer> */}
              {/* </div> */}
            {/* </div> */}
            {/* <div className="col-lg-6 col-12  d-flex justify-content-center"> */}
              {/* <div className="p-2 bg-white rounded anchor_graph_cont flex-column "> */}
                {/* <p className="">List of Invoices</p> */}
                {/* <ResponsiveContainer> */}
                  {/* <PieChart
                    margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                    className="d-flex justify-content-space-evenly align-items-center flex-column flex-lg-row"
                  > */}
                    {/* <Label value={60} /> */}
                    {/* <Legend
                      verticalAlign="middle"
                      // align="right"
                      iconType="circle"
                      height={0}
                      // margin={{top:0, right: -10, bottom:0, left:-30}}
                      layout="vertical"
                      wrapperStyle={{
                        position: "relative",
                        display: "inline",
                        top: 0,
                        left: "-7%",
                        // right:0,
                        width: 250,
                        height: 130,
                        // border: '1px solid black',
                      }}
                      // content={<p>Token issuances & approval Rate</p>}
                    /> */}
                    {/* <Pie
                      data={BuyerDashPieOne}
                      innerRadius={90}
                      outerRadius={120}
                      fill="#8884d8"
                      paddingAngle={1}
                      startAngle={0}
                      dataKey="value"
                      cornerRadius={5}
                    >
                      {BuyerDashPieOne.map((entry, index) => (
                        <Cell key={`cell-${index}`} />
                      ))}
                    </Pie> */}
                    {/* <Pie
                      data={BuyerDashPieTwo}
                      innerRadius={90}
                      outerRadius={140}
                      startAngle={0}
                      endAngle={142}
                      cornerRadius={0}
                      fill="#85f2eb"
                    ></Pie> */}

                    {/* <Tooltip /> */}
                  {/* </PieChart> */}
                {/* </ResponsiveContainer> */}
              {/* </div> */}
            {/* </div> */}
          {/* </div> */}
          {/* </div> */}

          {/* -------------------------------------------------------------
          Graph starts here (Row 2)
          ------------------------------------------------------------- */}

          {/* -------------------------------------------------------------
          Graph starts here (Col 1)
          ------------------------------------------------------------- */}
          {/* <div className="row gx-3 gy-4 my-2"> */}
          {/* <div className="row gx-1 gy-5 gy-md-2 my-3 mx-0 w-100 d-flex"> */}
            {/* <div className="col-lg-6 col-12  d-flex justify-content-center "> */}
              {/* <div className="p-2 bg-white anchor_graph_cont flex-column "> */}
                {/* <p className="">Delivery Time Zone</p> */}
                {/* <ResponsiveContainer> */}
                  {/* <BarChart data={BuuyerDashBarOne}>
                    <CartesianGrid
                      strokeDasharray="1 0"
                      vertical={false}
                      horizontal={true}
                    />
                    <XAxis dataKey="name" axisLine={false} dx={0} dy={12} />
                    <YAxis dataKey="pv" axisLine={false} dy={-3} dx={-12} />
                    <Tooltip cursor={{ stroke: "#b5e8e5", strokeWidth: 2 }} />
                    <Legend
                      wrapperStyle={{
                        padding: "1.3% 10px 0px 0px",
                        fontFamily: "Montserrat",
                        fontSize: "20px",
                        display: "flex",
                        justifyContent: "end",
                      }}
                    />
                    <Bar
                      dataKey="uv"
                      fill="#BFDDA6"
                      barSize={20}
                      background={"#fff"}
                    />
                  </BarChart> */}
                {/* </ResponsiveContainer> */}
              {/* </div> */}
            {/* </div> */}

            {/* -------------------------------------------------------------
          Graph starts here (Col 2)
          ------------------------------------------------------------- */}

            {/* <div className="col-lg-6 col-12 d-flex justify-content-center">
              <div className="p-2 bg-white rounded anchor_graph_cont flex-column">
                <p className="">Best Anchor</p>
                <div className="flex-fill progress-bar-cont">
                  <AnchorProgressBar
                    bgcolor="orange"
                    progress={250}
                    height={3}
                    name={"sdd"}
                    imger={imgPowder}
                    className={""}
                  />
                  <AnchorProgressBar
                    bgcolor="orange"
                    progress={250}
                    height={6}
                    name={"sdd"}
                    imger={imgPowder}
                  />
                </div>
              </div>
            </div> */}
          {/* </div> */}









          {/*................. new dashboard */}

          <div className="d-flex justify-content-around align-items-center row">

              {/* ----------first column with 3 rows---------- */}
              <div className="col-lg-5 col-12 d-flex flex-column justify-content-between" >

                {/*-------- row 1 --------*/}
                <div className="p-2 bg-white anchor_graph_cont">
                  <p className="graph-head">Total Limited Issued/Total Utilized Scale</p>
                  <div className="flex-fill anchor-progress-bar-cont">
                    <AnchorProgressBar
                      progress={progressPercentage}
                      color={progressPercentage <50?'#198754':progressPercentage<80?'#ffc107':'#E85354'}
                      height={6}
                      title={"Total Limit Issued"}
                      imger={imgPowder}
                      className={''}
                    />
                    <div className="limit-container col-10">
                      <div className="limit" 
                        style={{ left: progressPercentage < 80 ? `${progressPercentage}%` : 'auto', 
                        right: progressPercentage >= 80 ? `${100 - progressPercentage}%` : 'auto',
                        top: progressPercentage >= 80 ? `-32px` : 'auto' }}
                        >{!isNaN(totalLimitedIssued)?totalLimitedIssued:""}
                      </div>
					            <div className="max-limit">{!isNaN(totalUtilizedScale)?totalUtilizedScale:""}</div>
                    </div>
                  </div>
                  <p className="graph-footer">Amt. INR (in Crores)</p>
                </div>

                {/*-------- row 2 --------*/}
                <div className="p-2 bg-white anchor_graph_cont container">
                  {/* <div className="mt-3">
                    <AnchorDashCard
                      title="No. of Invoice"
                      count={totalInvoices}
                      status="200+ lastweek"
                      icon={<DashboardCardIco width="20px" height="20px"/>}
                      value=""
                    />
                  </div> */}

                  <div className="row">
                    <div className="col-12 col-md-6 mt-3">
                      <AnchorDashCard
                        title="No. of Invoice"
                        count={dashboard?.total_invoice}
                        // status="+19% lastweek"
                        icon={<InvoiceIcon width="20px" height="20px"/>}
                        value=""
                      />
                    </div> 
                    <div className="col-12 col-md-6 mt-3">
                      <AnchorDashCard
                        title="CounterParties OnBoarded"
                        count={dashboard?.counter_parties_onboarded_count}
                        // status="+43%"
                        icon={<InvoiceIcon width="20px" height="20px"/>}
                        value=""
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 mt-3">
                      <AnchorDashCard
                        title="No. of Invoice Discounted"
                        count={dashboard?.total_discounted_invoices}
                        // status="+19% lastweek"
                        icon={<InvoiceIcon width="20px" height="20px"/>}
                        value=""
                      />
                    </div> 
                    <div className="col-12 col-md-6 mt-3">
                      <AnchorDashCard
                        title="No. of Invoice Rejected"
                        count={dashboard?.total_rejected_invoices}
                        // status="+43%"
                        icon={<InvoiceIcon width="20px" height="20px"/>}
                        value=""
                      />
                    </div>
                  </div>
                </div>


                {/*-------- row 3 --------*/}
                <div className="p-2 bg-white anchor_graph_cont">
                  <p className="graph-head">Total Invoice Discounted</p>
                  <div className="flex-fill anchor-progress-bar-cont">
                    <AnchorProgressBar
                      progress={(dashboard?.total_value_of_discounted_invoices_of_top_four_buyers?.[buyerKeys[0]] ?? 0) * (100 / maxLimitTotalInvoiceDiscounted)}
                      color={'#E88A55'}
                      height={6}
                      title={buyerKeys[0]}
                      imger={imgPowder}
                      className={''}
                      value={dashboard?.total_value_of_discounted_invoices_of_top_four_buyers?.[buyerKeys[0]]}
                    />

                    <AnchorProgressBar
                      progress={(dashboard?.total_value_of_discounted_invoices_of_top_four_buyers?.[buyerKeys[1]]??0)*(100/maxLimitTotalInvoiceDiscounted)}
                      color={'#EBDA40'}
                      height={6}
                      title={buyerKeys[1]}
                      imger={imgPowder}
                      className={''}
                      value={dashboard?.total_value_of_discounted_invoices_of_top_four_buyers?.[buyerKeys[1]]}
                    />

                    <AnchorProgressBar
                      progress={(dashboard?.total_value_of_discounted_invoices_of_top_four_buyers?.[buyerKeys[2]]??0)*(100/maxLimitTotalInvoiceDiscounted)}
                      color={'#189DB2'}
                      height={6}
                      title={buyerKeys[2]}
                      imger={imgPowder}
                      className={''}
                      value={dashboard?.total_value_of_discounted_invoices_of_top_four_buyers?.[buyerKeys[2]]}
                    />

                    <AnchorProgressBar
                      progress={(dashboard?.total_value_of_discounted_invoices_of_top_four_buyers?.[buyerKeys[3]]??0)*(100/maxLimitTotalInvoiceDiscounted)}
                      color={'#EF6082'}
                      height={6}
                      title={buyerKeys[3]}
                      imger={imgPowder}
                      className={''}
                      value={dashboard?.total_value_of_discounted_invoices_of_top_four_buyers?.[buyerKeys[3]]}
                    />
                    <div className="x-axis">Top Counter parties </div>
                    <div className="limit-container col-10">
                      <div className="mark1"></div>
                      <div className="mark2"></div>
                      <div className="mark3"></div>
                      <div className="limit" style={{left:'50%'}}>{maxLimitTotalInvoiceDiscounted/2}</div>
					            <div className="max-limit">{maxLimitTotalInvoiceDiscounted}</div>
                    </div>
                  </div>
                  <p className="graph-footer">Amt. INR (in lakhs)</p>
                </div>
              </div>
 
            {/* ----------second column with 3 rows---------- */}
              <div className="col-lg-7 col-12 d-flex flex-column justify-content-between container">

                {/*------- row 1-------- */}

                 
                  <div className="d-flex row ">


                     {/*-------- column1 ----------*/}
                  <div className="col-xl-8 col-12 bg-white anchor_graph_cont mr-2" style={{  marginRight: "15px", overflowX:"scroll"}}>
                    <p className="anchor-graph2-head">Total Invoice Discounted</p>
                    {/* <ResponsiveContainer width="100%" height="100%" > */}
                    <BarChart
                      width={500}
                      height={380}
                      data={totalDiscountedInvoices}
                      margin={{
                        top: 40,
                        right: 0,
                        left: 20,
                        bottom: 30,
                      }}
                    >
                      <CartesianGrid  vertical={false} />
                      <XAxis dataKey="month" tick={{ fontSize: 12 }} >
                      <Label value="Months" offset={-10} position="insideBottomLeft"   fontSize= {12}/>
                      {/* <Label value={`F.Y ${date.getFullYear()}-${date.getFullYear()+1}`} offset={-10}  dx={120} position="insideBottom"  fontSize= {12} /> */}
                      <Label value={`Year. ${date.getFullYear()}`} offset={-10}  dx={120} position="insideBottom"  fontSize= {12} />
                      </XAxis>
                      <YAxis tickCount={11} tick={{ fontSize: 12 }}>
                        <Label value="Number & Value in Cr." angle={-90} dy={50} offset={20} position="insideLeft" fontSize= {12} />
                      </YAxis>
                      <Tooltip content={<CustomTooltip />}/>
                      <Bar dataKey="count"   barSize={10}  />
                    </BarChart>
                    {/* </ResponsiveContainer> */}
                  </div>

                   {/*-------- column2 ----------*/}
                  <div className=" col-xl-3 col-12 bg-white anchor_graph_cont ml-2 position-relative pie" style={{flexGrow:"2" }}>
                    <p className="anchor-graph2-head">Liquidations Due by Age</p>
                      <div className="anchor-pie-chart-container">
                        <div className="pie-chart">
                          <VictoryPie
                            data={[
                              { y: liquidationDueGraphData?.due_amount?.[0],},
                              { y: liquidationDueGraphData?.due_amount?.[1],},
                              { y: liquidationDueGraphData?.due_amount?.[2],},
                              { y: liquidationDueGraphData?.due_amount?.[3],},
                            ]}
                            colorScale={COLORS}
                            radius={({ index }) => OUTER[index] + 70}
                            innerRadius={70}
                            labels={() => null}
                          />
                        </div>

                        <span className="inner-circle"> 
                          <VictoryPie
                            data={[{ y: 40 }]}
                            colorScale={["#E0E0E0"]}
                            innerRadius={60}
                            radius={70}
                            labels={() => null}
                          />
                        </span>
                         

                        <div className="fin-year">F.Y {date.getFullYear()}-{date.getFullYear()+1}</div>
                      </div>

                        {dues.map((entry, index) => {
                         // console.log(entry);
                          return (
                          <div className="d-flex justify-content-start align-items-center anchor-label">
                            <div className="small-circle" style={{backgroundColor:`${COLORS[index]}`}}>
                            </div>
                            <div className="due-name">
                              {entry}
                            </div>
                          </div>
                          );
                        })}

                        <div className="position-absolute pie-detail">
                            <div className="bold">Due Amt. INR (in Crores)</div>
                            {dues.map((entry, index) => {
                                return (
                                <div className="d-flex justify-content-start align-items-center label">
                                  <div className="" style={{backgroundColor:`${COLORS[index]}`, width:"10px", height:"10px", borderRadius:"50%", marginRight:"20px"}}>
                                  </div>
                                  <div className="">
                                  {liquidationDueGraphData?.due_amount?.[index]}
                                  </div>
                                </div>
                                );
                              })}
                
                        </div>
                    </div>
                  </div>

                {/*------- row2-------------- */}
                <div className="bg-white anchor_graph_cont row" style={{ height: "360px", overflowX:"scroll", overflowY:"hidden"}}> 
                <p className="anchor-graph2-head" style={{width:"93%" }}>Overdue Report</p>
                  {/* <ResponsiveContainer width="100%" height="100%" > */}
                  <BarChart
                    width={550}
                    height={330}
                    data={overDueData}
                    margin={{
                      top: 50,
                      right: 0,
                      left: 20,
                      bottom: 30,
                    }}
                  >
                    <CartesianGrid  vertical={false} />
                    <XAxis dataKey="name"  tick={{ fontSize: 12 }}>
                    </XAxis>
                    <YAxis tickCount={10} tick={{ fontSize: 12 }}>
                    <Label value="No of daysOutstanding" angle={-90} dy={50} position="insideLeft" fontSize={12}/>
                    </YAxis>
                    <Tooltip />
                    <Bar dataKey="value" fill='#9ABADC' barSize={50} barGap={5} />
                  </BarChart>
                  {/* </ResponsiveContainer> */}

                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default BuyerDashboard;
