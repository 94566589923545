import { HiOutlineArrowNarrowDown } from "react-icons/hi";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import ReusableModal from "../../../layout/GenericLayout/ReusableModal";
import { nanoid } from "@reduxjs/toolkit";
import TablePagination from "../../../layout/GenericLayout/TablePagination";

/* MODALS OR CHILDREN COMPONENTS */
const StatusHistoryModal = (props) => {
  /* OBJECT DESTRUCTURE */
  const { activeRemarks } = props;

  /* TABLE OPTIONS */
  const dateOptions = {
    day: "numeric",
    year: "numeric",
    month: "short",
  };

  const timeOptions = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  return (
    <div className="">
      <span className="fs-4 fw-semibold">Status History</span>
      <div>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Sl No.</th>
              <th scope="col">Remarks</th>
              <th scope="col">Date</th>
            </tr>
          </thead>
          <tbody>
            {activeRemarks.map((eachRows, index) => (
              <tr key={nanoid()} scope="col">
                <th scope="row">{index + 1}</th>
                <td>{eachRows.remark}</td>
                <td className="d-flex flex-column">
                  <span className="">
                    {new Date(eachRows.timestamp).toLocaleDateString(
                      "en-IN",
                      dateOptions
                    )}
                  </span>
                  <span className="text-uppercase">
                    {new Date(eachRows.timestamp).toLocaleTimeString(
                      "en-IN",
                      timeOptions
                    )}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

/* PARENT COMPONENT */
const SuperAdminTableTable = (props) => {
  /* FUNCTION DECLARATIONS */
  const navigate = useNavigate();
  /* USESTATES */
  const [showModal, setShowModal] = useState(false);
  const [activeRemarks, setActiveRemarks] = useState([]);

  /* EVENT LISTENERS */
  const toggleModal = () => {
    setShowModal((prev) => !prev);
  };

  const onClickStatus = (remarks) => {
    setActiveRemarks(remarks);
    setShowModal(!showModal);
  };



  const updatePage = (currentPage) => {
    const newOffSet = props.tableQueryParams.tableLimit * (currentPage - 1);
    props.setTableQueryParams((prev) => ({
      ...prev,
      tableOffset: newOffSet,
      currentPage,
    }));
  };

  const onClickViewMoreTableData = () => {
    let newLimit = props.tableQueryParams.tableLimit;
    if (newLimit === 5) {
      newLimit += 5;
    } else {
      newLimit -= 5;
    }
    props.setTableQueryParams((prev) => ({
      ...prev,
      tableLimit: newLimit,
    }));
  };


  return (
    <>
      {/* Modal Content */}
      <ReusableModal
        showModal={showModal}
        toggleModal={toggleModal}
        modalContent={<StatusHistoryModal activeRemarks={activeRemarks} />}
        modalSizeClass="modal-lg"
      />
      <div className="table-container">
        <table className={props.className} style={{width:'150%'}}>
          <thead>
            <tr>
              {props.tableHeader.map((eachHeader) => (
                <th key={nanoid()} scope="col">
                  {eachHeader}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {props.tableRows.map((eachRows) => (
              <tr key={eachRows.customerId} scope="col">
                <td>{eachRows.addedDate}</td>
                <td>{eachRows.sellerName}</td>
                <td>{eachRows.sellerId}</td>
                <td>{eachRows.invoiceId}</td>
                <td>{eachRows.invoiceAmount}</td>
                <td>{eachRows.disbursingReceivedAmount}</td>
                <td>{eachRows.buyerInvoiceStatus}</td>
                <td>{eachRows.invoiceStatus}</td>
              </tr>
            ))}
          </tbody>
        </table>

       

        {/* <div className="d-flex justify-content-end px-2">
          <span
            // onClick={props.onClickViewMoreTableData}
            className="d-flex align-items-center gap-1 my-3 view-more-text"
          >
            View more <HiOutlineArrowNarrowDown className="fs-5" />
          </span>
        </div> */}
      </div>

      {!props.isLoading && (
          <div className="d-flex justify-content-end px-2">
            <span
              onClick={onClickViewMoreTableData}
              className="d-flex align-items-center gap-1 my-3 view-more-text"
            >
              {props.tableQueryParams.tableLimit === 5
                ? "View More "
                : "View Less "}
              <HiOutlineArrowNarrowDown
                className={`fs-5 ${
                  props.tableQueryParams.tableLimit === 5
                    ? "down-arrow"
                    : "up-arrow"
                }`}
              />
            </span>
          </div>
        )}
        
      {/* <div className="d-flex align-items-center gap-2 page-data-text-container mt-3 px-3">
        <AiOutlineDoubleLeft
        />
        <span className="page-data-text">
          Page {} of {totalPage}
        </span>
        <AiOutlineDoubleRight
        />
      </div> */}

      <div className="d-flex align-items-center gap-2 page-data-text-container mt-3 px-3 w-100">
        <TablePagination
          pageSize={props.tableQueryParams.tableLimit}
          onUpdatePage={updatePage}
          current={props.tableQueryParams.currentPage}
          total={props.superAdminTrailMetaData?.paging?.total}
        ></TablePagination>
      </div>
    </>
  );
};

export default SuperAdminTableTable;
