import { useNavigate } from "react-router-dom";

const UnderConstructionPage = (props) => {
  const navigate = useNavigate();
  return (
    <div className="d-flex flex-column py-5 align-items-center w-100 bg-white h-100 rounded">
      <h1 class="tape w-100 placeholder-wave mt-5">
        <span className="text-center text-danger fw-semibold">
          PAGE UNDER CONSTRUCTION!
        </span>
      </h1>

      <div className="d-flex flex-column gap-4 align-items-center justify-content-center mx-5 my-3">
        <span className="fs-4 text-danger text-center">
          Please forgive the inconvenience. <br />
          We are currently initializing our brand new site.
        </span>

        <button
          className="btn btn-primary text-white placeholder-wave"
          onClick={() => navigate(props.path)}
        >
          Go Back To Dashboard
        </button>
      </div>
    </div>
  );
};

export default UnderConstructionPage;
