import React, { useState } from "react";
import { BiSort } from "react-icons/bi";
import { GrCheckmark } from "react-icons/gr";
import { ImBin } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../../layout/GenericLayout/Loader";
import { toastMessage } from "../../../layout/GenericLayout/ToastMessage";
import {
  getUnderWriter,
  postAddUnderWriter,
} from "../../../store/slices/lender";
import TablePagination, {
  jumpToPage,
  nextPage,
  previousPage,
} from "../../../layout/GenericLayout/TablePagination";
import { HiOutlineArrowNarrowDown } from "react-icons/hi";

const PurchaseOrderTable = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const addUnderWriterResponse = useSelector(
    (state) => state.lender.addUnderWriter
  );
  const [data, setData] = useState({
    name: "",
    PhNumber: "",
    email: "",
    status: "",
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const updatePage = (currentPage) => {
    const newOffSet = props.tableQueryParams.tableLimit * (currentPage - 1);
    props.setTableQueryParams((prev) => ({
      ...prev,
      tableOffset: newOffSet,
      currentPage,
    }));
  };

  const onClickActiveSort = (activeSortValue) => {
    if (activeSortValue !== "") {
      let order = -1;
      if (activeSortValue === props.tableQueryParams.tableSort) {
        if (props.tableQueryParams.tableOrder === -1) {
          order = 1;
        }
      }
      props.setTableQueryParams((prev) => ({
        ...prev,
        tableSort: activeSortValue,
        tableOrder: order,
      }));
    }
  };

  const onClickViewMoreTableData = () => {
    let newLimit = props.tableQueryParams.tableLimit;
    if (newLimit === 5) {
      newLimit += 5;
    } else {
      newLimit -= 5;
    }
    props.setTableQueryParams((prev) => ({
      ...prev,
      tableLimit: newLimit,
    }));
  };

  const handleAdddata = () => {
    const body = {
      emailId: data.email,
      name: data.name,
      password: "test@123",
      phone: data.PhNumber,
      roles: ["underwriter"],
    };

    if (
      body.emailId.length > 0 &&
      body.name.length > 0 &&
      body.phone.length > 0
    ) {
      dispatch(postAddUnderWriter(body))
        .unwrap()
        .then((response) => {
          toastMessage(response.success, "success");
          setData({ name: "", PhNumber: "", email: "", status: "", });
          props.toggleRMAddModal();
          dispatch(getUnderWriter());
        })
        .catch((error) => {
          console.log(error);
          toastMessage(error.message, "error");
        });
    } else {
      toastMessage("Invalid Input", "error");
    }
  };

  return (
    <>
      <div className="lender-table-container">
        <div className="table-inner-cont">
          <table className={props.className}>
            <thead>
              <tr>
                {props.tableHeader.map((eachHeader) => (
                  <th
                    key={eachHeader.name}
                    scope="col"
                    // role={eachHeader.sortValue !== "" ? "button" : false}
                    className={`${
                      props.tableQueryParams.tableSort ===
                        eachHeader.sortValue &&
                      eachHeader.sortValue !== "" &&
                      "active-sort-text"
                    }`}
                    onClick={() => onClickActiveSort(eachHeader.sortValue)}
                  >
                    {eachHeader.name}
                    {eachHeader.sortValue !== "" && <BiSort />}
                  </th>
                ))}
              </tr>
            </thead>
            {props.materialRequestMetaData?.paging?.total<0?(<div className="no-data text-danger">Oops! there is no data to show. Please check back later</div>):null} 
            {props.isLoading ? (
              <tbody>
                <tr>
                  <td colSpan={5}>
                    <Loader className="my-5" />
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {props.addData === true && (
                  <tr scope="col">
                    <td>
                      <input
                        type="text"
                        value={data.name}
                        name="name"
                        onChange={handleChange}
                        className="w-75 h-100 rounded p-2"
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={data.PhNumber}
                        name="PhNumber"
                        onChange={handleChange}
                        className="w-75 h-100 rounded p-2"
                      />
                    </td>
                    <td>
                      <input style={{textTransform:"lowercase"}}
                        type="text"
                        value={data.email}
                        name="email"
                        onChange={handleChange}
                        className="w-75 h-100 rounded p-2"
                      />
                    </td>
                    <td>
                      {"-"}
                      {/* <input
                        type="text"
                        disabled
                        value={data.status}
                        name="status"
                        onChange={handleChange}
                        className="w-75 h-100 rounded p-2"
                      /> */}
                    </td>
                    {props.addData && (
                      <td style={{ display: "flex" }}>
                        {addUnderWriterResponse?.isLoading ? (
                          <Loader className="mx-2" />
                        ) : (
                          <GrCheckmark
                            onClick={() => handleAdddata()}
                            style={{
                              marginTop: "10px",
                              marginLeft: "30%",
                              cursor: "pointer",
                            }}
                          />
                        )}

                        <ImBin
                          onClick={() => {
                            setData({ name: "", PhNumber: "", email: "", status: "", });
                            props.toggleRMAddModal();
                          }}
                          style={{
                            marginTop: "10px",
                            marginLeft: "20px",
                            color: "red",
                            cursor: "pointer",
                          }}
                        />
                      </td>
                    )}
                  </tr>
                )}
                {props.tableRows?.map((eachRows, index) => (
                  <tr key={eachRows.underWriterId} scope="col">
                    <td>{eachRows.name}</td>
                    <td>{eachRows.phone}</td>
                    <td>{eachRows.emailId}</td>
                    <td
                      className={`text-capitalize ${
                        eachRows.status === "active"
                          ? "text-success"
                          : "text-secondary"
                      }`}
                    >
                      {eachRows.status}
                    </td>
                    <td></td>
                    {/* <td style={{ display: "flex" }}>
                    <GrEdit onClick={()=>props.handleEdit(eachRows.customerId,'Edit')} style={{ marginTop: "10px", marginLeft: "30%" }} />
                    <ImBin onClick={()=>props.handleEdit(eachRows.customerId,'Delete')} style={{ marginTop: "10px", marginLeft: "20px",color:'red' }} />
                  </td> */}
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
          {!props.isLoading && props.materialRequestMetaData?.paging?.total>5 && (
          <div className="d-flex justify-content-end px-2">
         
              <span
              onClick={onClickViewMoreTableData}
              className="d-flex align-items-center justify-content-start gap-1 my-3 view-more-text"
            >
              {props.tableQueryParams.tableLimit === 5
               ? props.tableQueryParams.tableOffset+props.tableQueryParams.tableLimit<props.materialRequestMetaData?.paging?.total?"View More ":""
               : props.tableQueryParams.tableOffset+props.tableQueryParams.tableLimit<props.materialRequestMetaData?.paging?.total?"View Less ":""}
              <HiOutlineArrowNarrowDown
                className={`fs-5 ${
                  props.tableQueryParams.tableLimit === 5
                  ? props.tableQueryParams.tableOffset+props.tableQueryParams.tableLimit<props.materialRequestMetaData?.paging?.total? "down-arrow":"d-none"
                  : props.tableQueryParams.tableOffset+props.tableQueryParams.tableLimit<props.materialRequestMetaData?.paging?.total? "up-arrow":"d-none"}
                `}
              />
            </span>
          </div>
        )}


      <div className="d-flex align-items-center gap-2 page-data-text-container mt-3 px-3 w-100">
        <TablePagination
          pageSize={props.tableQueryParams?.tableLimit}
          onUpdatePage={updatePage}
          current={props.tableQueryParams?.currentPage}
          total={props.materialRequestMetaData?.paging?.total}
        ></TablePagination>
      </div>
    </>
  );
};

export default PurchaseOrderTable;
