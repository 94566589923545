import { CorporateKycActions } from "../actionTypes";
// eslint-disable-next-line no-unused-vars
import { CorporateKYCFormTemplate } from "../corporateKycContext";

export const actions = CorporateKycActions.BANK_DETAILS;

/**
 * @param { typeof CorporateKYCFormTemplate.bankDetails } state 
 * @param {{ type: typeof actions, payload: string | BufferSource }} param1 
 * @returns { typeof CorporateKYCFormTemplate.bankDetails }
 */
export function BankDetailsReducer(state, { type, payload }) {
	
	if (type === actions.BANK_STATEMENT_UPDATE_API) {
		return { ...state, bankStatement: { fileList: payload ?? [], isTouched: true, isVerified: true } };
	}

	else if (type === actions.CHANGE_ACC_NO) {
		const value = payload ?? state.accountNumber.value;

		return { ...state, accountNumber: { value, isValid: value !== "", isTouched: true } };
	}

	else if (type === actions.BLUR_ACC_NO) {
		return { ...state, accountNumber: { ...state.accountNumber, isTouched: true } };
	}

	else if (type === actions.CHANGE_ACC_HOLDER_NAME) {
		const value = payload ?? state.accountHolderName.value;

		return { ...state, accountHolderName: { value, isValid: value.length > 3, isTouched: true } };
	}

	else if (type === actions.BLUR_ACC_HOLDER_NAME) {
		return { ...state, accountHolderName: { ...state.accountHolderName, isTouched: true } };
	}

	else if (type === actions.CHANGE_BANK_NAME) {
		const value = payload ?? state.bankName.value;

		return { ...state, bankName: { value, isValid: value !== "", isTouched: true } };
	}

	else if (type === actions.BLUR_BANK_NAME) {
		return { ...state, bankName: { ...state.bankName, isTouched: true } };
	}

	else if (type === actions.CHANGE_ACC_TYPE) {
		const value = payload ?? state.accountType.value;

		return { ...state, accountType: { value, isValid: value !== "", isTouched: true } };
	}

	else if (type === actions.BLUR_ACC_TYPE) {
		return { ...state, accountType: { ...state.accountType, isTouched: true } };
	}
	
	else if (type === actions.CHANGE_IFSC_CODE) {
		const value = payload ?? state.IFSCCode.value;

		return { ...state, IFSCCode: { value, isValid: value.length > 3, isTouched: true } };
	}

	else if (type === actions.BLUR_IFSC_CODE) {
		return { ...state, IFSCCode: { ...state.IFSCCode, isTouched: true } };
	}

	else if (type === actions.TOUCH_ALL) {
		for (let item in state) {
			for (let obj in state[item]) {
				if (obj === "isTouched") {
					state[item][obj] = true;
				}
			}
		}

		return { ...state };
	}
	
	return state;
}