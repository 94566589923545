import { useEffect, useState } from "react";
import {
  Link,
  Navigate,
  useNavigate,
  useHistory,
  useOutlet,
} from "react-router-dom";
import { BsSearch } from "react-icons/bs";
import { TbBellRingingFilled } from "react-icons/tb";
import { FcAdvertising } from "react-icons/fc";
import { FaBars } from "react-icons/fa";
import { RxCross1 } from "react-icons/rx";
import { AiFillLeftCircle, AiFillSetting } from "react-icons/ai";
import { FiLogOut } from "react-icons/fi";
import atonLogo from "../../assests/images/Aton-logo.png";
import notificationDeclined from "../../assests/icons/notification-declined.svg";
import notificationActive from "../../assests/icons/notification-active.svg";
import { Outlet } from "react-router-dom";
import { nanoid } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

const ChannelLayout = (props) => {
  const outlet = useOutlet();
  const navigate = useNavigate();
  const userDetails = JSON.parse(sessionStorage.getItem("user"));
  const [activeMenu, setActiveMenu] = useState("/channel/dashboard");
  const [notification, setNotification] = useState([
    {
      title: "Welcome to ATON!",
      message:
        "Hi there! Thank you for signing up! You are an official ATON user now.",
      timeStamp: "3 days ago",
      id: nanoid(),
    },
  ]);
  const [mobileNavbarStatus, setMobileNavbarBar] = useState(false);
  const [adminSearch, setAdminSearch] = useState("");

  const [isLogout, setIsLogout] = useState(false);

  const onClickActiveMenu = (path) => {
    setActiveMenu(path);
    setMobileNavbarBar((prev) => !prev);
    navigate(path);
  };

  const onClickSidebarCollaspe = () => {
    let sidebar = document.querySelector(".admin-sidebar");
    if (sidebar.style.minWidth === "120px") {
      sidebar.style.minWidth = "350px";
      sidebar.style.width = "350px";
    } else {
      sidebar.style.minWidth = "120px";
      sidebar.style.width = "120px";
    }

    let sidebarMenuText = document.querySelectorAll(".sidebar-text");
    sidebarMenuText.forEach((eachMenuText) => {
      if (eachMenuText.style.display === "none") {
        eachMenuText.style.display = "";
      } else {
        eachMenuText.style.display = "none";
      }
    });

    let sidebarCircle = document.querySelector(".sidebar-circle-icon");
    if (sidebarCircle.style.transform === "rotate(180deg)") {
      sidebarCircle.style.transform = "rotate(0deg)";
    } else {
      sidebarCircle.style.transform = "rotate(180deg)";
    }
  };

  const onChangeAdminSearch = (event) => {
    // Search resutls to be handled
    setAdminSearch(event.target.value);
  };

  const onClickLogout = () => {
    sessionStorage.clear();
    window.location.href = "/login";
  };

  /* SMALL AND MEDIUM DEVICED EVENTS */
  const onClickMobileNavbar = () => {
    setMobileNavbarBar((prev) => !prev);
  };

  return (
    <>
      {/* desktop and tablet layout */}
      <div className="d-md-flex gap-2 admin-layout-bg-container p-3 pe-2 d-none">
        <div className="admin-sidebar mh-100 d-flex flex-column py-1">
          <div className="sidebar-circle">
            <AiFillLeftCircle
              className="sidebar-circle-icon"
              onClick={onClickSidebarCollaspe}
            />
          </div>
          <Link to="/channel-partner/dashboard">
            <div className="d-flex justify-content-center align-items-center">
              <img src={atonLogo} className="admin-sidebar-image-logo" />
            </div>
          </Link>
          <div className="sidebar-menu-container d-flex flex-column gap-2">
            <div
              className={`d-flex align-items-center px-5 gap-3 py-2 ${
                window.location.pathname.includes(
                  "/channel-partner/dashboard" || "/channel-partner/kyc"
                )
                  ? "sidebar-active-menu"
                  : "menu-container placeholder-wave"
              }`}
              onClick={() => onClickActiveMenu("/channel-partner/dashboard")}
              title="Dashboard"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26.826"
                height="26.816"
                viewBox="0 0 26.826 26.816"
              >
                <g
                  id="noun-dashboard-3644009"
                  transform="translate(-98.002 -28.113)"
                >
                  <path
                    className="sidebar-icons"
                    id="Path_6"
                    data-name="Path 6"
                    d="M100.684,28.113h6.626a2.882,2.882,0,0,1,2.682,2.682v3.914a2.682,2.682,0,0,1-2.682,2.682h-6.626A2.882,2.882,0,0,1,98,34.709V30.8a2.682,2.682,0,0,1,2.682-2.682Z"
                    fill="#2e3c83"
                  />
                  <path
                    className="sidebar-icons"
                    id="Path_7"
                    data-name="Path 7"
                    d="M379.394,28.113h6.626A2.882,2.882,0,0,1,388.7,30.8v9.192a2.682,2.682,0,0,1-2.682,2.682h-6.626a2.882,2.882,0,0,1-2.682-2.682V30.8A2.682,2.682,0,0,1,379.394,28.113Z"
                    transform="translate(-263.875 0)"
                    fill="#2e3c83"
                  />
                  <path
                    className="sidebar-icons"
                    id="Path_8"
                    data-name="Path 8"
                    d="M100.684,258.44h6.626a2.882,2.882,0,0,1,2.682,2.682v9.192A2.683,2.683,0,0,1,107.311,273h-6.626A2.882,2.882,0,0,1,98,270.315v-9.192A2.682,2.682,0,0,1,100.684,258.44Z"
                    transform="translate(0 -218.068)"
                    fill="#2e3c83"
                  />
                  <path
                    className="sidebar-icons"
                    id="Path_9"
                    data-name="Path 9"
                    d="M379.394,357.56h6.626a2.882,2.882,0,0,1,2.682,2.682v3.914a2.682,2.682,0,0,1-2.682,2.682h-6.626a2.882,2.882,0,0,1-2.682-2.682v-3.914A2.682,2.682,0,0,1,379.394,357.56Z"
                    transform="translate(-263.875 -311.912)"
                    fill="#2e3c83"
                  />
                </g>
              </svg>{" "}
              <span className="sidebar-text">Dashboard</span>
            </div>

            <div
              className={`d-flex align-items-center px-5 gap-3 py-2 ${
                window.location.pathname.includes(
                  "/channel-partner/customer-onboarding"
                )
                  ? "sidebar-active-menu"
                  : "menu-container placeholder-wave"
              }`}
              onClick={() =>
                onClickActiveMenu("/channel-partner/customer-onboarding")
              }
              title="Customer Onboarding"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25.62"
                height="20.13"
                viewBox="0 0 25.62 20.13"
              >
                <path
                  className="sidebar-icons"
                  id="Icon_ionic-md-card"
                  data-name="Icon ionic-md-card"
                  d="M25.308,5.625H4.812A2.52,2.52,0,0,0,2.261,8.141l-.011,15.1a2.53,2.53,0,0,0,2.562,2.516h20.5a2.53,2.53,0,0,0,2.562-2.516V8.141A2.53,2.53,0,0,0,25.308,5.625Zm0,17.614H4.812V15.69h20.5Zm0-12.581H4.812V8.141h20.5Z"
                  transform="translate(-2.25 -5.625)"
                  fill="#2e3c83"
                />
              </svg>

              <span className="sidebar-text">Customer-Onboarding</span>
            </div>
          </div>
        </div>
        <div className="admin-content-panel d-flex flex-column gap-2 flex-grow-1 mw-100">
          <div className="d-flex justify-content-between align-items-center pe-3">
            <div className="admin-search-container d-flex align-items-center py-1 px-4 mx-3">
              <input
                value={adminSearch}
                className="admin-search-input-element d-flex flex-grow-1"
                type="search"
                placeholder="Search"
                onChange={onChangeAdminSearch}
              />{" "}
              <BsSearch />
            </div>
            <div className="d-flex gap-3 align-items-end">
              <div className="dropdown-center">
                <button
                  className="notification-bell border-0"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {notification.length === 0 ? (
                    <img src={notificationDeclined} />
                  ) : (
                    <>
                      <img src={notificationActive} />
                      <span className="position-absolute top-0 start-95 translate-middle badge rounded-pill bg-success">
                        {notification.length > 99 ? "99+" : notification.length}
                        <span className="visually-hidden">unread messages</span>
                      </span>
                    </>
                  )}
                </button>
                <ul
                  className="dropdown-menu dropdown-menu-start border-0 shadow"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <h6 className="dropdown-header d-flex align-items-center gap-2">
                      <TbBellRingingFilled /> Notification
                    </h6>
                  </li>
                  {notification.length === 0 ? (
                    <li className="dropdown-item d-flex flex-column justify-content-center align-items-center">
                      <FcAdvertising className="fs-3" />
                      No new notification!
                    </li>
                  ) : (
                    notification.map((eachNotification) => (
                      <li
                        key={eachNotification.id}
                        className="dropdown-item d-flex flex-column justify-content-center text-wrap"
                      >
                        <h6 className="dropdown-header d-flex gap-3 justify-content-between align-items-center p-0">
                          <span>{eachNotification.title}</span>
                          <small className="text-muted">
                            {eachNotification.timeStamp}
                          </small>
                        </h6>
                        {eachNotification.message}
                      </li>
                    ))
                  )}
                </ul>
              </div>
              <div className="d-flex align-items-center gap-2">
                <div className="dropdown-center">
                  <button
                    className="border-0"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      className="admin-profile-pic rounded-circle"
                      src="https://picsum.photos/200"
                      alt="admin pro"
                    />
                  </button>
                  <ul
                    className="dropdown-menu dropdown-menu-start border-0 shadow"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <h6 className="dropdown-header d-flex align-items-center gap-2">
                        <AiFillSetting /> Menu
                      </h6>
                    </li>

                    <li
                      className="dropdown-item fw-semibold d-flex align-items-center gap-2"
                      style={{ cursor: "pointer" }}
                      onClick={onClickLogout}
                    >
                      {" "}
                      <FiLogOut /> Logout
                    </li>
                  </ul>
                </div>

                <div className="d-flex flex-column justify-content=evenly">
                  <span className="admin-name">
                    {userDetails.name === null ? "N/A" : "Hi, " + userDetails.name}
                  </span>
                  <span className="role">Channel Partner!</span>
                </div>
              </div>
            </div>
          </div>
          <div className="admin-content mx-2">
            {window.innerWidth > 767 && outlet}
          </div>
        </div>
      </div>
      {/* mobile layout */}
      <div
        className={`d-md-none admin-layout-small-devices-bg-container d-flex flex-column ${
          mobileNavbarStatus && "active-navbar"
        }`}
      >
        <div className="topnav d-flex flex-column">
          <div className="d-flex justify-content-between align-items-center px-2 py-2 only-navbar">
            <div className="d-flex align-items-center gap-2">
              <button
                onClick={onClickMobileNavbar}
                type="button"
                className="btn outline-0 border-0"
              >
                {mobileNavbarStatus ? (
                  <RxCross1 className="fs-1" />
                ) : (
                  <FaBars className="fs-2" />
                )}
              </button>

              <Link to="/channel-partner/dashboard">
                <div className="d-flex justify-content-center align-items-center">
                  <img src={atonLogo} className="mobile-navbar-image-logo" />
                </div>
              </Link>
            </div>

            <div className="d-flex align-items-center gap-2">
              <div className="dropdown-center">
                <button
                  className="border-0 bg-transparent"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {notification.length === 0 ? (
                    <img src={notificationDeclined} />
                  ) : (
                    <>
                      <img src={notificationActive} />
                      <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success">
                        {notification.length > 99 ? "99+" : notification.length}
                        <span className="visually-hidden">unread messages</span>
                      </span>
                    </>
                  )}
                </button>
                <ul
                  className="dropdown-menu dropdown-menu-start border-0 shadow"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <h6 className="dropdown-header d-flex align-items-center gap-2">
                      <TbBellRingingFilled /> Notification
                    </h6>
                  </li>
                  {notification.length === 0 ? (
                    <li className="dropdown-item d-flex flex-column justify-content-center align-items-center">
                      <FcAdvertising className="fs-3" />
                      No new notification!
                    </li>
                  ) : (
                    notification.map((eachNotification) => (
                      <li
                        key={eachNotification.id}
                        className="dropdown-item d-flex flex-column justify-content-center text-wrap"
                      >
                        <h6 className="dropdown-header d-flex justify-content-between align-items-center p-0">
                          <span>{eachNotification.title}</span>
                          <small className="text-muted">
                            {eachNotification.timeStamp}
                          </small>
                        </h6>
                        {eachNotification.message}
                      </li>
                    ))
                  )}
                </ul>
              </div>

              <div className="d-flex align-items-center gap-2">
                <div className="dropdown-center">
                  <button
                    className="border-0 bg-transparent"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      className="admin-profile-pic rounded-circle"
                      src="https://picsum.photos/40"
                    />
                  </button>
                  <ul
                    className="dropdown-menu dropdown-menu-start border-0 shadow"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <h6 className="dropdown-header d-flex align-items-center gap-2">
                        <AiFillSetting /> Admin!
                      </h6>
                    </li>

                    <li className="dropdown-item d-flex flex-column justify-content-center align-items-center">
                      {userDetails.name === null ? "N/A" : userDetails.name}
                    </li>
                  </ul>
                </div>
              </div>

              <div
                className="fw-semibold d-flex align-items-center justify-content-center"
                style={{ cursor: "pointer" }}
                onClick={onClickLogout}
              >
                <FiLogOut className="fs-2" />
              </div>
            </div>
          </div>

          {mobileNavbarStatus && (
            <div className="min-vh-100 mx-auto">
              <div
                className="d-flex flex-column gap-1 justify-content-center"
                style={{ padding: "0 100px" }}
              >
                <div
                  className={`d-flex align-items-center gap-3 py-2 ${
                    window.location.pathname.includes(
                      "/channel-partner/dashboard"
                    )
                      ? "mobile-sidebar-active-menu"
                      : "mobile-menu-container"
                  }`}
                  onClick={() =>
                    onClickActiveMenu("/channel-partner/dashboard")
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26.826"
                    height="26.816"
                    viewBox="0 0 26.826 26.816"
                  >
                    <g
                      id="noun-dashboard-3644009"
                      transform="translate(-98.002 -28.113)"
                    >
                      <path
                        className="sidebar-icons"
                        id="Path_6"
                        data-name="Path 6"
                        d="M100.684,28.113h6.626a2.882,2.882,0,0,1,2.682,2.682v3.914a2.682,2.682,0,0,1-2.682,2.682h-6.626A2.882,2.882,0,0,1,98,34.709V30.8a2.682,2.682,0,0,1,2.682-2.682Z"
                        fill="#2e3c83"
                      />
                      <path
                        className="sidebar-icons"
                        id="Path_7"
                        data-name="Path 7"
                        d="M379.394,28.113h6.626A2.882,2.882,0,0,1,388.7,30.8v9.192a2.682,2.682,0,0,1-2.682,2.682h-6.626a2.882,2.882,0,0,1-2.682-2.682V30.8A2.682,2.682,0,0,1,379.394,28.113Z"
                        transform="translate(-263.875 0)"
                        fill="#2e3c83"
                      />
                      <path
                        className="sidebar-icons"
                        id="Path_8"
                        data-name="Path 8"
                        d="M100.684,258.44h6.626a2.882,2.882,0,0,1,2.682,2.682v9.192A2.683,2.683,0,0,1,107.311,273h-6.626A2.882,2.882,0,0,1,98,270.315v-9.192A2.682,2.682,0,0,1,100.684,258.44Z"
                        transform="translate(0 -218.068)"
                        fill="#2e3c83"
                      />
                      <path
                        className="sidebar-icons"
                        id="Path_9"
                        data-name="Path 9"
                        d="M379.394,357.56h6.626a2.882,2.882,0,0,1,2.682,2.682v3.914a2.682,2.682,0,0,1-2.682,2.682h-6.626a2.882,2.882,0,0,1-2.682-2.682v-3.914A2.682,2.682,0,0,1,379.394,357.56Z"
                        transform="translate(-263.875 -311.912)"
                        fill="#2e3c83"
                      />
                    </g>
                  </svg>{" "}
                  <span className="sidebar-text">Dashboard</span>
                </div>

                <hr className="px-5 mobile-nav-line my-2" />

                <div
                  className={`d-flex align-items-center gap-3 py-2 ${
                    window.location.pathname.includes(
                      "/channel-partner/customer-onboarding"
                    )
                      ? "mobile-sidebar-active-menu"
                      : "mobile-menu-container"
                  }`}
                  onClick={() =>
                    onClickActiveMenu("/channel-partner/customer-onboarding")
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25.166"
                    height="28.907"
                    viewBox="0 0 25.166 28.907"
                  >
                    <g
                      id="noun-files-3972654"
                      transform="matrix(-1, -0.017, 0.017, -1, 97.085, 60.505)"
                    >
                      <path
                        className="sidebar-icons"
                        id="Path_517"
                        data-name="Path 517"
                        d="M153.217,32.852v20.88a1.75,1.75,0,0,1-1.75,1.74H140.092a.87.87,0,0,1-.618-.256l-7-6.96a.87.87,0,0,1-.257-.614V32.851a1.75,1.75,0,0,1,1.75-1.74h17.5a1.75,1.75,0,0,1,1.75,1.74Z"
                        transform="translate(-59.332 -0.78)"
                        fill="#2e3c83"
                      />
                      <path
                        className="sidebar-icons"
                        id="Path_518"
                        data-name="Path 518"
                        d="M243.86,124.45v21.96h-18.3v1.83h18.3a1.835,1.835,0,0,0,1.83-1.83V124.45Z"
                        transform="translate(-147.979 -89.431)"
                        fill="#2e3c83"
                      />
                    </g>
                  </svg>
                  <span className="sidebar-text">Customer-Onboarding</span>
                </div>

                <hr className="px-5 mobile-nav-line my-2" />
              </div>
            </div>
          )}
        </div>
        {!mobileNavbarStatus && (
          <div className="min-vh-100 p-2">
            {window.innerWidth < 768 && outlet}
          </div>
        )}
      </div>
    </>
  );
};

export default ChannelLayout;
