import React, { useEffect, useState } from "react";
import { BsPlusCircleFill, BsSearch } from "react-icons/bs";
import UnderwriterTables from "./UnderwriterTables/UnderwriterTables";
import Loader from "../../layout/GenericLayout/Loader";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {getCreditLimitDetails} from "../../store/slices/underwriter";
import {Helmet} from "react-helmet";



const UnderwriterLimit = () => {


  const dispatch = useDispatch();
  const creditLimitDetailsTableData = useSelector((state)=> state.underwriter.creditLimitDetailsData)
  const [creditLimitDetailsTableRows, setCreditLimitDetailsTableRows] = useState([]);
  // const [materialRequestMetaData, setMaterialRequestMetaData] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  /* TABLE QUERY PARAMS */
  const [tableQueryParams, setTableQueryParams] = useState({
    tableOffset: 0,
    tableLimit: 5,
    tableSort: "",
    tableOrder: -1,
    currentPage: 1,
  });

  function fetchCreditLimitDetails() {
    dispatch(getCreditLimitDetails())
      .unwrap()
      .then((response) => {
        setCreditLimitDetailsTableRows(response?.success)
      })
      .catch((error) => {
        console.log("error : ", error);
      });
  }

  useEffect(()=>{
      // setCreditLimitDetailsTableRows();
    fetchCreditLimitDetails();
  },[dispatch])

 console.log(creditLimitDetailsTableData)

  return (<>
    <div className="p-0"
    style={{overflowX:'hidden'}}
    >
      <Helmet>
                <title>Underwriter-Limit</title>
            </Helmet>
      <div className="bg-white admin-dashboard-card-container p-3">
        <div className="d-flex justify-content-between align-items-center flex-wrap px-2 gap-2 py-2">
          <span className="dashboard-content-title">
          Credit Limit Details
          </span>
        </div>

        <div
              className="mb-2"
              style={{
                overflowX: "hidden",
                overflowY: "hidden",
                boxSizing: "border-box",
                // border:'1px solid black'
              }}
            >
              <UnderwriterTables
                className="buyer-table placeholder-wave"
                tableHeader={[
                  { name: "Customer ID", sortValue: "" },
                  { name: "Name", sortValue: "" },
                  { name: "Type", sortValue: "" },
                  { name: "Max Limit (₹)", sortValue: "" },
                  { name: "Total Utilized Amount (₹)", sortValue: "" },
                  { name: "Available Amount (₹)", sortValue: "" },
                  { name: "Grade", sortValue: "" },
                  { name: "Limit Expiry", sortValue: "" },
                  { name: "Limit Next Renewal", sortValue: "" },
                  { name: "Line Of Credit", sortValue: "" },
                  { name: "Score Card", sortValue: "" },
                  { name: "CAM", sortValue: "" },
                  { name: "Status", sortValue: "" },
                ]}
                tableRows={creditLimitDetailsTableRows}
                tableQueryParams={tableQueryParams}
                isLoading={creditLimitDetailsTableData.isLoading}
                fetchCreditLimitDetails={ fetchCreditLimitDetails }
              />
        </div> 

      </div>
     

    </div>
  </>
  );
};

export default UnderwriterLimit;
