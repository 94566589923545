import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { BsPlusCircleFill } from "react-icons/bs";

// import AdminRMDetailsTable from "./AdminTables/AdminRMDetailsTable";
import { useDispatch, useSelector } from "react-redux";
import { addLender, getAllLender } from "../../store/slices/admin";
import ReusableModal from "../../layout/GenericLayout/ReusableModal";

import { toastMessage } from "../../layout/GenericLayout/ToastMessage";
import { useNavigate } from "react-router-dom";
import { getListOfCustomersOnboarding } from "../../store/slices/corporate";
import CorporateListOfCustomerOnboardingTable from "./CorporateTables/CorporateListOfCustomerOnboardingTable";
import Loader from "../../layout/GenericLayout/Loader";
import {Helmet} from "react-helmet";

/* MODALS OR CHILDREN COMPONENTS */
const ChooseOnboardType = ({ toggleOnboardModal, onRefreshTable }) => {
  /* FUNCTION DECLARATIONS */
  const navigate = useNavigate();

  const onClickOnboardAnchor = () => {
    navigate("/corporate/corporate-onboarding/anchor");
  };

  const onClickOnboardCounterParty = () => {
    navigate("/corporate/corporate-onboarding/counter-party");
  };

  return (
    <div className="pb-3">
      <span className="fs-4 fw-semibold">Onboard Anchor/Counter Party</span>
      <div className="details-container container mt-4">
        <div className="row gy-md-4 gy-2">
          <div className="kyc-fields-container col-12 col-md-6 text-center">
            <button
              onClick={onClickOnboardAnchor}
              className="btn btn-outline-primary px-5 py-3 fs-5"
            >
              Onboard Anchor
            </button>
          </div>

          <div className="kyc-fields-container col-12 col-md-6 text-center">
            <button
              onClick={onClickOnboardCounterParty}
              className="btn btn-outline-primary px-5 py-3 fs-5"
            >
              Onboard Counter Party
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

/* PARENT COMPONENT */
const CorporateCustomerOnboarding = () => {
  const dispatch = useDispatch();
  const listOfCustomersOnboardingData = useSelector(
    (state) => state.corporate.listOfCustomersOnboardingData
  );
  const [channelAllUsersTableRows, setChannelAllUsersTableRows] = useState([]);

  const [isHovered, setIsHovered] = useState(false);

  /* MODALS USESTATE */
  const [showChooseOnboardTypeModal, setShowLenderAddModal] = useState(false);

  /* MODALS TOGGLE */
  const toggleOnboardModal = () => {
    setShowLenderAddModal((prev) => !prev);
  };

  useEffect(() => {
    dispatch(getListOfCustomersOnboarding())
      .unwrap()
      .then((response) => {
        setChannelAllUsersTableRows(response?.success)
        console.log("response : ", response);
      })
      .catch((error) => {
        console.log("error : ", error);
      });
  }, []);

  const handleMouseEnter = () => {
    // setIsHovered((prev) => !prev);
  };

  const handleMouseLeave = () => {
    // setIsHovered((prev) => !prev);
  };

  return (
    <>
      {/* MODAL CONTENT */}
      {showChooseOnboardTypeModal && (
        <ReusableModal
          showModal={showChooseOnboardTypeModal}
          toggleModal={toggleOnboardModal}
          modalContent={
            <ChooseOnboardType toggleOnboardModal={toggleOnboardModal} />
          }
          modalSizeClass="modal-lg"
        />
      )}

      <div className="d-flex flex-column gap-4">
      <Helmet>
                <title>Corporate-Customer-Onboarding</title>
            </Helmet>
        <div className="bg-white admin-dashboard-card-container px-3 pt-3 d-flex flex-column gap-2 pb-5">
          <div className="d-flex justify-content-between align-items-center flex-wrap px-2 gap-2 py-2">
            <span className="dashboard-content-title">
              List Of Customer Onboarding
            </span>
            <div
              onClick={toggleOnboardModal}
              className="d-flex align-items-center"
              // type="button"
            >
              <div
                className={`rm-add-button ${isHovered ? "show" : ""}`}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                ADD
              </div>
              <BsPlusCircleFill
                className="rm-plus-button"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              />
            </div>
          </div>
          {listOfCustomersOnboardingData.isLoading ? (
            <Loader className="my-5" />
          ) : (
            <CorporateListOfCustomerOnboardingTable
              className="admin-channel-partner-data-table"
              tableHeader={[
                "Sector Name",
                "Email ID",
                "Roles",
                "Application Status",
              ]}
              tableRows={channelAllUsersTableRows}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default CorporateCustomerOnboarding;
