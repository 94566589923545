import BannerImg from "../assests/images/rodion-kutsaiev-JKGjV3E-HnY-unsplash@2x.png";
import atonLogo from "../assests/images/Aton-logo.png";
import { Link } from "react-router-dom";

const ErrorPage = () => {
  // const navigate = useNavigation()

  return (
    <>
      <div className="login-page-layout">
        <div className="login-banner">
          <img
            src={BannerImg}
            style={{ width: "100%", height: "100%", zIndex: 0 }}
            alt="ban img"
          />
          <div className="left-transparent"></div>
          <div className="right-transparent"></div>
          <div className="login-card-body lightSpeedIn d-flex flex-column justify-content-center align-items-center">
            <img
              src={atonLogo}
              alt="logo img"
              height={130}
              width={100}
              className="my-1"
            />
            <h3 className="my-5">404 Page not found !</h3>
            <Link to={"/"}>
              <button className="btn btn-success">Login</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
