const AnchorDashCard = (props) => {
    //   const {} = props
    return (
      <div className="anchor-dashboard-card d-flex flex-grow-1 flex-column">
        <span className="px-2 p-1 mt-2 card-title">{props?.title}</span>
        <hr className="card-line my-0" />
        <div className="d-flex p-1 px-4">
          <div className="d-flex flex-column flex-grow-1 ">
            <span className="card-count mt-1">{props?.count}</span>
            <span className="card-status mb-3 " style={{minHeight:"30px"}}>{props?.status}</span>
          </div>
          <div className="card-icon">{props?.icon}</div>
        </div>
      </div>
    );
  };
  
  export default AnchorDashCard;
  