import React, { useEffect, useState } from "react";
import { BsPlusCircleFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { InputValidator } from "../../layout/GenericLayout/InputValidator";
import Loader from "../../layout/GenericLayout/Loader";
import ReusableModal from "../../layout/GenericLayout/ReusableModal";
import { toastMessage } from "../../layout/GenericLayout/ToastMessage";
import {
  addInventoryDetails,
  addInventoryFileDetails,
  getDigitalInvoiceTable,
  getInventoryTable,
  getUserRetailerById,
} from "../../store/slices/anchor";
import AnchorInventoryTable from "./AnchorTables/AnchorInventoryTable";
import { Helmet } from "react-helmet";

/* MODALS OR CHILDREN COMPONENTS */
const CreateManualInvoiceModal = ({ toggleModal }) => {
  const dispatch = useDispatch();
  const [inventoryData, setInventoryData] = useState({});
  const [isError, setIsError] = useState({});

  const inventoryAddTableData = useSelector(
    (state) => state.anchor.inventoryAddTableData
  );

  useEffect(() => {
    dispatch(getUserRetailerById());
  }, [dispatch]);

  const onChangeInputUpload = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const pattern = new RegExp(event.target.pattern);

    if (value === "") {
      setInventoryData((prev) => ({ ...prev, [name]: value }));
      return;
    }

    if (pattern.test(value) === false) {
      if (name === "quantity" || name === "unitPrice") {
        toastMessage("This field accept only Positive Numbers", "error");
        return;
      }
      toastMessage("This field accept only alphabet characters", "error");
    } else {
      setInventoryData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const onSubmitCreateManualInvoice = (event) => {
    event.preventDefault();

    if (
      !inventoryData?.productName ||
      !inventoryData?.modelNumber ||
      !inventoryData?.description ||
      !inventoryData?.HSN ||
      !inventoryData?.quantity ||
      !inventoryData?.unitPrice
    ) {
      toastMessage("All Fields are required");
      return;
    }

    dispatch(addInventoryDetails(inventoryData))
      .unwrap()
      .then((response) => {
        console.log("response : ", response);
        toastMessage(response.success, "success");
        toggleModal();
      })
      .catch((error) => {
        console.log("error : ", error);
        toastMessage(error.message, "error");
      });
  };

  const onBlurInputFeilds = (event) => {
    const name = event.target.id;
    const value = event.target.value;

    console.log(name, value);

    const validator = () => {
      return {
        regex: /^.+$/,
        message: "Field is required",
      };
    };

    const errorMessgae = InputValidator({
      regex: validator().regex,
      value,
      message: validator().message,
    });

    setIsError((prev) => ({
      ...prev,
      [name]: errorMessgae,
    }));
  };

  return (
    <form onSubmit={onSubmitCreateManualInvoice} className="">
      <span className="fs-4 fw-semibold">Add Inventory</span>
      <div className="details-container container mt-4">
        <div className="row gy-md-4 gy-2">
          <div className="kyc-fields-container col-12  col-md-6">
            <label htmlFor="productName" className="kyc-label-text form-label">
              Product Name
            </label>
            <input
              required
              onChange={onChangeInputUpload}
              id="productName"
              name="productName"
              type="text"
              pattern="^[a-zA-Z. ]*$"
              className="kyc-input-field form-control"
              value={inventoryData?.productName}
            />
          </div>

          <div className="kyc-fields-container col-12  col-md-6">
            <label htmlFor="modelNumber" className="kyc-label-text form-label">
              Model Number
            </label>
            <input
              // onBlur={onBlurAddRMDetailsFields}
              required
              onChange={onChangeInputUpload}
              id="modelNumber"
              type="text"
              name="modelNumber"
              className="kyc-input-field form-control"
              value={inventoryData?.modelNumber}
            />
          </div>

          <div className="kyc-fields-container col-12  col-md-6">
            <label htmlFor="description" className="kyc-label-text form-label">
              Description
            </label>
            <input
              required
              onChange={onChangeInputUpload}
              id="description"
              type="text"
              pattern="^[a-zA-Z. ]*$"
              name="description"
              className="kyc-input-field form-control"
              value={inventoryData?.description}
            />
          </div>

          <div className="kyc-fields-container col-12  col-md-6">
            <label htmlFor="HSN" className="kyc-label-text form-label">
              HSN Code
            </label>
            <input
              required
              onChange={onChangeInputUpload}
              id="HSN"
              type="text"
              name="HSN"
              className="kyc-input-field form-control"
              value={inventoryData?.HSN}
            />
          </div>

          <div className="kyc-fields-container col-12  col-md-6">
            <label htmlFor="quantity" className="kyc-label-text form-label">
              Available Quantity (min 1)
            </label>
            <input
              required
              onChange={onChangeInputUpload}
              id="quantity"
              type="number"
              name="quantity"
              className="kyc-input-field form-control"
              onKeyDown={(evt) =>
                ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
              }
              min={0}
              value={inventoryData?.quantity}
            />
          </div>

          <div className="kyc-fields-container col-12  col-md-6">
            <label htmlFor="unitPrice" className="kyc-label-text form-label">
              Price/Unit
            </label>
            <input
              required
              onChange={onChangeInputUpload}
              onKeyDown={(evt) =>
                ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
              }
              id="unitPrice"
              type="number"
              name="unitPrice"
              className="kyc-input-field form-control"
              value={inventoryData?.unitPrice}
            />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end m-2">
        <button type="submit" className="btn btn-primary">
          {inventoryAddTableData.isLoading ? (
            <span className="loader mx-auto"></span>
          ) : (
            "Submit"
          )}
        </button>
      </div>
    </form>
  );
};

/* PARENT COMPONENT */
const AnchorInventory = () => {
  const dispatch = useDispatch();
  /* USESELECT HOOKS */
  const inventoryTableData = useSelector(
    (state) => state.anchor.inventoryTableData
  );

  const inventoryFileData = useSelector(
    (state) => state.anchor.inventoryFileData
  );

  console.log("inventoryTableData", inventoryTableData);

  /* USESTATES */
  const [invoiceTableRows, setInvoiceTableRows] = useState([]);
  const [inventoryUploadFile, setInventoryUploadFile] = useState("");
  //
  const [showCreateManualInvoiceModal, setShowCreateManualInvoiceModal] =
    useState(false);

  /* TABLE QUERY PARAMS */
  const tableQueryParams = {
    tableOffset: 0,
    tableLimit: 5,
    tableSort: "invoiceDate",
    tableOrder: -1,
    currentPage: 1,
  };

  useEffect(() => {
    dispatch(getInventoryTable())
      .unwrap()
      .then((response) => {
        setInvoiceTableRows(response.items);
      })
      .catch((error) => {
        console.log("error : ", error);
        toastMessage(error.message, "error");
      });
  }, [dispatch]);

  /* EVENT LISTENERS */
  const toggleCreateManualInvoiceModal = () => {
    setShowCreateManualInvoiceModal((prev) => !prev);
  };

  const onRefreshTable = () => {
    dispatch(
      getDigitalInvoiceTable({
        tableQueryParams,
      })
    );
  };

  const onChangeInventoryFileUpload = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const file = event.target.files[0];
    setInventoryUploadFile((prev) => ({ ...prev, [name]: { file, value } }));
  };

  const onClickFileUpload = () => {
    const body = new FormData();
    body.append("file", inventoryUploadFile);
    console.log("body : ", body);
    for (let keys in inventoryUploadFile) {
      console.log(keys);
    }

    dispatch(addInventoryFileDetails(body))
      .unwrap()
      .then((response) => {
        console.log("response : ", response);
        toastMessage(response.success, "success");
      })
      .catch((error) => {
        console.log("error : ", error);
        toastMessage(error.message, "error");
      });
  };

  return (
    <>
      {showCreateManualInvoiceModal && (
        <ReusableModal
          showModal={showCreateManualInvoiceModal}
          toggleModal={toggleCreateManualInvoiceModal}
          modalContent={
            <CreateManualInvoiceModal
              // activeBOEData={activeBOEData}
              toggleModal={toggleCreateManualInvoiceModal}
              onRefreshTable={onRefreshTable}
            />
          }
          modalSizeClass="modal-lg"
        />
      )}

      <div className="d-flex flex-column gap-4" style={{ overflowY: "auto" }}>
        <Helmet>
          <title>Inventory</title>
        </Helmet>
        <div className="bg-white admin-dashboard-card-container p-3">
          <div className="d-flex justify-content-between align-items-center flex-wrap px-2 gap-2 py-2">
            <span className="dashboard-content-title">Inventory</span>
            <div
              onClick={toggleCreateManualInvoiceModal}
              className="d-flex align-items-center"
              type="button"
            >
              <button
                className="btn mx-3"
                style={{
                  border: "1px solid #6378d4",
                  borderRadius: "10px",
                }}
              >
                {" "}
                Add Item &nbsp;
                <BsPlusCircleFill className="rm-light-plus-button" />{" "}
              </button>
            </div>
          </div>

          <div className="d-flex flex-column flex-sm-row align-items-center mt-2 mb-1">
            <div className="d-flex align-items-end justify-content-start gap-3 col-12 col-sm-6">
              <div>
                <label
                  htmlFor="selectAnchor"
                  className="anchor-and-lender-select-label"
                >
                  Upload Inventory list (.csv, .xls, .xlsx)
                </label>
                <input
                  accept=".csv, .xls, .xlsx"
                  className="d-flex flex-grow-1 form-control"
                  type="file"
                  placeholder=""
                  onChange={onChangeInventoryFileUpload}
                  value={inventoryUploadFile}
                />
              </div>
              <div>
                <button
                  className="btn btn-primary px-4"
                  onClick={onClickFileUpload}
                >
                  {inventoryFileData.isLoading ? (
                    <span className="loader mx-auto"></span>
                  ) : (
                    "Upload"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex flex-column">
          <div>
            {inventoryTableData.isLoading ? (
              <Loader className="my-5" />
            ) : (
              <AnchorInventoryTable
                //   onRefreshTable={onRefreshTable}
                className="admin-channel-partner-data-table"
                tableHeader={[
                  "Product Name",
                  "Model Number",
                  "Product Description",
                  "HSN Code",
                  "Available Quantity",
                  "Price/Unit",
                  "Action",
                ]}
                tableRows={invoiceTableRows}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AnchorInventory;
