import { Navigate, Outlet } from "react-router-dom";

const CorporatePrivateRoute = () => {
  const token = sessionStorage.getItem("token");
  const role = sessionStorage.getItem("role");
  const isAuthenticated =
    token === null || token === undefined || role !== "corporate";

  return isAuthenticated ? <Navigate to="/login" replace /> : <Outlet />;
};

export default CorporatePrivateRoute;
