import { Fragment, useCallback, useContext, useEffect, useState, } from "react";
import { GenericInputField } from "../../../../GenericComponent/Input/Input";
import { BanksNames } from "../../../../utilities/banks";
import { GenericSelect } from "../../../../GenericComponent/Select/Select";
import { GenericFileInput } from "../../../../GenericComponent/FileInput/FileInput";
import { AnchorKycContext } from "../anchorKycContext";
import { actions } from "./bankDetailsReducer";

const banksList = function () {
	/**
	 * @type { { label: string, value: string, id?: string, disabled?: boolean }[] }
	 */
	const bankList = [
		{ label: "Select Bank", value: "", id: "0", disabled: true },
	];

	for (let i = 0; i < BanksNames.length; i++) {
		bankList.push({
			label: BanksNames[i],
			value: banksList[i],
			id: (i + 1).toString(),
		});
	}

	return bankList;
};

/**
 * @type { { label: string, value: string, id?: string, disabled?: boolean }[] }
 */
const accountTypeList = [
	{ label: "Select Account", value: "", disabled: true, id: "0", },
	{ label: "Current Account", value: "Current Account", id: "1" },
	{ label: "Cash Credit (CC)/Overdraft (OD)", value: "Cash Credit (CC)/Overdraft (OD)", id: "2" },
	{ label: "SavingsAccount", value: "SavingsAccount", id: "3" },
];

export const AnchorKYCBankDetails = (props) => {

	const [ bankStatements, setBankStatements ] = useState([]);

	const [ dataFilled, setDataFilled ] = useState(false);

	const banksListCallback = useCallback(() => {
		return banksList();
	}, [ ]);

	const { files, bankDetailsDispatch, bankDetails, uploadFiles, disableEdit } = useContext(AnchorKycContext);

	useEffect(() => {

		if (files?.length > 0 && files?.[0]?.documents && Array.isArray(files[0].documents)) {
			
			const bankStatementsTemp = [];
			for (let item of files[0].documents) {
				if (item?.bankstatement !== undefined) {
					bankStatementsTemp.push(item);
				}
			}

			if (bankStatementsTemp.length > 0) {
				bankDetailsDispatch({ type: actions.BANK_STATEMENT_UPDATE_API, payload: bankStatementsTemp });
			}

			setBankStatements(bankStatementsTemp);
		}
		
	}, [ bankDetailsDispatch, files ]);
	
	useEffect(() => {
		if (!dataFilled && files && Array.isArray(files) && files.length > 0) {
			bankDetailsDispatch({ type: actions.CHANGE_ACC_HOLDER_NAME, payload:files[0]?.accountHolderName? files[0]?.accountHolderName:""});
			bankDetailsDispatch({ type: actions.CHANGE_ACC_NO, payload:files[0]?.accountNumber? files[0]?.accountNumber:"" });
			bankDetailsDispatch({ type: actions.CHANGE_ACC_HOLDER_NAME, payload:files[0]?.accountHolderName });

			setDataFilled(true);
		}
	}, [files, dataFilled, bankDetailsDispatch]);

	/**
	 * @param { React.ChangeEvent<HTMLInputElement> } event 
	 */
	const handleBankStatementChange = (event) => {
		const formData = new FormData();

		formData.append("bankstatement", event?.target?.files?.[0]);

		uploadFiles(formData, "bankstatement");
	};

	return (
		<Fragment>
			<div className="container-with-heading d-flex flex-column container">
				<span className="kyc-content-title mb-3 row">Bank Details</span>
				<div className="details-container ">
					<div className="row gy-md-4 gy-2">

						<GenericInputField
							label="Account Number" required={ true } type="text" value={ bankDetails.accountNumber.value } isInvalid={ bankDetails.accountNumber.isTouched && !bankDetails.accountNumber.isValid }
							errorMessage={ bankDetails.accountNumber.value !== "" && "Invalid Account Number" } onChangeHandler={ event => bankDetailsDispatch({ type: actions.CHANGE_ACC_NO, payload: event.target.value.toUpperCase() }) }
							onBlurHandler={ () => bankDetailsDispatch({ type: actions.BLUR_ACC_NO }) } onKeyDown={ evt => [ "+", "-"].includes(evt.key) && evt.preventDefault()}
							disabled={disableEdit}
						/>

						<GenericInputField
							label="Account Holder Name" required={ true } value={ bankDetails.accountHolderName.value } isInvalid={ bankDetails.accountHolderName.isTouched && !bankDetails.accountHolderName.isValid }
							errorMessage={ bankDetails.accountHolderName.value < 3 ? "Name should contain at least 3 chars" : "Invalid Name" } onChangeHandler={ event => bankDetailsDispatch({ type: actions.CHANGE_ACC_HOLDER_NAME, payload: event.target.value }) }
							onBlurHandler={ () => bankDetailsDispatch({ type: actions.BLUR_ACC_HOLDER_NAME }) }
							disabled={disableEdit}
						/>

						<GenericSelect
							optionList={ banksListCallback() } required={ true } label="Bank Name" value={ bankDetails.bankName.value } isInvalid={ bankDetails.bankName.isTouched && !bankDetails.bankName.isValid }
							errorMessage="Bank Name is Required" onChangeHandler={ event => bankDetailsDispatch({ type: actions.CHANGE_BANK_NAME, payload: event.target.value }) }
							onBlurHandler={ () => bankDetailsDispatch({ type: actions.BLUR_BANK_NAME }) }
							disabled={disableEdit}
						/>

						<GenericSelect
							optionList={ accountTypeList } required={ true } label="Account Type" value={ bankDetails.accountType.value } isInvalid={ bankDetails.accountType.isTouched && !bankDetails.accountType.isValid }
							errorMessage="Account Type is Required" onChangeHandler={ event => bankDetailsDispatch({ type: actions.CHANGE_ACC_TYPE, payload: event.target.value }) }
							onBlurHandler={ () => bankDetailsDispatch({ type: actions.BLUR_ACC_TYPE }) }
							disabled={disableEdit}
						/>

						<GenericInputField
							label="IFSC Code" required={ true } value={ bankDetails.IFSCCode.value } isInvalid={ bankDetails.IFSCCode.isTouched && !bankDetails.IFSCCode.isValid }
							errorMessage={ bankDetails.IFSCCode.value === "" ? "IFSC Code is Required" : "Invalid IFSC Code" } onChangeHandler={ event => bankDetailsDispatch({ type: actions.CHANGE_IFSC_CODE, payload: event.target.value.toUpperCase() })}
							onBlurHandler={ () => bankDetailsDispatch({ type: actions.BLUR_IFSC_CODE })}
							disabled={disableEdit}
						/>

						<GenericFileInput
							label="Upload Bank Statement (6 months) (ONLY PDF)"	required={ true }
							fileList={ bankStatements } fileType="bankstatement" accept="application/pdf"
							onChangeHandler={ handleBankStatementChange } isVerified={ bankDetails.bankStatement.isVerified }
							isInvalid={ bankDetails.bankStatement.isTouched && bankStatements?.length === 0 }
							disabled={disableEdit}
						/>
						
					</div>
				</div>
			</div>
		</Fragment>
	);
};