import {
  Fragment,
  createContext,
  useReducer,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { PersonalDetailsReducer } from "./personalDetails/personalDetailsReducer";
import { useDispatch, } from "react-redux";
import { toastMessage } from "../../../layout/GenericLayout/ToastMessage";
import {
  getUserKYCDetails,
  getUserKYCDocument,
  updateAadharOCRDetails,
  updateDeleteFile,
  updatePANVerification,
  updateUploadKYCDocuments,
  updateUploadProfilePicture,
} from "../../../store/slices/generic";
import { useLocation } from "react-router-dom";
import { DocumentDetailsReducer } from "./documentDetails/documentDetailsReducer";
import { useNavigate } from "react-router-dom";
import { gstVerification, } from "../../../store/slices/anchor";
import { ChannelPartnerKycActions } from "./actionTypes";
import { updateChannelKyc } from "../../../store/slices/channel";

function generateTemplate() {
  return {
    value: "",
    isTouched: false,
    isValid: false,
  };
}

function generateTemplateForFile() {
  return {
    fileList: [],
    isTouched: false,
    isVerified: false,
  };
}

const personalDetailsTemplate = {
  profilePic: { ...generateTemplateForFile(), isVerified: false },
  name: generateTemplate(),
  mobileNumber: generateTemplate(),
  panNo: { ...generateTemplate(), isVerified: false },
  aadhaarImage: {
    ...generateTemplateForFile(),
    isVerified: false,
    verificationStatus: null,
  },
  companyType: generateTemplate(),
  gstNumber: { ...generateTemplate(), isVerified: false },
  address: generateTemplate(),
  city: generateTemplate(),
  state: generateTemplate(),
  pinCode: generateTemplate(),
  addressProof: { ...generateTemplateForFile(), isVerified: false },
  bureauReport: { ...generateTemplateForFile(), isVerified: false },
  businessProfile: { ...generateTemplateForFile(), isVerified: false },
};

const documentDetailsTemplate = {
  documents: [
    {
      documentName: generateTemplate(),
      bankStatement: generateTemplateForFile(),
    },
  ],
};

export const ChannelPartnerKYCFormTemplate = {
  personalDetails: personalDetailsTemplate,
  documentDetails: documentDetailsTemplate,
  personalDetailsDispatch: ({ type, payload }) => { },
  documentDetailsDispatch: ({ type, payload }) => { },
  handleSubmitForm: () => { },
  files: [
    {
      email: "",
      documents: [
        {
          imageFileFormat: "image/jpeg",
          name: "",
          modifiedDate: "2023-07-12 20:23:06.476000",
          coAadharImage:
            "https://atoncap.s3.ap-south-1.amazonaws.com/monherry9+wxobz@gmail.com/coAadharImage/2023-07-12 20:23:06.374819-2023-05-15 13_01_27.395279-adhar.jpg",
        },
      ],
    },
  ],
  deleteFiles: (key, url) => { },
  emailId: "",
  uploadFiles: (formData, type) => { },
};

export const ChannelPartnerKycContext = createContext(ChannelPartnerKYCFormTemplate);

/**
 * @param {{ children: React.ReactNode }} props
 */
export const ChannelPartnerKycContextWrapper = (props) => {
  /**
   * @type { [ typeof ChannelPartnerKYCFormTemplate['personalDetails'], React.DispatchWithoutAction ] }
   */
  const [personalDetails, personalDetailsDispatch] = useReducer(
    PersonalDetailsReducer,
    ChannelPartnerKYCFormTemplate.personalDetails
  );

  const [documentDetails, documentDetailsDispatch] = useReducer(
    DocumentDetailsReducer,
    ChannelPartnerKYCFormTemplate.documentDetails
  );

  const [files, setFiles] = useState([]);

  const dispatch = useDispatch();

  const { state } = useLocation();

  const navigate = useNavigate();

  const emailId = useMemo(() => {
    if (state?.loginData?.items[0].user.emailId) {
      return state.loginData.items[0].user.emailId;
    }
    toastMessage("session Expired", "error");

    setTimeout(() => navigate("/login", { replace: true }), 2000);
  }, [state, navigate]);

  const roles = useMemo(() => {
    if (state?.loginData?.items[0].user.roles) {
      return state.loginData.items[0].user.roles;
    }
    toastMessage("session Expired", "error");

    setTimeout(() => navigate("/login", { replace: true }), 2000);
  }, [state, navigate]);

  const fetchDocuments = useCallback(() => {
    dispatch(getUserKYCDocument())
      .unwrap()
      .then((response) => {
        setFiles(response?.items);
      })
      .catch((error) => {
        if (error.message !== "kyc is not updated for this login") {
          toastMessage(error.message ?? "Unknown Error Occurred", "error");
        }
      });
  }, [dispatch]);


  useEffect(() => {

    const updatePersonalDetails = ({
      name, PANNumber, mobileNumber, aadhaarValidationStatus, aadharSDK, address, state, city,
      companyType, pinCode, GSTNumber
    }) => {
      console.log("gst", GSTNumber);
      
      const { PERSONAL_DETAILS } = ChannelPartnerKycActions;

      personalDetailsDispatch({ type: PERSONAL_DETAILS.CHANGE_NAME, payload: name });
      personalDetailsDispatch({ type: PERSONAL_DETAILS.CHANGE_MOBILE, payload: mobileNumber });

      personalDetailsDispatch({ type: PERSONAL_DETAILS.CHANGE_ADDRESS, payload: address });
      personalDetailsDispatch({ type: PERSONAL_DETAILS.CHANGE_STATE, payload: state });
      personalDetailsDispatch({ type: PERSONAL_DETAILS.CHANGE_CITY, payload: city });
      personalDetailsDispatch({ type: PERSONAL_DETAILS.CHANGE_COMPANY_TYPE, payload: companyType });
      personalDetailsDispatch({ type: PERSONAL_DETAILS.CHANGE_PIN, payload: pinCode });
      personalDetailsDispatch({ type: PERSONAL_DETAILS.CHANGE_GST, payload: GSTNumber });

      dispatch(gstVerification(GSTNumber))
        .unwrap()
        .then(response => {
          if (response?.success) {
            personalDetailsDispatch({ type: PERSONAL_DETAILS.BLUR_GST, payload: true });
          }
          else {
            personalDetailsDispatch({ type: PERSONAL_DETAILS.BLUR_GST, payload: false });
          }
        })
        .catch(error => {
          personalDetailsDispatch({ type: PERSONAL_DETAILS.BLUR_GST, payload: false });
        });

      if (PANNumber !== undefined) {
        personalDetailsDispatch({ type: PERSONAL_DETAILS.CHANGE_PAN, payload: PANNumber });

        const body = { panNumber: PANNumber, userid: emailId, };

        dispatch(updatePANVerification(body))
          .unwrap()
          .then(response => {
            if (response?.status) {
              personalDetailsDispatch({ type: PERSONAL_DETAILS.BLUR_PAN, payload: true });
            }
          })
          .catch(error => {
            personalDetailsDispatch({ type: PERSONAL_DETAILS.BLUR_PAN, payload: true });
          })
      }

      if (aadharSDK.name && aadharSDK.dateOfBirth && aadharSDK.gender) {
        personalDetailsDispatch({ type: PERSONAL_DETAILS.CHANGE_AADHAAR, payload: { status: aadhaarValidationStatus, response: aadharSDK, }, });
      }

    };

    dispatch(getUserKYCDetails())
      .unwrap()
      .then(response => {

        console.log("kyc", response?.items?.[0]);

        if (response?.items?.length > 0) {
          updatePersonalDetails(response?.items?.[response?.items?.length - 1]);
        }

      })
      .catch(error => {
        console.log("kyc response", error);
      });
  }, [ dispatch, emailId ]);

  useEffect(() => {
    fetchDocuments();
  }, [fetchDocuments]);

  const handleSubmitForm = () => {
    const states = [personalDetails, documentDetails,];

    //! TRIGGER FOCUS EVENT ON THE FIELDS WHICH ARE NOT FILLED
    personalDetailsDispatch({ type: "TOUCH_ALL" });
    documentDetailsDispatch({ type: "TOUCH_ALL" });

    for (let state of states) {
      for (let obj in state) {
        for (let item in state[obj]) {
          if (
            (item === "isVerified" || item === "isValid") &&
            state[obj][item] === false
          ) {
            console.log(state, obj, state[obj]);
            return;
          }
        }
      }
    }

    if (personalDetails.aadhaarImage.verificationStatus === null) {
      toastMessage("Upload Aadhaar In Personal Details", "error");
      return;
    }

    const data = {
      name: personalDetails.name.value ?? "",
      emailId: emailId ?? "",
      mobileNumber: personalDetails.mobileNumber.value ?? "",
      panValidationStatus: personalDetails.panNo.isVerified ?? "",
      PANNumber: personalDetails.panNo.value.toUpperCase() ?? "",
      profilePicture:
        personalDetails.profilePic.fileList?.[personalDetails.profilePic.fileList?.length]?.userProfilePic ?? null, // url
      aadhaarValidationStatus: personalDetails.aadhaarImage.isVerified ?? false,
      aadharSDK: {
        dateOfBirth:
          personalDetails.aadhaarImage.verificationStatus?.response?.dob ??
          null,
        name:
          personalDetails.aadhaarImage.verificationStatus?.response?.name ??
          null,
        gender:
          personalDetails.aadhaarImage.verificationStatus?.response?.gender ??
          null,
      },
      GSTNumber: personalDetails.gstNumber.value ?? "",
      GSTValidationStatus: personalDetails.gstNumber.isVerified ?? false,
      roles: roles ?? [],
      address: personalDetails.address.value ?? "",
      city: personalDetails.city.value ?? "",
      companyType: personalDetails.companyType.value ?? "",
      pinCode: parseInt(personalDetails.pinCode.value) ?? "",
      state: personalDetails.state.value ?? "",
    };

    dispatch(updateChannelKyc(data))
      .unwrap()
      .then((response) => {
        toastMessage(
          response?.success ?? "KYC Uploaded Successfully",
          "success"
        );

        setTimeout(() => window.location.pathname = "/login", 3200);
      })
      .catch((error) => {
        toastMessage(error?.message ?? "Unable to Upload KYC", "error");
      });
  };

  const deleteFiles = (key, url) => {
    const body = { email_id: emailId, image_url: url, key };

    dispatch(updateDeleteFile(body))
      .unwrap()
      .then((response) => {
        toastMessage("Document deleted Successfully", "success");
        fetchDocuments();
      })
      .catch((error) => {
        toastMessage(error?.message ?? "Unable to delete file", "error");
      });
  };

  const uploadFiles = (formData, type) => {
    formData.append("emailId", emailId);
    formData.append("roles", roles);

    console.log(formData);

    if (type === "userProfilePic") {
      dispatch(updateUploadProfilePicture(formData))
        .unwrap()
        .then((response) => {
          fetchDocuments();
        })
        .catch((error) => {
          toastMessage(
            error?.message ?? error?.error ?? "Unable to Upload Profile Pic",
            "error"
          );
        });

      return;
    } else if (type === "adharImage") {
      dispatch(updateAadharOCRDetails(formData))
        .unwrap()
        .then((validationResponse) => {
          if (validationResponse.status) {
            if (type === "adharImage") {
              personalDetailsDispatch({
                type: ChannelPartnerKycActions.PERSONAL_DETAILS.CHANGE_AADHAAR,
                payload: {
                  status: validationResponse.status,
                  response: validationResponse?.data?.result?.[0]?.details,
                },
              });
            }

            dispatch(updateUploadKYCDocuments(formData))
              .unwrap()
              .then((response) => {
                fetchDocuments();
              })
              .catch((error) => {
                console.log("error while uploading Aadhaar", error);
              });
          }
        })
        .catch((error) => {
          toastMessage(
            error?.message ?? error?.error ?? "Unable to verify Aadhaar",
            "error"
          );
        });
      return;
    }

    dispatch(updateUploadKYCDocuments(formData))
      .unwrap()
      .then((response) => {
        fetchDocuments();
      })
      .catch((error) => {
        toastMessage("Unable to upload File", "error");
      });
  };

  const value = {
    personalDetails,
    personalDetailsDispatch,
    documentDetails,
    documentDetailsDispatch,
    files,
    deleteFiles,
    emailId,
    uploadFiles,
    handleSubmitForm: handleSubmitForm,
  };

  return (
    <Fragment>
      <ChannelPartnerKycContext.Provider value={value}>
        {props?.children ?? null}
      </ChannelPartnerKycContext.Provider>
    </Fragment>
  );
};
