import { BsSearch } from "react-icons/bs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../layout/GenericLayout/Loader";
import CorporateCard from "./CorporateCard";
import {Helmet} from "react-helmet";
import {

  getTotalRejectedUsers, 
  getTotalApprovedUsers,
  getTotalOutstandingInvoices,
  getTotalNotDiscountedInvoices,
  getTotalPendingUsers,
  getTotalOnboardingUsers,

} from "../../store/slices/corporate";

const DashBoardIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25.166"
      height="28.907"
      viewBox="0 0 25.166 28.907"
    >
      <g
        id="noun-files-3972654"
        transform="matrix(-1, -0.017, 0.017, -1, 97.085, 60.505)"
      >
        <path
          id="Path_517"
          data-name="Path 517"
          d="M153.217,32.852v20.88a1.75,1.75,0,0,1-1.75,1.74H140.092a.87.87,0,0,1-.618-.256l-7-6.96a.87.87,0,0,1-.257-.614V32.851a1.75,1.75,0,0,1,1.75-1.74h17.5a1.75,1.75,0,0,1,1.75,1.74Z"
          transform="translate(-59.332 -0.78)"
          fill="#2e3c83"
        />
        <path
          className="sidebar-icons"
          id="Path_518"
          data-name="Path 518"
          d="M243.86,124.45v21.96h-18.3v1.83h18.3a1.835,1.835,0,0,0,1.83-1.83V124.45Z"
          transform="translate(-147.979 -89.431)"
          fill="#2e3c83"
        />
      </g>
    </svg>
  );
};

/* PARENT COMPONENT */
const CorporateDashboard = () => {
  /* FUNCTION DECLARATIONS */
  const dispatch = useDispatch();

  /* USESELECTOR */
  const totalRejectedUsers = useSelector((state)=> state.corporate.totalRejectedUsersData);
  const totalApprovedUsers = useSelector((state)=> state.corporate.totalApprovedUsersData);
  const totalOutstandingInvoices = useSelector((state)=> state.corporate.totalOutstandingInvoicesData);
  const totalNotDiscountedInvoices = useSelector((state)=> state.corporate.totalNotDiscountedInvoicesData);
  const totalPendingUsers = useSelector((state)=> state.corporate.totalPendingUsersData);
  const totalOnboardingUsers = useSelector((state)=> state.corporate.totalOnboardingUsersData);



  /**Dispatchs */
  useEffect(()=>{

  dispatch(getTotalRejectedUsers()); 
  dispatch(getTotalApprovedUsers());
  dispatch(getTotalOutstandingInvoices());
  dispatch(getTotalNotDiscountedInvoices());
  dispatch(getTotalPendingUsers());
  dispatch(getTotalOnboardingUsers());
    
  }, [])


  /* USESTATES */
  // const [search, setSearch] = useState("");

  /* USEEFFECTS */

  /* EVENT LISTENERS */
  // const onChangeSearch = (event) => {
  //   setSearch(event.target.value);
  // };

  console.log("totalRejectUsersData", totalOnboardingUsers?.data);

  return (
    <div className="d-flex flex-column gap-4">
      <Helmet>
                <title>Dashboard</title>
            </Helmet>
      <div className="bg-white admin-dashboard-card-container px-3 pt-3">
        <div className="row">
          {/* Total Onboading */}
          <div className="col-12 col-md-6 col-xl-4 mb-3">
            <CorporateCard
              title="Total Onboarded"
              count={totalOnboardingUsers?.data?.success}
              // status="200+ lastweek"
              icon={<DashBoardIcon />}
              value=""
            />
          </div>

          {/* Total Approved */}
          <div className="col-12 col-md-6 col-xl-4 mb-3">
            <CorporateCard
              title="Total Approved"
              count={totalApprovedUsers?.data?.success}
              // status="200+ lastweek"
              icon={<DashBoardIcon />}
              value=""
            />
          </div>

          {/**Total Rejected */}
          <div className="col-12 col-md-6 col-xl-4 mb-3">
            <CorporateCard
              title="Total Rejected"
              count={totalRejectedUsers?.data?.success}
              // status="200+ lastweek"
              icon={<DashBoardIcon />}
              value=""
            />
          </div>

          {/**Total OutStanding Invoices */}
          <div className="col-12 col-md-6 col-xl-4 mb-3">
            <CorporateCard
              title="Total OutStanding Invoices"
              count={totalOutstandingInvoices?.data?.count}
              // status="200+ lastweek"
              icon={<DashBoardIcon />}
              value=""
            />
          </div>

          {/**Total Hot Discounted Invoices */}
          <div className="col-12 col-md-6 col-xl-4 mb-3">
            <CorporateCard
              title="Total Not Discounted Invoices"
              count={totalNotDiscountedInvoices?.data?.count}
              // status="200+ lastweek"
              icon={<DashBoardIcon />}
              value=""
            />
          </div>

          {/**Total Pending Users*/}
          <div className="col-12 col-md-6 col-xl-4 mb-3">
            <CorporateCard
              title="Total Pending Users"
              count={totalPendingUsers?.data?.success}
              // status="200+ lastweek"
              icon={<DashBoardIcon />}
              value=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CorporateDashboard;
