import React, { useEffect, useState } from "react";
import { BsPlusCircleFill, BsSearch } from "react-icons/bs";
import BuyerMaterialRequestTable from "../BuyerTables/BuyerMaterialRequestTable";
import Loader from "../../../layout/GenericLayout/Loader";
import { useDispatch, useSelector } from "react-redux";
import { getMaterialRequestTable } from "../../../store/slices/buyer";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const BuyerMaterialRequest = () => {
  const dispatch = useDispatch();
  /* USESELECT HOOKS */
  const materialRequestTableData = useSelector(
    (state) => state.buyer.materialRequestTableData
  );
  const [materialRequestTableRows, setMaterialRequestTableRows] = useState([]);
  const [materialRequestMetaData, setMaterialRequestMetaData] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [searchByMaterialRequestID, setSearchByMaterialRequestID] =
    useState("");

  /* TABLE QUERY PARAMS */
  const [tableQueryParams, setTableQueryParams] = useState({
    tableOffset: 0,
    tableLimit: 5,
    tableSort: "mrId",
    tableOrder: -1,
    currentPage: 1,
  });

  useEffect(() => {
    let tableQuery = `?offset=${tableQueryParams.tableOffset}&limit=${tableQueryParams?.tableLimit}`;
    // **For sort order needs to be there
    if (tableQueryParams?.tableSort.length > 1) {
      // console.log(tableQueryParams.tableSort)
      tableQuery = `?offset=${tableQueryParams.tableOffset}&limit=${tableQueryParams?.tableLimit}&sort=${tableQueryParams.tableSort}&order=${tableQueryParams?.tableOrder}`;
    }
    if (searchByMaterialRequestID.length > 1) {
      //&search=mrID
      tableQuery = `?offset=${tableQueryParams.tableOffset}&limit=${tableQueryParams?.tableLimit}&sort=${tableQueryParams.tableSort}&order=${tableQueryParams?.tableOrder}&search=${searchByMaterialRequestID}`;
    }
    dispatch(
      getMaterialRequestTable({
        tableQuery,
      })
    )
      .unwrap()
      .then((response) => {
        setMaterialRequestTableRows(response.data.items);
        setMaterialRequestMetaData(response.data.metadata);
      })
      .catch((error) => {});
  }, [dispatch, tableQueryParams, searchByMaterialRequestID]);

  useEffect(() => {
    if (materialRequestTableData.data) {
      setMaterialRequestTableRows(materialRequestTableData.data.items);
      setMaterialRequestMetaData(materialRequestTableData.data.metadata);
    }
  }, [materialRequestTableData.data]);

  //console.log(materialRequestTableData);

  const onChangeSearchByMaterialRequestID = (event) => {
    setSearchByMaterialRequestID(event.target.value);
  };

  // console.log(searchByMaterialRequestID);

  return (
    <div className="p-0" style={{ overflowX: "hidden" }}>
      <Helmet>
        <title>Material-Request</title>
      </Helmet>

      {/* Above table Component */}
      <div className="bg-white admin-dashboard-card-container p-3">
        {/* This is The Heading and Add Btn */}
        <div className="d-flex justify-content-between align-items-center flex-wrap px-2 gap-2 py-2">
          <span className="dashboard-content-title">
            List of Material Request
          </span>
          <div
            // onClick={toggleRMAddModal}
            className="d-flex align-items-center"
            type="button"
          >
            <div
              className={`rm-add-button ${isHovered ? "show" : ""}`}
              // onMouseEnter={handleMouseEnter}
              // onMouseLeave={handleMouseLeave}
            >
              ADD
            </div>
            <Link to={"add"}>
              <button
                className="btn mx-3"
                style={{
                  // background:'#fff',
                  border: "1px solid #6378d4",
                  borderRadius: "10px",
                }}
                id="add_material_request"
              >
                {" "}
                Add Material Request &nbsp;
                <BsPlusCircleFill className="rm-light-plus-button" />{" "}
              </button>
              {/* <BsPlusCircleFill
              className="rm-plus-button"
            /> */}
            </Link>
          </div>
        </div>

        {/* This is the Material Request Search Bar */}
        <div className="d-flex flex-column flex-sm-row align-items-center my-4">
          <div className="d-flex flex-column justify-content-center gap-3 col-12 col-sm-6">
            {/* <label
              htmlFor="selectAnchor"
              className="anchor-and-lender-select-label"
            >
            </label> */}

            <div className="search-container d-flex align-items-center py-1 px-3 col-12 col-sm-8">
              <input
                className="search-input-element d-flex flex-grow-1"
                type="text"
                placeholder="Search with Material Request ID"
                onChange={onChangeSearchByMaterialRequestID}
                value={searchByMaterialRequestID}
              />{" "}
              <BsSearch className="fs-5" />
            </div>
          </div>
        </div>
      </div>

      {/* The Table container starts here */}
      <div
        className="mb-5 mt-3"
        style={{
          overflow: "hidden",
          boxSizing: "border-box",
        }}
      >
        <BuyerMaterialRequestTable
          className="buyer-table placeholder-wave"
          tableHeader={[
            { name: "Material Request ID", sortValue: "mrId" },
            { name: "Anchor", sortValue: "sellerCompanyFullName" },
            { name: "Items Count", sortValue: "" },
            { name: "Quantity Requested", sortValue: "" },
            { name: "Modified Date", sortValue: "modifiedDate" },
            { name: "Status", sortValue: "" },
          ]}
          tableRows={materialRequestTableRows}
          materialRequestMetaData={materialRequestMetaData}
          tableQueryParams={tableQueryParams}
          setTableQueryParams={setTableQueryParams}
          isLoading={materialRequestTableData.isLoading}
        />
      </div>
    </div>
  );
};

export default BuyerMaterialRequest;
