import { Fragment, createContext, useMemo, useReducer, useState, } from "react";
import { useSelector } from "react-redux";
import { additionalDetailsReducer, applicantDetailsReducer, companyPromoterReducer, dealershipStockReducer, debtorReducer, directorAndPartnerReducer, groupExposureReducer, securityReducer, totalWorkingCapitalReducer, transactionDetailsReducer,  } from "./adminCamFormReducers";
import { additionalDetailsStateType, applicantDetailsStateType, bureauScoreType, dealershipStockStateType, debtorsStateType, deviationAndApprovalStateType, directorAndPartnerDetailsType, groupExposureStateType, recommendedByStateType, totalWorkingCapitalType, transactionDetailsStateType } from "./stateTypes";

export const AdminCamContext = createContext({
	editEnabled: false,
	handleToggleEdit: () => {},
	additionalDetailsState: additionalDetailsStateType,
	transactionDetailsState: transactionDetailsStateType,
	securityState: "",
	groupExposureState: groupExposureStateType,
	applicantDetailsState: applicantDetailsStateType,
	directorsAndPartnersDetails: directorAndPartnerDetailsType,
	companyPromotersState: "",
	dealershipStockState: dealershipStockStateType,
	debtorsState: debtorsStateType,
	totalWorkingCapitalState: totalWorkingCapitalType,
	bureauScoreState: bureauScoreType,
	deviationAndApprovalState: deviationAndApprovalStateType,
	gstAnalysisState: [],
	bankAnalysisState: [],
	financialStatement: { itr: [], mca: [] },
	recommendedByState: recommendedByStateType,
	additionalDetailsDispatch: ({ type, payload }) => {},
	transactionDetailsDispatch: ({ type, payload }) => {},
	securityStateDispatch: ({ type, payload }) => {},
	groupExposureStateDispatch: ({ type, payload }) => {},
	applicantDetailsStateDispatch: ({ type, payload }) => {},
	directorsAndPartnersDispatch: ({ type, payload }) => {},
	companyPromotersDispatch: ({ type, payload }) => {},
	dealershipStockDispatch: ({ type, payload }) => {},
	totalWorkingCapitalStateDispatch: ({ type, payload }) => {},
	bureauScoreStateDispatch: ({ type, payload }) => {},
	deviationAndApprovalStateDispatch: ({ type, payload }) => {},
	gstAnalysisStateDispatch: ({ type, payload }) => {},
	bankAnalysisStateDispatch: ({ type, payload }) => {},
	financialStatementDispatch : ({ type, payload }) => {},
	recommendedByStateDispatch : ({ type, payload }) => {},
});

/**
 * 
 * @param {{ children: React.ReactNode }} props 
 */
export const AdminCamContextWrapper = (props) => {
	const [ editEnabled, toggleEditEnabled ] = useState(false);

	const camDetails = useSelector(state => state?.admin?.camByEmailIdData?.data?.data); 
	
	const [ additionalDetailsState, additionalDetailsDispatch ] = useReducer(additionalDetailsReducer, camDetails?.otherInfo)
	const [ transactionDetailsState, transactionDetailsDispatch ] = useReducer(transactionDetailsReducer, camDetails?.camDetails?.transactionDetails);
	const [ securityState, securityStateDispatch ] = useReducer(securityReducer, camDetails?.camDetails?.security);
	const [ groupExposureState, groupExposureStateDispatch ] = useReducer(groupExposureReducer, camDetails?.camDetails?.groupExposure);
	const [ applicantDetailsState, applicantDetailsStateDispatch ] = useReducer(applicantDetailsReducer, camDetails?.applicantDetails);
	const [ directorsAndPartnersState, directorsAndPartnersDispatch, ] = useReducer(directorAndPartnerReducer, camDetails?.directorPartnerDetails);
	const [ companyPromotersState, companyPromotersDispatch, ] = useReducer(companyPromoterReducer, camDetails?.camDetails?.companyPromoterDetails);
	const [ dealershipStockState, dealershipStockDispatch, ] = useReducer(dealershipStockReducer, camDetails?.camDetails?.stocks);

	const [ debtorsState, debtorsStateDispatch, ] = useReducer(debtorReducer, camDetails?.camDetails?.debtors);
	const [ totalWorkingCapitalState, totalWorkingCapitalStateDispatch, ] = useReducer(totalWorkingCapitalReducer, camDetails?.camDetails?.workingCapital);
	const [ bureauScoreState, bureauScoreStateDispatch, ] = useReducer(directorAndPartnerReducer, camDetails?.dedupe);
	const [ deviationAndApprovalState, deviationAndApprovalStateDispatch, ] = useReducer(directorAndPartnerReducer, camDetails?.deviationAndApproval);
	const [ gstAnalysisState, gstAnalysisStateDispatch, ] = useReducer(directorAndPartnerReducer, camDetails?.gst_data);
	const [ bankAnalysisState, bankAnalysisStateDispatch, ] = useReducer(directorAndPartnerReducer, camDetails?.bank_data);
	const [ financialStatement, financialStatementDispatch, ] = useReducer(directorAndPartnerReducer, { itr: camDetails?.itr_data, mca: camDetails?.mca_data, });
	const [ recommendedByState, recommendedByStateDispatch, ] = useReducer(directorAndPartnerReducer, camDetails?.comments?.recommendedBy);
	

	console.log("cam by email id", camDetails);
	
	const handleToggleEdit = () => {
		toggleEditEnabled(previousState => !previousState);
	};

	const value = useMemo(() => ({ 
		editEnabled, handleToggleEdit, additionalDetailsState, transactionDetailsState,
		additionalDetailsDispatch, transactionDetailsDispatch, securityState, securityStateDispatch, 
		groupExposureState, groupExposureStateDispatch, applicantDetailsState, applicantDetailsStateDispatch,
		directorsAndPartnersState, directorsAndPartnersDispatch, companyPromotersState, companyPromotersDispatch,
		dealershipStockState, dealershipStockDispatch, debtorsState, debtorsStateDispatch,
		totalWorkingCapitalState, totalWorkingCapitalStateDispatch, bureauScoreState, bureauScoreStateDispatch,
		deviationAndApprovalState, deviationAndApprovalStateDispatch, gstAnalysisState, gstAnalysisStateDispatch,
		bankAnalysisState, bankAnalysisStateDispatch, financialStatement, financialStatementDispatch,
		recommendedByState, recommendedByStateDispatch,
	}), [ 
		editEnabled, additionalDetailsState, transactionDetailsState, securityState, 
		groupExposureState, applicantDetailsState, directorsAndPartnersState, companyPromotersState,
		dealershipStockState, debtorsState, totalWorkingCapitalState, bureauScoreState, recommendedByState,
		deviationAndApprovalState, gstAnalysisState, bankAnalysisState, financialStatement,
	]);

	return (
		<Fragment>
			<AdminCamContext.Provider value={ value }>
				{ props.children }
			</AdminCamContext.Provider>
		</Fragment>
	);
};
