import { HiOutlineArrowNarrowDown } from "react-icons/hi";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ReusableModal from "../../../layout/GenericLayout/ReusableModal";
import { BsPencil } from "react-icons/bs";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { getIsEmailRegistered } from "../../../store/slices/generic";
import {
  deleteRelationshipManager,
  getAllRelationshipManager,
  updateRelationshipManager,
} from "../../../store/slices/admin";
import { toastMessage } from "../../../layout/GenericLayout/ToastMessage";
import Loader from "../../../layout/GenericLayout/Loader";
import { RxCrossCircled } from "react-icons/rx";
import {
  deleteInventoryDetails,
  editInventoryTable,
} from "../../../store/slices/anchor";

/* MODAL/CHILDREN COMPONENT */
const EditRMModal = ({ toggleModal, activeEditData, onRefreshTable }) => {
  /* FUNCTION DECLARATION */
  const dispatch = useDispatch();
  console.log("activeEditData : ", activeEditData);
  /* USESTATES */
  const [editRMDetailsFields, setAddRMDetailsFields] = useState({
    productName: "",
    modelNumber: "",
    description: "",
    HSN: "",
    quantity: "",
    unitPrice: "",
  });

  /* USESELECTORS */

  const relationshipManagerEditData = useSelector(
    (state) => state.admin.relationshipManagerEditData
  );

  /* USEEFFECTS */

  useEffect(() => {
    setAddRMDetailsFields((prev) => ({
      ...prev,
      productName: activeEditData.productName,
      modelNumber: activeEditData.modelNumber,
      description: activeEditData.description,
      HSN: activeEditData.HSN,
      quantity: activeEditData.quantity,
      unitPrice: activeEditData.unitPrice,
    }));
  }, [activeEditData]);

  /* EVENT LISTENERS */
  const onChangeAddRMDetailsFields = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setAddRMDetailsFields((prev) => ({ ...prev, [name]: value }));
  };

  const onBlurAddRMDetailsFields = (event) => {
    const name = event.target.name;
    const validity = event.target.checkValidity();

    if (name === "emailId" && validity) {
      dispatch(getIsEmailRegistered(editRMDetailsFields.emailId))
        .unwrap()
        .then((response) => {
          toastMessage(response.success, "success");
        })
        .catch((error) => {
          toastMessage(error.message, "error");
        });
    }
  };

  const onSubmitRMAddDetails = (event) => {
    event.preventDefault();
    const body = { ...editRMDetailsFields, _id: activeEditData._id };
    dispatch(editInventoryTable(body))
      .unwrap()
      .then((response) => {
        // onRefreshTable();
        toastMessage(response.success, "success");
        toggleModal();
      })
      .catch((error) => {
        toastMessage(error.message, "error");
      });
  };

  return (
    <>
      {relationshipManagerEditData.isLoading && (
        <div className="blur-loader-container">
          <Loader />
        </div>
      )}
      <form className="" onSubmit={onSubmitRMAddDetails}>
        <span className="fs-4 fw-semibold">Edit Inventory Details</span>
        <div className="details-container container mt-4">
          <div className="row gy-md-4 gy-2">
            <div className="kyc-fields-container col-12  col-md-6">
              <label
                htmlFor="productName"
                className="kyc-label-text form-label"
              >
                Product Name
              </label>
              <input
                required
                onChange={onChangeAddRMDetailsFields}
                id="productName"
                name="productName"
                type="text"
                className="kyc-input-field form-control"
                value={editRMDetailsFields.productName}
              />
            </div>

            <div className="kyc-fields-container col-12  col-md-6">
              <label
                htmlFor="modelNumber"
                className="kyc-label-text form-label"
              >
                Model Number
              </label>
              <input
                onBlur={onBlurAddRMDetailsFields}
                required
                onChange={onChangeAddRMDetailsFields}
                id="modelNumber"
                type="text"
                name="modelNumber"
                className="kyc-input-field form-control"
                value={editRMDetailsFields.modelNumber}
              />
            </div>

            <div className="kyc-fields-container col-12  col-md-6">
              <label
                htmlFor="description"
                className="kyc-label-text form-label"
              >
                Description
              </label>
              <input
                required
                onChange={onChangeAddRMDetailsFields}
                id="description"
                type="text"
                name="description"
                className="kyc-input-field form-control"
                value={editRMDetailsFields.description}
              />
            </div>

            <div className="kyc-fields-container col-12  col-md-6">
              <label htmlFor="HSN" className="kyc-label-text form-label">
                HSN Code
              </label>
              <input
                required
                onChange={onChangeAddRMDetailsFields}
                id="HSN"
                type="tel"
                name="HSN"
                className="kyc-input-field form-control"
                value={editRMDetailsFields.HSN}
              />
            </div>

            <div className="kyc-fields-container col-12  col-md-6">
              <label htmlFor="quantity" className="kyc-label-text form-label">
                Available Quantity
              </label>
              <input
                required
                onChange={onChangeAddRMDetailsFields}
                id="quantity"
                type="tel"
                name="quantity"
                className="kyc-input-field form-control"
                value={editRMDetailsFields.quantity}
              />
            </div>

            <div className="kyc-fields-container col-12  col-md-6">
              <label htmlFor="unitPrice" className="kyc-label-text form-label">
                Price/Unit
              </label>
              <input
                required
                onChange={onChangeAddRMDetailsFields}
                id="unitPrice"
                type="tel"
                name="unitPrice"
                className="kyc-input-field form-control"
                value={editRMDetailsFields.unitPrice}
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end gap-4 mt-5">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={toggleModal}
          >
            Back
          </button>
          <button type="submit" className="btn btn-success">
            Update
          </button>
        </div>
      </form>
    </>
  );
};

const DeleteRMModal = ({ toggleModal, activeDeleteData, onRefreshTable }) => {
  /* FUNCTION DECLARATIONS */
  const dispatch = useDispatch();

  /* USESELECTOR */
  const inventoryDeleteTableData = useSelector(
    (state) => state.anchor.inventoryDeleteTableData
  );
  /* EVENT LISTENERS */
  const onClickCancel = () => {
    toggleModal();
  };
  const onClickDelete = () => {
    const body = {
      _id: activeDeleteData._id,
    };
    console.log(body);
    dispatch(deleteInventoryDetails(body))
      .unwrap()
      .then((response) => {
        // onRefreshTable();
        toastMessage(response.success, "success");
        toggleModal();
      })
      .catch((error) => {
        console.log("error : ", error);
        toastMessage(error.message, "error");
      });
  };
  return (
    <>
      {inventoryDeleteTableData.isLoading && (
        <div className="blur-loader-container">
          <Loader />
        </div>
      )}
      <div className="d-flex justify-content-center align-items-center flex-column gap-4 p-4">
        <div className="d-flex justify-content-center align-items-center">
          <RxCrossCircled className="text-danger" size={100} />
        </div>
        <div className="d-flex justify-content-center align-items-center flex-column gap-2">
          <h2 className="fw-semibold">Are you sure?</h2>
          <span className="fs-5 text-secondary text-center">
            Do you really want to delete this records? <br /> This process
            cannot be undone.
          </span>
        </div>
        <div className="d-flex justify-content-center align-items-center gap-3">
          <button onClick={onClickCancel} className="btn btn-secondary">
            Cancel
          </button>
          <button onClick={onClickDelete} className="btn btn-danger">
            Delete
          </button>
        </div>
      </div>
    </>
  );
};

/* PARENT COMPONENT */
const AnchorInventoryTable = (props) => {
  const navigate = useNavigate();
  const [activeEditData, setActiveEditData] = useState([]);
  const [activeDeleteData, setActiveDeleteData] = useState([]);

  console.log("props : ", props);

  /* TABLE OPTIONS */
  const totalPage = 5;

  /* MODALS USESTATE */
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  /* MODALS TOGGLE */
  const toggleEditModal = () => {
    setShowEditModal((prev) => !prev);
  };
  const toggleDeleteModal = () => {
    setShowDeleteModal((prev) => !prev);
  };

  const onClickEdit = (data) => {
    setActiveEditData(data);
    toggleEditModal();
  };

  const onClickDelete = (data) => {
    setActiveDeleteData(data);
    toggleDeleteModal();
  };
  console.log("props", props);
  return (
    <>
      {/* MODALS */}
      {showEditModal && (
        <ReusableModal
          showModal={showEditModal}
          toggleModal={toggleEditModal}
          modalContent={
            <EditRMModal
              activeEditData={activeEditData}
              toggleModal={toggleEditModal}
              onRefreshTable={props.onRefreshTable}
            />
          }
          modalSizeClass="modal-lg"
        />
      )}
      {showDeleteModal && (
        <ReusableModal
          showModal={showDeleteModal}
          toggleModal={toggleDeleteModal}
          modalContent={
            <DeleteRMModal
              activeDeleteData={activeDeleteData}
              toggleModal={toggleDeleteModal}
              onRefreshTable={props.onRefreshTable}
            />
          }
          modalSizeClass="modal-lg"
        />
      )}
      <div className="table-container">
        <table className={props.className}>
          <thead>
            <tr>
              {props.tableHeader.map((eachHeader) => (
                <th key={eachHeader} scope="col">
                  {eachHeader}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {props.tableRows.length !== 0 &&
              props.tableRows.map((eachRows) => (
                <tr key={eachRows._id} scope="col">
                  <td>{eachRows.productName}</td>
                  <td>{eachRows.modelNumber}</td>
                  <td>{eachRows.description}</td>
                  <td>{eachRows.HSN}</td>
                  <td>{eachRows.quantity}</td>
                  <td>{eachRows.unitPrice}</td>
                  <td>
                    <span
                      onClick={() => onClickEdit(eachRows)}
                      className="text-capitalize mx-1 text-primary"
                      type="button"
                    >
                      <BsPencil />
                    </span>
                    <span
                      onClick={() => onClickDelete(eachRows)}
                      className="text-capitalize mx-1 text-danger"
                      type="button"
                    >
                      <RiDeleteBin6Line />
                    </span>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {/* <div className="d-flex justify-content-end px-2">
          <span
            // onClick={props.onClickViewMoreTableData}
            className="d-flex align-items-center gap-1 my-3 view-more-text"
          >
            View more <HiOutlineArrowNarrowDown className="fs-5" />
          </span>
        </div> */}
      </div>
      {/* <div className="d-flex align-items-center gap-2 page-data-text-container mt-3 px-3">
        <AiOutlineDoubleLeft
        // onClick={props.onClickPreviousPage}
        />
        <span className="page-data-text">
          Page {} of {totalPage}
        </span>
        <AiOutlineDoubleRight
        // onClick={props.onClickNextPage}
        />
      </div> */}
    </>
  );
};

export default AnchorInventoryTable;
