import React, { useEffect, useState } from 'react';
import user from "../../../assests/images/user.png"
import {getBOEDetails, getInvoiceDigitalDetails, getProformaInvoiceDetails} from "../../../store/slices/buyer";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {Helmet} from "react-helmet";

const BuyerBillOfExchange = () => {

    const dispatch = useDispatch();
    const boeDetailsResponse = useSelector((state)=> state.buyer.BOEDetails);
    const InvoiceDigitalDetailsResponse = useSelector((state)=> state.buyer.InvoiceDigitalDetails);
    const proformaDetailsResponse = useSelector((state)=> state.buyer.proformaInvoiceDetailsDataState);

    const [invoiceDetails, setInvoiceDetails] = useState();
    const [boeDetails, setBoeDetails] = useState();
    const [proformaInvoiceDetails, setProformaInvoiceDetails] = useState();
    const {BOENumber} = useParams();

    const handlePrintPage = ()=>{
        // let originalContent = document.body.innerHTML;
        let billOfExchange = document.getElementById('print-invoice-details');
        // document.body.innerHTML = billOfExchange.innerHTML;
        // window.print();
        // document.body.innerHTML = originalContent;
        // var content = document.getElementById("print-invoice-details");
        // var pri = document.getElementById("print-invoice").contentWindow;
        // pri.document.open();
        // pri.document.write(content.innerHTML);
        // pri.document.close();
        // pri.focus();
        // pri.print();

        let w=window.document.open("", "",'height=400,width=600',);
      
        w.document.write(billOfExchange.innerHTML);
        // w.document.open();
        // w.focus();
        w.print();
        w.document.close();

       

    }

    useEffect(()=>{
      dispatch(getBOEDetails(BOENumber));
    }, [dispatch, BOENumber]);

    useEffect(()=>{

      if(boeDetailsResponse.data){
        setBoeDetails(boeDetailsResponse?.data?.items[0]);
        dispatch(getInvoiceDigitalDetails(
          {
          'sellerID':boeDetailsResponse?.data?.items[0]?.sellerId, 
          'invoiceNumber':boeDetailsResponse?.data?.items[0]?.invoiceId
          }));
      }
    }, [boeDetailsResponse.data, dispatch]);

    useEffect(()=>{
      if(InvoiceDigitalDetailsResponse.data){
        setInvoiceDetails(InvoiceDigitalDetailsResponse.data?.items[0]);
        dispatch(getProformaInvoiceDetails({
          'sellerID':InvoiceDigitalDetailsResponse.data?.items[0]?.sellerId,
          'proformaInvoiceID':InvoiceDigitalDetailsResponse.data?.items[0]?.proformaId,
        }));
      }

    }, [InvoiceDigitalDetailsResponse.data, dispatch])

    useEffect(()=>{
      if(proformaDetailsResponse.data){
        setProformaInvoiceDetails(proformaDetailsResponse.data?.items[0])
      }
    }, [proformaDetailsResponse.data])


    console.log(boeDetails);

  return (<>
  
  <div className="d-flex flex-column m-0 p-0 gap-4 request-details-cont mb-3"
    id='print-invoice-details'
  >
    <Helmet>
                <title>BillOfExchange</title>
            </Helmet>
        <div className="container-with-heading d-flex flex-column container"
        id='print-invoice'>
          <span className="content-title mb-3 row">Invoice Details</span>

          {/* <h1 className='border border-dark text-center'>BILL OF EXCHANGE</h1> */}
        <div className='row'>
            <div className="col-md-9"></div>
            <div className="col-md-3">
                <img src={user} alt="user img" width={50} height={50} className='mb-4'/>
                <h4 className='my-2'>Ricoh Electronics</h4>
                <p className='my-2'>{invoiceDetails?.sellerAddress}</p>
                <p className="my-2">{invoiceDetails?.sellerCity}</p>
                <p className='my-2'>{`${invoiceDetails?.sellerState} - ${invoiceDetails?.sellerPinCode}`}</p>
            </div>
        </div>
        <div>
            <div className='mx-5 my-3 mb-5'>
                <p><span>Invoice Amount:</span> ₹ {boeDetails?.invoiceAmount}</p>
                <p>thirty days after date,pay to Ricoh Electronics, or to their order, the sum of rupees only for the value received.</p>
            </div>
            <div className='row'>
            <div className="col-md-9"></div>
            <div className="col-md-3">
                {/* <img src={user} alt="" height={50} width={50}/> */}
                <p className='my-2'>Authorized Signatory</p>
                <p className='my-2'>For Aton Electronics</p>
            </div>
        </div>

        </div>
        <div className='row'>
            <div className="col-md-3 px-md-5">
                <img src={user} alt="" width={50} height={50} className='mb-4'/>
                <h4 className='my-2'>{invoiceDetails?.buyerName}</h4>
                <p className='my-2'>{invoiceDetails?.invoiceToAddress}</p>
                {/* <p className='my-2'>Odisha- 757041</p> */}
            </div>
            <div className="col-md-9"></div>
        </div>
        <div className='row my-3'>
            <div className="col-md-8"></div>
            <div className="col-md-4 d-flex justify-content-center"
            >
                <button 
                className='btn btn-primary'
                onClick={handlePrintPage}
                >
                    print
                </button>
            </div>
        </div>
        </div>
      </div>
  </>
  )
}


export default BuyerBillOfExchange;
