import React, { useEffect, useState } from "react";
import { BsPlusCircleFill, BsSearch } from "react-icons/bs";
import Loader from "../../layout/GenericLayout/Loader";
import { useDispatch, useSelector } from "react-redux";
import {
  getDigitalPurchaseOrderTable,
  getManualPurchaseOrderTable,
  getApprovedRetailerList,
  updateAddManualPO,
} from "../../store/slices/anchor";
import DigitalPurchaseOrderTable from "./AnchorTables/PurchaseOrderTables.js/DigitalPurchaseOrderTable";
import ManualPurchaseOrderTable from "./AnchorTables/PurchaseOrderTables.js/ManualPurchaseOrderTable";
import ReusableModal from "../../layout/GenericLayout/ReusableModal";
import { toastMessage } from "../../layout/GenericLayout/ToastMessage";
import { Helmet } from "react-helmet";

/* *****ON REFRESH IS NOT IMPLEMENTED, TABLE WON'T UPDATE AFTER ADDING MANUAL PO***** */

/* MODALS OR CHILDREN COMPONENTS */
const PurchaseOrderAddModal = ({ togglePurchaseOrderAddModal, retailerId }) => {
  /* FUNCTION DECLARATIONS */
  const dispatch = useDispatch();

  /* USESTATES */
  const [addPODetailsFields, setAddPODetailsFields] = useState({});
  const [allCouterPartyList, setAllCounterPartyList] = useState([]);

  /* USEEFFECTS */
  useEffect(() => {
    dispatch(getApprovedRetailerList())
      .unwrap()
      .then((response) => {
        const allCounterPartyls = [];
        response.success.map((eachData) => {
          allCounterPartyls.push({
            name: eachData.name,
            retailerId: eachData.retailerId,
          });
        });
        setAllCounterPartyList(allCounterPartyls);
        console.log(allCounterPartyls);
      })
      .catch((error) => {
        console.log("error : ", error);
      });
  }, [dispatch]);

  /* EVENT LISTENERS */
  const onChangeAddPODetailsFields = (event) => {
    const name = event.target.name;
    const value =
      name === "ePurchaseOrder" ? event.target.files[0] : event.target.value;

    setAddPODetailsFields((prev) => ({ ...prev, [name]: value }));
  };

  const onBlurAddPODetailsFields = (event) => {
    const name = event.target.name;
    const validity = event.target.checkValidity();
  };

  const onSubmitRMAddDetails = (event) => {
    event.preventDefault();

    console.log("------------------------", addPODetailsFields.counterParty);
    if (
      addPODetailsFields?.counterParty === "Open this select menu" ||
      addPODetailsFields?.counterParty === undefined
    ) {
      toastMessage("Select Counter Party", "error");
    } else {
      const body = new FormData();
      body.append("po_doc", addPODetailsFields.ePurchaseOrder);
      body.append("po_date", addPODetailsFields.poDate);
      body.append("poNumber", addPODetailsFields.poNumber);
      body.append("finalAmount", addPODetailsFields.finalAmount);
      body.append("buyerId", addPODetailsFields.counterParty);

      dispatch(updateAddManualPO(body))
        .unwrap()
        .then((response) => {
          toastMessage(response.success, "success");
          // onRefreshTable();
          togglePurchaseOrderAddModal();
        })
        .catch((error) => {
          toastMessage(error.message, "error");
        });
    }
  };

  console.log(addPODetailsFields);

  return (
    <>
      {/* {relationshipManagerAddData.isLoading && (
        <div className="blur-loader-container">
          <Loader />
        </div>
      )} */}
      <form className="" onSubmit={onSubmitRMAddDetails}>
        <span className="fs-4 fw-semibold">Add Manual Purchase Order</span>
        <div className="details-container container mt-4">
          <div className="row gy-md-4 gy-2">
            <div className="kyc-fields-container col-12  col-md-6">
              <label htmlFor="poNumber" className="kyc-label-text form-label">
                PO Number
              </label>
              <input
                required
                onChange={onChangeAddPODetailsFields}
                id="poNumber"
                name="poNumber"
                type="text"
                className="kyc-input-field form-control"
                value={addPODetailsFields.name}
              />
            </div>

            <div className="kyc-fields-container col-12  col-md-6">
              <label htmlFor="poDate" className="kyc-label-text form-label">
                PO Date
              </label>
              <input
                onBlur={onBlurAddPODetailsFields}
                required
                onChange={onChangeAddPODetailsFields}
                id="poDate"
                type="date"
                name="poDate"
                className="kyc-input-field form-control"
                // value={addPODetailsFields.emailId}
              />
            </div>

            <div className="kyc-fields-container col-12  col-md-6">
              <label
                htmlFor="counterParty"
                className="kyc-label-text form-label"
              >
                Counter-Party
              </label>
              <select
                // onFocus={onFocusSelectAnchor}
                // value={activeMappingData.anchor}
                onChange={onChangeAddPODetailsFields}
                name="counterParty"
                className="form-select w-100"
                id="counterParty"
              >
                <option
                  // value=""
                  disabled
                  defaultValue
                  selected
                >
                  Open this select menu
                </option>

                {allCouterPartyList.length !== 0 &&
                  allCouterPartyList.map((eachanchorData, index) => (
                    <option
                      key={eachanchorData.retailerId}
                      value={eachanchorData.retailerId}
                      // selected={index === 0}
                    >
                      {eachanchorData.name}
                    </option>
                  ))}
              </select>
            </div>

            <div className="kyc-fields-container col-12  col-md-6">
              <label
                htmlFor="finalAmount"
                className="kyc-label-text form-label"
              >
                Final Amount
              </label>
              <input
                required
                onChange={onChangeAddPODetailsFields}
                id="finalAmount"
                type="tel"
                name="finalAmount"
                className="kyc-input-field form-control"
                // value={addPODetailsFields.mobileNumber}
              />
            </div>

            <div className="kyc-fields-container col-12  col-md-6">
              <label
                htmlFor="ePurchaseOrder"
                className="kyc-label-text form-label"
              >
                E- Purchase Order
              </label>
              <input
                required
                onChange={onChangeAddPODetailsFields}
                id="ePurchaseOrder"
                type="file"
                name="ePurchaseOrder"
                className="kyc-input-field form-control"
                // value={addPODetailsFields.mobileNumber}
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end gap-4 mt-5">
          <button type="submit" className="btn btn-success">
            Submit
          </button>
        </div>
      </form>
    </>
  );
};

/* PARENT COMPONENT */
const AnchorPurchaseOrder = () => {
  const dispatch = useDispatch();
  /* USESELECT HOOKS */
  const digitalPurchaseOrderTableData = useSelector(
    (state) => state.anchor.digitalPurchaseOrderTableData
  );
  const manualPurchaseOrderTableData = useSelector(
    (state) => state.anchor.manualPurchaseOrderTableData
  );
  /* USESTATES */
  const [activeTableData, setActiveTableData] = useState(1);
  const [materialRequestTableRows, setMaterialRequestTableRows] = useState([]);

  const [search, setSearch] = useState("");

  const [materialRequestMetaData, setMaterialRequestMetaData] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [showPurchaseOrderAddModal, setShowPurchaseOrderAddModal] =
    useState(false);

  /* TABLE QUERY PARAMS */
  const [tableQueryParams, setTableQueryParams] = useState({
    tableOffset: 0,
    tableLimit: 5,
    tableSort: "modifiedDate",
    tableOrder: -1,
    currentPage: 1,
  });

  /* USEEFFECTS */
  useEffect(() => {
    if (activeTableData === 1) {
      dispatch(
        getDigitalPurchaseOrderTable({
          tableQueryParams,
          search,
        })
      );
    } else if (activeTableData === 2) {
      dispatch(
        getManualPurchaseOrderTable({
          tableQueryParams,
          search,
        })
      );
    }
  }, [dispatch, tableQueryParams, activeTableData, search]);

  useEffect(() => {
    if (activeTableData === 1) {
      if (digitalPurchaseOrderTableData.data) {
        setMaterialRequestTableRows(digitalPurchaseOrderTableData.data.items);

        setMaterialRequestMetaData(digitalPurchaseOrderTableData.data.metadata);
      }
    }
  }, [digitalPurchaseOrderTableData.data]);

  useEffect(() => {
    if (activeTableData === 2) {
      if (manualPurchaseOrderTableData.data) {
        setMaterialRequestTableRows(manualPurchaseOrderTableData.data.items);

        setMaterialRequestMetaData(manualPurchaseOrderTableData.data.metadata);
      }
    }
  }, [manualPurchaseOrderTableData.data]);

  /* EVENT LISTENERS */
  const onClickActiveTable = (value) => {
    setActiveTableData(value);

    setSearch("");
  };

  const togglePurchaseOrderAddModal = () => {
    setShowPurchaseOrderAddModal((prev) => !prev);
  };

  const onChangeSearch = (e) => {
    setSearch(e.target.value);
  };

  return (
    <>
      {/* Modal Content */}
      {showPurchaseOrderAddModal && (
        <ReusableModal
          showModal={showPurchaseOrderAddModal}
          toggleModal={togglePurchaseOrderAddModal}
          modalContent={
            <PurchaseOrderAddModal
              togglePurchaseOrderAddModal={togglePurchaseOrderAddModal}
            />
          }
          modalSizeClass="modal-lg"
        />
      )}
      <div className="d-flex flex-column gap-4" style={{ overflowY: "auto" }}>
        <Helmet>
          <title>Purchase-Order</title>
        </Helmet>
        <div className="bg-white admin-dashboard-card-container px-3">
          <div className="d-flex justify-content-between align-items-center flex-wrap px-2 gap-2">
            <span className="dashboard-content-title">Purchase Order</span>
            <div
              onClick={togglePurchaseOrderAddModal}
              className="d-flex align-items-center"
              type="button"
            >
              <div className={`rm-add-button ${isHovered ? "show" : ""}`}>
                ADD
              </div>
              {/* <button
                className="btn mx-3 mt-4"
                style={{
                  border: "1px solid #6378d4",
                  borderRadius: "10px",
                }}
              >
                {" "}
                Add Manual Purchase Order &nbsp;
                <BsPlusCircleFill className="rm-light-plus-button" />{" "}
              </button> */}

              {/* <BsPlusCircleFill
                className="rm-plus-button"
              /> */}
            </div>
          </div>

          <div className="d-flex flex-column flex-sm-row align-items-center my-4">
            <div className="d-flex flex-column justify-content-center gap-3 col-12 col-sm-6">
              {/* <label
                htmlFor="selectAnchor"
                className="anchor-and-lender-select-label"
              >
                Purchase Order ID
              </label> */}
              <div className="search-container d-flex align-items-center py-1 px-3 col-12 col-sm-8 border border-secondary">
                <input
                  className="search-input-element d-flex flex-grow-1"
                  type="text"
                  placeholder="Search with Purchase Order ID"
                  onChange={onChangeSearch}
                  value={search}
                />{" "}
                <BsSearch className="fs-5" />
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex flex-column">
          <div className="d-flex">
            <span
              className={`dashboard-table-title ${
                activeTableData !== 1 && "dashboard-table-title-hover"
              } py-2 px-3 ${
                activeTableData === 1 && "active-table-data placeholder-wave"
              }`}
              onClick={() => onClickActiveTable(1)}
            >
              Digital PO
            </span>
            {/* <span
              className={`dashboard-table-title ${
                activeTableData !== 2 && "dashboard-table-title-hover"
              } py-2 px-3 ${
                activeTableData === 2 && "active-table-data placeholder-wave"
              }`}
              onClick={() => onClickActiveTable(2)}
            >
              Manual PO
            </span> */}
          </div>
          <div>
            {activeTableData === 1 && (
              <DigitalPurchaseOrderTable
                className="admin-anchor-and-lender-mapped-table"
                tableHeader={[
                  { name: "Purchase Order Date", sortValue: "addedDate" },
                  { name: "Purchase Order ID", sortValue: "" },
                  { name: "Counter Party", sortValue: "buyerName" },
                  {
                    name: "Date Of Delivery",
                    sortValue: "expectedDateOfDelivery",
                  },
                  {
                    name: "Payment Terms (in days)",
                    sortValue: "paymentTerms",
                  },
                  { name: "Delivery Location", sortValue: "" },
                  { name: "Total Amount (₹)", sortValue: "" },
                ]}
                tableRows={materialRequestTableRows}
                materialRequestMetaData={materialRequestMetaData}
                tableQueryParams={tableQueryParams}
                setTableQueryParams={setTableQueryParams}
                isLoading={digitalPurchaseOrderTableData.isLoading}
              />
            )}
            {activeTableData === 2 && (
              <ManualPurchaseOrderTable
                className="admin-anchor-and-lender-mapped-table"
                tableHeader={[
                  { name: "Purchase Order Date", sortValue: "addedDate" },
                  { name: "Purchase Order ID", sortValue: "" },
                  { name: "Counter Party", sortValue: "buyerName" },
                  {
                    name: "Anchor",
                    sortValue: "",
                  },
                  { name: "Total Amount (₹)", sortValue: "" },
                  { name: "Invoice Status", sortValue: "" },
                  { name: "Status", sortValue: "" },
                ]}
                tableRows={materialRequestTableRows}
                materialRequestMetaData={materialRequestMetaData}
                tableQueryParams={tableQueryParams}
                setTableQueryParams={setTableQueryParams}
                isLoading={manualPurchaseOrderTableData.isLoading}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AnchorPurchaseOrder;
