import React, { useEffect, useState } from "react";
import ProformaInvoiceTable from "../Lender/LenderTables/ApproveLimitTable";
import { BsPlusCircleFill, BsSearch } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { getApproveLimit } from "../../store/slices/lender";
import { useNavigate } from "react-router-dom";
import {Helmet} from "react-helmet";

const LenderBillLogding = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const proFormaInvoiceTableResponse = useSelector(
    (state) => state.lender.approveLimit
  );

  const [proformaInvoiceTableRowData, setProformaInvoiceTableRowData] =
    useState([]);
  const [proformInvoiceTableMetaData, setProformInvoiceTableMetaData] =
    useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [searchByProformaID, setSearchByProformaID] = useState("");

  /* TABLE QUERY PARAMS */
  const [tableQueryParams, setTableQueryParams] = useState({
    tableOffset: 0,
    tableLimit: 5,
    tableSort: "proformaId",
    tableOrder: 1,
    currentPage: 0,
    search: "",
  });

  useEffect(() => {
    dispatch(getApproveLimit(tableQueryParams.tableOffset));

    // setSearchByProformaID("");
  }, [tableQueryParams, dispatch]);

  function refreshTable() {
    dispatch(getApproveLimit(tableQueryParams.tableOffset));
  }

  useEffect(() => {
    if (proFormaInvoiceTableResponse.data) {
      console.log(proFormaInvoiceTableResponse);
      setProformaInvoiceTableRowData(proFormaInvoiceTableResponse.data.success);
      setProformInvoiceTableMetaData(
        proFormaInvoiceTableResponse.data.metadata
      );
    }
  }, [proFormaInvoiceTableResponse]);

  const handleSearchByProformaIDOnchange = (e) => {
    setSearchByProformaID(e.target.value);
    //
  };

  useEffect(() => {
    if (searchByProformaID !== "") {
      setTableQueryParams((prev) => ({ ...prev, search: searchByProformaID }));
    }
  }, [searchByProformaID]);

  const handleSearchByProformaIDSubmit = (e) => {
    if (e.key === "Enter") {
      console.log("Thiis is search by ProfoID", searchByProformaID);
      setTableQueryParams((prev) => ({ ...prev, search: searchByProformaID }));
      // setSearchByProformaID('')
    }
  };

  // console.log("The Response or the Data Itself",proFormaInvoiceTableResponse);
  console.log("The Data Itself ", proformaInvoiceTableRowData);
  console.log("The Meta Data ", proformInvoiceTableMetaData);

  // console.log(searchByProformaID.length)

  const RedirectFunct = (data, data1) => {
    sessionStorage.setItem("CustomerId", data);
    sessionStorage.setItem("InvoiceId", data1);
    navigate({ pathname: "/lender/bill-lodging/invoice-details" });
  };
  const handleBillDetail = (data) => {
    sessionStorage.setItem("ReferenceBillId", data);
    navigate({ pathname: "/lender/bill-lodging/bill-deails" });
  };

  return (
    <>
      <div className="d-flex flex-column gap-4">
      <Helmet>
                <title>Approve-Limit</title>
            </Helmet>
        <div className="bg-white admin-dashboard-card-container p-3">
          <div className="d-flex justify-content-between align-items-center flex-wrap px-2 gap-2 py-2">
            <span className="dashboard-content-title">
              Credit Limit Details
            </span>
          </div>
        </div>

        <div className="d-flex flex-column justify-content-between flex-wrap px-2 gap-2">
          <ProformaInvoiceTable
            className="lender-anchor-and-lender-mapped-table2 placeholder-wave0"
            tableHeader={[
              { name: "", sortValue: "", },
              { name: "Customer ID", sortValue: "" },
              { name: "Name", sortValue: "" },
              { name: "Max Limit", sortValue: "" },
              { name: "Total Utilized Amount", sortValue: "" },
              { name: "Available Amount", sortValue: "" },
              { name: "Hold Amount", sortValue: "" },
              { name: "Suspended Amount", sortValue: "" },
              { name: "Grade", sortValue: "" },
              { name: "Max Late Charge", sortValue: "" },
              { name: "Limit Expiry", sortValue: "" },
              { name: "Limit Next Renewal", sortValue: "" },
              { name: "Line of Credit", sortValue: "" },
              { name: "Score Card", sortValue: "" },
              { name: "CAM", sortValue: "" },
              { name: "Type", sortValue: "" },
              { name: "Status", sortValue: "" },
            ]}
            tableRows={proformaInvoiceTableRowData}
            materialRequestMetaData={proformInvoiceTableMetaData}
            tableQueryParams={tableQueryParams}
            setTableQueryParams={setTableQueryParams}
            isLoading={proFormaInvoiceTableResponse.isLoading}
            RedirectFunct={RedirectFunct}
            handleBillDetail={handleBillDetail}
            refreshTable={refreshTable}
          />
        </div>
      </div>
    </>
  );
};

export default LenderBillLogding;
