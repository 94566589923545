import { AnchorKycActions } from "../actionTypes";
// eslint-disable-next-line no-unused-vars
import { AnchorKYCFormTemplate } from "../anchorKycContext";

export const actions = AnchorKycActions.COMPANY_DETAILS;

/**
 * @param { typeof AnchorKYCFormTemplate.companyDetails } state
 * @param { {type: typeof actions, payload: string | BufferSource} } param1
 * @returns { typeof AnchorKYCFormTemplate.companyDetails }
 */
export function CompanyDetailsReducer(state, { type, payload }) {
  if (type === actions.LOGO_UPDATE_API) {
    return {
      ...state,
      logo: { fileList: payload ?? [], isVerified: true, isTouched: true },
    };
  } else if (type === actions.CHANGE_PRODUCT_CATEGORY) {
    const value = payload || state.productCategory.value;
    return {
      ...state,
      productCategory: { value, isTouched: true, isValid: value !== "" },
    };
  } else if (type === actions.BLUR_PRODUCT_CATEGORY) {
    return {
      ...state,
      productCategory: { ...state.productCategory, isTouched: true },
    };
  } else if (type === actions.CHANGE_COMPANY_NAME) {
    const value = payload ?? state.companyName.value;
    return {
      ...state,
      companyName: { value, isTouched: true, isValid: value.length > 3 },
    };
  } else if (type === actions.BLUR_COMPANY_NAME) {
    return { ...state, companyName: { ...state.companyName, isTouched: true } };
  } else if (type === actions.CHANGE_COMPANY_TYPE) {
    const value = payload || state.companyType.value;
    return {
      ...state,
      companyType: { value, isTouched: true, isValid: value !== "" },
    };
  } else if (type === actions.BLUR_COMPANY_TYPE) {
    return { ...state, companyType: { ...state.companyType, isTouched: true } };
  } else if (type === actions.CHANGE_WEBSITE) {
    const value = payload ?? state.websiteAddress.value;
    return {
      ...state,
      websiteAddress: {
        value,
        isTouched: true,
        isValid:
          /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g.test(
            value
          ),
      },
    };
  } else if (type === actions.BLUR_WEBSITE) {
    return {
      ...state,
      websiteAddress: { ...state.websiteAddress, isTouched: true },
    };
  } else if (type === actions.CHANGE_CIN) {
    const value = payload ?? state.cinNumber.value;

    return {
      ...state,
      cinNumber: { value, isTouched: true, isValid: value.length === 21 },
    };
  } else if (type === actions.BLUR_CIN) {
    return { ...state, cinNumber: { ...state.cinNumber, isTouched: true } };
  } else if (type === actions.CHANGE_PAN) {
    const value = payload ?? state.panNumber.value;
    return {
      ...state,
      panNumber: {
        isVerified: false,
        value,
        isTouched: true,
        isValid: value.length === 10,
      },
    };
  } else if (type === actions.BLUR_PAN) {
    return {
      ...state,
      panNumber: {
        ...state.panNumber,
        isTouched: true,
        isVerified: payload??false,
      },
    };
  } else if (type === actions.CHANGE_SECURITY) {
    const value = payload || state.security.value;
    return {
      ...state,
      security: { value, isTouched: true, isValid: value !== "" },
    };
  } else if (type === actions.BLUR_SECURITY) {
    return { ...state, security: { ...state.security, isTouched: true } };
  } else if (type === actions.CHANGE_COMPANY_ADDRESS) {
    const value = payload ?? state.companyAddress.value;
    return {
      ...state,
      companyAddress: { value, isTouched: true, isValid: value.length > 5 },
    };
  } else if (type === actions.BLUR_COMPANY_ADDRESS) {
    return {
      ...state,
      companyAddress: { ...state.companyAddress, isTouched: true },
    };
  } else if (type === actions.CHANGE_CITY) {
    const value = payload ?? state.city.value;
    return {
      ...state,
      city: { value, isTouched: true, isValid: value!=='' },
    };
  } 
  
  else if (type === actions.BLUR_CITY) {
    const pattern = /^[A-Za-z]{3,}$/g;
    return { ...state, city: { ...state.city, isTouched: true, isValid: pattern.test(state.city.value), } };
  } 

  else if (type === actions.CHANGE_STATE) {
    // const value = payload ?? state.state.value;
    const value = payload||state?.state?.value;
    return {
      ...state,
      state: { value, isTouched: true, isValid: value.length > 3 },
    };
  } else if (type === actions.BLUR_STATE) {
    return { ...state, state: { ...state.state, isTouched: true } };
  } else if (type === actions.CHANGE_PIN) {
    const value = payload ?? state?.pinCode?.value;
    return {
      ...state,
      pinCode: { value, isTouched: true, isValid: value.length === 6 },
    };
  } else if (type === actions.BLUR_PIN) {
    return { ...state, pinCode: { ...state.pinCode, isTouched: true } };
  } else if (type === actions.CHANGE_REGION) {
    const value = payload || state.region.value;
    return {
      ...state,
      region: { value, isTouched: true, isValid: value.length !== "" },
    };
  } else if (type === actions.BLUR_REGION) {
    return { ...state, region: { ...state.region, isTouched: true } };
  } else if (type === actions.CHANGE_COUNTRY) {
    const value = payload ?? state.country.value;
    return {
      ...state,
      country: { value, isValid: value.length > 3, isTouched: true },
    };
  } else if (type === actions.BLUR_COUNTRY) {
    return { ...state, country: { ...state.country, isTouched: true } };
  } else if (type === actions.CHANGE_OFFICE_TYPE) {
    const value = payload ?? state.typeOfOffices.value;
    return {
      ...state,
      typeOfOffices: { value, isValid: value !== "", isTouched: true },
    };
  } else if (type === actions.BLUR_OFFICE_TYPE) {
    return {
      ...state,
      typeOfOffices: { ...state.typeOfOffices, isTouched: true },
    };
  } else if (type === actions.CHANGE_NO_OFFICE) {
    const value = payload ?? state.noOfOffices.value;
    const isValid =
      value !== "" && !isNaN(parseInt(value)) && parseInt(value) > 0;
    return { ...state, noOfOffices: { value, isValid, isTouched: true } };
  } else if (type === actions.BLUR_NO_OFFICES) {
    return { ...state, noOfOffices: { ...state.noOfOffices, isTouched: true } };
  } else if (type === actions.CHANGE_STORE_TYPE) {
    const value = payload || state.typeOfStoresAndPlant.value;
    return {
      ...state,
      typeOfStoresAndPlant: { value, isValid: value !== "", isTouched: true },
    };
  } else if (type === actions.BLUR_STORE_TYPE) {
    return {
      ...state,
      typeOfStoresAndPlant: { ...state.typeOfStoresAndPlant, isTouched: true },
    };
  } else if (type === actions.CHANGE_EMP_STORE) {
    const value = payload ?? state.noOfEmployeesInStore.value;
    const isValid =
      value !== "" && !isNaN(parseInt(value)) && parseInt(value) > 0;

    return {
      ...state,
      noOfEmployeesInStore: { value, isValid, isTouched: true },
    };
  } else if (type === actions.BLUR_EMP_STORE) {
    return {
      ...state,
      noOfEmployeesInStore: { ...state.noOfEmployeesInStore, isTouched: true },
    };
  } else if (type === actions.CHANGE_NO_WAREHOUSE) {
    const value = payload ?? state.noOfWarehouses.value;
    const isValid =
      value !== "" && !isNaN(parseInt(value)) && parseInt(value) > 0;

    return { ...state, noOfWarehouses: { value, isValid, isTouched: true } };
  } else if (type === actions.BLUR_NO_WAREHOUSE) {
    return {
      ...state,
      noOfWarehouses: { ...state.noOfWarehouses, isTouched: true },
    };
  } else if (type === actions.CHANGE_TELEPHONE) {
    const value = payload ?? state.telephoneNumber.value;
    // const value = payload!==''?payload:state.telephoneNumber.value;
    console.log(value)
    return {
      ...state,
      telephoneNumber: {
        value,
        isValid: /^\d{3}\d{3}\d{5}$/.test(value),
        isTouched: true,
      },
    };
  } else if (type === actions.BLUR_TELEPHONE) {
    console.log(state);
    return {
      ...state,
      telephoneNumber: { ...state.telephoneNumber, isTouched: true },
    };
  } else if (type === actions.CHANGE_MOBILE) {
    const value = payload ?? state.mobileNumber.value;
   
    return {
      ...state,
      mobileNumber: {
        value,
        isValid: /^(\+91)?(-)?0?[6789]\d{9}$/g.test(value),
        isTouched: true,
      },
    };
  } else if (type === actions.BLUR_MOBILE) {
    return {
      ...state,
      mobileNumber: { ...state.mobileNumber, isTouched: true },
    };
  } else if (type === actions.CHANGE_EMP_WAREHOUSE) {
    const value = payload ?? state.noOfEmployeesInWarehouse.value;
    const isValid =
      value !== "" && !isNaN(parseInt(value)) && parseInt(value) > 0;

    return {
      ...state,
      noOfEmployeesInWarehouse: { value, isValid, isTouched: true },
    };
  } else if (type === actions.BLUR_EMP_WAREHOUSE) {
    return {
      ...state,
      noOfEmployeesInWarehouse: {
        ...state.noOfEmployeesInWarehouse,
        isTouched: true,
      },
    };
  } else if (type === actions.CHANGE_STORE_DETAILS) {
    const value = payload ?? state.storeDetails.value;

    return {
      ...state,
      storeDetails: { value, isValid: value.length > 5, isTouched: true },
    };
  } else if (type === actions.BLUR_STORE_DETAILS) {
    return {
      ...state,
      storeDetails: { ...state.storeDetails, isTouched: true },
    };
  } else if (type === actions.CHANGE_EMAIL) {
    const value = payload ?? state.email.value;
    return {
      ...state,
      email: {
        value,
        isValid: new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}").test(value),
        isTouched: true,
      },
    };
  } else if (type === actions.TOUCH_ALL) {
    for (let item in state) {
      for (let obj in state[item]) {
        if (obj === "isTouched") {
          state[item][obj] = true;
        }
      }
    }
    return { ...state };
  } else if(type===actions.CONFIG_CIN){
    return{...state,cinNumber:{...state.cinNumber, isValid:payload, isTouched:payload, value:""} }
  }else if(type===actions.CHANGE_MANUFACTURER){
    console.log(payload);
    return{...state,
      manufacturer: state.manufacturer.map((item, index) =>
        index === payload.index ? payload.value : item
      )}
  }

  return state;
}
