import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProformaInvoiceById, acceptPerformaInvoice } from "../../../../store/slices/anchor";
import { toastMessage } from "../../../../layout/GenericLayout/ToastMessage";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const AnchorProformaInvoiceDetailsById = () => {
	/* FUNCTION DECLARATIONS */
	const { sellerId, proformaId } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	/* USESELECTOR */
	const proformaInvoiceByIdData = useSelector(
		(state) => state.anchor.proformaInvoiceByIdData
	);
	
	/* USESTATES */
	const [tableRows, setTableRows] = useState([]);
	const [inputValues, setInputValues] = useState({});

	/* USEEFFECTS */
	useEffect(() => {
		dispatch(getProformaInvoiceById({ sellerId, proformaId }))
			.unwrap()
			.then((response) => {
				setTableRows(response.items[0].itemDetails);

				setInputValues((prev) => ({ ...prev, ...response.items[0] }));
			});
	}, [dispatch, sellerId, proformaId]);

	const acceptPerformaInvo = (event) => {
		const value = event.target.value
		if (value === "Accepted") {
			const body = {
				proformaId: proformaId,
				proformaStatus: value
			}
			dispatch(acceptPerformaInvoice(body))
				.unwrap()
				.then((response) => {
					console.log(response)
					toastMessage(
						response?.success ?? "Success",
						"success"
					);
					navigate("/anchor/proforma-invoice");
				})
				.catch((error) => {
					console.log("error : ", error);
					toastMessage(
						error?.message ?? error?.error ?? "Faild",
						"error"
					);
					navigate("/anchor/proforma-invoice");
				});
		}
		else {
			const body = {
				proformaId: proformaId,
				proformaStatus: value,
				rejectionRemarks: "rejected"
			}
			dispatch(acceptPerformaInvoice(body))
				.unwrap()
				.then((response) => {
					console.log(response)
					toastMessage(
						response?.success ?? "Success",
						"success"
					);
					navigate("/anchor/proforma-invoice");
				})
				.catch((error) => {
					console.log("error : ", error);
					toastMessage(
						error?.message ?? error?.error ?? "Faild",
						"error"
					);
					navigate("/anchor/proforma-invoice");
				});
		}

	};

	const onClickBack = () => {
		navigate(-1, { replace: true });
	};

	/* OPTIONS */
	const totalAmount =
		tableRows.length === 0
			? 0
			: tableRows.reduce(
				(accumulator, currentValue) => accumulator + currentValue.totalAmount,
				0
			);

	const performaInvoiceStatus = proformaInvoiceByIdData.data?.items[0].proformaStatus ?? "";

	const getDocumentDefinition = () => {

		const invoiceData = proformaInvoiceByIdData?.data?.items?.[0];
		
		return {
			content: [
				{
					text: 'Invoice',
					bold: true,
					fontSize: 20,
					alignment: 'center',
					margin: [0, 0, 0, 20]
				},
				{
					columns: [
						[
							{ text: invoiceData.sellerName, style: 'name' },
							{ text: invoiceData.sellerName, },
							{ text: invoiceData.sellerAddress },
							{ text: invoiceData.sellerState + ', ' + invoiceData.sellerCountry },
							{ text: invoiceData.sellerPinCode },
							{ text: 'GSTIN : ' + "", bold: true, }
						],
						[{ text: '         Proforma Invoice ID : ' + invoiceData.proformaId, color: 'blue', }]
					]
				},
				{ text: '', margin: [10, 10, 10, 10] },
				{
					columns: [
						{ text: 'PO Id          :   ' + "", },
						{ text: 'Bill To :  ' + invoiceData.buyerName + ', ' + invoiceData.locationOfDelivery },
					]
				},
				{
					columns: [
						{ text: 'Order Date :   ' + invoiceData.addedDate },
						{ text: 'Phone :   ' + invoiceData.buyerPhoneNumber },
					]
				},
				{
					columns: [
						{ text: 'PAN   : ' + invoiceData.buyerPanNumber },
						{ text: 'Ship To :   ' + invoiceData.buyerName + ', ' + invoiceData.locationOfDelivery },
					]
				},
				{
					columns: [
						{ text: 'CIN   : ' + "" },
						{ text: 'Phone :   ' + "" }
					]
				},
				{ text: '', },
				{ text: 'Items Information', style: 'header', },
				{
					table: {
						widths: ["auto", "auto", "auto", "auto", "auto", "auto", "auto"],
						margin: [0, 0, 0, 0],
						alignment: 'center',
						body: [
							[{
								text: 'Item',
								style: 'tableHeader',
								bold: true,
								fillColor: '#88A9AA'
							},
							{
								text: 'Model Number',
								style: 'tableHeader',
								bold: true,
								fillColor: '#88A9AA'
							},
							{
								text: 'Quantity Ordered',
								style: 'tableHeader',
								bold: true,
								fillColor: '#88A9AA'
							},
							{
								text: 'Available Quantity',
								style: 'tableHeader',
								bold: true,
								fillColor: '#88A9AA'
							},
							{
								text: 'Price/Unit(₹)',
								style: 'tableHeader',
								bold: true,
								alignment: 'center',
								fillColor: '#88A9AA'
							},
							{
								text: 'Tax(%)',
								style: 'tableHeader',
								bold: true,
								alignment: 'center',
								fillColor: '#88A9AA'
							},
							{
								text: 'Total Amount',
								style: 'tableHeader',
								bold: true,
								alignment: 'center',
								fillColor: '#88A9AA'
							}
							],
							...tableRows.map(ed => {
								return [
									{ text: ed.item, fillColor: '#D9F2F2' },
									{ text: ed.modelNumber, fillColor: '#D9F2F2' },
									{ text: ed.quantityOrdered, fillColor: '#D9F2F2' },
									{ text: ed.quantityAvailable, fillColor: '#D9F2F2' },
									{ text: ed.unitPrice, fillColor: '#D9F2F2' },
									{ text: ed.tax, fillColor: '#D9F2F2' },
									{ text: ed.totalAmount, fillColor: '#D9F2F2' }
								]
							})
						]
					}
				},
				{ text: 'Transport Details', style: 'header', },
				{
					columns: [
						{ text: 'Shipment Terms: ' + invoiceData.shippingTerms, },
						{ text: 'Mode of Transport: ' + invoiceData.modeOfTransport, },
					]
				},
				{
					columns: [
						{ text: 'Delivery Location: ' + invoiceData.locationOfDelivery, },
						{ text: 'Payment Terms: ' + invoiceData.paymentTerms, },
					]
				},
				{ text: 'Expected Date of delivery: ' + invoiceData.expectedDateOfDelivery }
			],
			styles: {
				header: {
					fontSize: 18,
					bold: true,
					margin: [0, 20, 0, 10],
					decoration: 'underline'
				},
				name: {
					fontSize: 16,
					bold: true
				},
				tableHeader: {
					bold: true,
				}
			}
		};
	}

	const handlePrintPage = () => {
		const documentDefinition = getDocumentDefinition();
		pdfMake.createPdf(documentDefinition).open();
	};

	return (

		<div className="bg-white admin-dashboard-card-container p-3">
			<div className="d-flex justify-content-between align-items-center flex-wrap px-2 gap-2 py-2">
				<span className="dashboard-content-title">
					Proforma Invoice Details
				</span>
				<button onClick={handlePrintPage} className="modal-export-pdf-button d-flex align-items-center gap-2">
					Export to PDF
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="20"
						height="20"
						viewBox="0 0 20 25"
					>
						<path
							id="Path_541"
							data-name="Path 541"
							d="M207.639,120.656l3.949-4.032a1.18,1.18,0,0,0-1.667-1.671l-1.352,1.361c-.308.31-.557.208-.557-.227V102.8a1.178,1.178,0,1,0-2.356,0v13.291c0,.434-.249.537-.557.227l-1.352-1.361a1.18,1.18,0,0,0-1.667,1.671l3.908,4.026a1.145,1.145,0,0,0,1.651.005Zm-8.464-6.96a1.379,1.379,0,0,0,1.95-1.95,8.074,8.074,0,1,1,11.419,0,1.379,1.379,0,0,0,1.95,1.95,10.832,10.832,0,1,0-15.318,0Z"
							transform="translate(-196.003 -95.205)"
							fill="#fff"
							fillRule="evenodd"
						/>
					</svg>
				</button>
			</div>

			<div className="d-flex flex-column align-items-center my-4 details-page-container">
				<div className="d-flex flex-column details-header">
					Items Information
				</div>
				<div className="p-3 w-100">
					<div className="details-page-table-container">
						<table className="details-page-table">
							<thead>
								<tr>
									<th scope="col">Item</th>
									<th scope="col">Model Number</th>
									<th scope="col">Quantity Ordered</th>
									<th scope="col">Available Quantity</th>
									<th scope="col">Price/Unit (₹)</th>
									<th scope="col">GST (%)</th>
									<th scope="col">Total Amount</th>
								</tr>
							</thead>

							<tbody>
								{tableRows.map((eachRows, index) => (
									<tr key={eachRows.id} scope="col">
										<td>{eachRows.item}</td>
										<td>{eachRows.modelNumber}</td>
										<td>{eachRows.quantityOrdered}</td>
										<td>{eachRows.quantityAvailable}</td>
										<td>{eachRows.unitPrice}</td>
										<td>{eachRows.gst}</td>
										<td>{eachRows.totalAmount.toFixed(2)}</td>
									</tr>
								))}
								<tr className="total-table-text">
									<td className="fw-bold" colSpan={6}>
										TOTAL
									</td>
									<td className="text-center fw-bold">{totalAmount.toFixed(2)}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>

			<div className="d-flex flex-column align-items-center my-4 details-page-container">
				<div className="d-flex flex-column details-header">
					Transport Details
				</div>
				<div className="details-container container p-3">
					<div className="row gy-md-4 gy-2">
						<div className="kyc-fields-container col-12  col-md-4">
							<label htmlFor="name" className="kyc-label-text form-label">
								Shipment Terms
							</label>
							<input
								disabled
								type="text"
								className="kyc-input-field form-control"
								value={inputValues.shippingTerms}
							/>
						</div>

						<div className="kyc-fields-container col-12  col-md-4">
							<label htmlFor="name" className="kyc-label-text form-label">
								Mode of Transport
							</label>
							<input
								disabled
								type="text"
								className="kyc-input-field form-control"
								value={inputValues.modeOfTransport}
							/>
						</div>

						<div className="kyc-fields-container col-12  col-md-4">
							<label htmlFor="name" className="kyc-label-text form-label">
								Expected Date of delivery
							</label>
							<input
								disabled
								type="text"
								className="kyc-input-field form-control"
								value={inputValues.expectedDateOfDelivery}
							/>
						</div>

						<div className="kyc-fields-container col-12  col-md-4">
							<label htmlFor="name" className="kyc-label-text form-label">
								Delivery Location
							</label>
							<input
								disabled
								type="text"
								className="kyc-input-field form-control"
								value={inputValues.locationOfDelivery}
							/>
						</div>

						<div className="kyc-fields-container col-12  col-md-4">
							<label htmlFor="name" className="kyc-label-text form-label">
								Payment Terms
							</label>
							<input
								disabled
								type="text"
								className="kyc-input-field form-control"
								value={inputValues.paymentTerms}
							/>
						</div>

						{/* <div className="kyc-fields-container col-12  col-md-4">
							<label htmlFor="name" className="kyc-label-text form-label">
							Additional Text
							</label>
							<input
								disabled
								type="text"
								className="kyc-input-field form-control"
								value={inputValues.shippingTerms}
							/>
						</div> */}
					</div>
				</div>
			</div>

			{
				performaInvoiceStatus === "Negotiated" &&
				<div className="d-flex flex-column align-items-center my-4 details-page-container">
					<div className="d-flex flex-column details-header">
						Negotiation Details
					</div>
					<div className="details-container container p-3">
						<div className="row gy-md-4 gy-2">
							<div className="kyc-fields-container col-12  col-md-4">
								<label htmlFor="name" className="kyc-label-text form-label">
									Mode of Transport
								</label>
								<input
									disabled
									type="text"
									className="kyc-input-field form-control"
									value={inputValues?.modeOfTransportNegotiated}
								/>
							</div>

							<div className="kyc-fields-container col-12  col-md-4">
								<label htmlFor="name" className="kyc-label-text form-label">
									Expected Date of Delivery
								</label>
								<input
									disabled
									type="text"
									className="kyc-input-field form-control"
									value={inputValues?.expectedDateOfDeliveryNegotiated}
								/>
							</div>

							<div className="kyc-fields-container col-12  col-md-4">
								<label htmlFor="name" className="kyc-label-text form-label">
									Payment Terms
								</label>
								<input
									disabled
									type="text"
									className="kyc-input-field form-control"
									value={inputValues?.paymentTermsNegotiated}
								/>
							</div>
						</div>
					</div>
				</div>
			}


			<div className="d-flex flex-column flex-md-row align-items-center justify-content-end gap-3 mx-5 my-3">
				{(performaInvoiceStatus === "Negotiated") && (
					<>
						<button
							className="success-button"
							value={"Accepted"}
							onClick={acceptPerformaInvo}
						>
							Accept
						</button>
						<button
							className="danger-button"
							onClick={acceptPerformaInvo}
							value={"Rejected"}>
							Reject
						</button>
					</>
				)}
				<button className="kyc-back-button" onClick={onClickBack}>
					Back
				</button>
			</div>
		</div>
	);
};

export default AnchorProformaInvoiceDetailsById;
