import { Fragment, useContext, useEffect, useState } from "react";
import { GenericInputField } from "../../../../GenericComponent/Input/Input";
import { BuyerKycContext } from "../buyerKycContext";
import { GenericFileInput } from "../../../../GenericComponent/FileInput/FileInput";
import { BuyerKycActions } from "../actionTypes";
import { useDispatch } from "react-redux";
import { updatePANVerification } from "../../../../store/slices/generic";
import { toastMessage } from "../../../../layout/GenericLayout/ToastMessage";

export const BuyerKYCCoApplicantDetails = () => {

	// ---------------------> States and Reducers
	
	const [ aadhaarFiles, setAadhaarFiles ] = useState([]);
	
	// ---------------------> Hooks And UseEffects
	
	const { coApplicantAvailable, setCoApplicantAvailable, emailId, coApplicantDetailsDispatch, coApplicantDetails, uploadFiles, disableEdit } = useContext(BuyerKycContext);
	const { files } = useContext(BuyerKycContext);

	const dispatch = useDispatch();

	useEffect(() => {
		if (files?.length > 0 && files?.[0]?.documents && Array.isArray(files?.[0]?.documents)) {
			const aadhaarTemp = [];
			for (let item of files[0].documents) {
				if (item?.coAadharImage !== undefined) {
					aadhaarTemp.push(item);
				}
			}

			if (aadhaarTemp.length > 0) {
				coApplicantDetailsDispatch({ type: BuyerKycActions.CO_APPLICANT_DETAILS.AADHAAR_UPDATE_API });
			}

			setAadhaarFiles(aadhaarTemp);
		}
	}, [ files, coApplicantDetailsDispatch ]);

	// ---------------------> Functions and Handlers

	const handleIncludeCoApplicantDetails = () => {
		if (coApplicantAvailable) {
			coApplicantDetailsDispatch({ type: BuyerKycActions.CO_APPLICANT_DETAILS.EXCLUDE_CO_APPLICANT });
		}
		else {
			coApplicantDetailsDispatch({ type: BuyerKycActions.CO_APPLICANT_DETAILS.INCLUDE_CO_APPLICANT });
		}
		
		setCoApplicantAvailable(!coApplicantAvailable);
	};

	const handlePanBlur = () => {
		if (coApplicantDetails.panNo.value.length === 10) {
			const body = {
				panNumber: coApplicantDetails.panNo.value,
				userid: emailId,
			};

			dispatch(updatePANVerification(body))
				.unwrap()
				.then(response => {
					if (response?.status) {
						coApplicantDetailsDispatch({ type: BuyerKycActions.CO_APPLICANT_DETAILS.BLUR_PAN, payload: true });
					}
				})
				.catch(error => {
					coApplicantDetailsDispatch({ type: BuyerKycActions.CO_APPLICANT_DETAILS.BLUR_PAN, payload: false });
					toastMessage(error?.error ?? error?.message ?? "Unable to verify PAN", "error");
				});
		} else {
			coApplicantDetailsDispatch({ type: BuyerKycActions.CO_APPLICANT_DETAILS.BLUR_PAN, payload: false });
		}


	};

	/**
	 * @param { React.ChangeEvent<HTMLInputElement> } event 
	 */
	const handleAadhaarChange = (event) => {
		const formData = new FormData();

		formData.append("coAadharImage", event?.target?.files?.[0]);

		uploadFiles(formData, "coAadharImage");
	};

	/**
	 * @param { React.ChangeEvent<HTMLInputElement> } e
	 */
	const handleChangeName = e => {
		const pattern = /^[A-Za-z ]*$/g;

		if (!pattern.test(e.target.value)) {
			return
		}

		coApplicantDetailsDispatch({ type: BuyerKycActions.CO_APPLICANT_DETAILS.CHANGE_NAME, payload: e.target.value })
	}

	return (
		<Fragment>
			<div id="accordionExample" className="accordion container-with-heading d-flex flex-column container my-3 p-0">
				<span
					className="kyc-content-title row accordion-button m-0 bg-warning bg-opacity-50 placeholder-wave"
					type="button"
					data-bs-toggle="collapse"
					data-bs-target="#collapseOne"
					aria-expanded="true"
					aria-controls="collapseOne"
				>
					Co-Applicant Details
				</span>

				<div className="details-container accordion-collapse collapse show" id="collapseOne"
					aria-labelledby="headingOne" data-bs-parent="#accordionExample"
				>
					<div className="row gy-md-4 gy-2">

						<label>
							<input onChange={ handleIncludeCoApplicantDetails } disabled={ true } type="checkbox" checked={ coApplicantAvailable } />
							&nbsp;&nbsp;&nbsp;Include Co-Applicant Details
						</label>
						
						<GenericInputField 
							label="Name" required={ true } 
							onChangeHandler={ handleChangeName }
							onBlurHandler={ () => coApplicantDetailsDispatch({ type: BuyerKycActions.CO_APPLICANT_DETAILS.BLUR_NAME, }) }
							value={ coApplicantDetails.name.value } 
							disabled={ !coApplicantAvailable || disableEdit } 
							isInvalid={ coApplicantDetails.name.isTouched && !coApplicantDetails.name.isValid }
							errorMessage={ coApplicantDetails.name.value === "" ? "Name is required" : "Name should have at least 4 characters" }
						/>

						<GenericInputField 
							label="PAN Number" required={ true } verified={ coApplicantDetails.panNo.isVerified }
							value={ coApplicantDetails.panNo.value } disabled={ !coApplicantAvailable || disableEdit } onBlurHandler={ () => handlePanBlur() } 
							onChangeHandler={ event => coApplicantDetailsDispatch({ type: BuyerKycActions.CO_APPLICANT_DETAILS.CHANGE_PAN, payload: event.target.value.toUpperCase() }) }
							isInvalid={ (coApplicantDetails.panNo.isTouched && !coApplicantDetails.panNo.isValid) || (coApplicantDetails.panNo.isTouched && !coApplicantDetails.panNo.isVerified) }
							errorMessage={ coApplicantDetails.panNo.value === "" ? "PAN Number is required" : "Invalid PAN Number" }
							successMessage="PAN Verified"
						/>
						
						<GenericInputField 
							label="Mobile Number" required={ true } 
							errorMessage={ coApplicantDetails.mobileNumber.value === "" ? "Mobile Number is required" : "Invalid Mobile Number" }
							value={ coApplicantDetails.mobileNumber.value } type="number" disabled={ !coApplicantAvailable || disableEdit } 
							onBlurHandler={ () => coApplicantDetailsDispatch({ type: BuyerKycActions.CO_APPLICANT_DETAILS.BLUR_MOBILE }) }
							onChangeHandler={ event => coApplicantDetailsDispatch({ type: BuyerKycActions.CO_APPLICANT_DETAILS.CHANGE_MOBILE, payload: event.target.value }) }
							isInvalid={ coApplicantDetails.mobileNumber.isTouched && !coApplicantDetails.mobileNumber.isValid }
						/>

						<GenericFileInput
							label="Upload Aadhaar Card" required={ true } fileList={ aadhaarFiles } onChangeHandler={ handleAadhaarChange }
							fileType="coAadharImage" disabled={ !coApplicantAvailable || disableEdit } isVerified={ coApplicantDetails.aadhaarImage.isVerified }
							isInvalid={ coApplicantDetails.aadhaarImage.isTouched && (!coApplicantDetails.aadhaarImage.isVerified || aadhaarFiles?.length === 0) }
						/>
					</div>
				</div>
			</div>
		</Fragment>
	);
};