import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BsPlusCircleFill, BsSearch } from "react-icons/bs";
import {
  getBillLodgingDigital,
  getBillLodgingManual
} from "../../store/slices/lender";
import Loader from "../../layout/GenericLayout/Loader";
import BillLodgingManual from "./LenderTables/BillLogingTables/BillLodgingManual";
import BillLodgingDigital from "./LenderTables/BillLogingTables/BillLodgingDigital";
import {Helmet} from "react-helmet";

const LenderBillLodge = () => {


  const [search, setSearch] = useState("");
  const dispatch = useDispatch();

  // Tabs Switching
  const [activeTableData, setActiveTableData] = useState(1);
  const [activeTableRows, setActiveTableRows] = useState([]);

  // Redux API Response Data
  const ListOfBillsDigitalResponse = useSelector(
    (state) => state.buyer.billLodgingDigital
  );
  const ListOfBillsManualResponse = useSelector(
    (state) => state.buyer.billLodgingManual
  );

  // Table Data
  const [listOfBillsDigitalTableRows, setListOfBillsDigitalTableRows] =
    useState([]);

  const [
    listOfBillsDigitalTableMetaData,
    setListOfBillsDigitalTableMetaData,
  ] = useState();
  const [invoiceOrderManualTableRows, setinvoiceOrderManualTableRows] =
    useState([]);
  const [invoiceOrderManualTableMetaData, setinvoiceOrderManualTableMetaData] =
    useState();

  const onClickActiveTable = (value) => {
    setActiveTableData(value);
  };

  /* TABLE QUERY PARAMS Digital PO*/
  const [
    tableQueryParamsinvoiceOrderDigitalPO,
    setTableQueryParamsinvoiceOrderDigitalPO,
  ] = useState({
    tableOffset: 0,
    tableLimit: 5,
    tableSort: "",
    tableOrder: -1,
    currentPage: 0,
    search: "",
  });

  /* TABLE QUERY PARAMS Manual PO*/
  const [
    tableQueryParamsinvoiceOrderManualPO,
    setTableQueryParamsinvoiceOrderManualPO,
  ] = useState({
    tableOffset: 0,
    tableLimit: 5,
    tableSort: "invoiceId",
    tableOrder: -1,
    currentPage: 0,
    search: "",
  });

  // This is For Searching by ID
  const [searchBothPOByID, setSearchBothPOByID] = useState("");

  const handleOnChangeSearchBothPOByID = (event) => {
    setSearchBothPOByID(event.target.value);
  };

  useEffect(() => {
    if (activeTableData === 1) {
      if (searchBothPOByID.length > 1) {
        const data = `offset=${tableQueryParamsinvoiceOrderDigitalPO?.tableOffset}&limit=${tableQueryParamsinvoiceOrderDigitalPO?.tableLimit}&search=${searchBothPOByID}`;
        dispatch(getBillLodgingDigital(data));
      } else {
        const data = `offset=${tableQueryParamsinvoiceOrderDigitalPO?.tableOffset}&limit=${tableQueryParamsinvoiceOrderDigitalPO?.tableLimit}`;
        dispatch(getBillLodgingDigital(data));
      }
    }

    if (activeTableData === 2) {
      if (searchBothPOByID.length > 1) {
        const data = `offset=${tableQueryParamsinvoiceOrderManualPO?.tableOffset}&limit=${tableQueryParamsinvoiceOrderManualPO?.tableLimit}&search=${searchBothPOByID}`;
        dispatch(getBillLodgingManual(data));
      } else {
        const data = `offset=${tableQueryParamsinvoiceOrderManualPO?.tableOffset}&limit=${tableQueryParamsinvoiceOrderManualPO?.tableLimit}`;
        dispatch(getBillLodgingManual(data));
      }
    }
  }, [
    activeTableData,
    dispatch,
    tableQueryParamsinvoiceOrderDigitalPO,
    tableQueryParamsinvoiceOrderManualPO,
    searchBothPOByID,
  ]);

  useEffect(() => {
    console.log();
    if (ListOfBillsDigitalResponse?.data) {
      setListOfBillsDigitalTableRows(ListOfBillsDigitalResponse.data.items);
      setListOfBillsDigitalTableMetaData(
        ListOfBillsDigitalResponse.data.metadata
      );
    }
  }, [ListOfBillsDigitalResponse]);

  useEffect(() => {
    if (ListOfBillsManualResponse?.data) {
      setinvoiceOrderManualTableRows(ListOfBillsManualResponse.data.items);
      setinvoiceOrderManualTableMetaData(
        ListOfBillsManualResponse.data.metadata
      );
    }
  }, [ListOfBillsManualResponse]);

  // console.log("This is Rows: ",listOfBillsDigitalTableRows);
  // console.log("This is Meta Data: ", listOfBillsDigitalTableMetaData);
  // console.log("This is Manual PO: ", listOfBillsDigitalTableRows);

  return (
    <>
      <div className="p-0" style={{ overflowX: "hidden" }}>
      <Helmet>
                <title>Bill-Lodgement</title>
            </Helmet>
        <div className="bg-white admin-dashboard-card-container p-3">
          <div className="d-flex justify-content-between align-items-center flex-wrap px-2 gap-2 py-2">

            {
              activeTableData === 1 ?
                <span className="dashboard-content-title">
                  List of Bills (Digital)
                </span> :
                activeTableData === 2 ?
                  <span className="dashboard-content-title">
                    List of Bills (Manual)
                  </span> :
                  <span></span>
            }

            <div
              // onClick={toggleRMAddModal}
              className="d-flex align-items-center"
              type="button"
            >
              {/* <BsPlusCircleFill
                className="rm-plus-button"
              /> */}
            </div>
          </div>

          <div className="d-flex flex-column flex-sm-row align-items-center my-4">
            <div className="d-flex flex-column justify-content-center gap-3 col-12 col-sm-6">
              <label
                htmlFor="selectAnchor"
                className="anchor-and-lender-select-label"
              >
              </label>
              <div className="search-container d-flex align-items-center py-1 px-3 col-12 col-sm-8">
                <input
                  className="search-input-element d-flex flex-grow-1"
                  type="text"
                  placeholder="Search by Invoice Number"
                  onChange={handleOnChangeSearchBothPOByID}
                  value={searchBothPOByID}
                // onKeyDown={handleOnSubmitSearchBothPOByID}

                // value={search}
                />{" "}
                <BsSearch className="fs-5" />
              </div>
            </div>
          </div>
        </div>

        {/* The Table container starts here */}

        <div className="mt-3" style={{ overflowX: "hidden" }}>
          {/* This is For the Tab Switch */}
          <div className="d-flex px-0">
            <span
              className={`dashboard-table-title ${activeTableData !== 1 && "dashboard-table-title-hover"
                } py-2 px-3 ${activeTableData === 1 && "active-table-data placeholder-wave"
                }`}
              onClick={() => onClickActiveTable(1)}
            >
              Digital
            </span>
            <span
              className={`dashboard-table-title ${activeTableData !== 2 && "dashboard-table-title-hover"
                } py-2 px-3 ${activeTableData === 2 && "active-table-data placeholder-wave"
                }`}
              onClick={() => onClickActiveTable(2)}
            >
              Manual
            </span>
          </div>
          {/* END of Tab Switch */}

          <div>
            {activeTableData === 1 && (
              <div
                className="mb-5"
                style={{
                  overflowX: "hidden",
                  boxSizing: "border-box",
                }}
              >
                <BillLodgingDigital
                  className="buyer-table"
                  tableHeader={[
                    { name: "CIF", sortValue: "" },
                    { name: "Anchor", sortValue: "" },
                    { name: "Invoice Amount", sortValue: "" },
                    { name: "Invoice Number", sortValue: "" },
                    { name: "Invoice Status", sortValue: "" },
                    { name: "Compliance Check", sortValue: "" },
                    { name: "Counter Party Confirmation", sortValue: "" },
                    { name: "Bill Reference Number", sortValue: "" },
                    { name: "Status", sortValue: "" },
                  ]}
                  tableRows={listOfBillsDigitalTableRows}
                  materialRequestMetaData={listOfBillsDigitalTableMetaData}
                  tableQueryParams={tableQueryParamsinvoiceOrderDigitalPO}
                  setTableQueryParams={setTableQueryParamsinvoiceOrderDigitalPO}
                  isLoading={ListOfBillsDigitalResponse?.isLoading}
                />
              </div>
            )}
            {activeTableData === 2 && (
              <div
                className=""
                style={{
                  overflowX: "hidden",
                  boxSizing: "border-box",
                }}
              >
                <BillLodgingManual
                  className="buyer-table"
                  tableHeader={[
                    { name: "Invoice Amount", sortValue: "" },
                    { name: "Invoice Number", sortValue: "" },
                    { name: "Compliance Check", sortValue: "" },
                    { name: "Counter Party Confirmation", sortValue: "" },
                    { name: "Bill Reference Number", sortValue: "" },
                    { name: "Status", sortValue: "" },
                  ]}
                  tableRows={invoiceOrderManualTableRows}
                  materialRequestMetaData={invoiceOrderManualTableMetaData}
                  tableQueryParams={tableQueryParamsinvoiceOrderManualPO}
                  setTableQueryParams={setTableQueryParamsinvoiceOrderManualPO}
                  isLoading={ListOfBillsManualResponse?.isLoading}
                  setActiveTableData={setActiveTableData}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default LenderBillLodge;
