import React, { useEffect, useRef, useState } from "react";
import { BsPlusCircleFill, BsSearch, BsEyeFill } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { InputValidator } from "../../layout/GenericLayout/InputValidator";
import ReusableModal from "../../layout/GenericLayout/ReusableModal";
import { toastMessage } from "../../layout/GenericLayout/ToastMessage";
import {Helmet} from "react-helmet";
import {
  deleteInvoiceFile,
  fetchManualInvoiceData,
  getApprovedRetailerList,
  getBulkInvoiceTable,
  getManualPO,
  submitEditManualInvoice,
  updateAddBatchManualFilesUpload,
} from "../../store/slices/anchor";
import ManualInvoiceTable from "./AnchorTables/AnchorInvoiceTables.js/ManualBulkInvoiceTable";

/* MODALS OR CHILDREN COMPONENTS */
const CreateManualInvoiceModal = (props) => {
  const dispatch = useDispatch();
  const [manualInvoiceFields, setManualInvoiceFields] = useState({ po_id: "", });
  const [fileUploads, setFileUploads] = useState({});
  const [isError, setIsError] = useState({});

  const [ poData, setPoData ] = useState({});

  const $dueDateRef = useRef();

  const userRetailerById = useSelector(
    (state) => state.anchor.approvedRetailer
  );

  const editInvoiceState = useSelector(state => state?.anchor?.submitEditManualInvoice);
  const submitInvoiceState = useSelector(state => state?.anchor?.uploadBatchInvoiceData);

  const manualApprovedPOData = useSelector(state => state.anchor.manualPOData);

  useEffect(() => {
    if (props.id) {
      setManualInvoiceFields(prev => ({ ...prev, invoice_number: props?.externalId, po_id: "" }));

      dispatch(fetchManualInvoiceData(props?.id))
        .unwrap()
        .then(data => {
          console.log("invoice for id", props?.id, data);

          if (data !== undefined && typeof data === "object" && 'items' in data) {
            const { items } = data;

            setManualInvoiceFields(previous => ({
              ...previous,
              invoice_date: items?.invoiceDate,
              invoice_amount: items?.invoiceAmount,
              due_date: items?.dueDate,
              buyer_id: items?.buyerId,
              uploadedInvoice: items?.invoiceFileUrl,
            }));
          }
        })
        .catch(err => {
          console.error("error occurred", err?.message);
        })
    }
  }, [props?.id, dispatch]);

  useEffect(() => {
    dispatch(getApprovedRetailerList());
    dispatch(getManualPO());
  }, [dispatch]);

  const handleChangeInvoiceAmount = (event) => {
    if (event?.target?.value === "") {
      setManualInvoiceFields((prev) => ({ ...prev, invoice_amount: "" }));
      return;
    }

    if (isNaN(event.target.value)) {
      return;
    }

    setManualInvoiceFields((prev) => ({
      ...prev,
      invoice_amount: event.target.value?.toString(),
    }));
  };

  const handleBlurInvoiceAmount = (event) => {
    const value = parseInt(event?.target.value);

    if (isNaN(value) || value <= 0) {
      setManualInvoiceFields((prev) => ({ ...prev, invoice_amount: "" }));
      setIsError((prev) => ({
        ...prev,
        invoiceAmount: "Amount should be greater than 0",
      }));
    } else {
      setIsError((prev) => ({ ...prev, invoiceAmount: "" }));
    }
  };

  const onChangeAddManualInvoice = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    if (name === "po_id") {
      for (let item of manualApprovedPOData.data.items) {
        if (value === item.poId) {
          setPoData(item);
          setManualInvoiceFields(prev => ({ ...prev, [name]: value, buyer_id: item?.buyerId }))
          break;
        }
      }
      return;
    }

    console.log("on change : ", name, value);
    setManualInvoiceFields((prev) => ({ ...prev, [name]: value }));
  };
  const onChangeFileUpload = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const file = event.target.files[0];

    console.log(file?.size / (1024 * 1024));

    if (file?.size / (1024 * 1024) > 5) {
      toastMessage("File is too large", "error");
    } else {
      setFileUploads((prev) => ({ ...prev, [name]: { file, value }, uploadedInvoice: undefined }));
    }
  };

  const onSubmitCreateManualInvoice = (event) => {
    event.preventDefault();

    const data = {
      ...manualInvoiceFields,
      billOfExchange: "",
      lorryReceipt:
        fileUploads?.lorryReceipt?.file === undefined
          ? ""
          : fileUploads?.lorryReceipt?.file,
      invoice: fileUploads?.invoice?.file,
      invoice_excel: fileUploads?.invoice_excel?.file,
    };

    const body = new FormData();
    Object.keys(data).forEach((key) => body.append(key, data[key]));

    const isNoneError =
      Object.keys(isError).length === 0
        ? false
        : Object.values(isError).every(
          (eachError) => eachError === null || eachError === ""
        );


        if(!data?.po_id || !data?.buyer_id){
          toastMessage("Please select the INV Reference Number ID", "error");
          return;
        }else if(!data?.invoice_date){
          toastMessage("Please select the invoice date", "error");
          return;
        }else if(!data?.due_date){
          toastMessage("Please select the due date", "error");
          return;
        }
    if (isNoneError) {

      if (!manualInvoiceFields.uploadedInvoice && !fileUploads?.invoice) {
        toastMessage("Please upload Batch E-Invoice File", "error");
        return;
      }

      if (props?.id) {
        dispatch(submitEditManualInvoice(body))
          .unwrap()
          .then((response) => {
            toastMessage(response?.message, "success");
            props?.onRefreshTable();
            props?.toggleBOEModal();
          })
          .catch((error) => {
            toastMessage(error.message, "error");
          });

        return;
      }

      dispatch(updateAddBatchManualFilesUpload(body))
        .unwrap()
        .then((response) => {
          toastMessage(response?.message, "success");
          props?.onRefreshTable();
          props?.toggleBOEModal();
        })
        .catch((error) => {
          toastMessage(error.message, "error");
        });
    } else {
      toastMessage("Please edit fields to update", "error");
    }
  };

  const onBlurInputFeilds = (event) => {
    const name = event.target.id;
    const value = event.target.value;

    console.log(name, value);

    if (name === "batch_e_Invoice" || name === "eWayBill" || name === "invoice_excel") {
      if (event.target.files[0]?.size / (1024 * 1024) > 5) {
        setIsError((prev) => ({
          ...prev,
          [name]: "File is Too large, should be less than 5 Mb",
        }));
      } else {
        const validator = () => {
          return {
            regex: /^.+$/,
            message: "Field is required",
          };
        };

        if (name === "invoice_date" && !manualInvoiceFields.invoice_date) {
          setManualInvoiceFields((prev) => ({ ...prev, due_date: "" }));
          $dueDateRef.current.value = null;
        }

        const errorMessgae = InputValidator({
          regex: validator().regex,
          value,
          message: validator().message,
        });

        setIsError((prev) => ({
          ...prev,
          [name]: errorMessgae,
        }));
      }
    } else {
      const validator = () => {
        return {
          regex: /^.+$/,
          message: "Field is required",
        };
      };

      if (name === "invoice_date" && !manualInvoiceFields.invoice_date) {
        setManualInvoiceFields((prev) => ({ ...prev, due_date: "" }));
        $dueDateRef.current.value = null;
      }

      const errorMessgae = InputValidator({
        regex: validator().regex,
        value,
        message: validator().message,
      });

      setIsError((prev) => ({
        ...prev,
        [name]: errorMessgae,
      }));
    }
  };

  const handleDeleteInvoiceFile = () => {

    console.log("manualInvoiceFields", manualInvoiceFields);

    const body = {
      sellerId: sessionStorage.getItem("customerId"),
      invoiceFileUrl: manualInvoiceFields.uploadedInvoice,
      invoiceId: props?.id,
    };

    dispatch(deleteInvoiceFile(body))
      .unwrap()
      .then(data => {
        toastMessage(data?.message, "success");
        setManualInvoiceFields(prev => ({ ...prev, uploadedInvoice: undefined }));
      })
      .catch(err => {
        console.error("error delete invoice file", err.message);
        toastMessage(err?.message, "error");
      });

  };

  return (
    <form onSubmit={onSubmitCreateManualInvoice} className="">
      <span className="fs-4 fw-semibold">{props?.id ? "Edit " : ""}Batch Manual Invoice</span>
      <div className="details-container container px-5 py-3">
        <div className="row gy-md-4 gy-2">

          <div className="kyc-fields-container col-12 col-md-6">
            <label
              htmlFor="INV_Reference_id"
              className="kyc-label-text form-label add-star"
            >
              INV Reference Number ID
            </label>
            <select id="INV_Reference_id" className={`form-select ${isError?.INV_Reference_id && "invalid-input"}`} value={manualInvoiceFields.po_id} onChange={onChangeAddManualInvoice} onBlur={onBlurInputFeilds} name="po_id">
              <option value="" disabled>Select INV Reference Number Id</option>
              {
                manualApprovedPOData.data?.items?.map((element, index) => (
                  <option value={element?.poId} key={element?.buyerId}>{element?.poId}</option>
                ))
              }
            </select>
            <span className="text-danger px-1">
                {isError?.INV_Reference_id}
              </span>
          </div>
          {
            manualInvoiceFields.po_id !== "" &&
            <>
              {
                [{label: 'INV Reference Amount', key: "totalAmount"}, {label: 'INV Reference Date', key: "poDate"}].map((element, index) => (
                  <div key={element} className="kyc-fields-container col-12 col-md-6">
                    <label
                      htmlFor={element.key}
                      className="kyc-label-text form-label add-star"
                    >
                      {element.label}
                    </label>
                    <input
                      id={element.key}
                      name={element.key}
                      type="text"
                      className={`kyc-input-field form-control  ${ isError?.invoiceDate && "invalid-input" }`}
                      value={poData[element.key]}
                      disabled={true}
                    />
                  </div>
                ))
              }
              <div className="kyc-fields-container col-12  col-md-4">
                <label htmlFor="name" className="kyc-label-text form-label">
                  INV Reference File
                </label>
                <a
                  href={poData?.poFileUrl} target="_blank" rel="noreferrer"
                  className="d-flex justify-content-center align-items-center gap-3 list-group-item list-group-item-action list-group-item-primary w-100 py-2 rounded fw-semibold"
                >
                  View File <BsEyeFill className="fs-4" />{" "}
                </a>
              </div>
            </>
          }

          <div className="kyc-fields-container col-12  col-md-6">
            <label
              htmlFor="batch_e_Invoice"
              className="kyc-label-text form-label add-star"
            >
              Batch E-Invoice (File should be below 5 MB, pdf)
            </label>
            <input
              onChange={onChangeFileUpload}
              name="invoice"
              id="batch_e_Invoice"
              type="file"
              className={`kyc-input-field form-control   ${isError?.batch_e_Invoice && "invalid-input"
                }`}
              onBlur={onBlurInputFeilds}
              accept=".pdf"
              value={manualInvoiceFields?.invoice}
            />
            <span>
              {
                manualInvoiceFields?.uploadedInvoice &&
                <p className="p-2" title={manualInvoiceFields?.uploadedInvoice}>
                  <a href={manualInvoiceFields?.uploadedInvoice} target="_blank" rel="noreferrer">
                    {manualInvoiceFields?.uploadedInvoice?.slice(0, 30)}...
                  </a>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span>
                    <MdDelete onClick={handleDeleteInvoiceFile} className="fs-4 text-danger" role="button" />
                  </span>
                </p>
              }
            </span>
            <span className="text-danger px-1">{isError?.batch_e_Invoice}</span>
          </div>
          
          <div className="kyc-fields-container col-12  col-md-6">
            <label
              htmlFor="batch_e_invoice_excel"
              className="kyc-label-text form-label "
            >
              Batch E-Invoice Excel List (File should be below 5 MB, xls/xlsx/csv)
            </label>
            <input
              onChange={onChangeFileUpload}
              name="invoice_excel"
              id="batch_e_invoice_excel"
              type="file"
              className={`kyc-input-field form-control   ${isError?.eInvoice && "invalid-input"}`}
              // onBlur={onBlurInputFeilds}
              accept=".csv,.xls,.xlsx"
              value={manualInvoiceFields?.invoice_excel}
            />
            <span>
              {
                manualInvoiceFields?.uploadedInvoice &&
                <p className="p-2" title={manualInvoiceFields?.uploadedInvoice}>
                  <a href={manualInvoiceFields?.uploadedInvoice} target="_blank" rel="noreferrer">
                    {manualInvoiceFields?.uploadedInvoice?.slice(0, 30)}...
                  </a>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span>
                    <MdDelete onClick={handleDeleteInvoiceFile} className="fs-4 text-danger" role="button" />
                  </span>
                </p>
              }
            </span>
            <span className="text-danger px-1">{isError?.invoice_excel}</span>
          </div>

          <div className="kyc-fields-container col-12  col-md-6">
            <label htmlFor="batch_Invoice_Date" className="kyc-label-text form-label">
              Batch Invoice Date <span className="text-danger">*</span>
            </label>
            <input
              onChange={onChangeAddManualInvoice}
              id="batch_Invoice_Date"
              name="invoice_date"
              type="date"
              className={`kyc-input-field form-control  ${isError?.invoiceDate && "invalid-input"
                }`}
              onBlur={onBlurInputFeilds}
              value={manualInvoiceFields?.invoice_date}
            />
            <span className="text-danger px-1">{isError?.invoiceDate}</span>
          </div>

          <div className="kyc-fields-container col-12  col-md-6">
            <label
              htmlFor="batch_Invoice_Number"
              className="kyc-label-text form-label"
            >
              Batch Invoice Number <span className="text-danger">*</span>
            </label>
            <input
              name="invoice_number"
              onChange={onChangeAddManualInvoice}
              id="batch_Invoice_Number"
              type="text"
              className={`kyc-input-field form-control  ${isError?.batch_Invoice_Number && "invalid-input"
                }`}
              disabled={props?.id}
              onBlur={onBlurInputFeilds}
              value={manualInvoiceFields?.invoice_number}
            />
            <span className="text-danger px-1">{isError?.batch_Invoice_Number}</span>
          </div>

          <div className="kyc-fields-container col-12  col-md-6">
            <label
              htmlFor="batch_Invoice_Amount"
              className="kyc-label-text form-label"
            >
              Batch Invoice Amount <span className="text-danger">*</span>
            </label>
            <input
              name="invoice_amount"
              onChange={handleChangeInvoiceAmount}
              id="batch_Invoice_Amount"
              type="number"
              min={0}
              className={`kyc-input-field form-control  ${isError?.invoiceAmount && "invalid-input"
                }`}
              onBlur={handleBlurInvoiceAmount}
              value={manualInvoiceFields?.invoice_amount}
            />
            <span className="text-danger px-1">{isError?.invoiceAmount}</span>
          </div>

          <div className="kyc-fields-container col-12  col-md-6">
            <label htmlFor="batch_Due_Date" className="kyc-label-text form-label">
              Batch Due Date
            </label>
            <input
              onChange={onChangeAddManualInvoice}
              name="due_date"
              id="batch_Due_Date"
              type="date"
              className={`kyc-input-field form-control  ${isError?.dueDate && "invalid-input"
                }`}
              onBlur={onBlurInputFeilds}
              value={manualInvoiceFields?.due_date}
              disabled={!manualInvoiceFields?.invoice_date}
              min={manualInvoiceFields.invoice_date}
              ref={$dueDateRef}
            />
            <span className="text-danger px-1">{isError?.dueDate}</span>
          </div>

          {sessionStorage.getItem("productCategory") !==
            "Purchase Invoice Discounting" && (
              <div className="kyc-fields-container col-12  col-md-6">
                <label
                  htmlFor="select_Buyer_Name"
                  className="kyc-label-text form-label"
                >
                  Counter Party Name <span className="text-danger">*</span>
                </label>
                <select
                  onChange={onChangeAddManualInvoice}
                  name="buyer_id"
                  className={`kyc-input-field form-select  ${isError?.selectBuyerName && "invalid-input"
                    }`}
                  onBlur={onBlurInputFeilds}
                  value={manualInvoiceFields?.buyer_id || ""}
                  id="select_Buyer_Name"
                  disabled={true}
                >
                  <option value="" disabled defaultValue>
                    Open this select menu
                  </option>
                  {userRetailerById.data &&
                    userRetailerById.data.success.map((eachBuyer) => (
                      <option
                        key={eachBuyer.retailerId}
                        value={eachBuyer.retailerId}
                      >
                        {eachBuyer.name}
                      </option>
                    ))}
                </select>
                <span className="text-danger px-1">
                  {isError?.selectBuyerName}
                </span>
              </div>
            )}

          <div className="kyc-fields-container col-12  col-md-6">
            <span className="text-danger px-1">
              {isError?.selectPurchaseIdOrder}
            </span>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end m-2">
        <button type="button" onClick={props?.toggleBOEModal} className="btn btn-outline-secondary mx-2">
          Cancel
        </button>
        <button type="submit" className="btn btn-success">
          {props?.id ?
            <>
              {
                editInvoiceState?.isLoading ?
                  <span className="loader mx-auto"></span> :
                  "Edit"
              }
            </>
            :
            <>
              {
                submitInvoiceState?.isLoading ?
                  <span className="loader mx-auto"></span> :
                  "Submit"
              }
            </>
          }
        </button>
      </div>
    </form>
  );
};

/* PARENT COMPONENT */
const AnchorBulkInvoice = () => {
  const dispatch = useDispatch();

  /* USESELECT HOOKS */
  const manualInvoiceTableData = useSelector(
    (state) => state.anchor.bulkInvoiceTableData
  );
  /* USESTATES */

  const [invoiceMetaData, setInvoiceMetaData] = useState(null);
  const [invoiceTableRows, setInvoiceTableRows] = useState([]);
  const isHovered = false;
  
  const [editId, setEditId] = useState("");
  const [externalInvoiceId, setExternalInvoiceId] = useState("");

  const [showCreateManualInvoiceModal, setShowCreateManualInvoiceModal] =
    useState(false);

  /* TABLE QUERY PARAMS */
  const [tableQueryParams, setTableQueryParams] = useState({
    tableOffset: 0,
    tableLimit: 5,
    tableSort: "invoiceDate",
    tableOrder: -1,
    currentPage: 1,
    search:""
  });

  /* EVENT LISTENERS */
  const toggleCreateManualInvoiceModal = () => {
    setShowCreateManualInvoiceModal((prev) => {
      if (prev === true) {
        setEditId("");
        setExternalInvoiceId("");
      }

      return !prev;
    });
  };

  useEffect(() => {
    dispatch(getBulkInvoiceTable({tableQueryParams}))
      .unwrap()
      .then(data => {
        console.log("manual invoice", data);
        setInvoiceTableRows(data?.items);
        setInvoiceMetaData(data?.metadata);
      })
      .catch(err => {
        console.log("error manual invoice", err?.message);
      });
  }, [ dispatch, tableQueryParams, ]);

  useEffect(() => {
    if (manualInvoiceTableData.data) {
      setInvoiceTableRows(manualInvoiceTableData.data.items);
      setInvoiceMetaData(manualInvoiceTableData.data.metadata);
    }
  }, [manualInvoiceTableData]);

  const onRefreshTable = () => {
    dispatch(
      getBulkInvoiceTable({
        tableQueryParams,
      })
    );
  };

  const onChangeSearch = (event) => {
    // setSearchInvoiceValue((prev, ))
    // console.log(event.target.value)
    setTableQueryParams((prev)=>({
      ...prev,
      tableOffset:0,
      search: event.target.value,
    }));
    // const tabQuerySearch = {
    //   ...tableQueryParams,
    //   tableOffset: 0,
    //   tableLimit: 5,
    //   search: event.target.value,
    // };

    // dispatch(
    //   getBulkInvoiceTable({
    //     tabQuerySearch,
    //   })
    // );
  };

  const handleEditManualInvoice = (id, externalInvoiceId) => {
    toggleCreateManualInvoiceModal();

    setEditId(id);
    setExternalInvoiceId(externalInvoiceId)
  }

  return (
    <>
      {showCreateManualInvoiceModal && (
        <ReusableModal
          showModal={showCreateManualInvoiceModal}
          toggleModal={toggleCreateManualInvoiceModal}
          modalContent={
            <CreateManualInvoiceModal
              // activeBOEData={activeBOEData}
              toggleBOEModal={toggleCreateManualInvoiceModal}
              onRefreshTable={onRefreshTable}
              id={editId}
              externalId={externalInvoiceId}
            />
          }
          modalSizeClass="modal-lg"
        />
      )}

      <div className="d-flex flex-column gap-4" style={{ overflowY: "auto" }}>
        <Helmet>
            <title>Batch Invoice | Anchor</title>
        </Helmet>
        <div className="bg-white admin-dashboard-card-container p-3">
          <div className="d-flex justify-content-between align-items-center flex-wrap px-2 gap-2 py-2">
            <span className="dashboard-content-title">List of Batch Invoices</span>
            <div
              onClick={toggleCreateManualInvoiceModal}
              className="d-flex align-items-center"
              type="button"
            >
              <div className={`rm-add-button ${isHovered ? "show" : ""}`}>
                ADD
              </div>

              <button
                id="add_Batch_Invoice"
                className="btn mx-3"
                style={{
                  border: "1px solid #6378d4",
                  borderRadius: "10px",
                }}
              >
                {" "}
                Add Batch Invoice &nbsp;
                <BsPlusCircleFill className="rm-light-plus-button" />{" "}
              </button>
            </div>
          </div>

          <div className="d-flex flex-column flex-sm-row align-items-center mt-2 mb-1">
            <div className="d-flex flex-column justify-content-center gap-3 col-12 col-sm-6">
              <div className="search-container d-flex align-items-center py-1 px-3 col-12 col-sm-8">
                <input
                  className="search-input-element d-flex flex-grow-1"
                  type="text"
                  placeholder="Search with Batch Invoice No."
                  onChange={onChangeSearch}
                  value={tableQueryParams?.search}
                />{" "}
                <BsSearch className="fs-5" />
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex flex-column">

          <div>
            <ManualInvoiceTable
              className="admin-anchor-manual-invoice-table"
              tableHeader={
                sessionStorage.getItem("productCategory") !==
                  "Purchase Invoice Discounting"
                  ? [
                      { name: "Counter Party", sortValue: "" },
                      { name: "Batch Date", sortValue: "" },
                      { name: "Batch Invoice Number", sortValue: "" },
                      { name: "Total Amount (₹)	", sortValue: "" },
                      { name: "Due Date", sortValue: "" },
                      { name: "Bulk Excel File", sortValue: "" },
                      { name: "Other Document(.zip)", sortValue: "" },
                      { name: "Action", sortValue: "" },
                      { name: "Status", sortValue: "" },
                    ]
                    : [
                      { name: "Batch Date", sortValue: "" },
                      { name: "Batch Invoice Number", sortValue: "" },
                      { name: "PO Number", sortValue: "" },
                      { name: "Total Amount (₹)	", sortValue: "" },
                      { name: "Due Date", sortValue: "" },
                      { name: "Bulk Excel File", sortValue: "" },
                      { name: "Other Document(.zip)", sortValue: "" },
                      { name: "Action", sortValue: "" },
                    ]
              }
              tableRows={invoiceTableRows}
              invoiceMetaData={invoiceMetaData}
              tableQueryParams={tableQueryParams}
              setTableQueryParams={setTableQueryParams}
              isLoading={manualInvoiceTableData.isLoading}
              onRefreshTable={onRefreshTable}
              handleEditManualInvoice={handleEditManualInvoice}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AnchorBulkInvoice;
