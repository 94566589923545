import { Fragment, useContext, useEffect, useState, } from "react";


import { GenericInputField } from "../../../../GenericComponent/Input/Input";
import { CorporateKYCCoApplicantDetails } from "../coApplicantDetails";
import { CorporateKycContext } from "../corporateKycContext";
import { CorporateKycActions } from "../actionTypes";
import { GenericFileInput } from "../../../../GenericComponent/FileInput/FileInput";
import { useDispatch } from "react-redux";
import { updatePANVerification } from "../../../../store/slices/generic";

export const CorporateKYCPersonalDetails = (props) => {

	// ---------------------------> States and Reducers
	const [ aadhaarFiles, setAadhaarFiles ] = useState([]);
	const [ profilePics, setProfilePics ] = useState([]);
	const [ aadhaarBackFiles, setAadhaarBackFiles ] = useState([]);
	
	// ---------------------------> HOOKS
	const { personalDetails, personalDetailsDispatch, files, emailId, uploadFiles } = useContext(CorporateKycContext);
	const dispatch = useDispatch();

	// --------> useEffects 
	useEffect(() => {
		if (files?.length > 0 && files?.[0]?.documents && Array.isArray(files[0].documents)) {
			const aadhaarTemp = [], profilePicsTemp = [], aadhaarBackTemp = [];
			for (let item of files[0].documents) {
				if (item?.adharImage !== undefined) {
					aadhaarTemp.push(item);
				}
				else if (item?.userProfilePic !== undefined) {
					profilePicsTemp.push(item);
				}
				else if (item?.aadharImageBack !== undefined) {
					aadhaarBackTemp.push(item);
				}
			}
			personalDetailsDispatch({ type: CorporateKycActions.PERSONAL_DETAILS.AADHAAR_UPDATE_API, payload: aadhaarTemp });

			personalDetailsDispatch({ type: CorporateKycActions.PERSONAL_DETAILS.PROFILE_PIC_UPDATE_API, payload: profilePicsTemp });

			personalDetailsDispatch({ type: CorporateKycActions.PERSONAL_DETAILS.AADHAAR_BACK_UPDATE_API, payload: aadhaarBackTemp });

			setAadhaarFiles(aadhaarTemp);
			setProfilePics(profilePicsTemp);
			setAadhaarBackFiles(aadhaarBackTemp);
		}
	}, [ files, personalDetailsDispatch ]);

	// -----------------------------------> Handlers and Functions

	/**
	 * @param { string } value 
	 */
	const handleNameChange = (value) => {
		personalDetailsDispatch({ type: CorporateKycActions.PERSONAL_DETAILS.CHANGE_NAME, payload: value });
	};

	/**
	 * @param { string } value 
	 */
	const handleNameBlur = () => {
		personalDetailsDispatch({ type: CorporateKycActions.PERSONAL_DETAILS.BLUR_NAME, });
	}

	/**
	 * @param {string} value 
	 */
	const handleDOBBlur = (value) => {
		personalDetailsDispatch({ type: CorporateKycActions.PERSONAL_DETAILS.BLUR_DOB, payload: value });
	};

	/**
	 * @param {string} value 
	 */
	const handleDOBChange = (value) => {
		personalDetailsDispatch({ type: CorporateKycActions.PERSONAL_DETAILS.CHANGE_DOB, payload: value });
	};

	const handlePanBlur = () => {
		if (personalDetails.panNo.value.length === 10) {
			const body = {
				panNumber: personalDetails.panNo.value,
				userid: emailId,
			};
	
			dispatch(updatePANVerification(body))
				.unwrap()
				.then(response => {				
					if (response?.status) {
						personalDetailsDispatch({ type: CorporateKycActions.CO_APPLICANT_DETAILS.BLUR_PAN, payload: true });
					}
				})
				.catch(error => {
					personalDetailsDispatch({ type: CorporateKycActions.CO_APPLICANT_DETAILS.BLUR_PAN, payload: false });
					console.log("pan blur error", error);
				});
		}
		personalDetailsDispatch({ type: CorporateKycActions.CO_APPLICANT_DETAILS.BLUR_PAN, payload: false });

	};

	/**
	 * @param { React.ChangeEvent<HTMLInputElement> } event 
	 */
	const handleAadhaarChange = (event) => {
		const formData = new FormData();

		console.log("event", event);

		formData.append("adharImage", event?.target?.files?.[0]);

		uploadFiles(formData, "adharImage");
	};

	/**
	 * @param { React.ChangeEvent<HTMLInputElement> } event 
	 */
	const handleProfilePicChange = (event) => {
		const formData = new FormData();

		formData.append("userProfilePic", event.target.files?.[0]);

		uploadFiles(formData, "userProfilePic");
	};

	return (
		<Fragment>
			<div className="container-with-heading d-flex flex-column container">
				<span className="kyc-content-title mb-3 row">Personal Details</span>
				<div className="details-container pb-0">
					<div className="row gy-md-4 gy-2">
						<GenericInputField
							label="Name"
							value={ personalDetails.name.value }
							isInvalid={ personalDetails.name.isTouched && !personalDetails.name.isValid }
							errorMessage={ personalDetails.name.value === "" ? "Name is required" : "Name should have at least 4 characters" }
							required={ true }
							onChangeHandler={ event => handleNameChange(event?.target?.value) }
							onBlurHandler={ _ => handleNameBlur() }
						/> 

						<GenericInputField
							label="Date of Birth (Should be at least 18 years)"
							value={ personalDetails.DOB.value }
							isInvalid={ personalDetails.DOB.isTouched && !personalDetails.DOB.isValid }
							errorMessage={ personalDetails.DOB.value === "" ? "DOB is required" : "Invalid DOB" }
							required={ true }
							type="date"
							onChangeHandler={ event => handleDOBChange(event.target.value) }
							onBlurHandler={ event => handleDOBBlur(event.target.value) }
						/>

						<GenericInputField
							label="PAN Number"
							value={ personalDetails.panNo.value }
							isInvalid={ (personalDetails.panNo.isTouched && !personalDetails.panNo.isValid) || (personalDetails.panNo.isTouched && !personalDetails.panNo.isVerified) }
							errorMessage={ personalDetails.panNo.value === "" ? "PAN Number is required" : "Invalid PAN Number" }
							required={ true }
							onChangeHandler={ event => personalDetailsDispatch({ type: CorporateKycActions.PERSONAL_DETAILS.CHANGE_PAN, payload: event.target.value }) }
							onBlurHandler={ () => handlePanBlur() }
							verified={ personalDetails.panNo.isVerified }
							successMessage="PAN Verified"
						/>

						<GenericInputField
							label="Mobile Number"
							value={ personalDetails.mobileNumber.value }
							isInvalid={ personalDetails.mobileNumber.isTouched && !personalDetails.mobileNumber.isValid }
							errorMessage={ personalDetails.mobileNumber.value === "" ? "Mobile Number is required" : "Invalid Mobile Number" }
							required={ true }
							onChangeHandler={ event => personalDetailsDispatch({ type: CorporateKycActions.PERSONAL_DETAILS.CHANGE_MOBILE, payload: event.target.value }) }
							onBlurHandler={ _ => personalDetailsDispatch({ type: CorporateKycActions.PERSONAL_DETAILS.BLUR_MOBILE, }) }
						/>

						<GenericFileInput
							label="User Profile Pic" required={ true } fileList={ profilePics }
							fileType="userProfilePic" isVerified={ personalDetails.aadhaarImage.isVerified }
							onChangeHandler={ handleProfilePicChange }
						/>

						<GenericFileInput
							label="Upload Front Aadhaar" required={ true } fileList={ aadhaarFiles }
							fileType="adharImage" onChangeHandler={ handleAadhaarChange }
							isVerified={ personalDetails.aadhaarImage.isVerified } isInvalid={ personalDetails.aadhaarImage.isTouched && !personalDetails.aadhaarImage.isVerified }
						/>
						
						<GenericFileInput
							label="Upload Back Aadhaar" required={ true } fileList={ aadhaarBackFiles }
							fileType="aadharImageBack" onChangeHandler={ handleAadhaarChange }
							isVerified={ personalDetails.aadhaarBackImage.isVerified } isInvalid={ personalDetails.aadhaarBackImage.isTouched && !personalDetails.aadhaarBackImage.isVerified }
						/>
					</div>
				</div>
				
				{/*===============================================
				*  CO-APPLICANT DETAILS
				* ==============================================*/}
				<CorporateKYCCoApplicantDetails />
			</div>
		</Fragment>
	);
};