import { useEffect, useState } from "react";
import { BsPlusCircleFill, BsSearch } from "react-icons/bs";
import Loader from "../../layout/GenericLayout/Loader";
import AdminRMDetailsTable from "./AdminTables/AdminRMDetailsTable";
import { useDispatch, useSelector } from "react-redux";
import {
  addRelationshipManager,
  getAllRelationshipManager,
} from "../../store/slices/admin";
import ReusableModal from "../../layout/GenericLayout/ReusableModal";
import {
  getIsEmailRegistered,
} from "../../store/slices/generic";
import { toastMessage } from "../../layout/GenericLayout/ToastMessage";
import { Helmet } from "react-helmet";
import TablePagination from "../../layout/GenericLayout/TablePagination";
import { HiOutlineArrowNarrowDown } from "react-icons/hi";

/* MODALS OR CHILDREN COMPONENTS */
const AddRMDetailsModal = ({ toggleRMAddModal, onRefreshTable }) => {
  /* FUNCTION DECLARATIONS */
  const dispatch = useDispatch();

  const relationshipManagerAddData = useSelector(
    (state) => state.admin.relationshipManagerAddData
  );

  /* USE STATES */
  const [addRMDetailsFields, setAddRMDetailsFields] = useState({
    name: "",
    emailId: "",
    address: "",
    mobileNumber: "",
  });

  /* EVENT LISTENERS */
  const onChangeAddRMDetailsFields = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const pattern = new RegExp(event.target.pattern);
    console.log(pattern.test(value));
    if (pattern.test(value)) {
      setAddRMDetailsFields((prev) => ({ ...prev, [name]: value }));
    }
  };

  const onBlurAddRMDetailsFields = (event) => {
    const name = event.target.name;
    const validity = event.target.checkValidity();

    if (name === "emailId" && validity) {
      dispatch(getIsEmailRegistered(addRMDetailsFields.emailId))
        .unwrap()
        .then((response) => {
          toastMessage(response.success, "success");
        })
        .catch((error) => {
          toastMessage(error.message, "error");
        });
    }
  };

  const onSubmitRMAddDetails = (event) => {
    event.preventDefault();
    
    //check if the mobile number is valid
    const regex= /^(?!(9999999999|8888888888|7777777777|6666666666|9876543210)$)[6-9][0-9]{9}$/;
    if(!regex.test(addRMDetailsFields?.mobileNumber)){
      toastMessage("Invalid Mobile Number","error");
      return;
    }
    dispatch(addRelationshipManager(addRMDetailsFields))
      .unwrap()
      .then((response) => {
        toastMessage(response.success, "success");
        onRefreshTable();
        toggleRMAddModal();
      })
      .catch((error) => {
        toastMessage(error.message, "error");
      });
  };

  return (
    <>
      {relationshipManagerAddData.isLoading && (
        <div className="blur-loader-container">
          <Loader />
        </div>
      )}
      <form className="" onSubmit={onSubmitRMAddDetails}>
        <span className="fs-4 fw-semibold">Relationship Manager Details</span>
        <div className="details-container container mt-4">
          <div className="row gy-md-4 gy-2">
            <div className="kyc-fields-container col-12  col-md-6">
              <label htmlFor="name" className="kyc-label-text form-label">
                Name
              </label>
              <input
                required
                onChange={onChangeAddRMDetailsFields}
                id="name"
                name="name"
                pattern="^[a-zA-Z. ]*$"
                type="text"
                className="kyc-input-field form-control"
                value={addRMDetailsFields.name}
              />
            </div>

            <div className="kyc-fields-container col-12  col-md-6">
              <label htmlFor="pan" className="kyc-label-text form-label">
                Email ID
              </label>
              <input
                onBlur={onBlurAddRMDetailsFields}
                required
                onChange={onChangeAddRMDetailsFields}
                id="emailId"
                type="email"
                name="emailId"
                className="kyc-input-field form-control"
                value={addRMDetailsFields.emailId}
              />
            </div>

            <div className="kyc-fields-container col-12  col-md-6">
              <label htmlFor="email" className="kyc-label-text form-label">
                Address
              </label>
              <input
                required
                onChange={onChangeAddRMDetailsFields}
                id="address"
                type="text"
                name="address"
                className="kyc-input-field form-control"
                value={addRMDetailsFields.address}
              />
            </div>

            <div className="kyc-fields-container col-12  col-md-6">
              <label
                htmlFor="mobileNumber"
                className="kyc-label-text form-label"
              >
                Mobile Number
              </label>
              <input
                required
                onChange={onChangeAddRMDetailsFields}
                id="mobileNumber"
                type="number"
                name="mobileNumber"
                className="kyc-input-field form-control"
                value={addRMDetailsFields.mobileNumber}
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end gap-4 mt-5">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={toggleRMAddModal}
          >
            Back
          </button>
          <button type="submit" className="btn btn-success">
            Add
          </button>
        </div>
      </form>
    </>
  );
};

/* PARENT COMPONENT */
const AdminRelationshipManager = () => {
  /* FUNCTION DECLARATIONS */
  const dispatch = useDispatch();

  /* USE SELECTOR */
  const relationshipManagerData = useSelector(
    (state) => state.admin.relationshipManagerData
  );

  /* USE STATES */
  const [
    relationshipManagerDetailsTableRows,
    setRelationshipManagerDetailsTableRows,
  ] = useState([]);
  const [searchByRMName, setSearchByRMName] = useState("");
  const [
    getAllRelationshipManagerMetaData,
    setGetAllRelationshipManagerMetaData,
  ] = useState("");
  const [isHovered, setIsHovered] = useState(false);

  const [showRMAddModal, setShowRMAddModal] = useState(false);

  /* TABLE QUERY PARAMS */
  const [tableQueryParams, setTableQueryParams] = useState({
    tableOffset: 0,
    tableLimit: 5,
    tableSort: "",
    tableOrder: -1,
    currentPage: 1,
  });

  const toggleRMAddModal = () => {
    setShowRMAddModal((prev) => !prev);
  };

  /* USE EFFECTS */
  useEffect(() => {
    let tableQuery = `?offset=${tableQueryParams.tableOffset}&limit=${tableQueryParams?.tableLimit}`;
    // **For sort order needs to be there
    if (tableQueryParams?.tableSort.length > 1) {
      // console.log(tableQueryParams.tableSort)
      tableQuery = `?offset=${tableQueryParams.tableOffset}&limit=${tableQueryParams?.tableLimit}&sort=${tableQueryParams.tableSort}&order=${tableQueryParams?.tableOrder}`;
    }
    if (searchByRMName.length > 1) {
      //&search=mrID
      tableQuery = `?offset=0&limit=${tableQueryParams?.tableLimit}&search=${searchByRMName}`;
    }
    dispatch(getAllRelationshipManager(tableQuery))
      .unwrap()
      .then((response) => {
        setRelationshipManagerDetailsTableRows(response.items);
        setGetAllRelationshipManagerMetaData(response.metadata);
        console.log(
          "response : ",
          getAllRelationshipManagerMetaData?.paging?.total
        );
      })
      .catch((error) => {
        console.log("error : ", error);
      });
  }, [dispatch, tableQueryParams, searchByRMName]);

  //update a page
  const updatePage = (currentPage) => {
    const newOffSet = tableQueryParams.tableLimit * (currentPage - 1);
    setTableQueryParams((prev) => ({
      ...prev,
      tableOffset: newOffSet,
      currentPage,
    }));
  };

  //on click on view more
  const onClickViewMoreTableData = () => {
    let newLimit = tableQueryParams.tableLimit;
    if (newLimit === 5) {
      newLimit += 5;
    } else {
      newLimit -= 5;
    }
    setTableQueryParams((prev) => ({
      ...prev,
      tableLimit: newLimit,
    }));
  };

  /* EVENT LISTENERS */
  const handleMouseEnter = () => {
    // setIsHovered((prev) => !prev);
  };

  const handleMouseLeave = () => {
    // setIsHovered((prev) => !prev);
  };

  const onRefreshTable = () => {

    dispatch(getAllRelationshipManager())
      .unwrap()
      .then((response) => {
        setRelationshipManagerDetailsTableRows(response.items);
      })
      .catch((error) => {
        console.log("error : ", error);
      });
  };

  const onChangeSearchByRMName = (e) => {
    setSearchByRMName(e.target.value);
  };

  return (
    <>
      {/* MODAL CONTENT */}
      {showRMAddModal && (
        <ReusableModal
          showModal={showRMAddModal}
          toggleModal={toggleRMAddModal}
          modalContent={
            <AddRMDetailsModal
              toggleRMAddModal={toggleRMAddModal}
              onRefreshTable={onRefreshTable}
            />
          }
          modalSizeClass="modal-lg"
        />
      )}

      <div className="d-flex flex-column gap-4">
        <Helmet>
          <title>Relationship-Manager</title>
        </Helmet>
        <div className="bg-white admin-dashboard-card-container px-3 pt-3 d-flex flex-column gap-2 pb-3">
          <div className="d-flex justify-content-between align-items-center flex-wrap px-2 gap-2 py-2">
            <span className="dashboard-content-title">RM Details</span>

            <div
              onClick={toggleRMAddModal}
              className="d-flex align-items-center"
              // type="button"
            >
              <div
                className={`rm-add-button ${isHovered ? "show" : ""}`}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                ADD
              </div>
              <button
                className="btn mx-3"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                style={{
                  // background:'#fff',
                  border: "1px solid #6378d4",
                  borderRadius: "10px",
                }}
              >
                {" "}
                Onboard Relationship Manager &nbsp;
                <BsPlusCircleFill className="rm-plus-button" />{" "}
              </button>
              {/* <BsPlusCircleFill
                className="rm-plus-button"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              /> */}
            </div>
          </div>

          {/* This is the  Search Bar */}
          <div className="d-flex flex-column flex-sm-row align-items-center my-4">
            <div className="d-flex flex-column justify-content-center gap-3 col-12 col-sm-6">
              <div className="search-container d-flex align-items-center py-1 px-3 col-12 col-sm-8">
                <input
                  className="search-input-element d-flex flex-grow-1"
                  type="text"
                  placeholder="Search with RM Name"
                  onChange={onChangeSearchByRMName}
                  value={searchByRMName}
                />{" "}
                <BsSearch className="fs-5" />
              </div>
            </div>
          </div>
        </div>

        {relationshipManagerData.isLoading ? (
            <Loader className="my-5" />
          ) : (
            <AdminRMDetailsTable
              onRefreshTable={onRefreshTable}
              className="admin-channel-partner-data-table"
              tableHeader={[
                "RM Name",
                "Email ID",
                "Phone No.",
                "Address",
                "Action",
              ]}
              tableRows={relationshipManagerDetailsTableRows}
              getAllRelationshipManagerMetaData={getAllRelationshipManagerMetaData}
            />
          )}


          {!relationshipManagerData.isLoading && getAllRelationshipManagerMetaData?.paging?.total>5 && (
            <div className="d-flex justify-content-end px-2">
              <span
                onClick={onClickViewMoreTableData}
                className="d-flex align-items-center gap-1 my-3 view-more-text"
              >
                {tableQueryParams.tableLimit === 5
                ? tableQueryParams.tableOffset+tableQueryParams.tableLimit<getAllRelationshipManagerMetaData?.paging?.total?"View More ":""
                : tableQueryParams.tableOffset+tableQueryParams.tableLimit<getAllRelationshipManagerMetaData?.paging?.total?"View Less ":""}
                <HiOutlineArrowNarrowDown
                  className={`fs-5 ${
                    tableQueryParams.tableLimit === 5
                      ? tableQueryParams.tableOffset+tableQueryParams.tableLimit<getAllRelationshipManagerMetaData?.paging?.total?"down-arrow ":"d-none"
                      : tableQueryParams.tableOffset+tableQueryParams.tableLimit<getAllRelationshipManagerMetaData?.paging?.total?"up-arrow ":"d-none"}
                  `}
                />
              </span>
            </div>
          )}

        <div className="d-flex align-items-center gap-2 page-data-text-container mt-3 px-3 w-100">
          <TablePagination
            pageSize={tableQueryParams.tableLimit}
            onUpdatePage={updatePage}
            current={tableQueryParams.currentPage}
            total={getAllRelationshipManagerMetaData?.paging?.total}
          ></TablePagination>
        </div>
      </div>
    </>
  );
};

export default AdminRelationshipManager;
