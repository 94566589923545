import React from "react";
import BannerImg from "../../assests/images/new-banner3.png";
import { Outlet } from "react-router-dom";



const GenericLayout = () => {
  return (
    <>
      <div className="login-page-layout">
        <div className="login-banner">
          <img
            src={BannerImg}
            style={{ width: "100%", height: "100%", zIndex: 0 }}
            alt="ban img"
          />
         
          <div className="login-card-body lightSpeedIn">
            <Outlet/>
          </div>
        </div> 
      </div>
    </>
  );
};

export default GenericLayout;
