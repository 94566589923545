// ===========================================
// Below constants, help for autocompletion.
// ===========================================


export const additionalDetailsStateType = [
	{
		otherInfoFiveKey: '',
		otherInfoFiveValue: '',
		otherInfoFourKey: '',
		otherInfoFourValue: '',
		otherInfoOneKey: 'QWERT',
		otherInfoOneValue: 'ERTYU',
		otherInfoThreeKey: '',
		otherInfoThreeValue: '',
		otherInfoTwoKey: '42',
		otherInfoTwoValue: ''
	}
];

export const transactionDetailsStateType = [
	{
		data: [
			{ existingLimit: '', proposedLimit: '', totalLimit: '', trancheTenor: '', typeOfLimit: '12' }, { existingLimit: '', proposedLimit: '', totalLimit: '', trancheTenor: '', typeOfLimit: '' },
		],
		newData: [
			{ existingLimit: '', proposedLimit: '', totalLimit: '', trancheTenor: '', typeOfLimit: '12' }, { existingLimit: '', proposedLimit: '', totalLimit: '', trancheTenor: '', typeOfLimit: '' },
		],
		otherCharges: '0.00',
		newOtherCharges: '0.00',
		processingFees: '0.00',
		newProcessingFees: '0.00',
		rateOfInterest: '0.00',
		newRateOfInterest: '0.00',
		total: { existingLimit: 0, proposedLimit: 0, totalLimit: 0, trancheTenor: '' }
	}
];

export const groupExposureStateType = [
	{
		data: [
			{
				currentUtilization: '',
				entityName: '',
				exposure: '',
				rateOfInterest: '',
				securityValues: ''
			}, {
				currentUtilization: '',
				entityName: '',
				exposure: '',
				rateOfInterest: '',
				securityValues: ''
			},
		],
		total: { currentUtilization: 0, exposure: 0, rateOfInterest: 0, securityValues: 0 }
	}
];

export const applicantDetailsStateType = {
	corrospondenceAddress: 'NA',
	dateOfCommencementOfBusiness: 'Invalid date',
	emailId: 'ropociv558@cosaxu.com',
	noOfEmpShowroom: 0,
	noOfEmpWarehouse: 0,
	noOfOffices: 0,
	noOfWarehouse: 0,
	ownedRented: 'Owned',
	pan: 'AAATW4183C',
	registeredAddress: 'NA',
	telephoneNumber: '7256756858'
}

export const directorAndPartnerDetailsType = [
	{
		data: [
			{
				MobileNumber: '',
				address: '',
				dateOfBirth: '',
				designation: '',
				name: '',
				networth: '',
				officePhoneNumber: '',
				panCard: '',
				stack: '',
				status: ''
			}
		]
	}
]

export const dealershipStockStateType = [
	{
		data: [
			{ ageingOfStock: '<= 30days', totalStockPercentage: '', units: '', value: '' },
		],
		total: { totalStockPercentage: '', units: '', value: '' }
	}
];

export const debtorsStateType = [
	{
		data: [
			{ ageingOfDebtors: '< = 90 days', totalStockPercentage: '', units: '', value: '' }, { ageingOfDebtors: '> 90 days & < =180 days', totalStockPercentage: '', units: '', value: '' },
			{ ageingOfDebtors: '> 180 days', totalStockPercentage: '', units: '', value: '' }
		],
		total: { totalStockPercentage: '', units: '', value: '' }
	}
];

export const totalWorkingCapitalType = [
	{
		data: [
			{ bankName: '', limitExtended: '', limitType: '', outstanding: '' }, { bankName: '', limitExtended: '', limitType: '', outstanding: '' },
			{ bankName: '', limitExtended: '', limitType: '', outstanding: '' }
		],
		total: { Outstanding: '', limitExtended: '', limitType: '' }
	}
];

export const bureauScoreType = {
	dedupe: { equifax: { company: '', individual: '', report: 'Equifax Bureau', result: '755' } }
}

export const deviationAndApprovalStateType = [
	{
		data: [
			{
				actual: '',
				approvingAuthority: '',
				criteria: 'Total Business Vintage',
				deviation: '',
				policyNorm: ''
			}
		]
	}
];

export const recommendedByStateType = [
	{ comment: '', emp_id: '', name: '', org: '', recommendedDate: '' }
]
