import React, { useEffect, useState } from "react";
import ProformaInvoiceTable from "../Lender/LenderTables/AgreementTable"; 
import { BsPlusCircleFill, BsSearch } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { getAgreementList,postCreateAgreement,postUploadAgreement,postViewAgreement,postSendAgreement} from "../../store/slices/lender";
import { toastMessage } from "../../layout/GenericLayout/ToastMessage";
import ReusableModal from "../../layout/GenericLayout/ReusableModal"
import PdfView from "../../layout/GenericLayout/PdfView"
import {Helmet} from "react-helmet";

const CamModal = ({ pdfData }) => {
  return <PdfView pdfData={pdfData} />;
};

const LenderUnderWriter = () => {
  const dispatch = useDispatch();
  const proFormaInvoiceTableResponse = useSelector(
    (state) => state.lender.agreementList
  );
  const createSucc = useSelector((state) => state.lender.createAgreement)
 
  const [proformaInvoiceTableRowData, setProformaInvoiceTableRowData] = useState([]);
  const [proformInvoiceTableMetaData, setProformInvoiceTableMetaData] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [searchByProformaID, setSearchByProformaID] = useState("");
  const [viewModal,setViewModal] = useState(false)
  const [pdfData,setPdfData] = useState('')
  const [tableQueryParams, setTableQueryParams] = useState({
    tableOffset: 0,
    tableLimit: 10,
    tableSort: "proformaId",
    tableOrder: 1,
    currentPage: 0,
    search: "",
  });

  console.log(createSucc)

  useEffect(() => {
    dispatch(getAgreementList());
  }, [tableQueryParams, dispatch]);

  useEffect(() => {
    if (proFormaInvoiceTableResponse.data) {
      console.log(proFormaInvoiceTableResponse)
      setProformaInvoiceTableRowData(proFormaInvoiceTableResponse.data.success);
      setProformInvoiceTableMetaData(
        proFormaInvoiceTableResponse.data.metadata
      );
    }
  }, [proFormaInvoiceTableResponse]);

  const handleSearchByProformaIDOnchange = (e) => {
    setSearchByProformaID(e.target.value);
    //
  };

  useEffect(() => {
    if (searchByProformaID !== "") {
      setTableQueryParams((prev) => ({ ...prev, search: searchByProformaID }));
    }
  }, [searchByProformaID]);

  const handleSearchByProformaIDSubmit = (e) => {
    if (e.key === "Enter") {
      console.log("Thiis is search by ProfoID", searchByProformaID);
      setTableQueryParams((prev) => ({ ...prev, search: searchByProformaID }));
    }
  };

  console.log("The Data Itself ", proformaInvoiceTableRowData);
  console.log("The Meta Data ", proformInvoiceTableMetaData);

  const handleCreate=(value)=>{

      const data={
        lenderId: sessionStorage.getItem("customerId"),
        signerId: value
      }
      dispatch(postCreateAgreement(data))
      .unwrap()
      .then((response) => {
        toastMessage(response.success, "success");
      })
      .catch((error) => {
        console.log(error)
        toastMessage(error.message, "error");
      });
  }


  const handleUpload=(e)=>{
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("lenderId", sessionStorage.getItem("customerId"));
    formData.append("signerId", e.target.name);
    dispatch(postUploadAgreement(formData))
    .unwrap()
    .then((response) => {
      toastMessage(response.success, "success");
    })
    .catch((error) => {
      console.log(error)
      toastMessage(error.message, "error");
    });
  }


  const ToggleView=()=>{
    if(viewModal == false){
      setViewModal(!viewModal)
    }else{
      setViewModal(!viewModal)
      setPdfData('')
    }
  }

  const handleView=(value)=>{

    const data={
      lenderId: sessionStorage.getItem("customerId"),
      signerId: value
    }
    dispatch(postViewAgreement(data))
      .unwrap()
      .then((response) => {
        setPdfData(response.success.original_doc_link)
        ToggleView();
      })
      .catch((error) => {
        console.log(error)
        toastMessage(error.message, "error");
      });
  }

  const handleSend=(value)=>{

    const data={
      lenderId: sessionStorage.getItem("customerId"),
      signerId: value
    }
    dispatch(postSendAgreement(data))
      .unwrap()
      .then((response) => {
        toastMessage(response.success, "success");        
      })
      .catch((error) => {
        console.log(error)
        toastMessage(error.message, "error");
      });
  }


  return (
    <>
     <ReusableModal
        showModal={viewModal}
        toggleModal={ToggleView}
        modalContent={<CamModal pdfData={pdfData}/>}
        modalSizeClass="modal-xl"
      />
      <div className="d-flex flex-column gap-4">
      <Helmet>
                <title>Agreement</title>
            </Helmet>
        <div className="bg-white admin-dashboard-card-container p-3">
          <div className="d-flex justify-content-between align-items-center flex-wrap px-2 gap-2 py-2">
            <span className="dashboard-content-title">Agreement Details</span>
            
          </div>

          {/* <div className="d-flex flex-column flex-sm-row justify-content-end align-items-center my-4">
            <div className="d-flex flex-column justify-content-center gap-3 col-12 col-sm-3">
              
              <div className="search-container d-flex align-items-center py-1 px-3 col-12 col-sm-8">
                <input
                  className="search-input-element d-flex flex-grow-1"
                  type="text"
                  placeholder="Search with Customer ID"
                  value={searchByProformaID}
                  onChange={handleSearchByProformaIDOnchange}
                  // onKeyDown={handleSearchByProformaIDSubmit}

                  // value={search}
                />{" "}
                <BsSearch className="fs-5" />
              </div>
            </div>
          </div> */}
        </div>

        <div className="d-flex flex-column justify-content-between flex-wrap px-2 gap-2">
          <ProformaInvoiceTable
            className="lender-anchor-and-lender-mapped-table1 placeholder-wave0"
            tableHeader={[
              { name: "CustomerID", sortValue: "customerId" },
              { name: "Name", sortValue: "companyFullName" },
              { name: "Type", sortValue: "role" },
              { name: "EmailID", sortValue: "emailId" },
              { name: "Phone no", sortValue: "mobileNumber" },
              { name: "Score card", sortValue: "paymentTerms" },
              { name: "CAM", sortValue: "paymentTerms" },
              { name: "Create Agreement", sortValue: "paymentTerms" },
              { name: "Upload Agreement", sortValue: "paymentTerms" },
              { name: "Action", sortValue: "paymentTerms" },
              { name: "", sortValue: "paymentTerms" },
            ]}
            tableRows={proformaInvoiceTableRowData}
            materialRequestMetaData={proformInvoiceTableMetaData}
            tableQueryParams={tableQueryParams}
            setTableQueryParams={setTableQueryParams}
            isLoading={proFormaInvoiceTableResponse.isLoading}
            handleCreate={handleCreate}
            handleUpload={handleUpload}
            handleView={handleView}
            handleSend={handleSend}
          />
        </div>
      </div>
    </>
  );
};

export default LenderUnderWriter;
