import { configureStore } from "@reduxjs/toolkit";
import genericReducer from "./slices/generic";
import adminReducer from "./slices/admin";
import buyerReducer from "./slices/buyer";
import lenderReducer from "./slices/lender";
import anchorReducer from "./slices/anchor";
import channelReducer from "./slices/channel";
import corporateReducer from "./slices/corporate";
import underwriter from "./slices/underwriter";
import rmReducer from "./slices/rm";

const store = configureStore({
  reducer: {
    generic: genericReducer,
    admin: adminReducer,
    buyer: buyerReducer,
    anchor: anchorReducer,
    lender: lenderReducer,
    channel: channelReducer,
    corporate: corporateReducer,
    underwriter:underwriter,
    rm:rmReducer,
  },
});

export { store };
