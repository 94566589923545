export const AUDIT_ACTIONS = Object.freeze({
  "EDIT MANUAL INVOICE": "Edit Invoice Upload",
  "UPLOAD MANUAL INVOICE": "Upload Manual Invoice",
  "REJECT MANUAL INVOICE": "Reject manual invoice",
  "ACCEPT MANUAL INVOICE": "Accepted manual invoice",
  "APPLY FOR DISCOUNTING":"apply_for_discounting",
  "ERROR ACCEPT MANUAL INVOICE":
    "Error occurred during manual invoice acceptance",
  "LOGIN": "Login",
});

export const AUDIT_ACTION_FILTERS = Object.keys(AUDIT_ACTIONS).sort();
