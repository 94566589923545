import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../utilities/api";

const initialState = {
  totalRejectUsersData: {
    data: null,
    isLoading: false,
    error: null,
  },
  totalApprovedUsersData: {
    data: null,
    isLoading: false,
    error: null,
  },
  totalOnBoardUsersData: {
    data: null,
    isLoading: false,
    error: null,
  },

  channelAllUsersData: {
    data: null,
    isLoading: false,
    error: null,
  },

  updateKYCData: {
    data: null,
    isLoading: false,
    error: null,
  },
  
};

export const totalRejectUsers = createAsyncThunk(
  "get/totalRejectUsers",
  async () => {
    try {
      const url = `channelpartner/total_rejected_users`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.get(url, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

export const updateChannelKyc = createAsyncThunk(
  "post/updateChannelKYC",
  async (data) => {
    try {
      const url = `channelpartner/kyc`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.post(url, data, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

export const totalApprovedtUsers = createAsyncThunk(
  "get/totalApprovedtUsers",
  async () => {
    try {
      const url = `channelpartner/total_approved_users`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.get(url, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

export const totalOnboardUsers = createAsyncThunk(
  "get/totalOnboardUsers",
  async () => {
    try {
      const url = `channelpartner/total_onboarded_users`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.get(url, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

export const channelAllUsers = createAsyncThunk(
  "get/channelAllUsers",
  async () => {
    try {
      const url = `channelpartner/get_all_user_by_channel`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.get(url, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

const channelSlice = createSlice({
  name: "channel",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* TOTAL REJECTED DATA */
      .addCase(totalRejectUsers.pending, (state, action) => {
        state.totalRejectUsersData.isLoading = true;
      })
      .addCase(totalRejectUsers.fulfilled, (state, action) => {
        state.totalRejectUsersData.data = action.payload;
        state.totalRejectUsersData.isLoading = false;
      })
      .addCase(totalRejectUsers.rejected, (state, action) => {
        state.totalRejectUsersData.error = action.error.message;
        state.totalRejectUsersData.isLoading = false;
      })

      /* TOTAL APPROVED DATA */
      .addCase(totalApprovedtUsers.pending, (state, action) => {
        state.totalApprovedUsersData.isLoading = true;
      })
      .addCase(totalApprovedtUsers.fulfilled, (state, action) => {
        state.totalApprovedUsersData.data = action.payload;
        state.totalApprovedUsersData.isLoading = false;
      })
      .addCase(totalApprovedtUsers.rejected, (state, action) => {
        state.totalApprovedUsersData.error = action.error.message;
        state.totalApprovedUsersData.isLoading = false;
      })

      /* TOTAL ONBOARD DATA */
      .addCase(totalOnboardUsers.pending, (state, action) => {
        state.totalOnBoardUsersData.isLoading = true;
      })
      .addCase(totalOnboardUsers.fulfilled, (state, action) => {
        state.totalOnBoardUsersData.data = action.payload;
        state.totalOnBoardUsersData.isLoading = false;
      })
      .addCase(totalOnboardUsers.rejected, (state, action) => {
        state.totalOnBoardUsersData.error = action.error.message;
        state.totalOnBoardUsersData.isLoading = false;
      })

      /* GET ALL USERS */
      .addCase(channelAllUsers.pending, (state, action) => {
        state.channelAllUsersData.isLoading = true;
      })
      .addCase(channelAllUsers.fulfilled, (state, action) => {
        state.channelAllUsersData.data = action.payload;
        state.channelAllUsersData.isLoading = false;
      })
      .addCase(channelAllUsers.rejected, (state, action) => {
        state.channelAllUsersData.error = action.error.message;
        state.channelAllUsersData.isLoading = false;
      });
  },
});

export default channelSlice.reducer;
