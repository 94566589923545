import { Fragment, useEffect, useState } from "react";
import { GenericCamTableComponent } from "../../../../GenericComponent/Table/tableComponent";
import { useSelector } from "react-redux";

/**
 * @type { { value: string, rowspan?: number, colspan?: number }[][] }
 */

export const AdminCamMCA = () => {
    const [reduxState, setReduxState] = useState([]);
	// const [additionalDetails, setAdditionalDetails] = useState([])
	const additionalReduxState = useSelector(state=>state?.admin?.camByEmailIdData?.data?.data?.mca_data);

	useEffect(()=>{
		if(reduxState != additionalReduxState){
			setReduxState(additionalReduxState);
		}
	},[additionalReduxState])

	return (
		<Fragment>
			<div className="row">
                {reduxState.length!=0 && reduxState.map((val, indx)=> <div key={"mca-tab-"+indx} className="col-md-12">
                    <div className="mca-data-card">
                        <div className="mca-data-section" style={{fontSize:'1.1rem', fontWeight:'bold'}}>
                           <span>Business Overview</span>
                        </div>
                        
                        <div className="row">
                            <div className="col-md-10">
                                <table>
                                    <tr>
                                        <td >Trade Name</td>
                                        <td>{val?.business_overview?.natureOfBusiness?.tradeName}</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="col-md-2">
                                <button className="btn btn-info text-white" onClick={()=>{
                                    window.open(val?.mcaReportLink)
                                }}>Download</button>
                            </div>
                        </div>
                        
                        <div className="row mt-2">
                            <div className="col-md-12">
                                <span style={{fontSize:'1.1rem', fontWeight:'bold'}}>Entity Profile</span>
                            </div>
                            <div className="col-md-2 mt-2">
                                Address     :
                            </div>
                            <div className="col-md-4">
                                {val?.entity_profile?.address}
                            </div>
                            <div className="col-md-2 mt-2">
                                CIN  :
                            </div>
                            <div className="col-md-4">
                                {val?.entity_profile?.cin}
                            </div>
                            <div className="col-md-2 mt-2">
                                Email :
                            </div>
                            <div className="col-md-4">
                                {val?.entity_profile?.email}
                            </div>
                            <div className="col-md-2 mt-2">
                                Date of Incorporation :
                            </div>
                            <div className="col-md-4">
                                {val?.entity_profile?.date_of_Incorporation}
                            </div>
                            <div className="col-md-2 mt-2">
                                Mobile :
                            </div>
                            <div className="col-md-4">
                                {val?.entity_profile?.mobile}
                            </div>
                        </div>

                        {/* -----------------Sub-Section 1 -----------------  */}
                        <div className="mca-data-section mt-3" style={{fontSize:'1.1rem', fontWeight:'bold'}}>
                           <span>Balance Sheet Snapshot</span>
                        </div>
                        <div className="row mt-1">
                        <div className="col-md-2">
                                Financial Start Date  :
                            </div>
                            <div className="col-md-2">
                                {val?.business_overview?.balance_Sheet_Snapshot?.financialStartDate}
                            </div>
                            <div className="col-md-2">
                            </div>
                            <div className="col-md-2">
                                Financial End Date  :
                            </div>
                            <div className="col-md-2">
                                {val?.business_overview?.balance_Sheet_Snapshot?.financialEndDate}
                            </div>
                            
                        </div>

                        <table style={{width:'100%', marginTop:'1rem'}}>
                            <tr>
                                <td></td>
                                <td className="text-center">Current</td>
                                <td className="text-center">Previous</td>
                            </tr>
                            <tr>
                                <td>Total Assets</td>
                                <td className="numLeft">{val?.business_overview?.balance_Sheet_Snapshot?.current_totalAssets}</td>
                                <td className="numLeft">{val?.business_overview?.balance_Sheet_Snapshot?.previous_totalAssets}</td>
                            </tr>
                            <tr>
                                <td>Total Current Assets</td>
                                <td className="numLeft">{val?.business_overview?.balance_Sheet_Snapshot?.current_totalCurrentAssets}</td>
                                <td className="numLeft">{val?.business_overview?.balance_Sheet_Snapshot?.previous_totalCurrentAssets}</td>
                            </tr>
                            <tr>
                                <td>Total Current Liability</td>
                                <td className="numLeft">{val?.business_overview?.balance_Sheet_Snapshot?.current_totalCurrentLiability}</td>
                                <td className="numLeft">{val?.business_overview?.balance_Sheet_Snapshot?.previous_totalCurrentLiability}</td>
                            </tr>
                            <tr>
                                <td>Total EL</td>
                                <td className="numLeft">{val?.business_overview?.balance_Sheet_Snapshot?.current_totalEL}</td>
                                <td className="numLeft">{val?.business_overview?.balance_Sheet_Snapshot?.previous_totalEL}</td>
                            </tr>
                            <tr>
                                <td>Total Equity</td>
                                <td className="numLeft">{val.business_overview?.balance_Sheet_Snapshot?.current_totalEquity}</td>
                                <td className="numLeft">{val.business_overview?.balance_Sheet_Snapshot?.previous_totalEquity}</td>
                            </tr>
                            <tr>
                                <td>Total Fixed Assets</td>
                                <td className="numLeft">{val?.business_overview?.balance_Sheet_Snapshot?.current_totalFixedAsset}</td>
                                <td className="numLeft">{val?.business_overview?.balance_Sheet_Snapshot?.previous_totalFixedAsset}</td>
                            </tr>
                            <tr>
                                <td>Total Inventory</td>
                                <td className="numLeft">{val?.business_overview?.balance_Sheet_Snapshot?.current_totalInventory}</td>
                                <td className="numLeft">{val?.business_overview?.balance_Sheet_Snapshot?.previous_totalInventory}</td>
                            </tr>
                            <tr>
                                <td>Total Investment</td>
                                <td className="numLeft">{val.business_overview.balance_Sheet_Snapshot.current_totalInvestment}</td>
                                <td className="numLeft">{val.business_overview.balance_Sheet_Snapshot.previous_totalInvestment}</td>
                            </tr>
                            <tr>
                                <td>Total Liabilities</td>
                                <td className="numLeft">{val.business_overview.balance_Sheet_Snapshot.current_totalLiabilities}</td>
                                <td className="numLeft">{val.business_overview.balance_Sheet_Snapshot.previous_totalLiabilities}</td>
                            </tr>

                            <tr>
                                <td>Total Trade Payables</td>
                                <td className="numLeft">{val.business_overview.balance_Sheet_Snapshot.current_tradePayables}</td>
                                <td className="numLeft">{val.business_overview.balance_Sheet_Snapshot.previous_tradePayables}</td>
                            </tr>
                            <tr>
                                <td>Total Trade Receivables</td>
                                <td className="numLeft">{val.business_overview.balance_Sheet_Snapshot.current_tradeReceivables}</td>
                                <td className="numLeft">{val.business_overview.balance_Sheet_Snapshot.previous_tradeReceivables}</td>
                            </tr>
                            <tr>
                                <td>Cash & Cash Equivalent</td>
                                <td className="numLeft">{val.business_overview.balance_Sheet_Snapshot.current_cashAndCashEqv}</td>
                                <td className="numLeft">{val.business_overview.balance_Sheet_Snapshot.previous_cashAndCashEqv}</td>
                            </tr>
                        </table>

                        {/* -----------------Sub-Section 2 -----------------  */}
                        
                        <div className="mca-data-section mt-3" style={{fontSize:'1.1rem', fontWeight:'bold'}}>
                           <span>Income Statement Snapshot</span>
                        </div>
                        <div className="row mt-1">
                        <div className="col-md-2">
                                Financial Start Date  :
                            </div>
                            <div className="col-md-2">
                                {val?.business_overview?.income_statement_snapshot?.financialStartDate}
                            </div>
                            <div className="col-md-2">
                            </div>
                            <div className="col-md-2">
                                Financial End Date  :
                            </div>
                            <div className="col-md-2">
                                {val?.business_overview?.income_statement_snapshot?.financialEndDate}
                            </div>
                            
                        </div>

                        <table style={{width:'100%', marginTop:'1rem'}}>
                            <tr>
                                <td></td>
                                <td className="text-center">Current</td>
                                <td className="text-center">Previous</td>
                            </tr>
                            <tr>
                                <td>Direct Cost</td>
                                <td className="numLeft">{val?.business_overview?.income_statement_snapshot?.current_directCost}</td>
                                <td className="numLeft">{val?.business_overview?.income_statement_snapshot?.previous_directCost}</td>
                            </tr>
                            <tr>
                                <td>Ebitda</td>
                                <td className="numLeft">{val?.business_overview?.income_statement_snapshot?.current_ebitda}</td>
                                <td className="numLeft">{val?.business_overview?.income_statement_snapshot?.previous_ebitda}</td>
                            </tr>
                            <tr>
                                <td>Gross Profit</td>
                                <td className="numLeft">{val?.business_overview?.income_statement_snapshot?.current_grossProfit}</td>
                                <td className="numLeft">{val?.business_overview?.income_statement_snapshot?.previous_grossProfit}</td>
                            </tr>
                            <tr>
                                <td>Income from BP</td>
                                <td className="numLeft">{val?.business_overview?.income_statement_snapshot?.current_incomeFromBP}</td>
                                <td className="numLeft">{val?.business_overview?.income_statement_snapshot?.previous_incomeFromBP}</td>
                            </tr>
                            <tr>
                                <td>Income from CG</td>
                                <td className="numLeft">{val.business_overview?.income_statement_snapshot?.current_incomeFromCG}</td>
                                <td className="numLeft">{val.business_overview?.income_statement_snapshot?.previous_incomeFromCG}</td>
                            </tr>
                            <tr>
                                <td>Income from HP</td>
                                <td className="numLeft">{val?.business_overview?.income_statement_snapshot?.current_incomeFromHP}</td>
                                <td className="numLeft">{val?.business_overview?.income_statement_snapshot?.previous_incomeFromHP}</td>
                            </tr>
                            <tr>
                                <td>Income from OS</td>
                                <td className="numLeft">{val?.business_overview?.income_statement_snapshot?.current_incomeFromOS}</td>
                                <td className="numLeft">{val?.business_overview?.income_statement_snapshot?.previous_incomeFromOS}</td>
                            </tr>
                            <tr>
                                <td>Income from salary</td>
                                <td className="numLeft">{val.business_overview.income_statement_snapshot?.current_incomeFromSalary}</td>
                                <td className="numLeft">{val.business_overview.income_statement_snapshot?.previous_incomeFromSalary}</td>
                            </tr>
                            <tr>
                                <td>Interest Expense</td>
                                <td className="numLeft">{val.business_overview.income_statement_snapshot?.current_interestExpense}</td>
                                <td className="numLeft">{val.business_overview.income_statement_snapshot?.previous_interestExpense}</td>
                            </tr>

                            <tr>
                                <td>Profit after tax</td>
                                <td className="numLeft">{val.business_overview.income_statement_snapshot?.current_profitAfterTax}</td>
                                <td className="numLeft">{val.business_overview.income_statement_snapshot?.previous_profitAfterTax}</td>
                            </tr>
                            <tr>
                                <td>Profit before tax</td>
                                <td className="numLeft">{val.business_overview.income_statement_snapshot?.current_profitBeforeTax}</td>
                                <td className="numLeft">{val.business_overview.income_statement_snapshot?.previous_profitBeforeTax}</td>
                            </tr>
                            <tr>
                                <td>Purchases</td>
                                <td className="numLeft">{val.business_overview.income_statement_snapshot?.current_purchases}</td>
                                <td className="numLeft">{val.business_overview.income_statement_snapshot?.previous_purchases}</td>
                            </tr>
                            <tr>
                                <td>Revenue from Operations</td>
                                <td className="numLeft">{val.business_overview.income_statement_snapshot?.current_revenueFromOperations}</td>
                                <td className="numLeft">{val.business_overview.income_statement_snapshot?.previous_revenueFromOperations}</td>
                            </tr>
                            <tr>
                                <td>Total Expense</td>
                                <td className="numLeft">{val.business_overview.income_statement_snapshot?.current_totalExpense}</td>
                                <td className="numLeft">{val.business_overview.income_statement_snapshot?.previous_totalExpense}</td>
                            </tr>
                            <tr>
                                <td>Total Revenue</td>
                                <td className="numLeft">{val.business_overview.income_statement_snapshot?.current_totalRevenue}</td>
                                <td className="numLeft">{val.business_overview.income_statement_snapshot?.previous_totalRevenue}</td>
                            </tr>
                            <tr>
                                <td>Total Tax</td>
                                <td className="numLeft">{val.business_overview.income_statement_snapshot?.current_totalTax}</td>
                                <td className="numLeft">{val.business_overview.income_statement_snapshot?.previous_totalTax}</td>
                            </tr>
                        </table>
                        
                    {/* --------------------Section 3----------------------- */}
                     
                    <div className="mca-data-section mt-3" style={{fontSize:'1.1rem', fontWeight:'bold'}}>
                           <span>Financial Statement Analysis</span>
                        </div>
                        <div className="row mt-1">
                        <div className="col-md-2">
                                Financial Start Date  :
                            </div>
                            <div className="col-md-2">
                                {val?.financial_statement_analysis?.financialStartDate}
                            </div>
                            <div className="col-md-2">
                            </div>
                            <div className="col-md-2">
                                Financial End Date  :
                            </div>
                            <div className="col-md-2">
                                {val?.financial_statement_analysis?.financialEndDate}
                            </div>
                            
                        </div>

                        <table style={{width:'100%', marginTop:'1rem'}}>
                        <tbody>
                            <tr>
                                <td></td>
                                <td className="text-center">Current</td>
                                <td className="text-center">Previous</td>
                            </tr>
                            <tr>
                                <td>Cash Conversion Cycle</td>
                                <td className="numLeft">{val?.financial_statement_analysis?.activity_ratio?.current_cashConversionCycle}</td>
                                <td className="numLeft">{val?.financial_statement_analysis?.activity_ratio?.previous_cashConversionCycle}</td>
                            </tr>
                            <tr>
                                <td>Days of Inventory Outstanding</td>
                                <td className="numLeft">{val?.financial_statement_analysis?.activity_ratio?.current_daysOfInventoryOutstanding}</td>
                                <td className="numLeft">{val?.financial_statement_analysis?.activity_ratio?.previous_daysOfInventoryOutstanding}</td>
                            </tr>
                            <tr>
                                <td>Days of Payable Outstanding</td>
                                <td className="numLeft">{val?.financial_statement_analysis?.activity_ratio?.current_daysOfPayablesOutstanding}</td>
                                <td className="numLeft">{val?.financial_statement_analysis?.activity_ratio?.previous_daysOfPayablesOutstanding}</td>
                            </tr>
                            <tr>
                                <td>Days of Sales Outstanding</td>
                                <td className="numLeft">{val?.financial_statement_analysis?.activity_ratio?.current_daysOfSalesOutstanding}</td>
                                <td className="numLeft">{val?.financial_statement_analysis?.activity_ratio?.previous_daysOfSalesOutstanding}</td>
                            </tr>
                            <tr>
                                <td>Days working capital</td>
                                <td className="numLeft">{val.financial_statement_analysis?.activity_ratio?.current_daysWorkingCapital}</td>
                                <td className="numLeft">{val.financial_statement_analysis?.activity_ratio?.previous_daysWorkingCapital}</td>
                            </tr>
                            <tr>
                                <td>Inventory Turnover</td>
                                <td className="numLeft">{val?.financial_statement_analysis?.activity_ratio?.current_inventoryTurnover}</td>
                                <td className="numLeft">{val?.financial_statement_analysis?.activity_ratio?.previous_inventoryTurnover}</td>
                            </tr>
                            <tr>
                                <td>Payables Turnover</td>
                                <td className="numLeft">{val?.financial_statement_analysis?.activity_ratio?.current_payablesTurnover}</td>
                                <td className="numLeft">{val?.financial_statement_analysis?.activity_ratio?.previous_payablesTurnover}</td>
                            </tr>
                            <tr>
                                <td>Receivables Turnover</td>
                                <td className="numLeft">{val?.financial_statement_analysis?.activity_ratio?.current_receivablesTurnover}</td>
                                <td className="numLeft">{val?.financial_statement_analysis?.activity_ratio?.previous_receivablesTurnover}</td>
                            </tr>
                            <tr>
                                <td>Total Asset Turnover</td>
                                <td className="numLeft">{val?.financial_statement_analysis?.activity_ratio?.current_totalAssetTurnover}</td>
                                <td className="numLeft">{val?.financial_statement_analysis?.activity_ratio?.previous_totalAssetTurnover}</td>
                            </tr>
                            <tr>
                                <td>Working Capital Turnover</td>
                                <td className="numLeft">{val?.financial_statement_analysis?.activity_ratio?.current_workingCapitalTurnover}</td>
                                <td className="numLeft">{val?.financial_statement_analysis?.activity_ratio?.previous_workingCapitalTurnover}</td>
                            </tr>
                           </tbody>
                        </table>

                    {/* --------------------Section 4----------------------- */}
                     
                    <div className="mca-data-section mt-3" style={{fontSize:'1.1rem', fontWeight:'bold'}}>
                           <span>Growth Ratio</span>
                        </div>

                        <table style={{width:'100%', marginTop:'1rem'}}>
                        <tbody>
                            <tr>
                                <td></td>
                                <td className="text-center">Current</td>
                                <td className="text-center">Previous</td>
                            </tr>
                            <tr>
                                <td>Net Sales Growth</td>
                                <td className="numLeft">{val?.financial_statement_analysis?.growth_ratio?.current_netSalesGrowth}</td>
                                <td className="numLeft">{val?.financial_statement_analysis?.growth_ratio?.previous_netSalesGrowth}</td>
                            </tr>
                            <tr>
                                <td>Net Worth Growth</td>
                                <td className="numLeft">{val?.financial_statement_analysis?.growth_ratio?.current_netWorthGrowth}</td>
                                <td className="numLeft">{val?.financial_statement_analysis?.growth_ratio?.previous_netWorthGrowth}</td>
                            </tr>
                            <tr>
                                <td>Total Assets Growth</td>
                                <td className="numLeft">{val?.financial_statement_analysis?.growth_ratio?.current_totalAssetsGrowth}</td>
                                <td className="numLeft">{val?.financial_statement_analysis?.growth_ratio?.previous_totalAssetsGrowth}</td>
                            </tr>
                            <tr>
                                <td>Total Liabilities Growth</td>
                                <td className="numLeft">{val?.financial_statement_analysis?.growth_ratio?.current_totalLiabilitiesGrowth}</td>
                                <td className="numLeft">{val?.financial_statement_analysis?.growth_ratio?.previous_totalLiabilitiesGrowth}</td>
                            </tr>
                            
                           </tbody>
                        </table>
                        
                    {/* --------------------Section 4----------------------- */}
                     
                    <div className="mca-data-section mt-3" style={{fontSize:'1.1rem', fontWeight:'bold'}}>
                           <span>Liquidity Ratio</span>
                        </div>

                        <table style={{width:'100%', marginTop:'1rem'}}>
                        <tbody>
                            <tr>
                                <td></td>
                                <td className="text-center">Current</td>
                                <td className="text-center">Previous</td>
                            </tr>
                            <tr>
                                <td>Cash Ratio</td>
                                <td className="numLeft">{val?.financial_statement_analysis?.liquidityRatios?.current_cashRatio}</td>
                                <td className="numLeft">{val?.financial_statement_analysis?.liquidityRatios?.previous_cashRatio}</td>
                            </tr>
                            <tr>
                                <td>Current Ratio</td>
                                <td className="numLeft">{val?.financial_statement_analysis?.liquidityRatios?.current_currentRatio}</td>
                                <td className="numLeft">{val?.financial_statement_analysis?.liquidityRatios?.previous_currentRatio}</td>
                            </tr>
                            <tr>
                                <td>Interest Coverage</td>
                                <td className="numLeft">{val?.financial_statement_analysis?.liquidityRatios?.current_interestCoverage}</td>
                                <td className="numLeft">{val?.financial_statement_analysis?.liquidityRatios?.previous_interestCoverage}</td>
                            </tr>
                            <tr>
                                <td>Quick Ratio</td>
                                <td className="numLeft">{val?.financial_statement_analysis?.liquidityRatios?.current_quickRatio}</td>
                                <td className="numLeft">{val?.financial_statement_analysis?.liquidityRatios?.previous_quickRatio}</td>
                            </tr>
                            
                           </tbody>
                        </table>
                        
                        {/* --------------------Section 4----------------------- */}
                         
                        <div className="mca-data-section mt-3" style={{fontSize:'1.1rem', fontWeight:'bold'}}>
                               <span>Profitability Ratio</span>
                            </div>
    
                            <table style={{width:'100%', marginTop:'1rem'}}>
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td className="text-center">Current</td>
                                    <td className="text-center">Previous</td>
                                </tr>
                                <tr>
                                    <td>Ebitda Margin</td>
                                    <td className="numLeft">{val?.financial_statement_analysis?.profitability_ratio?.current_ebitdaMargin}</td>
                                    <td className="numLeft">{val?.financial_statement_analysis?.profitability_ratio?.previous_ebitdaMargin}</td>
                                </tr>
                                <tr>
                                    <td>Gross Profit Margin</td>
                                    <td className="numLeft">{val?.financial_statement_analysis?.profitability_ratio?.current_grossProfitMargin}</td>
                                    <td className="numLeft">{val?.financial_statement_analysis?.profitability_ratio?.previous_grossProfitMargin}</td>
                                </tr>
                                <tr>
                                    <td>Net Profit Margin</td>
                                    <td className="numLeft">{val?.financial_statement_analysis?.profitability_ratio?.current_netProfitMargin}</td>
                                    <td className="numLeft">{val?.financial_statement_analysis?.profitability_ratio?.previous_netProfitMargin}</td>
                                </tr>
                                <tr>
                                    <td>Operating Profit Margin</td>
                                    <td className="numLeft">{val?.financial_statement_analysis?.profitability_ratio?.current_operatingProfitMargin}</td>
                                    <td className="numLeft">{val?.financial_statement_analysis?.profitability_ratio?.previous_operatingProfitMargin}</td>
                                </tr>
                                <tr>
                                    <td>Return on Assets</td>
                                    <td className="numLeft">{val?.financial_statement_analysis?.profitability_ratio?.current_returnOnAssets}</td>
                                    <td className="numLeft">{val?.financial_statement_analysis?.profitability_ratio?.previous_returnOnAssets}</td>
                                </tr>
                                <tr>
                                    <td>Return on Equity</td>
                                    <td className="numLeft">{val?.financial_statement_analysis?.profitability_ratio?.current_returnOnEquity}</td>
                                    <td className="numLeft">{val?.financial_statement_analysis?.profitability_ratio?.previous_returnOnEquity}</td>
                                </tr>
                               </tbody>
                            </table>

                                                  {/* --------------------Section 4----------------------- */}
                         
                        <div className="mca-data-section mt-3" style={{fontSize:'1.1rem', fontWeight:'bold'}}>
                               <span>Solvency Ratio</span>
                            </div>
    
                            <table style={{width:'100%', marginTop:'1rem'}}>
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td className="text-center">Current</td>
                                    <td className="text-center">Previous</td>
                                </tr>
                                <tr>
                                    <td>Debt Capital</td>
                                    <td className="numLeft">{val?.financial_statement_analysis?.solvency_ratio?.current_debtCapital}</td>
                                    <td className="numLeft">{val?.financial_statement_analysis?.solvency_ratio?.previous_debtCapital}</td>
                                </tr>
                                <tr>
                                    <td>Debt EBITDA</td>
                                    <td className="numLeft">{val?.financial_statement_analysis?.solvency_ratio?.current_debtEBITDA}</td>
                                    <td className="numLeft">{val?.financial_statement_analysis?.solvency_ratio?.previous_debtEBITDA}</td>
                                </tr>
                                <tr>
                                    <td>Debt Equity</td>
                                    <td className="numLeft">{val?.financial_statement_analysis?.solvency_ratio?.current_debtEquity}</td>
                                    <td className="numLeft">{val?.financial_statement_analysis?.solvency_ratio?.previous_debtEquity}</td>
                                </tr>
                                <tr>
                                    <td>EBITDA Coverage</td>
                                    <td className="numLeft">{val?.financial_statement_analysis?.solvency_ratio?.current_ebitdaCoverage}</td>
                                    <td className="numLeft">{val?.financial_statement_analysis?.solvency_ratio?.previous_ebitdaCoverage}</td>
                                </tr>
                                <tr>
                                    <td>Equity Multiplier</td>
                                    <td className="numLeft">{val?.financial_statement_analysis?.solvency_ratio?.current_equityMultiplier}</td>
                                    <td className="numLeft">{val?.financial_statement_analysis?.solvency_ratio?.previous_equityMultiplier}</td>
                                </tr>
                               </tbody>
                            </table>

                                  {/* --------------------Section 4----------------------- */}
                         
                            <div className="mca-data-section mt-3" style={{fontSize:'1.1rem', fontWeight:'bold'}}>
                               <span>Business Summary Report</span>
                            </div>
    
                            <table style={{width:'100%', marginTop:'1rem'}}>
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td className="text-center">Current</td>
                                    <td className="text-center">Previous</td>
                                </tr>
                                <tr>
                                    <td>Cash & Cash Eqv</td>
                                    <td className="numLeft">{val?.business_summary?.summary_report?.current_cashAndCashEqv}</td>
                                    <td className="numLeft">{val?.business_summary?.summary_report?.previous_cashAndCashEqv}</td>
                                </tr>
                                <tr>
                                    <td>Profit After Tax</td>
                                    <td className="numLeft">{val?.business_summary?.summary_report?.current_profitAfterTax}</td>
                                    <td className="numLeft">{val?.business_summary?.summary_report?.previous_profitAfterTax}</td>
                                </tr>
                                <tr>
                                    <td>Revenue From Operation</td>
                                    <td className="numLeft">{val?.business_summary?.summary_report?.current_revenueFromOperation}</td>
                                    <td className="numLeft">{val?.business_summary?.summary_report?.previous_revenueFromOperation}</td>
                                </tr>
                                <tr>
                                    <td>Total Assets</td>
                                    <td className="numLeft">{val?.business_summary?.summary_report?.current_totalAssets}</td>
                                    <td className="numLeft">{val?.business_summary?.summary_report?.previous_totalAssets}</td>
                                </tr>
                                <tr>
                                    <td>Total Liabilities</td>
                                    <td className="numLeft">{val?.business_summary?.summary_report?.current_totalLiabilities}</td>
                                    <td className="numLeft">{val?.business_summary?.summary_report?.previous_totalLiabilities}</td>
                                </tr>
                               </tbody>
                            </table>
                    </div>

                </div>)}
            </div>
		</Fragment>
	);
};