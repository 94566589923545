import { Fragment, useContext, useMemo } from "react";
import { AdminCamContext } from "../../context/admin/cam/adminCamContext";

/**
 * @Description
 * Common table component
 * @param {{ tableHeaders: { value: string, colspan?: number, rowspan?: number }[][], children: React.ReactNode, addNewRows?: boolean, handleAddNewRow?: () => void }} props 
 * 
 * ## Usage
 */
export const GenericCamTableComponent = (props) => {
	
	const addNewRows = props.addNewRows ?? false;
	
	const maxRowSpan = useMemo(() => {
		let maxRow = 1;

		for (let arr of (props?.tableHeaders ?? [])) {
			for (let item of arr) {
				if (item.rowspan && item.rowspan > maxRow) {
					maxRow = item.rowspan;
				}
			}
		}
		return maxRow;
	}, [ props?.tableHeaders ]);

	const { editEnabled } = useContext(AdminCamContext);
	
	if (props.tableHeaders.length === 0) {
		return null;
	}

	return (
		<Fragment>
			<table className="table table-bordered border-secondary" style={{height:props?.height}}>
				<thead>
					{
						props?.tableHeaders?.map((list, index1) => {
							return (
								<tr key={ index1 } style={{ backgroundColor: "#eeeeee", fontSize: '1.05rem', fontWeight: '400', color: "#666666", letterSpacing: ".7px", }}>
									{
										list?.map((item, index2) => (
											<Fragment key={ (index1 + 1) * index2 }>
												<th className="text-center" colSpan={ item.colspan ?? 1 } rowSpan={ item.rowspan ?? 1 }>{ item.value }</th>
											</Fragment>
										))
									}
									{
										editEnabled && addNewRows && index1 === 0 &&
											<th className="text-center" rowSpan={ maxRowSpan }>
												<button style={{ fontSize: "1.5rem", }} onClick={ props?.handleAddNewRow ?? null } title="Add New Row" className="btn btn-secondary">+</button>
											</th>
									}
								</tr>
							);
						})
					}
				</thead>
				<tbody>
					{ props.children ?? null }
				</tbody>
			</table>
		</Fragment>
	);
};