import React, { useEffect, useState } from "react";
import { BsPlusCircleFill, BsSearch } from "react-icons/bs";
import Loader from "../../layout/GenericLayout/Loader";
import { useDispatch, useSelector } from "react-redux";
import { getProformaInvoiceTable } from "../../store/slices/anchor";
import AnchorProformaInvoiceTable from "./AnchorTables/AnchorProformaInvoice/AnchorProformaInvoiceTable";
import {Helmet} from "react-helmet";

const AnchorProformaInvoice = () => {
  const dispatch = useDispatch();
  /* USESELECT HOOKS */
  const proformaInvoiceTableData = useSelector(
    (state) => state.anchor.proformaInvoiceTableData
  );
  const [materialRequestTableRows, setMaterialRequestTableRows] = useState([]);
  const [materialRequestMetaData, setMaterialRequestMetaData] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  /* TABLE QUERY PARAMS */
  const [tableQueryParams, setTableQueryParams] = useState({
    tableOffset: 0,
    tableLimit: 5,
    tableSort: "proformaId",
    tableOrder: -1,
    currentPage: 1,
    search: "",
  });

  useEffect(() => {
    dispatch(
      getProformaInvoiceTable({
        tableQueryParams,
      })
    );
  }, [dispatch, tableQueryParams]);

  useEffect(() => {
    if (proformaInvoiceTableData.data) {
      // console.log("inside useeffect ", proformaInvoiceTableData);
      setMaterialRequestTableRows(proformaInvoiceTableData.data.items);
      setMaterialRequestMetaData(proformaInvoiceTableData.data.metadata);
    }
  }, [proformaInvoiceTableData.data]);

  // console.log(materialRequestMetaData)

  return (
    <div className="d-flex flex-column gap-4" style={{ overflowY: "auto" }}>
       <Helmet>
                <title>Proforma-Invoice</title>
            </Helmet>
      <div className="bg-white admin-dashboard-card-container p-3">
        <div className="d-flex justify-content-between align-items-center flex-wrap px-2 gap-2 py-2">
          <span className="dashboard-content-title">Proforma Invoice</span>
          {/* <div
            // onClick={toggleRMAddModal}
            className="d-flex align-items-center"
            type="button"
          >
            <div
              className={`rm-add-button ${isHovered ? "show" : ""}`}
              // onMouseEnter={handleMouseEnter}
              // onMouseLeave={handleMouseLeave}
            >
              ADD
            </div>
            <BsPlusCircleFill
              className="rm-plus-button"
              // onMouseEnter={handleMouseEnter}
              // onMouseLeave={handleMouseLeave}
            />
          </div> */}
        </div>

        <div className="d-flex flex-column flex-sm-row align-items-center mt-2 mb-1">
          <div className="d-flex flex-column justify-content-center gap-3 col-12 col-sm-6">
            <div className="search-container d-flex align-items-center py-1 px-3 col-12 col-sm-8">
              <input
                className="search-input-element d-flex flex-grow-1"
                type="text"
                placeholder="Search with Proforma Invoice ID"
                onChange={(event) =>
                  setTableQueryParams((prev) => ({
                    ...prev,
                    search: event.target.value,
                  }))
                }
                // value={search}
              />{" "}
              <BsSearch className="fs-5" />
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex flex-column justify-content-between flex-wrap px-2 gap-2">
        <AnchorProformaInvoiceTable
          className="admin-anchor-and-lender-mapped-table"
          tableHeader={[
            { name: "Proforma Invoice ID", sortValue: "proformaId" },
            { name: "Counter Party", sortValue: "buyerName" },
            { name: "Raised Date", sortValue: "addedDate" },
            {
              name: "Date Of Delivery",
              sortValue: "expectedDateOfDelivery",
            },
            { name: "Payment Terms (in days)", sortValue: "paymentTerms" },
            { name: "Status", sortValue: "proformaStatus" },
          ]}
          tableRows={materialRequestTableRows}
          materialRequestMetaData={materialRequestMetaData}
          tableQueryParams={tableQueryParams}
          setTableQueryParams={setTableQueryParams}
          isLoading={proformaInvoiceTableData.isLoading}
        />
      </div>
    </div>
  );
};

export default AnchorProformaInvoice;
