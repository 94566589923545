import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAccountReports, discountingSeller } from '../../store/slices/lender';
import { Helmet } from "react-helmet";
import { toastMessage } from '../../layout/GenericLayout/ToastMessage';
import ExcelSheet from './Excelsheet';

const LenderLiquidation = () => {
	const [report, setReport] = useState("0");
	const [discountingSellerList, setDiscountingSellerList] = useState([]);
	const [anchor, setAnchor] = useState('');
	const [status, setStatus] = useState('');


	const dispatch = useDispatch();

	const fetchedReports = useSelector(state => state.lender.fetchReports);
	const discountingSellerData = useSelector(state => state.lender.discountingSellerData)

	console.log("reports", fetchedReports);

	useEffect(() => {
		dispatch(discountingSeller());
	}, [])

	useEffect(() => {
		setStatus('')
		dispatch(fetchAccountReports({ field: 'customer_id', value: anchor }))
			.unwrap()
			.then((response) => {
				toastMessage(response.message, "success");
			})
			.catch((error) => {
				toastMessage(error.message, "error");
			});
	}, [anchor])

	useEffect(() => {
		// setAnchor('')
		dispatch(fetchAccountReports({ field: 'customer_id', value: status }))
			.unwrap()
			.then((response) => {
				toastMessage(response.message, "success");
			})
			.catch((error) => {
				toastMessage(error.message, "error");
			});
	}, [status])

	useEffect(() => {
		if (discountingSellerData?.data) {
			console.log("discounting", discountingSellerData?.data?.success)
			setDiscountingSellerList(discountingSellerData?.data?.success);
		}
	}, [discountingSellerData])

	useEffect(() => {
		if (report !== "0") {
			if (report === "Statement_of_Account")
				dispatch(fetchAccountReports());
			else if (report === "Overdue")
				dispatch(fetchAccountReports({ field: 'field', value: "overdue" }));
		}
	}, [report, dispatch]);

	const showTable = report !== "0";

	return (
		<div className="d-flex flex-column gap-4" style={{ backgroundColor: 'white', padding: '2rem', borderRadius: '10px' }}>
			<Helmet>
				<title>Reports</title>
			</Helmet>

			<div className="d-flex justify-content-between flex-column px-2 gap-2">
				<span className="dashboard-content-title">Bill Liquidation</span>
				<label style={{ width: '100%', marginTop: '2rem', fontWeight: 'bold', fontSize: '18px' }}>Types Of Report</label>
				<div className=" d-flex align-items-center py-1 px-3 col-12 col-sm-4 ">


					<select onChange={e => setReport(e.target.value)} value={report} className="search-input-element1 d-flex flex-grow-1">
						<option disabled value="0">Select Report Type</option>
						<option value="Statement_of_Account">Statement of Account</option>
						<option value="Overdue">Overdue</option>
					</select>
				</div>
			</div>

			{report === "Statement_of_Account" &&
				<div>
					<div className="details-container container p-3">
						<div className="row gy-md-4 gy-2">
							<div className="kyc-fields-container col-12  col-md-4">
								<label
									htmlFor="billing"
									className="kyc-label-text form-label fw-semibold"
								>
									Select the Anchor
								</label>
								{/* <input
											disabled
											id="billing"
											type="text"
											name="billing"
											className="kyc-input-field form-control"
										/> */}
								<select onChange={e => setAnchor(e.target.value)} value={anchor}
									className="kyc-input-field form-control" style={{ backgroundColor: '#e9ecef' }}>
									<option value=''>Select the Anchor</option>
									{discountingSellerList?.map((item, index) => (
										<option key={item?.sellerId} value={item?.sellerId}>{item?.companyFullName}</option>
									))}

								</select>
							</div>

							<div className="kyc-fields-container col-12  col-md-4">
								<label
									htmlFor="shipping"
									className="kyc-label-text form-label fw-semibold"
								>Select the Status
								</label>
								<select value={status} onChange={e => setStatus(e.target.value)}
									className="kyc-input-field form-control" style={{ backgroundColor: '#e9ecef' }}>
									<option value=''>Select the Status</option>
									<option value="Liquidated">Liquidated</option>
									<option value="Partial Payment Initiated">Partial Payment Initiated</option>
									<option value="Liquidation request Initiated">Liquidation request Initiated</option>
								</select>
							</div>
							{/* <div className="kyc-fields-container col-12  col-md-4">
								<label
									htmlFor="shipping"
									className="kyc-label-text form-label fw-semibold"
								>Start Date
								</label>
								<input
									id="shipping"
									disabled
									type="date"
									name="shipping"
									className="kyc-input-field form-control"
								/>
							</div> */}

							<div className="kyc-fields-container col-12  col-md-4" style={{paddingTop:'15px'}}>
								
								<ExcelSheet tableData={fetchedReports} />
							</div>
						</div>
					</div>
					{/* <div className="details-container container p-3">
						<div className="row gy-md-4 gy-2">
							<div className="kyc-fields-container col-12  col-md-4">
								<label
									htmlFor="shipping"
									className="kyc-label-text form-label fw-semibold"
								>End Date
								</label>
								<input
									id="shipping"
									disabled
									type="date"
									name="shipping"
									className="kyc-input-field form-control"
								/>
							</div>
						</div>
					</div> */}
				</div>
			}

			{
				showTable &&
				<Fragment>
					<div className="container-fluid" style={{ border: ".75px solid #efefef", borderRadius: "5px", overflowX: "auto", overflowY: "hidden" }}>
						<table className="table lender-anchor-and-lender-mapped-table1 placeholder-wave0 text-center">
							<thead>
								<tr>
									{
										["CIF", "Anchor", "Invoice Amount (₹)", "Invoice Number", "Compliance check", "Buyer Confirmation", "Status"]
											.map((element, index) => <th key={element} title={element} style={{ minWidth: "8rem" }}>{element}</th>)
									}
								</tr>
							</thead>

							<tbody>
								{fetchedReports?.data?.items.map((element, index) => (
									<tr key={index}>
										<td>{element?.sellerId}</td>
										<td>{element?.sellerName}</td>
										<td>{element?.invoiceAmount}</td>
										<td>{element?.invoiceId}</td>
										<td>{element?.complianceCheck}</td>
										<td>{element?.buyerInvoiceStatus}</td>
										<td>{element?.invoiceStatus}</td>
									</tr>
								))}


							</tbody>
						</table>
					</div>
				</Fragment>
			}
		</div>
	)
}

export default LenderLiquidation;
