import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ImportantSymbol from "../../../assests/icons/ImportantSymbol";
import ReusableMessageModal from "../../../layout/GenericLayout/ReusableMessageModel";
import {
  getGenerateProformaPO,
  getProformaAccept,
  getProformaInvoiceDetails,
  getProformaNegotiationSave,
} from "../../../store/slices/buyer";

const AlertResponse = (props) => {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <p className="w-75 text-center mt-5">{props?.alertMsg}</p>
    </div>
  );
};

const BuyerProformaInvoiceDetails = () => {
  const { paramsProformaInvoiceID } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const now = new Date();
  const minDate = moment(now).format("YYYY-MM-DD");

  const proformaInvoiceDetailsDataStateResponse = useSelector(
    (state) => state.buyer.proformaInvoiceDetailsDataState
  );
  const proformInvoiceGeneratePOResponse = useSelector(
    (state) => state.buyer.proformaInvoiceGeneratePOState
  );

  const proformaAcceptResponse = useSelector(
    (state) => state.buyer.proformaAccept
  );

  const [clickNegotiate, setClickNegotiate] = useState(false);
  const [showNegotiationSuccess, setShowNegotiationSuccess] = useState(false);
  const toggleShowNegotiationSuccess = () => {
    setShowNegotiationSuccess(!showNegotiationSuccess);
    navigate("/buyer/proforma-invoice");
  };

  const [showNegativeGeneratePOMessage, setShowNegativeGeneratePOMessage] =
    useState(false);
  const toggleShowNegativeGeneratePOMessage = () => {
    setShowNegativeGeneratePOMessage(!showNegativeGeneratePOMessage);
  };

  const [showAcceptPositiveResponse, setShowAcceptPositiveResponse] =
    useState(false);
  const toggleShowAcceptPositiveResponse = () => {
    setShowAcceptPositiveResponse(!showAcceptPositiveResponse);
  };

  const [showAcceptBadResponse, setShowAcceptBadResponse] = useState(false);
  const toggleShowAcceptBadResponse = () => {
    setShowAcceptBadResponse(!showAcceptBadResponse);
  };

  // const [negotiatingBtn, setNegotiatingBtn] = useState(false);
  const [proformaDetailsData, setProformaDetailsData] = useState();

  console.log("proformaDetailsData : ", proformaDetailsData);
  const [
    reviseModeOfTransPortationOptions,
    setReviseModeOfTransPortationOptions,
  ] = useState(["Courier", "Rail", "Road", "Air"]);
  const [negotiateForm, setNegotiationForm] = useState();

  useEffect(() => {
    dispatch(
      getProformaInvoiceDetails({
        sellerID: location.state,
        proformaInvoiceID: paramsProformaInvoiceID,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (proformaInvoiceDetailsDataStateResponse.data) {
      setProformaDetailsData(
        proformaInvoiceDetailsDataStateResponse.data["items"][0]
      );
    }
  }, [proformaInvoiceDetailsDataStateResponse.data]);

  const handleGeneratePO = () => {
    let body = {
      sellerId: location.state,
      proformaId: paramsProformaInvoiceID,
    };
    dispatch(getGenerateProformaPO(body))
      .unwrap()
      .then((response) => {
        setShowAcceptPositiveResponse(true);
        // setShowNegativeGeneratePOMessage(true);
      })
      .catch((error) => {
        setShowNegativeGeneratePOMessage(true);
      });
  };
  const handleClickNegotiate = () => {
    setClickNegotiate(true);
  };

  const handleApiNegotiation = () => {
    console.log("we are there in destination Api Ngotiaiotn");
    dispatch(
      getProformaNegotiationSave({
        dataUpdates: [],
        sellerId: location.state,
        proformaId: paramsProformaInvoiceID,
        ...negotiateForm,
      })
    )
      .unwrap()
      .then((response) => {
        setShowNegotiationSuccess(true);
      })
      .catch((error) => {});
  };

  const handleNegotiationOnChange = (event) => {
    event.preventDefault();
    let val = event.target.value;
    if (event.target.name === "paymentTerms") {
      val = Number(event.target.value) * 1;
    }
    setNegotiationForm((prev) => {
      return {
        ...negotiateForm,
        [event.target.name]: val,
      };
    });
  };

  const handleAcceptNegotiation = () => {
    const body = {
      proformaId: paramsProformaInvoiceID,
      sellerId: location.state,
    };
    dispatch(getProformaAccept(body))
      .unwrap()
      .then((response) => {
        setShowAcceptPositiveResponse(true);
        setTimeout(() => navigate(0), 3500)
      })
      .catch((error) => {
        setShowAcceptBadResponse(true);
      });
  };

  console.log(negotiateForm);

  return (
    <>
      {showAcceptPositiveResponse && (
        <ReusableMessageModal
          showModal={showAcceptPositiveResponse}
          toggleModal={toggleShowAcceptPositiveResponse}
          msgType={"ok"}
          noBtn={false}
          modalContent={
            <AlertResponse alertMsg={proformaAcceptResponse?.data?.message ?? "Proforma invoice is accepted successfully"} />
          }
          modalSizeClass="modal-lg"
        />
      )}
      {showAcceptBadResponse && (
        <ReusableMessageModal
          showModal={showAcceptBadResponse}
          toggleModal={toggleShowAcceptBadResponse}
          msgType={"alert"}
          noBtn={false}
          modalContent={
            <AlertResponse alertMsg={proformaAcceptResponse?.error ?? "faild"} />
          }
          modalSizeClass="modal-lg"
        />
      )}
      {showNegotiationSuccess && (
        <ReusableMessageModal
          showModal={showNegotiationSuccess}
          toggleModal={toggleShowNegotiationSuccess}
          msgType={"ok"}
          noBtn={false}
          modalContent={<AlertResponse alertMsg={"Negotiation Initiated"} />}
          modalSizeClass="modal-lg"
        />
      )}
      {showNegativeGeneratePOMessage && (
        <ReusableMessageModal
          showModal={showNegativeGeneratePOMessage}
          toggleModal={toggleShowNegativeGeneratePOMessage}
          msgType={"alert"}
          noBtn={false}
          modalContent={
            <AlertResponse
              alertMsg={
                proformInvoiceGeneratePOResponse?.error
                // ?
                // <>
                // {proformInvoiceGeneratePOResponse.error}
                // </>:<>{'Unable to Process'}</>
              }
            />
          }
          modalSizeClass="modal-lg"
        />
      )}

      <div className="d-flex flex-column m-0 p-0 gap-4 request-details-cont mb-3">
        <div className="container-with-heading d-flex flex-column container my-4">
          <span className="content-title mb-3 row">
            Proforma Invoice Details
          </span>
          <div className="details-container ">
            {/* <div className="row gy-md-4 gy-2"> */}

            <div className="fields-container container-with-heading  px-0 row ">
              <span className="content-title  ">Items Information</span>
              <table className="w-100 col-12 m-0 ">
                <thead>
                  <tr>
                    <th>Item</th>
                    <th>Model Number</th>
                    <th>Quantity Ordered</th>
                    <th>Available Quantity</th>
                    <th>Price/Unit</th>
                    <th>Tax(%)</th>
                    <th>Total Amount</th>
                  </tr>
                </thead>
                {proformaDetailsData?.itemDetails.map((each)=>(
                  <tr>
                    <td>{each?.item}</td>
                    <td>{each?.modelNumber}</td>
                    <td>
                      {each?.quantityOrdered}
                    </td>
                    <td>
                      {each?.quantityAvailable}
                    </td>
                    <td>{each?.unitPrice}</td>
                    <td>{`${each?.tax} %`}</td>
                    <td>{`₹ ${each?.totalAmount.toFixed(
                      2
                    )}`}</td>
                  </tr>
                  )
                )}
                
                <tr>
                  <td colSpan={6}>{"Total"}</td>
                  <td>{`₹ ${proformaDetailsData?.proformaAmount.toFixed(
                    2
                  )}`}</td>
                </tr>
              </table>
            </div>

            {/* TransPort Details */}

            <div className="fields-container container-with-heading px-0 row mt-3 gy-0">
              <span className="content-title">Transport Details</span>
              <div className="col-12  col-md-4 mt-3">
                <label
                  htmlFor="DeliveryLocation"
                  className="label-text form-label"
                >
                  Shipment Terms
                </label>
                <input
                  id="DeliveryLocation"
                  name="DeliveryLocation"
                  type="text"
                  disabled
                  value={proformaDetailsData?.shippingTerms}
                  className={`input-field form-control`}
                />
                <span className="text-danger px-1"></span>
              </div>

              <div className="fields-container col-12  col-md-4 mt-3">
                <label htmlFor="RequiredDate" className="label-text form-label">
                  Mode of Transport
                </label>
                <input
                  id="RequiredDate"
                  type="text"
                  name="RequiredDate"
                  disabled
                  value={proformaDetailsData?.modeOfTransport}
                  className={`input-field form-control`}
                />

                <span className="text-danger px-1"></span>
              </div>

              <div className="fields-container col-12  col-md-4 mt-3">
                <label htmlFor="Shipment" className="label-text form-label">
                  Expected Date of delivery
                </label>
                <input
                  id="Shipment"
                  type="text"
                  name="Shipment"
                  className={`input-field form-control`}
                  disabled
                  value={proformaDetailsData?.expectedDateOfDelivery}
                />
                <span className="text-danger px-1"></span>
              </div>
              <div className="fields-container col-12  col-md-4 mt-3">
                <label htmlFor="Shipment" className="label-text form-label">
                  Delivery Location
                </label>
                <input
                  id="Shipment"
                  type="text"
                  name="Shipment"
                  className={`input-field form-control`}
                  disabled
                  value={proformaDetailsData?.locationOfDelivery}
                />
                <span className="text-danger px-1"></span>
              </div>

              <div className="fields-container col-12  col-md-4 mt-3">
                <label htmlFor="Shipment" className="label-text form-label">
                  Payment Terms
                </label>
                <input
                  id="Shipment"
                  type="text"
                  name="Shipment"
                  className={`input-field form-control`}
                  disabled
                  value={proformaDetailsData?.paymentTerms}
                />
                <span className="text-danger px-1"></span>
              </div>

              <div className="fields-container col-12  col-md-4 mt-3">
                <label htmlFor="Shipment" className="label-text form-label">
                  Additional Text
                </label>
                <input
                  id="Shipment"
                  type="text"
                  name="Shipment"
                  className={`input-field form-control`}
                  disabled
                  // The API IS INKNOWN
                  value={proformaDetailsData?.additionalText}
                />
                <span className="text-danger px-1"></span>
              </div>

              {(proformaDetailsData?.proformaStatus ===
                "Negotiation Accepted" ||
                proformaDetailsData?.proformaStatus === "Negotiated" || proformaDetailsData?.proformaStatus === "New") &&
                clickNegotiate && (
                  <>
                    <div className="fields-container col-12  col-md-4">
                      <label
                        htmlFor="modeOfTrans"
                        className="label-text form-label"
                      >
                        {`Revised Mode of Transport `}
                        <ImportantSymbol />
                      </label>
                      <select
                        id="modeOfTrans"
                        type="text"
                        className={`input-field form-select`}
                        name="modeOfTransport"
                        onChange={handleNegotiationOnChange}
                        // disabled
                        // The API IS INKNOWN
                        // value={proformaDetailsData?.additionalText}
                      >
                        <option>Select Transport</option>
                        {reviseModeOfTransPortationOptions.map(
                          (options, index) => {
                            return (
                              <option key={index} value={options}>
                                {options}
                              </option>
                            );
                          }
                        )}
                      </select>
                      <span className="text-danger px-1"></span>
                    </div>
                    <div className="fields-container col-12  col-md-4">
                      <label
                        htmlFor="Shipment"
                        className="label-text form-label"
                      >
                        Revised Expected Date of delivery <ImportantSymbol />
                      </label>
                      <input
                        id="Shipment"
                        type="date"
                        name="expectedDateOfDelivery"
                        onChange={handleNegotiationOnChange}
                        min={minDate}
                        className={`input-field form-control`}
                        // disabled
                      />
                      <span className="text-danger px-1"></span>
                    </div>
                    <div className="fields-container col-12  col-md-4">
                      <label
                        htmlFor="Shipment"
                        className="label-text form-label"
                      >
                        Revised Payment Terms <ImportantSymbol />
                      </label>
                      <input
                        id="Shipment"
                        type="number"
                        name="paymentTerms"
                        onChange={handleNegotiationOnChange}
                        className={`input-field form-control`}
                      />
                      <span className="text-danger px-1"></span>
                    </div>
                  </>
                )}
            </div>

            {/* </div> */}
          </div>
          <div className="d-flex justify-content-end my-2 px-4 container mb-5">
            <button
              className="back-button"
              onClick={() => {
                navigate("/buyer/proforma-invoice");
              }}
            >
              Back
            </button>
            {clickNegotiate ? (
              <>
                {proformaDetailsData?.proformaStatus ===
                  "Proforma Accepted" && (
                  <>
                    <button className="generate-po" onClick={handleGeneratePO}>
                      Generate PO
                    </button>
                  </>
                )}
                {(proformaDetailsData?.proformaStatus ===
                  "Negotiation Accepted" ||
                  proformaDetailsData?.proformaStatus === "Negotiated" || proformaDetailsData?.proformaStatus === "New") && (
                  <>
                    {/* <button 
          className='accept-button'
          >back</button> */}
                    <button
                      className="warning-button"
                      onClick={handleApiNegotiation}
                    >
                      Save
                    </button>
                  </>
                )}
              </>
            ) : (
              <>
                {proformaDetailsData?.proformaStatus ===
                  "Proforma Accepted" && (
                  <>
                    <button className="generate-po" onClick={handleGeneratePO}>
                      Generate PO
                    </button>
                  </>
                )}
                {(proformaDetailsData?.proformaStatus ===
                  "Negotiation Accepted" ||
                  proformaDetailsData?.proformaStatus === "Negotiated" ||
                  proformaDetailsData?.proformaStatus === "New") && (
                  <>
                    <button
                      className="warning-button"
                      onClick={handleClickNegotiate}
                      disabled={proformaDetailsData?.proformaStatus === "Negotiated"}
                    >
                      { proformaDetailsData?.proformaStatus === "Negotiated" ? "Negotiated" : "Negotiate" }
                    </button>
                    <button
                      className="success-button"
                      onClick={handleAcceptNegotiation}
                    >
                      Accept
                    </button>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BuyerProformaInvoiceDetails;
