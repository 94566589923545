import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { IoIosLock, IoIosUnlock } from "react-icons/io";
import { MdEmail, MdPhone } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import atonLogo from "../assests/images/Aton-logo.png";
import { InputValidator } from "../layout/GenericLayout/InputValidator";
import ReusableModal from "../layout/GenericLayout/ReusableModal";
import { toastMessage } from "../layout/GenericLayout/ToastMessage";
import { Helmet } from "react-helmet";
import {
  getOTPForLogin,
  otpLogin,
  updateForgotPassword,
  updateLoginData,
} from "../store/slices/generic";

/* MODALS OR CHILDREN COMPONENTS */
const ForgotPassowordModal = ({ toggleForgotPasswordModal }) => {
  const dispatch = useDispatch();

  const forgotPasswordData = useSelector(
    (state) => state.generic.forgotPasswordData
  );

  const [forgotPasswordCredentials, setForgotPasswordCredentials] =
    useState("");

  const onChangeForgotPasswordCredentials = (event) => {
    const value = event.target.value;
    setForgotPasswordCredentials(value);
  };

  const onSubmitForgotPassword = (event) => {
    event.preventDefault();
    console.log(forgotPasswordCredentials);
    const body = {
      emailId: forgotPasswordCredentials,
    };
    dispatch(updateForgotPassword(body))
      .unwrap()
      .then((response) => {
        toastMessage(response.success, "success");
        setForgotPasswordCredentials("");
        toggleForgotPasswordModal();
      })
      .catch((error) => {
        toastMessage(error.message, "error");
      });
  };

  return (
    <div className="p-2">
      <span className="fs-4 fw-semibold">Forgot Password</span>
      <form onSubmit={onSubmitForgotPassword}>
        <div className="row my-4">
          <div className="col-md-2"></div>
          <h5 className="text-center text-secondary">
            Enter your Email and instructions will be sent to you
          </h5>
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8 mt-5">
              <div className="row px-3 mb-4">
                <label className="label-text" htmlFor="forgot-login-email">
                  Email
                </label>
                <div
                  className={`input-container form-control d-flex align-items-center`}
                >
                  <input
                    onChange={onChangeForgotPasswordCredentials}
                    // onBlur={onBlurInputFeilds}
                    className="input-element d-flex flex-grow-1"
                    placeholder="Enter Email Id"
                    name="email"
                    type="email"
                    id="forgot-login-email"
                    // required
                    value={forgotPasswordCredentials}
                  />
                  <MdEmail className="input-icon" />
                </div>
                {/* <span className="text-danger px-1">{isError?.email}</span> */}
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>

          <div className="col-md-2"></div>
        </div>
        <div className="d-flex justify-content-center">
          <button type="submit" className="btn btn-primary w-50">
            {forgotPasswordData.isLoading ? (
              <span className="loader mx-auto"></span>
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

/* PARENT COMPONENT */
const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  sessionStorage.clear();
  const loginData = useSelector((state) => state.generic.loginData);
  const [credentials, setCredentails] = useState({ email: "", password: "" });
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isError, setIsError] = useState(null);

  const [$loginWithMobile, setLoginWithMobile] = useState(false);
  const [$mobileNumber, setMobileNumber] = useState({
    isTouched: false,
    isValid: false,
    value: "",
    errorMessage: "",
  });
  const [$counter, setCounter] = useState(0);
  const counterRef = useRef(null);
  const [$showCounter, setShowCounter] = useState(false);
  const [$showOTP, setShowOTP] = useState(false);
  const [$otp, setOTP] = useState({
    isTouched: false,
    isValid: false,
    value: "",
    errorMessage: "",
  });

  const [tabIndex, setTabIndex] = useState(0);

  const [showForgotPasswordStatus, setShowForgotPasswordStatus] =
    useState(false);

    const getOTPForLoginData= useSelector((state)=> state.generic.getOTPForLogin);

  useEffect(() => {
    if (loginData.data) {
      sessionStorage.setItem("token", loginData.data.items[0].token);
      if (loginData.data.items[0].kycStatus !== "NotUpdated") {
        sessionStorage.setItem("token", loginData.data.items[0].token);
        sessionStorage.setItem("role", loginData.data.items[0].user.roles[0]);
        sessionStorage.setItem("kycStatus", loginData.data.items[0].kycStatus);
        sessionStorage.setItem("email", loginData.data.items[0].user.emailId);
        sessionStorage.setItem(
          "customerId",
          loginData.data.items[0].customerId
        );
        sessionStorage.setItem(
          "user",
          JSON.stringify(loginData.data.items[0].user)
        );
      }
    }
  }, [loginData]);

  const onChangeCredentials = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setCredentails((prev) => ({ ...prev, [name]: value }));
  };

  const onSubmitLogIn = (event) => {
    event.preventDefault();

    if (!credentials.email || !credentials.password) {
      return;
    }

    if (!isError?.email || !isError?.password) {
      const body = {
        emailId: credentials.email.toLowerCase(),
        password: credentials.password,
      };
      dispatch(updateLoginData(body))
        .unwrap()
        .then((response) => {
          console.log("response : ", response);
          toastMessage(response.success, "success");
          const productCategory = response.items[0].user.productCategory;
          console.log("Product Category=========>", productCategory);
          sessionStorage.setItem("productCategory", productCategory);
        })
        .catch((error) => {
          console.log("error : ", error);
          toastMessage(error.message, "error");
        });
    }
  };

  const onClickSignUp = () => {
    navigate("/select-role");
  };

  const onClickPasswordVisibilityStatus = () => {
    setIsPasswordVisible((prev) => !prev);
  };

  const onBlurInputFeilds = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    const validator = {
      email: {
        regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        message: "Please enter a valid email address",
      },
      password: {
        regex: /^.+$/,
        message: "Password is required",
      },
      mobile: {
        regex: /^$/g,
        message: "Mobile is Required",
      },
    };

    const errorMessgae = InputValidator({
      regex: validator?.[name].regex,
      value,
      message: validator[name].message,
    });

    setIsError((prev) => ({
      ...prev,
      [name]: errorMessgae,
    }));
  };

  const toggleForgotPasswordModal = () => {
    setShowForgotPasswordStatus((prev) => !prev);
  };

  if (loginData.data) {
    /* FOR KYC */
    if (loginData?.data?.items?.[0]?.kycStatus !== "approved") {
      if (loginData?.data?.items?.[0]?.user?.roles?.[0] === "banker") {
        return (
          <Navigate
            to="/kyc/kyc-verification-form"
            replace={true}
            state={{ loginData: loginData.data }}
          />
        );
      }
      if (
        loginData?.data?.items?.[0]?.user?.roles[0] === "seller" ||
        loginData?.data?.items?.[0]?.user?.roles[0] === "anchor"
      ) {
        return (
          <Navigate
            to="/seller/kyc-verification-form"
            replace={true}
            state={{ loginData: loginData.data }}
          />
        );
      }
      if (loginData?.data?.items?.[0]?.user?.roles?.[0] === "buyer") {
        return (
          <Navigate
            to="/buyer/kyc-verification-form"
            replace={true}
            state={{ loginData: loginData.data }}
          />
        );
      }
      if (loginData?.data?.items?.[0]?.user?.roles?.[0] === "channel partner") {
        return (
          <Navigate
            to="/channel/kyc-verification-form"
            replace={true}
            state={{ loginData: loginData.data }}
          />
        );
      }
      if (
        loginData?.data?.items?.[0]?.user?.roles?.[0] === "corporate" ||
        loginData?.data?.items?.[0]?.user?.roles?.[0] === "corporate"
      ) {
        return (
          <Navigate
            to="/corporate/kyc-verification-form"
            replace={true}
            state={{ loginData: loginData.data }}
          />
        );
      }
      if (
        loginData?.data?.items?.[0]?.user?.roles?.[0] === "relation_manager" ||
        loginData?.data?.items?.[0]?.user?.roles?.[0] === "corporate"
      ) {
        return (
          <Navigate
            to="/rm/kyc-verification-form"
            replace={true}
            state={{ loginData: loginData.data }}
          />
        );
      }
      if (
        loginData?.data?.items?.[0]?.user?.roles?.[0] === "underwriter" ||
        loginData?.data?.items?.[0]?.user?.roles?.[0] === "underwriter"
      ) {
        return (
          <Navigate
            to="/underwriter/kyc-verification-form"
            replace={true}
            state={{ loginData: loginData.data }}
          />
        );
      }
    }

    if (loginData?.data?.items?.[0]?.user?.adminSubmitStatus !== "submitted") {
      if (
        loginData?.data?.items?.[0]?.user?.roles[0] === "seller" ||
        loginData?.data?.items?.[0]?.user?.roles[0] === "anchor"
      ) {
        return (
          <Navigate
            to="/seller/kyc-verification-form"
            replace={true}
            state={{ loginData: loginData.data }}
          />
        );
      }

      if (loginData?.data?.items?.[0]?.user?.roles?.[0] === "buyer") {
        return (
          <Navigate
            to="/buyer/kyc-verification-form"
            replace={true}
            state={{ loginData: loginData.data }}
          />
        );
      }
    }

    /* FOR REGULAR LOGIN */
    if (loginData?.data?.items?.[0]?.kycStatus === "approved") {
      switch (loginData.data.items[0].user.roles[0]) {
        case "admin":
          return <Navigate to="/admin/dashboard" replace />;
        case "banker":
          return <Navigate to="/lender/dashboard" replace />;
        case "seller":
          return <Navigate to="/anchor/dashboard" replace />;
        case "buyer":
          return <Navigate to="/buyer/dashboard" replace />;
        case "channel partner":
          return <Navigate to="/channel-partner/dashboard" replace />;
        case "relation_manager":
          return <Navigate to="/rm/dashboard" replace />;
        case "corporate":
          return <Navigate to="/corporate/dashboard" replace />;
        case "underwriter":
          return <Navigate to="/underwriter/limit" replace />;
        default:
          return <Navigate to="/login" replace />;
      }
    }
  }

  /**
   * @param { React.ChangeEvent<HTMLInputElement> } event
   */
  function handleChangeMobileNumber(event) {
    if (event.target.value.length > 10) {
      return;
    }

    setMobileNumber((previous) => ({
      value: event.target.value,
      isTouched: true,
      isValid: false,
      errorMessage: "",
    }));
  }

  /**
   * @param { React.ChangeEvent<HTMLInputElement> } event
   */
  function handleChangeOTP(event) {
    if (event.target.value.length > 6) {
      return;
    }

    setOTP((previous) => ({
      value: event.target.value,
      isTouched: true,
      isValid: false,
      errorMessage: "",
    }));
  }

  /**
   * @param { React.FocusEvent<HTMLInputElement> } event
   */
  function handleBlurMobileNumber(event) {
    const regex =  /^(?!(9999999999|8888888888|7777777777|6666666666|9876543210)$)[6-9][0-9]{9}$/;

    if (regex.test($mobileNumber.value)) {
      setMobileNumber((previous) => ({
        ...previous,
        isValid: true,
        errorMessage: "",
      }));
    } else {
      setMobileNumber((previous) => ({
        ...previous,
        isValid: false,
        errorMessage:
          previous.value.length === 0
            ? "Mobile Number is Required"
            : "Invalid Mobile Number",
      }));
    }
  }

  /**
   * @param { React.FocusEvent<HTMLInputElement> } event
   */
  function handleBlurOTP(event) {
    if ($otp.value.length === 6) {
      setOTP((previous) => ({ ...previous, isValid: true, errorMessage: "" }));
    } else {
      setOTP((previous) => ({
        ...previous,
        isValid: false,
        errorMessage: "Invalid OTP",
      }));
    }
  }

  /**
   * @param { React.SyntheticEvent<Element, Event> } event
   * @param { number } newVal
   */
  const handleChangeTab = (event, newValue) => {
    if (newValue === 0) {
      setMobileNumber((previous) => ({
        value: "",
        isTouched: false,
        isValid: false,
        errorMessage: "",
      }));
      setOTP((previous) => ({
        value: "",
        isTouched: false,
        isValid: false,
        errorMessage: "",
      }));
      setShowCounter(false);
      setShowOTP(false);
    }

    setTabIndex(newValue);

    setLoginWithMobile(newValue === 0 ? false : true);
  };

  function handleSubmitMobileNumber() {
    if (!$mobileNumber.isValid) {
      return;
    }

    dispatch(getOTPForLogin({ mobileNumber: $mobileNumber.value }))
      .unwrap()
      .then((data) => {
        console.log("otp", data);
        toastMessage(data?.success, "success");
        setCounter(30);
        setShowCounter(true);
        setShowOTP(true);

        setTimeout(() => {
          counterRef.current = setInterval(() => {
            setCounter((previous) => {
              if (previous === 0) {
                clearInterval(counterRef.current);
                return previous;
              }

              return previous - 1;
            });
          }, 1000);
        }, 100);
      })
      .catch((error) => {
        console.log("otp error", error.message);
        toastMessage(error?.message, "error");
      });
  }

  function handleLoginWithOTP() {
    if (!$mobileNumber.isValid || !$otp.isValid) {
      return;
    }

    dispatch(otpLogin({ mobileNumber: $mobileNumber.value, otp: $otp.value }))
      .unwrap()
      .then((data) => {
        toastMessage(data?.success ?? "Logged In Successfully", "success");
      })
      .catch((error) => {
        toastMessage(error?.message, "error");
      });
  }

  return (
    <>
      <ReusableModal
        showModal={showForgotPasswordStatus}
        toggleModal={toggleForgotPasswordModal}
        modalContent={
          <ForgotPassowordModal
            toggleForgotPasswordModal={toggleForgotPasswordModal}
          />
        }
        modalSizeClass="modal-lg"
      />
      <Helmet>
        <title>Login</title>
      </Helmet>
      <form
        onSubmit={onSubmitLogIn}
        className="actual-login-cont"
        style={{ position: "relative", width: "100%", height: "100%" }}
      >
        <div className="row m-0 actual-login-cont">
          <div className="col-md-2 i-vanish-mobile-view"></div>
          <div className="col-md-8 mt-md-5 mt-2">
            <div className="row d-flex align-items-center">
              <div
                className="
              col-md-8 
              d-flex 
              justify-content-center 
              align-items-center 
              flex-column 
              d-md-block
              align-content-around
              pt-md-0
              gap-0
              flex-wrap
              pt-3"
              >
                <span className="txt-welc">Welcome!</span> <br />
                <span className="txt-yr-acc">Sign in to your account</span>
              </div>

              <div className="col-md-4 d-flex justify-content-md-end justify-content-center">
                <img src={atonLogo} style={{ width: "80px" }} />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8 mt-1">
              <Tabs
                value={tabIndex}
                onChange={handleChangeTab}
                aria-label="basic tabs"
              >
                <Tab label="Email" {...a11yProps(0)} />
                <Tab label="Mobile Number" {...a11yProps(1)} />
              </Tabs>

              <CustomTabPanel value={tabIndex} index={0}>
                <div className="row px-3 mb-4">
                  <label htmlFor="login-email" className="label-text">
                    Email
                  </label>
                  <div
                    className={`input-container form-control d-flex align-items-center ${
                      isError?.email && "invalid-input"
                    }`}
                  >
                    <input
                      onChange={onChangeCredentials}
                      onBlur={onBlurInputFeilds}
                      className="input-element d-flex flex-grow-1"
                      placeholder="Enter Email Id"
                      name="email"
                      type="email"
                      id="login-email"
                      // required
                    />
                    <MdEmail
                      // className={`input-icon ${
                      //   isError?.email ? "text-danger" : "text-success"
                      // }`}
                      className={`input-icon`}
                    />
                  </div>
                  <span className="text-danger px-1">{isError?.email}</span>
                </div>

                <div className="row px-3 mb-4">
                  <label className="label-text" htmlFor="login-psw">
                    Password
                  </label>
                  <div
                    className={`input-container form-control d-flex align-items-center ${
                      isError?.password && "invalid-input"
                    }`}
                  >
                    <input
                      onChange={onChangeCredentials}
                      onBlur={onBlurInputFeilds}
                      className="input-element d-flex flex-grow-1"
                      placeholder="Enter Password"
                      name="password"
                      id="login-psw"
                      type={isPasswordVisible ? "text" : "password"}
                      // required
                    />
                    {isPasswordVisible ? (
                      <IoIosUnlock
                        // className={`input-icon ${
                        //   isError?.password ? "text-secondary" : "text-danger"
                        // }`}
                        className={`input-icon`}
                        onClick={onClickPasswordVisibilityStatus}
                      />
                    ) : (
                      <IoIosLock
                        // className={`input-icon ${
                        //   isError?.password ? "text-secondary" : "text-success"
                        // }`}
                        className={`input-icon`}
                        onClick={onClickPasswordVisibilityStatus}
                      />
                    )}
                  </div>
                  <span className="text-danger px-1">{isError?.password}</span>
                  <div className="p-0 d-flex justify-content-between mt-2">
                    <div className="sign-up-text" onClick={onClickSignUp}>
                      Don't have an account?{" "}
                      <span className="sign-up-text-highlighted">Sign up</span>
                    </div>

                    <div
                      onClick={toggleForgotPasswordModal}
                      className="sign-up-text text-primary"
                    >
                      Forgot Password?{" "}
                    </div>
                  </div>
                </div>
              </CustomTabPanel>

              <CustomTabPanel value={tabIndex} index={1}>
                <div className="row px-3 mb-4">
                  <label className="label-text" htmlFor="mob-num">
                    Mobile Number
                  </label>
                  <div
                    className={`input-container form-control d-flex align-items-center ${
                      $mobileNumber.isTouched &&
                      !$mobileNumber.isValid &&
                      "invalid-input"
                    }`}
                  >
                    <input
                      onChange={handleChangeMobileNumber}
                      onBlur={handleBlurMobileNumber}
                      className="input-element d-flex flex-grow-1"
                      placeholder="Enter Mobile Number"
                      name="phone"
                      type="number"
                      id="mob-num"
                      min={0}
                      value={$mobileNumber.value}
                      maxLength={10}
                    />
                    <MdPhone className={`input-icon`} />
                  </div>
                  <span className="text-danger px-1">
                    {$mobileNumber.errorMessage}
                  </span>
                  {$showCounter && (
                    <span className="text-success px-1">
                      {$counter === 0 ? (
                        <button
                          className="btn"
                          type="button"
                          onClick={handleSubmitMobileNumber}
                        >
                          Click here to resend OTP
                        </button>
                      ) : (
                        `Resend OTP in ${$counter} sec`
                      )}
                    </span>
                  )}
                </div>

                {$showOTP && (
                  <div className="row px-3 mb-4">
                    <label className="label-text">OTP</label>
                    <div
                      className={`input-container form-control d-flex align-items-center ${
                        $otp.isTouched && !$otp.isValid && "invalid-input"
                      }`}
                    >
                      <input
                        onChange={handleChangeOTP}
                        onBlur={handleBlurOTP}
                        className="input-element d-flex flex-grow-1"
                        placeholder="Enter OTP"
                        name="otp"
                        type="number"
                        min={0}
                        value={$otp.value}
                        maxLength={6}
                      />
                      <IoIosLock className={`input-icon`} />
                    </div>
                    <span className="text-danger px-1">
                      {$otp.errorMessage}
                    </span>
                  </div>
                )}
              </CustomTabPanel>
            </div>
            <div className="col-md-2"></div>
          </div>

          <div className="col-md-2"></div>
        </div>
        {!$loginWithMobile ? (
          <button type="submit" className="sign-in-btn">
            {loginData.isLoading ? (
              <span className="loader mx-auto"></span>
            ) : (
              "Sign In"
            )}
          </button>
        ) : !$showOTP ? (
          <button
            className="sign-in-btn"
            type="button"
            disabled={$showCounter}
            onClick={handleSubmitMobileNumber}
          >
            {getOTPForLoginData.isLoading ? (
              <span className="loader mx-auto"></span>
            ) : (
              "Get OTP"
            )}
          </button>
        ) : (
          <button
            className="sign-in-btn"
            type="button"
            onClick={handleLoginWithOTP}
          >
            {loginData.isLoading ? (
              <span className="loader mx-auto"></span>
            ) : (
              "Login"
            )}
            
          </button>
        )}
      </form>
      {/* </div>
        </div>
      </div> */}
    </>
  );
};

export default Login;
