import { CorporateKycActions } from "../actionTypes";

// eslint-disable-next-line no-unused-vars
import { CorporateKYCFormTemplate, } from "../corporateKycContext";

export const actions = CorporateKycActions.DOCUMENT_DETAIL;

/**
 * @param { typeof CorporateKYCFormTemplate.documentDetails } state
 * @param {{ type: typeof actions, payload: string | BufferSource }} param1
 * @returns { typeof CorporateKYCFormTemplate.documentDetails }
 * */
export function DocumentDetailsReducer(state, { type, payload }) {
	if (type === actions.CHANGE_LOAN) {
		const value = payload ?? state.loanAmount.value;
		const isValid = value !== "" && !isNaN(parseInt(value)) && parseInt(value) > 0;

		return { ...state, loanAmount: { value, isValid, isTouched: true } };
	}
	else if (type === actions.BLUR_LOAN) {
		return { ...state, loanAmount: { ...state.loanAmount, isTouched: true } };
	}
	else if (type === actions.TOUCH_ALL) {
		for (let item in state) {
			for (let obj in state[item]) {
				if (obj === "isTouched") {
					state[item][obj] = true;
				}
			}
		}
		return { ...state };
	}

	return state;
}
