import React, { useEffect, useState } from "react";
import { IoIosLock, IoIosUnlock } from "react-icons/io";
import { useParams, useNavigate } from "react-router-dom";
import atonLogo from "../assests/images/Aton-logo.png";
//import { InputValidator } from "../layout/GenericLayout/InputValidator";
import { toastMessage } from "../layout/GenericLayout/ToastMessage";
import { useDispatch, useSelector } from "react-redux";
import { resetForgotPassword } from "../store/slices/generic";
import { Helmet } from "react-helmet";

const ResetPassword = () => {
  const [isError, setIsError] = useState(null);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const resetForgotPasswordData = useSelector(
    (state) => state.generic.resetForgotPassword
  );

  const { emailId, token } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
    if (!emailId || !token) {
      toastMessage("Email Id or Token not available in URL", "error");

      setTimeout(() => navigate("/login"), 3500);
    } else if (!emailRegex.test(emailId)) {
      toastMessage("Invalid Email Id", "error");

      setTimeout(() => navigate("/login"), 3500);
    }
  }, [emailId, token, navigate]);

  const onBlurInputFeilds = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    const validator = {
      password: {
        regex: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/g,
        message:
          value === ""
            ? "Password is required"
            : "8-15 chars long, at least 1 uppercase, 1 lowercase, 1 number, 1 special character required",
      },
      confirm_password: {
        regex: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/g,
        message:
          value === ""
            ? "Confirm Password is required"
            : value !== password
            ? "Does not match with password"
            : "",
      },
    };

    const InputValidation = ({ regex, value, password, message }) => {
      if (!regex.test(value)) {
        return message;
      }
      if (password !== value) {
        return message;
      }
      return null;
    };

    const errorMessage = InputValidation({
      regex: validator?.[name].regex,
      value,
      password,
      message: validator[name].message,
    });

    setIsError((prev) => ({
      ...prev,
      [name]: errorMessage,
    }));
  };

  const onClickPasswordVisibilityStatus = () => {
    setIsPasswordVisible((prev) => !prev);
  };

  const onClickConfirmPasswordVisibilityStatus = () => {
    setIsConfirmPasswordVisible((prev) => !prev);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (isError === null) {
      toastMessage("Password Fields cannot be empty", "error");
    } else {
      for (let error in isError) {
        if (isError[error] !== null) {
          toastMessage(isError[error], "error");
          return;
        }
      }
    }

    if (password !== confirmPassword) {
      toastMessage("Passwords do not match", "error");
      return;
    }

    const payload = {
      emailId: emailId,
      oldPassword: token,
      newPassword: password,
    };

    dispatch(resetForgotPassword(payload))
      .unwrap()
      .then((data) => {
        toastMessage("Password Reset Successful", "success");
        setTimeout(() => navigate("/login"), 3500);
      })
      .catch((err) => {
        toastMessage(err?.message, "error");
      });
  };

  return (
    <form
      className="actual-login-cont"
      style={{ position: "relative", width: "100%", height: "100%" }}
      onSubmit={handleOnSubmit}
    >
      <Helmet>
        <title>Reset-Password</title>
      </Helmet>
      <div className="row m-0 actual-login-cont">
        <div className="col-md-2 i-vanish-mobile-view"></div>
        <div className="col-md-8 mt-md-5 mt-2">
          <div className="row d-flex align-items-center">
            <div
              className="
              col-md-8 
              d-flex 
              justify-content-center 
              align-items-center 
              flex-column 
              d-md-block
              align-content-around
              pt-md-0
              gap-0
              flex-wrap
              pt-3"
            >
              <span className="txt-welc">Welcome!</span> <br />
              <span className="txt-yr-acc">Reset your password</span>
            </div>

            <div className="col-md-4 d-flex justify-content-md-end justify-content-center">
              <img src={atonLogo} style={{ width: "80px" }} alt="aton logo" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8 mt-1">
            <div className="row px-3 mb-4">
              <label className="label-text" htmlFor="login-psw">
                New Password
              </label>
              <div
                className={`input-container form-control d-flex align-items-center ${
                  isError?.password && "invalid-input"
                }`}
              >
                <input
                  onBlur={onBlurInputFeilds}
                  className="input-element d-flex flex-grow-1"
                  placeholder="Enter Password"
                  name="password"
                  id="login-psw"
                  type={isPasswordVisible ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {isPasswordVisible ? (
                  <IoIosUnlock
                    className={`input-icon`}
                    onClick={onClickPasswordVisibilityStatus}
                  />
                ) : (
                  <IoIosLock
                    className={`input-icon`}
                    onClick={onClickPasswordVisibilityStatus}
                  />
                )}
              </div>
              <span className="text-danger px-1">{isError?.password}</span>
            </div>

            <div className="row px-3 mb-4">
              <label className="label-text" htmlFor="confirm-login-psw">
                Confirm Password
              </label>
              <div
                className={`input-container form-control d-flex align-items-center ${
                  isError?.confirm_password && "invalid-input"
                }`}
              >
                <input
                  onBlur={onBlurInputFeilds}
                  className="input-element d-flex flex-grow-1"
                  placeholder="Enter Confirm Password"
                  name="confirm_password"
                  id="confirm-login-psw"
                  type={isConfirmPasswordVisible ? "text" : "password"}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                {isConfirmPasswordVisible ? (
                  <IoIosUnlock
                    className={`input-icon`}
                    onClick={onClickConfirmPasswordVisibilityStatus}
                  />
                ) : (
                  <IoIosLock
                    className={`input-icon`}
                    onClick={onClickConfirmPasswordVisibilityStatus}
                  />
                )}
              </div>
              <span className="text-danger px-1">
                {isError?.confirm_password}
              </span>
            </div>
          </div>
        </div>
      </div>
      <button className="sign-in-btn" type="submit">
        {resetForgotPasswordData.isLoading ? (
          <span className="loader mx-auto"></span>
        ) : (
          "Change Password"
        )}
      </button>
    </form>
  );
};

export default ResetPassword;
