import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../utilities/api";
import { baseUrl } from "../../utilities/config";

const initialState = {
  /* FROM ADMIN DAHSBOARD */
  customerOnBoardData: {
    data: null,
    isLoading: false,
    error: null,
  },
  partnerOnboardData: {
    data: null,
    isLoading: false,
    error: null,
  },
  corporateOnboardData: {
    data: null,
    isLoading: false,
    error: null,
  },
  relationManagerOnboardData: {
    data: null,
    isLoading: false,
    error: null,
  },
  kycData: {
    data: null,
    isLoading: false,
    error: null,
  },
  kycApproveData: {
    data: null,
    isLoading: false,
    error: null,
  },

  /* FROM ANDCHOR & LENDER */
  selectAnchorData: {
    data: null,
    isLoading: false,
    error: null,
  },
  selectLenderData: {
    data: null,
    isLoading: false,
    error: null,
  },
  anchorAndLenderMappedData: {
    data: null,
    isLoading: false,
    error: null,
  },
  anchorAndLenderUpdatedMapData: {
    data: null,
    isLoading: false,
    error: null,
  },

  /* FROM USERS */
  usersData: {
    data: null,
    isLoading: false,
    error: null,
  },
  allUsersByCustomerIdData: {
    data: null,
    isLoading: false,
    error: null,
  },
  camByEmailIdData: {
    data: null,
    isLoading: false,
    error: null,
  },
  scoreCardByEmailIdData: {
    data: null,
    isLoading: false,
    error: null,
  },

  /* CHANNEL PARTNER */
  channelPartnerData: {
    data: null,
    isLoading: false,
    error: null,
  },

  /* RELATIONSHIP MANAGER DETAILS */
  relationshipManagerData: {
    data: null,
    isLoading: false,
    error: null,
  },
  relationshipManagerAddData: {
    data: null,
    isLoading: false,
    error: null,
  },
  relationshipManagerEditData: {
    data: null,
    isLoading: false,
    error: null,
  },
  relationshipManagerDeleteData: {
    data: null,
    isLoading: false,
    error: null,
  },

  /* LENDER DETAILS */
  lenderData: {
    data: null,
    isLoading: false,
    error: null,
  },
  lenderAddData: {
    data: null,
    isLoading: false,
    error: null,
  },
  lenderEditData: {
    data: null,
    isLoading: false,
    error: null,
  },
  lenderDeleteData: {
    data: null,
    isLoading: false,
    error: null,
  },
  superAdminData: {
    data: null,
    isLoading: false,
    error: null,
  },
  getNumberOfLenders: {},
  getNumberOfCounterParty: {},
  getNumberOfAnchors: {},
  getNumberOfInvoicesDiscounted: {},
  getDashboardData:{},

  userDataKycSubmitToLenderData: {
    data: null,
    isLoading: false,
    error: null,
  },
  fetchAuditTrail: { data: null, error: null, isLoading: false },
};

/* FROM ADMIN DASHBOARD */
export const getDashboardData = createAsyncThunk(
  "admin/admin_dashboard",
  async () => {
    try {
      const url = "admin/admin_dashboard";
      const response = await api.get(url, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAuditTrail = createAsyncThunk(
  "get/audit_trail",
  async (obj) => {
    try {
      /**
       * @type {import("axios").AxiosHeaders}
       */
      const headers = {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      };

      const { data } = await api.get(
        `admin/get_audit_trail?limit=${
          obj.limit ?? 10
        }&offset=${obj.offset ?? 10}&field=${obj.field??""}&search=${obj.search ?? ""}`,
        { headers }
      );
      return data;
    } catch (err) {
      throw new Error(err?.response?.data?.error);
    }
  }
);


export const getCustomerOnboardData = createAsyncThunk(
  "get/customerOnboard",
  async () => {
    try {
      const url = "admin/kyc";
      const response = await api.get(url, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const getPartnerOnboardData = createAsyncThunk(
  "get/partnerOnboard",
  async () => {
    try {
      const url = "channelpartner/get_all_users_for_admin";
      const response = await api.get(url, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const getCorporateOnboardData = createAsyncThunk(
  "get/corporateOnboard",
  async () => {
    try {
      const url = "admin/get_corporate_kyc";
      const response = await api.get(url, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const getRelationManagerOnBoardData = createAsyncThunk(
  "get/relation_manager_on_board_data",
  async () => {
    try {
      const url = "/relationmanager/get_all_users_for_admin";
      const response = await api.get(url, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const getKycData = createAsyncThunk("get/kyc", async (emailId) => {
  try {
    const url = `admin/kyc/${emailId}`;
    const response = await api.get(url, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
});

export const updateKycApproveData = createAsyncThunk(
  "update/kycApprove",
  async (body) => {
    try {
      const url = `admin/kyc`;
      const response = await api.post(url, body, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.response);
    }
  }
);

/* FROM ANCHOR & LENDER */
export const getSelectAnchor = createAsyncThunk(
  "get/selectAnchor",
  async () => {
    try {
      const url = "/user/seller";
      const response = await api.get(url, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

export const getSelectLender = createAsyncThunk(
  "get/selectLender",
  async () => {
    try {
      const url = "/user/list_of_banks";
      const response = await api.get(url, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

export const getAnchorAndLenderMapped = createAsyncThunk(
  "get/anchorAndLenderMapped",
  async () => {
    try {
      const url = "admin/get_map_list";
      const response = await api.get(url, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

export const updateAnchorAndLenderMapped = createAsyncThunk(
  "update/anchorAndLenderMapped",
  async (data) => {
    try {
      const url = "admin/maplender";
      const response = await api.put(url, data, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

/* FROM USERS */
export const getUsers = createAsyncThunk("get/users", async () => {
  try {
    const url = "user/get_all_distributors";
    const response = await api.get(url, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
});

export const getAllUsersByCustomerId = createAsyncThunk(
  "get/allUsersByCustoerId",
  async (customerId) => {
    try {
      const url = `user/get_all_retailers/${customerId}`;
      const response = await api.get(url, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

export const getCamDataByEmailId = createAsyncThunk(
  "get/camDataByEmailId",
  async (emailId) => {
    try {
      const url = `cam/get_cam/${emailId}`;
      // const url = `${baseUrl}cam/get_cam/xatag64888@inkiny.com`;
      const response = await api.get(url, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const getScoreCardDataByEmailId = createAsyncThunk(
  "get/scoreCardDataByEmailId",
  async (emailId) => {
    try {
      const url = `user/getdetails/${emailId}`;
      const response = await api.get(url, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

/* FROM CHANNEL PARTNER */
export const getAllChannelPartner = createAsyncThunk(
  "get/allChannelPartner",
  async () => {
    try {
      const url = `channelpartner/get_all_channel_partners`;
      const response = await api.get(url, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

/* FROM RELATIONSHIP MANAGER*/
export const getAllRelationshipManager = createAsyncThunk(
  "get/allRelationshipManager",
  async (tableData) => {
    try {
      const url = `relationmanager/get_all_relation_manager${tableData}`;
      const response = await api.get(url, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);
export const addRelationshipManager = createAsyncThunk(
  "add/relationshipManager",
  async (body) => {
    try {
      const url = `admin/add_relation_manager`;
      const response = await api.post(url, body, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);
export const updateRelationshipManager = createAsyncThunk(
  "update/relationshipManager",
  async (body) => {
    try {
      const url = `admin/edit_relation_manager`;
      const response = await api.put(url, body, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);
export const deleteRelationshipManager = createAsyncThunk(
  "delete/relationshipManager",
  async (body) => {
    try {
      const url = `admin/disable_relation_manager`;
      const response = await api.delete(url, body, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

/* FROM LENDER*/
export const getAllLender = createAsyncThunk(
  "get/allLender", 
  async (tableData) => {
  try {
    const url = `admin/get_all_lenders${tableData}`;
    const response = await api.get(url, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
});
export const addLender = createAsyncThunk("add/lender", async (body) => {
  try {
    const url = `admin/add_lender`;
    const response = await api.post(url, body, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
});
export const updateLender = createAsyncThunk("update/lender", async (body) => {
  try {
    const url = `admin/edit_lender`;
    const response = await api.put(url, body, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
});
export const deleteLender = createAsyncThunk("delete/lender", async (body) => {
  try {
    // const url = `admin/disable_relation_manager`;
    // const response = await api.delete(url, body, {
    //   headers: {
    //     Authorization: "Bearer " + sessionStorage.getItem("token"),
    //   },
    // });
    // return response.data;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
});

export const getSuperAdminData = createAsyncThunk(
  "get/superAdmin",
  async (body) => {
    try {
      const url = `/admin/invoiceDiscountList?limit=${body?.limit}&offset=${body?.offset}`;
      const response = await api.post(url, body, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

export const userDataKycSubmitToLender = createAsyncThunk(
  "put/userDataKycSubmitToLender",
  async (body) => {
    try {
      const url = `admin/submit_to_lender`;
      const response = await api.put(url, body, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    resetAdminState: (state) => initialState,
  },
  extraReducers(builder) {
    builder

      /*******FROM ADMIN DASHBOARD*****/
      /* GET CUSTOMER ONBOARD DATA */
      .addCase(getCustomerOnboardData.pending, (state, action) => {
        state.customerOnBoardData.isLoading = true;
      })
      .addCase(getCustomerOnboardData.fulfilled, (state, action) => {
        state.customerOnBoardData.data = action.payload;
        state.customerOnBoardData.isLoading = false;
      })
      .addCase(getCustomerOnboardData.rejected, (state, action) => {
        state.customerOnBoardData.error = action.error.message;
        state.customerOnBoardData.isLoading = false;
      })
      /* GET PARTNER ONBOARD DATA */
      .addCase(getPartnerOnboardData.pending, (state, action) => {
        state.partnerOnboardData.isLoading = true;
      })
      .addCase(getPartnerOnboardData.fulfilled, (state, action) => {
        state.partnerOnboardData.data = action.payload;
        state.partnerOnboardData.isLoading = false;
      })
      .addCase(getPartnerOnboardData.rejected, (state, action) => {
        state.partnerOnboardData.error = action.error.message;
        state.partnerOnboardData.isLoading = false;
      })
      /* GET CORPORATE ONBOARD DATA */
      .addCase(getCorporateOnboardData.pending, (state, action) => {
        state.corporateOnboardData.isLoading = true;
      })
      .addCase(getCorporateOnboardData.fulfilled, (state, action) => {
        state.corporateOnboardData.data = action.payload;
        state.corporateOnboardData.isLoading = false;
      })
      .addCase(getCorporateOnboardData.rejected, (state, action) => {
        state.corporateOnboardData.error = action.error.message;
        state.corporateOnboardData.isLoading = false;
      })
      .addCase(getRelationManagerOnBoardData.pending, (state, action) => {
        state.relationManagerOnboardData.isLoading = true;
      })
      .addCase(getRelationManagerOnBoardData.fulfilled, (state, action) => {
        state.relationManagerOnboardData.data = action.payload;
        state.relationManagerOnboardData.isLoading = false;
      })
      .addCase(getRelationManagerOnBoardData.rejected, (state, action) => {
        state.relationManagerOnboardData.error = action.error.message;
        state.relationManagerOnboardData.isLoading = false;
      })
      /* GET KYC DATA */
      .addCase(getKycData.pending, (state, action) => {
        state.kycData.isLoading = true;
      })
      .addCase(getKycData.fulfilled, (state, action) => {
        state.kycData.data = action.payload;
        state.kycData.isLoading = false;
      })
      .addCase(getKycData.rejected, (state, action) => {
        state.kycData.error = action.error.message;
        state.kycData.isLoading = false;
      })
      /* UPDATE KYC UDATE DATA */
      .addCase(updateKycApproveData.pending, (state, action) => {
        state.kycApproveData.isLoading = true;
      })
      .addCase(updateKycApproveData.fulfilled, (state, action) => {
        state.kycApproveData.data = action.payload;
        state.kycApproveData.isLoading = false;
      })
      .addCase(updateKycApproveData.rejected, (state, action) => {
        state.kycApproveData.error = action.error.message;
        state.kycApproveData.isLoading = false;
      })

      /*******FROM ADMIN ANCHOR & LENDER*****/
      /* GET SELECT ANCHOR DATA */
      .addCase(getSelectAnchor.pending, (state, action) => {
        state.selectAnchorData.isLoading = true;
      })
      .addCase(getSelectAnchor.fulfilled, (state, action) => {
        state.selectAnchorData.data = action.payload;
        state.selectAnchorData.isLoading = false;
      })
      .addCase(getSelectAnchor.rejected, (state, action) => {
        state.selectAnchorData.error = action.error.message;
        state.selectAnchorData.isLoading = false;
      })
      /* GET SELECT LENDER DATA */
      .addCase(getSelectLender.pending, (state, action) => {
        state.selectLenderData.isLoading = true;
      })
      .addCase(getSelectLender.fulfilled, (state, action) => {
        state.selectLenderData.data = action.payload;
        state.selectLenderData.isLoading = false;
      })
      .addCase(getSelectLender.rejected, (state, action) => {
        state.selectLenderData.error = action.error.message;
        state.selectLenderData.isLoading = false;
      })
      /* GET ANCHOR AND LENDER MAPPED DATA */
      .addCase(getAnchorAndLenderMapped.pending, (state, action) => {
        state.anchorAndLenderMappedData.isLoading = true;
      })
      .addCase(getAnchorAndLenderMapped.fulfilled, (state, action) => {
        state.anchorAndLenderMappedData.data = action.payload;
        state.anchorAndLenderMappedData.isLoading = false;
      })
      .addCase(getAnchorAndLenderMapped.rejected, (state, action) => {
        state.anchorAndLenderMappedData.error = action.error.message;
        state.anchorAndLenderMappedData.isLoading = false;
      })
      /* UPDATE ANCHOR AND LENDER MAPPED DATA */
      .addCase(updateAnchorAndLenderMapped.pending, (state, action) => {
        state.anchorAndLenderUpdatedMapData.isLoading = true;
      })
      .addCase(updateAnchorAndLenderMapped.fulfilled, (state, action) => {
        state.anchorAndLenderUpdatedMapData.data = action.payload;
        state.anchorAndLenderUpdatedMapData.isLoading = false;
      })
      .addCase(updateAnchorAndLenderMapped.rejected, (state, action) => {
        state.anchorAndLenderUpdatedMapData.error = action.error.message;
        state.anchorAndLenderUpdatedMapData.isLoading = false;
      })

      /*******FROM ADMIN USERS*******/
      /* GET USERS LIST */
      .addCase(getUsers.pending, (state, action) => {
        state.usersData.isLoading = true;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.usersData.data = action.payload;
        state.usersData.isLoading = false;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.usersData.error = action.error.message;
        state.usersData.isLoading = false;
      })
      /* GET ALL USERS LIST BY CUSTOMER ID */
      .addCase(getAllUsersByCustomerId.pending, (state, action) => {
        state.allUsersByCustomerIdData.data = null;
        state.allUsersByCustomerIdData.error = null;
        state.allUsersByCustomerIdData.isLoading = true;
      })
      .addCase(getAllUsersByCustomerId.fulfilled, (state, action) => {
        state.allUsersByCustomerIdData.data = action.payload;
        state.allUsersByCustomerIdData.isLoading = false;
      })
      .addCase(getAllUsersByCustomerId.rejected, (state, action) => {
        state.allUsersByCustomerIdData.error = action.error.message;
        state.allUsersByCustomerIdData.isLoading = false;
      })
      /* GET CAM DETAILS BY EMAIL ID */
      .addCase(getCamDataByEmailId.pending, (state, action) => {
        state.camByEmailIdData.isLoading = true;
      })
      .addCase(getCamDataByEmailId.fulfilled, (state, action) => {
        state.camByEmailIdData.data = action.payload;
        state.camByEmailIdData.isLoading = false;
      })
      .addCase(getCamDataByEmailId.rejected, (state, action) => {
        state.camByEmailIdData.error = action.error.message;
        state.camByEmailIdData.isLoading = false;
      })
      /* GET SCORE CARD DETAILS BY EMAIL ID */
      .addCase(getScoreCardDataByEmailId.pending, (state, action) => {
        state.scoreCardByEmailIdData.isLoading = true;
      })
      .addCase(getScoreCardDataByEmailId.fulfilled, (state, action) => {
        state.scoreCardByEmailIdData.data = action.payload;
        state.scoreCardByEmailIdData.isLoading = false;
      })
      .addCase(getScoreCardDataByEmailId.rejected, (state, action) => {
        state.scoreCardByEmailIdData.error = action.error.message;
        state.scoreCardByEmailIdData.isLoading = false;
      })

      /*******FROM ADMIN CHANNEL PARTNER*******/
      /* GET ALL CHANNEL PARTNER */
      .addCase(getAllChannelPartner.pending, (state, action) => {
        state.channelPartnerData.isLoading = true;
      })
      .addCase(getAllChannelPartner.fulfilled, (state, action) => {
        state.channelPartnerData.data = action.payload;
        state.channelPartnerData.isLoading = false;
      })
      .addCase(getAllChannelPartner.rejected, (state, action) => {
        state.channelPartnerData.error = action.error.message;
        state.channelPartnerData.isLoading = false;
      })

      /*******FROM RELATIONSHIP MANAGER*******/
      /* GET ALL RELATIONSHIP MANAGER */
      .addCase(getAllRelationshipManager.pending, (state, action) => {
        state.relationshipManagerData.isLoading = true;
      })
      .addCase(getAllRelationshipManager.fulfilled, (state, action) => {
        state.relationshipManagerData.data = action.payload;
        state.relationshipManagerData.isLoading = false;
      })
      .addCase(getAllRelationshipManager.rejected, (state, action) => {
        state.relationshipManagerData.error = action.error.message;
        state.relationshipManagerData.isLoading = false;
      })
      /* ADD RELATIONSHIP MANAGER */
      .addCase(addRelationshipManager.pending, (state, action) => {
        state.relationshipManagerAddData.isLoading = true;
      })
      .addCase(addRelationshipManager.fulfilled, (state, action) => {
        state.relationshipManagerAddData.data = action.payload;
        state.relationshipManagerAddData.isLoading = false;
      })
      .addCase(addRelationshipManager.rejected, (state, action) => {
        state.relationshipManagerAddData.error = action.error.message;
        state.relationshipManagerAddData.isLoading = false;
      })
      /* UPDATE RELATIONSHIP MANAGER */
      .addCase(updateRelationshipManager.pending, (state, action) => {
        state.relationshipManagerEditData.isLoading = true;
      })
      .addCase(updateRelationshipManager.fulfilled, (state, action) => {
        state.relationshipManagerEditData.data = action.payload;
        state.relationshipManagerEditData.isLoading = false;
      })
      .addCase(updateRelationshipManager.rejected, (state, action) => {
        state.relationshipManagerEditData.error = action.error.message;
        state.relationshipManagerEditData.isLoading = false;
      })
      /* DELETE RELATIONSHIP MANAGER */
      .addCase(deleteRelationshipManager.pending, (state, action) => {
        state.relationshipManagerDeleteData.isLoading = true;
      })
      .addCase(deleteRelationshipManager.fulfilled, (state, action) => {
        state.relationshipManagerDeleteData.data = action.payload;
        state.relationshipManagerDeleteData.isLoading = false;
      })
      .addCase(deleteRelationshipManager.rejected, (state, action) => {
        state.relationshipManagerDeleteData.error = action.error.message;
        state.relationshipManagerDeleteData.isLoading = false;
      })

      //
      /*******FROM LENDER*******/
      /* GET ALL LENDER */
      .addCase(getAllLender.pending, (state, action) => {
        state.lenderData.isLoading = true;
      })
      .addCase(getAllLender.fulfilled, (state, action) => {
        state.lenderData.data = action.payload;
        state.lenderData.isLoading = false;
      })
      .addCase(getAllLender.rejected, (state, action) => {
        state.lenderData.error = action.error.message;
        state.lenderData.isLoading = false;
      })
      /* ADD LENDER */
      .addCase(addLender.pending, (state, action) => {
        state.lenderAddData.isLoading = true;
      })
      .addCase(addLender.fulfilled, (state, action) => {
        state.lenderAddData.data = action.payload;
        state.lenderAddData.isLoading = false;
      })
      .addCase(addLender.rejected, (state, action) => {
        state.lenderAddData.error = action.error.message;
        state.lenderAddData.isLoading = false;
      })
      /* UPDATE LENDER */
      .addCase(updateLender.pending, (state, action) => {
        state.lenderEditData.isLoading = true;
      })
      .addCase(updateLender.fulfilled, (state, action) => {
        state.lenderEditData.data = action.payload;
        state.lenderEditData.isLoading = false;
      })
      .addCase(updateLender.rejected, (state, action) => {
        state.lenderEditData.error = action.error.message;
        state.lenderEditData.isLoading = false;
      })
      /* DELETE LENDER */
      .addCase(deleteLender.pending, (state, action) => {
        state.lenderDeleteData.isLoading = true;
      })
      .addCase(deleteLender.fulfilled, (state, action) => {
        state.lenderDeleteData.data = action.payload;
        state.lenderDeleteData.isLoading = false;
      })
      .addCase(deleteLender.rejected, (state, action) => {
        state.lenderDeleteData.error = action.error.message;
        state.lenderDeleteData.isLoading = false;
      })

      /* Get Super Admin Data */
      .addCase(getSuperAdminData.pending, (state, action) => {
        state.superAdminData.isLoading = true;
      })
      .addCase(getSuperAdminData.fulfilled, (state, action) => {
        state.superAdminData.data = action.payload;
        state.superAdminData.isLoading = false;
      })
      .addCase(getSuperAdminData.rejected, (state, action) => {
        state.superAdminData.error = action.error.message;
        state.superAdminData.isLoading = false;
      })

      /* UPDATE USER SUBMIT TO LENDER */
      .addCase(userDataKycSubmitToLender.pending, (state, action) => {
        state.userDataKycSubmitToLenderData.isLoading = true;
      })
      .addCase(userDataKycSubmitToLender.fulfilled, (state, action) => {
        state.userDataKycSubmitToLenderData.data = action.payload;
        state.userDataKycSubmitToLenderData.isLoading = false;
      })
      .addCase(userDataKycSubmitToLender.rejected, (state, action) => {
        state.userDataKycSubmitToLenderData.error = action.error.message;
        state.userDataKycSubmitToLenderData.isLoading = false;
      })

      .addCase(fetchAuditTrail.pending, (state, action) => {
        state.fetchAuditTrail.isLoading = true;
        state.fetchAuditTrail.error = null;
        state.fetchAuditTrail.data = null;
      })
      .addCase(fetchAuditTrail.fulfilled, (state, action) => {
        state.fetchAuditTrail.data = action.payload;
        state.fetchAuditTrail.error = null;
        state.fetchAuditTrail.isLoading = false;
      })
      .addCase(fetchAuditTrail.rejected, (state, action) => {
        state.fetchAuditTrail.error = action.error.message;
        state.fetchAuditTrail.data = null;
        state.fetchAuditTrail.isLoading = false;
      });
  },
});

export const { resetAdminState } = adminSlice.actions;
export default adminSlice.reducer;
