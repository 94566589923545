import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../utilities/api";
import axios from "axios";
import { DOC_URL } from "../../utilities/config";

const initialState = {
  totaPurchaseState: {
    data: null,
    isLoading: false,
    error: null,
  },
  buyerKYCVerification: {
    data: null,
    error: null,
    isLoading: false,
  },
  buyerDetailsState: {
    data: null,
    isLoading: false,
    error: null,
  },
  purchasedBasedDetailSellerState: {
    data: null,
    isLoading: false,
    error: null,
  },

  /* MATERIAL REQUEST */
  materialRequestTableData: {
    data: null,
    isLoading: false,
    error: null,
  },

  /* Proforma Invoice*/
  proformaInvoiceTableData: {
    data: null,
    isLoading: false,
    error: null,
  },

  /* Purchase Data PO Digital*/
  purchaseOrderDigitalPOTableData: {
    data: null,
    isLoading: false,
    error: null,
  },

  /* Purchase Data PO Manual*/
  purchaseOrderManualPOTableData: {
    data: null,
    isLoading: false,
    error: null,
  },

  /* Purchase Data PO Manual*/
  invoiceDigitalTableData: {
    data: null,
    isLoading: false,
    error: null,
  },
  /* Purchase Data PO Manual*/
  invoiceManualTableData: {
    data: null,
    isLoading: false,
    error: null,
  },
  /* Purchase Data PO Manual*/
  MaterialRequestDetails: {
    data: null,
    isLoading: false,
    error: null,
  },

  /* Material request add items*/
  AddMaterialRequest: {
    data: null,
    isLoading: false,
    error: null,
  },

  /* Get delivery locations */
  materialRequestGetLocations: {
    data: null,
    isLoading: false,
    error: null,
  },

  /* Post Material request Form */
  materialRequestFormSubmit: {
    data: null,
    isLoading: false,
    error: null,
  },

  /* Buyer Proforma Invoice Details */
  proformaInvoiceDetailsDataState: {
    data: null,
    isLoading: false,
    error: null,
  },

  /* Buyer Proforma Invoice Details */
  proformaInvoiceGeneratePOState: {
    data: null,
    isLoading: false,
    error: null,
  },

  /* Buyer Purchase Digital PO Details */
  purchaseIDDigitalPODetailState: {
    data: null,
    isLoading: false,
    error: null,
  },

  /* Buyer Proforma Invoice Details */
  InvoiceDigitalDetails: {
    data: null,
    isLoading: false,
    error: null,
  },

  /* Buyer Proforma Invoice Details */
  AcceptInvoiceDetails: {
    data: null,
    isLoading: false,
    error: null,
  },

  /**Invoice Bill Of Exchange */
  invoiceBillOfExchange: {
    data: null,
    isLoading: false,
    error: null,
  },

  invoiceLorryReceipt: {
    data: null,
    isLoading: false,
    error: null,
  },

  invoiceRejection: {
    data: null,
    isLoading: false,
    error: null,
  },
  uploadBOEFiles: {
    data: null,
    isLoading: false,
    error: null,
  },
  BOEDetails: {
    data: null,
    isLoading: false,
    error: null,
  },
  proformaNegotiation: {
    data: null,
    isLoading: false,
    error: null,
  },
  proformaAccept: {
    data: null,
    isLoading: false,
    error: null,
  },

  generateDigitalPurchaseOrderData: {
    data: null,
    isLoading: false,
    error: null,
  },

  buyerManualInvoiceData: {
    data: null,
    isLoading: false,
    error: null,
  },

  dashboard: {
    data: null,
    isLoading: false,
    error: null,
  },

  totalDiscountedInvoices: {
    data: null,
    isLoading: false,
    error: null,
  },
  LiquidationDueData:{
    data:null,
    isLoading:false,
    error:null,
  },
  digitalInvoiceStatus:{
    data:null,
    isLoading:false,
    error:null,
  },
  manualInvoiceStatus:{
    data:null,
    isLoading:false,
    error:null,
  },

};
export const getManualInvoiceStatus = createAsyncThunk(
  "/buyer/manual_invoice_status",
  async (body) => {
    try {
      const url =
        "/buyer/manual_invoice_status"
      const response = await api.post(url,body,{
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);
export const getManualInvoicePayment = createAsyncThunk(
  "/buyer/manual_invoice_payment",
  async (body) => {
    try {
      const url =
        "/buyer/manual_invoice_payment"
      const response = await api.post(url,body,{
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

export const getDigitalInvoiceStatus = createAsyncThunk(
  "/buyer/digital_invoice_status",
  async (body) => {
    try {
      const url =
        "/buyer/digital_invoice_status"
      const response = await api.post(url,body,{
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

export const getDigitalInvoicePayment = createAsyncThunk(
  "/buyer/digital_invoice_payment",
  async (body) => {
    try {
      const url =
        "/buyer/digital_invoice_payment"
      const response = await api.post(url,body,{
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);


export const getLiquidationDue = createAsyncThunk(
  "get/liquidation_due",
  async () => {
    try {
      const url = "analytics/buyer/liquidation_due";
      const response = await api.get(url, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const getTotalDiscountedInvoices = createAsyncThunk(
  "buyer/invoices_discounted_by_month/year",
  async (year) => {
    try {
      const url = `analytics/buyer/invoices_discounted_by_month/${year}`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.get(url, head);
      return response.data;
    } catch (error) {
      // console.log("error : ", error);
      throw new Error(error.response.data.error);
    }
  }
);

export const getDashboardData = createAsyncThunk(
  "buyer/dashboardData",
  async () => {
    try {
      const url = `analytics/buyer/buyer_dashboard`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.get(url, head);
      return response.data;
    } catch (error) {
      // console.log("error : ", error);
      throw new Error(error.response.data.error);
    }
  }
);

/**
 * @param {null}
 * @returns { response(purchase_details) `analytics/buyer/purchase_details`}
 */
export const getTotalPurchases = createAsyncThunk(
  "buyer/purchase_details",
  async () => {
    try {
      const url = `analytics/buyer/purchase_details`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.get(url, head);
      return response.data;
    } catch (error) {
      // console.log("error : ", error);
      throw new Error(error.response.data.error);
    }
  }
);

/**
 * @param {null}
 * @returns { response(getBuyerDetails) `analytics/buyer/details`}
 */
export const getBuyerDetails = createAsyncThunk("buyer/details", async () => {
  try {
    const url = `analytics/buyer/details`;
    const head = {
      headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
    };
    const response = await api.get(url, head);
    return response.data;
  } catch (error) {
    // console.log("error : ", error);
    throw new Error(error.response.data.error);
  }
});

export const getPurchasedBasedDetailSeller = createAsyncThunk(
  "buyer/purchaseDetails",
  async () => {
    try {
      const url = `analytics/buyer/purchase_details_based_seller`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.get(url, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

/* MATERIAL REQUEST */
export const getMaterialRequestTable = createAsyncThunk(
  "get/materailRequestTable",
  async (tableData) => {
    try {
      // console.log("fddf", tableData?.tableQuery);
      const url = `buyer/mr${tableData?.tableQuery}`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.get(url, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

/* MATERIAL REQUEST */
export const getMaterialRequestDetails = createAsyncThunk(
  "get/materialRequestDetails",
  async (mr) => {
    try {
      console.log("Inside Redux -> materialID: ", mr);
      const url = `buyer/mr/${sessionStorage.getItem("customerId")}/${mr}`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.get(url, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

/* MATERIAL REQUEST */
export const getAddMaterialItems = createAsyncThunk(
  "get/getAddMaterialItems",
  async (item) => {
    try {
      // console.log("Inside Redux -> materialID: ", item);
      const url = `buyer/inventory/item/${sessionStorage.getItem(
        "customerId"
      )}/${item}`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.get(url, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

/* MATERIAL REQUEST Get cities*/
export const getMaterialRequestDeliveryLocation = createAsyncThunk(
  "get/getMaterialRequestCities",
  async () => {
    try {
      // console.log("Inside Redux -> materialID: ", item);
      const url = `buyer/cities/`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.get(url, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

/* MATERIAL REQUEST Get cities*/
export const getMaterialRequestFormSubmit = createAsyncThunk(
  "get/getMaterialrequestFormSubmit",
  async (body) => {
    try {
      console.log("Inside Redux -> materialID: ", body);
      const url = `buyer/mr`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.post(url, body, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

/* Proforma Invoice */
export const getProformaInvoiceTable = createAsyncThunk(
  "get/ProformaInvoiceTable",
  async (tableData) => {
    console.log(tableData);
    try {
      const url = `buyer/proforma?${tableData}`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.get(url, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

/* Proforma Invoice */
export const getGenerateProformaPO = createAsyncThunk(
  "get/postGeneratePRoformaPO",
  async (body) => {
    console.log(body);
    try {
      let url = `buyer/po`;

      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.post(url, body, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

/* Proforma Invoice */
export const getProformaInvoiceDetails = createAsyncThunk(
  "get/proformaDetails",
  async (SellerProformaID) => {
    // console.log(SellerProformaID)
    try {
      let url = `buyer/proforma/${SellerProformaID.sellerID}/${SellerProformaID.proformaInvoiceID}`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.get(url, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);
// Negotiation save*/
export const getProformaNegotiationSave = createAsyncThunk(
  "get/proformaDetails/negotiation",
  async (body) => {
    // console.log(SellerProformaID)
    try {
      let url = `buyer/proforma`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.put(url, body, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

// Accept Negatitaion*/
export const getProformaAccept = createAsyncThunk(
  "get/proformaDetails/accept",
  async (body) => {
    // console.log('indide Get Accept proforma !!!!!', body)
    try {
      let url = `buyer/proforma`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.post(url, body, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

/* Purchase Order  Digital PO */
export const getPurchaseOrderDigitalPOTable = createAsyncThunk(
  "get/purchaseOrderDigitalPO",

  async (tableData) => {
    try {
      console.log(tableData);
      let url = `buyer/po?${tableData}`;
      // tableData.tableQueryParamsPurchaseOrderDigitalPO.search.length > 1
      // ? (url = `buyer/po?offset=${tableData.tableQueryParamsPurchaseOrderDigitalPO.tableOffset}&limit=${tableData.tableQueryParamsPurchaseOrderDigitalPO.tableLimit}&search=${tableData.tableQueryParamsPurchaseOrderDigitalPO.search}}`)
      // : (url = `buyer/po?offset=${tableData.tableQueryParamsPurchaseOrderDigitalPO.tableOffset}&limit=${tableData.tableQueryParamsPurchaseOrderDigitalPO.tableLimit}&sort=${tableData.tableQueryParamsPurchaseOrderDigitalPO.tableSort}&order=${tableData.tableQueryParamsPurchaseOrderDigitalPO.tableOrder}`);

      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.get(url, head);
      console.log(response);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

/* Purchase Order Manual PO */
export const getPurchaseOrderManualPOTable = createAsyncThunk(
  "get/purchaseOrderManualPO",
  async (tableData) => {
    console.log("Inside the Redux MANUAL", tableData);
    try {
      let url = `seller/manualpo?${tableData}`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.get(url, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

/* Purchase Order Manual PO */
export const getInvoiceDigitalTable = createAsyncThunk(
  "get/invoiceDigital",
  async (tableData) => {
    try {
      let url = `buyer/invoice?${tableData}`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.get(url, head);
      // console.log(")))))", response)
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

/* Purchase Order Manual PO */
export const getInvoiceManualTable = createAsyncThunk(
  "get/invoiceManual",
  async (tableData) => {
    console.log("Inside the Redux MANUAL", tableData);
    try {
      const url = `buyer/invoice/manual?` + tableData;

      // let url = "";
      // tableData.tableQueryParamsinvoiceOrderManualPO.search.length > 1
      //   ? (url = `seller/invoice/manual?=offset=${tableData.tableQueryParamsinvoiceOrderManualPO.tableOffset}&limit=${tableData.tableQueryParamsinvoiceOrderManualPO.tableLimit}&search=${tableData.tableQueryParamsinvoiceOrderManualPO.search}}`)
      //   : (url = `seller/invoice/manual?=offset=${tableData.tableQueryParamsinvoiceOrderManualPO.tableOffset}&limit=${tableData.tableQueryParamsinvoiceOrderManualPO.tableLimit}&sort=${tableData.tableQueryParamsinvoiceOrderManualPO.tableSort}&order=${tableData.tableQueryParamsinvoiceOrderManualPO.tableOrder}`);

      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.get(url, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

/* Proforma Invoice */
export const getPurchaseDigitalPODetails = createAsyncThunk(
  "get/buyer/po/",
  async (POIDBuyerID) => {
    console.log(POIDBuyerID);
    try {
      let url = `buyer/po/${POIDBuyerID.sellerID}/${POIDBuyerID.PoID}`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.get(url, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

/* Proforma Invoice */
export const getInvoiceDigitalDetails = createAsyncThunk(
  "get/buyer/invoice/digitalDetails",
  async (invoiceNumSellerID) => {
    console.log("I am inside Redux Invoice Details: ", invoiceNumSellerID);
    try {
      // let url=''
      let url = `buyer/invoice/${invoiceNumSellerID.sellerID}/${invoiceNumSellerID.invoiceNumber}`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.get(url, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

/* Accept Invoice Details*/
export const getAcceptInvoiceDetails = createAsyncThunk(
  "post/buyer/invoice/acceptInvoiceDetails",
  async (body) => {
    // console.log('I am inside Redux Invoice accept Details: ',body)
    try {
      // let url=''
      let url = `buyer/invoice`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.post(url, body, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

/* Invoice Bill Of Exchange */
export const getInvoiceBillOfExchange = createAsyncThunk(
  "get/buyer/invoice/billOfExchange",
  async (invoiceNumSellerID) => {
    // console.log('I am inside Redux Invoice accept Details: ',invoiceNumSellerID)
    try {
      // let url=''
      let url = `${DOC_URL}swarm/docs/${invoiceNumSellerID.sellerID}/${invoiceNumSellerID.invoiceID}/false/billOfExchange`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await axios.get(url, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

export const updateBuyerKYCVerification = createAsyncThunk(
  "get/buyerKYCVerification",
  async (body) => {
    console.log("body : ", body);
    try {
      const url = `buyer/kyc`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.post(url, body, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

/* Invoice Bill Of Exchange */
export const getInvoiceLorryReceipt = createAsyncThunk(
  "get/buyer/invoice/lorryReceipt",
  async (invoiceNumSellerID) => {
    // console.log('I am inside Redux Invoice accept Details: ',invoiceNumSellerID)
    try {
      // let url=''
      let url = `${DOC_URL}swarm/docs/${invoiceNumSellerID.sellerID}/${invoiceNumSellerID.invoiceID}/false/lorryReceipt`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await axios.get(url, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

/* Invoice Bill Of Exchange */
export const getInvoiceRejection = createAsyncThunk(
  "post/buyer/invoice/invoiceRejection",
  async (body) => {
    // console.log('I am inside Redux Invoice accept Details: ',invoiceNumSellerID)
    try {
      // let url=''
      let url = `buyer/invoice`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.post(url, body, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

/* Invoice Bill Of Exchange */
export const getUploadBOE = createAsyncThunk(
  "post/swarmdb.atoncap.com/swarm/upload_docs",
  async (body) => {
    // console.log('I am inside Redux Invoice accept Details: ',invoiceNumSellerID)
    try {
      // let url=''
      let url = `${DOC_URL}swarm/upload_docs`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await axios.post(url, body, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);
/* Invoice Bill Of Exchange Details*/
export const getBOEDetails = createAsyncThunk(
  "get/buyer/boeDetails/",
  async (BOENumber) => {
    // console.log('I am inside Redux Invoice accept Details: ',invoiceNumSellerID)
    try {
      // let url=''
      let url = `buyer/boe/${BOENumber}`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.get(url, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

export const updateGenerateDigitalPurchaseOrder = createAsyncThunk(
  "update/GgenerateDigitalPurchaseOrder",
  async (body) => {
    // console.log('I am inside Redux Invoice accept Details: ',invoiceNumSellerID)
    try {
      let url = `seller/invoice`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.post(url, body, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

export const updateBuyerManualStatus = createAsyncThunk(
  "update/buyerManualStatus",
  async (body) => {
    try {
      let url = `buyer/manual_invoice`;
      const head = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      };
      const response = await api.post(url, body, head);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  }
);

const buyerSlice = createSlice({
  name: "buyer",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder

      .addCase(updateBuyerKYCVerification.pending, (state, action) => {
        state.buyerKYCVerification.isLoading = true;
      })
      .addCase(updateBuyerKYCVerification.fulfilled, (state, action) => {
        state.buyerKYCVerification.data = action.payload;
        state.buyerKYCVerification.isLoading = false;
      })
      .addCase(updateBuyerKYCVerification.rejected, (state, action) => {
        state.buyerKYCVerification.error = action.error.message;
        state.buyerKYCVerification.isLoading = false;
      })

      // update login data
      .addCase(getTotalPurchases.pending, (state, action) => {
        state.totaPurchaseState.isLoading = true;
      })
      .addCase(getTotalPurchases.fulfilled, (state, action) => {
        state.totaPurchaseState.data = action.payload;
        state.totaPurchaseState.error = null;
        state.totaPurchaseState.isLoading = false;
      })
      .addCase(getTotalPurchases.rejected, (state, action) => {
        state.totaPurchaseState.error = action.error.message;
        state.totaPurchaseState.data = null;
        state.totaPurchaseState.isLoading = false;
      })

      // Buyer details
      .addCase(getBuyerDetails.pending, (state, action) => {
        state.buyerDetailsState.isLoading = true;
      })
      .addCase(getBuyerDetails.fulfilled, (state, action) => {
        state.buyerDetailsState.data = action.payload;
        state.buyerDetailsState.error = null;
        state.buyerDetailsState.isLoading = false;
      })
      .addCase(getBuyerDetails.rejected, (state, action) => {
        state.buyerDetailsState.error = action.error.message;
        state.buyerDetailsState.data = null;
        state.buyerDetailsState.isLoading = false;
      })

      // purcase based detail seller
      .addCase(getPurchasedBasedDetailSeller.pending, (state, action) => {
        state.purchasedBasedDetailSellerState.isLoading = true;
      })
      .addCase(getPurchasedBasedDetailSeller.fulfilled, (state, action) => {
        state.purchasedBasedDetailSellerState.data = action.payload;
        state.purchasedBasedDetailSellerState.error = null;
        state.purchasedBasedDetailSellerState.isLoading = false;
      })
      .addCase(getPurchasedBasedDetailSeller.rejected, (state, action) => {
        state.purchasedBasedDetailSellerState.error = action.error.message;
        state.purchasedBasedDetailSellerState.data = null;
        state.purchasedBasedDetailSellerState.isLoading = false;
      })

      /* MATERIAL REQUEST */
      .addCase(getMaterialRequestTable.pending, (state, action) => {
        state.materialRequestTableData.data = null;
        state.materialRequestTableData.error = null;
        state.materialRequestTableData.isLoading = true;
      })
      .addCase(getMaterialRequestTable.fulfilled, (state, action) => {
        state.materialRequestTableData.data = action.payload;
        state.materialRequestTableData.error = null;
        state.materialRequestTableData.isLoading = false;
      })
      .addCase(getMaterialRequestTable.rejected, (state, action) => {
        state.materialRequestTableData.error = action.error.message;
        state.materialRequestTableData.data = null;
        state.materialRequestTableData.isLoading = false;
      })
      /* MATERIAL REQUEST DETAILS */
      .addCase(getMaterialRequestDetails.pending, (state, action) => {
        state.MaterialRequestDetails.data = null;
        state.MaterialRequestDetails.error = null;
        state.MaterialRequestDetails.isLoading = true;
      })
      .addCase(getMaterialRequestDetails.fulfilled, (state, action) => {
        state.MaterialRequestDetails.data = action.payload;
        state.MaterialRequestDetails.error = null;
        state.MaterialRequestDetails.isLoading = false;
      })
      .addCase(getMaterialRequestDetails.rejected, (state, action) => {
        state.MaterialRequestDetails.error = action.error.message;
        state.MaterialRequestDetails.data = null;
        state.MaterialRequestDetails.isLoading = false;
      })

      /* MATERIAL REQUEST ADD ITEMS */
      .addCase(getAddMaterialItems.pending, (state, action) => {
        state.AddMaterialRequest.data = null;
        state.AddMaterialRequest.error = null;
        state.AddMaterialRequest.isLoading = true;
      })
      .addCase(getAddMaterialItems.fulfilled, (state, action) => {
        state.AddMaterialRequest.data = action.payload;
        state.AddMaterialRequest.error = null;
        state.AddMaterialRequest.isLoading = false;
      })
      .addCase(getAddMaterialItems.rejected, (state, action) => {
        state.AddMaterialRequest.error = action.error.message;
        state.AddMaterialRequest.data = null;
        state.AddMaterialRequest.isLoading = false;
      })
      /* MATERIAL REQUEST get cities */
      .addCase(getMaterialRequestDeliveryLocation.pending, (state, action) => {
        state.materialRequestGetLocations.data = null;
        state.materialRequestGetLocations.error = null;
        state.materialRequestGetLocations.isLoading = true;
      })
      .addCase(
        getMaterialRequestDeliveryLocation.fulfilled,
        (state, action) => {
          state.materialRequestGetLocations.data = action.payload;
          state.materialRequestGetLocations.error = null;
          state.materialRequestGetLocations.isLoading = false;
        }
      )
      .addCase(getMaterialRequestDeliveryLocation.rejected, (state, action) => {
        state.materialRequestGetLocations.error = action.error.message;
        state.materialRequestGetLocations.data = null;
        state.materialRequestGetLocations.isLoading = false;
      })

      /* MATERIAL REQUEST materialRequestFormSubmit*/
      .addCase(getMaterialRequestFormSubmit.pending, (state, action) => {
        state.materialRequestFormSubmit.data = null;
        state.materialRequestFormSubmit.error = null;
        state.materialRequestFormSubmit.isLoading = true;
      })
      .addCase(getMaterialRequestFormSubmit.fulfilled, (state, action) => {
        state.materialRequestFormSubmit.data = action.payload;
        state.materialRequestFormSubmit.error = null;
        state.materialRequestFormSubmit.isLoading = false;
      })
      .addCase(getMaterialRequestFormSubmit.rejected, (state, action) => {
        state.materialRequestFormSubmit.error = action.error.message;
        state.materialRequestFormSubmit.data = null;
        state.materialRequestFormSubmit.isLoading = false;
      })

      /* Proforma Invoice */
      .addCase(getProformaInvoiceTable.pending, (state, action) => {
        state.proformaInvoiceTableData.data = null;
        state.proformaInvoiceTableData.error = null;
        state.proformaInvoiceTableData.isLoading = true;
      })
      .addCase(getProformaInvoiceTable.fulfilled, (state, action) => {
        state.proformaInvoiceTableData.data = action.payload;
        state.proformaInvoiceTableData.error = null;
        state.proformaInvoiceTableData.isLoading = false;
      })
      .addCase(getProformaInvoiceTable.rejected, (state, action) => {
        state.proformaInvoiceTableData.error = action.error.message;
        state.proformaInvoiceTableData.data = null;
        state.proformaInvoiceTableData.isLoading = false;
      })
      // Proforma based detail seller
      .addCase(getProformaInvoiceDetails.pending, (state, action) => {
        state.proformaInvoiceDetailsDataState.isLoading = true;
      })
      .addCase(getProformaInvoiceDetails.fulfilled, (state, action) => {
        state.proformaInvoiceDetailsDataState.data = action.payload;
        state.proformaInvoiceDetailsDataState.error = null;
        state.proformaInvoiceDetailsDataState.isLoading = false;
      })
      .addCase(getProformaInvoiceDetails.rejected, (state, action) => {
        state.proformaInvoiceDetailsDataState.error = action.error.message;
        state.proformaInvoiceDetailsDataState.data = null;
        state.proformaInvoiceDetailsDataState.isLoading = false;
      })

      /*  Purchase Order Digital PO */
      .addCase(getPurchaseOrderDigitalPOTable.pending, (state, action) => {
        state.purchaseOrderDigitalPOTableData.data = null;
        state.purchaseOrderDigitalPOTableData.error = null;
        state.purchaseOrderDigitalPOTableData.isLoading = true;
      })
      .addCase(getPurchaseOrderDigitalPOTable.fulfilled, (state, action) => {
        state.purchaseOrderDigitalPOTableData.data = action.payload;
        state.purchaseOrderDigitalPOTableData.error = null;
        state.purchaseOrderDigitalPOTableData.isLoading = false;
      })
      .addCase(getPurchaseOrderDigitalPOTable.rejected, (state, action) => {
        state.purchaseOrderDigitalPOTableData.error = action.error.message;
        state.purchaseOrderDigitalPOTableData.data = null;
        state.purchaseOrderDigitalPOTableData.isLoading = false;
      })

      /* Purchase Order Manual PO */
      .addCase(getPurchaseOrderManualPOTable.pending, (state, action) => {
        state.purchaseOrderManualPOTableData.data = null;
        state.purchaseOrderManualPOTableData.error = null;
        state.purchaseOrderManualPOTableData.isLoading = true;
      })
      .addCase(getPurchaseOrderManualPOTable.fulfilled, (state, action) => {
        state.purchaseOrderManualPOTableData.data = action.payload;
        state.purchaseOrderManualPOTableData.error = null;
        state.purchaseOrderManualPOTableData.isLoading = false;
      })
      .addCase(getPurchaseOrderManualPOTable.rejected, (state, action) => {
        state.purchaseOrderManualPOTableData.error = action.error.message;
        state.purchaseOrderManualPOTableData.data = null;
        state.purchaseOrderManualPOTableData.isLoading = false;
      })

      /* Invoice digital */
      .addCase(getInvoiceDigitalTable.pending, (state, action) => {
        state.invoiceDigitalTableData.data = null;
        state.invoiceDigitalTableData.error = null;
        state.invoiceDigitalTableData.isLoading = true;
      })
      .addCase(getInvoiceDigitalTable.fulfilled, (state, action) => {
        state.invoiceDigitalTableData.data = action.payload;
        state.invoiceDigitalTableData.error = null;
        state.invoiceDigitalTableData.isLoading = false;
      })
      .addCase(getInvoiceDigitalTable.rejected, (state, action) => {
        state.invoiceDigitalTableData.error = action.error.message;
        state.invoiceDigitalTableData.data = null;
        state.invoiceDigitalTableData.isLoading = false;
      })

      /* Invoice Manual */
      .addCase(getInvoiceManualTable.pending, (state, action) => {
        state.invoiceManualTableData.data = null;
        state.invoiceManualTableData.error = null;
        state.invoiceManualTableData.isLoading = true;
      })
      .addCase(getInvoiceManualTable.fulfilled, (state, action) => {
        state.invoiceManualTableData.data = action.payload;
        state.invoiceManualTableData.error = null;
        state.invoiceManualTableData.isLoading = false;
      })
      .addCase(getInvoiceManualTable.rejected, (state, action) => {
        state.invoiceManualTableData.error = action.error.message;
        state.invoiceManualTableData.data = null;
        state.invoiceManualTableData.isLoading = false;
      })

      /* Generate PO proforma Invoice */
      .addCase(getGenerateProformaPO.pending, (state, action) => {
        state.proformaInvoiceGeneratePOState.data = null;
        state.proformaInvoiceGeneratePOState.error = null;
        state.proformaInvoiceGeneratePOState.isLoading = true;
      })
      .addCase(getGenerateProformaPO.fulfilled, (state, action) => {
        state.proformaInvoiceGeneratePOState.data = action.payload;
        state.proformaInvoiceGeneratePOState.error = null;
        state.proformaInvoiceGeneratePOState.isLoading = false;
      })
      .addCase(getGenerateProformaPO.rejected, (state, action) => {
        state.proformaInvoiceGeneratePOState.error = action.error.message;
        state.proformaInvoiceGeneratePOState.data = null;
        state.proformaInvoiceGeneratePOState.isLoading = false;
      })

      /* Purchase Order Digital PO details  */
      .addCase(getPurchaseDigitalPODetails.pending, (state, action) => {
        state.purchaseIDDigitalPODetailState.data = null;
        state.purchaseIDDigitalPODetailState.error = null;
        state.purchaseIDDigitalPODetailState.isLoading = true;
      })
      .addCase(getPurchaseDigitalPODetails.fulfilled, (state, action) => {
        state.purchaseIDDigitalPODetailState.data = action.payload;
        state.purchaseIDDigitalPODetailState.error = null;
        state.purchaseIDDigitalPODetailState.isLoading = false;
      })
      .addCase(getPurchaseDigitalPODetails.rejected, (state, action) => {
        state.purchaseIDDigitalPODetailState.error = action.error.message;
        state.purchaseIDDigitalPODetailState.data = null;
        state.purchaseIDDigitalPODetailState.isLoading = false;
      })

      /* Invoice Digital Details  */
      .addCase(getInvoiceDigitalDetails.pending, (state, action) => {
        state.InvoiceDigitalDetails.data = null;
        state.InvoiceDigitalDetails.error = null;
        state.InvoiceDigitalDetails.isLoading = true;
      })
      .addCase(getInvoiceDigitalDetails.fulfilled, (state, action) => {
        state.InvoiceDigitalDetails.data = action.payload;
        state.InvoiceDigitalDetails.error = null;
        state.InvoiceDigitalDetails.isLoading = false;
      })
      .addCase(getInvoiceDigitalDetails.rejected, (state, action) => {
        state.InvoiceDigitalDetails.error = action.error.message;
        state.InvoiceDigitalDetails.data = null;
        state.InvoiceDigitalDetails.isLoading = false;
      })

      /* Invoice accept Details  */
      .addCase(getAcceptInvoiceDetails.pending, (state, action) => {
        state.AcceptInvoiceDetails.data = null;
        state.AcceptInvoiceDetails.error = null;
        state.AcceptInvoiceDetails.isLoading = true;
      })
      .addCase(getAcceptInvoiceDetails.fulfilled, (state, action) => {
        state.AcceptInvoiceDetails.data = action.payload;
        state.AcceptInvoiceDetails.error = null;
        state.AcceptInvoiceDetails.isLoading = false;
      })
      .addCase(getAcceptInvoiceDetails.rejected, (state, action) => {
        state.AcceptInvoiceDetails.error = action.error.message;
        state.AcceptInvoiceDetails.data = null;
        state.AcceptInvoiceDetails.isLoading = false;
      })

      /* Invoice accept Details  */
      .addCase(getInvoiceBillOfExchange.pending, (state, action) => {
        state.invoiceBillOfExchange.data = null;
        state.invoiceBillOfExchange.error = null;
        state.invoiceBillOfExchange.isLoading = true;
      })
      .addCase(getInvoiceBillOfExchange.fulfilled, (state, action) => {
        state.invoiceBillOfExchange.data = action.payload;
        state.invoiceBillOfExchange.error = null;
        state.invoiceBillOfExchange.isLoading = false;
      })
      .addCase(getInvoiceBillOfExchange.rejected, (state, action) => {
        state.invoiceBillOfExchange.error = action.error.message;
        state.invoiceBillOfExchange.data = null;
        state.invoiceBillOfExchange.isLoading = false;
      })

      /* Invoice accept Details  */
      .addCase(getInvoiceLorryReceipt.pending, (state, action) => {
        state.invoiceLorryReceipt.data = null;
        state.invoiceLorryReceipt.error = null;
        state.invoiceLorryReceipt.isLoading = true;
      })
      .addCase(getInvoiceLorryReceipt.fulfilled, (state, action) => {
        state.invoiceLorryReceipt.data = action.payload;
        state.invoiceLorryReceipt.error = null;
        state.invoiceLorryReceipt.isLoading = false;
      })
      .addCase(getInvoiceLorryReceipt.rejected, (state, action) => {
        state.invoiceLorryReceipt.error = action.error.message;
        state.invoiceLorryReceipt.data = null;
        state.invoiceLorryReceipt.isLoading = false;
      })

      /* Invoice Rejection   */
      .addCase(getInvoiceRejection.pending, (state, action) => {
        state.invoiceRejection.data = null;
        state.invoiceRejection.error = null;
        state.invoiceRejection.isLoading = true;
      })
      .addCase(getInvoiceRejection.fulfilled, (state, action) => {
        state.invoiceRejection.data = action.payload;
        state.invoiceRejection.error = null;
        state.invoiceRejection.isLoading = false;
      })
      .addCase(getInvoiceRejection.rejected, (state, action) => {
        state.invoiceRejection.error = action.error.message;
        state.invoiceRejection.data = null;
        state.invoiceRejection.isLoading = false;
      })

      /* Upload BOW Files  */
      .addCase(getUploadBOE.pending, (state, action) => {
        state.uploadBOEFiles.data = null;
        state.uploadBOEFiles.error = null;
        state.uploadBOEFiles.isLoading = true;
      })
      .addCase(getUploadBOE.fulfilled, (state, action) => {
        state.uploadBOEFiles.data = action.payload;
        state.uploadBOEFiles.error = null;
        state.uploadBOEFiles.isLoading = false;
      })
      .addCase(getUploadBOE.rejected, (state, action) => {
        state.uploadBOEFiles.error = action.error.message;
        state.uploadBOEFiles.data = null;
        state.uploadBOEFiles.isLoading = false;
      })

      /* Upload BOE Details  */
      .addCase(getBOEDetails.pending, (state, action) => {
        state.BOEDetails.data = null;
        state.BOEDetails.error = null;
        state.BOEDetails.isLoading = true;
      })
      .addCase(getBOEDetails.fulfilled, (state, action) => {
        state.BOEDetails.data = action.payload;
        state.BOEDetails.error = null;
        state.BOEDetails.isLoading = false;
      })
      .addCase(getBOEDetails.rejected, (state, action) => {
        state.BOEDetails.error = action.error.message;
        state.BOEDetails.data = null;
        state.BOEDetails.isLoading = false;
      })

      /* Proforma negotiatation  */
      .addCase(getProformaNegotiationSave.pending, (state, action) => {
        state.proformaNegotiation.data = null;
        state.proformaNegotiation.error = null;
        state.proformaNegotiation.isLoading = true;
      })
      .addCase(getProformaNegotiationSave.fulfilled, (state, action) => {
        state.proformaNegotiation.data = action.payload;
        state.proformaNegotiation.error = null;
        state.proformaNegotiation.isLoading = false;
      })
      .addCase(getProformaNegotiationSave.rejected, (state, action) => {
        state.proformaNegotiation.error = action.error.message;
        state.proformaNegotiation.data = null;
        state.proformaNegotiation.isLoading = false;
      })
      /* Proforma Accept  */
      .addCase(getProformaAccept.pending, (state, action) => {
        state.proformaAccept.data = null;
        state.proformaAccept.error = null;
        state.proformaAccept.isLoading = true;
      })
      .addCase(getProformaAccept.fulfilled, (state, action) => {
        state.proformaAccept.data = action.payload;
        state.proformaAccept.error = null;
        state.proformaAccept.isLoading = false;
      })
      .addCase(getProformaAccept.rejected, (state, action) => {
        state.proformaAccept.error = action.error.message;
        state.proformaAccept.data = null;
        state.proformaAccept.isLoading = false;
      })

      /* GENERATE BUYER DIGITAL PURCHASE ORDER */
      .addCase(updateGenerateDigitalPurchaseOrder.pending, (state, action) => {
        state.generateDigitalPurchaseOrderData.isLoading = true;
      })
      .addCase(
        updateGenerateDigitalPurchaseOrder.fulfilled,
        (state, action) => {
          state.generateDigitalPurchaseOrderData.data = action.payload;
          state.generateDigitalPurchaseOrderData.isLoading = false;
        }
      )
      .addCase(updateGenerateDigitalPurchaseOrder.rejected, (state, action) => {
        state.generateDigitalPurchaseOrderData.error = action.error.message;
        state.generateDigitalPurchaseOrderData.isLoading = false;
      })

      /* UPDATE MANUAL INVOICE  */
      .addCase(updateBuyerManualStatus.pending, (state, action) => {
        state.buyerManualInvoiceData.isLoading = true;
      })
      .addCase(updateBuyerManualStatus.fulfilled, (state, action) => {
        state.buyerManualInvoiceData.data = action.payload;
        state.buyerManualInvoiceData.isLoading = false;
      })
      .addCase(updateBuyerManualStatus.rejected, (state, action) => {
        state.buyerManualInvoiceData.error = action.error.message;
        state.buyerManualInvoiceData.isLoading = false;
      })

      /* UPDATE DASHBOARD DATA  */
      .addCase(getDashboardData.pending, (state, action) => {
        state.dashboard.isLoading = true;
      })
      .addCase(getDashboardData.fulfilled, (state, action) => {
        state.dashboard.data = action.payload;
        state.dashboard.isLoading = false;
      })
      .addCase(getDashboardData.rejected, (state, action) => {
        state.dashboard.error = action.error.message;
        state.dashboard.isLoading = false;
      })

      /* get total invoice discounted */
      .addCase(getTotalDiscountedInvoices.pending, (state, action) => {
        state.totalDiscountedInvoices.isLoading = true;
      })
      .addCase(getTotalDiscountedInvoices.fulfilled, (state, action) => {
        state.totalDiscountedInvoices.data = action.payload;
        state.totalDiscountedInvoices.isLoading = false;
      })
      .addCase(getTotalDiscountedInvoices.rejected, (state, action) => {
        state.totalDiscountedInvoices.error = action.error.message;
        state.totalDiscountedInvoices.isLoading = false;
      })
      .addCase(getLiquidationDue.pending, (state, action) => {
        state.LiquidationDueData.isLoading = true;
        state.LiquidationDueData.data = null;
        state.LiquidationDueData.error = null;
      })
      .addCase(getLiquidationDue.fulfilled, (state, action) => {
        state.LiquidationDueData.data = action.payload;
        state.LiquidationDueData.isLoading = false;
        state.LiquidationDueData.error = null;
      })
      .addCase(getLiquidationDue.rejected, (state, action) => {
        state.LiquidationDueData.error = action.error.message;
        state.LiquidationDueData.isLoading = false;
        state.LiquidationDueData.data = null;
      })
      .addCase(getDigitalInvoiceStatus.pending, (state, action) => {
        state.digitalInvoiceStatus.isLoading = true;
        state.digitalInvoiceStatus.data = null;
        state.digitalInvoiceStatus.error = null;
      })
      .addCase(getDigitalInvoiceStatus.fulfilled, (state, action) => {
        state.digitalInvoiceStatus.isLoading = false;
        state.digitalInvoiceStatus.data = action.payload;
        state.digitalInvoiceStatus.error = null;
      })
      .addCase(getDigitalInvoiceStatus.rejected, (state, action) => {
        state.digitalInvoiceStatus.isLoading = false;
        state.digitalInvoiceStatus.error = action.error.message;
        state.digitalInvoiceStatus.data = null;
      })
      .addCase(getManualInvoiceStatus.pending, (state, action) => {
        state.manualInvoiceStatus.isLoading = true;
        state.manualInvoiceStatus.data = null;
        state.manualInvoiceStatus.error = null;
      })
      .addCase(getManualInvoiceStatus.fulfilled, (state, action) => {
        state.manualInvoiceStatus.isLoading = false;
        state.manualInvoiceStatus.data = action.payload;
        state.manualInvoiceStatus.error = null;
      })
      .addCase(getManualInvoiceStatus.rejected, (state, action) => {
        state.manualInvoiceStatus.isLoading = false;
        state.manualInvoiceStatus.error = action.error.message;
        state.manualInvoiceStatus.data = null;
      });
  },
});

export default buyerSlice.reducer;
