import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../utilities/api";

const initialState = {

  totalRejectedUsersData: {
    data: null,
    isLoading: false,
    error: null,
  },
  totalApprovedUsersData: {
    data: null,
    isLoading: false,
    error: null,
  },
  totalOutstandingInvoicesData: {
    data: null,
    isLoading: false,
    error: null,
  },
  totalNotDiscountedInvoicesData: {
    data: null,
    isLoading: false,
    error: null,
  },
  totalPendingUsersData: {
    data: null,
    isLoading: false,
    error: null,
  },
  totalOnboardingUsersData: {
    data: null,
    isLoading: false,
    error: null,
  },
  listOfCustomersOnboardingData:{
    data: null,
    isLoading: false,
    error: null,
  }
};

export const getTotalRejectedUsers = createAsyncThunk("get/total_rejected_users", async () => {
  try {
    const url = `channelpartner/total_rejected_users`;
    const head = {
      headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
    };
    const response = await api.get(url, head);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
});
export const getTotalApprovedUsers = createAsyncThunk("get/total_approved_users", async () => {
  try {
    const url = `channelpartner/total_approved_users`;
    const head = {
      headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
    };
    const response = await api.get(url, head);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
});
export const getTotalOutstandingInvoices = createAsyncThunk("get/total_outstanding_invoices", async () => {
  try {
    const url = `relation_manager/total_outstanding_invoices`;
    const head = {
      headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
    };
    const response = await api.get(url, head);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
});
export const getTotalNotDiscountedInvoices = createAsyncThunk("get/total_not_discounted_invoices", async () => {
  try {
    const url = `relation_manager/total_not_discounted_invoices`;
    const head = {
      headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
    };
    const response = await api.get(url, head);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
});
export const getTotalPendingUsers = createAsyncThunk("get/total_pending_users", async () => {
  try {
    const url = `channelpartner/total_pending_users`;
    const head = {
      headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
    };
    const response = await api.get(url, head);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
});
export const getTotalOnboardingUsers = createAsyncThunk("get/total_onboarded_users", async () => {
  try {
    const url = `channelpartner/total_onboarded_users`;
    const head = {
      headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
    };
    const response = await api.get(url, head);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
});
export const getListOfCustomersOnboarding = createAsyncThunk("get/get_all_user_by_channel", async () => {
  try {
    const url = `channelpartner/get_all_user_by_channel`;
    const head = {
      headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
    };
    const response = await api.get(url, head);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
});


const corporateSlice = createSlice({
  name: "corporate",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      // total_rejected_users
      .addCase(getTotalRejectedUsers.pending, (state, action) => {
        state.totalRejectedUsersData.isLoading = true;
      })
      .addCase(getTotalRejectedUsers.fulfilled, (state, action) => {
        state.totalRejectedUsersData.data = action.payload;
        state.totalRejectedUsersData.isLoading = false;
      })
      .addCase(getTotalRejectedUsers.rejected, (state, action) => {
        state.totalRejectedUsersData.error = action.error.message;
        state.totalRejectedUsersData.isLoading = false;
      })

      // total_approved_users
      .addCase(getTotalApprovedUsers.pending, (state, action) => {
        state.totalApprovedUsersData.isLoading = true;
      })
      .addCase(getTotalApprovedUsers.fulfilled, (state, action) => {
        state.totalApprovedUsersData.data = action.payload;
        state.totalApprovedUsersData.isLoading = false;
      })
      .addCase(getTotalApprovedUsers.rejected, (state, action) => {
        state.totalApprovedUsersData.error = action.error.message;
        state.totalApprovedUsersData.isLoading = false;
      })

      // total_outstanding_invoices
      .addCase(getTotalOutstandingInvoices.pending, (state, action) => {
        state.totalOutstandingInvoicesData.isLoading = true;
      })
      .addCase(getTotalOutstandingInvoices.fulfilled, (state, action) => {
        state.totalOutstandingInvoicesData.data = action.payload;
        state.totalOutstandingInvoicesData.isLoading = false;
      })
      .addCase(getTotalOutstandingInvoices.rejected, (state, action) => {
        state.totalOutstandingInvoicesData.error = action.error.message;
        state.totalOutstandingInvoicesData.isLoading = false;
      })


      // total_not_discounted_invoices
      .addCase(getTotalNotDiscountedInvoices.pending, (state, action) => {
        state.totalNotDiscountedInvoicesData.isLoading = true;
      })
      .addCase(getTotalNotDiscountedInvoices.fulfilled, (state, action) => {
        state.totalNotDiscountedInvoicesData.data = action.payload;
        state.totalNotDiscountedInvoicesData.isLoading = false;
      })
      .addCase(getTotalNotDiscountedInvoices.rejected, (state, action) => {
        state.totalNotDiscountedInvoicesData.error = action.error.message;
        state.totalNotDiscountedInvoicesData.isLoading = false;
      })


      // total_pending_users
      .addCase(getTotalPendingUsers.pending, (state, action) => {
        state.totalPendingUsersData.isLoading = true;
      })
      .addCase(getTotalPendingUsers.fulfilled, (state, action) => {
        state.totalPendingUsersData.data = action.payload;
        state.totalPendingUsersData.isLoading = false;
      })
      .addCase(getTotalPendingUsers.rejected, (state, action) => {
        state.totalPendingUsersData.error = action.error.message;
        state.totalPendingUsersData.isLoading = false;
      })


      // total_onboarded_users
      .addCase(getTotalOnboardingUsers.pending, (state, action) => {
        state.totalOnboardingUsersData.isLoading = true;
      })
      .addCase(getTotalOnboardingUsers.fulfilled, (state, action) => {
        state.totalOnboardingUsersData.data = action.payload;
        state.totalOnboardingUsersData.isLoading = false;
      })
      .addCase(getTotalOnboardingUsers.rejected, (state, action) => {
        state.totalOnboardingUsersData.error = action.error.message;
        state.totalOnboardingUsersData.isLoading = false;
      })

      // List Of Customer Onboarding
      .addCase(getListOfCustomersOnboarding.pending, (state, action) => {
        state.listOfCustomersOnboardingData.isLoading = true;
      })
      .addCase(getListOfCustomersOnboarding.fulfilled, (state, action) => {
        state.listOfCustomersOnboardingData.data = action.payload;
        state.listOfCustomersOnboardingData.isLoading = false;
      })
      .addCase(getListOfCustomersOnboarding.rejected, (state, action) => {
        state.listOfCustomersOnboardingData.error = action.error.message;
        state.listOfCustomersOnboardingData.isLoading = false;
      });

  },
});

export default corporateSlice.reducer;
