import { Fragment, useContext, useState } from "react";
import { ChannelPartnerKycNav } from "./channelPartnerKycNav";
import { ChannelPartnerKycContext, ChannelPartnerKycContextWrapper } from "./channelPartnerKycContext";
import { AnchorKYCPersonalDetails } from "./personalDetails";
import { AnchorKYCDocumentDetails } from "./documentDetails";


const ChannelPartnerKYC = () => {

	const [ termsAndConditions, setTermsAndConditions ] = useState(false);
	
	const { handleSubmitForm, } = useContext(ChannelPartnerKycContext);

	const handleSubmit = () => {
		console.log("sum");
		handleSubmitForm();
	}

	return (
		<Fragment>
			<ChannelPartnerKycNav />

			<div className="admin-kyc-verification-container px-3 py-5">

				<form className="d-flex flex-column m-0 p-0 gap-4">

					{/*===============================================
					*  PERSONAL DETAILS
					* ==============================================*/}
					<AnchorKYCPersonalDetails />

					{/*===============================================
					*  OTHER DETAILS
					* ==============================================*/}
					<AnchorKYCDocumentDetails />

					<div className="mx-5">
						<div
							className={`d-flex gap-2 mx-5 align-items-center my-2 details-page-container ${ termsAndConditions ? "bg-success" : "bg-danger" } bg-opacity-25 p-2`}
						>
							<input
								id="termAndCondition"
								type="checkbox"
								className="form-check-input"
								value={ termsAndConditions }
								onChange={ () => setTermsAndConditions(previous => !previous) }
								required
							/>
							<label
								htmlFor="termAndCondition"
								className="kyc-label-text form-check-label"
							>
								I hereby declare that this invoice is not funded/discounted with
								any financier outside ATON
							</label>
						</div>
					</div>

					<div className="d-flex flex-column flex-md-row align-items-center justify-content-end gap-3 mx-5 mb-5">
						<button type="button" onClick={ handleSubmit } disabled={ !termsAndConditions } className="kyc-back-button">
							Submit
						</button>
					</div>

				</form>

				<div className="d-flex   justify-content-center mt-4">
					<span>Copyright 2020 Aton.</span>
				</div>
			</div>
		</Fragment>
	);
};

const ChannelPartnerKycWrapper = () => {

	return (
		<ChannelPartnerKycContextWrapper>
			<ChannelPartnerKYC />
		</ChannelPartnerKycContextWrapper>
	);
};

export default ChannelPartnerKycWrapper;
