import React from "react";
import { BsSearch } from "react-icons/bs";
import AdminCard from "../Admin/AdminCard";
import { useEffect, useState } from "react";
import { Link, useNavigate, useOutlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../layout/GenericLayout/Loader";
import { postCreateData } from "../../store/slices/lender";
import { toastMessage } from "../../layout/GenericLayout/ToastMessage";
import { getDropDownData,getRiskManagementData } from "../../store/slices/lender";

const LenderNewTransactionMaintenance = ({ Route }) => {
  console.log(Route);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dropDownData = useSelector(
    (state) => state.lender.dropDown?.data?.success
  );

  const customerOnboardData = useSelector(
    (state) => state.lender.corporateOnboardData
  );

  const createApi=useSelector((state)=>state.lender.createApi);

  const [data, setData] = useState({
    name: "",
    fldg: "",
    category: "",
    cif: "-",
    product: "",
    InterestRate: "",
    ProcessingFee: "",
    tenure: "",
    IntCalcu: "",
    PlatFee: "",
    stamping: "",
    Insurance: "",
    OthChar: "",
    margin: "",
    odc: "",
  });

  console.log(customerOnboardData);

  useEffect(() => {
    // dispatch(getDropDownData());
    dispatch(getRiskManagementData())
  }, [dispatch]);

  const handleModify = () => {
    const body = {
      FLDG: data.fldg,
      ODC: data.odc,
      category: data.category,
      customerId: data.cif,
      fees: parseFloat(data.ProcessingFee),
      insuranceCharges: data.Insurance,
      interestCalculation: data.IntCalcu,
      interestRate: parseFloat(data.InterestRate),
      margin: data.margin,
      name: data.name,
      otherChanrges: data.OthChar,
      platformFee: data.PlatFee,
      product: data.product,
      stampingCharges: data.stamping,
      tenure: +data.tenure,
    };
    dispatch(postCreateData(body))
      .unwrap()
      .then((response) => {
        toastMessage(response.success, "success");
        navigate("/lender/trans-maintain");
      })
      .catch((error) => {
        console.log(error);
        toastMessage(error.message, "error");
      });
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    console.log(name);
    console.log(value);
    setData((prev) => ({ ...prev, [name]: value }));

    if(name==='name'){
      const id=(customerOnboardData?.data?.success.find(item => item.companyFullName === value))?.customerId;
      console.log(id);
      setData((prev) => ({ ...prev, cif: id }));
    }
  };

  console.log(data);

  return (
    <div className="d-flex flex-column gap-4 bg-white p-3 rounded">
      <div className="d-flex justify-content-between flex-column px-2 gap-2">
        <div
          className="d-flex flex-column mt-4"
          style={{
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            borderRadius: "7px",
          }}
        >
          <div
            className=""
            style={{
              backgroundColor: "#aeb7e5",
              borderRadius: "7px",
              padding: "7px",
              height: "40px",
            }}
          >
            <span className="kyc-content-title my-3 fw-semibold text-white">
              Transaction Maintenance
            </span>
          </div>

          <div className="details-container container p-3">
            <div className="row gy-md-4 gy-2 mt-4">

              <div className="kyc-fields-container col-12  col-md-4">
                <label htmlFor="name-cif" className="kyc-label-text form-label">
                  Name
                </label>
                <select
                  id="name-cif"
                  name="name"
                  onChange={handleChange}
                  value={data.name}
                  className="search-input-element1 d-flex flex-grow-1"
                  defaultValue=""
                >
                  <option value="" disabled>
                    Choose Name
                  </option>
                  {customerOnboardData?.data?.success !== undefined &&
                    customerOnboardData?.data?.success.map((item) => (
                      <option value={item.companyFullName}>
                        {item.companyFullName}
                      </option>
                    ))}
                </select>
              </div>

              <div className="kyc-fields-container col-12  col-md-4">
                <label htmlFor="customer_id" className="kyc-label-text form-label">
                  CIF
                </label>

                <div className="kyc-input-field form-control" id="customer_id">
                  {data?.cif}
                  {/* {(customerOnboardData?.data?.success.find(item => item.companyFullName === data.name))?.customerId} */}
                </div>
              </div>

              <div className="kyc-fields-container col-12  col-md-4">
                <label htmlFor="shipping" className="kyc-label-text form-label">
                  FLDG (%)
                </label>
                <input
                  id="shipping"
                  type="text"
                  name="fldg"
                  onChange={handleChange}
                  className="kyc-input-field form-control"
                  value={data.fldg}
                />
              </div>

              <div className="kyc-fields-container col-12  col-md-4">
                <label htmlFor="shipping" className="kyc-label-text form-label">
                  Category
                </label>
                <select
                  name="category"
                  onChange={handleChange}
                  value={data.category}
                  className="search-input-element1 d-flex flex-grow-1"
                >
                  <option></option>
                  <option value="Secured">Secured</option>
                  <option value="Unsecured">Unsecured</option>
                </select>
              </div>

              <div className="kyc-fields-container col-12  col-md-4">
                <label htmlFor="shipping" className="kyc-label-text form-label">
                  Product
                </label>
                <input
                  id="shipping"
                  type="text"
                  name="product"
                  onChange={handleChange}
                  className="kyc-input-field form-control"
                  value={data.product}
                />
              </div>

              <div className="kyc-fields-container col-12  col-md-4">
                <label htmlFor="shipping" className="kyc-label-text form-label">
                  Interest Rate (%)
                </label>
                <input
                  id="shipping"
                  type="text"
                  name="InterestRate"
                  onChange={handleChange}
                  className="kyc-input-field form-control"
                  value={data.InterestRate}
                />
              </div>

              <div className="kyc-fields-container col-12  col-md-4">
                <label htmlFor="billing" className="kyc-label-text form-label">
                  Processing Fees (%)
                </label>
                <input
                  id="billing"
                  type="text"
                  name="ProcessingFee"
                  onChange={handleChange}
                  value={data.ProcessingFee}
                  className="kyc-input-field form-control"
                />
              </div>

              <div className="kyc-fields-container col-12  col-md-4">
                <label htmlFor="shipping" className="kyc-label-text form-label">
                  Tenure (In Days)
                </label>
                <input
                  id="shipping"
                  type="text"
                  name="tenure"
                  onChange={handleChange}
                  className="kyc-input-field form-control"
                  value={data.tenure}
                />
              </div>
              <div className="kyc-fields-container col-12  col-md-4">
                <label htmlFor="shipping" className="kyc-label-text form-label">
                  Interest Calculation
                </label>
                <select
                  onChange={handleChange}
                  name="IntCalcu"
                  value={data.IntCalcu}
                  className="search-input-element1 d-flex flex-grow-1"
                >
                  <option></option>
                  <option value="Daily">Daily</option>
                  <option value="Weekly">Weekly</option>
                  <option value="Monthly">Monthly</option>
                  <option value="Quarterly">Quarterly</option>
                  <option value="Yearly">Yearly</option>
                </select>
              </div>

              <div className="kyc-fields-container col-12  col-md-4">
                <label htmlFor="billing" className="kyc-label-text form-label">
                  Platform Fees (%)
                </label>
                <input
                  id="billing"
                  type="text"
                  name="PlatFee"
                  onChange={handleChange}
                  value={data.PlatFee}
                  className="kyc-input-field form-control"
                />
              </div>

              <div className="kyc-fields-container col-12  col-md-4">
                <label htmlFor="shipping" className="kyc-label-text form-label">
                  Stamping Charges (%)
                </label>
                <input
                  id="shipping"
                  type="text"
                  name="stamping"
                  onChange={handleChange}
                  className="kyc-input-field form-control"
                  value={data.stamping}
                />
              </div>
              <div className="kyc-fields-container col-12  col-md-4">
                <label htmlFor="shipping" className="kyc-label-text form-label">
                  Insurance Charges (%)
                </label>
                <input
                  id="shipping"
                  type="text"
                  name="Insurance"
                  onChange={handleChange}
                  className="kyc-input-field form-control"
                  value={data.Insurance}
                />
              </div>

              <div className="kyc-fields-container col-12  col-md-4">
                <label htmlFor="billing" className="kyc-label-text form-label">
                  Other Charges (%)
                </label>
                <input
                  id="billing"
                  type="text"
                  name="OthChar"
                  onChange={handleChange}
                  value={data.OthChar}
                  className="kyc-input-field form-control"
                />
              </div>

              <div className="kyc-fields-container col-12  col-md-4">
                <label htmlFor="shipping" className="kyc-label-text form-label">
                  Margin (%)
                </label>
                <input
                  id="shipping"
                  type="text"
                  name="margin"
                  onChange={handleChange}
                  className="kyc-input-field form-control"
                  value={data.margin}
                />
              </div>

              <div className="kyc-fields-container col-12  col-md-4">
                <label htmlFor="shipping" className="kyc-label-text form-label">
                  ODC (%)
                </label>
                <input
                  id="shipping"
                  type="text"
                  name="odc"
                  onChange={handleChange}
                  className="kyc-input-field form-control"
                  value={data.odc}
                />
              </div>
            </div>

            <div className="row mt-4">
              <div
                className="col-12 d-flex justify-content-center gap-4 justify-content-lg-end"
                style={{ marginBottom: "2rem" }}
              >
                <button
                  onClick={() => navigate("/lender/trans-maintain")}
                  className="btn btn-danger px-4"
                >
                  Cancel
                </button>
                <button onClick={handleModify} className="btn btn-success px-4">
                  

                  {createApi.isLoading ? (
                    <span className="loader mx-auto"></span>
                      ) : (
                      "Submit"
                    )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LenderNewTransactionMaintenance;
