import { Fragment, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AdminCamContext } from "../../../../context/admin/cam/adminCamContext";
import { AdminKycActions } from "../../../../context/admin/cam/actionTypes";

export const AdminCamCompanyDetails = () => {
	const [reduxState, setReduxState] = useState([]);
	const { editEnabled,companyPromotersDispatch } = useContext(AdminCamContext);

	const additionalReduxState = useSelector(state=>state?.admin?.camByEmailIdData?.data?.data?.companyPromoterDetails);
	useEffect(()=>{
		if(reduxState != additionalReduxState){
			setReduxState(additionalReduxState);
		}
	},[additionalReduxState]);

	useEffect(()=>{
		companyPromotersDispatch({type:AdminKycActions.PROMOTER.UPDATE_DETAILS,payload:reduxState});
	},[reduxState]);

	return (
		<Fragment>

			{editEnabled ?(
				<textarea className="form-control"  value={reduxState} onChange={e=>setReduxState(e.target.value)} />
			):(
				<span className="fw-bold">
					{reduxState}
				</span>

			)}

			
		</Fragment>
	);
};