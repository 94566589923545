import { useState } from "react";

const RMCard = (props) => {
  /* OBJECT DESTRUCTURE */
  const { title, count, status, icon } = props;
  /* USESTATES */
  const [activeOnHover, setActiveOnHover] = useState(false);

  /* EVENT LISTENERS */
  const onMouserOverCard = () => {
    setActiveOnHover((prev) => !prev);
  };
  const onMouserOutCard = () => {
    setActiveOnHover((prev) => !prev);
  };

  return (
    <div
      onMouseOver={onMouserOverCard}
      onMouseOut={onMouserOutCard}
      className={`admin-dashboard-card d-flex flex-grow-1 flex-column ${
        activeOnHover && "placeholder-wave"
      }`}
    >
      <span className="px-4 p-1 card-title">{title}</span>
      <hr className="card-line my-0" />
      <div className="d-flex p-1 px-4">
        <div className="d-flex flex-column flex-grow-1 ">
          <span className="card-count">{count}</span>
          <span className="card-status">{status}</span>
        </div>
        <div className="d-flex align-items-end">{icon}</div>
      </div>
    </div>
  );
};

export default RMCard;
