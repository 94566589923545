import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getInvoiceStatus,postDisbursement } from "../store/slices/lender";
import {getManualInvoiceStatus,getDigitalInvoiceStatus, getManualInvoicePayment, getDigitalInvoicePayment} from "../store/slices/buyer"
import { toastMessage } from "../layout/GenericLayout/ToastMessage";

/*
<PaymentPage amount={2000}>
  <button className="btn btn-success">Payment</button>
</PaymentPage>
*/


const PaymentPage = ({ amount, children, closePayment, handleSearch, billReferenceNo, name ,email, mobile, orderNote, customerDetails, invoiceId, sellerId, role }) => {
  console.log("payment props : ", typeof amount, invoiceId,sellerId,role, orderNote);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  useEffect(() => {
    loadRazorpayScript();
  }, []);

  const loadRazorpayScript = () => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    script.onload = handleRazorpayScriptLoad;
    document.body.appendChild(script);
  };

  const handleRazorpayScriptLoad = () => {
    console.log("Razorpay script loaded.");
  };

  const handlePayment = () => {
    if(role=='buyerDigital'){
      dispatch(getDigitalInvoiceStatus({invoiceId: invoiceId , amount: amount, currency: "INR", sellerId:sellerId}))
      .unwrap()
      .then((res) => {

        // for buyer side payment
        const notes = {
          invoiceId: invoiceId?invoiceId:"", 
          amount: amount?amount:"", 
          currency: "INR", 
          sellerId: sellerId?sellerId:"",
          orderNote: orderNote ? orderNote : "",

        };

        const options = {
          "key": "rzp_test_zsvtDlpbBb3lDI", // Replace with your Razorpay API key
          "amount": amount * 100, // Amount in paise (e.g., 10000 paise = ₹100)
          "currency": "INR",
          "name": "Aton Cap",
          // name: JSON.parse(sessionStorage.getItem("user")).name,
          "description": "Test Payment",
          "order_id": res?.orderData.id,
          notes:notes,

          handler: (response) => {
            // Handle successful payment response
            console.log("Payment successful:", response);
            dispatch(getDigitalInvoicePayment({invoiceId: invoiceId,orderId: res?.orderData.id, sellerId: sellerId, amount:amount }))
            handleSearch?.();
            closePayment?.();
          },
          modal: {
            ondismiss: () => {
              // Handle payment error or cancellation
              console.log("Payment error or cancellation");
            },
          },
        };
    
        const razorpayOptions = new window.Razorpay(options);
        razorpayOptions.open();
      })
      .catch((error) => {
        toastMessage(error.message, "error");
        closePayment?.();
      });
    }else if(role=='buyerManual'){
      dispatch(getManualInvoiceStatus({invoiceId: invoiceId , amount: amount, currency: "INR", sellerId:sellerId}))
      .unwrap()
      .then((res) => {

        // for buyer side payment
        const notes = {
          invoiceId: invoiceId?invoiceId:"", 
          amount: amount?amount:"", 
          currency: "INR", 
          sellerId: sellerId?sellerId:"",
          orderNote: orderNote ? orderNote : "",
        };

        const options = {
          "key": "rzp_test_zsvtDlpbBb3lDI", // Replace with your Razorpay API key
          "amount": amount * 100, // Amount in paise (e.g., 10000 paise = ₹100)
          "currency": "INR",
          "name": "Aton Cap",
          // name: JSON.parse(sessionStorage.getItem("user")).name,
          "description": "Test Payment",
          "order_id": res?.orderData.id,
          notes:notes,

          handler: (response) => {
            // Handle successful payment response
            console.log("Payment successful:", response);
            dispatch(getManualInvoicePayment({invoiceId: invoiceId,orderId: res?.orderData.id, sellerId: sellerId, amount:amount }))
            handleSearch?.();
            closePayment?.();
          },
          modal: {
            ondismiss: () => {
              // Handle payment error or cancellation
              console.log("Payment error or cancellation");
            },
          },
        };
    
        const razorpayOptions = new window.Razorpay(options);
        razorpayOptions.open();
      })
      .catch((error) => {
        toastMessage(error.message, "error");
        closePayment?.();
      });
    }else{
        dispatch(getInvoiceStatus({billReferenceNo: billReferenceNo , amount: amount, currency: "INR"}))
      .unwrap()
      .then((res) => {

        //for lender side payment
        const notes = {
          amount: amount?amount:"",
          name: name ? name : "",
          email: email ? email : "",
          mobile: mobile ? mobile : "",
          orderNote: orderNote ? orderNote : "",
          customerDetails: JSON.stringify(customerDetails),
        };

        console.log(customerDetails,"notes")
        const options = {
          "key": "rzp_test_zsvtDlpbBb3lDI", // Replace with your Razorpay API key
          "amount": amount * 100, // Amount in paise (e.g., 10000 paise = ₹100)
          "currency": "INR",
          "name": "Aton Cap",
          // name: JSON.parse(sessionStorage.getItem("user")).name,
          "description": "Test Payment",
          "order_id": res?.orderData.id,
          notes:notes,

          handler: (response) => {
            // Handle successful payment response
            console.log("Payment successful:", response);
            dispatch(postDisbursement({billReferenceNo: billReferenceNo,orderId: res?.orderData.id }))
            handleSearch?.();
            closePayment?.();
          },
          modal: {
            ondismiss: () => {
              // Handle payment error or cancellation
              console.log("Payment error or cancellation");
            },
          },
        };
    
        const razorpayOptions = new window.Razorpay(options);
        razorpayOptions.open();
      })
      .catch((error) => {
        toastMessage(error.message, "error");
        closePayment?.();
      });
    }
  };

  


  return <div onClick={handlePayment}>{children}</div>;
};

export default PaymentPage;
