// Package Imports
import { Fragment } from "react";

const AnchorProgressBar = (props) => {
  // This Function Maps the value from 0 to say 600 to 0 to 100
  function map_range(value, low1, high1, low2, high2) {
    return low2 + ((high2 - low2) * (value - low1)) / (high1 - low1);
  }
  // console.log('inside progress >>>>>>> ', map_range(props.progress, 0, 600, 0, 20))

  //box-shadow: 0px 3px 6px #00000017;
  //
  return (
    //.............old component

    // <div
    //   className={`${props.className} container  my-1`}
    //   style={{ boxShadow: "0px 3px 6px #00000017" }}
    // >
    //   <div className="row gx-2">
    //     <div className="col-2  rounded d-flex justify-content-center my-2">
    //       <img
    //         src={props.imger}
    //         alt="imager"
    //         style={{ width: "60px", height: "60px" }}
    //       />
    //     </div>
    //     <div className="col-10 " style={{ margin: "auto 0px" }}>
    //       <div className="progress" style={{ height: "10px" }}>
    //         <div
    //           className="progress-bar"
    //           role="progressbar"
    //           style={{ width: `15%` }}
    //           aria-valuenow="25"
    //           aria-valuemin="0"
    //           aria-valuemax="100"
    //         ></div>
    //       </div>
    //     </div>
    //   </div>
    // </div>




    // <div style={outerCont}>
    // 	<div style={outerLeft}>
    // 		<div style={innerLeft}>
    // 			<div style={parentDivStyle}>
    // 				 <div style={childDivStyle}>
    // 					{/* <span style={progressTextStyle}>{`${props.progress}%`}</span> */}
    // 				</div>
    // 			</div>
    // 			<div style={profileLeftCont}>
    // 				<img alt="imager" src={props.imger}
    // 				 style={{width:"60px", height:'80px'}}/>
    // 				<p>{props.name}</p>
    // 			</div>
    // 		</div>
    // 	</div>

    // 	<div style={outerRigth}>
    // 		{/* <p style={{ fontSize: "13px" }}>view Profile</p> */}
    // 		<div style={innerRigth}>
    // 			<div
    // 				style={{
    // 					height: "100%",
    // 					display: "flex",
    // 					alignItems: "center",
    //                     fontSize:"15px",
    // 					margin:"0px 10px"
    // 				}}
    // 			>
    // 				<p>{props.progress}</p>
    // 			</div>
    // 			<img
    // 				alt="leaves"
    // 				src={props.imger}
    // 				style={{ width: "30px", height: "100%" }}
    // 			/>
    // 		</div>
    // 	</div>
    // </div>



// ---------new dashboard---------

    <div className={`${props.className} bar-container py-1 position-relative`}>
			<div className="gx-2">
				<p className="my-1 px-3">
					{props.title}
				</p>
				<div className="col-10 " style={{ margin: "auto 0px"}}>
					<div class="progress" style={{ height: '8px' }}>
						<div className={`progress-bar z-index-2`}
							role="progressbar" style={{ width: `${props.progress}`+"%" ,backgroundColor: `${props.color}`, important: "true"}}
							aria-valuenow="25"
							aria-valuemin="0"
							aria-valuemax="100"
							></div>
					</div>
					
				</div>
			</div>
      {props.value !== undefined ? (
          <div className="position-absolute data">
              Amt : {props.value}
          </div>
      ) : null}
		</div>
  );
};

export default AnchorProgressBar;
