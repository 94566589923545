import { AnchorKycActions } from "../actionTypes";
// eslint-disable-next-line no-unused-vars
import { AnchorKYCFormTemplate } from "../anchorKycContext";

export const actions = AnchorKycActions.BANK_DETAILS;

/**
 * @param { typeof AnchorKYCFormTemplate.bankDetails } state 
 * @param {{ type: typeof actions, payload: string | BufferSource }} param1 
 * @returns { typeof AnchorKYCFormTemplate.bankDetails }
 */
export function BankDetailsReducer(state, { type, payload }) {
	
	if (type === actions.BANK_STATEMENT_UPDATE_API) {
		return { ...state, bankStatement: { ...state.bankStatement,fileList: payload ?? [], isTouched: true, isVerified: true } };
	}

	else if (type === actions.CHANGE_ACC_NO) {
		const value = payload ??state.accountNumber.value;

		return { ...state, accountNumber: { ...state.accountNumber,value, isTouched: true } };
	}

	else if (type === actions.BLUR_ACC_NO) {
		// const regex = /^\d{9,18}$/g;
		const regex = /^[a-zA-Z0-9]{9,18}$/g;
		const isValid = regex.test(state.accountNumber.value);
		
		return { ...state, accountNumber: { ...state.accountNumber, isTouched: true, isValid } };
	}

	else if (type === actions.CHANGE_ACC_HOLDER_NAME) {
		const value = payload ?? state.accountHolderName.value;

		return { ...state, accountHolderName: { ...state.accountHolderName,value, isTouched: true } };
	}

	else if (type === actions.BLUR_ACC_HOLDER_NAME) {
		const regex = /^[a-z .A-Z]{3,}$/gm;
		
		const isValid = regex.test(state.accountHolderName.value);	
		
		return { ...state, accountHolderName: { ...state.accountHolderName, isTouched: true, isValid } };
	}

	else if (type === actions.CHANGE_BANK_NAME) {
		const value = payload || state.bankName.value;

		return { ...state, bankName: { value, isValid: value !== "", isTouched: true } };
	}

	else if (type === actions.BLUR_BANK_NAME) {
		return { ...state, bankName: { ...state.bankName, isTouched: true } };
	}

	else if (type === actions.CHANGE_ACC_TYPE) {
		const value = payload || state.accountType.value;

		return { ...state, accountType: { value, isValid: value !== "", isTouched: true } };
	}

	else if (type === actions.BLUR_ACC_TYPE) {
		return { ...state, accountType: { ...state.accountType, isTouched: true } };
	}
	
	else if (type === actions.CHANGE_IFSC_CODE) {
		const value = payload ?? state.IFSCCode.value;

		return { ...state, IFSCCode: { value, isValid: false, isTouched: true } };
	}

	else if (type === actions.BLUR_IFSC_CODE) {
		const pattern = /^[A-Z]{4}0[\dA-Z]{6}$/g;
		
		return { ...state, IFSCCode: { ...state.IFSCCode, isTouched: true, isValid: pattern.test(state.IFSCCode.value), } };
	}

	else if (type === actions.TOUCH_ALL) {
		for (let item in state) {
			for (let obj in state[item]) {
				if (obj === "isTouched") {
					state[item][obj] = true;
				}
			}
		}

		return { ...state };
	}
	
	return state;
}