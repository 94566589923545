import { Fragment, useContext, useEffect, useState } from "react";
import { GenericCamTableComponent } from "../../../../GenericComponent/Table/tableComponent";
import { useSelector } from "react-redux";
import { AdminCamContext } from "../../../../context/admin/cam/adminCamContext";
import { AdminKycActions } from "../../../../context/admin/cam/actionTypes";

/**
 * @type { { value: string, rowspan?: number, colspan?: number }[][] }
 */
const AdminCamTransactionDetailsTableHeaders = [
	[
		{ value: "Name of Bank" },
		{ value: "Type of Limit" },
		{ value: "Limit Extended(Rs. In lacs)" },
		{ value: "Outstanding" }
	],
];
export const AdminCamTotalCapital = () => {
	const [tableData, setTableData] = useState([
		{title:'', type_of_limit:'', limit_ext:'', outstanding:'' },
		{title:'', type_of_limit:'', limit_ext:'', outstanding:'' },
		{title:'', type_of_limit:'', limit_ext:'', outstanding:'' },
		{title:'Total (₹)', type_of_limit:'', limit_ext:'', outstanding:'' }
	]) 
	const [reduxState, setReduxState] = useState([]);
	const [total,setTotal]=useState();
	const { editEnabled,totalWorkingCapitalStateDispatch } = useContext(AdminCamContext);

	const additionalReduxState = useSelector(state=>state?.admin?.camByEmailIdData?.data?.data?.camDetails?.workingCapital);

	useEffect(()=>{
		console.log(additionalReduxState)
		if(reduxState != additionalReduxState){
			setReduxState(additionalReduxState[0]?.data);
			setTotal(additionalReduxState[0]?.total)
		}
	},[additionalReduxState]);

	const handleChangeValue = (e, index) => {
		const { name, value } = e.target;
		let newArray=[];

		if(Array.isArray(reduxState)){
			newArray=reduxState?.map((item, i)=>{
				if(i===index){
					return {...item,[name]:value}
				}
				return item
			})	
		}
		
		
		setReduxState(newArray);
	};

	useEffect(()=>{
		// console.log(reduxState)
		const newTotal={limitType: 0, limitExtended: 0, outstanding: 0};
		reduxState?.map((item)=>{
			newTotal.limitType=+newTotal.limitType+ +(item?.limitType);
			newTotal.limitExtended=+newTotal.limitExtended+ +(item?.limitExtended);
			newTotal.outstanding=+newTotal.outstanding+ +(item?.outstanding);
		})
		setTotal(newTotal);
	},[reduxState])

	useEffect(()=>{
		totalWorkingCapitalStateDispatch({type: AdminKycActions.TOTAL_WORKING_CAPITAL.UPDATE_DETAILS, payload: {total,reduxState}})
	},[reduxState,total])

	return (
		<Fragment>
			<GenericCamTableComponent addNewRows={ false }  tableHeaders={AdminCamTransactionDetailsTableHeaders} >
				{reduxState?.length !=0 && reduxState?.map((val,indx)=><tr key={"capital"+indx}>
					<td className="fw-bold text-center">{editEnabled?<input className="form-control" type="text" name="bankName" value={ val.bankName } onChange={ e => handleChangeValue(e, indx)} />:val?.bankName}</td>
					<td className="fw-bold text-center text-uppercase">{editEnabled?<input className="form-control" type="number" name="limitType" value={ val.limitType } onChange={ e => handleChangeValue(e, indx)} />:(val?.limitType!=""?val?.limitType:'-') }</td>
					<td className="numbrLeft">{editEnabled?<input className="form-control" type="number" name="limitExtended" value={ val.limitExtended } onChange={ e => handleChangeValue(e, indx)} />:val?.limitExtended}</td>
					<td className="numbrLeft">{editEnabled?<input className="form-control" type="number" name="outstanding" value={ val.outstanding } onChange={ e => handleChangeValue(e, indx)} />:val?.outstanding}</td>
				</tr>)}
				<tr>
					<td className="fw-bold text-center">Total</td>
					<td className="fw-bold text-center">{total?.limitType}</td>
					<td className="numbrLeft">{total?.limitExtended}</td>
					<td className="numbrLeft">{total?.outstanding}</td>
				</tr>
			</GenericCamTableComponent>
		</Fragment>
	);
};