import React from "react";
import UnderConstructionPage from "../../Pages/UnderConstructionPage";
import {Helmet} from "react-helmet";

const AnchorAgreement = () => {
  return (
    <div className="d-flex flex-column gap-4 h-100 ">
      <Helmet>
                <title>Agreement</title>
            </Helmet>
      <UnderConstructionPage path="/anchor/dashboard" />
    </div>
  );
};

export default AnchorAgreement;
