import React, { Fragment, useContext, useId, } from "react";
import { FiCheckCircle, FiFile } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { AnchorKycContext } from "../../Components/Anchor/AnchorKyc/anchorKycContext";
import { BuyerKycContext } from "../../Components/Buyer/BuyerKyc/buyerKycContext";
import { ChannelPartnerKycContext } from "../../Components/Channel/channelPartnerKYC/channelPartnerKycContext";
import { CorporateKycContext } from "../../Components/Corporate/corporateKyc/corporateKycContext";

/**
 * @param {{ 
 * 	label: string, 
 * 	isInvalid?: boolean,
 * 	className?: string,
 * 	style?: React.CSSProperties,
 * 	onChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void,
 * 	onBlurHandler: (event: React.FocusEvent<HTMLInputElement>) => void,
 * 	isVerified?: boolean,
 * 	fileType: 'adharImage' | 'userProf0ilePic' | 'coAadhaarImage' | 'profilePicture' | 'bankstatement' | 'otherDocs',
 * 	fileList: { [key: string]: any[] },
 * 	errorMessage?: string,
 * 	accept?: string,
 * 	disabled?: boolean
 * }} props 
 */
export const GenericFileInput = (props) => {

	const uniqueId = useId();
	const dropdownForMoreId = useId();

	const { deleteFiles: deleteAnchorFiles } = useContext(AnchorKycContext);
	const { deleteFiles: deleteBuyerFiles } = useContext(BuyerKycContext);
	const { deleteFiles: deleteChannelFiles } = useContext(ChannelPartnerKycContext);
	const { deleteFiles: deleteCorporateFiles } = useContext(CorporateKycContext);

	/**
	 * @param {string} fileType 
	 * @param {string} url 
	 */
	const handleDeleteFile = (fileType, url) => {
		deleteAnchorFiles?.(fileType, url);
		deleteBuyerFiles?.(fileType, url);
		deleteChannelFiles?.(fileType, url);
		deleteCorporateFiles?.(fileType, url);
	};

	return (
		<Fragment>
			<div className="kyc-fields-container col-12  col-md-4">
				<label htmlFor={uniqueId} className="kyc-label-text form-label">
					{props?.label ?? ""}
					{
						props?.required &&
							<span className="text-danger fs-5">*</span>
					}
				</label>

				<div className={`d-flex align-items-center gap-2 py-1 kyc-input-field form-control ${(props?.isInvalid ?? false) && "invalid-input"} ${props?.className ?? ""}`}>
					<input type="file" id={uniqueId} onChange={props?.onChangeHandler ?? null} disabled={ props?.disabled ?? false }
						onBlur={props?.onBlurHandler ?? null} accept={ props?.accept ?? null }
					/>
					{
						props?.isVerified === true &&
						<FiCheckCircle className="fs-3 text-success" />
					}
				</div>
				{
					props?.fileList?.length !== 0 && (
						<Fragment>
							<div className="col-12 d-flex align-items-center gap-2 mt-1">
								<div className="d-flex align-items-center justify-content-between gap-2 flex-grow-1">
									<a href={ props?.fileList?.[0]?.[props?.fileType] } title={ props?.fileList?.[0]?.[props?.fileType] } target="_blank" rel="noreferrer" className="text-info">
										<span>{ props?.fileList?.[0]?.[props?.fileType]?.slice(0, 30) }...</span>
									</a>
									{
										props?.disabled !== true &&
											<MdDelete title="Delete File" onClick={ () => handleDeleteFile(props?.fileType, props?.fileList?.[0]?.[props?.fileType]) }
												role="button" className="fs-4 text-danger" />
									}
								</div>
								{
									props?.fileList?.length > 1 && (
										<Fragment>
											<div className="dropdown-center">
												<button className="border-0 dropdown-toggle p-1 bg-transparent"
													type="button" id={ dropdownForMoreId } data-bs-toggle="dropdown" aria-expanded="false">More</button>

												<ul className="dropdown-menu dropdown-menu-start border-0 shadow" aria-labelledby={ dropdownForMoreId }>
													<li>
														<span className="dropdown-header fw-semibold d-flex align-items-center gap-2">
															<FiFile /> Files
														</span>
													</li>
													{
														props?.fileList?.map((item, index) => (index !== 0 &&
																<li key={ item?.[props?.fileType] } className="dropdown-item d-flex align-items-center gap-4 justify-content-between"
																	style={{ cursor: "pointer" }}
																>
																	<a href={ item?.[props?.fileType] } title={ item?.[props?.fileType] } target="_blank" rel="noreferrer" className="text-info px-1 col-10">{ item?.[props?.fileType]?.slice(0, 35) }...</a>
																	{
																		props?.disabled !== true &&
																			<MdDelete title="Delete File" onClick={ () => handleDeleteFile(props?.fileType, item?.[props?.fileType]) } role="button" className="fs-4 text-danger" />
																	}
																</li>
															))
													}
												</ul>
											</div>
										</Fragment>
									)
								}
							</div>
						</Fragment>
					)
				}
				<span className="text-danger px-1">
					{ props?.errorMessage ?? "" }
				</span>
			</div>
		</Fragment>
	);
};