
// import { Document, Page } from 'react-pdf';
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useOutlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {postModifyData} from '../../store/slices/lender'
import { toastMessage } from "../../layout/GenericLayout/ToastMessage";
import { getDropDownData } from "../../store/slices/lender";

const EditUnderWriter = (props) => {
    const dispatch = useDispatch(); 
    const navigate = useNavigate(); 
    const modifySucc = useSelector((state) => state.lender.modifyApi);
    const dropDownData = useSelector((state) => state.lender.dropDown?.data?.success)
  const [data, setData] = useState({ name: props.editData.name, PhNumber:props.editData.phone,email:props.editData.emailId,status:props.editData.status});



  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    dispatch(getDropDownData());
}, [dispatch]);

  const handleModify=()=>{
    const body={
        FLDG: data.fldg ,
        ODC: data.odc ,
        category: data.category ,
        customerId: data.cif ,
        fees: parseInt(data.ProcessingFee) ,
        insuranceCharges: data.Insurance ,
        interestCalculation : data.IntCalcu ,
        interestRate: parseInt(data.InterestRate) ,
        margin: data.margin ,
        name: data.name ,
        otherChanrges: data.OthChar ,
        platformFee: data.PlatFee ,
        product: data.product ,
        stampingCharges: data.stamping ,
        tenure: data.tenure 
    }
    dispatch(postModifyData(body))
    .unwrap()
      .then((response) => {
        toastMessage(response.success, "success");
        navigate('/lender/trans-maintain')
      })
      .catch((error) => {
        console.log(error)
        toastMessage(error.message, "error");
      });
  }

  const handleDelete={

  }

  console.log(data)
  return (
    <div className="d-flex flex-column gap-3">
      <div className="container" style={{padding:'2rem'}}>
        <div className="row gy-md-4 gy-2 mt-4 w-100">
            <div className="kyc-fields-container col-12 w-100  col-md-4">
                <label className='fs-3 font-weight-bolder p-3'>{props.screenData === "Edit" ? "Edit" : "Delete" } Underwriter Details</label>
                <div className="container border border-secondary p-3 rounded shadow-sm">
                    <div className="row mt-3">
                        <div className='col-md-3'><p>Name</p></div>
                        <div className='col-md-9'><input type="text" value={data.name} name="name" onChange={handleChange} className="w-75 h-100 rounded"/></div>
                    </div>
                    <div className="row mt-4">
                        <div className='col-md-3'><p>Phone Number </p></div>
                        <div className='col-md-9'><input type="text" value={data.PhNumber} name="PhNumber" onChange={handleChange} className="w-75 h-100 rounded"/></div>
                    </div>
                    <div className="row mt-4">
                        <div className='col-md-3'><p>Email ID</p></div>
                        <div className='col-md-9'><input type="text" value={data.email} name="email" disabled className="w-75 h-100 rounded"/></div>
                    </div>
                    <div className="row mt-4">
                        <div className='col-md-3'><p>Status</p></div>
                        <div className='col-md-9'><input type="text" value={data.status} name="status" onChange={handleChange} className="w-75 h-100 rounded"/></div>
                    </div>
                </div>
            </div>
        </div>

        <div className="row gy-md-4 gy-2 mt-1">
            <div className="kyc-fields-container col-12  col-md-4"></div>

            <div className="kyc-fields-container col-12  col-md-4">
            </div>
            <div className="kyc-fields-container col-12  col-md-4" style={{marginBottom:'2rem'}}>
            <button onClick={()=>navigate('/trans-maintain')} id="buttons" style={{padding:'5px 20px',backgroundColor:'#6c757d',border:'1px solid #6c757d',color:'white',fontWeight:'bold',borderRadius:'5px'}}>Cancel</button>
            {props.screenData === "Edit" ?
                <button id="buttons" style={{padding:'5px 20px',backgroundColor:'#6c757d',border:'1px solid #6c757d',color:'white',fontWeight:'bold',borderRadius:'5px',marginLeft:'20px'}}>Modify</button>
            :
                <button id="buttons" style={{padding:'5px 20px',backgroundColor:'#6c757d',border:'1px solid #6c757d',color:'white',fontWeight:'bold',borderRadius:'5px',marginLeft:'20px'}}>Delete</button>
            }
            </div>
        </div>
      </div>
    </div>
  );
};
export default EditUnderWriter;
