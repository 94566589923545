export const AdminKycActions = Object.freeze({
	// Common for all the objects
	UPDATE_FROM_API: "UPDATE_FROM_API",

	// Types for Personal Details
	ADDITIONAL_INFO: Object.freeze({
		CHANGE_DETAIL: "CHANGE_DETAIL",
	}),

    TRANSACTION_DETAILS: Object.freeze({
        UPDATE_DETAILS: "UPDATE_DETAILS",
    }),

    // Types for Security Details
    SECURITY: Object.freeze({
        UPDATE_DETAILS: "UPDATE_DETAILS",
    }),

    GROUP_EXPOSURE: Object.freeze({
        UPDATE_DETAILS: "UPDATE_DETAILS",
    }),

    APPLICANT_DETAILS: Object.freeze({
        UPDATE_DETAILS: "UPDATE_DETAILS",
    }),

    DIR_AND_PARTNERS: Object.freeze({
        UPDATE_DETAILS: "UPDATE_DETAILS",
    }),

    PROMOTER: Object.freeze({
        UPDATE_DETAILS: "UPDATE_DETAILS",
    }),

    STOCK_DEALERSHIP: Object.freeze({
        UPDATE_DETAILS: "UPDATE_DETAILS",
    }),
    DEBTORS: Object.freeze({
        UPDATE_DETAILS: "UPDATE_DETAILS",
    }),
    TOTAL_WORKING_CAPITAL: Object.freeze({
        UPDATE_DETAILS: "UPDATE_DETAILS",
    }),
	
});