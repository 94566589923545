import React, { useEffect, useState } from "react";
import { BsPlusCircleFill, BsSearch, BsDownload } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../layout/GenericLayout/Loader";
import {
  getLenderDisbursedInvoiceTable,
  uploadLenderDisbursedInvoice
} from "../../store/slices/lender";
import LenderDisbursedInvoiceTable from "./LenderTables/LenderDisbursedInvoiceTable";
import { Helmet } from "react-helmet";
import { toastMessage } from "../../layout/GenericLayout/ToastMessage";
import disbursementSample from "../../assests/sampleData/disbursement_sample.xlsx"

const LenderDisbursedInvoice = () => {
  const dispatch = useDispatch();
  const [disbursedInvoiceTableRows, setDisbursedInvoiceTableRows] = useState([]);
  const [disbursedInvoiceMetaData,setDisbursedInvoiceMetaData]=useState([]);

    const getLenderDisbursedInvoiceTableData = useSelector(
      (state) => state.lender.getLenderDisbursedInvoiceTableData
    );

  const [tableQueryParams,setTableQueryParams] = useState({
    tableOffset: 0,
    tableLimit: 5,
    tableSort: "invoiceDate",
    tableOrder: -1,
    currentPage: 1,
    searchValue:""
  });
  // const [searchValue,setSearchValue]=useState("");


  const handleSearch=(e)=>{
    setTableQueryParams((prev)=> ({...prev, tableOffset:0, searchValue:e.target.value}));
  }


  const uploadDisbursedInvoices = (event) => {
    const file = event.target.files[0];
    dispatch(uploadLenderDisbursedInvoice(file))
      .unwrap()
      .then((response) => {
        toastMessage(response.success, "success")
      })
      .catch((error) => {
        console.log("error : ", error);
        toastMessage(error.message, "error");
      });
  };

  useEffect(() => {
    dispatch(getLenderDisbursedInvoiceTable(tableQueryParams))
      .unwrap()
      .then((response) => {
        setDisbursedInvoiceTableRows(response.items);
        setDisbursedInvoiceMetaData(response.metadata);
      })
      .catch((error) => {
        console.log("error : ", error);
        toastMessage(error.message, "error");
      });
  }, [dispatch,tableQueryParams]);


  return (
    <>
      <div className="d-flex flex-column gap-4">
        <Helmet>
          <title>Disbursed-Invoice</title>
        </Helmet>
        <div className="bg-white admin-dashboard-card-container p-3">
          <div className="d-flex justify-content-between align-items-center flex-wrap px-2 gap-2 py-2">
            <div  style={{ flex: 6, }}>
              <div className="dashboard-content-title">Disbursed Invoice</div>
              <div className="d-flex flex-column flex-sm-row align-items-center my-4">
                <div className="d-flex flex-column justify-content-center gap-3 col-12 col-sm-10">
                  <div className="search-container d-flex align-items-center py-1 px-3 col-12 col-sm-8">
                    <input
                      className="search-input-element d-flex flex-grow-1"
                      type="text"
                      placeholder="Search with Invoice No"
                      value={tableQueryParams?.searchValue}
                      onChange={handleSearch}
                    />{" "}
                    <BsSearch className="fs-5" />
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center row" style={{ flex: 4, }}>
              <div className="col-12 m-0 p-0" style={{ display: "inline-block", textAlign:"center" }}>
                <label htmlFor="upload_invoice" className="form-label text-primary" style={{ cursor: "pointer", }}>Upload Disbursed Invoices (.csv,.xls,.xlsx)</label>
                <input type="file" style={{ display:"none" }} onChange={uploadDisbursedInvoices} id="upload_invoice" accept=".csv,.xls,.xlsx" />
              </div>
              <div className="m-0 p-0">
                <a href={disbursementSample}>
                  <button type="button" className="btn btn-outline-danger">

                    Download Disbursed Invoice Sample (.csv, .xls, .xlsx) &nbsp;&nbsp;&nbsp;
                    <BsDownload style={{ fontWeight: "600", fontSize: "2rem" }} />
                  </button>
                </a>
              </div>
            </div>
          </div>

         
        </div>

        <div className="d-flex flex-column justify-content-between flex-wrap px-2 gap-2">
          <LenderDisbursedInvoiceTable
            className="lender-anchor-and-lender-mapped-table2 placeholder-wave"
            tableHeader={[
              { name: "Anchor" },
              { name: "Borrower" },
              { name: "Borrower PAN" },
              { name: "GSTN" },
              { name: "Borrower Debtor Code" },
              { name: "Invoice Number" },
              { name: "Invoice Date" },
              { name: "Invoice Amount" },
              { name: "Discounted On" },
              { name: "Status" },
              { name: "UTR/Payment Reference" },
              { name: "Disbursed Amount" },
              { name: "Disbursed On" },
            ]}
            tableRows={disbursedInvoiceTableRows}
            materialRequestMetaData={disbursedInvoiceMetaData}
            tableQueryParams={tableQueryParams}
            setTableQueryParams={setTableQueryParams}
            isLoading={getLenderDisbursedInvoiceTableData.isLoading}
          />
        </div>
      </div>
    </>
  );
};

export default LenderDisbursedInvoice;
