import axios from "axios";
import { BASE_URL } from "./config";
import { toast } from "react-toastify";

const api = axios.create({
  baseURL: BASE_URL,
  // Add other default configurations here
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    // console.log(error);
    if (error.response.status === 401) {
      // Handle 401 Unauthorized response here
      // For example, you can redirect to the login page or display an error message

      sessionStorage.clear();

      toast.error("Session Expired!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        onClose: () => (window.location.pathname = "/login"),
      });
      return;
      // window.location.pathname = "/login";
      // return <Navigate to="/login" replace />;
    }
    return Promise.reject(error);
  }
);

export default api;
