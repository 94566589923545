import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { BsPlusCircleFill } from "react-icons/bs";

// import AdminRMDetailsTable from "./AdminTables/AdminRMDetailsTable";
import { useDispatch, useSelector } from "react-redux";
import { addLender, getAllLender } from "../../store/slices/admin";
import ReusableModal from "../../layout/GenericLayout/ReusableModal";

import { toastMessage } from "../../layout/GenericLayout/ToastMessage";
import { useNavigate } from "react-router-dom";
import { channelAllUsers } from "../../store/slices/channel";
import ChannelListOfCustomerOnboardingTable from "./ChannelTables/ChannelListOfCustomerOnboardingTable";
import Loader from "../../layout/GenericLayout/Loader";
import {Helmet} from "react-helmet";

/* MODALS OR CHILDREN COMPONENTS */
const ChooseOnboardType = ({ toggleOnboardModal, onRefreshTable }) => {
  /* FUNCTION DECLARATIONS */
  const navigate = useNavigate();
  const [emailId, setEmailId] = useState("");
  const [isEmail, setIsEmail] = useState(false);
  const [activePath, setActivePath] = useState("");

  const onChangeEmail = (event) => {
    setEmailId(event.target.value);
  };

  const onClickSetPath = (data) => {
    setActivePath(data);
    isEmailStatus();
  };

  const isEmailStatus = () => {
    setIsEmail((prev) => !prev);
  };

  const onClickRoute = () => {
    navigate(activePath, { state: { emailId } });
  };

  return (
    <div className="pb-3">
      <span className="fs-4 fw-semibold">Onboard Anchor/Counter Party</span>
      <div className="details-container container mt-4">
        {isEmail ? (
          <div className="row gy-md-4 gy-2">
            <div className="kyc-fields-container col-12">
              <label
                htmlFor="emailId"
                className="kyc-label-text form-label fw-semibold"
              >
                Email Id <span style={{ color: "red" }}>*</span>
              </label>
              <input
                id="emailId"
                type="email"
                className="kyc-input-field form-control"
                name="emailId"
                onChange={onChangeEmail}
                // style={{border:error?.errors?.state === undefined ? "1px solid lightgray" : '1px solid red'}}
              />
              {/* <span style={{color:'red'}}>{error?.errors?.state}</span> */}
            </div>
            <div className="row text-center mt-4">
              <div className="kyc-fields-container col-12">
                <button
                  onClick={() => isEmailStatus()}
                  className="btn btn-primary mx-3"
                >
                  Back
                </button>

                <button
                  disabled={emailId === ""}
                  onClick={onClickRoute}
                  className="btn btn-success mx-3 my-2"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="row gy-md-4 gy-2">
            <div className="kyc-fields-container col-12 col-md-6 text-center">
              <button
                onClick={() =>
                  onClickSetPath("/channel-partner/customer-onboarding/anchor")
                }
                className="btn btn-outline-primary px-5 py-3 fs-5"
              >
                Onboard Anchor
              </button>
            </div>

            <div className="kyc-fields-container col-12 col-md-6 text-center">
              <button
                onClick={() =>
                  onClickSetPath(
                    "/channel-partner/customer-onboarding/counter-party"
                  )
                }
                className="btn btn-outline-primary px-5 py-3 fs-5"
              >
                Onboard Counter Party
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

/* PARENT COMPONENT */
const ChannelCustomerOnboarding = () => {
  const dispatch = useDispatch();
  const channelAllUsersData = useSelector(
    (state) => state.channel.channelAllUsersData
  );
  const [channelAllUsersTableRows, setChannelAllUsersTableRows] = useState([]);

  const [isHovered, setIsHovered] = useState(false);

  /* MODALS USESTATE */
  const [showChooseOnboardTypeModal, setShowLenderAddModal] = useState(false);

  /* MODALS TOGGLE */
  const toggleOnboardModal = () => {
    setShowLenderAddModal((prev) => !prev);
  };

  useEffect(() => {
    dispatch(channelAllUsers())
      .unwrap()
      .then((response) => {
        console.log("response : ", response);
      })
      .catch((error) => {
        console.log("error : ", error);
      });
  }, []);

  const handleMouseEnter = () => {
    // setIsHovered((prev) => !prev);
  };

  const handleMouseLeave = () => {
    // setIsHovered((prev) => !prev);
  };

  return (
    <>
      {/* MODAL CONTENT */}
      {showChooseOnboardTypeModal && (
        <ReusableModal
          showModal={showChooseOnboardTypeModal}
          toggleModal={toggleOnboardModal}
          modalContent={
            <ChooseOnboardType toggleOnboardModal={toggleOnboardModal} />
          }
          modalSizeClass="modal-lg"
        />
      )}

      <div className="d-flex flex-column gap-4">
      <Helmet>
                <title>Channel-Customer-Onboarding</title>
            </Helmet>
        <div className="bg-white admin-dashboard-card-container px-3 pt-3 d-flex flex-column gap-2 pb-5">
          <div className="d-flex justify-content-between align-items-center flex-wrap px-2 gap-2 py-2">
            <span className="dashboard-content-title">
              List Of Customer Onboarding
            </span>
            <div
              onClick={toggleOnboardModal}
              className="d-flex align-items-center"
              // type="button"
            >
              <div
                className={`rm-add-button ${isHovered ? "show" : ""}`}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                ADD
              </div>
              <BsPlusCircleFill
                className="rm-plus-button"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              />
            </div>
          </div>
          {channelAllUsersData.isLoading ? (
            <Loader className="my-5" />
          ) : (
            <ChannelListOfCustomerOnboardingTable
              className="admin-channel-partner-data-table"
              tableHeader={[
                "Sector Name",
                "Email ID",
                "Roles",
                "Application Status",
              ]}
              tableRows={channelAllUsersTableRows}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ChannelCustomerOnboarding;
