import { BsDownload } from "react-icons/bs";

const AdditionalDocument = ({ scoreCardDetails }) => {
  let newAadharDetails = [];
  let newBankStatement = [];
  const newUserProfilePic = [];
  scoreCardDetails?.success?.documents.map((eachItem) => {
    if (eachItem["userProfilePic"]) {
      newUserProfilePic.push(eachItem);
    }

    if (eachItem["adharImage"]) {
      newAadharDetails.push(eachItem);
    }

    if (eachItem["bankstatement"]) {
      newBankStatement.push(eachItem);
    }
  });

  console.log("docs : ", newAadharDetails, newBankStatement, newUserProfilePic);
  return (
    <div className="my-3 d-flex flex-column flex-lg-row justify-content-around">
      <div>
        <span className="fw-semibold text-secondary">Profile Picture : </span>
        {newUserProfilePic.length !== 0 ? (
          <a
            className="text-decoration-none btn btn btn-outline-success py-0"
            target="_blank"
            href={newUserProfilePic[0]?.userProfilePic}
            download
          >
            <span className="d-flex align-items-center gap-3">
              Download <BsDownload />
            </span>
          </a>
        ) : (
          <span className="fw-semibold text-danger">N/A</span>
        )}
      </div>

      <div>
        <span className="fw-semibold text-secondary">Aadhaar Card : </span>
        {newAadharDetails.length !== 0 ? (
          <a
            className="text-decoration-none btn btn btn-outline-success py-0"
            target="_blank"
            href={newAadharDetails[0]?.adharImage}
            download
          >
            <span className="d-flex align-items-center gap-3">
              Download <BsDownload />
            </span>
          </a>
        ) : (
          <span className="fw-semibold text-danger">N/A</span>
        )}
      </div>

      <div>
        <span className="fw-semibold text-secondary">Bank Statement : </span>
        {newBankStatement.length !== 0 ? (
          <a
            className="text-decoration-none btn btn btn-outline-success py-0"
            target="_blank"
            href={newBankStatement[0]?.bankstatement}
            download
          >
            <span className="d-flex align-items-center gap-3">
              Download <BsDownload />
            </span>
          </a>
        ) : (
          <span className="fw-semibold text-danger">N/A</span>
        )}
      </div>
    </div>
  );
};

export default AdditionalDocument;
