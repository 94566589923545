import { BsSearch } from "react-icons/bs";
import BuyerDashCard from "./BuyerDashCard/BuyerDashCard";

// react components imports
import { useEffect, useState } from "react";
import {Helmet} from "react-helmet";

// imports wrt graphs
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {
  BuyerDashPieOne,
  BuyerDashPieTwo,
  BuuyerDashBarOne,
  DashLineGraphData,
} from "./DummyData/DummyData";
import { PieChart, Pie, Sector, Cell, Label } from "recharts";
import { BarChart, Bar } from "recharts";
import BuyerProgressBar from "./BuyerProgressBar/BuyerProgressBar";
import imgPowder from "../../assests/images/progress.png";

import atonLogo from "../../assests/images/Aton-logo.png";

// Redux realted imports
import { useDispatch, useSelector } from "react-redux";
import {
  getLiquidDationDue,
  getBusinessGrowthContributors,
  getDashboard
} from "../../store/slices/lender";

import {
  getCountryPartyUserLimit,
} from "../../store/slices/anchor";

import PaymentPage from "../../Pages/PaymentPage";

//for pie chart
import { VictoryPie } from "victory";
/**
 * This is for converting API response to recharts data structure
 * @params: {Key1, Key2}
 * ** Remember this works only for 2 Keys ***
 * Returns the required Data structure
 */
const convertRechartData = (key1, key2, validResponse) => {
  // let validResponse = []
  let validData = [];

  try {
    // have to get this in arg, the response
    // validResponse =
    for (let ele in validResponse) {
      if (validResponse[ele][key1].length !== validResponse[ele][key2].length) {
        throw Error;
      }

      for (let in1 in validResponse[ele][key1]) {
        let tmp = {};
        tmp[key1] = validResponse[ele][key1][in1];
        tmp[key2] = validResponse[ele][key2][in1];
        validData.push(tmp);
      }
    }
    return validData;
  } catch (e) {
    console.log(e);
  }
  return null;
};

/**
 *
 * The Colors and radius for Pie Chart
 */

const COLORS = ["#BFDDA5", "#AEE9EB", "#2E3C83", "#9ABADC"];
const OUTER=[25,30,20,35];

const dues={"dueAmountToday":"Due Today", 
            "1-30days":"1 - 30days",
            "31-60days":"31 - 60days",
            "61-90days":"61 - 90days" };

const LenderDashboard = () => {
  const dispatch = useDispatch();

  // Liquidation Datum
  const liquidDationDueResponse = useSelector(
    (state) => state.lender.LiquidDationDueData
  );
  const [liquidDationDueGraphData, setLiquidDationDueGraphData] = useState([]);

  // Business Growth Contributors (BusinessGrowthContributorsData)
  const businessGrowthContributorsResponse = useSelector(
    (state) => state.lender.BusinessGrowthContributorsData
  );
  const [businessGrowthContributorsGraphData,setBusinessGrowthContributorsGraphData] = useState();


  //dashboard data
  const dashboardData= useSelector(
    (state)=> state.lender.dashboardData
  );

  const [dashboard, setDashboard]= useState();
  const [anchorKey,setAnchorKey]= useState([]);
  const [counterPartyKey,setCounterPartyKey]= useState([]);
  const [monthWiseDiscountKey,setMonthWiseDiscountKey]= useState([]);
  const [monthWiseDiscountData, setMonthWiseDiscountData]=useState();

    //Total Limited Issued/Total Utilized Scale
    const counterPartyUserLimitData = useSelector(
      (state) => state.anchor.counterPartyUserLimitData
    );
  
    const [totalLimitedIssued, setTotalLimitedIssued] = useState(0);
    const [totalUtilizedScale, setTotalUtilizedScale] = useState(0);
    const [progressPercentage, setProgressPercentage] = useState(0);




  useEffect(() => {
    dispatch(getLiquidDationDue());
    dispatch(getBusinessGrowthContributors());
    dispatch(getDashboard());
    dispatch(getCountryPartyUserLimit());
  }, [dispatch]);

  useEffect(() => {
    if (liquidDationDueResponse.data) {
      setLiquidDationDueGraphData(
        convertRechartData(
          "due_amount",
          "due_date",
          liquidDationDueResponse.data["items"]
        )
      );
    }
  }, [liquidDationDueResponse.data]);

  useEffect(() => {
    if (businessGrowthContributorsResponse.data) {
      setBusinessGrowthContributorsGraphData(businessGrowthContributorsResponse.data.items);
    }
  }, [businessGrowthContributorsResponse.data]);


  useEffect(()=>{
    if(dashboardData.data){
      // console.log(dashboardData?.data?.items?.total_number_of_invoices_discounted_by_month)
      setDashboard(dashboardData?.data?.items);
      setAnchorKey(Object.keys(dashboardData?.data?.items?.total_number_of_discounted_invoices_for_each_seller??{}));
      setCounterPartyKey(Object.keys(dashboardData?.data?.items?.total_number_of_discounted_invoices_for_each_buyer??{}));
      setMonthWiseDiscountKey(Object.keys(dashboardData?.data?.items?.total_number_of_discounted_invoices_by_month ?? {}));

      setTotalLimitedIssued((counterPartyUserLimitData?.data?.success?.totalUtilisedAmnt / 10000000)?.toFixed(2));
      setTotalUtilizedScale((counterPartyUserLimitData?.data?.success?.maxLimit  / 10000000)?.toFixed(2));
      setProgressPercentage(totalLimitedIssued*100/totalUtilizedScale);

    }
  },[dashboardData, counterPartyUserLimitData.data,totalLimitedIssued,totalUtilizedScale])

  const date= new Date();
  
//months for bar graph
  var discountData = [
    {
      month: 'Jan',
    },
    {
      month: 'Feb',
    },
    {
      month: 'Mar',
    },
    {
      month: 'Apr',
    },
    {
      month: 'May',
    },
    {
      month: 'Jun',
    },
    {
      month: 'Jul',
    },
    {
      month: 'Aug',
    },
    {
      month: 'Sept',
    },
    {
      month: 'Oct',
    },
    {
      month: 'Nov',
    },
    {
      month: 'Dec',
    },
  ];
  
  //have alternate colors and add data in bar graph
useEffect(()=>{
  setMonthWiseDiscountData(discountData.map((each, index) => ({
    ...each,
    fill: index % 2 === 0 ? '#9ABADC' : '#BFDDA5',
    count: dashboard?.total_number_of_discounted_invoices_by_month[monthWiseDiscountKey[index]]?.discounted_invoices_count,
    value: dashboard?.total_number_of_discounted_invoices_by_month[monthWiseDiscountKey[index]]?.total_discounted_invoice_amount,
  })));
},[monthWiseDiscountKey])


  //calculate the maxLimitTotalInvoiceDiscounted for anchor and counter-party
  const [maxLimitTotalInvoiceDiscountedAnchor, setMaxLimitTotalInvoiceDiscountedAnchor]=useState(1000);
  const [maxLimitTotalInvoiceDiscountedCounterParty, setMaxLimitTotalInvoiceDiscountedCounterParty]=useState(1000);

  useEffect(()=>{
    if(dashboard?.total_number_of_discounted_invoices_for_each_seller){
      const values= Object.values(dashboard?.total_number_of_discounted_invoices_for_each_seller); //get all the values in the object
      const max = values.reduce((a, b) => Math.max(a, b), -Infinity);//get the max value in the values array
      const exponent = Math.ceil(Math.log10(max)); // fix the exponent of the max value  like for one digit--->1, 2 digit --> 2, 3 digit -->3 
      console.log(max, exponent);
      setMaxLimitTotalInvoiceDiscountedAnchor (10 ** exponent); 
    }

    if(dashboard?.total_number_of_discounted_invoices_for_each_buyer){
      const values= Object.values(dashboard?.total_number_of_discounted_invoices_for_each_buyer); //get all the values in the object
      const max = values.reduce((a, b) => Math.max(a, b), -Infinity);//get the max value in the values array
      const exponent = Math.ceil(Math.log10(max)); // fix the exponent of the max value  like for one digit--->1, 2 digit --> 2, 3 digit -->3 
      console.log(max, exponent);
      setMaxLimitTotalInvoiceDiscountedCounterParty (10 ** exponent); 
    }
  },[dashboard])


 
const CustomTooltip = ({payload }) => {
    return (
      <div className="custom-tooltip">
        {payload[0]?.payload?.month?<p>{`Month: ${payload[0]?.payload?.month}`}</p>:""}
        {payload[0]?.payload?.count!==undefined?<p>{`Count: ${payload[0]?.payload?.count}`}</p>:""}
        {payload[0]?.payload?.value!== undefined?<p>{`Value: ${payload[0]?.payload?.value} Cr`}</p>:""}
      </div>
    );
};

  //dummy data for overdue graph
  const overDueData=[{
    name:'Anchor',
    value:20,
  },
  {
    name:'CounterParty',
    value:90,
  }
];

  return (
    <div className="d-flex flex-column gap-4">
      <Helmet>
                <title>Dashboard</title>
            </Helmet>
      {/* <PaymentPage amount={300}>
        <button>Submit</button>
      </PaymentPage> */}
      {/* <div className="bg-white admin-dashboard-card-container px-3 pt-3">
        <div className="row">
          <div className="col-12 col-md-6 col-xl-3 mb-3">
            <BuyerDashCard
              title="Pending Quotes"
              count={buyerDetails['pendingQuotes']}
              status="200+ lastweek"
              icon={<DashboardCardIco />}
              value=""
            />
          </div>
          <div className="col-12 col-md-6 col-xl-3 mb-3">
            <BuyerDashCard
              title="LRA Waited"
              count={buyerDetails['LRAwaited']}
              status="200+ lastweek"
              icon={<DashboardCardIco />}
              value=""
            />
          </div>
          <div className="col-12 col-md-6 col-xl-3 mb-3">
            <BuyerDashCard
              title="Order Placed"
              count={buyerDetails['orderPlaced']}
              status="200+ lastweek"
              icon={<DashboardCardIco />}
              value=""
            />
          </div>
          <div className="col-12 col-md-6 col-xl-3 mb-3">
            <BuyerDashCard
              title="Payment Due"
              count={buyerDetails['paymentDue']}
              status="200+ lastweek"
              icon={<DashboardCardIco />}
              value=""
            />
          </div>
        </div>
      </div> */}

      {/* -------------------------------------------------------------
          Graph starts here (Row 1)
          ------------------------------------------------------------- */}

      <div className="bg-white admin-dashboard-card-container p-3">
        <div className="container mw-100">

          
{/* ......start of the old dashboard */}


          {/* <div className="row gx-1 gy-5 gy-md-2 my-3 mx-0 w-100 d-flex"> */}
            {/* <div className="col-lg-6 col-12  d-flex justify-content-center"> */}
              {/* <div className="p-2 bg-white rounded buyer_graph_cont flex-column "> */}
                {/* <p className="">Liquidation Due By Date</p> */}
                {/* <ResponsiveContainer> */}
                  {/* <PieChart
                    margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                    className="d-flex justify-content-space-evenly align-items-center flex-row "
                  > */}
                    {/* <Label value={60} /> */}
                    {/* <Legend
                      verticalAlign="middle"
                      iconType="circle"
                      height={0}
                      layout="vertical"
                      payload={liquidDationDueGraphData.map((item, index) => {
                        if (item.due_date === "dueAmountToday") {
                          return {
                            id: item.name,
                            type: "square",
                            value: `Due Amount Today`,
                            color: COLORS[index % COLORS.length],
                          };
                        } else {
                          return {
                            id: item.name,
                            type: "square",
                            value: `${item.due_date.slice(0, -4)} Days`,
                            color: COLORS[index % COLORS.length],
                          };
                        }
                      })}
                      data={liquidDationDueGraphData}
                      key={"due_date"}
                      wrapperStyle={{
                        display: "flex",
                        position: "relative",
                        justifyContent: "center",
                        alignContent: "center",
                        top: 0,
                        left: "-7%",
                        width: 250,
                        height: "100%",
                        // border: '1px solid black',
                        flexWrap: "wrap",
                      }}
                      // content={<p>Token issuances & approval Rate</p>}
                    /> */}
                    {/* <Pie
                      data={liquidDationDueGraphData}
                      innerRadius={90}
                      outerRadius={120}
                      // fill="#8884d8"
                      // paddingAngle={1}
                      // startAngle={0}
                      cx="50%"
                      cy="50%"
                      dataKey="due_amount"
                      cornerRadius={5}
                    >
                      {liquidDationDueGraphData.map((entry, index) => {
                        console.log(entry.due_date);

                        return (
                          <Cell
                            key={`cell-${entry.due_date}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        );
                      })}
                    </Pie> */}
                    {/* <Tooltip /> */}
                  {/* </PieChart> */}
                {/* </ResponsiveContainer> */}
              {/* </div> */}
            {/* </div> */}

            {/* <div className="col-lg-6 col-12  d-flex justify-content-center ">
              <div className="p-2 bg-white buyer_graph_cont flex-column ">
                <p>Total Purchases</p>
                <ResponsiveContainer>
                  <LineChart
                    data={DashLineGraphData}
                    margin={{ top: 20, left: 5, right: 0, bottom: 0 }}
                  >
                    <CartesianGrid
                      strokeDasharray="1 0"
                      vertical={false}
                      horizontal={true}
                    />
                    <XAxis dataKey="name" axisLine={false} dx={0} dy={12} />
                    <YAxis dataKey="uv" axisLine={false} dy={-3} dx={-12} />
                    <Tooltip cursor={{ stroke: "#b5e8e5", strokeWidth: 2 }} />
                    <Legend
                      wrapperStyle={{
                        padding: "1.3% 10px 0px 0px",
                        fontFamily: "Montserrat",
                        fontSize: "20px",
                        display: "flex",
                        justifyContent: "end",
                      }}
                    />
                    <Line
                      type="monotone"
                      dataKey="uv"
                      stroke="#82ca9d"
                      activeDot={{ r: 3 }}
                      strokeWidth={3}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div> */}
          {/* </div> */}
          {/* </div> */}

          {/* -------------------------------------------------------------
          Graph starts here (Row 2)
          ------------------------------------------------------------- */}

          {/* -------------------------------------------------------------
          Graph starts here (Col 1)
          ------------------------------------------------------------- */}
          {/* <div className="row gx-3 gy-4 my-2"> */}
          
          {/* <div className="row gx-1 gy-5 gy-md-2 my-3 mx-0 w-100 d-flex"> */}
            {/* <div className="col-lg-6 col-12  d-flex justify-content-center "> */}
              {/* <div className="p-2 bg-white buyer_graph_cont flex-column "> */}
                {/* <p className="">Business Growth Contributors</p> */}
                {/* <ResponsiveContainer> */}
                  {/* <BarChart data={businessGrowthContributorsGraphData}>
                    <CartesianGrid
                      strokeDasharray="1 0"
                      vertical={false}
                      horizontal={true}
                    />
                    <XAxis dataKey="name" axisLine={false} dx={0} dy={12} />
                    <YAxis dataKey="value" axisLine={false} dy={-3} dx={-12} />
                    <Tooltip cursor={{ stroke: "#b5e8e5", strokeWidth: 2 }} />
                    <Legend
                      wrapperStyle={{
                        padding: "1.3% 10px 0px 0px",
                        fontFamily: "Montserrat",
                        fontSize: "20px",
                        display: "flex",
                        justifyContent: "end",
                      }}
                    />
                    <Bar
                      dataKey="value"
                      fill="#BFDDA6"
                      barSize={20}
                      background={"#fff"}
                    />
                  </BarChart> */}
                {/* </ResponsiveContainer> */}
              {/* </div> */}
            {/* </div> */}

            {/* -------------------------------------------------------------
          Graph starts here (Col 2)
          ------------------------------------------------------------- */}
            {/* <div className="col-lg-6 col-12  d-flex justify-content-center "> */}
              {/* <div className="p-2 bg-white buyer_graph_cont flex-column "> */}
                {/* <p className="">Delivery Time Zone</p> */}
                {/* <ResponsiveContainer> */}
                  {/* <BarChart data={BuuyerDashBarOne}>
                    <CartesianGrid
                      strokeDasharray="1 0"
                      vertical={false}
                      horizontal={true}
                    />
                    <XAxis dataKey="name" axisLine={false} dx={0} dy={12} />
                    <YAxis dataKey="pv" axisLine={false} dy={-3} dx={-12} />
                    <Tooltip cursor={{ stroke: "#b5e8e5", strokeWidth: 2 }} />
                    <Legend
                      wrapperStyle={{
                        padding: "1.3% 10px 0px 0px",
                        fontFamily: "Montserrat",
                        fontSize: "20px",
                        display: "flex",
                        justifyContent: "end",
                      }}
                    />
                    <Bar
                      dataKey="uv"
                      fill="#BFDDA6"
                      barSize={20}
                      background={"#fff"}
                    />
                  </BarChart> */}
                {/* </ResponsiveContainer> */}
              {/* </div> */}
            {/* </div> */}

            {/* <div className="col-lg-6 col-12 d-flex justify-content-center">
              <div className="p-2 bg-white rounded buyer_graph_cont flex-column">
                <p className="">Best Seller</p>
                <div className="flex-fill progress-bar-cont">
                  <BuyerProgressBar
                    bgcolor="orange"
                    progress={250}
                    height={6}
                    name={"sdd"}
                    imger={imgPowder}
                    className={''}
                  />
                  <BuyerProgressBar
                    bgcolor="orange"
                    progress={250}
                    height={6}
                    name={"sdd"}
                    imger={imgPowder}
                  />
                </div>
              </div>
            </div> */}
          {/* </div> */}
          

{/* ...................end of the old dashBoard */}

            <div className="d-flex justify-content-around align-items-center row">

              {/* ----------first column with 3 rows---------- */}
              <div className="col-lg-5 col-12 d-flex flex-column justify-content-between" >

                {/*-------- row 1 --------*/}
                <div className="p-2 bg-white lender_graph_cont">
                  <p className="graph-head">Total Limited Issued/Total Utilized Scale</p>
                  <div className="flex-fill lender-progress-bar-cont">
                    <BuyerProgressBar
                      progress={progressPercentage}
                      color={progressPercentage <50?'#198754':progressPercentage<80?'#ffc107':'#E85354'}
                      height={6}
                      title={"Total Limit Issued"}
                      imger={imgPowder}
                      className={''}
                    />
                    <div className="limit-container col-10">
                      <div className="limit" 
                      style={{ left: progressPercentage < 80 ? `${progressPercentage}%` : 'auto', 
                      right: progressPercentage >= 80 ? `${100 - progressPercentage}%` : 'auto',
                      top: progressPercentage >= 80 ? `-32px` : 'auto' }}
                      >{!isNaN(totalLimitedIssued)?totalLimitedIssued:""}</div>
					            <div className="max-limit">{!isNaN(totalUtilizedScale)?totalUtilizedScale:""}</div>
                    </div>
                  </div>
                  <p className="graph-footer">Amt. INR (in Crores)</p>
                </div>


                {/*-------- row 2 --------*/}
                <div className="p-2 bg-white lender_graph_cont">
                  <p className="graph-head">Total Invoice Discounted</p>
                  <div className="flex-fill lender-progress-bar-cont">
                    <BuyerProgressBar
                      progress={(dashboard?.total_number_of_discounted_invoices_for_each_seller[anchorKey[0]]??0)*(100/maxLimitTotalInvoiceDiscountedAnchor)}
                      color={'#E88A55'}
                      height={6}
                      title={anchorKey[0]}
                      imger={imgPowder}
                      className={''}
                      value={dashboard?.total_number_of_discounted_invoices_for_each_seller[anchorKey[0]]}
                    />

                    <BuyerProgressBar
                      progress={(dashboard?.total_number_of_discounted_invoices_for_each_seller[anchorKey[1]]??0)*(100/maxLimitTotalInvoiceDiscountedAnchor)}
                      color={'#EBDA40'}
                      height={6}
                      title={anchorKey[1]}
                      imger={imgPowder}
                      className={''}
                      value={dashboard?.total_number_of_discounted_invoices_for_each_seller[anchorKey[1]]}
                    />

                    <BuyerProgressBar
                      progress={(dashboard?.total_number_of_discounted_invoices_for_each_seller[anchorKey[2]]??0)*(100/maxLimitTotalInvoiceDiscountedAnchor)}
                      color={'#189DB2'}
                      height={6}
                      title={anchorKey[2]}
                      imger={imgPowder}
                      className={''}
                      value={dashboard?.total_number_of_discounted_invoices_for_each_seller[anchorKey[2]]}
                    />

                    <BuyerProgressBar
                      progress={(dashboard?.total_number_of_discounted_invoices_for_each_seller[anchorKey[3]]??0)*(100/maxLimitTotalInvoiceDiscountedAnchor)}
                      color={'#EF6082'}
                      height={6}
                      title={anchorKey[3]}
                      imger={imgPowder}
                      className={''}
                      value={dashboard?.total_number_of_discounted_invoices_for_each_seller[anchorKey[3]]}
                    />
                    <div className="x-axis">Top Anchors</div>
                    <div className="limit-container col-10">
                      <div className="mark1"></div>
                      <div className="mark2"></div>
                      <div className="mark3"></div>
                      <div className="limit" style={{left:'50%'}}>{maxLimitTotalInvoiceDiscountedAnchor/2}</div>
					            <div className="max-limit">{maxLimitTotalInvoiceDiscountedAnchor}</div>
                    </div>
                  </div>
                  <p className="graph-footer">Amt. INR (in lakhs)</p>
                </div>


                {/*-------- row 3 --------*/}
                <div className="p-2 bg-white rounded lender_graph_cont">
                  <p className="graph-head">Total Invoice Discounted</p>
                  <div className="flex-fill lender-progress-bar-cont">
                    <BuyerProgressBar
                      progress={(dashboardData?.data?.items?.total_number_of_discounted_invoices_for_each_buyer[counterPartyKey[0]]??0)*(100/maxLimitTotalInvoiceDiscountedCounterParty)}
                      color={'#E88A55'}
                      height={6}
                      title={counterPartyKey[0]}
                      imger={imgPowder}
                      className={''}
                      value={dashboardData?.data?.items?.total_number_of_discounted_invoices_for_each_buyer[counterPartyKey[0]]}
                    />

                    <BuyerProgressBar
                      progress={(dashboardData?.data?.items?.total_number_of_discounted_invoices_for_each_buyer[counterPartyKey[1]]??0)*(100/maxLimitTotalInvoiceDiscountedCounterParty)}
                      color={'#EBDA40'}
                      height={6}
                      title={counterPartyKey[1]}
                      imger={imgPowder}
                      className={''}
                      value={dashboardData?.data?.items?.total_number_of_discounted_invoices_for_each_buyer[counterPartyKey[1]]}
                    />

                    <BuyerProgressBar
                      progress={(dashboardData?.data?.items?.total_number_of_discounted_invoices_for_each_buyer[counterPartyKey[2]]??0)*(100/maxLimitTotalInvoiceDiscountedCounterParty)}
                      color={'#189DB2'}
                      height={6}
                      title={counterPartyKey[2]}
                      imger={imgPowder}
                      className={''}
                      value={dashboardData?.data?.items?.total_number_of_discounted_invoices_for_each_buyer[counterPartyKey[2]]}
                    />

                    <BuyerProgressBar
                      progress={(dashboardData?.data?.items?.total_number_of_discounted_invoices_for_each_buyer[counterPartyKey[3]]??0)*(100/maxLimitTotalInvoiceDiscountedCounterParty)}
                      color={'#EF6082'}
                      height={6}
                      title={counterPartyKey[3]}
                      imger={imgPowder}
                      className={''}
                      value={dashboardData?.data?.items?.total_number_of_discounted_invoices_for_each_buyer[counterPartyKey[3]]}
                    />
                    <div className="x-axis">Top Counter parties </div>
                    <div className="limit-container col-10">
                      <div className="mark1"></div>
                      <div className="mark2"></div>
                      <div className="mark3"></div>
                      <div className="limit" style={{left:'50%'}}>{maxLimitTotalInvoiceDiscountedCounterParty/2}</div>
					            <div className="max-limit">{maxLimitTotalInvoiceDiscountedCounterParty}</div>
                    </div>
                  </div>
                  <p className="graph-footer">Amt. INR (in lakhs)</p>
                </div>
              </div>
 
            {/* ----------second column with 3 rows---------- */}
              <div className="col-lg-7 col-12 d-flex flex-column justify-content-between container">

                {/*------- row 1-------- */}

                 
                  <div className="d-flex row ">


                     {/*-------- column1 ----------*/}
                  <div className="col-xl-8 col-12 bg-white lender_graph_cont mr-2" style={{marginRight: "15px", overflowX:"scroll" }}>
                    <p className="lender-graph2-head">Total Invoice Discounted</p>
                    {/* <ResponsiveContainer width="100%" height="100%" > */}
                    <BarChart
                      width={500}
                      height={380}
                      data={monthWiseDiscountData}
                      margin={{
                        top: 40,
                        right: 0,
                        left: 20,
                        bottom: 30,
                      }}
                    >
                      <CartesianGrid  vertical={false} />
                      <XAxis dataKey="month" tick={{ fontSize: 12 }} >
                      <Label value="Months" offset={-10} position="insideBottomLeft"   fontSize= {12}/>
                      {/* <Label value={`F.Y ${date.getFullYear()}-${date.getFullYear()+1}`} offset={-10}  dx={120} position="insideBottom"  fontSize= {12} /> */}
                      <Label value={`Year. ${date.getFullYear()}`} offset={-10}  dx={120} position="insideBottom"  fontSize= {12} />
                      </XAxis>
                      <YAxis tickCount={11} tick={{ fontSize: 12 }}>
                        <Label value="Number & Value in Cr." angle={-90} dy={90} offset={20} position="insideLeft" fontSize= {12} />
                      </YAxis>
                      <Tooltip content={<CustomTooltip />}/>
                      <Bar dataKey="count"   barSize={10}  />
                    </BarChart>
                    {/* </ResponsiveContainer> */}
                  </div>

                   {/*-------- column2 ----------*/}
                  <div className="col-xl-3 col-12 bg-white lender_graph_cont ml-2 position-relative pie" style={{ flexGrow:"2"}}>
                      <p className="lender-graph2-head">Liquidations Due by Age</p>
                      <div className="pie-chart-container ">
                        <div className="pie-chart">
                          <VictoryPie
                            data={[
                              { y: liquidDationDueGraphData[0]?.due_amount,},
                              { y: liquidDationDueGraphData[1]?.due_amount,},
                              { y: liquidDationDueGraphData[2]?.due_amount,},
                              { y: liquidDationDueGraphData[3]?.due_amount,},
                            ]}
                            colorScale={COLORS}
                            radius={({ index }) => OUTER[index] + 70}
                            innerRadius={70}
                            labels={() => null}
                          />
                        </div>

                        <span className="inner-circle"> 
                          <VictoryPie
                            data={[{ y: 40 }]}
                            colorScale={["#E0E0E0"]}
                            innerRadius={60}
                            radius={70}
                            labels={() => null}
                          />
                        </span>
                         

                        <div className="fin-year">F.Y {date.getFullYear()}-{date.getFullYear()+1}</div>
                      </div>

                        {liquidDationDueGraphData.map((entry, index) => {
                          return (
                          <div className="d-flex justify-content-start align-items-center label">
                            <div className="small-circle" style={{backgroundColor:`${COLORS[index]}`}}>
                            </div>
                            <div className="due-name">
                              {dues[entry.due_date]}
                            </div>
                          </div>
                          );
                        })}

                       

                      
                          <div className=" position-absolute pie-detail">
                            <div className="bold">Due Amt. INR (in Crores)</div>
                            {liquidDationDueGraphData.map((entry, index) => {
                                return (
                                <div className="d-flex justify-content-start align-items-center label">
                                  <div className="" style={{backgroundColor:`${COLORS[index]}`, width:"10px", height:"10px", borderRadius:"50%", marginRight:"20px"}}>
                                  </div>
                                  <div className="">
                                  {liquidDationDueGraphData[index]?.due_amount}
                                  </div>
                                </div>
                                );
                              })}
                
                          </div>
                      
                    </div>
                  </div>

                {/*------- row2-------------- */}
                <div className="bg-white lender_graph_cont row" style={{ height: "350px",overflowX:"scroll", overflowY:"hidden"}}> 
                <p className="lender-graph2-head" style={{width:"93%" }}>Overdue Report</p>
                  {/* <ResponsiveContainer width="100%" height="100%" > */}
                  <BarChart
                    width={550}
                    height={320}
                    data={overDueData}
                    margin={{
                      top: 50,
                      right: 0,
                      left: 20,
                      bottom: 30,
                    }}
                  >
                    <CartesianGrid  vertical={false} />
                    <XAxis dataKey="name"  tick={{ fontSize: 12 }}>
                    </XAxis>
                    <YAxis tickCount={10} tick={{ fontSize: 12 }}>
                    <Label value="No of daysOutstanding" angle={-90} dy={90} position="insideLeft" fontSize={12}/>
                    </YAxis>
                    <Tooltip />
                    <Bar dataKey="value" fill='#9ABADC' barSize={50} barGap={5} />
                  </BarChart>
                  {/* </ResponsiveContainer> */}

                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default LenderDashboard;
