import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  redirect,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { FiCheckCircle, FiDelete, FiFile, FiLogOut } from "react-icons/fi";
import { AiFillEye, AiFillSetting } from "react-icons/ai";
import { MdDelete, MdPostAdd } from "react-icons/md";
import { indianStateList } from "../../../utilities/indianStateList";
import {Helmet} from "react-helmet";
import generic, {
  getUserKYCDocument,
  updateAadharOCRDetails,
  updateDeleteFile,
  updatePANVerification,
  updateUploadKYCDocuments,
  updateUploadProfilePicture,
} from "../../../store/slices/generic";
import { toastMessage } from "../../../layout/GenericLayout/ToastMessage";
import { nanoid } from "@reduxjs/toolkit";
import { BsFile, BsImage } from "react-icons/bs";
import { updateSellerKYCVerification } from "../../../store/slices/anchor";
import { InputValidator } from "../../../layout/GenericLayout/InputValidator";

/* MODALS OR CHILDREN COMPONENTS */
// const RejectModalContent = ({ kycData, toggleRejectModal }) => {
//   const [rejectReason, setRejectReason] = useState("");

//   const onChangeRejectReason = (event) => {
//     setRejectReason(event.target.value);
//   };
//   const onClickSubmitRejectReason = () => {
//     const body = {
//       emailId: kycData.data?.success?.emailId,
//       remarks: rejectReason,
//       status: "rejected",
//     };
//   };

//   return (
//     <div className="container">
//       <span className="fs-4 fw-semibold">Rejection Reason</span>
//       <div className="row">
//         <div className="kyc-fields-container col-12">
//           <label htmlFor="rejectReason" className="kyc-label-text form-label">
//             Remarks <span className="text-danger">*</span>
//           </label>
//           <input
//             onChange={onChangeRejectReason}
//             id="rejectReason"
//             name="rejectReason"
//             type="text"
//             className="kyc-input-field form-control"
//             // value={rejectReason}
//           />
//         </div>
//       </div>
//       <div className="d-flex align-items-center justify-content-end gap-3 mx-5 my-3">
//         <button className="kyc-back-button" onClick={toggleRejectModal}>
//           Cancel
//         </button>
//         <button className="kyc-back-button" onClick={onClickSubmitRejectReason}>
//           Submit
//         </button>
//       </div>
//     </div>
//   );
// };

/* PARENT COMPONENT */
const KycVerificationForm = () => {
  /* FUNCTION DECLARATIONS */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  /* OBJECT DESTRUCTURE */
  // const { emailId } = useParams();
  const emailId = state.emailId;

  // const roles = state.loginData.items[0].user.roles;
  const roles = "anchor";

  /* USESELECTOR */
  const uploadAadharOCRDetails = useSelector(
    (state) => state.generic.uploadAadharOCRDetails
  );

  const deleteFileData = useSelector((state) => state.generic.deleteFileData);
  const uploadKYCDocuments = useSelector(
    (state) => state.generic.uploadKYCDocuments
  );

  const userProfilePictureData = useSelector(
    (state) => state.generic.userProfilePictureData
  );

  // const loginData = useSelector((state) => state.generic.loginData);
  //   const kycData = useSelector((state) => state.admin.kycData);
  //   const kycApproveData = useSelector((state) => state.admin.kycApproveData);
  /* USESTATES */
  const [kycInputDetails, setKycInputDetails] = useState({});
  const [kycUserData, setKycUserData] = useState(null);
  const [kycDataError, setKycDataError] = useState(null);
  const [showRejectModalStatus, setShowRejectModalStatus] = useState(false);
  const [showSendBackModalStatus, setShowSendBackModalStatus] = useState(false);
  const [isError, setIsError] = useState(null);

  // NEW USESTATE
  const [personalDetails, setPersonalDetails] = useState({});
  const [coApplicant, setCoApplicant] = useState({});
  const [companyInformation, setCompanyInformation] = useState({});
  const [bankDetails, setBankDetails] = useState({});
  const [aadharSDK, setAadharSDK] = useState({});
  const [addharOCRData, setAadharOCRData] = useState(null);
  const [termAndCondition, setTermsAndCondition] = useState(false);

  //
  const [userKYCData, setUserKYCData] = useState({
    upload: {
      adharImage: null,
      coAadharImage: null,
    },
    apiData: {
      adharImage: [],
      coAadharImage: [],
    },
  });

  const [userProfilePicture, setUserProfilePicture] = useState({
    upload: "",
    apiData: [],
  });

  const [companyInfoProfilePicture, setCompanyInfoProfilePicture] = useState({
    upload: "",
    apiData: [],
  });

  // const [bankStatement, setBankStatement] = useState({});

  const [bankStatementFiles, setBankStatementFiles] = useState({
    upload: "",
    apiData: [],
  });

  const [otherUploadDocument, setOtherUploadDocument] = useState([
    {
      otherDocs: null,
      apiData: null,
      bankStatementValue: "",
      documentNameValue: "",
      roles,
      emailId,
      id: nanoid(),
    },
  ]);

  const [limitCash, setLimitCash] = useState(0);

  /* USEEFFECTS */
  // useEffect(() => {
  //   dispatch(getUserKYCDocument())
  //     .unwrap()
  //     .then((response) => {
  //       let newAadharDetails = {
  //         adharImage: [],
  //         coAadharImage: [],
  //       };
  //       let newProfilePicture = [];
  //       let newBankStatement = [];
  //       const OtherDocsArr = [];
  //       const userProfilePicApiData = [];
  //       response.items[0].documents.map((eachItem) => {
  //         if (eachItem["userProfilePic"]) {
  //           userProfilePicApiData.push(eachItem);
  //         }
  //         if (eachItem["coAadharImage"]) {
  //           newAadharDetails.coAadharImage.push(eachItem);
  //         }
  //         if (eachItem["adharImage"]) {
  //           newAadharDetails.adharImage.push(eachItem);
  //         }
  //         if (eachItem["profilePicture"]) {
  //           newProfilePicture.push(eachItem);
  //         }
  //         if (eachItem["bankstatement"]) {
  //           newBankStatement.push(eachItem);
  //         }
  //         if (eachItem["otherDocs"]) {
  //           const newDocument = {
  //             otherDocs: null,
  //             bankStatementValue: "",
  //             documentNameValue: "",
  //             roles,
  //             emailId,
  //             apiData: null,
  //             id: nanoid(),
  //           };
  //           OtherDocsArr.push({
  //             ...newDocument,
  //             apiData: eachItem,
  //             documentNameValue: eachItem.name,
  //           });
  //         }
  //       });
  //       setUserProfilePicture((prev) => ({
  //         ...prev,
  //         apiData: userProfilePicApiData,
  //       }));
  //       // setBankDetails((prev) => ({ ...prev, apiData: newBankStatement }));
  //       setUserKYCData((prev) => ({ ...prev, apiData: newAadharDetails }));
  //       setCompanyInfoProfilePicture((prev) => ({
  //         ...prev,
  //         apiData: newProfilePicture,
  //       }));
  //       OtherDocsArr.length === 0
  //         ? setOtherUploadDocument([
  //             {
  //               otherDocs: null,
  //               apiData: null,
  //               bankStatementValue: "",
  //               documentNameValue: "",
  //               roles,
  //               emailId,
  //               id: nanoid(),
  //             },
  //           ])
  //         : setOtherUploadDocument(OtherDocsArr);
  //       setBankStatementFiles((prev) => ({
  //         ...prev,
  //         apiData: newBankStatement,
  //       }));
  //       // toastMessage(response.success, "success");
  //     })
  //     .catch((error) => {
  //       toastMessage(error.message, "error");
  //     });
  // }, [
  //   dispatch,
  //   deleteFileData.data,
  //   uploadKYCDocuments.data,
  //   userProfilePictureData.data,
  // ]);

  /* EVENT LISTENERS */
  // const toggleSendBackModal = () => {
  //   setShowSendBackModalStatus((prev) => !prev);
  // };

  // FOR PERSONAL DETAILS
  const onChangePersonalDetailsInput = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setPersonalDetails((prev) => ({ ...prev, [name]: value }));
  };

  // FOR CO-APPLICANT
  const onChangeCoApplicantInput = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setCoApplicant((prev) => ({ ...prev, [name]: value }));
  };

  // FOR COMPANY INFORMATION
  const onChangeCompanyInformationInput = (event) => {
    const name = event.target.name;
    const value =
      event.target.type === "number"
        ? parseInt(event.target.value)
        : event.target.value;

    setCompanyInformation((prev) => ({ ...prev, [name]: value }));
  };

  // FOR AADHAR SDK
  const onChangeAadharSDKInput = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setAadharSDK((prev) => ({ ...prev, [name]: value }));
    //
    if (name === "adharImage" || name === "coAadharImage") {
      const body = new FormData();
      body.append([name], event.target.files[0]);
      body.append("emailId", emailId);
      body.append("roles", roles);

      dispatch(updateUploadKYCDocuments(body))
        .unwrap()
        .then((response) => {
          setIsError((prev) => ({
            ...prev,
            personalDetailsUplaodAadhaarCard: null,
          }));
          setAadharSDK((prev) => ({ ...prev, [name]: "" }));
          toastMessage(response.message, "success");
        })
        .catch((error) => {
          toastMessage(error.message, "error");
        });

      const aadharOCRBody = new FormData();
      aadharOCRBody.append([name], event.target.files[0]);
      aadharOCRBody.append("emailId", emailId);
      dispatch(updateAadharOCRDetails(aadharOCRBody))
        .unwrap()
        .then((response) => {
          setAadharOCRData(response?.data.result[0].details);
          toastMessage(response.success, "success");
        })
        .catch((error) => {
          toastMessage(error.message, "error");
        });
    }
  };

  // FOR PROFILE PICTURE
  const onBlurProfilePictureInputFields = (event) => {};
  // Profile Picture Upload
  const onChangeProfilePictureInput = (event) => {
    const value = event.target.files[0];
    setUserProfilePicture((prev) => ({ ...prev, upload: event.target.value }));

    const body = new FormData();
    body.append("userProfilePic", value);
    body.append("emailId", emailId);
    body.append("roles", roles);

    dispatch(updateUploadProfilePicture(body))
      .unwrap()
      .then((response) => {
        setUserProfilePicture((prev) => ({ ...prev, upload: "" }));
        toastMessage(response.message, "success");
      })
      .catch((error) => {
        toastMessage(error.message, "error");
      });
  };

  // FOR COMPANY INFORMATION LOGO
  const onChangeCompanyInfoProfilePicInput = (event) => {
    const name = event.target.name;
    const value = event.target.files[0];
    setCompanyInfoProfilePicture((prev) => ({
      ...prev,
      upload: event.target.value,
    }));

    const body = new FormData();
    body.append([name], value);
    body.append("emailId", emailId);
    body.append("roles", roles);

    dispatch(updateUploadKYCDocuments(body))
      .unwrap()
      .then((response) => {
        setCompanyInfoProfilePicture((prev) => ({
          ...prev,
          upload: "",
        }));
        toastMessage(response.message, "success");
      })
      .catch((error) => {
        toastMessage(error.message, "error");
      });
  };

  // FOR BANK DETAILS
  const onChangeBankDetails = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setBankDetails((prev) => ({ ...prev, [name]: value }));
  };

  const onChangeUploadBankStatement = (event) => {
    const name = event.target.name;
    const value = event.target.files[0];
    setBankStatementFiles((prev) => ({
      ...prev,
      upload: event.target.value,
    }));

    const body = new FormData();
    body.append([name], value);
    body.append("emailId", emailId);
    body.append("roles", roles);

    dispatch(updateUploadKYCDocuments(body))
      .unwrap()
      .then((response) => {
        // setBankStatementFiles((prev) => ({
        //   ...prev,
        //   apiData: response.url,
        // }));
        toastMessage(response.message, "success");
      })
      .catch((error) => {
        toastMessage(error.message, "error");
      });
  };

  // FOR DOCUMENT DETAILS
  const onChangeDocumentDetails = (event, id) => {
    const name = event.target.name;
    const value = event.target.value;
    const newDocument = otherUploadDocument.map((eachDocument) => {
      if (eachDocument.id === id) {
        return { ...eachDocument, documentNameValue: value };
      }
      return eachDocument;
    });
    setOtherUploadDocument(newDocument);
  };

  const onChangeBankStatement = (event, id) => {
    const name = event.target.name;
    const value = event.target.value;
    const file = event.target.files[0];
    const newDocument = otherUploadDocument.map((eachDocument) => {
      if (eachDocument.id === id) {
        return { ...eachDocument, bankStatementValue: value, otherDocs: file };
      }
      return eachDocument;
    });
    setOtherUploadDocument(newDocument);
    //

    const body = new FormData();
    body.append("name", "document");
    body.append([name], file);
    body.append("emailId", emailId);
    body.append("roles", roles);

    dispatch(updateUploadKYCDocuments(body))
      .unwrap()
      .then((response) => {
        // setBankStatementFiles((prev) => ({
        //   ...prev,
        //   apiData: response.url,
        // }));
        toastMessage(response.message, "success");
      })
      .catch((error) => {
        toastMessage(error.message, "error");
      });
  };

  const onChangeLimitCash = (event) => {
    setLimitCash(event.target.value);
  };

  const onClickAddDocumentDetailsField = () => {
    const newDocument = {
      otherDocs: null,
      bankStatementValue: "",
      documentNameValue: "",
      roles,
      emailId,
      apiData: null,
      id: nanoid(),
    };

    setOtherUploadDocument((prev) => [...prev, newDocument]);
  };

  // const onClickDeleteDocumentDetailsField = (id) => {
  //   const newDocument = otherUploadDocument.filter(
  //     (eachDocument) => eachDocument.id !== id
  //   );

  //   setOtherUploadDocument(newDocument);
  // };

  // DELETE FILES
  // const deleteFile = ({ key, image_url }) => {
  //   const body = {
  //     email_id: emailId,
  //     image_url,
  //     key,
  //   };
  //   dispatch(updateDeleteFile(body))
  //     .unwrap()
  //     .then((response) => {
  //       // dispatch(getUserKYCDocument());
  //       toastMessage(response.message, "success");
  //     })
  //     .catch((error) => {
  //       toastMessage(error.message, "error");
  //     });
  // };

  const onClickBack = () => {
    navigate(-1, { replace: true });
  };

  const onBlurInputFeilds = (event) => {
    const name = event.target.id;
    const value = event.target.value;

    if (name === "personalDetailsPanNo" || name === "coApplicantPANNumber") {
      const body = {
        panNumber: value,
        userid: emailId,
      };
      dispatch(updatePANVerification(body))
        .unwrap()
        .then((response) => {
          toastMessage(response.success, "success");
          setIsError((prev) => ({
            ...prev,
            [name]: null,
          }));
        })
        .catch((error) => {
          toastMessage(error.message, "error");
          setIsError((prev) => ({
            ...prev,
            [name]: error.message,
          }));
        });
    } else {
      const validator = () => {
        if (name.toLowerCase().includes("email")) {
          return {
            regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            message: "Please enter a valid email address",
          };
        } else if (name.toLowerCase().includes("mobile")) {
          return {
            regex:
              /^(?:\+?\d{1,3}\s?)?(?:\(\d{1,3}\)\s?)?\d{3}(?:[-\s]?)\d{3}(?:[-\s]?)\d{4}$/,
            message: "Please enter a valid mobile number",
          };
        } else {
          return {
            regex: /^.+$/,
            message: "Field is required",
          };
        }
      };

      const errorMessgae = InputValidator({
        regex: validator().regex,
        value,
        message: validator().message,
      });

      setIsError((prev) => ({
        ...prev,
        [name]: errorMessgae,
      }));
    }
  };

  const onSubmitKycForm = (event) => {
    event.preventDefault();
    const body = {
      //
      ...personalDetails,
      ...companyInformation,
      ...bankDetails,
      //
      coApplicant: [coApplicant],
      aadharSDK: { ...addharOCRData, dateOfBirth: addharOCRData.dob },
      profilePicture: userProfilePicture.apiData[0].userProfilePic,
      limitCash,
      // new fileds
      dateOfBirth: addharOCRData.dob,
      companyEmail: emailId,
      //
      companyPAN: "ASSPM9160h",
      companyTelephone: "94531309230",
      companyMobileNumber: "9080706050",
      detailsAboutCompany: "RANDOM",
      bankName: "state ank of idnia",
      bankstatement: "random",
      roles,
      isPrimary: true,
      loanRequestAmount: 9092234,
      panValidationStatus: "true",
      companyPanValidationStatus: "true",
      aadhaarValidationStatus: "true",
      gstValidationStatus: "true",
      coPanValidationStatus: "true",
    };
    console.log("bankDetails : ", bankDetails);
    console.log("body : ", body);

    dispatch(updateSellerKYCVerification(body))
      .unwrap()
      .then((response) => {
        console.log("response : ", response);
        toastMessage(response.success, "success");
      })
      .catch((error) => {
        console.log("error : ", error);
        toastMessage(error.message, "error");
      });
  };

  const onClickLogout = () => {
    sessionStorage.clear();
    window.location.href = "/login";
    // return <Navigate to="/login" replace />;
    // setIsLogout((prev) => !prev);
  };

  const onChangeTermAndCondition = (event) => {
    setTermsAndCondition(event.target.checked);
  };

  /* RENDER */

  return (
    <>
      {/* <ReusableModal
        showModal={showRejectModalStatus}
        toggleModal={toggleRejectModal}
        modalContent={
          <RejectModalContent
            // kycData={kycData}
            toggleRejectModal={toggleRejectModal}
          />
        }
        modalSizeClass="modal-lg"
      />

      <ReusableModal
        showModal={showSendBackModalStatus}
        toggleModal={toggleSendBackModal}
        modalContent={
          <SendBackModalContent
            // kycData={kycData}
            toggleSendBackModal={toggleSendBackModal}
          />
        }
        modalSizeClass="modal-lg"
      /> */}

      <div className="admin-kyc-verification-container px-3 py-5">
      <Helmet>
                <title>Channel-Anchor-Onboarding</title>
            </Helmet>
        {/* {kycData.isLoading && (
        <div className="d-flex justify-content-center align-items-center w-100 min-vh-100">
          <Loader />
        </div>
      )} */}

        <form
          onSubmit={onSubmitKycForm}
          className="d-flex flex-column m-0 p-0 gap-4"
        >
          {/* PERSONAL DETAILS */}
          <div className="container-with-heading d-flex flex-column container">
            <span className="kyc-content-title mb-3 row">Personal Details</span>
            <div className="details-container pb-0">
              <div className="row gy-md-4 gy-2">
                {/* NAME */}
                <div className="kyc-fields-container col-12 col-md-4">
                  <label
                    htmlFor="personalDetailsName"
                    className="kyc-label-text form-label"
                  >
                    Name
                  </label>
                  <input
                    onChange={onChangePersonalDetailsInput}
                    id="personalDetailsName"
                    name="name"
                    type="text"
                    className={`kyc-input-field form-control  ${
                      isError?.personalDetailsName && "invalid-input"
                    }`}
                    onBlur={onBlurInputFeilds}
                    value={personalDetails?.name}
                  />
                  <span className="text-danger px-1">
                    {isError?.personalDetailsName}
                  </span>
                </div>
                {/* DATE OF BIRTH */}
                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="personalDetailsDateOfBirth"
                    className="kyc-label-text form-label"
                  >
                    Date Of Birth
                  </label>
                  <input
                    onChange={onChangeAadharSDKInput}
                    id="personalDetailsDateOfBirth"
                    name="dateOfBirth"
                    type="date"
                    className={`kyc-input-field form-control  ${
                      isError?.personalDetailsDateOfBirth && "invalid-input"
                    }`}
                    onBlur={onBlurInputFeilds}
                    value={aadharSDK?.dateOfBirth}
                  />
                  <span className="text-danger px-1">
                    {isError?.personalDetailsDateOfBirth}
                  </span>
                </div>
                {/* EMAIL */}
                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="personalDetailsEmail"
                    className="kyc-label-text form-label"
                  >
                    Email
                  </label>
                  <input
                    onChange={onChangePersonalDetailsInput}
                    id="personalDetailsEmail"
                    type="text"
                    name="emailId"
                    className={`kyc-input-field form-control  ${
                      isError?.personalDetailsEmail && "invalid-input"
                    }`}
                    onBlur={onBlurInputFeilds}
                    value={personalDetails?.emailId}
                  />
                  <span className="text-danger px-1">
                    {isError?.personalDetailsEmail}
                  </span>
                </div>
                {/* MOBILE NUMBER */}
                {/* <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="personalDetailsMobileNumber"
                    className="kyc-label-text form-label"
                  >
                    Mobile Number
                  </label>
                  <input
                    onChange={onChangePersonalDetailsInput}
                    id="personalDetailsMobileNumber"
                    type="text"
                    name="mobileNumber"
                    className={`kyc-input-field form-control  ${
                      isError?.personalDetailsMobileNumber && "invalid-input"
                    }`}
                    onBlur={onBlurInputFeilds}
                    value={personalDetails?.mobileNumber}
                  />
                  <span className="text-danger px-1">
                    {isError?.personalDetailsMobileNumber}
                  </span>
                </div> */}
                {/* PAN NUMBER */}
                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="personalDetailsPanNo"
                    className="kyc-label-text form-label"
                  >
                    Pan No.
                  </label>
                  <input
                    onChange={onChangePersonalDetailsInput}
                    id="personalDetailsPanNo"
                    type="text"
                    name="PANNumber"
                    className={`kyc-input-field form-control  ${
                      isError?.personalDetailsPanNo && "invalid-input"
                    }`}
                    onBlur={onBlurInputFeilds}
                    value={personalDetails?.PANNumber}
                  />
                  <span className="text-danger px-1">
                    {isError?.personalDetailsPanNo}
                  </span>
                </div>
                {/* UPLOAD AADHAR CARD */}
                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="personalDetailsUplaodAadhaarCard"
                    className="kyc-label-text form-label"
                  >
                    Upload Aadhaar Front Side
                  </label>
                  <div
                    className={`d-flex align-items-center gap-2 py-1 kyc-input-field form-control ${
                      isError?.personalDetailsUplaodAadhaarCard &&
                      "invalid-input"
                    }`}
                  >
                    <input
                      onChange={onChangeAadharSDKInput}
                      id="personalDetailsUplaodAadhaarCard"
                      type="file"
                      name="adharImage"
                      onBlur={onBlurInputFeilds}
                      // value={userKYCData.upload.adharImage}
                      value={aadharSDK.adharImage}
                    />
                    {userKYCData.apiData.adharImage.length !== 0 && (
                      <FiCheckCircle className="fs-3 text-success" />
                    )}
                  </div>
                  {userKYCData.apiData.adharImage.length !== 0 && (
                    <div className="col-12 d-flex align-items-center gap-2 mt-1">
                      <div className="d-flex align-items-center justify-content-between gap-2 flex-grow-1">
                        <a
                          href={userKYCData.apiData.adharImage[0].adharImage}
                          target="_blank"
                          className="text-info"
                        >
                          <span>
                            {userKYCData.apiData.adharImage[0].adharImage.slice(
                              0,
                              30
                            )}
                            ...
                          </span>
                        </a>
                        <MdDelete
                          // onClick={() =>
                          //   deleteFile({
                          //     key: "adharImage",
                          //     image_url:
                          //       userKYCData.apiData.adharImage[0].adharImage,
                          //   })
                          // }
                          role="button"
                          className="fs-4 text-danger"
                        />
                      </div>
                      {userKYCData.apiData.adharImage.length > 1 && (
                        <div className="dropdown-center">
                          <button
                            className="border-0 dropdown-toggle p-1 bg-transparent"
                            type="button"
                            id="dropdownUserAadhar"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            More
                          </button>

                          <ul
                            className="dropdown-menu dropdown-menu-start border-0 shadow"
                            aria-labelledby="dropdownUserAadhar"
                          >
                            <li>
                              <span className="dropdown-header fw-semibold d-flex align-items-center gap-2">
                                <FiFile /> Aadhar Files
                              </span>
                            </li>
                            {userKYCData.apiData.adharImage.map(
                              (eachAadharDetails, index) =>
                                index !== 0 && (
                                  <li
                                    key={eachAadharDetails.adharImage}
                                    className="dropdown-item d-flex align-items-center gap-4 justify-content-between"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <a
                                      href={eachAadharDetails.adharImage}
                                      target="_blank"
                                      className="text-info px-1 col-10"
                                    >
                                      {eachAadharDetails.adharImage.slice(
                                        0,
                                        35
                                      )}
                                      ...
                                    </a>
                                    <MdDelete
                                      // onClick={() =>
                                      //   deleteFile({
                                      //     key: "adharImage",
                                      //     image_url:
                                      //       eachAadharDetails.adharImage,
                                      //   })
                                      // }
                                      role="button"
                                      className="fs-4 text-danger"
                                    />
                                  </li>
                                )
                            )}
                          </ul>
                        </div>
                      )}
                    </div>
                  )}

                  <span className="text-danger px-1">
                    {isError?.personalDetailsUplaodAadhaarCard}
                  </span>
                </div>
                {/* UPLOAD AADHAR CARD BACK */}
                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="personalDetailsUplaodAadhaarCardBack"
                    className="kyc-label-text form-label"
                  >
                    Upload Aadhaar Back Side
                  </label>
                  <div
                    className={`d-flex align-items-center gap-2 py-1 kyc-input-field form-control ${
                      isError?.personalDetailsUplaodAadhaarCard &&
                      "invalid-input"
                    }`}
                  >
                    <input
                      onChange={onChangeAadharSDKInput}
                      id="personalDetailsUplaodAadhaarCardBack"
                      type="file"
                      name="adharImageBack"
                      onBlur={onBlurInputFeilds}
                      // value={userKYCData.upload.adharImage}
                      value={aadharSDK.adharImageBack}
                    />
                    {/* {userKYCData.apiData.adharImageBack.length !== 0 && (
                      <FiCheckCircle className="fs-3 text-success" />
                    )} */}
                  </div>
                  {/* {userKYCData.apiData.adharImageBack.length !== 0 && (
                    <div className="col-12 d-flex align-items-center gap-2 mt-1">
                      <div className="d-flex align-items-center justify-content-between gap-2 flex-grow-1">
                        <a
                          href={
                            userKYCData.apiData.adharImageBack[0].adharImageBack
                          }
                          target="_blank"
                          className="text-info"
                        >
                          <span>
                            {userKYCData.apiData.adharImage[0].adharImage.slice(
                              0,
                              30
                            )}
                            ...
                          </span>
                        </a>
                        <MdDelete
                          // onClick={() =>
                          //   deleteFile({
                          //     key: "adharImage",
                          //     image_url:
                          //       userKYCData.apiData.adharImage[0].adharImage,
                          //   })
                          // }
                          role="button"
                          className="fs-4 text-danger"
                        />
                      </div>
                      {userKYCData.apiData.adharImage.length > 1 && (
                        <div className="dropdown-center">
                          <button
                            className="border-0 dropdown-toggle p-1 bg-transparent"
                            type="button"
                            id="dropdownUserAadhar"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            More
                          </button>

                          <ul
                            className="dropdown-menu dropdown-menu-start border-0 shadow"
                            aria-labelledby="dropdownUserAadhar"
                          >
                            <li>
                              <span className="dropdown-header fw-semibold d-flex align-items-center gap-2">
                                <FiFile /> Aadhar Files
                              </span>
                            </li>
                            {userKYCData.apiData.adharImage.map(
                              (eachAadharDetails, index) =>
                                index !== 0 && (
                                  <li
                                    key={eachAadharDetails.adharImage}
                                    className="dropdown-item d-flex align-items-center gap-4 justify-content-between"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <a
                                      href={eachAadharDetails.adharImage}
                                      target="_blank"
                                      className="text-info px-1 col-10"
                                    >
                                      {eachAadharDetails.adharImage.slice(
                                        0,
                                        35
                                      )}
                                      ...
                                    </a>
                                    <MdDelete
                                      // onClick={() =>
                                      //   deleteFile({
                                      //     key: "adharImage",
                                      //     image_url:
                                      //       eachAadharDetails.adharImage,
                                      //   })
                                      // }
                                      role="button"
                                      className="fs-4 text-danger"
                                    />
                                  </li>
                                )
                            )}
                          </ul>
                        </div>
                      )}
                    </div>
                  )} */}

                  {/* <span className="text-danger px-1">
                    {isError?.personalDetailsUplaodAadhaarCard}
                  </span> */}
                </div>
                {/* PROFILE PICTURE */}
                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="personalDetailsUploadProfilePicture"
                    className="kyc-label-text form-label"
                  >
                    Profile Picture
                  </label>
                  <div
                    className={`d-flex align-items-center gap-2 py-1 kyc-input-field form-control ${
                      isError?.personalDetailsUploadProfilePicture &&
                      "invalid-input"
                    }`}
                  >
                    <input
                      onChange={onChangeProfilePictureInput}
                      id="personalDetailsUploadProfilePicture"
                      type="file"
                      name="profilePicture"
                      onBlur={onBlurProfilePictureInputFields}
                      value={userProfilePicture.upload}
                    />
                    {userProfilePicture.apiData.length !== 0 && (
                      <FiCheckCircle className="fs-3 text-success" />
                    )}
                  </div>
                  {userProfilePicture.apiData.length !== 0 && (
                    <div className="col-12 d-flex align-items-center gap-2 mt-1">
                      <div className="d-flex align-items-center justify-content-between gap-2 flex-grow-1">
                        <a
                          href={userProfilePicture.apiData[0].userProfilePic}
                          target="_blank"
                          className="text-info"
                        >
                          <span>
                            {userProfilePicture.apiData[0].userProfilePic.slice(
                              0,
                              30
                            )}
                            ...
                          </span>
                        </a>
                        <MdDelete
                          // onClick={() =>
                          //   deleteFile({
                          //     key: "userProfilePic",
                          //     image_url:
                          //       userProfilePicture.apiData[0].userProfilePic,
                          //   })
                          // }
                          role="button"
                          className="fs-4 text-danger"
                        />
                      </div>
                      {userProfilePicture.apiData.length > 1 && (
                        <div className="dropdown-center">
                          <button
                            className="border-0 dropdown-toggle p-1 bg-transparent"
                            type="button"
                            id="dropdownUserAadhar"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            More
                          </button>

                          <ul
                            className="dropdown-menu dropdown-menu-start border-0 shadow"
                            aria-labelledby="dropdownUserAadhar"
                          >
                            <li>
                              <span className="dropdown-header fw-semibold d-flex align-items-center gap-2">
                                <FiFile /> Aadhar Files
                              </span>
                            </li>
                            {userProfilePicture.apiData.map(
                              (eachAadharDetails, index) =>
                                index !== 0 && (
                                  <li
                                    key={eachAadharDetails.userProfilePic}
                                    className="dropdown-item d-flex align-items-center gap-4 justify-content-between"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <a
                                      href={eachAadharDetails.userProfilePic}
                                      target="_blank"
                                      className="text-info px-1 col-10"
                                    >
                                      {eachAadharDetails.userProfilePic.slice(
                                        0,
                                        35
                                      )}
                                      ...
                                    </a>
                                    <MdDelete
                                      // onClick={() =>
                                      //   deleteFile({
                                      //     key: "userProfilePic",
                                      //     image_url:
                                      //       eachAadharDetails.userProfilePic,
                                      //   })
                                      // }
                                      role="button"
                                      className="fs-4 text-danger"
                                    />
                                  </li>
                                )
                            )}
                          </ul>
                        </div>
                      )}
                    </div>
                  )}
                  <span className="text-danger px-1">
                    {isError?.personalDetailsUploadProfilePicture}
                  </span>
                </div>
              </div>
            </div>

            {/* Co-Applicant Details */}
            <div
              id="accordionExample"
              className="accordion container-with-heading d-flex flex-column container my-3 p-0"
            >
              <span
                className="kyc-content-title row accordion-button m-0 bg-warning bg-opacity-50 placeholder-wave"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                Co-Applicant Details
              </span>

              <div
                className="details-container accordion-collapse collapse show"
                id="collapseOne"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div className="row gy-md-4 gy-2">
                  <div className="kyc-fields-container col-12 col-md-4">
                    <label
                      htmlFor="coApplicantName"
                      className="kyc-label-text form-label"
                    >
                      Name
                    </label>
                    <input
                      onChange={onChangeCoApplicantInput}
                      id="coApplicantName"
                      name="name"
                      type="text"
                      className={`kyc-input-field form-control  ${
                        isError?.coApplicantName && "invalid-input"
                      }`}
                      onBlur={onBlurInputFeilds}
                      value={coApplicant?.name}
                    />
                    <span className="text-danger px-1">
                      {isError?.coApplicantName}
                    </span>
                  </div>

                  <div className="kyc-fields-container col-12  col-md-4">
                    <label
                      htmlFor="coApplicantMobileNumber"
                      className="kyc-label-text form-label"
                    >
                      Mobile Number
                    </label>
                    <input
                      onChange={onChangeCoApplicantInput}
                      id="coApplicantMobileNumber"
                      type="datextte"
                      name="mobileNumber"
                      className={`kyc-input-field form-control  ${
                        isError?.coApplicantMobileNumber && "invalid-input"
                      }`}
                      onBlur={onBlurInputFeilds}
                      value={coApplicant?.mobileNumber}
                    />
                    <span className="text-danger px-1">
                      {isError?.coApplicantMobileNumber}
                    </span>
                  </div>

                  <div className="kyc-fields-container col-12  col-md-4">
                    <label
                      htmlFor="coApplicantPANNumber"
                      className="kyc-label-text form-label"
                    >
                      Pan No.
                    </label>
                    <input
                      onChange={onChangeCoApplicantInput}
                      id="coApplicantPANNumber"
                      type="text"
                      name="PANNumber"
                      className={`kyc-input-field form-control  ${
                        isError?.coApplicantPANNumber && "invalid-input"
                      }`}
                      onBlur={onBlurInputFeilds}
                      value={coApplicant?.PANNumber}
                    />
                    <span className="text-danger px-1">
                      {isError?.coApplicantPANNumber}
                    </span>
                  </div>

                  <div className="kyc-fields-container col-12  col-md-4">
                    <label
                      htmlFor="coApplicantAadharImageFront"
                      className="kyc-label-text form-label"
                    >
                      Upload Aadhaar Card
                    </label>
                    <div
                      className={`d-flex align-items-center gap-2 py-1 kyc-input-field form-control ${
                        isError?.coApplicantUplaodAadhaarCard && "invalid-input"
                      }`}
                    >
                      <input
                        onChange={onChangeAadharSDKInput}
                        id="coApplicantAadharImageFront"
                        type="file"
                        name="coAadharImage"
                        onBlur={onBlurInputFeilds}
                        // value={userKYCData.upload.coAadharImage}
                        value={aadharSDK.coAadharImage}
                      />
                      {userKYCData.apiData.coAadharImage.length !== 0 && (
                        <FiCheckCircle className="fs-3 text-success" />
                      )}
                    </div>
                    {userKYCData.apiData.coAadharImage.length !== 0 && (
                      <div className="col-12 d-flex align-items-center gap-2 mt-1">
                        <div className="d-flex align-items-center justify-content-between gap-2 flex-grow-1">
                          <a
                            href={
                              userKYCData.apiData.coAadharImage[0].coAadharImage
                            }
                            target="_blank"
                            className="text-info"
                          >
                            <span>
                              {userKYCData.apiData.coAadharImage[0].coAadharImage.slice(
                                0,
                                30
                              )}
                              ...
                            </span>
                          </a>
                          <MdDelete
                            // onClick={() =>
                            //   deleteFile({
                            //     key: "coAadharImage",
                            //     image_url:
                            //       userKYCData.apiData.coAadharImage[0]
                            //         .coAadharImage,
                            //   })
                            // }
                            role="button"
                            className="fs-4 text-danger"
                          />
                        </div>
                        {userKYCData.apiData.coAadharImage.length > 1 && (
                          <div className="dropdown-center">
                            <button
                              className="border-0 dropdown-toggle p-1 bg-transparent"
                              type="button"
                              id="dropdownUserAadhar"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              More
                            </button>

                            <ul
                              className="dropdown-menu dropdown-menu-start border-0 shadow"
                              aria-labelledby="dropdownUserAadhar"
                            >
                              <li>
                                <span className="dropdown-header fw-semibold d-flex align-items-center gap-2">
                                  <FiFile /> Aadhar Files
                                </span>
                              </li>
                              {userKYCData.apiData.coAadharImage.map(
                                (eachAadharDetails, index) =>
                                  index !== 0 && (
                                    <li
                                      key={eachAadharDetails.coAadharImage}
                                      className="dropdown-item d-flex align-items-center gap-4 justify-content-between"
                                      style={{ cursor: "pointer" }}
                                    >
                                      <a
                                        href={eachAadharDetails.coAadharImage}
                                        target="_blank"
                                        className="text-info px-1 col-10"
                                      >
                                        {eachAadharDetails.coAadharImage.slice(
                                          0,
                                          35
                                        )}
                                        ...
                                      </a>
                                      <MdDelete
                                        // onClick={() =>
                                        //   deleteFile({
                                        //     key: "coAadharImage",
                                        //     image_url:
                                        //       eachAadharDetails.coAadharImage,
                                        //   })
                                        // }
                                        role="button"
                                        className="fs-4 text-danger"
                                      />
                                    </li>
                                  )
                              )}
                            </ul>
                          </div>
                        )}
                      </div>
                    )}
                    <span className="text-danger px-1">
                      {isError?.coApplicantUplaodAadhaarCard}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* COMPANY INFORMATION */}
          <div className="container-with-heading d-flex flex-column container">
            <span className="kyc-content-title mb-3 row">
              Company Information
            </span>
            <div className="details-container ">
              <div className="row gy-md-4 gy-2">
                {/* LOGO */}
                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="companyInfoProfilePicture"
                    className="kyc-label-text form-label"
                  >
                    Logo
                  </label>
                  <div
                    className={`d-flex align-items-center gap-2 py-1 kyc-input-field form-control ${
                      isError?.companyInfoProfilePicture && "invalid-input"
                    }`}
                  >
                    <input
                      onChange={onChangeCompanyInfoProfilePicInput}
                      id="companyInfoProfilePicture"
                      type="file"
                      name="profilePicture"
                      // onBlur={onBlurProfilePictureInputFields}
                      value={companyInfoProfilePicture.upload}
                    />
                    {companyInfoProfilePicture.apiData.length !== 0 && (
                      <FiCheckCircle className="fs-3 text-success" />
                    )}
                  </div>
                  {companyInfoProfilePicture.apiData.length !== 0 && (
                    <div className="col-12 d-flex align-items-center gap-2 mt-1">
                      <div className="d-flex align-items-center justify-content-between gap-2 flex-grow-1">
                        <a
                          href={
                            companyInfoProfilePicture.apiData[0].profilePicture
                          }
                          target="_blank"
                          className="text-info"
                        >
                          <span>
                            {companyInfoProfilePicture.apiData[0].profilePicture.slice(
                              0,
                              30
                            )}
                            ...
                          </span>
                        </a>
                        <MdDelete
                          // onClick={() =>
                          //   deleteFile({
                          //     key: "profilePicture",
                          //     image_url:
                          //       companyInfoProfilePicture.apiData[0]
                          //         .profilePicture,
                          //   })
                          // }
                          role="button"
                          className="fs-4 text-danger"
                        />
                      </div>
                      {companyInfoProfilePicture.apiData.length > 1 && (
                        <div className="dropdown-center">
                          <button
                            className="border-0 dropdown-toggle p-1 bg-transparent"
                            type="button"
                            id="dropdownUserAadhar"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            More
                          </button>

                          <ul
                            className="dropdown-menu dropdown-menu-start border-0 shadow"
                            aria-labelledby="dropdownUserAadhar"
                          >
                            <li>
                              <span className="dropdown-header fw-semibold d-flex align-items-center gap-2">
                                <FiFile /> Aadhar Files
                              </span>
                            </li>
                            {companyInfoProfilePicture.apiData.map(
                              (eachAadharDetails, index) =>
                                index !== 0 && (
                                  <li
                                    key={eachAadharDetails.profilePicture}
                                    className="dropdown-item d-flex align-items-center gap-4 justify-content-between"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <a
                                      href={eachAadharDetails.profilePicture}
                                      target="_blank"
                                      className="text-info px-1 col-10"
                                    >
                                      {eachAadharDetails.profilePicture.slice(
                                        0,
                                        35
                                      )}
                                      ...
                                    </a>
                                    <MdDelete
                                      // onClick={() =>
                                      //   deleteFile({
                                      //     key: "profilePicture",
                                      //     image_url:
                                      //       eachAadharDetails.profilePicture,
                                      //   })
                                      // }
                                      role="button"
                                      className="fs-4 text-danger"
                                    />
                                  </li>
                                )
                            )}
                          </ul>
                        </div>
                      )}
                    </div>
                  )}
                  <span className="text-danger px-1">
                    {isError?.personalDetailsUploadProfilePicture}
                  </span>
                </div>
                {/* LOAN CATEGORY */}
                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="storeInfoProductCategory"
                    className="kyc-label-text form-label"
                  >
                    Loan Category
                  </label>

                  <select
                    onChange={onChangeCompanyInformationInput}
                    id="storeInfoProductCategory"
                    name="productCategory"
                    className={`kyc-input-field form-select  ${
                      isError?.storeInfoProductCategory && "invalid-input"
                    }`}
                    // onBlur={onBlurInputFeilds}
                    value={companyInformation?.productCategory}
                    defaultValue=""
                  >
                    <option value="" disabled>
                      Choose Loan Category
                    </option>

                    <option value="Supply Chain Finance">
                      Supply Chain Finance
                    </option>

                    <option value="Vendor Financing">Vendor Financing</option>
                    <option value="B2B">B2B</option>
                    <option value="B2C">B2C</option>
                  </select>
                  <span className="text-danger px-1">
                    {isError?.storeInfoStoreLogo}
                  </span>
                </div>
                {/* COMPANY NAME */}
                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="storeInfoStoreName"
                    className="kyc-label-text form-label"
                  >
                    Company Name
                  </label>
                  <input
                    onChange={onChangeCompanyInformationInput}
                    id="storeInfoStoreName"
                    type="text"
                    name="companyFullName"
                    className={`kyc-input-field form-control  ${
                      isError?.storeInfoStoreName && "invalid-input"
                    }`}
                    onBlur={onBlurInputFeilds}
                    value={companyInformation?.companyFullName}
                  />
                  <span className="text-danger px-1">
                    {isError?.storeInfoStoreName}
                  </span>
                </div>
                {/* COMPANY TYPE */}
                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="storeInfoCompanyType"
                    className="kyc-label-text form-label"
                  >
                    Company Type
                  </label>

                  <select
                    onChange={onChangeCompanyInformationInput}
                    id="storeInfoCompanyType"
                    name="companyType"
                    className={`kyc-input-field form-select  ${
                      isError?.storeInfoCompanyType && "invalid-input"
                    }`}
                    // onBlur={onBlurInputFeilds}
                    value={companyInformation?.companyType}
                    defaultValue=""
                  >
                    <option value="" disabled>
                      Choose Company Type
                    </option>

                    <option value="Private Limited">Private Limited</option>

                    <option value="Public Limited">Public Limited</option>

                    <option value="Limited Liability Partnership ">
                      Limited Liability Partnership{" "}
                    </option>

                    <option value="Partnership">Partnership</option>

                    <option value="Proprietorship">Proprietorship</option>
                  </select>
                  <span className="text-danger px-1">
                    {isError?.storeInfoCompanyType}
                  </span>
                </div>

                {/* WEBSITE ADDRESS */}
                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="storeInfoWebAddress"
                    className="kyc-label-text form-label"
                  >
                    Website Address
                  </label>
                  <input
                    onChange={onChangeCompanyInformationInput}
                    id="storeInfoWebAddress"
                    type="text"
                    name="websiteAddress"
                    className={`kyc-input-field form-control  ${
                      isError?.storeInfoWebAddress && "invalid-input"
                    }`}
                    onBlur={onBlurInputFeilds}
                    value={companyInformation?.websiteAddress}
                  />
                  <span className="text-danger px-1">
                    {isError?.storeInfoWebAddress}
                  </span>
                </div>
                {/* EMAIL */}
                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="storeInfoEmail"
                    className="kyc-label-text form-label"
                  >
                    Email
                  </label>
                  <input
                    // onChange={onChangeCompanyInformationInput}
                    id="storeInfoEmail"
                    type="text"
                    name="companyEmail"
                    className={`kyc-input-field form-control  ${
                      isError?.storeInfoEmail && "invalid-input"
                    }`}
                    // onBlur={onBlurInputFeilds}
                    value={emailId}
                    disabled
                  />
                  {/* <span className="text-danger px-1">
                    {isError?.storeInfoEmail}
                  </span> */}
                </div>

                {/* GST NUMBER */}
                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="storeInfoGSTNumber"
                    className="kyc-label-text form-label"
                  >
                    GST Number
                  </label>
                  <input
                    onChange={onChangeCompanyInformationInput}
                    id="storeInfoGSTNumber"
                    type="text"
                    name="GSTNumber"
                    className={`kyc-input-field form-control  ${
                      isError?.storeInfoGSTNumber && "invalid-input"
                    }`}
                    onBlur={onBlurInputFeilds}
                    value={companyInformation?.GSTNumber}
                  />
                  <span className="text-danger px-1">
                    {isError?.storeInfoGSTNumber}
                  </span>
                </div>
                {/* CIN NUMBER */}
                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="storeInfoCINNumber"
                    className="kyc-label-text form-label"
                  >
                    CIN Number
                  </label>
                  <input
                    onChange={onChangeCompanyInformationInput}
                    id="storeInfoCINNumber"
                    type="text"
                    name="CIN"
                    className={`kyc-input-field form-control  ${
                      isError?.storeInfoCINNumber && "invalid-input"
                    }`}
                    onBlur={onBlurInputFeilds}
                    value={companyInformation?.CIN}
                  />
                  <span className="text-danger px-1">
                    {isError?.storeInfoCINNumber}
                  </span>
                </div>
                {/* PAN NUMBER */}
                {/* <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="storeInfoGSTNumber"
                    className="kyc-label-text form-label"
                  >
                    PAN Number
                  </label>
                  <input
                    onChange={onChangeCompanyInformationInput}
                    id="storeInfoGSTNumber"
                    type="text"
                    name="storeInfoGSTNumber"
                    className={`kyc-input-field form-control  ${
                      isError?.storeInfoGSTNumber && "invalid-input"
                    }`}
                    onBlur={onBlurInputFeilds}
                    // value={kycData.data?.success?.anything}
                  />
                  <span className="text-danger px-1">
                    {isError?.storeInfoGSTNumber}
                  </span>
                </div> */}
                {/* SECURITY */}
                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="storeInfoSecurity"
                    className="kyc-label-text form-label"
                  >
                    Security
                  </label>

                  <select
                    onChange={onChangeCompanyInformationInput}
                    id="storeInfoSecurity"
                    name="security"
                    className={`kyc-input-field form-select  ${
                      isError?.storeInfoSecurity && "invalid-input"
                    }`}
                    // onBlur={onBlurInputFeilds}
                    value={companyInformation?.security}
                    defaultValue=""
                  >
                    <option value="" disabled>
                      Choose Security Type
                    </option>

                    <option value="Enforceable Security">
                      Enforceable Security
                    </option>

                    <option value="Corporate guarantee & PG of Directors">
                      Corporate guarantee & PG of Directors
                    </option>
                  </select>
                  <span className="text-danger px-1">
                    {isError?.storeInfoStoreLogo}
                  </span>
                </div>

                {/* COMPANY ADDRESS */}
                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="storeInfoCompanyAddress"
                    className="kyc-label-text form-label"
                  >
                    Company Address
                  </label>
                  <input
                    onChange={onChangeCompanyInformationInput}
                    id="storeInfoCompanyAddress"
                    type="text"
                    name="companyFullAddress"
                    className={`kyc-input-field form-control  ${
                      isError?.storeInfoCompanyAddress && "invalid-input"
                    }`}
                    onBlur={onBlurInputFeilds}
                    value={companyInformation?.companyFullAddress}
                  />
                  <span className="text-danger px-1">
                    {isError?.storeInfoCompanyAddress}
                  </span>
                </div>
                {/* CITY */}
                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="storeInfoComapnyCity"
                    className="kyc-label-text form-label"
                  >
                    City
                  </label>
                  <input
                    onChange={onChangeCompanyInformationInput}
                    id="storeInfoComapnyCity"
                    type="text"
                    name="city"
                    className={`kyc-input-field form-control  ${
                      isError?.storeInfoComapnyCity && "invalid-input"
                    }`}
                    onBlur={onBlurInputFeilds}
                    value={companyInformation?.city}
                  />
                  <span className="text-danger px-1">
                    {isError?.storeInfoComapnyCity}
                  </span>
                </div>
                {/* STATE */}
                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="storeInfoState"
                    className="kyc-label-text form-label"
                  >
                    State
                  </label>

                  <select
                    onChange={onChangeCompanyInformationInput}
                    id="storeInfoState"
                    name="state"
                    className={`kyc-input-field form-select  ${
                      isError?.storeInfoState && "invalid-input"
                    }`}
                    // onBlur={onBlurInputFeilds}
                    value={companyInformation?.state}
                    defaultValue=""
                  >
                    <option value="" disabled>
                      Choose Your State
                    </option>

                    {indianStateList.map((eachState) => (
                      <option
                        key={eachState.stateCode}
                        value={eachState.stateName}
                      >
                        {eachState.stateName}
                      </option>
                    ))}
                  </select>
                  <span className="text-danger px-1">
                    {isError?.storeInfoState}
                  </span>
                </div>
                {/* PINCODE */}
                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="storeInfoPinCode"
                    className="kyc-label-text form-label"
                  >
                    Pin code
                  </label>
                  <input
                    onChange={onChangeCompanyInformationInput}
                    id="storeInfoPinCode"
                    type="number"
                    name="pinCode"
                    className={`kyc-input-field form-control  ${
                      isError?.storeInfoPinCode && "invalid-input"
                    }`}
                    onBlur={onBlurInputFeilds}
                    value={companyInformation.pinCode}
                  />
                  <span className="text-danger px-1">
                    {isError?.storeInfoPinCode}
                  </span>
                </div>
                {/* REGION */}
                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="storeInfoRegion"
                    className="kyc-label-text form-label"
                  >
                    Region
                  </label>

                  <select
                    onChange={onChangeCompanyInformationInput}
                    id="storeInfoRegion"
                    name="region"
                    className={`kyc-input-field form-select  ${
                      isError?.storeInfoRegion && "invalid-input"
                    }`}
                    // onBlur={onBlurInputFeilds}
                    // value={kycData.data?.success?.anything}
                    defaultValue=""
                  >
                    <option value="" disabled>
                      Choose Your Region
                    </option>
                    <option value="East">East</option>
                    <option value="West">West</option>
                    <option value="North">North</option>
                    <option value="South">South</option>
                  </select>
                  <span className="text-danger px-1">
                    {isError?.storeInfoRegion}
                  </span>
                </div>
                {/* COUNTRY */}
                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="storeInfoCountry"
                    className="kyc-label-text form-label"
                  >
                    Country
                  </label>
                  <input
                    onChange={onChangeCompanyInformationInput}
                    id="storeInfoCountry"
                    type="text"
                    name="country"
                    className={`kyc-input-field form-control  ${
                      isError?.storeInfoCountry && "invalid-input"
                    }`}
                    onBlur={onBlurInputFeilds}
                    // value={kycData.data?.success?.anything}
                  />
                  <span className="text-danger px-1">
                    {isError?.storeInfoCountry}
                  </span>
                </div>
                {/* TYPE OF OFFICIES */}
                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="storeInfoTypeOfOffices"
                    className="kyc-label-text form-label"
                  >
                    Type of Offices
                  </label>

                  <select
                    onChange={onChangeCompanyInformationInput}
                    id="storeInfoTypeOfOffices"
                    name="typeOfOffices"
                    className={`kyc-input-field form-select  ${
                      isError?.storeInfoTypeOfOffices && "invalid-input"
                    }`}
                    // onBlur={onBlurInputFeilds}
                    value={companyInformation?.typeOfOffices}
                    defaultValue=""
                  >
                    <option value="" disabled>
                      Choose Your Type Of Office
                    </option>
                    <option value="Owned">Owned</option>
                    <option value="Rented">Rented</option>
                  </select>
                  <span className="text-danger px-1">
                    {isError?.storeInfoStoreLogo}
                  </span>
                </div>
                {/* NO. OF OFFICIES */}
                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="storeInfoNoOfOffice"
                    className="kyc-label-text form-label"
                  >
                    No. of Offices
                  </label>
                  <input
                    onChange={onChangeCompanyInformationInput}
                    id="storeInfoNoOfOffice"
                    type="number"
                    name="noOfOffices"
                    className={`kyc-input-field form-control  ${
                      isError?.storeInfoNoOfOffice && "invalid-input"
                    }`}
                    onBlur={onBlurInputFeilds}
                    value={companyInformation?.noOfOffices}
                  />
                  <span className="text-danger px-1">
                    {isError?.storeInfoNoOfOffice}
                  </span>
                </div>
                {/* Type of Store/Plant */}
                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="storeInfoTypeOfStoreOrPlant"
                    className="kyc-label-text form-label"
                  >
                    Type of Store/Plant
                  </label>

                  <select
                    onChange={onChangeCompanyInformationInput}
                    id="storeInfoTypeOfStoreOrPlant"
                    name="typeOfShowroom"
                    className={`kyc-input-field form-select  ${
                      isError?.storeInfoTypeOfStoreOrPlant && "invalid-input"
                    }`}
                    // onBlur={onBlurInputFeilds}
                    value={companyInformation?.typeOfShowroom}
                    defaultValue=""
                  >
                    <option value="" disabled>
                      Choose Your Type Of Store/Plant
                    </option>
                    <option value="Owned">Owned</option>
                    <option value="Rented">Rented</option>
                    <option value="No Store/Plant">No Store/Plant</option>
                  </select>
                  <span className="text-danger px-1">
                    {isError?.storeInfoTypeOfStoreOrPlant}
                  </span>
                </div>
                {/* No. of Employees in Store/Plant */}
                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="storeInfoNoOfEmployeesInStore"
                    className="kyc-label-text form-label"
                  >
                    No. of Employees in Store/Plant
                  </label>
                  <input
                    onChange={onChangeCompanyInformationInput}
                    id="storeInfoNoOfEmployeesInStore"
                    type="number"
                    name="noOfEMPShowroom"
                    className={`kyc-input-field form-control  ${
                      isError?.storeInfoNoOfEmployeesInStore && "invalid-input"
                    }`}
                    onBlur={onBlurInputFeilds}
                    value={companyInformation?.noOfEMPShowroom}
                  />
                  <span className="text-danger px-1">
                    {isError?.storeInfoNoOfEmployeesInStore}
                  </span>
                </div>
                {/* No. of Warehouse */}
                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="storeInfoNoOfWarehose"
                    className="kyc-label-text form-label"
                  >
                    No. of Warehouse
                  </label>
                  <input
                    onChange={onChangeCompanyInformationInput}
                    id="storeInfoNoOfWarehose"
                    type="number"
                    name="noOfWarehouse"
                    className={`kyc-input-field form-control  ${
                      isError?.storeInfoNoOfWarehose && "invalid-input"
                    }`}
                    onBlur={onBlurInputFeilds}
                    value={companyInformation?.noOfWarehouse}
                  />
                  <span className="text-danger px-1">
                    {isError?.storeInfoNoOfWarehose}
                  </span>
                </div>
                {/* Telephone Number */}
                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="storeInfoTelNumber"
                    className="kyc-label-text form-label"
                  >
                    Telephone Number
                  </label>
                  <input
                    onChange={onChangeCompanyInformationInput}
                    id="storeInfoTelNumber"
                    type="text"
                    name="phoneNumber"
                    className={`kyc-input-field form-control  ${
                      isError?.storeInfoTelNumber && "invalid-input"
                    }`}
                    onBlur={onBlurInputFeilds}
                    value={companyInformation?.phoneNumber}
                  />
                  <span className="text-danger px-1">
                    {isError?.storeInfoTelNumber}
                  </span>
                </div>
                {/* Mobile Number */}
                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="storeInfoMobileNumber"
                    className="kyc-label-text form-label"
                  >
                    Mobile Number
                  </label>
                  <input
                    onChange={onChangeCompanyInformationInput}
                    id="storeInfoMobileNumber"
                    type="text"
                    name="mobileNumber"
                    className={`kyc-input-field form-control  ${
                      isError?.storeInfoMobileNumber && "invalid-input"
                    }`}
                    onBlur={onBlurInputFeilds}
                    value={companyInformation?.mobileNumber}
                  />
                  <span className="text-danger px-1">
                    {isError?.storeInfoMobileNumber}
                  </span>
                </div>
                {/* No. of Employees In Warehouse */}
                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="storeInfoNoOfEmployeesInWareHouse"
                    className="kyc-label-text form-label"
                  >
                    No. of Employees In Warehouse
                  </label>
                  <input
                    onChange={onChangeCompanyInformationInput}
                    id="storeInfoNoOfEmployeesInWareHouse"
                    type="number"
                    name="noOfEMPWarehouse"
                    className={`kyc-input-field form-control  ${
                      isError?.storeInfoNoOfEmployeesInWareHouse &&
                      "invalid-input"
                    }`}
                    onBlur={onBlurInputFeilds}
                    value={companyInformation?.noOfEMPWarehouse}
                  />
                  <span className="text-danger px-1">
                    {isError?.storeInfoNoOfEmployeesInWareHouse}
                  </span>
                </div>
                {/* Details about the Store / Promoters */}
                <div className="kyc-fields-container col-12  col-md-8">
                  <label
                    htmlFor="aboutCompany"
                    className="kyc-label-text form-label"
                  >
                    Details about the Store / Promoters
                  </label>
                  <textarea
                    onChange={onChangeCompanyInformationInput}
                    id="aboutCompany"
                    type="text"
                    name="DetailAboutCompany"
                    className="kyc-input-field form-control"
                    value={companyInformation?.DetailAboutCompany}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          {/* BANK DETAILS */}
          <div className="container-with-heading d-flex flex-column container">
            <span className="kyc-content-title mb-3 row">Bank Details</span>
            <div className="details-container ">
              <div className="row gy-md-4 gy-2">
                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="bankDetailsAccountType"
                    className="kyc-label-text form-label"
                  >
                    Account Type
                  </label>
                  <input
                    onChange={onChangeBankDetails}
                    id="bankDetailsAccountType"
                    name="accountType"
                    type="text"
                    className={`kyc-input-field form-control  ${
                      isError?.bankDetailsAccountType && "invalid-input"
                    }`}
                    onBlur={onBlurInputFeilds}
                    value={bankDetails?.accountType}
                  />
                  <span className="text-danger px-1">
                    {isError?.bankDetailsAccountType}
                  </span>
                </div>

                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="bankDetailsBankName"
                    className="kyc-label-text form-label"
                  >
                    Bank Name
                  </label>
                  <input
                    onChange={onChangeBankDetails}
                    id="bankDetailsBankName"
                    name="accountName"
                    type="text"
                    className={`kyc-input-field form-control  ${
                      isError?.bankDetailsBankName && "invalid-input"
                    }`}
                    onBlur={onBlurInputFeilds}
                    value={bankDetails?.accountName}
                  />
                  <span className="text-danger px-1">
                    {isError?.bankDetailsBankName}
                  </span>
                </div>

                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="bankDetailsAccountNumber"
                    className="kyc-label-text form-label"
                  >
                    Account Number
                  </label>
                  <input
                    onChange={onChangeBankDetails}
                    id="bankDetailsAccountNumber"
                    name="accountNumber"
                    type="text"
                    maxLength={20}
                    className={`kyc-input-field form-control  ${
                      isError?.bankDetailsAccountNumber && "invalid-input"
                    }`}
                    onBlur={onBlurInputFeilds}
                    value={bankDetails?.accountNumber}
                  />
                  <span className="text-danger px-1">
                    {isError?.bankDetailsAccountNumber}
                  </span>
                </div>

                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="bankDetailsAccountHolderName"
                    className="kyc-label-text form-label"
                  >
                    Account Holder's Name
                  </label>
                  <input
                    onChange={onChangeBankDetails}
                    id="bankDetailsAccountHolderName"
                    name="accountHolderName"
                    type="text"
                    className={`kyc-input-field form-control  ${
                      isError?.bankDetailsAccountHolderName && "invalid-input"
                    }`}
                    onBlur={onBlurInputFeilds}
                    value={bankDetails?.accountHolderName}
                  />
                  <span className="text-danger px-1">
                    {isError?.bankDetailsAccountHolderName}
                  </span>
                </div>

                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="bankDetailsIFSCCode"
                    className="kyc-label-text form-label"
                  >
                    IFSC Code
                  </label>
                  <input
                    onChange={onChangeBankDetails}
                    id="bankDetailsIFSCCode"
                    name="IFSCCode"
                    type="text"
                    className={`kyc-input-field form-control  ${
                      isError?.bankDetailsIFSCCode && "invalid-input"
                    }`}
                    onBlur={onBlurInputFeilds}
                    value={bankDetails?.IFSCCode}
                  />
                  <span className="text-danger px-1">
                    {isError?.bankDetailsIFSCCode}
                  </span>
                </div>

                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="bankDetailsIFSCCode"
                    className="kyc-label-text form-label"
                  >
                    Upload Bank Statement (6 Months) (Only PDF)
                  </label>
                  <div
                    className={`d-flex align-items-center gap-2 kyc-input-field form-control  ${
                      isError?.bankDetailsIFSCCode && "invalid-input"
                    }`}
                  >
                    <input
                      onChange={onChangeUploadBankStatement}
                      id="bankDetailsIFSCCode"
                      name="bankstatement"
                      type="file"
                      // onBlur={onBlurInputFeilds}
                      value={bankStatementFiles.upload}
                    />
                    {bankStatementFiles.apiData.length !== 0 && (
                      <FiCheckCircle className="fs-3 text-success" />
                    )}
                  </div>
                  {bankStatementFiles.apiData.length !== 0 && (
                    <div className="col-12 d-flex align-items-center gap-2 mt-1">
                      <div className="d-flex align-items-center justify-content-between gap-2 flex-grow-1">
                        <a
                          href={bankStatementFiles.apiData[0].profilePicture}
                          target="_blank"
                          className="text-info"
                        >
                          <span>
                            {bankStatementFiles.apiData[0].bankstatement.slice(
                              0,
                              30
                            )}
                            ...
                          </span>
                        </a>
                        <MdDelete
                          // onClick={() =>
                          //   deleteFile({
                          //     key: "bankstatement",
                          //     image_url:
                          //       bankStatementFiles.apiData[0].bankstatement,
                          //   })
                          // }
                          role="button"
                          className="fs-4 text-danger"
                        />
                      </div>
                      {bankStatementFiles.apiData.length > 1 && (
                        <div className="dropdown-center">
                          <button
                            className="border-0 dropdown-toggle p-1 bg-transparent"
                            type="button"
                            id="dropdownUserAadhar"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            More
                          </button>

                          <ul
                            className="dropdown-menu dropdown-menu-start border-0 shadow"
                            aria-labelledby="dropdownUserAadhar"
                          >
                            <li>
                              <span className="dropdown-header fw-semibold d-flex align-items-center gap-2">
                                <FiFile /> Aadhar Files
                              </span>
                            </li>
                            {bankStatementFiles.apiData.map(
                              (eachAadharDetails, index) =>
                                index !== 0 && (
                                  <li
                                    key={eachAadharDetails.bankstatement}
                                    className="dropdown-item d-flex align-items-center gap-4 justify-content-between"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <a
                                      href={eachAadharDetails.bankstatement}
                                      target="_blank"
                                      className="text-info px-1 col-10"
                                    >
                                      {eachAadharDetails.bankstatement.slice(
                                        0,
                                        35
                                      )}
                                      ...
                                    </a>
                                    <MdDelete
                                      // onClick={() =>
                                      //   deleteFile({
                                      //     key: "bankstatement",
                                      //     image_url:
                                      //       eachAadharDetails.bankstatement,
                                      //   })
                                      // }
                                      role="button"
                                      className="fs-4 text-danger"
                                    />
                                  </li>
                                )
                            )}
                          </ul>
                        </div>
                      )}
                    </div>
                  )}
                  {/* <span className="text-danger px-1">
                    {isError?.bankDetailsIFSCCode}
                  </span> */}
                </div>
              </div>
            </div>
          </div>

          {/* DOCUMENT DETAILS */}
          <div className="container-with-heading d-flex flex-column container">
            <span className="kyc-content-title mb-3 row">Document Details</span>
            <div className="details-container">
              {otherUploadDocument.map((eachDocument, index) => (
                <div key={eachDocument.id} className="row gy-md-4 gy-2">
                  <div className="kyc-fields-container col-12  col-md-4">
                    <label
                      htmlFor={`documentName_${eachDocument.id}`}
                      className="kyc-label-text form-label"
                    >
                      Document Name
                    </label>
                    <input
                      onChange={(event) =>
                        onChangeDocumentDetails(event, eachDocument.id)
                      }
                      id={`documentName_${eachDocument.id}`}
                      name={`documentName_${eachDocument.id}`}
                      type="text"
                      className="kyc-input-field form-control"
                      value={eachDocument.documentNameValue}
                    />
                  </div>

                  <div className="kyc-fields-container col-12  col-md-4">
                    <label
                      htmlFor={`bankStatement_${eachDocument.id}`}
                      className="kyc-label-text form-label"
                    >
                      Bank Statement
                    </label>
                    <div
                      className={`d-flex align-items-center gap-2 py-1 kyc-input-field form-control ${
                        isError?.personalDetailsUplaodAadhaarCard &&
                        "invalid-input"
                      }`}
                    >
                      <input
                        onChange={(event) =>
                          onChangeBankStatement(event, eachDocument.id)
                        }
                        id={`bankStatement_${eachDocument.id}`}
                        type="file"
                        // name={`bankStatement_${eachDocument.id}`}
                        name="otherDocs"
                        value={eachDocument.bankStatementValue}
                        // onBlur={onBlurInputFeilds}
                      />
                      {eachDocument.apiData && (
                        <FiCheckCircle className="fs-3 text-success" />
                      )}
                    </div>
                    {eachDocument.apiData && (
                      <>
                        {/* <a
                          href={eachDocument.otherDocs}
                          target="_blank"
                          className="text-info px-1"
                        >
                          {eachDocument.otherDocs.slice(0, 40)}...
                        </a> */}
                        <li
                          className="dropdown-item d-flex align-items-center gap-4 justify-content-between"
                          style={{ cursor: "pointer" }}
                        >
                          <a
                            href={eachDocument.apiData.otherDocs}
                            target="_blank"
                            className="text-info px-1 col-10"
                          >
                            {eachDocument.apiData.otherDocs.slice(0, 35)}
                            ...
                          </a>
                          <MdDelete
                            // onClick={() =>
                            //   deleteFile({
                            //     key: "otherDocs",
                            //     image_url: eachDocument.apiData.otherDocs,
                            //   })
                            // }
                            role="button"
                            className="fs-4 text-danger"
                          />
                        </li>
                      </>
                    )}
                    {eachDocument.otherDocs === null && (
                      <span className="text-danger px-1">
                        {isError?.[`bankStatement_${eachDocument.id}`]}
                      </span>
                    )}
                  </div>

                  <div className="kyc-fields-container d-flex gap-2 col-12  col-md-4 mt-auto mb-4">
                    {otherUploadDocument[otherUploadDocument.length - 1].id ===
                      eachDocument.id && (
                      <button
                        onClick={onClickAddDocumentDetailsField}
                        className="d-flex justify-content-center align-items-center gap-3 list-group-item list-group-item-action list-group-item-primary w-50 mx-auto py-2 rounded fw-semibold"
                      >
                        Add <MdPostAdd className="fs-4" />
                      </button>
                    )}

                    {index !== 0 && eachDocument.apiData === null && (
                      <button
                        // onClick={() =>
                        //   onClickDeleteDocumentDetailsField(eachDocument.id)
                        // }
                        className="d-flex justify-content-center align-items-center gap-3 list-group-item list-group-item-action list-group-item-danger w-50 mx-auto py-2 rounded fw-semibold"
                      >
                        Delete <MdDelete className="fs-4" />
                      </button>
                    )}
                  </div>
                </div>
              ))}

              <div className="row gy-md-4 gy-2 mt-1">
                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="documentDetailsLoadAmount"
                    className="kyc-label-text form-label"
                  >
                    Loan Amount
                  </label>
                  <input
                    onChange={onChangeLimitCash}
                    id="documentDetailsLoadAmount"
                    name="limitCash"
                    type="text"
                    className={`kyc-input-field form-control  ${
                      isError?.documentDetailsLoadAmount && "invalid-input"
                    }`}
                    value={limitCash}
                    onBlur={onBlurInputFeilds}
                  />
                  <span className="text-danger px-1">
                    {isError?.documentDetailsLoadAmount}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="mx-5">
            <div
              className={`d-flex gap-2 mx-5 align-items-center my-2 details-page-container ${
                termAndCondition ? "bg-success" : "bg-danger"
              } bg-opacity-25 p-2`}
            >
              <input
                id="termAndCondition"
                type="checkbox"
                className="form-check-input"
                value={termAndCondition}
                onChange={onChangeTermAndCondition}
                required
              />
              <label
                htmlFor="termAndCondition"
                className="kyc-label-text form-check-label"
              >
                I hereby declare that this invoice is not funded/discounted with
                any financier outside ATON
              </label>
            </div>
          </div>

          <div className="d-flex flex-column flex-md-row align-items-center justify-content-end gap-3 mx-5 mb-5">
            <button type="submit" className="kyc-back-button">
              Submit
            </button>
          </div>
        </form>

        <div className="d-flex   justify-content-center mt-4">
          <span>Copyright 2020 Aton.</span>
        </div>
      </div>
    </>
  );
};

export default KycVerificationForm;
