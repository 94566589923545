import React, { Fragment, useContext, useId, useState, useRef } from "react";
import { FiCheckCircle } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { AnchorKycContext } from "../../Components/Anchor/AnchorKyc/anchorKycContext";
import { BuyerKycContext } from "../../Components/Buyer/BuyerKyc/buyerKycContext";
import { ChannelPartnerKycContext } from "../../Components/Channel/channelPartnerKYC/channelPartnerKycContext";

/**
 * @param {{
 * 	documentNameId?: string,
 * 	documentId?: string,
 * 	documentName: string,
 * 	document: string,
 * 	onChangeDocNameHandler?: (event: React.ChangeEvent<HTMLInputElement>) => void,
 * 	onBlurDocNameHandler?: (event: React.FocusEvent<HTMLInputElement>) => void,
 * 	onChangeDocHandler?: (event: React.ChangeEvent<HTMLInputElement>) => void,
 * 	onBlurDocHandler?: (event: React.FocusEvent<HTMLInputElement>) => void,
 * 	documentNamePlaceholder?: string,
 * 	isVerified?: boolean,
 * 	isInvalid?: boolean,
 * 	showInputField?: boolean,
 * 	showSubmitButton?: boolean,
 * 	handleAddDocument: () => void,
 * 	disabled?: boolean
 * }} props
 */
export const DocumentListItem = (props) => {

	const [documentChanged, setDocumentChanged] = useState(false);

	const documentNameId = useId();
	const documentId = useId();

	const fileInputRef = useRef(null);

	const { deleteFiles: anchorDeleteFile } = useContext(AnchorKycContext);
	const { deleteFiles: buyerDeleteFile } = useContext(BuyerKycContext);
	const { deleteFiles: channelDeleteFile } = useContext(ChannelPartnerKycContext);

	const handleDelete = (url) => {
		anchorDeleteFile('otherDocs', url);
		buyerDeleteFile("otherDocs", url);
		channelDeleteFile("otherDocs", url);
	};

	return (
		<Fragment>
			<div className="kyc-fields-container col-12  col-md-4">
				<label
					htmlFor={documentNameId}
					className="kyc-label-text form-label"
				>
					Document Name (Min 6 chars)
				</label>
				<input
					type="text"
					className="kyc-input-field form-control"
					value={props?.documentName ?? ""}
					onChange={props?.onChangeDocNameHandler ?? null}
					onBlur={props?.onBlurDocNameHandler ?? null}
					id={documentNameId}
					disabled={props?.disabled}
					placeholder={props?.documentNamePlaceholder ?? ""}
				/>
				{
					documentChanged && props?.documentName?.length < 6 &&
					<span className="text-danger">Document Name should be more than 6 chars</span>
				}
			</div>

			<div className="kyc-fields-container col-12 col-md-4">
				<label
					htmlFor={documentId}
					className="kyc-label-text form-label"
				>
					Upload Document
				</label>

				{
					props?.showInputField ?
						<Fragment>
							<div className={`d-flex align-items-center gap-2 py-1 kyc-input-field form-control ${(props?.isInvalid ?? false) && "invalid-input"}`}>
								<input
									type="file"
									id={documentId}
									name="otherDocs"
									onChange={e => {
										setDocumentChanged(true);
										props?.onChangeDocHandler?.(e);
									}}
									onBlur={props?.onBlurDocHandler ?? null}
									disabled={props?.disabled}
									ref={fileInputRef}
								/>
								{(props?.isVerified ?? false) && (
									<FiCheckCircle className="fs-3 text-success" />
								)}
							</div>
							{
								documentChanged === false && props?.documentName?.length > 0 &&
								<span className="text-danger">Please select document to upload</span>
							}
						</Fragment>
						:
						<Fragment>
							<div className={`d-flex align-items-center gap-2 py-1 px-2 ${(props?.isInvalid ?? false) && "invalid-input"}`}>
								<div className="col-12 d-flex align-items-center gap-2 mt-1">
									<div className="d-flex align-items-center justify-content-between gap-2 flex-grow-1">
										<a href={props?.document ?? null} title={props?.document ?? null} target="_blank" rel="noreferrer" className="text-info">
											<span>{props?.document?.slice(0, 33) ?? "No document"}...</span>
										</a>
										{
											props?.disabled !== true &&
											<MdDelete title="Delete File" onClick={() => handleDelete(props?.document)}
												role="button" className="fs-4 text-danger" />
										}
									</div>
								</div>
							</div>
						</Fragment>
				}

			</div>

			{
				props?.showSubmitButton && !props?.disabled &&
				<div className="kyc-fields-container d-flex gap-2 col-12 col-md-4 mt-auto mb-3 pt-2">
					<button onClick={ e => {
						setDocumentChanged(false);
						props?.handleAddDocument?.(e);
						fileInputRef.current.value = null;
					}}
						className="d-flex justify-content-center align-items-center gap-3 list-group-item list-group-item-action list-group-item-primary w-50 mx-auto py-2 rounded fw-semibold mt-5"
					>Click to Add Document</button>
				</div>
			}
		</Fragment>
	);
};