import React from "react";
import { HiOutlineArrowNarrowDown } from "react-icons/hi";
import {
  postAddUnderWriter,
  getUnderWriter,
} from "../../../store/slices/lender";
import Loader from "../../../layout/GenericLayout/Loader";
import TablePagination from "../../../layout/GenericLayout/TablePagination";




const LenderDisbursedInvoiceTable = (props) => {

  const updatePage = (currentPage) => {
    const newOffSet = props.tableQueryParams.tableLimit * (currentPage - 1);
    props.setTableQueryParams((prev) => ({
      ...prev,
      tableOffset: newOffSet,
      currentPage,
    }));
  };

  const onClickViewMoreTableData = () => {
    let newLimit = props.tableQueryParams.tableLimit;
    if (newLimit === 5) {
      newLimit += 5;
    } else {
      newLimit -= 5;
    }
    props.setTableQueryParams((prev) => ({
      ...prev,
      tableLimit: newLimit,
    }));
  };

  return (
    <>
      <div className="lender-table-container">
        <div className="table-inner-cont">
          <table className={props.className}>
            <thead>
              <tr>
                {props.tableHeader.map((eachHeader) => (
                  <th key={eachHeader.name} scope="col">
                    {eachHeader.name}
                  </th>
                ))}
              </tr>
            </thead>
            {props.materialRequestMetaData?.paging?.total<0?(<div className="no-data text-danger">Oops! there is no data to show. Please check back later</div>):null} 
            {props.isLoading ? (
              <tbody>
                <tr>
                  <td colSpan={10}>
                    <Loader className="my-5" />
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {props.tableRows.map((eachRows) => (
                  <tr scope="col">
                    <td>{eachRows.sellerName}</td>
                    <td>{eachRows.buyerName}</td>
                    <td></td>
                    <td></td>
                    <td>{eachRows.gstn}</td>
                    <td>{eachRows.invoiceId}</td> 
                    <td>{eachRows.date}</td>
                    <td>{eachRows.invoiceAmount}</td>
                    <td>{eachRows.discountedOn}</td>
                    <td>{eachRows.invoiceStatus}</td>
                    <td>{eachRows.paymentId}</td>
                    <td>{eachRows.disbursedAmount}</td>
                    <td>{eachRows.disbursedOn}</td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
      {!props.isLoading && props.disbursedInvoiceMetaData?.paging?.total>5 && (
          <div className="d-flex justify-content-end px-2">
         
              <span
              onClick={onClickViewMoreTableData}
              className="d-flex align-items-center justify-content-start gap-1 my-3 view-more-text"
            >
              {props.tableQueryParams.tableLimit === 5
             ? props.tableQueryParams.tableOffset+props.tableQueryParams.tableLimit<props.materialRequestMetaData?.paging?.total?"View More ":""
             : props.tableQueryParams.tableOffset+props.tableQueryParams.tableLimit<props.materialRequestMetaData?.paging?.total?"View Less ":""}
              <HiOutlineArrowNarrowDown
                className={`fs-5 ${
                  props.tableQueryParams.tableLimit === 5
                  ? props.tableQueryParams.tableOffset+props.tableQueryParams.tableLimit<props.materialRequestMetaData?.paging?.total? "down-arrow":"d-none"
                  : props.tableQueryParams.tableOffset+props.tableQueryParams.tableLimit<props.materialRequestMetaData?.paging?.total? "up-arrow":"d-none"}
                `}
              />
            </span>
          </div>
        )}

      <div className="d-flex align-items-center gap-2 page-data-text-container mt-3 px-3 w-100">
      <TablePagination
        pageSize={props.tableQueryParams.tableLimit}
        onUpdatePage={updatePage}
        current={props.tableQueryParams.currentPage}
        total={props.disbursedInvoiceMetaData?.paging?.total}
      ></TablePagination>
    </div>
    </>
  );
};

export default LenderDisbursedInvoiceTable;
