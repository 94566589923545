import React, { useState, useRef } from "react";
import BannerImg from "../assests/images/rodion-kutsaiev-JKGjV3E-HnY-unsplash@2x.png";
import atonLogo from "../assests/images/Aton-logo.png";
import { useLocation, useNavigate } from "react-router-dom";

const OtpVerification = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [otp, setOtp] = useState({ 0: "", 1: "", 2: "", 3: "" });
  const otpVerificationStatus = useRef(false);

  // const checkLength = (event) => {
  //   const inputSelector = document.querySelector(
  //     `input[name="${event.target.name}"]`
  //   );
  //   if (inputSelector.value.length >= 1) {
  //     inputSelector.value = "";
  //   }
  // };

  const onChangeOtp = (event) => {
    if (otp[parseInt(event.target.name)] === "" || event.target.value === "") {
      setOtp((prev) => ({ ...prev, [event.target.name]: event.target.value }));
    }

    // for auto focus to next input
    if (event.target.name !== "3" && event.target.value !== "") {
      const inputSelector = document.querySelector(
        `input[name="${event.target.name}"]`
      );
      inputSelector.nextSibling.focus();
    }
  };

  const onKeyUpOtpInput = (event) => {
    // for auto focus to previous input after clicking backspace or left arrow
    if (event.key === "Backspace" || event.key === "ArrowLeft") {
      if (event.target.name !== "0") {
        const inputSelector = document.querySelector(
          `input[name="${event.target.name}"]`
        );
        inputSelector.previousSibling.focus();
      }
    }

    // for auto focus to next input after clicking right arrow
    if (event.key === "ArrowRight") {
      if (event.target.name !== "3") {
        const inputSelector = document.querySelector(
          `input[name="${event.target.name}"]`
        );
        inputSelector.nextSibling.focus();
      }
    }
  };

  const onSubmitGetOtp = (event) => {
    event.preventDefault();
    // if getOTP API response is success then the page should be redirected to OTP Verification
    // console.log(otpVerificationStatus);
    // for otp success
    // otpVerificationStatus.current = true;
    setOtp({ 0: "", 1: "", 2: "", 3: "" });
  };

  const onClickBack = () => {
    navigate("/mobile-verification", { replace: true });
  };

  return (
    <>
      <form
        onSubmitCapture={onSubmitGetOtp}
        style={{ position: "relative", width: "100%", height: "100%" }}
      >
        <div className="row m-0 p-0">
          <div className="col-md-2 d-flex jusitfy-content-center ">
            <span
              className="mt-4 mx-4 fw-semibold back-text"
              onClick={onClickBack}
            >
              Back
            </span>
          </div>
          <div className="col-md-8 mt-5">
            <div className="row d-flex align-items-center">
              <div className="col-md-8">
                <span className="txt-welc">
                  {otpVerificationStatus.current
                    ? "Verification"
                    : "OTP Verification"}
                </span>{" "}
                <br />
                <span className="txt-yr-acc">
                  Enter the OTP sent to{" "}
                  <span className="fw-bold" style={{ color: "#191616" }}>
                    {location.state.mobileNumber}
                  </span>
                </span>
              </div>
              <div className="col-md-4 d-flex justify-content-end">
                <img src={atonLogo} style={{ width: "80px" }} />
              </div>
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>

        <div className="row mt-3">
          <div className="col-md-2"></div>
          <div className="col-md-8 mt-5">
            <div className="row px-3 mb-3">
              <label className="label-text">Enter 4 digits OTP</label>
              <div className="d-flex align-items-center justify-content-start gap-2 p-0">
                <input
                  onChange={onChangeOtp}
                  onKeyUp={onKeyUpOtpInput}
                  // onInput={checkLength}
                  autoFocus
                  value={otp[0]}
                  className="d-flex justify-content-center align-items-center input-container form-control px-4 fw-bold"
                  style={{
                    borderRadius: "10px",
                    width: "70px",
                    height: "50px",
                    fontSize: "24px",
                  }}
                  name="0"
                  type="number"
                  maxLength={1}
                  required
                />

                <input
                  onChange={onChangeOtp}
                  onKeyUp={onKeyUpOtpInput}
                  value={otp[1]}
                  className="d-flex justify-content-center align-items-center input-container form-control px-4 fw-bold"
                  style={{
                    borderRadius: "10px",
                    width: "70px",
                    height: "50px",
                    fontSize: "24px",
                  }}
                  name="1"
                  type="number"
                  maxLength={1}
                  required
                />

                <input
                  onChange={onChangeOtp}
                  onKeyUp={onKeyUpOtpInput}
                  value={otp[2]}
                  className="d-flex justify-content-center align-items-center input-container form-control px-4 fw-bold"
                  style={{
                    borderRadius: "10px",
                    width: "70px",
                    height: "50px",
                    fontSize: "24px",
                  }}
                  name="2"
                  type="number"
                  maxLength={1}
                  required
                />

                <input
                  onChange={onChangeOtp}
                  onKeyUp={onKeyUpOtpInput}
                  value={otp[3]}
                  className="d-flex justify-content-center align-items-center input-container form-control px-4 fw-bold"
                  style={{
                    borderRadius: "10px",
                    width: "70px",
                    height: "50px",
                    fontSize: "24px",
                  }}
                  name="3"
                  type="number"
                  maxLength={1}
                  required
                />
              </div>
              <span className="resend-otp-text p-0 mt-3">Resent OTP</span>
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>

        <button type="submit" className="sign-in-btn">
          Verify
        </button>
      </form>
      {/* </div>
        </div>
      </div> */}
    </>
  );
};

export default OtpVerification;
