import React, { Fragment, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { GenericFileInput } from "../../../../GenericComponent/FileInput/FileInput";
import { GenericInputField } from "../../../../GenericComponent/Input/Input";
import { GenericSelect } from "../../../../GenericComponent/Select/Select";
import { updatePANVerification } from "../../../../store/slices/generic";
import { indianStateList } from "../../../../utilities/indianStateList";
import { AnchorKycContext } from "../anchorKycContext";
import { actions } from "./companyDetailsReducer";
import { toastMessage } from "../../../../layout/GenericLayout/ToastMessage";

/**
 * @type { { label: string, value: string, id?: string, disabled?: boolean }[] }
 */
const productCategoryList = Object.freeze([
  { label: "Choose Product Category", value: "", disabled: true, id: "0" },
  { label: "Supply Chain Finance", value: "Supply Chain Finance", id: "1" },
  { label: "Purchase Invoice Discounting", value: "Purchase Invoice Discounting", id: "2" },
  { label: "Vendor Financing", value: "Vendor Financing", id: "3"},

]);

/**
 * @type { { label: string, value: string, id?: string, disabled?: boolean }[] }
 */
const companyTypeList = Object.freeze([
  { label: "Choose Type", value: "", disabled: true, id: "0" },
  { label: "Private Limited", value: "Private Limited", id: "1" },
  { label: "Public Limited", value: "Public Limited", id: "2" },
  {
    label: "Limited Liability Partnership",
    value: "Limited Liability Partnership",
    id: "3",
  },
  { label: "Partnership", value: "Partnership", id: "4" },
  { label: "Proprietorship", value: "Proprietorship", id: "5" },
]);

/**
 * @type { { label: string, value: string, id?: string, disabled?: boolean }[] }
 */
const securityList = [
  { label: "Choose Type", value: "", disabled: true, id: "0" },
  { label: "Enforceable Security", value: "Enforceable Security", id: "1" },
  {
    label: "Corporate guarantee & PG of Directors",
    value: "Corporate guarantee & PG of Directors",
    id: "2",
  },
];

/**
 * @type { { label: string, value: string, id?: string, disabled?: boolean }[] }
 */
const officeTypesList = [
  { label: "Choose Office Type", value: "", disabled: true, id: "0" },
  { label: "Owned", value: "Owned", id: "1" },
  { label: "Rented", value: "Rented", id: "2" },
];

/**
 * @type { { label: string, value: string, id?: string, disabled?: boolean }[] }
 */
const storeTypesList = [
  { label: "Choose Office Type", value: "", disabled: true, id: "0" },
  { label: "Owned", value: "Owned", id: "1" },
  { label: "Rented", value: "Rented", id: "2" },
  { label: "No Store/Plant", value: "No Store/Plant", id: "3" },
];

const statesList = (() => {
  /**
   * @type { { label: string, value: string, id?: string, disabled?: boolean }[] }
   */
  const states = [
    { label: "Select State", value: "", disabled: true, id: "0" },
  ];

  for (let i = 0; i < indianStateList.length; i++) {
    states.push({
      label: indianStateList[i].stateName,
      value: indianStateList[i].stateName,
      id: (i + 1).toString(),
    });
  }
  return states;
})();

/**
 * @type { { label: string, value: string, id?: string, disabled?: boolean }[] }
 */
const regionList = [
  { label: "Choose Region", value: "", disabled: true, id: "0" },
  { label: "East", value: "East", id: "1" },
  { label: "West", value: "West", id: "2" },
  { label: "North", value: "North", id: "3" },
  { label: "South", value: "South", id: "4" },
];

var manufacturer=1;

export const AnchorKYCCompanyDetails = (props) => {
  // ----------------------------------> States
  const [profilePics, setProfilePics] = useState([]);

  const [dataFilled, setDataFilled] = useState(false);

  const dispatch = useDispatch();

  // -----------------------------------> Hooks and Effects

  const {
    files,
    companyDetails,
    companyDetailsDispatch,
    uploadFiles, disableEdit,
    emailId,
  } = useContext(AnchorKycContext);

  console.log("-------",files);

  // SET VALUES THAT ARE RECEIVED FROM API
  useEffect(() => {
    if (!dataFilled && files && Array.isArray(files) && files.length > 0) {
      companyDetailsDispatch({
        type: actions.CHANGE_COUNTRY,
        payload: files[0]?.country ? files[0]?.country : "",
      });
      companyDetailsDispatch({ type: actions.CHANGE_EMAIL, payload: emailId });
      companyDetailsDispatch({
        type: actions.CHANGE_CIN,
        payload: files[0]?.CIN ? files[0]?.CIN : "",
      });
      companyDetailsDispatch({
        type: actions.CHANGE_COMPANY_NAME,
        payload: files[0]?.companyFullName ? files[0]?.companyFullName : "",
      });
      companyDetailsDispatch({
        type: actions.CHANGE_WEBSITE,
        payload: files[0]?.websiteAddress ? files[0]?.websiteAddress : "",
      });
      companyDetailsDispatch({
        type: actions.CHANGE_COMPANY_ADDRESS,
        payload: files[0]?.companyFullAddress ? files[0]?.companyFullAddress : "",
      });
      companyDetailsDispatch({
        type: actions.CHANGE_COMPANY_TYPE,
        payload: files[0]?.companyType ? files[0]?.companyType : "",
      });
      companyDetailsDispatch({
        type: actions.CHANGE_STATE,
        payload: files[0]?.state ? files[0]?.state : "",
      });
      companyDetailsDispatch({
        type: actions.CHANGE_CITY,
        payload: files[0]?.city ? files[0]?.city : "",
      });
      companyDetailsDispatch({
        type: actions.CHANGE_PRODUCT_CATEGORY,
        payload: files[0]?.productCategory ? files[0]?.productCategory : "",
      });
      companyDetailsDispatch({
        type: actions.CHANGE_PIN,
        payload: files[0]?.pincode ? files[0]?.pincode : null,
      });
      companyDetailsDispatch({
        type: actions.CHANGE_REGION,
        payload: files[0]?.region ? files[0]?.region : "",
      });
      companyDetailsDispatch({
        type: actions.CHANGE_OFFICE_TYPE,
        payload: files[0]?.typeOfOffices ? files[0]?.typeOfOffices : "",
      });
      companyDetailsDispatch({
        type: actions.CHANGE_STORE_TYPE,
        payload: files[0]?.typeOfShowroom ? files[0]?.typeOfShowroom : "",
      });
      companyDetailsDispatch({
        type: actions.CHANGE_SECURITY,
        payload: files[0]?.security ? files[0]?.security : "",
      });
      // noOfOffices noOfEMPShowroom noOfEMPWarehouse  phoneNumber
      companyDetailsDispatch({
        type: actions.CHANGE_NO_OFFICE,
        payload: files[0]?.noOfOffices ? files[0]?.noOfOffices : "",
      });
      companyDetailsDispatch({
        type: actions.CHANGE_EMP_STORE,
        payload: files[0]?.noOfEMPShowroom ? files[0]?.noOfEMPShowroom : "",
      });
      companyDetailsDispatch({
        type: actions.CHANGE_EMP_WAREHOUSE,
        payload: files[0]?.noOfEMPWarehouse ? files[0]?.noOfEMPWarehouse : "",
      });
      companyDetailsDispatch({
        type: actions.CHANGE_TELEPHONE,
        payload: files[0]?.phoneNumber ? files[0]?.phoneNumber : null,
      });

      // PAN added
      companyDetailsDispatch({
        type: actions.CHANGE_PAN,
        payload: files[0]?.companyPAN ? files[0]?.companyPAN : "",
      });
      companyDetailsDispatch({
        type: actions.BLUR_PAN,
        payload: files[0]?.companyPAN,
      });

      setDataFilled(true);
    }
  }, [companyDetailsDispatch, emailId, files, dataFilled]);

  useEffect(() => {
    if (
      files?.length > 0 &&
      files?.[0]?.documents &&
      Array.isArray(files[0].documents)
    ) {
      const profilePicsTemp = [];
      for (let item of files[0].documents) {
        if (item?.profilePicture !== undefined) {
          profilePicsTemp.push(item);
        }
      }

      if (profilePicsTemp.length > 0) {
        companyDetailsDispatch({
          type: actions.LOGO_UPDATE_API,
          payload: profilePicsTemp,
        });
      }

      setProfilePics(profilePicsTemp);
    }
  }, [files, companyDetailsDispatch]);

  // ----------------------------------> Handlers and functions

  /**
   * @param { React.ChangeEvent<HTMLInputEvent> } event
   */
  const handleLogoChange = (event) => {
    const formData = new FormData();

    formData.append("profilePicture", event.target.files[0]);

    uploadFiles(formData, "profilePicture");
  };

  const handlePanBlur = () => {
    if (companyDetails.panNumber.value.length === 10) {
      const body = {
        panNumber: companyDetails.panNumber.value,
        userid: emailId,
      };
      dispatch(updatePANVerification(body))
        .unwrap()
        .then((response) => {
          if (response?.status) {
            companyDetailsDispatch({ type: actions.BLUR_PAN, payload: true });
          }
        })
        .catch((error) => {
          companyDetailsDispatch({ type: actions.BLUR_PAN, payload: false });
          toastMessage(error?.message, "error");
        });
    } else {
      companyDetailsDispatch({ type: actions.BLUR_PAN, payload: false });
    }
  };

  return (
    <Fragment>
      <div className="container-with-heading d-flex flex-column container">
        <span className="kyc-content-title mb-3 row">Company Information</span>
        <div className="details-container ">
          <div className="row gy-md-4 gy-2">
            <GenericFileInput
              required="true"
              fileType="profilePicture"
              label="Logo"
              isVerified={companyDetails.logo.isVerified}
              fileList={profilePics}
              onChangeHandler={handleLogoChange}
              isInvalid={companyDetails.logo.isTouched && profilePics?.length === 0}
              disabled={disableEdit}
            />

            <GenericSelect
              label="Product Category"
              value={companyDetails.productCategory.value}
              isInvalid={
                companyDetails.productCategory.isTouched &&
                !companyDetails.productCategory.isValid
              }
              optionList={productCategoryList}
              required={true}
              errorMessage={
                companyDetails.productCategory.value === ""
                  ? "Product Category is Required"
                  : "Choose Product Category"
              }
              onChangeHandler={(event) =>
                companyDetailsDispatch({
                  type: actions.CHANGE_PRODUCT_CATEGORY,
                  payload: event.target.value,
                })
              }
              onBlurHandler={() =>
                companyDetailsDispatch({ type: actions.BLUR_PRODUCT_CATEGORY })
              }
              disabled={disableEdit}
            />
            {console.log('manuf ', companyDetails)}
            
            {
              companyDetails.productCategory.value === 'Vendor Financing' &&(
                <div className="kyc-fields-container col-12 col-md-4 pt-1">
              {Array.from({ length: companyDetails.manufacturer.length }).map((_, i) => (
                (i === 0 || companyDetails.manufacturer[i - 1] !== '') && (
                  <GenericInputField
                    key={i} 
                    label="Manufacturer"
                    required={false}
                    value={companyDetails?.manufacturer[i]}
                    onChangeHandler={(event) =>
                      companyDetailsDispatch({
                        type: actions.CHANGE_MANUFACTURER,
                        payload: { value: event.target.value, index: i }
                      })
                    }
                  />
                )
              ))}
              </div>
              )
            }
           
            <GenericInputField
              label="Company Name"
              required={true}
              value={companyDetails.companyName.value}
              errorMessage={
                companyDetails.companyName.value === ""
                  ? "Company Name is Required"
                  : "Name should contain at least 4 chars"
              }
              isInvalid={
                companyDetails.companyName.isTouched &&
                !companyDetails.companyName.isValid
              }
              onChangeHandler={(event) =>
                companyDetailsDispatch({
                  type: actions.CHANGE_COMPANY_NAME,
                  payload: event.target.value,
                })
              }
              onBlurHandler={() =>
                companyDetailsDispatch({ type: actions.BLUR_COMPANY_NAME })
              }
              disabled={disableEdit}
            />

            <GenericSelect
              optionList={companyTypeList}
              required={true}
              value={companyDetails.companyType.value}
              errorMessage={
                companyDetails.companyType.value === ""
                  ? "Company Type is Required"
                  : "Invalid Company Type"
              }
              label="Company Type"
              onChangeHandler={(event) =>{                
               
                if(event.target.value!=='Private Limited'||event.target.value!=='Public Limited'){
                  companyDetailsDispatch({
                    type: actions.CONFIG_CIN,
                    payload: true,
                  })
                }
                // else if(
                //   (companyDetails.companyType.value === 'Private Limited' || companyDetails.companyType.value === 'Public Limited') && 
                //   companyDetails.companyType.value !== event.target.value){
                //     companyDetailsDispatch({
                //       type: actions.CONFIG_CIN,
                //       payload: false,
                //     })
                // }
                companyDetailsDispatch({
                  type: actions.CHANGE_COMPANY_TYPE,
                  payload: event.target.value,
                })
              }}
              onBlurHandler={() =>
                companyDetailsDispatch({ type: actions.BLUR_COMPANY_TYPE })
              }
              isInvalid={
                companyDetails.companyType.isTouched &&
                !companyDetails.companyType.isValid
              }
              disabled={disableEdit}
            />

            {
              (companyDetails.companyType.value==='Private Limited'||companyDetails.companyType.value==='Public Limited') &&
              <GenericInputField
                label="CIN Number"
                required={true}
                value={companyDetails.cinNumber.value}
                errorMessage={
                  companyDetails.cinNumber.value === ""
                    ? "CIN Number is Required"
                    : "Invalid CIN Number"
                }
                isInvalid={
                  companyDetails.cinNumber.isTouched &&
                  !companyDetails.cinNumber.isValid
                }
                onChangeHandler={(event) =>
                  companyDetailsDispatch({
                    type: actions.CHANGE_CIN,
                    payload: event.target.value.toUpperCase(),
                  })
                }
                onBlurHandler={() =>
                  companyDetailsDispatch({ type: actions.BLUR_CIN })
                }
                disabled={disableEdit}
              />
            }

            <GenericInputField
              label="Website Address"
              required={true}
              value={companyDetails.websiteAddress.value}
              errorMessage={
                companyDetails.websiteAddress.value === ""
                  ? "Company Website is Required"
                  : "Invalid Website Address"
              }
              isInvalid={
                companyDetails.websiteAddress.isTouched &&
                !companyDetails.websiteAddress.isValid
              }
              onChangeHandler={(event) =>
                companyDetailsDispatch({
                  type: actions.CHANGE_WEBSITE,
                  payload: event.target.value,
                })
              }
              onBlurHandler={() =>
                companyDetailsDispatch({ type: actions.BLUR_WEBSITE })
              }
              disabled={disableEdit}
            />

            <GenericInputField
              label="Email"
              disabled={true}
              required={true}
              value={emailId}
            />

            <GenericInputField
              label="PAN Number"
              required={true}
              value={companyDetails.panNumber.value}
              errorMessage={
                companyDetails.panNumber.value === "" ?
                "PAN Number is required"
                : "Invalid PAN Number"
              }
              isInvalid={
                (companyDetails.panNumber.isTouched &&
                !companyDetails.panNumber.isValid)||
                (companyDetails.panNumber.isTouched &&
                  !companyDetails.panNumber.isVerified)
              }
              onChangeHandler={(event) =>
                companyDetailsDispatch({
                  type: actions.CHANGE_PAN,
                  payload: event.target.value.toUpperCase(),
                })
              }
              onBlurHandler={() => handlePanBlur()}
              disabled={disableEdit}
              verified={companyDetails.panNumber.isVerified}
              successMessage="PAN Verified"
            />

            <GenericSelect
              optionList={securityList}
              required={true}
              value={companyDetails.security.value}
              errorMessage={
                companyDetails.security.value === "" &&
                "Security Details is Required"
              }
              label="Security"
              isInvalid={
                companyDetails.security.isTouched &&
                !companyDetails.security.isValid
              }
              onChangeHandler={(event) =>
                companyDetailsDispatch({
                  type: actions.CHANGE_SECURITY,
                  payload: event.target.value,
                })
              }
              onBlurHandler={() =>
                companyDetailsDispatch({ type: actions.BLUR_SECURITY })
              }
              disabled={disableEdit}
            />

            <GenericInputField
              label="Company Address"
              required={true}
              isInvalid={
                companyDetails.companyAddress.isTouched &&
                !companyDetails.companyAddress.isValid
              }
              errorMessage={
                companyDetails.companyAddress.value === ""
                  ? "Company Address is Required"
                  : "Address should contain at least 5 chars"
              }
              value={companyDetails.companyAddress.value}
              onChangeHandler={(event) =>
                companyDetailsDispatch({
                  type: actions.CHANGE_COMPANY_ADDRESS,
                  payload: event.target.value,
                })
              }
              onBlurHandler={() =>
                companyDetailsDispatch({ type: actions.BLUR_COMPANY_ADDRESS })
              }
              disabled={disableEdit}
            />

            <GenericInputField
              label="City"
              required={true}
              isInvalid={
                companyDetails.city.isTouched && !companyDetails.city.isValid
              }
              errorMessage={
                companyDetails.city.value === ""
                  ? "City is Required"
                  : "Invalid City Name"
              }
              value={companyDetails.city.value}
              onChangeHandler={(event) =>
                companyDetailsDispatch({
                  type: actions.CHANGE_CITY,
                  payload: event.target.value,
                })
              }
              onBlurHandler={() =>
                companyDetailsDispatch({ type: actions.BLUR_CITY })
              }
              disabled={disableEdit}
            />

            <GenericSelect
              optionList={statesList}
              required={true}
              isInvalid={
                companyDetails.state.isTouched && !companyDetails.state.isValid
              }
              value={companyDetails.state.value}
              errorMessage="State is Required"
              onChangeHandler={(event) =>
                companyDetailsDispatch({
                  type: actions.CHANGE_STATE,
                  payload: event.target.value,
                })
              }
              label="State"
              onBlurHandler={() =>
                companyDetailsDispatch({ type: actions.BLUR_STATE })
              }
              disabled={disableEdit}
            />

            <GenericInputField
              label="Pin Code"
              required={true}
              type="number"
              isInvalid={
                companyDetails.pinCode.isTouched &&
                !companyDetails.pinCode.isValid
              }
              errorMessage={
                companyDetails.pinCode.value === ""
                  ? "PinCode is Required"
                  : "Invalid PinCode"
              }
              onChangeHandler={(event) =>
                companyDetailsDispatch({
                  type: actions.CHANGE_PIN,
                  payload: event.target.value,
                })
              }
              value={companyDetails?.pinCode?.value}
              onBlurHandler={() =>
                companyDetailsDispatch({ type: actions.BLUR_PIN })
              }
              disabled={disableEdit}
            />

            <GenericSelect
              optionList={regionList}
              required={true}
              value={companyDetails.region.value}
              onChangeHandler={(event) =>
                companyDetailsDispatch({
                  type: actions.CHANGE_REGION,
                  payload: event.target.value,
                })
              }
              label="Region"
              errorMessage="Region is Required"
              isInvalid={
                companyDetails.region.isTouched &&
                !companyDetails.region.isValid
              }
              onBlurHandler={() =>
                companyDetailsDispatch({ type: actions.BLUR_REGION })
              }
              disabled={disableEdit}
            />

            <GenericInputField
              label="Country"
              required={true}
              disabled={disableEdit}
              value={companyDetails.country.value}
              isInvalid={
                companyDetails.country.isTouched &&
                !companyDetails.country.isValid
              }
              onChangeHandler={event => companyDetailsDispatch({ type: actions.CHANGE_COUNTRY, payload: event.target.value, })}
            />

            <GenericSelect
              optionList={officeTypesList}
              required={true}
              value={companyDetails.typeOfOffices.value}
              errorMessage={
                companyDetails.typeOfOffices.isTouched &&
                !companyDetails.typeOfOffices.isValid &&
                "Office Type is Required"
              }
              label="Type of Office"
              onChangeHandler={(event) =>
                companyDetailsDispatch({
                  type: actions.CHANGE_OFFICE_TYPE,
                  payload: event.target.value,
                })
              }
              onBlurHandler={() =>
                companyDetailsDispatch({ type: actions.BLUR_OFFICE_TYPE })
              }
              isInvalid={
                companyDetails.typeOfOffices.isTouched &&
                !companyDetails.typeOfOffices.isValid
              }
              disabled={disableEdit}
            />

            <GenericInputField
              label="No. of Offices"
              required={true}
              type="number"
              value={companyDetails.noOfOffices.value}
              errorMessage={
                companyDetails.noOfOffices.isTouched &&
                  companyDetails.noOfOffices.value === ""
                  ? "No Of Offices is Required"
                  : companyDetails.noOfOffices.isTouched &&
                    !companyDetails.noOfOffices.isValid
                    ? "Number should be at least 1"
                    : ""
              }
              onChangeHandler={(event) =>
                companyDetailsDispatch({
                  type: actions.CHANGE_NO_OFFICE,
                  payload: event.target.value,
                })
              }
              onBlurHandler={() =>
                companyDetailsDispatch({ type: actions.BLUR_NO_OFFICES })
              }
              isInvalid={
                companyDetails.noOfOffices.isTouched &&
                !companyDetails.noOfOffices.isValid
              }
              disabled={disableEdit}
            />

            <GenericSelect
              optionList={storeTypesList}
              required={true}
              value={companyDetails.typeOfStoresAndPlant.value}
              errorMessage="Type of Stores is Required"
              label="Type of Store/Plant"
              onChangeHandler={(event) =>
                companyDetailsDispatch({
                  type: actions.CHANGE_STORE_TYPE,
                  payload: event.target.value,
                })
              }
              onBlurHandler={() =>
                companyDetailsDispatch({ type: actions.BLUR_STORE_TYPE })
              }
              isInvalid={
                companyDetails.typeOfStoresAndPlant.isTouched &&
                !companyDetails.typeOfStoresAndPlant.isValid
              }
              disabled={disableEdit}
            />

            <GenericInputField
              label="No. of Employees in Store/Plant"
              required={true}
              type="number"
              value={companyDetails.noOfEmployeesInStore.value}
              errorMessage={
                companyDetails.noOfEmployeesInStore.isTouched &&
                  companyDetails.noOfEmployeesInStore.value === ""
                  ? "No Of Employees in Store is Required"
                  : companyDetails.noOfEmployeesInStore.isTouched &&
                    !companyDetails.noOfEmployeesInStore.isValid
                    ? "Number should be at least 1"
                    : ""
              }
              onChangeHandler={(event) =>
                companyDetailsDispatch({
                  type: actions.CHANGE_EMP_STORE,
                  payload: event.target.value,
                })
              }
              onBlurHandler={() =>
                companyDetailsDispatch({ type: actions.BLUR_EMP_STORE })
              }
              isInvalid={
                companyDetails.noOfEmployeesInStore.isTouched &&
                !companyDetails.noOfEmployeesInStore.isValid
              }
              disabled={disableEdit}
            />

            <GenericInputField
              label="No. of Warehouses"
              required={true}
              type="number"
              value={companyDetails.noOfWarehouses.value}
              errorMessage={
                companyDetails.noOfWarehouses.isTouched &&
                  companyDetails.noOfWarehouses.value === ""
                  ? "No Of Warehouses is Required"
                  : companyDetails.noOfWarehouses.isTouched &&
                    !companyDetails.noOfWarehouses.isValid
                    ? "Number should be at least 1"
                    : ""
              }
              onChangeHandler={(event) =>
                companyDetailsDispatch({
                  type: actions.CHANGE_NO_WAREHOUSE,
                  payload: event.target.value,
                })
              }
              onBlurHandler={() =>
                companyDetailsDispatch({ type: actions.BLUR_NO_WAREHOUSE })
              }
              isInvalid={
                companyDetails.noOfWarehouses.isTouched &&
                !companyDetails.noOfWarehouses.isValid
              }
              disabled={disableEdit}
            />

            <GenericInputField
              label="Telephone Number"
              required={true}
              type="number"
              value={companyDetails.telephoneNumber.value}
              errorMessage={
                companyDetails.telephoneNumber.isTouched &&
                  companyDetails.telephoneNumber.value === ""
                  ? "Telephone Number is Required"
                  : "Invalid Telephone Number"
              }
              onChangeHandler={(event) =>
                companyDetailsDispatch({
                  type: actions.CHANGE_TELEPHONE,
                  payload: event.target.value,
                })
              }
              onBlurHandler={() =>
                companyDetailsDispatch({ type: actions.BLUR_TELEPHONE })
              }
              isInvalid={
                companyDetails.telephoneNumber.isTouched &&
                !companyDetails.telephoneNumber.isValid
              }
              disabled={disableEdit}
            />

            <GenericInputField
              label="Mobile Number"
              required={true}
              type="number"
              errorMessage={
                companyDetails.mobileNumber.isTouched &&
                  companyDetails.mobileNumber.value === ""
                  ? "Mobile Number is Required"
                  : "Invalid Mobile Number"
              }
              onChangeHandler={(event) =>
                companyDetailsDispatch({
                  type: actions.CHANGE_MOBILE,
                  payload: event.target.value,
                })
              }
              value={companyDetails.mobileNumber.value}
              onBlurHandler={() =>
                companyDetailsDispatch({ type: actions.BLUR_MOBILE })
              }
              isInvalid={
                companyDetails.mobileNumber.isTouched &&
                !companyDetails.mobileNumber.isValid
              }
              disabled={disableEdit}
            />

            <GenericInputField
              label="No. of Employees In Warehouse"
              required={true}
              type="number"
              value={companyDetails.noOfEmployeesInWarehouse.value}
              errorMessage={
                companyDetails.noOfEmployeesInWarehouse.isTouched &&
                  companyDetails.noOfEmployeesInWarehouse.value === ""
                  ? "No Of Employees in Warehouse is Required"
                  : companyDetails.noOfEmployeesInWarehouse.isTouched &&
                    !companyDetails.noOfEmployeesInWarehouse.isValid
                    ? "Number should be at least 1"
                    : ""
              }
              onChangeHandler={(event) =>
                companyDetailsDispatch({
                  type: actions.CHANGE_EMP_WAREHOUSE,
                  payload: event.target.value,
                })
              }
              onBlurHandler={() =>
                companyDetailsDispatch({ type: actions.BLUR_EMP_WAREHOUSE })
              }
              isInvalid={
                companyDetails.noOfEmployeesInWarehouse.isTouched &&
                !companyDetails.noOfEmployeesInWarehouse.isValid
              }
              disabled={disableEdit}
            />

            <div className="kyc-fields-container col-12  col-md-8">
              <label
                htmlFor="aboutCompany"
                className="kyc-label-text form-label"
              >
                Details about the Store / Promoters
                <span className="text-danger fs-5">*</span>
              </label>
              <textarea
                onChange={(event) =>
                  companyDetailsDispatch({
                    type: actions.CHANGE_STORE_DETAILS,
                    payload: event.target.value,
                  })
                }
                id="aboutCompany"
                type="text"
                name="DetailAboutCompany"
                onBlur={() =>
                  companyDetailsDispatch({ type: actions.BLUR_STORE_DETAILS })
                }
                className={`kyc-input-field form-control ${companyDetails.storeDetails.isTouched &&
                    !companyDetails.storeDetails.isValid
                    ? "invalid-input"
                    : ""
                  }`}
                value={companyDetails.storeDetails.value}
                disabled={disableEdit}
              ></textarea>
              <span className="text-danger px-1">
                {companyDetails.storeDetails.isTouched &&
                  companyDetails.storeDetails.value === ""
                  ? "Store Details is Required"
                  : companyDetails.storeDetails.isTouched &&
                    !companyDetails.storeDetails.isValid
                    ? "Store Details should contain more than 5 chars"
                    : ""}
              </span>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
