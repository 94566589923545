import { Fragment, useContext, useEffect, useState } from "react";
import { GenericCamTableComponent } from "../../../../GenericComponent/Table/tableComponent";
import { useSelector } from "react-redux";
import { AdminCamContext } from "../../../../context/admin/cam/adminCamContext";
import { AdminKycActions } from "../../../../context/admin/cam/actionTypes";
const template = () => ({
	corrospondence_address: "",
	emailId: "",
	no_of_emp_showroom: 0,
	no_of_emp_warehouse: 0,
	no_of_offices: 0,
	no_of_warehouse: 0,
	owned_rented: "",
	pan: "",
	registered_address: "",
	residentialSpace: "",
	telephone_number: "",
	date_of_commencement_of_business: "",
	date_of_incorporation: ""
});

/**
 * @type { { value: string, rowspan?: number, colspan?: number }[][] }
 */

export const AdminCamApplicantDetails = () => {
	const [applicantDetails, setApplicantDetails] = useState(template());
	const [newApplicantDetails, setNewApplicantDetails] = useState(template());

	const { editEnabled,applicantDetailsStateDispatch } = useContext(AdminCamContext);

	const [reduxData, setReduxdata] = useState({});
	const [viewProPic, setProPic] = useState(false);

	const applicantReduxState = useSelector(state => state?.admin?.camByEmailIdData?.data?.data?.applicantDetails);
	const applicantReduxState2 = useSelector(state => state?.admin?.camByEmailIdData?.data?.data?.profilePicture);


	useEffect(() => {
		if (reduxData != applicantReduxState) {
			setApplicantDetails({
				...applicantDetails,
				corrospondence_address: applicantReduxState?.corrospondence_address,
				emailId: applicantReduxState?.emailId,
				no_of_emp_showroom: applicantReduxState?.no_of_emp_showroom,
				no_of_emp_warehouse: applicantReduxState?.no_of_emp_warehouse,
				no_of_offices: applicantReduxState?.no_of_offices,
				no_of_warehouse: applicantReduxState?.no_of_warehouse,
				owned_rented: applicantReduxState?.owned_rented,
				pan: applicantReduxState?.pan,
				registered_address: applicantReduxState?.registered_address,
				residentialSpace: applicantReduxState?.residentialSpace,
				telephone_number: applicantReduxState?.telephone_number,
				date_of_commencement_of_business: applicantReduxState?.date_of_commencement_of_business,
				date_of_incorporation: applicantReduxState?.date_of_incorporation,
				profilePicture: applicantReduxState2
			});

			setNewApplicantDetails({
				...applicantDetails,
				corrospondence_address: applicantReduxState?.corrospondence_address,
				emailId: applicantReduxState?.emailId,
				no_of_emp_showroom: applicantReduxState?.no_of_emp_showroom,
				no_of_emp_warehouse: applicantReduxState?.no_of_emp_warehouse,
				no_of_offices: applicantReduxState?.no_of_offices,
				no_of_warehouse: applicantReduxState?.no_of_warehouse,
				owned_rented: applicantReduxState?.owned_rented,
				pan: applicantReduxState?.pan,
				registered_address: applicantReduxState?.registered_address,
				residentialSpace: applicantReduxState?.residentialSpace,
				telephone_number: applicantReduxState?.telephone_number,
				date_of_commencement_of_business: applicantReduxState?.date_of_commencement_of_business,
				date_of_incorporation: applicantReduxState?.date_of_incorporation,
				profilePicture: applicantReduxState2
			});

			setReduxdata(applicantReduxState);
		}
	}, [applicantReduxState]);


	function handleApplicantDetailsChange(e) {
		setNewApplicantDetails(prev => ({
			...prev,
			[e.target.name]: e.target.value,
		}));
	}

	useEffect(() => {
		applicantDetailsStateDispatch({type: AdminKycActions.APPLICANT_DETAILS.UPDATE_DETAILS, payload: newApplicantDetails});
	}, [newApplicantDetails]);


	return (
		<Fragment>
			<div className="row m-0">
				<div className="col-md-6">
					<GenericCamTableComponent tableHeaders={[[{ value: "Registered Details", colspan: 2 }]]} height="500px" bg="#e9ecef">
						<tr>
							<td style={{ width: '50%' }}>Profile Picture</td>
							<td>
								{viewProPic == false && <button className="btn btn-success" onClick={() => setProPic(true)}>View</button>}
								{viewProPic && <button className="btn btn-secondary" onClick={() => setProPic(false)}>Close</button>}
								{viewProPic && <img src={applicantDetails.profilePicture} style={{ width: '100%' }} />}
							</td>
						</tr>
						<tr>
							<td>Registered Address</td>
							{
								editEnabled ?
									<td>
										<input className="form-control" type="text" name="registered_address" onChange={handleApplicantDetailsChange} value={newApplicantDetails.registered_address} />
									</td>
									:
									<td>{newApplicantDetails.registered_address}</td>
							}
						</tr>
						<tr>
							<td>Telephone No</td>
							<td>{newApplicantDetails.telephone_number}</td>
						</tr>
						<tr>
							<td>Date Of Incorporation/ Date Of Partnership</td>
							{
								editEnabled ?
									<td>
										<input className="form-control" type="date" name="date_of_incorporation" onChange={handleApplicantDetailsChange} value={newApplicantDetails.date_of_incorporation} />
									</td>
									:
									<td>{newApplicantDetails.date_of_incorporation}</td>
							}
						</tr>
						<tr>
							<td>PAN No</td>
							<td>{newApplicantDetails.pan}</td>
						</tr>
						<tr>
							<td>No. Of Offices</td>
							{
								editEnabled ?
									<td>
										<input className="form-control" type="number" min={0} name="no_of_offices" onChange={handleApplicantDetailsChange} value={newApplicantDetails.no_of_offices} />
									</td>
									:
									<td>{newApplicantDetails.no_of_offices}</td>
							}
						</tr>
						<tr>
							<td>No. Of Warehouse</td>
							{
								editEnabled ?
									<td>
										<input className="form-control" type="number" min={0} name="no_of_warehouse" onChange={handleApplicantDetailsChange} value={newApplicantDetails.no_of_warehouse} />
									</td>
									:
									<td>{newApplicantDetails.no_of_warehouse}</td>
							}
						</tr>
						<tr>
							<td>No. Of Emp – Showroom</td>
							{
								editEnabled ?
									<td>
										<input className="form-control" type="number" min={0} name="no_of_emp_showroom" onChange={handleApplicantDetailsChange} value={newApplicantDetails.no_of_emp_showroom} />
									</td>
									:
									<td>{newApplicantDetails.no_of_emp_showroom}</td>
							}
						</tr>
					</GenericCamTableComponent>
				</div>
				<div className="col-md-6">
					<GenericCamTableComponent tableHeaders={[[{ value: "Correspondence Details", colspan: 2 }]]} height="500px">
						<tr>
							<td style={{ width: '50%' }}>Correspondence Address</td>
							{
								editEnabled ?
									<td>
										<input className="form-control" type="text" name="corrospondence_address" onChange={handleApplicantDetailsChange} value={newApplicantDetails.corrospondence_address} />
									</td>
									:
									<td>{newApplicantDetails.corrospondence_address}</td>
							}
						</tr>
						<tr>
							<td>Email ID</td>
							<td>{newApplicantDetails.emailId}</td>
						</tr>
						<tr>
							<td>Date Of Commencement Of Business</td>
							{
								editEnabled ?
									<td>
										<input className="form-control" type="date" name="date_of_commencement_of_business" onChange={handleApplicantDetailsChange} value={newApplicantDetails.date_of_commencement_of_business} />
									</td>
									:
									<td>
										{newApplicantDetails.date_of_commencement_of_business}
									</td>
							}
						</tr>
						<tr>
							<td>Commercial Space (Owned/Rented)</td>
							{
								editEnabled ?
									<td>
										<input className="form-control" type="text" name="owned_rented" onChange={handleApplicantDetailsChange} value={newApplicantDetails.owned_rented} />
									</td>
									:
									<td>
										{newApplicantDetails.owned_rented}
									</td>
							}
						</tr>
						<tr>
							<td>Residential Space (Owned/Rented)</td>
							{
								editEnabled ?
									<td>
										<input className="form-control" type="text" name="residentialSpace" onChange={handleApplicantDetailsChange} value={newApplicantDetails.residentialSpace} />
									</td>
									:
									<td>{newApplicantDetails.residentialSpace}</td>
							}
						</tr>
						<tr>
							<td>No. Of Emp - Warehouse</td>
							{
								editEnabled ?
									<td>
										<input className="form-control" type="number" min={0} name="no_of_emp_warehouse" onChange={handleApplicantDetailsChange} value={newApplicantDetails.no_of_emp_warehouse} />
									</td>
									:
									<td>{newApplicantDetails.no_of_emp_warehouse}</td>
							}
						</tr>
					</GenericCamTableComponent>
				</div>
			</div>
		</Fragment>
	);
};