import React from "react";
import { BsSearch } from "react-icons/bs";
import AdminCard from "../Admin/AdminCard";
import { useEffect, useState } from "react";
import AdminDashboardTable from "../Lender/LenderTables/LenderBillLogdingTable";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getDisbursedData } from "../../store/slices/lender.js";
import Loader from "../../layout/GenericLayout/Loader";
import PaymentPage from "../../Pages/PaymentPage";
import { toastMessage } from "../../layout/GenericLayout/ToastMessage";
import {Helmet} from "react-helmet";

const LenderDisbursed = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [customerBillData, setcustomerBillData] = useState("");
  console.log(customerBillData);

  const [search, setSearch] = useState("");
  const [discountData, setDiscount] = useState(true);

  const onChangeSearch = (e) => {
    setSearch(e.target.value);
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleSearch = () => {
    dispatch(getDisbursedData(search))
      .unwrap()
      .then((response) => {
        console.log(response);
        if (response?.items[0]?.invoiceStatus !== "Lodgement") {
          toastMessage("Invoice liquidation not initiated", "error");
        }
        setcustomerBillData(response.items[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDiscount = () => {
    setDiscount(!discountData);
  };

  console.log(customerBillData);
  return (
    <div
      className="d-flex flex-column gap-4"
      style={{
        backgroundColor: "white",
        padding: "2rem",
        borderRadius: "10px",
      }}
    >
      <Helmet>
                <title>Liquidation</title>
            </Helmet>
      <div className="d-flex justify-content-between flex-column px-2 gap-2">
        <span className="dashboard-content-title">Liquidation</span>
        <label
          style={{
            width: "100%",
            marginTop: "2rem",
            fontWeight: "bold",
            fontSize: "18px",
          }}
        >
          Enter Bill Reference Number
        </label>
        <div className="search-container d-flex align-items-center py-1 px-3 col-12 col-sm-4 ">
          <input
            className="search-input-element d-flex flex-grow-1"
            type="text"
            placeholder="Search"
            onChange={onChangeSearch}
            onKeyPress={onChangeSearch}
            value={search}
          />{" "}
          <BsSearch className="fs-5" onClick={handleSearch} />
        </div>

        {customerBillData !== "" && (
          <div className="details-container container p-3">
            <div className="row gy-md-4 gy-2">
              <div className="kyc-fields-container col-12  col-md-4">
                <label
                  htmlFor="billing"
                  className="kyc-label-text form-label fw-semibold"
                >
                  CIF
                </label>
                <input
                  disabled
                  id="billing"
                  type="text"
                  name="billing"
                  value={customerBillData?.billReferenceNo}
                  className="kyc-input-field form-control"
                />
              </div>

              <div className="kyc-fields-container col-12  col-md-4">
                <label
                  htmlFor="shipping"
                  className="kyc-label-text form-label fw-semibold"
                >
                  Anchor
                </label>
                <input
                  id="shipping"
                  disabled
                  type="text"
                  name="shipping"
                  className="kyc-input-field form-control"
                  value={customerBillData?.invoiceId}
                />
              </div>
              <div className="kyc-fields-container col-12  col-md-4">
                <label
                  htmlFor="shipping"
                  className="kyc-label-text form-label fw-semibold"
                >
                  Other Party
                </label>
                <input
                  id="shipping"
                  disabled
                  type="text"
                  name="shipping"
                  className="kyc-input-field form-control"
                  value={customerBillData?.invoiceDate}
                />
              </div>
            </div>
            <div className="row gy-md-4 mt-2 gy-2">
              <div className="kyc-fields-container col-12  col-md-4">
                <label
                  htmlFor="billing"
                  className="kyc-label-text form-label fw-semibold"
                >
                  Bill Type
                </label>
                <input
                  disabled
                  id="billing"
                  type="text"
                  name="billing"
                  className="kyc-input-field form-control"
                  value={customerBillData?.billType}
                />
              </div>

              <div className="kyc-fields-container col-12  col-md-4">
                <label
                  htmlFor="shipping"
                  className="kyc-label-text form-label fw-semibold"
                >
                  Currency
                </label>
                <input
                  id="shipping"
                  disabled
                  type="text"
                  name="shipping"
                  className="kyc-input-field form-control"
                  value={customerBillData?.lodgementDate}
                />
              </div>
              <div className="kyc-fields-container col-12  col-md-4">
                <label
                  htmlFor="shipping"
                  className="kyc-label-text form-label fw-semibold"
                >
                  Invoice Amount
                </label>
                <input
                  id="shipping"
                  disabled
                  type="text"
                  name="shipping"
                  className="kyc-input-field form-control"
                  value={customerBillData?.dueDate}
                />
              </div>
            </div>
            <div className="row gy-md-4 mt-2 gy-2">
              <div className="kyc-fields-container col-12  col-md-4">
                <label
                  htmlFor="billing"
                  className="kyc-label-text form-label fw-semibold"
                >
                  Margin
                </label>
                <input
                  disabled
                  id="billing"
                  type="text"
                  name="billing"
                  className="kyc-input-field form-control"
                  value={customerBillData?.fees}
                />
              </div>

              <div className="kyc-fields-container col-12  col-md-4">
                <label
                  htmlFor="shipping"
                  className="kyc-label-text form-label fw-semibold"
                >
                  Amount Financed (₹)
                </label>
                <input
                  id="shipping"
                  disabled
                  type="text"
                  name="shipping"
                  className="kyc-input-field form-control"
                  value={customerBillData?.interestAmount}
                />
              </div>
              <div className="kyc-fields-container col-12  col-md-4">
                <label
                  htmlFor="shipping"
                  className="kyc-label-text form-label fw-semibold"
                >
                  Interest Rate (%)
                </label>
                <input
                  id="shipping"
                  disabled
                  type="text"
                  name="shipping"
                  className="kyc-input-field form-control"
                  value={customerBillData?.interestRate}
                />
              </div>
            </div>
            <div className="row gy-md-4 mt-2 gy-2">
              <div className="kyc-fields-container col-12  col-md-4">
                <label
                  htmlFor="billing"
                  className="kyc-label-text form-label fw-semibold"
                >
                  Tenor(Days)
                </label>
                <input
                  disabled
                  id="billing"
                  type="text"
                  name="billing"
                  className="kyc-input-field form-control"
                  value={customerBillData?.amountFinanced}
                />
              </div>

              <div className="kyc-fields-container col-12  col-md-4">
                <label
                  htmlFor="shipping"
                  className="kyc-label-text form-label fw-semibold"
                >
                  Interest Amount (₹)
                </label>
                <input
                  id="shipping"
                  disabled
                  type="text"
                  name="shipping"
                  className="kyc-input-field form-control"
                  value={customerBillData?.tenure}
                />
              </div>
              <div className="kyc-fields-container col-12  col-md-4">
                <label
                  htmlFor="shipping"
                  className="kyc-label-text form-label fw-semibold"
                >
                  Processing Fees (%)
                </label>
                <input
                  id="shipping"
                  disabled
                  type="text"
                  name="shipping"
                  className="kyc-input-field form-control"
                  value={customerBillData?.discountedDate}
                />
              </div>
            </div>

            <div className="row gy-md-4 mt-2 gy-2">
              <div className="kyc-fields-container col-12  col-md-4">
                <label
                  htmlFor="billing"
                  className="kyc-label-text form-label fw-semibold"
                >
                  Platform Fees (%)
                </label>
                <input
                  disabled
                  id="billing"
                  type="text"
                  name="billing"
                  className="kyc-input-field form-control"
                  value={customerBillData?.outstandingAmount}
                />
              </div>

              <div className="kyc-fields-container col-12  col-md-4">
                <label
                  htmlFor="shipping"
                  className="kyc-label-text form-label fw-semibold"
                >
                  Stamping Charges (%)
                </label>
                <input
                  id="shipping"
                  disabled
                  type="text"
                  name="shipping"
                  className="kyc-input-field form-control"
                  value={customerBillData?.marginAmount}
                />
              </div>
              <div className="kyc-fields-container col-12  col-md-4">
                <label
                  htmlFor="shipping"
                  className="kyc-label-text form-label fw-semibold"
                >
                  Insurance Charges (%)
                </label>
                <input
                  id="shipping"
                  disabled
                  type="text"
                  name="shipping"
                  className="kyc-input-field form-control"
                  value={customerBillData?.margin}
                />
              </div>
            </div>

            <div className="row gy-md-4 mt-2 gy-2">
              <div className="kyc-fields-container col-12  col-md-4">
                <label
                  htmlFor="billing"
                  className="kyc-label-text form-label fw-semibold"
                >
                  Other Charges (%)
                </label>
                <input
                  disabled
                  id="billing"
                  type="text"
                  name="billing"
                  className="kyc-input-field form-control"
                  value={customerBillData?.disbursingAmount}
                />
              </div>

              {discountData == true && (
                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="shipping"
                    className="kyc-label-text form-label fw-semibold"
                  >
                    Disbursing Amount
                  </label>
                  <input
                    id="shipping"
                    disabled
                    type="text"
                    name="shipping"
                    className="kyc-input-field form-control"
                    value={customerBillData?.outstandingRemainingAmount}
                  />
                </div>
              )}

              {discountData == true && (
                <div className="kyc-fields-container col-12  col-md-4">
                  <label
                    htmlFor="shipping"
                    className="kyc-label-text form-label fw-semibold"
                  >
                    Disbursing Remaining Amount
                  </label>
                  <input
                    id="shipping"
                    disabled
                    type="text"
                    name="shipping"
                    className="kyc-input-field form-control"
                    value={customerBillData?.complianceCheck}
                  />
                </div>
              )}

              <div className="d-flex flex-row" style={{ padding: "2rem" }}>
                <div style={{ width: "20%", marginLeft: "60%" }}>
                  {discountData == true ? (
                    <button
                      style={{
                        background: "#719a57",
                        padding: "10px 30px",
                        color: "white",
                        border: "1px solid #719a57",
                        borderRadius: "7px",
                      }}
                      onClick={handleDiscount}
                    >
                      Liquidated
                    </button>
                  ) : (
                    <button
                      style={{
                        background: "#719a57",
                        padding: "10px 30px",
                        color: "white",
                        border: "1px solid #719a57",
                        borderRadius: "7px",
                      }}
                      onClick={handleDiscount}
                    >
                      Liquidate
                    </button>
                  )}
                </div>
                {/* <div style={{width:'20%'}}>
                    <PaymentPage amount={customerBillData?.outstandingAmount}>
                       <button style={{background:'white',padding:'10px 30px',color:'red',border:'2px solid red',borderRadius:'7px'}}>Disburse</button>                                 
                    </PaymentPage>
                   </div> */}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LenderDisbursed;
