import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  redirect,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import "../style/components/lenderKuc.scss";
import { toastMessage } from "../layout/GenericLayout/ToastMessage";
import {
  postUploadDoc,
  postKycData,
  getIfscVerified,
} from "../store/slices/lender";
import { InputValidator } from "../layout/GenericLayout/InputValidator";
import { getUserKYCDocument } from "../store/slices/generic";

/* PARENT COMPONENT */
const KycVerificationForm = () => {
  /* FUNCTION DECLARATIONS */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [error, setError] = useState();
  const [data, setData] = useState({
    IFSC: "",
    address: "",
    branch: "",
    category: "",
    city: "",
    email: "",
    license: "",
    mobileNumber: "",
    name: "",
    phoneNumber: "",
    poc: "",
    state: "",
  });
  const uploadKYCDocuments = useSelector((state) => state.lender.userKYCData);

  console.log(uploadKYCDocuments);

  useEffect(() => {
    dispatch(getUserKYCDocument())
      .unwrap()
      .then((data) => {
        console.log("data", data);

        if (
          data?.code === 200 &&
          data?.items !== undefined &&
          Array.isArray(data?.items) &&
          data?.items?.length > 0
        ) {
          const {
            IFSC,
            address,
            branch,
            category,
            city,
            emailId,
            license,
            mobileNumber,
            name,
            phoneNumber,
            poc,
            state,
          } = data.items[data.items.length - 1];

          setData({
            IFSC,
            address,
            branch,
            category,
            city,
            email: emailId,
            license,
            mobileNumber,
            name,
            phoneNumber,
            poc,
            state,
          });
        }
      })
      .catch(null);
  }, [dispatch]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    console.log("name", name, value);
    
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleBlurIFSC = (e) => {
    setData((prev) => ({ ...prev, IFSC: e.target.value }));
    dispatch(getIfscVerified(e.target.value))
      .unwrap()
      .then((response) => {
        setError((prev) => ({ ...prev, IFSC: "" }));
        toastMessage(response.message, "success");
      })
      .catch((error) => {
        setError((prev) => ({
          ...prev,
          IFSC: error.message ?? "Please enter valid IFSC code",
        }));
      });
  };

  const handleChangeFile = (e) => {
    let formData = new FormData();
    formData.append("profilePicture", e.target.files[0]);
    formData.append("emailId", sessionStorage.getItem("email"));
    formData.append("roles", sessionStorage.getItem("role"));

    dispatch(postUploadDoc(formData))
      .unwrap()
      .then((response) => {
        toastMessage(response.message, "success");
      })
      .catch((error) => {
        console.log(error);
        toastMessage(error.message, "error");
      });
  };

  const handleValidation = () => {
    const errors = {};
    let formIsValid = true;

    //  FirstName
    if (data.name === undefined || data.name === "") {
      formIsValid = false;
      errors.name = "This is a required field";
    }
    if (data.IFSC === undefined || data.IFSC === "") {
      formIsValid = false;
      errors.IFSC = "This is a required field";
    }
    if (data.address === undefined || data.address === "") {
      formIsValid = false;
      errors.address = "This is a required field";
    }
    if (data.branch === undefined || data.branch === "") {
      formIsValid = false;
      errors.branch = "This is a required field";
    }
    if (data.category === undefined || data.category === "") {
      formIsValid = false;
      errors.category = "This is a required field";
    }
    if (data.city === undefined || data.city === "") {
      formIsValid = false;
      errors.city = "This is a required field";
    }
    if (data.license === undefined || data.license === "") {
      formIsValid = false;
      errors.license = "This is a required field";
    }
    if (data.mobileNumber === undefined || data.mobileNumber === "") {
      formIsValid = false;
      errors.mobileNumber = "This is a required field";
    }
    if (data.phoneNumber === undefined || data.phoneNumber === "") {
      formIsValid = false;
      errors.phoneNumber = "This is a required field";
    }
    if (data.poc === undefined || data.poc === "") {
      formIsValid = false;
      errors.poc = "This is a required field";
    }
    if (data.state === undefined || data.state === "") {
      formIsValid = false;
      errors.state = "This is a required field";
    }

    if (data.email === undefined || data.email === "") {
      formIsValid = false;
      errors.email = "This is a required field";
    }

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
      formIsValid = false;
      errors.email = "please enter valid email id";
    }

    console.log("submit", errors);

    setError({ errors });
    return formIsValid;
  };

  const handleSubmit = (event) => {

    if (handleValidation()) {
      const errorKeys = Object.keys(error ?? {});
      const errorsKeys = Object.keys(error?.errors ?? {});
      
      if (errorKeys.length === 0 || ('errors' in error && errorsKeys.length === 0)) {
        const body = {
          IFSC: data?.IFSC,
          address: data?.address,
          branch: data?.branch,
          category: data?.category?.toLowerCase(),
          city: data?.city,
          emailId: data?.email,
          license: data?.license,
          mobileNumber: data?.mobileNumber,
          name: data?.name,
          phoneNumber: data?.phoneNumber,
          poc: data?.poc,
          state: data?.state,
        };

        console.log("data", data);

        dispatch(postKycData(body))
          .unwrap()
          .then((response) => {
            toastMessage(response.success, "success");
            setTimeout(() => {
              window.location.pathname = "/login";
            }, 3000);
          })
          .catch((error) => {
            console.log(error);
            toastMessage(error.message, "error");
          });
      }
    }
  };

  console.log(data);
  console.log(error);

  return (
    <div className="kyc-lender">
      <div className="container">
        <div className="row border w-100 mt-2 p-2 bg-primary text-white fw-bold rounded">
          <label>Banker Application Details</label>
        </div>
        <div className="label-tittle">
          <label>Bank Details</label>
          <hr className="w-98" />
        </div>
        <div className="container mt-4">
          <div className="row kyc-row">
            <div className="col-md-4">
              <div className="input-field">
                <input className="" type="file" onChange={handleChangeFile} />
              </div>
              <div className="label-field">
                <p>Upload File here</p>
              </div>
            </div>
            <div className="col-md-8">
              <div className="row gy-md-4 gy-2">
                <div className="kyc-fields-container col-md-6">
                  <label
                    htmlFor="billing"
                    className="kyc-label-text form-label fw-semibold"
                  >
                    Name of the bank <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    id="billing"
                    type="text"
                    className="kyc-input-field form-control"
                    name="name"
                    style={{
                      border:
                        error?.errors?.name === undefined
                          ? "1px solid lightgray"
                          : "1px solid red",
                    }}
                    onChange={handleChange}
                    value={data?.name}
                  />
                  <span style={{ color: "red" }}>{error?.errors?.name}</span>
                </div>
                <div className="kyc-fields-container col-md-6">
                  <label
                    htmlFor="shipping"
                    className="kyc-label-text form-label fw-semibold"
                  >
                    Name of the branch <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    id="shipping"
                    type="text"
                    className="kyc-input-field form-control"
                    name="branch"
                    value={data?.branch}
                    onChange={handleChange}
                    style={{
                      border:
                        error?.errors?.branch === undefined
                          ? "1px solid lightgray"
                          : "1px solid red",
                    }}
                  />
                  <span style={{ color: "red" }}>{error?.errors?.branch}</span>
                </div>
              </div>

              <div className="row gy-md-4 gy-2 mt-4">
                <div className="kyc-fields-container col-md-6">
                  <label
                    htmlFor="billing"
                    className="kyc-label-text form-label fw-semibold"
                  >
                    Category <span style={{ color: "red" }}>*</span>
                  </label>
                  {/* <input
                    id="billing"
                    type="text"
                    className="kyc-input-field form-control"
                    name="category"
                    onChange={handleChange}
                    style={{border:error?.errors?.category === undefined ? "1px solid lightgray" : '1px solid red'}}                    
                  /> */}
                  <select
                    onChange={handleChange}
                    value={data?.category}
                    name="category"
                    id="billing"
                    className="kyc-input-field form-select"
                    style={{
                      border:
                        error?.errors?.category === undefined
                          ? "1px solid lightgray"
                          : "1px solid red",
                    }}
                  >
                    <option value="">Select Category</option>
                    <option value="banker">Banker</option>
                    <option value="NBFC">NBFC</option>
                  </select>
                  <span style={{ color: "red" }}>
                    {error?.errors?.category}
                  </span>
                </div>
                <div className="kyc-fields-container col-md-6">
                  <label
                    htmlFor="shipping"
                    className="kyc-label-text form-label fw-semibold"
                  >
                    IFSC Code <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    id="shipping"
                    type="text"
                    className="kyc-input-field form-control"
                    name="IFSC"
                    value={data?.IFSC}
                    onChange={handleChange}
                    onKeypress={handleChange}
                    style={{
                      border:
                        error?.errors?.IFSC === undefined
                          ? "1px solid lightgray"
                          : "1px solid red",
                    }}
                  />
                  <span style={{ color: "red" }}>{error?.errors?.IFSC}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="row gy-md-4 gy-2 mt-4">
            <div className="kyc-fields-container col-md-4">
              <label
                htmlFor="billing"
                className="kyc-label-text form-label fw-semibold"
              >
                City <span style={{ color: "red" }}>*</span>
              </label>
              <input
                id="billing"
                type="text"
                className="kyc-input-field form-control"
                value={data?.city}
                name="city"
                onChange={handleChange}
                style={{
                  border:
                    error?.errors?.city === undefined
                      ? "1px solid lightgray"
                      : "1px solid red",
                }}
              />
              <span style={{ color: "red" }}>{error?.errors?.city}</span>
            </div>
            <div className="kyc-fields-container col-md-4">
              <label
                htmlFor="shipping"
                className="kyc-label-text form-label fw-semibold"
              >
                State <span style={{ color: "red" }}>*</span>
              </label>
              <input
                id="shipping"
                type="text"
                className="kyc-input-field form-control"
                name="state"
                value={data?.state}
                onChange={handleChange}
                style={{
                  border:
                    error?.errors?.state === undefined
                      ? "1px solid lightgray"
                      : "1px solid red",
                }}
              />
              <span style={{ color: "red" }}>{error?.errors?.state}</span>
            </div>
            <div className="kyc-fields-container col-md-4">
              <label
                htmlFor="shipping"
                className="kyc-label-text form-label fw-semibold"
              >
                Address <span style={{ color: "red" }}>*</span>
              </label>
              <input
                id="shipping"
                type="text"
                name="address"
                value={data?.address}
                onChange={handleChange}
                className="kyc-input-field form-control"
                style={{
                  border:
                    error?.errors?.address === undefined
                      ? "1px solid lightgray"
                      : "1px solid red",
                }}
              />
              <span style={{ color: "red" }}>{error?.errors?.address}</span>
            </div>
          </div>
          <div className="row gy-md-4 gy-2 mt-4">
            <div className="kyc-fields-container col-md-4">
              <label
                htmlFor="billing"
                className="kyc-label-text form-label fw-semibold"
              >
                Bank License No <span style={{ color: "red" }}>*</span>
              </label>
              <input
                id="billing"
                type="text"
                className="kyc-input-field form-control"
                name="license"
                value={data?.license}
                onChange={handleChange}
                style={{
                  border:
                    error?.errors?.license === undefined
                      ? "1px solid lightgray"
                      : "1px solid red",
                }}
              />
              <span style={{ color: "red" }}>{error?.errors?.license}</span>
            </div>
          </div>
        </div>
        <hr className="mt-5" />
        <div className="label-tittle">
          <label>Contact Details</label>
          <hr className="w-98" />
        </div>
        <div className="container mt-4 " style={{ marginBottom: "12rem" }}>
          <div className="row kyc-row">
            <div className="col-md-4">
              <div className="input-field">
                <input className="" type="file" onChange={handleChangeFile} />
              </div>
              <div className="label-field">
                <p>Upload File here</p>
              </div>
            </div>
            <div className="col-md-8">
              <div className="row gy-md-4 gy-2">
                <div className="kyc-fields-container col-md-6">
                  <label
                    htmlFor="billing"
                    className="kyc-label-text form-label fw-semibold"
                  >
                    Contact Person <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    id="billing"
                    type="text"
                    name="poc"
                    value={data.poc}
                    onChange={handleChange}
                    className="kyc-input-field form-control"
                    disabled={true}
                    style={{
                      border:
                        error?.errors?.poc === undefined
                          ? "1px solid lightgray"
                          : "1px solid red",
                    }}
                  />
                  <span style={{ color: "red" }}>{error?.errors?.poc}</span>
                </div>
                <div className="kyc-fields-container col-md-6">
                  <label
                    htmlFor="shipping"
                    className="kyc-label-text form-label fw-semibold"
                  >
                    Email <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    id="shipping"
                    type="text"
                    className="kyc-input-field form-control"
                    name="email"
                    value={data?.email}
                    onChange={handleChange}
                    disabled={true}
                    style={{
                      border:
                        error?.errors?.email === undefined
                          ? "1px solid lightgray"
                          : "1px solid red",
                    }}
                  />
                  <span style={{ color: "red" }}>{error?.errors?.email}</span>
                </div>
              </div>

              <div className="row gy-md-4 gy-2 mt-4">
                <div className="kyc-fields-container col-md-6">
                  <label
                    htmlFor="billing"
                    className="kyc-label-text form-label fw-semibold"
                  >
                    Telephone Number <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    id="billing"
                    type="text"
                    className="kyc-input-field form-control"
                    name="phoneNumber"
                    value={data?.phoneNumber}
                    onChange={handleChange}
                    style={{
                      border:
                        error?.errors?.phoneNumber === undefined
                          ? "1px solid lightgray"
                          : "1px solid red",
                    }}
                  />
                  <span style={{ color: "red" }}>
                    {error?.errors?.phoneNumber}
                  </span>
                </div>
                <div className="kyc-fields-container col-md-6">
                  <label
                    htmlFor="shipping"
                    className="kyc-label-text form-label fw-semibold"
                  >
                    Mobile Number <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    id="shipping"
                    type="text"
                    name="mobileNumber"
                    value={data?.mobileNumber}
                    disabled={true}
                    onChange={handleChange}
                    className="kyc-input-field form-control"
                    style={{
                      border:
                        error?.errors?.mobileNumber === undefined
                          ? "1px solid lightgray"
                          : "1px solid red",
                    }}
                  />
                </div>
                <div className="row gy-md-4 gy-2 mt-4">
                  <div className="kyc-fields-container col-md-4"></div>
                  <div className="kyc-fields-container col-md-4"></div>
                  <div
                    className="kyc-fields-container col-md-4"
                    style={{ textAlign: "end" }}
                  >
                    <button
                      onClick={handleSubmit}
                      style={{
                        padding: "8px 40px",
                        backgroundColor: "#5675fc",
                        border: "none",
                        borderRadius: "7px",
                        color: "white",
                        fontWeight: "bold",
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KycVerificationForm;
